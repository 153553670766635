import { Typography } from '@mui/material';
import { InfoEmptyIcon } from 'presentation/media/icons';
import { StyledFab, Wrapper } from './controls';
import { FC, ReactNode } from 'react';

type OfferClaimHelpProps = {
  readonly text: ReactNode;
  readonly onClick: () => void;
};

const OfferClaimHelp: FC<OfferClaimHelpProps> = ({ text, onClick }) => {
  return (
    <Wrapper>
      <Typography variant='body2'>{text}</Typography>
      <StyledFab
        variant='squared'
        color='secondaryLight'
        size='medium'
        onClick={onClick}
      >
        <InfoEmptyIcon />
      </StyledFab>
    </Wrapper>
  );
};

export default OfferClaimHelp;
