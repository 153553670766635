import AppDecorators from 'app/decorators';
import AppInfrastructure from 'app/infrastructure';
import HttpClient from 'data/network/http';
import store from 'data/store/store';
import SentryClient from 'integration/sentry/client';
import ContentLoader from 'presentation/components/common/loader';
import AuthProvider from 'presentation/features/auth/provider';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import ErrorBoundary from 'presentation/features/general/errorBoundary';
import LegalEntry from 'presentation/features/legal/entry';
import MainContainer from 'presentation/features/main/container';
import { UserThemeProvider } from 'presentation/features/user/theme/provider';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { MPThemeProvider, theme } from 'presentation/theme';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import SystemNotifier from 'system/notifier';
import Protect from '../presentation/features/auth/protect';
import UserSettingsProvider from '../presentation/features/user/current/userSettingsProvider';

const App = () => {
  HttpClient.getInstance().init(store.dispatch);
  SystemNotifier.getInstance().init(store.dispatch);

  return (
    <>
      <AuthProvider />
      <Switch>
        <Route
          path={rootRouting.legal}
          render={() => (
            <MPThemeProvider theme={theme}>
              <LegalEntry />
            </MPThemeProvider>
          )}
        />
        <AppInternal />
      </Switch>
    </>
  );
};

const AppInternal = () => {
  const { isLoading, isAuthenticated, logOut } = useAuth();
  const { isGuestModeDisabled } = useTechConfig();

  if (isLoading) {
    return <ContentLoader />;
  }

  return (
    <ErrorBoundary
      variant={SentryClient.getInstance().isActive() ? 'sentry' : 'common'}
      onLogout={isAuthenticated && logOut}
    >
      <UserThemeProvider>
        <Protect ignore={!isGuestModeDisabled}>
          <UserSettingsProvider>
            <AppInfrastructure />
            <AppDecorators>
              <MainContainer />
            </AppDecorators>
          </UserSettingsProvider>
        </Protect>
      </UserThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
