import styled from '@emotion/styled/macro';
import {
  Card as MuiCard,
  CardActions as MuiCardActions,
  cardActionsClasses,
  CardContent as MuiCardContent,
  cardContentClasses,
  css,
  Typography,
} from '@mui/material';
import { offerImageAspectRatio } from '../../utils';

export const Card = styled(MuiCard)(
  () => css`
    width: 100%;
    height: 100%;

    position: relative;

    user-select: none;
    cursor: pointer;
  `
);

export const CardColumn = styled(Card)(
  () => css`
    display: flex;
    flex-direction: column;
  `
);

export const CardRow = styled(Card)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 180px minmax(0, 100%);
    justify-content: space-between;
    direction: rtl;

    .${cardContentClasses.root} {
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing()};
      }
    }

    .${cardActionsClasses.root} {
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(0, 1, 1, 1)};
      }
    }
  `
);

export const CardContent = styled(MuiCardContent)(
  ({ theme }) => css`
    direction: ltr;
    flex: 1;

    padding: ${theme.spacing(1.5, 2)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(1.5)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1.5, 1)};
    }

    &.${cardContentClasses.root}:last-child {
      padding-bottom: ${theme.spacing(1.5)};
    }
  `
);

export const CardDescription = styled(Typography)(
  ({ theme }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    ${theme.breakpoints.down('md')} {
      -webkit-line-clamp: 3;
    }

    ${theme.breakpoints.down('sm')} {
      -webkit-line-clamp: 2;
    }
  `
);

export const CardActions = styled(MuiCardActions)(
  ({ theme }) => css`
    direction: ltr;
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 2, 2, 2)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(0, 1.5, 1, 1.5)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(0, 1, 0.75, 1)};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 1, 0.5, 1)};
    }
  `
);

export const CoverImageViewWrapper = styled.div(
  () => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: ${offerImageAspectRatio};
    overflow-x: hidden;
    border-radius: inherit;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center center;
    }

    * {
      direction: initial;
    }
  `
);

export const CardFavoriteButtonWrapper = styled.div(
  () => css`
    position: absolute;
    top: 0;
    right: 0;
  `
);

export const CardDetails = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `
);

export const LogoWrapper = styled.div`
  flex: 0 0 auto;

  width: 2rem;
  height: 2rem;

  ${p => p.theme.breakpoints.down('md')} {
    width: 1.75rem;
    height: 1.75rem;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const CardHeader = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing()};
  `
);

export const CardCaption = styled(Typography)(
  ({ theme }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: ${theme.spacing(3)};
  `
);
