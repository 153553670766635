import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { EOfferListType, ETradeOfferSortType } from 'domain/model/enums';
import { tradeOffersDefaultParams } from 'presentation/features/offer/trade/utils';

export type TradeOfferListState = {
  readonly guid: Nullable<UUID>;
  readonly isNewFetching: boolean;
  readonly isNewSearchFetching: boolean;
  readonly [EOfferListType.Common]: OfferListRequest;
  readonly [EOfferListType.Upcoming]: OfferListRequest;
};

type Reducer<T> = CaseReducer<TradeOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<TradeOfferListState> & {
  tradeOffersStartSession: Reducer<{ guid: UUID }>;
  tradeOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: number }>;
  tradeOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  tradeOffersSetSort: Reducer<{ type: EOfferListType; sort: ETradeOfferSortType[] }>;
  tradeOffersSetArgs: Reducer<{ type: EOfferListType; args: OfferListRequest }>;
  tradeOffersSetIsNewFetching: Reducer<boolean>;
  tradeOffersSetIsNewSearchFetching: Reducer<boolean>;
};

const emptyParams = tradeOffersDefaultParams;

const slice = createSlice<TradeOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    common: emptyParams,
    upcoming: emptyParams,
  },
  reducers: {
    tradeOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
    tradeOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;

      state[type] = args;
    },
    tradeOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;

      state[type].pageSize = pageSize;
    },
    tradeOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;

      state[type].sort = sort;
    },
    tradeOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;

      state[type].page = page;
    },
    tradeOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
    tradeOffersSetIsNewSearchFetching: (state, { payload }) => {
      state.isNewSearchFetching = payload;
    },
  },
});

export const {
  tradeOffersSetPageSize,
  tradeOffersSetPage,
  tradeOffersStartSession,
  tradeOffersSetSort,
  tradeOffersSetIsNewFetching,
  tradeOffersSetIsNewSearchFetching,
  tradeOffersSetArgs,
} = slice.actions;

export default slice.reducer;
