import { ButtonProps } from '@mui/material';
import AppSvgIcon from '../../../../components/common/icon';

import { CloseIcon, MenuIcon } from '../../../../media/icons';
import { StyledCatalogButton } from './controls';

type CatalogButtonProps = Pick<ButtonProps, 'disabled' | 'onClick'> & {
  readonly open: boolean;
  readonly text?: string;
  readonly showIconOnly?: boolean;
};

const CatalogButton = (props: CatalogButtonProps) => {
  const { open, text = 'Каталог', disabled = false, onClick, showIconOnly } = props;

  const icon = open ? (
    <AppSvgIcon
      fontSize='medium'
      icon={CloseIcon}
    />
  ) : (
    <AppSvgIcon
      fontSize='medium'
      icon={MenuIcon}
    />
  );

  const iconOnly = !text || showIconOnly;

  return (
    <StyledCatalogButton
      size='large'
      color='brand'
      disabled={disabled}
      onClick={onClick}
    >
      {iconOnly ? (
        icon
      ) : (
        <>
          {icon}
          {text}
        </>
      )}
    </StyledCatalogButton>
  );
};

export default CatalogButton;
