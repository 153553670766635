import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    position: relative;
  `
);

export const HeaderWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: end;
    border-radius: 100px;

    margin-bottom: ${theme.spacing(3)};

    gap: ${theme.spacing()};

    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const HeaderTitleWrapper = styled.div(
  () => css`
    flex: 1;
  `
);
