import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { AppOption } from 'domain/model';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';

type OrderCancelReasonType = AppOption;

type NsiApi = {
  readonly cancelOrderCustomerReasonTypes: (
    props: ApiCancellable
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderCancelReasonType[]>>;
  readonly cancelOrderReasonTypes: (
    props: ApiCancellable
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderCancelReasonType[]>>;
};

/**
 * АПИ по работе с НСИ
 */
const nsi: NsiApi = {
  cancelOrderCustomerReasonTypes: ({ signal }) => {
    return {
      url: `/customer/order/cancel-reason-types`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  cancelOrderReasonTypes: ({ signal }) => {
    return {
      url: `/orders/cancel-reason-types`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default nsi;
