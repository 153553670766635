import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useHistory } from 'react-router';
import { getUserOrdersRoute } from '../../../../user/entry';
import { getBookingOffersListRoute } from '../../routes';

const BookingOfferOrderButtons = () => {
  const history = useHistory();

  return (
    <MPGrid
      container
      spacing={2}
      flexDirection='column'
    >
      <MPGrid item>
        <MPButton
          fullWidth
          size='large'
          color='brand'
          onClick={() => history.push(getBookingOffersListRoute())}
        >
          Перейти в объекты отдыха
        </MPButton>
      </MPGrid>
      <MPGrid item>
        <MPButton
          fullWidth
          size='large'
          variant='text'
          color='brand'
          onClick={() => history.push(getUserOrdersRoute({}))}
        >
          Перейти к заказам
        </MPButton>
      </MPGrid>
    </MPGrid>
  );
};

export default BookingOfferOrderButtons;
