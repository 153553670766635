import { Container } from '@mui/material';
import React from 'react';

import { useHeaderParams } from '../../../../hooks/useHeaderParams';
import { Wrapper } from './controls';

type ScreenNavWrapperProps = {
  readonly children: React.ReactNode;
};

const ScreenNavWrapper = ({ children }: ScreenNavWrapperProps) => {
  const { headerHeight } = useHeaderParams({ allHeaders: false });

  return (
    <Wrapper
      position='sticky'
      offsetTop={headerHeight}
    >
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default ScreenNavWrapper;
