import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ProgressWrapper, Wrapper } from './controls';

export type ContentLoaderWrapperProps = {
  readonly alpha?: boolean;
  readonly size?: number | string;
  readonly position?: 'absolute' | 'relative' | 'fixed' | 'static';
  readonly justifyContent?: 'start' | 'center' | 'end';
};

type ContentLoaderProps = {
  readonly progress?: Nullable<number>;
  readonly color?: 'primary' | 'secondary' | 'inherit';
};

const ContentLoader = (props: ContentLoaderWrapperProps & ContentLoaderProps) => {
  const { size = '2rem', color = 'primary', alpha, position = 'absolute', justifyContent = 'center', progress } = props;

  return (
    <Wrapper
      alpha={alpha}
      size={size}
      position={position}
      justifyContent={justifyContent}
      data-testid={'content-loader'}
    >
      <CircularProgress
        size={size}
        color={color}
      />
      {(progress ?? 0) > 0 && (
        <ProgressWrapper>
          <Typography noWrap>{progress} %</Typography>
        </ProgressWrapper>
      )}
    </Wrapper>
  );
};

export default ContentLoader;
