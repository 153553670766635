import { combineReducers } from '@reduxjs/toolkit';
import offers, { SearchOffersState } from './offers/store/slice';

type SearchState = {
  readonly offers: SearchOffersState;
};

export default combineReducers<SearchState>({
  offers,
});
