import LastOrdersContainer from 'presentation/features/cart/lastOrders/container';
import ScreenLayout from 'presentation/layouts/screen';

export const LastOrdersScreen = () => {
  return (
    <ScreenLayout>
      <LastOrdersContainer />
    </ScreenLayout>
  );
};
