import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import PartnerDeskDetailsContainer from 'presentation/features/partnerDesk/details/container';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { PartnerDeskDetailsLocationState } from 'presentation/features/partnerDesk/entry';
import { EPartnerDeskUrlParam } from 'presentation/features/partnerDesk/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation, useParams } from 'react-router';

const PartnerDeskScreen = () => {
  const location = useLocation<Nullable<PartnerDeskDetailsLocationState>>();
  const { id } = useParams<{ id: UUID }>();
  const { isCorpUser } = useAuthUser();

  const searchParams = new URLSearchParams(location.search);

  const tab = (searchParams.get(EPartnerDeskUrlParam.Tab) as EPartnerDeskDetailsTab) ?? null;
  const categoryId = searchParams.get(EPartnerDeskUrlParam.Category) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <PartnerDeskDetailsContainer
            guid={guid}
            id={id}
            tab={tab}
            categoryId={categoryId}
            isCorpUser={isCorpUser}
            isTradeOfferFavoritesAllowed
            isCorpOfferFavoritesAllowed
            isProductOfferFavoritesAllowed
            isBookingOfferFavoritesAllowed
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default PartnerDeskScreen;
