import styled from '@emotion/styled/macro';
import { css, Grid } from '@mui/material';

export enum EProductDetailsGridArea {
  Breadcrumbs = 'breadcrumbs',
  Title = 'title',
  Favorite = 'favorite',
  Presentation = 'presentation',
  Controls = 'controls',
  Sidebar = 'sidebar',
}

export const DetailsGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    width: 100%;
    grid-column-gap: ${theme.spacing(1)};
    grid-row-gap: ${theme.spacing(1)};
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-template-areas:
      '${EProductDetailsGridArea.Breadcrumbs} ${EProductDetailsGridArea.Breadcrumbs} ${EProductDetailsGridArea.Breadcrumbs}'
      '${EProductDetailsGridArea.Title} ${EProductDetailsGridArea.Title} ${EProductDetailsGridArea.Title}'
      '${EProductDetailsGridArea.Favorite} ${EProductDetailsGridArea.Favorite} ${EProductDetailsGridArea.Favorite}'
      '${EProductDetailsGridArea.Presentation} ${EProductDetailsGridArea.Presentation} ${EProductDetailsGridArea.Presentation}'
      '${EProductDetailsGridArea.Sidebar} ${EProductDetailsGridArea.Sidebar} ${EProductDetailsGridArea.Sidebar}'
      '${EProductDetailsGridArea.Controls} ${EProductDetailsGridArea.Controls} ${EProductDetailsGridArea.Controls}';

    ${theme.breakpoints.up('sm')} {
      grid-column-gap: ${theme.spacing(1.75)};
      grid-row-gap: ${theme.spacing(1.75)};
    }

    ${theme.breakpoints.up('md')} {
      grid-column-gap: ${theme.spacing(4)};
      grid-row-gap: ${theme.spacing(1.75)};
      grid-template-columns: 346px 1fr 260px;
      grid-template-areas:
        '${EProductDetailsGridArea.Breadcrumbs} ${EProductDetailsGridArea.Breadcrumbs} ${EProductDetailsGridArea.Breadcrumbs}'
        '${EProductDetailsGridArea.Title} ${EProductDetailsGridArea.Title} ${EProductDetailsGridArea.Favorite}'
        '${EProductDetailsGridArea.Presentation} ${EProductDetailsGridArea.Controls} ${EProductDetailsGridArea.Sidebar}';
    }

    ${theme.breakpoints.up('lg')} {
      grid-column-gap: ${theme.spacing(7.5)};
      grid-template-columns: minmax(346px, 455px) 1fr 300px;
      grid-row-gap: ${theme.spacing(1.75)};
    }
  `
);

type DetailsGridAreaProps = {
  readonly area: EProductDetailsGridArea;
};

export const DetailsGridArea = styled(Grid)<DetailsGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);
