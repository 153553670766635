import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { CmsCollectionSummaryLayoutType } from 'presentation/features/cms/collectionSummary/layout/types';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';

const CmsCollectionSummaryLayout: CmsCollectionSummaryLayoutType = props => {
  const { loader, children } = props;

  useVisibleSectionLinks();

  return (
    <DefaultContentWrapper>
      {children}
      {loader}
    </DefaultContentWrapper>
  );
};

export default CmsCollectionSummaryLayout;
