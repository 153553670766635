import { FC, useRef, useState } from 'react';
import { DialogContent, Typography } from '@mui/material';
import { Address } from 'domain/model/address';
import { getMapsConfig } from 'presentation/utils';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { BookingDetailsId } from '../../types';
import { DialogContainer, MapClicker, MapContainer, StyledIframe } from './controls';

const BookingOfferMapAdapter: FC<BookingDetailsId> = ({ id }) => {
  const { unavailableReason } = getMapsConfig();
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const [isModalVisible, setModalVisible] = useState(false);

  const mapRef = useRef<Nullable<HTMLIFrameElement>>(null);

  const { name, position } = bookingOffer?.address ?? ({} as Partial<Address>);
  const url = `https://yandex.ru/map-widget/v1/?ll=${position?.lon}%2C${position?.lat}&text=${encodeURI(
    name ?? ''
  )}&z=12&mode=whatshere`;

  if (!bookingOffer?.address || !name || !position?.lat || !position?.lon) {
    return null;
  }

  const handleShowModal = () => {
    if (unavailableReason) {
      return;
    }
    setModalVisible(true);
  };

  return (
    <MapContainer
      container
      isError={!!unavailableReason}
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      {!unavailableReason && (
        <>
          <StyledIframe
            allowFullScreen
            loading='eager'
            src={url}
            ref={mapRef}
          />
          <MapClicker onClick={handleShowModal} />
        </>
      )}
      {!!unavailableReason && <Typography variant='body2'>{unavailableReason}</Typography>}
      <DialogContainer
        closeButtonVisible
        maxWidth='xl'
        open={isModalVisible}
        onClose={() => setModalVisible(false)}
      >
        <DialogContent>
          <StyledIframe
            isOpened
            allowFullScreen
            src={url}
          />
        </DialogContent>
      </DialogContainer>
    </MapContainer>
  );
};

export default BookingOfferMapAdapter;
