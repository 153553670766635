import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { pluralize } from 'presentation/utils';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import SmartPhoneFixedBar from '../../../components/smartPhoneFixedBar';
import { BookingCartItem } from '../../details/types';
import { TypographyEllipsis } from '../typographyEllipsis';
import {
  ButtonNoWrap,
  SmartPhoneFixedBarBottomWrapper,
  SmartPhoneFixedBarWrapper,
  SmartphoneSlotsGrid,
} from './controls';
import useBookingSidebarSlots from './slots';
import FooterRegister from 'presentation/features/footerRegister/footerRegister';

export type BookingSidebarSmartPhoneProps = {
  readonly slots: ReturnType<typeof useBookingSidebarSlots>;
  readonly cart: Nullable<BookingCartItem[]>;
  readonly totalPrice: Nullable<string>;
};

const BlankButton = (
  <MPButton
    key='disabled'
    fullWidth
    disabled
  >
    Выберите минимум одну услугу
  </MPButton>
);

const pluralizes = ['услуга', 'услуги', 'услуг'];

const BookingSidebarSmartPhone: FC<BookingSidebarSmartPhoneProps> = ({ slots, cart, totalPrice }) => {
  const [showModalCart, setShowModalCart] = useState(false);

  return (
    <>
      {showModalCart ? (
        <SmartPhoneFixedBarWrapper>
          <SmartPhoneFixedBar
            text='Оформление заказа'
            onBack={() => setShowModalCart(false)}
          />
          <SmartphoneSlotsGrid>
            {slots}
            <Splitter size={3} />
          </SmartphoneSlotsGrid>
        </SmartPhoneFixedBarWrapper>
      ) : (
        <FooterRegister name='bookingSidebarSmartPhoneFooter'>
          {footerRef => {
            return (
              <SmartPhoneFixedBarBottomWrapper ref={footerRef}>
                {cart && cart.length > 0 ? (
                  <MPGrid
                    container
                    spacing={1}
                    justifyContent='space-between'
                    alignItems='center'
                    flexWrap='nowrap'
                  >
                    <MPGrid item>
                      <Typography variant='caption'>
                        {cart.length} {pluralize(cart.length, pluralizes)}
                      </Typography>
                      <TypographyEllipsis variant='caption'>Предварительная стоимость</TypographyEllipsis>
                      <Splitter size={0.5} />
                      <Typography variant='h3'>{totalPrice}</Typography>
                    </MPGrid>
                    <MPGrid item>
                      <ButtonNoWrap
                        color='brand'
                        size='large'
                        onClick={() => setShowModalCart(true)}
                      >
                        К оформлению
                      </ButtonNoWrap>
                    </MPGrid>
                  </MPGrid>
                ) : (
                  BlankButton
                )}
              </SmartPhoneFixedBarBottomWrapper>
            );
          }}
        </FooterRegister>
      )}
    </>
  );
};

export default BookingSidebarSmartPhone;
