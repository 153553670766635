import axios, { AxiosResponse } from 'axios';
import { getBaseEndpoint } from 'data/openApi/utils';
import { EUserEventTypeCode } from 'domain/model/enums';

type ByOffersQueryProps = {
  readonly data: {
    readonly id: UUID;
    readonly typeCode: EUserEventTypeCode | string;
  }[];
};

type UserEventApi = {
  readonly byOffersQuery: (props: ByOffersQueryProps) => Promise<AxiosResponse<void>>;
};

/**
 * АПИ по работе с событиями, инициированные пользователем
 */
const userEvent: UserEventApi = {
  byOffersQuery: ({ data }) => {
    return axios.request({
      url: `${getBaseEndpoint()}/offers/events`,
      method: 'POST',
      data: data.map(({ id, typeCode }) => ({ offer: { id }, typeCode })),
    });
  },
};

export default userEvent;
