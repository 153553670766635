import { ReactNode } from 'react';
import { Wrapper } from './controls';

type OfferCatalogButtonProps = {
  readonly icon?: ReactNode;
  readonly title: ReactNode;
};

const OfferHeaderTitle = (props: OfferCatalogButtonProps) => {
  const { icon, title } = props;

  return (
    <Wrapper>
      {icon}
      {title}
    </Wrapper>
  );
};

export default OfferHeaderTitle;
