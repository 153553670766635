import { TypographyProps } from '@mui/material';
import { SectionTitleStyled } from './controls';

const SectionTitle = (props: TypographyProps) => {
  const { gutterBottom = true, color = 'textSecondary', variant = 'body2', ...others } = props;

  return (
    <SectionTitleStyled
      variant={variant}
      color={color}
      gutterBottom={gutterBottom}
      {...others}
    />
  );
};

export default SectionTitle;
