import { CmsOfferCollectionAdapterProps } from '../types';
import { getDataCmsCollectionRoute } from '../../../data/entry';
import { CmsContainerShowAllLink } from '../../components/showAllLink';

/**
 * адаптер который добавляет в хедер кнопку "Посмотреть всё"
 */
export const CmsCollectionShowAllAdapter = (props: Pick<CmsOfferCollectionAdapterProps, 'id'>) => {
  const { id } = props;

  const link = getDataCmsCollectionRoute({ id });

  return <CmsContainerShowAllLink to={link} />;
};
