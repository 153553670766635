import { Link as MUILink, Typography, useMediaQuery } from '@mui/material';
import { ProductOffer } from 'domain/model/productOffer';
import CardFavoriteButton from 'presentation/components/common/buttons/cardFavorite/button';
import { CoverImageView } from 'presentation/components/common/images/cover';
import { CloseIcon, RefreshIcon } from 'presentation/media/icons';
import MPSwitchIconButton from 'presentation/theme/ui-kit/button/switchIcon/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from 'presentation/utils/currency';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProductOfferCartCounterContainer from '../../cartCounter/container';
import { DiscountValue, OriginalPrice } from '../../controls';
import { getProductOfferDetailsRoute } from '../../entry';
import { EProductOfferFavoritePosition, formatToSale } from '../../utils';
import ProductOfferCartButtonLabelForCard from '../addToCartButton/labels/forCard';
import {
  Card,
  CardActions,
  CardContent,
  CardDescription,
  CardDetails,
  CardFavoriteButtonWrapper,
  CartActions,
  CoverImageViewWrapper,
  SwitchIconButtonWrapper,
} from './controls';
import { LoggedUserAdapter } from '../../../../auth/provider/loggedUserAdapter';

type FavoriteProps = {
  readonly active: boolean;
  readonly inProgress: boolean;
  readonly position?: EProductOfferFavoritePosition;
  readonly showAlways?: boolean;
  readonly onClick: () => void;
};

type ProductOfferCardProps = {
  readonly product: ProductOffer;
  readonly toRemove?: boolean;
  readonly imageThumb?: boolean;
  readonly favorite?: Nullable<FavoriteProps>;
  readonly children?: React.ReactNode;
};

const ProductOfferCard = (props: ProductOfferCardProps) => {
  const { toRemove, imageThumb = true, favorite, product, children } = props;

  const { price, originalPrice, images, salePercent } = product;
  const image = images?.[0] ?? null;

  const [mouseOnCard, setMouseOnCard] = useState<boolean>(false);
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const favoritePosition = favorite?.position ?? EProductOfferFavoritePosition.Preview;
  const showFavorite = favorite?.showAlways || mouseOnCard || favorite?.inProgress || favorite?.active;
  const isOriginalPriceVisible = originalPrice && originalPrice > price;

  return (
    <MUILink
      component={Link}
      to={getProductOfferDetailsRoute({ id: product.id })}
      underline='none'
    >
      <Card
        elevation={0}
        toRemove={toRemove}
        onMouseEnter={() => setMouseOnCard(true)}
        onMouseLeave={() => setMouseOnCard(false)}
      >
        <CardActions>
          <CoverImageViewWrapper>
            <CoverImageView
              border={false}
              topRounded={false}
              bottomRounded={false}
              thumb={imageThumb}
              fit='contain'
              id={image?.path ?? null}
            />
            {favorite && showFavorite && (favoritePosition === EProductOfferFavoritePosition.Preview || !isSmDown) && (
              <CardFavoriteButtonWrapper>
                <CardFavoriteButton {...favorite} />
              </CardFavoriteButtonWrapper>
            )}
          </CoverImageViewWrapper>
          <CardDetails>
            <CardContent>
              <MPGrid
                container
                spacing={1}
                wrap='nowrap'
                alignItems='baseline'
              >
                <MPGrid
                  item
                  zero
                >
                  <CardDescription variant='body1'>{product.name}</CardDescription>
                </MPGrid>
                {favorite && favoritePosition === EProductOfferFavoritePosition.Details && isSmDown && (
                  <MPGrid item>
                    <SwitchIconButtonWrapper>
                      <MPSwitchIconButton
                        {...favorite}
                        icons={{
                          on: CloseIcon,
                          off: RefreshIcon,
                        }}
                      />
                    </SwitchIconButtonWrapper>
                  </MPGrid>
                )}
              </MPGrid>
              {children}
            </CardContent>
            <CardActions>
              <MPGrid
                container
                spacing={isSmDown ? 0.5 : 1}
                alignItems='center'
              >
                <MPGrid
                  item
                  zero={isOriginalPriceVisible ? 'auto' : true}
                  sm={'auto'}
                >
                  <Typography variant={isSmDown ? 'subtitle1' : 'h4'}>{toRubCurrency(price)}</Typography>
                </MPGrid>
                {isOriginalPriceVisible && (
                  <MPGrid
                    item
                    container
                    zero={12}
                    sm={'auto'}
                  >
                    <MPGrid
                      item
                      zero={'auto'}
                    >
                      <OriginalPrice
                        variant={isSmDown ? 'body2' : 'body1'}
                        color='textSecondary'
                      >
                        {toRubCurrency(originalPrice)}
                      </OriginalPrice>
                    </MPGrid>
                    {salePercent && (
                      <MPGrid
                        item
                        zero={'auto'}
                      >
                        <DiscountValue
                          variant={isSmDown ? 'body2' : 'body1'}
                          color='primary.main'
                        >
                          {formatToSale(salePercent)}
                        </DiscountValue>
                      </MPGrid>
                    )}
                  </MPGrid>
                )}
              </MPGrid>
            </CardActions>
          </CardDetails>
        </CardActions>
        <LoggedUserAdapter>
          <CartActions>
            <ProductOfferCartCounterContainer
              product={product}
              label={ProductOfferCartButtonLabelForCard}
            />
          </CartActions>
        </LoggedUserAdapter>
      </Card>
    </MUILink>
  );
};

export default ProductOfferCard;
