import { Text } from '../../components/controls';
// import { useMemo } from 'react';
// import { getSupportInfo } from '../../../../utils';
// import { Link } from '@mui/material';

const AboutAspCommonInfo = () => {
  // const serviceLink = useMemo(() => {
  //   const email = getSupportInfo()?.email;
  //   return (
  //     <Link
  //       color='primary'
  //       underline='none'
  //       target='_blank'
  //       href={`mailto:${email}`}
  //     >
  //       {email}
  //     </Link>
  //   );
  // }, []);

  return (
    <>
      <Text>
        АСП (Авансированный социальный пакет) — это вид социальной поддержки для работников ОАО «РЖД». работник может
        использовать АСП для покупки сертификатов на товары и услуг через сервис «Витрина» в пределах установленного
        персонального лимита.
      </Text>
    </>
  );
};

export default AboutAspCommonInfo;
