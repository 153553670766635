import { Text } from '../../components/controls';

const AboutPublicOfferResponsibilityInfo = () => {
  return (
    <>
      <Text>
        5.1. РЖД и (или) Оператор не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы
        Платформы или отдельных их компонентов и (или) функций, соответствия Платформы конкретным целям и ожиданиям
        Пользователя Платформы, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящей Оферте.
        При этом пользователь может обратиться в техническую поддержку Платформы с запросом по ее работе, в том числе по
        ее неисправности, с целью последующего устранения этой неисправности.
      </Text>
      <Text>
        5.2. РЖД и (или) Оператор не несет ответственности за какие-либо прямые или косвенные последствия какого-либо
        использования или невозможности использования Платформы ущерб, причиненный Пользователю Платформы и (или)
        третьим сторонам в результате какого-либо использования, неиспользования или невозможности использования
        Платформы или отдельных ее компонентов и (или) функций, за исключением случаев, прямо предусмотренных
        законодательством.
      </Text>
      <Text>
        5.3. РЖД и (или) Оператор не несет ответственности за качество товара и (или) услуги, предоставляемой Партнером
        Пользователю Платформы.
      </Text>
    </>
  );
};

export default AboutPublicOfferResponsibilityInfo;
