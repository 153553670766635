import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import LandingWindowContainer from 'presentation/features/landing/window/container';
import ScreenLayout from 'presentation/layouts/screen';

const LandingWindowScreen = () => {
  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <LandingWindowContainer guid={guid} />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default LandingWindowScreen;
