import { Typography } from '@mui/material';
import React from 'react';
import { MPSelect } from 'presentation/theme/ui-kit/select';

type SortSelectItem = {
  readonly id: string;
  readonly name: string;
};

type SortSelectProps = {
  readonly value: Nullable<string>;
  readonly items: SortSelectItem[];
  readonly defaultLabel?: string;
  readonly renderValue?: (value: any) => React.ReactNode;
  readonly onChange: (value: string) => void;
};

function SortSelect(props: SortSelectProps) {
  const { value, items, defaultLabel = 'Отсортировать', renderValue, onChange } = props;

  return (
    <MPSelect
      value={value ?? ''}
      size='micro'
      label={value ? '' : defaultLabel}
      onChange={onChange}
      renderValue={renderValue}
    >
      {items.map(({ id, name }) => (
        <MPSelect.Item
          value={id}
          key={id}
        >
          <Typography>{name}</Typography>
        </MPSelect.Item>
      ))}
    </MPSelect>
  );
}

export default SortSelect;
