import styled from '@emotion/styled/macro';
import { Badge, css } from '@mui/material';

const SIZE = 12;

export const StyledBadge = styled(Badge)(
  () => css`
    .MuiBadge-badge {
      min-width: ${SIZE}px;
      height: ${SIZE}px;
      padding: 0;
    }
  `
);
