import CmsCollectionSummaryContainer from 'presentation/features/cms/collectionSummary/container';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { useParams } from 'react-router';

const CmsCollectionScreen = () => {
  const { id } = useParams<{ id: UUID }>();

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <CmsCollectionSummaryContainer
            id={id}
            guid={guid}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default CmsCollectionScreen;
