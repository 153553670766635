import styled from '@emotion/styled/macro';
import { Container, css, Typography, typographyClasses } from '@mui/material';

type WrapperProps = {
  readonly bottomOffset: number;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, bottomOffset }) => css`
    position: fixed;
    z-index: ${theme.zIndex.appBar};
    bottom: ${bottomOffset}px;
    right: 0;
    left: 0;
    background: ${theme.palette.white.main};
    padding: ${theme.spacing(1.5)} 0;
  `
);

export const AcceptCookieContainer = styled(Container)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.up('sm')} {
      justify-content: flex-end;
    }
  `
);

export const Text = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    font-weight: ${theme.typography.body2.fontWeight};
    margin-right: ${theme.spacing(3)};

    .${typographyClasses.root} {
      display: inline-block;
    }

    ${theme.breakpoints.up('sm')} {
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body1.lineHeight};
      font-weight: ${theme.typography.body1.fontWeight};
    }
  `
);
