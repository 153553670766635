import { Breakpoint, Dialog, DialogProps, useMediaQuery } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import { ReactNode } from 'react';
import { CloseButton, StyledDialogTitle } from './controls';
import { MPDialogBackButton } from './dialogBackButton';

export type MPDialogProps = Omit<DialogProps, 'title'> & {
  readonly title?: ReactNode;
  readonly backButtonLabel?: ReactNode;
  readonly closeButtonVisible?: boolean;
  readonly closeButton?: ReactNode;
  //брекпойнт, с которого включается полноэкранный режим
  readonly fullScreenBreakpoint?: Breakpoint;
};

export const MPDialog = (props: MPDialogProps) => {
  const {
    children,
    fullWidth = true,
    maxWidth = 'md',
    closeButtonVisible = true,
    closeButton,
    backButtonLabel,
    title,
    fullScreenBreakpoint,
    ...others
  } = props;

  const fullScreenBreakpointDefault = others.fullScreen ? 10000 : 0;

  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const fullScreen = useMediaQuery(theme =>
    theme.breakpoints.down(fullScreenBreakpoint ?? fullScreenBreakpointDefault)
  );

  const isBackButtonVisible = closeButtonVisible && others.onClose && fullScreen;
  const isCloseIconVisible = closeButtonVisible && others.onClose && !fullScreen;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll={downSm ? 'paper' : 'body'}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
      }}
      {...others}
      fullScreen={others.fullScreen || fullScreen}
    >
      {isBackButtonVisible && (
        <MPDialogBackButton
          label={backButtonLabel}
          onClick={event => others.onClose?.(event, 'escapeKeyDown')}
        />
      )}
      {isCloseIconVisible && (
        <>
          {closeButton ? (
            closeButton
          ) : (
            <CloseButton onClick={event => others.onClose?.(event, 'backdropClick')}>
              <CloseIcon fontSize='large' />
            </CloseButton>
          )}
        </>
      )}
      {title && <StyledDialogTitle compact={!!isBackButtonVisible}>{title}</StyledDialogTitle>}
      {children}
    </Dialog>
  );
};
