import { SvgIconProps } from '@mui/material';

export type AppSvgProps = SvgIconProps & {
  readonly icon: any;
};

const AppSvgIcon = ({ icon, ...others }: AppSvgProps) => {
  const Component = icon;
  return (
    <Component
      fontSize='medium'
      {...others}
    />
  );
};

export default AppSvgIcon;
