import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const badge: OverrideThemeFunc<Components> = baseTheme => ({
  MuiBadge: {
    styleOverrides: {
      badge: {
        height: baseTheme.typography.caption.lineHeight,
        minWidth: '15px',

        pointerEvents: 'none',
        zIndex: baseTheme.zIndex.fab,

        fontSize: baseTheme.typography.caption.fontSize,
        lineHeight: baseTheme.typography.caption.lineHeight,
        fontWeight: baseTheme.typography.caption.fontWeight,

        padding: baseTheme.spacing(0, 0.5),
      },
      colorBrand: {
        backgroundColor: baseTheme.palette.brand.main,
        color: baseTheme.palette.brand.contrastText,
      },
    },
  },
});

export default badge;
