import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';

export const CorpOfferClaimPendingWarnMessage = () => {
  return (
    <ClaimMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        Заявка обрабатывается
      </Typography>
    </ClaimMessage>
  );
};
