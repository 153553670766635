import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bottomNavigationSetVisible } from '../../../../header/store/slice';
import useHideHeader from '../../../../header/hooks/useHideHeader';

export const useOrderPrepare = () => {
  const dispatch = useDispatch();

  useHideHeader(true);

  useEffect(() => {
    dispatch(bottomNavigationSetVisible(false));

    return () => {
      dispatch(bottomNavigationSetVisible(true));
    };
  }, [dispatch]);
};
