import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useProductOffersContextHandlers } from '../../../hooks/useProductOffersContextHandlers';
import { partnerDeskDetailsGuidSelector, partnerDeskDetailsProductOffersArgsSelector } from '../../../store/selectors';
import PartnerDeskProductOffersDataContainer from '../dataContainer';

type PartnerDeskProductOffersAdapterProps = {
  readonly guid: UUID;
};

const PartnerDeskProductOffersAdapter = ({ guid }: PartnerDeskProductOffersAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsProductOffersArgsSelector);

  const handlers = useProductOffersContextHandlers();

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskProductOffersDataContainer
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangeProductOffersPage}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeProductOffersPage]
  );
};

export default PartnerDeskProductOffersAdapter;
