import moment from 'moment-timezone';

export const sortDates = (date1: Date, date2: Date) => {
  const isBefore = moment(date1).startOf('day').isBefore(moment(date2).startOf('day'));
  const isAfter = moment(date1).startOf('day').isAfter(moment(date2).startOf('day'));

  if (isBefore) return -1;
  if (isAfter) return 1;
  return 0;
};
