import { combineReducers } from '@reduxjs/toolkit';
import provider, { AuthProviderState } from './provider/store/slice';

type AuthState = {
  readonly provider: AuthProviderState;
};

export default combineReducers<AuthState>({
  provider,
});
