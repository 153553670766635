import { ButtonProps } from '@mui/material';
import { StyledDottedButton } from './controls';

const DottedButton = (props: ButtonProps) => {
  return (
    <StyledDottedButton
      {...props}
      variant='outlined'
    />
  );
};

export default DottedButton;
