import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { UserFavoriteProcess } from 'domain/model';
import { EOfferListType, EProductOfferSortType } from 'domain/model/enums';
import { PriceRangeFilter } from 'domain/model/offer';
import { productOffersDefaultParams } from 'presentation/features/offer/product/utils';
import { PaginationSize } from 'presentation/types';

export type ProductOfferListState = {
  readonly guid: Nullable<UUID>;
  readonly [EOfferListType.Common]: OfferListRequest;
  readonly filter: Nullable<PriceRangeFilter>;
  readonly processes: UserFavoriteProcess[];
  readonly isNewFetching: boolean;
  readonly isNewSearchFetching: boolean;
};

type Reducer<T> = CaseReducer<ProductOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<ProductOfferListState> & {
  productOffersSetPageSize: Reducer<PaginationSize>;
  productOffersSetPage: Reducer<number>;
  productOffersSetFilter: Reducer<PriceRangeFilter>;
  productOffersSetFavoritesChangeProcess: Reducer<UserFavoriteProcess>;
  productOffersClearFavoritesChangeProcess: Reducer<void>;
  productOffersSetSort: Reducer<EProductOfferSortType[]>;
  productOffersSetArgs: Reducer<OfferListRequest>;
  productOffersStartSession: Reducer<UUID>;
  productOffersSetIsNewFetching: Reducer<boolean>;
  productOffersSetIsNewSearchFetching: Reducer<boolean>;
};

const initialState: ProductOfferListState = {
  guid: null,
  common: productOffersDefaultParams,
  filter: null,
  processes: [],
  isNewFetching: true,
  isNewSearchFetching: true,
};

const slice = createSlice<ProductOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState,
  reducers: {
    productOffersStartSession: (state, { payload }) => {
      state.guid = payload;
    },
    productOffersSetPageSize: (state, { payload: pageSize }) => {
      state.common.pageSize = pageSize;
    },
    productOffersSetPage: (state, { payload }) => {
      state.common.page = payload;
    },
    productOffersSetSort: (state, { payload: sort }) => {
      state.common.sort = sort;
    },
    productOffersSetArgs: (state, { payload }) => {
      state.common = payload;
    },
    productOffersSetFavoritesChangeProcess: (state, { payload }) => {
      const process = state.processes.find(p => p.id === payload.id);
      if (process) {
        process.action = payload.action;
        process.id = payload.id;
        process.isFetching = payload.isFetching;
        process.isFetched = payload.isFetched;
        process.isFailed = payload.isFailed;
        process.isCancelled = payload.isCancelled;
      } else {
        state.processes.push(payload);
      }
    },
    productOffersClearFavoritesChangeProcess: state => {
      state.processes = [];
    },
    productOffersSetFilter: (state, { payload }) => {
      state.filter = payload;
    },
    productOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
    productOffersSetIsNewSearchFetching: (state, { payload }) => {
      state.isNewSearchFetching = payload;
    },
  },
});

export const {
  productOffersSetPageSize,
  productOffersSetPage,
  productOffersSetFavoritesChangeProcess,
  productOffersClearFavoritesChangeProcess,
  productOffersStartSession,
  productOffersSetSort,
  productOffersSetArgs,
  productOffersSetFilter,
  productOffersSetIsNewFetching,
  productOffersSetIsNewSearchFetching,
} = slice.actions;

export default slice.reducer;
