import styled from '@emotion/styled/macro';
import { Checkbox } from '@mui/material';

export const StyledCheckbox = styled(Checkbox)`
  margin-top: -${p => p.theme.spacing(0.75)};
  margin-left: -${p => p.theme.spacing(0.75)};
  padding: ${p => p.theme.spacing(0.75)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.light};
  padding: ${p => p.theme.spacing(2)} 0;

  :last-child {
    border-bottom: 1px solid ${p => p.theme.palette.secondary.light};
  }
`;
