import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';

type UserCorpLinkAcceptDialogProps = {
  readonly open: boolean;
  readonly onAccept: () => void;
  readonly onClose: () => void;
};

const UserCorpLinkAcceptDialog = (props: UserCorpLinkAcceptDialogProps) => {
  const { open, onAccept, onClose } = props;

  return (
    <MPConfirmDialog
      title='Подтвердить, что вы являетесь работником «ОАО РЖД»?'
      open={open}
      maxWidth='xs'
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onAccept}
          >
            Продолжить
          </MPButton>
          <MPButton
            fullWidth
            variant='text'
            color='brand'
            size='large'
            onClick={onClose}
          >
            Отменить
          </MPButton>
        </ConfirmButtons>
      }
    >
      Профиль будет заполнен данными из Сервисного портала после подтверждения
    </MPConfirmDialog>
  );
};

export default UserCorpLinkAcceptDialog;
