import { Typography } from '@mui/material';

type ProductFilterItemProps = {
  readonly label: string;
  readonly isActive: boolean;
}

export const ProductFilterItem: React.FCC<ProductFilterItemProps> = ({ label, isActive, children }) => {
  return isActive ? (
    <div>
      <Typography>{label}</Typography>
      {children}
    </div>
  ) : null;
};
