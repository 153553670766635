import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CatalogTabs } from '../components/tabs';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { catalogTabSelector, catalogTabsSelector } from '../store/selectors';

export const CatalogTabsAdapter = () => {
  const handlers = useContextHandlers();

  const tab = useSelector(catalogTabSelector);
  const tabs = useSelector(catalogTabsSelector);

  return useMemo(
    () =>
      tabs?.length && tab ? (
        <CatalogTabs
          tab={tab}
          tabs={tabs}
          onChangeTab={handlers.onChangeTab}
        />
      ) : null,
    [tab, tabs, handlers.onChangeTab]
  );
};
