import { Typography } from '@mui/material';
import { CategoryTreeItem } from 'domain/model/nsi';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { useEffect, useRef } from 'react';
import { Wrapper } from './controls';

type CategorySelectLeafProps = {
  readonly category: CategoryTreeItem;
  readonly selected?: boolean;
  readonly scrollToCategory?: (el: HTMLElement) => void;
  readonly onClick?: (category: CategoryTreeItem) => void;
};

const CategorySelectLeaf = ({ category, selected, scrollToCategory, onClick }: CategorySelectLeafProps) => {
  const selectedRef = useRef<any>(null);

  useEffect(() => {
    if (selectedRef?.current) {
      scrollToCategory?.(selectedRef.current);
    }
  }, [scrollToCategory]);

  return (
    <Wrapper
      underline='none'
      color='text.primary'
      onClick={onClick ? () => onClick(category) : undefined}
    >
      <Typography
        ref={selected ? selectedRef : null}
        component='span'
        color={selected ? 'primary' : 'inherit'}
      >
        {category.name}
      </Typography>
    </Wrapper>
  );
};

export default CategorySelectLeaf;
