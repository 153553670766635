import { AppDispatch } from 'data/store/store';
import { ENoticeStatus } from 'domain/model/enums';
import { addNotice } from 'presentation/features/general/notifier/store/slice';

class Notifier {
  private static instance: Notifier;

  public static getInstance(): Notifier {
    if (!Notifier.instance) {
      Notifier.instance = new Notifier();
    }

    return Notifier.instance;
  }

  private constructor() {}

  dispatch: Nullable<AppDispatch> = null;

  private getDispatch(): Nullable<AppDispatch> {
    return this.dispatch;
  }

  init(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  addNotice(status: ENoticeStatus, text: string) {
    const dispatch = this.getDispatch();

    if (dispatch) {
      dispatch(addNotice({ status, text }));
    }
  }

  addError(text: string) {
    this.addNotice(ENoticeStatus.Error, text);
  }

  addWarning(text: string) {
    this.addNotice(ENoticeStatus.Warning, text);
  }

  addInfo(text: string) {
    this.addNotice(ENoticeStatus.Info, text);
  }

  addSuccess(text: string) {
    this.addNotice(ENoticeStatus.Success, text);
  }
}

export default Notifier;
