import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { CatalogTabInfo, ECatalogTab } from 'presentation/features/catalog/types';

export type CatalogState = {
  readonly tab: Nullable<ECatalogTab>;
  readonly tabs: CatalogTabInfo[];
};

type Reducer<T> = CaseReducer<CatalogState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CatalogState> & {
  catalogSetTab: Reducer<ECatalogTab>;
  catalogSetTabs: Reducer<CatalogTabInfo[]>;
}

const slice = createSlice<CatalogState, Reducers, 'catalog'>({
  name: 'catalog',
  initialState: {
    tab: null,
    tabs: [],
  },
  reducers: {
    catalogSetTab: (state, { payload }) => {
      state.tab = payload;
    },
    catalogSetTabs: (state, { payload }) => {
      state.tabs = payload;
    },
  },
});

export const { catalogSetTab, catalogSetTabs } = slice.actions;

export default slice.reducer;
