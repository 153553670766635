import { Link as MUILink, Typography } from '@mui/material';
import { Location } from 'history';
import { ElementType, HTMLAttributeAnchorTarget } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type HeaderLinkProps = {
  readonly text: string;
  readonly to?: string | Location;
  readonly href?: string;
  readonly active?: boolean;
  readonly component?: ElementType;
  readonly target?: HTMLAttributeAnchorTarget;
  readonly onClick?: () => void;
};

const HeaderLink = ({ text, component = RouterLink, target = '_self', ...rest }: HeaderLinkProps) => {
  return (
    <MUILink
      color='textSecondary'
      variant='body2'
      underline='none'
      target={target}
      component={component}
      {...rest}
    >
      <Typography
        variant='body2'
        noWrap
      >
        {text}
      </Typography>
    </MUILink>
  );
};

export default HeaderLink;
