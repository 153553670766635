import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import OfferClaimLinkToActivations from '../../../../components/claim/linkToActivations';

const AspOfferClaimLinkToActivationsAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();

  if (!aspOffer) {
    return null;
  }

  const isVisible = activation.isLastActivationPending;

  return isVisible ? <OfferClaimLinkToActivations /> : null;
};

export default AspOfferClaimLinkToActivationsAdapter;
