import { locale } from '../../locale';

const normalizeSumParts = (parts: Nullable<Intl.NumberFormatPart[]>): Nullable<Intl.NumberFormatPart[]> =>
  parts?.map(part => ({
    ...part,
    value: part.value.replace(new RegExp(' ', 'g'), '\u2009'),
  })) ?? null; //замена пробела на полупробел*/

const currencyToParts = (n: Nullable<number>, maximumFractionDigits = 0) => {
  if (n === null) {
    return null;
  }

  return Intl.NumberFormat(locale.full, {
    style: 'currency',
    currency: locale.isoCode,
    maximumFractionDigits,
    minimumFractionDigits: 0,
  }).formatToParts(n);
};

export const toRubCurrency = (n: Nullable<number>, maximumFractionDigits = 0) => {
  return (
    normalizeSumParts(currencyToParts(n, maximumFractionDigits))
      ?.map(part => part.value)
      ?.join('')
      ?.trimEnd() ?? null
  );
};

export const toCurrency = (n: Nullable<number>, maximumFractionDigits = 0) => {
  return (
    normalizeSumParts(currencyToParts(n, maximumFractionDigits))
      ?.filter(part => part.type !== 'currency')
      ?.map(part => part.value)
      ?.join('')
      ?.trimEnd() ?? null
  );
};
