import { EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { CorpOfferClaimArchivedMessage } from './archived';
import { CorpOfferClaimPausedMessage } from './paused';
import { CorpOfferClaimPendingInfoMessage } from './pendingInfo';
import { CorpOfferClaimUnavailableMessage } from './unavailableInfo';
import { CorpOfferClaimUpcomingMessage } from './upcomingInfo';
import { CorpOfferClaimRejectedInfoMessage } from './rejectedInfo/rejectedInfo';

export const CorpOfferClaimInfoMessagesAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferClaimContext();

  const isActivationAvailable = activation.isActivationAvailable;

  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return <CorpOfferClaimUnavailableMessage />;
      } else {
        return (
          <>
            {corpOffer.status === EOfferStatus.Upcoming && (
              <CorpOfferClaimUpcomingMessage startDate={corpOffer.startDate} />
            )}

            {corpOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && <CorpOfferClaimPausedMessage />}

            {corpOffer.status === EOfferStatus.Archived && <CorpOfferClaimArchivedMessage />}

            {activation.lastActivation?.status === EOfferActivationStatus.Pending && (
              <CorpOfferClaimPendingInfoMessage
                activationComment={activation.lastActivation?.lastStatusComment || 'Ваша заявка будет рассмотрена '}
              />
            )}

            {!isActivationReceivedNow && activation.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CorpOfferClaimRejectedInfoMessage
                activationComment={activation.lastActivation?.lastStatusComment || 'Предложение отклонено'}
              />
            )}
          </>
        );
      }
    }
  }, [
    activation.lastActivation?.lastStatusComment,
    activation.lastActivation?.status,
    corpOffer,
    isActivationAvailable,
    isActivationReceivedNow,
  ]);
};
