import { FC } from 'react';
import BookingOfferBreadcrumbs from '../../components/breadcrumbs';
import useBookingOfferCategoriesData from '../hooks/useBookingOfferCategoriesData';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';

type BookingOfferBreadcrumbAdapterProps = {
  readonly id: UUID;
};

const BookingOfferBreadcrumbAdapter: FC<BookingOfferBreadcrumbAdapterProps> = props => {
  const { id } = props;
  const { categories } = useBookingOfferCategoriesData();
  const { bookingOffer } = useBookingOfferDetailsData(id);

  return (
    <BookingOfferBreadcrumbs
      offerCategories={bookingOffer?.category ? [bookingOffer?.category] : null}
      dictionary={categories ?? null}
    />
  );
};

export default BookingOfferBreadcrumbAdapter;
