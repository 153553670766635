import { Category } from 'domain/model/nsi';
import * as H from 'history';
import {
  BreadcrumbObject,
  BreadcrumbTree,
  getBreadcrumbChains,
} from 'presentation/components/common/breadcrumb/expanded';

type OfferBreadcrumbsProps = {
  readonly startCategory?: BreadcrumbObject;
  readonly offerCategories: Nullable<Category[]>;
  readonly dictionary: Nullable<Category[]>;
  readonly linkFactory?: (category: Category) => string | H.Location;
};

const OfferBreadcrumbs = (props: OfferBreadcrumbsProps) => {
  const { startCategory, offerCategories, dictionary, linkFactory } = props;

  if (!offerCategories || !dictionary) {
    return null;
  }
  const breadcrumbs: BreadcrumbObject[][] = getBreadcrumbChains(offerCategories, dictionary, linkFactory).map(item => {
    if (startCategory) {
      return [startCategory, ...item];
    } else {
      return item;
    }
  });

  return <BreadcrumbTree items={breadcrumbs} />;
};

export default OfferBreadcrumbs;
