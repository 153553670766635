import { Location } from 'history';
import SearchResultsScreen from 'presentation/screen/search/results';
import { ESearchUrlParam } from 'presentation/types';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';

const root = rootRouting.search;

const routing = {
  results: root,
};

export type SearchResultsLocationState = {
  readonly guid: UUID;
};

type GetSearchResultsRouteProps = {
  readonly guid?: UUID;
  readonly query: Nullable<string>;
};

export const getSearchResultsRoute = (props: GetSearchResultsRouteProps): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.results}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const SearchEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.results}
        component={SearchResultsScreen}
      />
      <Redirect to={routing.results} />
    </Switch>
  );
};

export default SearchEntry;
