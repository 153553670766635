import { SupportIcon } from 'presentation/media/icons';
import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useState } from 'react';
import Option from './option';

const Icon = <SupportIcon />;

const SupportOption = () => {
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);

  return (
    <>
      <Option
        icon={Icon}
        text='Написать в поддержку'
        onClick={() => setFeedbackVisible(true)}
      />

      <AboutFeedbackContainer
        open={feedbackVisible}
        onClose={() => setFeedbackVisible(false)}
      />
    </>
  );
};

export default SupportOption;
