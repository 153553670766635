import { useSelector } from 'react-redux';
import { tradeOfferListNoDataSelector, tradeOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import TradeOffersStub from '../stub/common';

export const TradeOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(tradeOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(tradeOfferListNoDataSelector);

  if (noData) {
    return <TradeOffersStub isEmptySearch={isEmptySearch} />;
  }

  return null;
};
