import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';
import { ExternalTheme } from 'presentation/theme';

const userThemeLogoSelector = (store: RootState) =>
  userApi.endpoints.getCurrentUser.select({})(store).data?.org?.logo?.path;

const userThemeFaviconSelector = (store: RootState) =>
  userApi.endpoints.getCurrentUser.select({})(store).data?.org?.favicon?.path;

const userThemeColorBrandSelector = (store: RootState) =>
  userApi.endpoints.getCurrentUser.select({})(store).data?.org?.colorBrand;

const userThemeColorPrimarySelector = (store: RootState) =>
  userApi.endpoints.getCurrentUser.select({})(store).data?.org?.colorPrimary;

export const userThemeSelector = createSelector(
  userThemeLogoSelector,
  userThemeFaviconSelector,
  userThemeColorBrandSelector,
  userThemeColorPrimarySelector,
  (logo, favicon, colorBrand, colorPrimary): Nullable<ExternalTheme> => {
    let color: ExternalTheme['color'] = colorBrand || colorPrimary ? {} : undefined;
    if (colorBrand) {
      color = { ...color, brand: { main: colorBrand } };
    }
    if (colorPrimary) {
      color = { ...color, primary: { main: colorPrimary } };
    }

    let result: Nullable<ExternalTheme> = null;
    if (color || logo || favicon) {
      result = {
        description: '',
        logo,
        favicon,
        color,
      };
    }

    return result;
  }
);
