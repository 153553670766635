import { OfferDataShort } from 'domain/model';
import { OfferActivation } from 'domain/model/activation';
import { OfferPromotionType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import ActivationListItemAdapter from './adapters/listItem';

type UserActivationsProps = {
  readonly activations: OfferActivation[];
  readonly totalCount: number;
  readonly isFetching: boolean;
  readonly onLoadNextPage: Nullable<() => void>;
  readonly onOfferClick: (offer: OfferDataShort) => void;
  readonly onPartnerLinkClick: (offer: OfferActivation) => void;
  readonly onOpenHelpDialog: (type: OfferPromotionType) => void;
  readonly getActivationCodeTypeName: (type: OfferPromotionType) => string;
};

const UserActivations = (props: UserActivationsProps) => {
  const {
    activations,
    totalCount,
    isFetching,
    onLoadNextPage,
    onOfferClick,
    onPartnerLinkClick,
    onOpenHelpDialog,
    getActivationCodeTypeName,
  } = props;

  return (
    <CardPageableList
      totalCount={totalCount}
      isFetching={isFetching}
      span={6}
      lgSpan={12}
      mdSpan={12}
      smSpan={12}
      onLoadMore={onLoadNextPage}
    >
      {activations.map(activation => (
        <ActivationListItemAdapter
          key={activation.id}
          activation={activation}
          getActivationCodeTypeName={getActivationCodeTypeName}
          onOfferClick={() => onOfferClick(activation.offer)}
          onPartnerLinkClick={() => onPartnerLinkClick(activation)}
          onOpenHelpDialog={onOpenHelpDialog}
        />
      ))}
    </CardPageableList>
  );
};

export default UserActivations;
