import { StompHeaders } from '@stomp/stompjs';

export type UseNotificationSocketProps<T> = {
  readonly onMessageReceive: (msg: T, type: ENotificationMessageType) => void;
};

type CommonNotification = {
  readonly title: string;
  readonly description: string;
};

export type CorpOfferActivateNotification = CommonNotification & {
  type: ENotificationMessageType.OfferActivationChangedMessage;
  offerActivationId: UUID;
};

export type UserBalanceChangeNotification = {
  readonly type: ENotificationMessageType.UserBalanceChange;
};

export type NotificationTypes = CorpOfferActivateNotification | UserBalanceChangeNotification;

export type NotificationMessageCallback = (msg: NotificationTypes, header: StompHeaders) => void;
export type NotificationConnectionFailureCallback = any;

export enum ENotificationMessageType {
  OfferActivationChangedMessage = 'OfferActivationChangedMessage',
  UserBalanceChange = 'UserBalanceChange',
}

export enum ENotificationSocketHeader {
  MessageType = 'Message-Type',
}

export type AppNotificationsReceiverCallbackProps = (msg: NotificationTypes, type: ENotificationMessageType) => void;
