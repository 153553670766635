import { TagDescription } from '@reduxjs/toolkit/query';
import { Pageable } from 'domain/model';
import { api, ECacheServicesTag } from './index';

type CacheResponse = Nullable<Pageable<any>>;

type CacheRequest = {
  readonly guid: string;
  readonly tag?: TagDescription<any>;
  readonly [name: string]: any;
};

export const cacheApi = api.injectEndpoints({
  endpoints: builder => ({
    getCachedData: builder.query<CacheResponse, CacheRequest>({
      queryFn: () => {
        return { data: null };
      },
      providesTags: (result, error, args) => {
        const tags: TagDescription<any>[] = [];
        if (args.tag) {
          tags.push(args.tag);
        }
        result?.data?.forEach(item => tags.push({ type: ECacheServicesTag.Common, id: item.id }));
        return tags;
      },
    }),
  }),
});

export const { useGetCachedDataQuery } = cacheApi;
