import React from 'react';
import { PartnerDesk } from '../../../../../domain/model/partner';
import { PartnerDesksFilter } from '../../types';

export type PartnerDeskListContextType = {
  readonly data: Nullable<PartnerDesk[]>;
  readonly currentFilter: PartnerDesksFilter;
  readonly isEmpty: boolean;
  readonly isFetching: boolean;
  readonly isShowMoreDisplayed: boolean;
  readonly onChangeFilter: (filter: PartnerDesksFilter) => void;
  readonly onLoadMore: () => void;
};

const PartnerDeskListContext = React.createContext<PartnerDeskListContextType>(
  null as unknown as PartnerDeskListContextType
);

export default PartnerDeskListContext;
