import { Link as MUILink } from '@mui/material';
import { BookingOfferShort } from 'domain/model/bookingOffer';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import { ERenderSource } from 'presentation/types';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getBookingOffersDetailsRoute } from '../../routes';
import BookingListItemInfo from './info';
import BookingListItemPrice from './price';

export type BookingOfferListItemProps = {
  readonly offer: BookingOfferShort;
  readonly favorite?: ReactNode;
  readonly direction?: OfferListItemProps['direction'];
  readonly renderFrom?: ERenderSource;
};

const BookingOfferListItem = forwardRef<any, BookingOfferListItemProps>((props, ref) => {
  const {
    offer,
    offer: { id, images, name, tags },
    favorite,
    direction,
    renderFrom,
  } = props;

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getBookingOffersDetailsRoute({ id, from: renderFrom })}
    >
      <OfferListItem
        images={images}
        description={name ?? ''}
        header={offer.displayPriceItem ? <BookingListItemPrice priceItem={offer.displayPriceItem} /> : null}
        favorite={favorite}
        info={<BookingListItemInfo {...offer} />}
        direction={direction}
        tags={tags}
      />
    </MUILink>
  );
});

export default BookingOfferListItem;
