import { ThumbSwiperWrapper } from './controls';
import { Swiper, SwiperProps } from 'swiper/react';
import { FreeMode, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';
import { Swiper as SwiperClass } from 'swiper/types';

type CarouselThumbsProps = {
  readonly swiperProps: SwiperProps;
  readonly onSwiper: (swiper: SwiperClass) => void;
  readonly children: JSX.Element[];
  readonly thumbScrollMode: boolean;
};

const CarouselThumbs = (props: CarouselThumbsProps) => {
  const { onSwiper, swiperProps, children, thumbScrollMode } = props;

  const thumbSwiperScrollModeProps: Partial<SwiperProps> = thumbScrollMode
    ? { direction: 'horizontal', mousewheel: true, scrollbar: true }
    : {};

  return (
    <ThumbSwiperWrapper withScroll={thumbScrollMode}>
      <Swiper
        {...swiperProps}
        {...thumbSwiperScrollModeProps}
        onSwiper={onSwiper}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[...(swiperProps?.modules ?? [FreeMode, Thumbs, Navigation, Pagination, Mousewheel, Scrollbar])]}
      >
        {children}
      </Swiper>
    </ThumbSwiperWrapper>
  );
};

export default CarouselThumbs;
