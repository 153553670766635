import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { isValidElement } from 'react';
import { AnyContentWrapper, ContentWrapper, HeaderWrapper } from './controls';
import { SearchResultsLayoutType } from './types';
import useVisibleSectionLinks from '../../features/sectionLinks/hooks/useVisibleSectionLinks';

const SearchResultsLayout: SearchResultsLayoutType = props => {
  const { toolbar, header, stub, children, loader } = props;

  useVisibleSectionLinks();

  return (
    <>
      {toolbar && <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>}
      <Fade in>
        <DefaultContentWrapper>
          {isValidElement(header) && <HeaderWrapper>{header}</HeaderWrapper>}
          <ContentWrapper>
            <AnyContentWrapper>
              {children}
              {stub}
            </AnyContentWrapper>
          </ContentWrapper>
          {loader}
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default SearchResultsLayout;
