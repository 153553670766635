import useOrdersCounter from 'presentation/features/order/counter/useCounter';
import { getUserOrdersRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemOrders } from '../../components/menuItems';

export const UserMenuOrdersAdapter = () => {
  const history = useHistory();

  const count = useOrdersCounter();

  const onClick = () => {
    history.push(getUserOrdersRoute({}));
  };

  return (
    <UserMenuItemOrders
      count={count}
      onClick={onClick}
    />
  );
};
