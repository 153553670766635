import { Link, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useGetCmsCollectionQuery } from 'data/api/cms';
import { useGetPartnerDeskQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import CmsCollectionContainer from 'presentation/features/cms/collection/container';
import PartnerDeskHeader from 'presentation/features/partnerDesk/components/header';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import CmsCollectionSummaryLayout from './layout';

type CmsCollectionSummaryContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

const Layout = CmsCollectionSummaryLayout;

/**
 * фича полной информации о коллекции: информация о партнёре, состав коллекции
 */
const CmsCollectionSummaryContainer = ({ id, guid }: CmsCollectionSummaryContainerProps) => {
  const history = useHistory();
  const { hasPrevIndependentLocation } = useHistoryExtensions();

  const {
    data: collection,
    isFetching: isCollectionFetching,
    error: collectionFetchError,
  } = useGetCmsCollectionQuery({ guid, id });

  const {
    data: partnerDesk,
    isFetching: isPartnerDeskFetching,
    error: partnerDeskFetchError,
  } = useGetPartnerDeskQuery(collection?.partner?.id ?? '', {
    skip: !collection?.partner?.id,
  });

  const header = useMemo(
    () =>
      partnerDesk && (
        <div>
          <PartnerDeskHeader partnerDesk={partnerDesk} />
          <Splitter size={3} />
        </div>
      ),
    [partnerDesk]
  );

  const StubComponent = useMemo(
    () => (
      <MPGrid
        container
        spacing={2}
        direction='column'
      >
        <MPGrid item>
          <Typography
            variant='h3'
            component='h1'
          >
            К сожалению, предложения больше недоступны
          </Typography>
        </MPGrid>
        {hasPrevIndependentLocation && (
          <MPGrid item>
            <Link
              color='primary'
              underline='none'
              onClick={history.goBack}
            >
              Вернуться назад
            </Link>
          </MPGrid>
        )}
      </MPGrid>
    ),
    [history, hasPrevIndependentLocation]
  );

  const isFetching = isCollectionFetching || isPartnerDeskFetching;

  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
    }
    if (collectionFetchError) {
      ErrorHandler.handleHttpError(collectionFetchError);
    }
  }, [collectionFetchError, partnerDeskFetchError]);

  return (
    <Layout loader={isFetching && <ContentLoader />}>
      <CmsCollectionContainer
        collectionId={id}
        guid={guid}
        header={header}
        stub={StubComponent}
      />
    </Layout>
  );
};

export default CmsCollectionSummaryContainer;
