import ContentLoader from 'presentation/components/common/loader';
import { userProfileIsFetchingSelector } from 'presentation/features/user/details/profile/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const UserProfileLoaderAdapter = () => {
  const isFetching = useSelector(userProfileIsFetchingSelector);

  return useMemo(() => isFetching && <ContentLoader position='fixed' />, [isFetching]);
};
