import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import OfferClaimPriceInfo from '../../../../components/claim/priceInfo';
import { EOfferStatus } from '../../../../../../../domain/model/enums';

export const AspOfferClaimPriceAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }

  const isVisible =
    (aspOffer.status === EOfferStatus.Active || aspOffer.status === EOfferStatus.Upcoming) &&
    !activation.isLastActivationPending;

  if (!isVisible) {
    return null;
  }

  return (
    <OfferClaimPriceInfo
      paymentType={aspOffer.paymentType}
      price={aspOffer.price}
    />
  );
};

