type UseFaviconProps = string;

const useFavicon = (icon: UseFaviconProps) => {
  let link: any = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = icon;
};

export default useFavicon;
