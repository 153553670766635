import LoginContainer from 'presentation/features/auth/login/container';
import ScreenLayout from 'presentation/layouts/screen';

const LoginScreen = () => {
  return (
    <ScreenLayout>
      <LoginContainer />
    </ScreenLayout>
  );
};

export default LoginScreen;
