import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { toRubCurrency } from '../../../../utils/currency';
import CartListItemShort from '../cartItem/short';
import { InfoBlock, ItemWrapper } from './controls';
import { ProductOrder, ProductOrderItem } from 'domain/model/order';

type CartOrderListItemProps = {
  readonly order: ProductOrder;
  readonly onOpenProductDetails: (order: ProductOrderItem) => void;
};

export const CartOrderListItem: React.FCC<CartOrderListItemProps> = ({ order, onOpenProductDetails }) => {
  const ordersAmount = useMemo(() => order.items?.reduce((prev, current) => (current.qty ?? 0) + prev, 0), [order]);

  return (
    <>
      <InfoBlock>
        <Typography variant='h4'>Заказ №{order.number}</Typography>
        <Typography variant='body1'>Партнер: {order.partner.name}</Typography>
        <Typography variant='body1'>Товаров: {ordersAmount}</Typography>
        <Typography variant='subtitle2'>Итого: {toRubCurrency(order.cost)}</Typography>
      </InfoBlock>
      {order.items?.map(item => (
        <ItemWrapper key={item.offer.id}>
          <CartListItemShort
            data={item}
            onOpenProductDetails={() => onOpenProductDetails(item)}
          />
        </ItemWrapper>
      ))}
    </>
  );
};
