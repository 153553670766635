import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { Wrapper } from './controls';

const TradeOfferClaimActivityEndDateAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();
  if (!tradeOffer) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Активно до {moment(tradeOffer.endDate).format(EDateFormat.Human)}</Typography>
    </Wrapper>
  );
};

export default TradeOfferClaimActivityEndDateAdapter;
