import { AuthProvider, observables, OnAuthProviderEvent, OnResetPassword } from '@mp-npm/mp-auth-client';
import { useResetCurrentUserPasswordMutation } from 'data/api/user';
import store from 'data/store/store';
import { SecurityToken } from 'domain/model/user';
import SentryClient from 'integration/sentry/client';
import { getCheckSsoPath, tokenExpiredConfig } from 'presentation/utils/auth';
import { FCC } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppConfigurator } from 'system/appConfigurator';

export const AppAuthProvider: FCC = ({ children }) => {
  const [resetCurrentUserPassword] = useResetCurrentUserPasswordMutation();

  const resetPassword: OnResetPassword = userId => {
    return resetCurrentUserPassword({ id: userId })
      .unwrap()
      .then(() => true)
      .catch(e => {
        console.error(e);
        return false;
      });
  };

  const onAuthEvent: OnAuthProviderEvent = (eventType, error) => {
    /** use kc observers fire here **/
    switch (eventType) {
      // errors
      case 'onInitError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Init error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      case 'onAuthError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Auth error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      // refresh token
      case 'onTokenExpired':
        break;
      // events
      case 'onReady':
        break;
      case 'onAuthLogout':
        break;
      case 'onAuthRefreshSuccess':
        break;
      case 'onAuthSuccess':
        break;
      case 'onAuthRefreshError':
        observables.onAuthRefreshErrorObservableSubject.fire();
        break;
    }
  };

  return (
    <AuthProvider<SecurityToken>
      tokenExpiredConfig={tokenExpiredConfig}
      onResetPassword={resetPassword}
      config={AppConfigurator.getInstance().getApiAuthService()}
      initOptions={{ silentCheckSsoRedirectUri: getCheckSsoPath() }}
      onAuthEvent={onAuthEvent}
    >
      {children}
    </AuthProvider>
  );
};

const AppProviders: FCC = ({ children }) => {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>{children}</BrowserRouter>
    </StoreProvider>
  );
};

export default AppProviders;
