import styled from '@emotion/styled/macro';
import { svgIconClasses, Theme } from '@mui/material';
import { isArray } from 'lodash';
import DatePicker, { Calendar } from 'react-multi-date-picker';

const dayCellSize = '30px';

const CommonStyles = (theme: Theme, value: any) => `
  /* wrapper */

  &.rmdp-wrapper,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker > div {
    width: 100%;
    overflow: auto;
  }

  &.rmdp-wrapper {
    border: none;
  }

  /* header */

  .rmdp-header {
    padding: 0;
    margin: 0;
    margin-bottom: ${theme.spacing(2)};

    .${svgIconClasses.root} {
      cursor: pointer;
    }

    .${svgIconClasses.root}:first-of-type {
      margin-right: 11px;
    }

    .${svgIconClasses.root}:last-of-type {
      margin-left: 11px;
    }

    .rmdp-header-values {
      flex: 1;

      text-align: left;
      font-size: ${theme.typography.subtitle1.fontSize};
      font-weight: ${theme.typography.subtitle1.fontWeight};
      line-height: ${theme.typography.subtitle1.lineHeight};

      //скрываем сепаратор, иначе не получается
      & span:nth-child(2) {
        display: none;
      }
    }
  }

  /* week */

  .rmdp-week {
    overflow: hidden;

    :not(:first-of-type) {
      margin-top: ${theme.spacing(2)};
    }
  }

  .rmdp-week-day {
    width: ${dayCellSize};
    height: auto;

    font-size: ${theme.typography.overline.fontSize};
    font-weight: ${theme.typography.overline.fontWeight};
    line-height: ${theme.typography.overline.lineHeight};

    color: ${theme.palette.secondary.main};
    text-transform: uppercase;
  }

  /* range */

  .rmdp-range {
    box-shadow: none;
    background-color: ${theme.palette.brand.main};

    ::before,
    ::after {
      content: '';

      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;

      background-color: ${theme.palette.brand.main};
    }

    ::before {
      margin-left: -35%;
      width: 95%;
    }

    ::after {
      margin-left: 85%;
      width: 100%;
    }

    &.start {
      ::before {
        display: none;
      }
    }

    &.end {
      ::after {
        display: none;
      }
    }

    ${
      isArray(value) &&
      value.length === 1 &&
      `
    border-radius: 50% !important; 
    box-shadow: none !important;
      ::before, ::after {
        display: none;
      }
    `
    }
  }

  /* day */

  .rmdp-day {
    width: ${dayCellSize};
    height: ${dayCellSize};

    span {
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.typography.body1.fontWeight};
      line-height: ${theme.typography.body1.lineHeight};

      transition: background-color 300ms, color 100ms ease-in;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.rmdp-selected span:not(.highlight) {
      background-color: ${theme.palette.brand.main};
      box-shadow: none;
    }

    &:not(.rmdp-selected, .rmdp-disabled, .rmdp-day-hidden) span:hover {
      background-color: ${theme.palette.brand.main};
      color: ${theme.palette.brand.contrastText};
    }

    &.rmdp-today span {
      background-color: ${theme.palette.primary.A500};
      color: ${theme.palette.text.primary};
    }
    
    &.rmdp-today.rmdp-range span {
      position: relative;
      top: 3px;
      background: none;
      color: ${theme.palette.background.paper};
      
      &:after {
        width: 30px;
        height: 30px;
        position: absolute;
        top: -3px;
        left: 0;
        display: block;
        content: ' ';
        z-index: ${theme.zIndex.drawer};
        background-color: ${theme.palette.background.paper};
        border-radius: 50%;
        opacity: 0.5;
      }
    }

    &.rmdp-deactive,
    &.rmdp-disabled {
      color: ${theme.palette.secondary.main};
    }
    
    &.rmdp-deactive.rmdp-range {
      color: ${theme.palette.background.paper};
    }
  }
`;

export const StyledCalendar = styled(Calendar)`
  ${p => CommonStyles(p.theme, p.value)}
`;

export const StyledPicker = styled(DatePicker)`
  ${p => CommonStyles(p.theme, p.value)}
  &.rmdp-wrapper {
    width: 280px;

    padding: ${p => p.theme.spacing(0.8)};
  }
`;

/** пробы для подсверки при hover
 * из коробки выглядит плохо, потому что календарь дефольно не растягивается больше своего контента,
 * и при hover дырки между датами
 * пока не нужно, поэтому не используем
 */

/*
.rmdp-range {
  + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }
}

.rmdp-range-hover {
  background-color: ${p => p.theme.palette.brand.A500};
  box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500},
    ${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};

  &.start {
    box-shadow: ${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  &.end:first-of-type:not(:nth-of-type(1)) {
    box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  &.end:not(:first-of-type) {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  .rmdp-range-hover:not(.rmdp-range) + .rmdp-range {
    box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }
}*/
