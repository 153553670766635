import { ECatalogTab } from 'presentation/features/catalog/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CatalogProductOffersContainer } from '../productOffers/container';
import { catalogTabSelector } from '../store/selectors';

type CatalogProductOffersTabAdapterProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogProductOffersTabAdapter = (props: CatalogProductOffersTabAdapterProps) => {
  const { selected } = props;

  const tab = useSelector(catalogTabSelector);

  return useMemo(
    () => (tab === ECatalogTab.ProductOffers ? <CatalogProductOffersContainer selected={selected} /> : null),
    [tab, selected]
  );
};
