import ContentLoader from 'presentation/components/common/loader';
import OfferClaimLayout from '../../../../layouts/offerClaimLayout';
import React, { useRef } from 'react';
import { TradeOfferClaimActivationAdapter } from './adapters/activation';
import ActivityEndDate from './adapters/activityEndDate';
import TradeOfferClaimHelpAdapter from './adapters/help';
import { TradeOfferClaimLeftPeriodAdapter } from './adapters/leftPeriod';
import { TradeOfferClaimInfoMessagesAdapter } from './adapters/messages/infoMessages';
import { TradeOfferClaimWarnMessagesAdapter } from './adapters/messages/warnMessages';
import TradeOfferPromotionTypeAdapter from './adapters/obtainingMethod';
import TradeOfferClaimCodeAdapter from './adapters/offerCode';
import { TradeOfferClaimPartnerLinkAdapter } from './adapters/partnerLink';
import { TradeOfferClaimPromotionAdapter } from './adapters/promotion';
import { TradeOfferClaimPromotionNewAdapter } from './adapters/promotionNew';
import TradeOfferClaimTitleAdapter from './adapters/title';
import useTradeOfferClaimContext from './provider/useTradeOfferClaimContext';
import ClaimContentLayout from './layout/content';
import ClaimInfoLayout from './layout/info';
import {
  TradeOfferClaimActivationSlot,
  TradeOfferClaimBottomBarSlot,
  TradeOfferClaimInfoMessagesSlot,
  TradeOfferClaimLeftPeriodSlot,
  TradeOfferClaimNewPromotionSlot,
  TradeOfferClaimPromotionSlot,
  TradeOfferClaimPromotionTypeSlot,
  TradeOfferClaimTitleSlot,
  TradeOfferClaimWarnMessagesSlot,
} from './slots';

const TradeOfferClaim = React.forwardRef(() => {
  const { tradeOffer, isWidget, isFetching } = useTradeOfferClaimContext();
  const ref = useRef<any>();

  return tradeOffer ? (
    <OfferClaimLayout
      ref={ref}
      content={
        <ClaimContentLayout>
          <TradeOfferClaimTitleSlot>
            <TradeOfferClaimTitleAdapter />
          </TradeOfferClaimTitleSlot>
          <TradeOfferClaimPromotionTypeSlot>
            <TradeOfferPromotionTypeAdapter />
          </TradeOfferClaimPromotionTypeSlot>
          <TradeOfferClaimLeftPeriodSlot>
            <TradeOfferClaimLeftPeriodAdapter />
          </TradeOfferClaimLeftPeriodSlot>
          <TradeOfferClaimActivationSlot>
            {isFetching ? <ContentLoader /> : <TradeOfferClaimActivationAdapter />}
          </TradeOfferClaimActivationSlot>
          <TradeOfferClaimPromotionSlot>
            <TradeOfferClaimPromotionAdapter wrapperRef={ref} />
          </TradeOfferClaimPromotionSlot>
          {!isWidget && (
            <>
              <TradeOfferClaimNewPromotionSlot>
                <TradeOfferClaimPromotionNewAdapter />
              </TradeOfferClaimNewPromotionSlot>
              <TradeOfferClaimBottomBarSlot>
                <TradeOfferClaimPartnerLinkAdapter />
              </TradeOfferClaimBottomBarSlot>
            </>
          )}
          <TradeOfferClaimWarnMessagesSlot>
            <TradeOfferClaimWarnMessagesAdapter />
          </TradeOfferClaimWarnMessagesSlot>
          <TradeOfferClaimInfoMessagesSlot>
            <TradeOfferClaimInfoMessagesAdapter />
          </TradeOfferClaimInfoMessagesSlot>
        </ClaimContentLayout>
        // <SlotsGridArea
        //   area={ESlotNameArea.subscriptions}
        //   key={ESlotNameArea.subscriptions}
        // >
        //   <TradeOfferSidebarSubscriptionAdapter />
        // </SlotsGridArea>,
      }
      skirt={
        <ClaimInfoLayout help={<TradeOfferClaimHelpAdapter key='help' />}>
          <TradeOfferClaimCodeAdapter key='code' />
          <ActivityEndDate key='end' />
        </ClaimInfoLayout>
      }
    />
  ) : null;
});

export default TradeOfferClaim;
