import { FC } from 'react';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';
import { BookingDateSlot } from '../../../../../../../../domain/model/order';

type BookingOrderItemSlotMultipleProps = {
  readonly slots: BookingDateSlot[];
};

const BookingOrderItemSlotMultiple: FC<BookingOrderItemSlotMultipleProps> = ({ slots }) => {
  return (
    <>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem
          title='Гостей'
          width={2}
        >
          {slots.map(({ qty, startDate }) => (
            <div key={`${startDate}-${qty}`}>{qty}</div>
          ))}
        </BookingSlotItem>
        <BookingSlotItem
          title='Дата'
          width={10}
        >
          {slots.map(({ startDate }) => (
            <div key={startDate as string}>{moment(startDate).format(EDateFormat.Human)}</div>
          ))}
        </BookingSlotItem>
      </MPGrid>
    </>
  );
};

export default BookingOrderItemSlotMultiple;
