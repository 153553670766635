import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

type UseInputDebounceProps = {
  readonly initialValue: Nullable<string>;
  readonly delay?: number;
  readonly nullIsValidValue?: boolean;
  readonly cancelToken?: Nullable<symbol>;
  readonly onChange: (value: Nullable<string>) => void;
};

type UseInputDebounce = (props: UseInputDebounceProps) => [Nullable<string>, (value: Nullable<string>) => void];

export const useLocalValue = <T>(initialValue: T) => {
  const [localValue, setLocalValue] = useState<T>(initialValue);

  const [prevInitialValue, setPrevInitialValue] = useState(initialValue);
  if (initialValue !== prevInitialValue) {
    setPrevInitialValue(initialValue);
    setLocalValue(initialValue);
  }

  return { localValue, setLocalValue };
};

export const useInputDebounce: UseInputDebounce = props => {
  const { initialValue, delay = 1000, nullIsValidValue = false, cancelToken = null, onChange } = props;

  const { localValue, setLocalValue } = useLocalValue<Nullable<string>>(initialValue);

  const handleChange = useMemo(() => debounce(newValue => onChange(newValue), delay), [delay, onChange]);

  useEffect(() => {
    if (localValue !== initialValue && (localValue || nullIsValidValue)) {
      handleChange(localValue);
    }
  }, [handleChange, initialValue, localValue, nullIsValidValue]);

  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [cancelToken, handleChange]);

  return [localValue, setLocalValue];
};
