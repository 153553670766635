import { Typography } from '@mui/material';

type OfferDetailsOnlyTitleProps = {
  readonly title: string;
};

const OfferDetailsOnlyTitle = (props: OfferDetailsOnlyTitleProps) => {
  const { title } = props;

  return (
    <Typography
      variant='h3'
      component='h1'
    >
      {title}
    </Typography>
  );
};

export default OfferDetailsOnlyTitle;
