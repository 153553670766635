import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    display: flex;
    justify-content: center;

    position: relative;
  `
);
