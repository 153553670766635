import { Typography } from '@mui/material';
import { ETradeOfferPromotionType } from '../../../../../../domain/model/enums';
import { getTradeOfferPromotionLabel } from '../../../utils';

type PromotionTypeTextProps = {
  readonly promotionType: ETradeOfferPromotionType;
};

const PromotionTypeText = ({ promotionType }: PromotionTypeTextProps) => {
  return (
    <Typography
      variant='body2'
      color='black'
    >
      {getTradeOfferPromotionLabel(promotionType)}
    </Typography>
  );
};

export default PromotionTypeText;
