import { createTheme } from '@mui/material';
import { ExternalTheme } from 'presentation/theme/types';
import overrideTheme from './override';
import breakpoints from './override/breakpoints';

const baseTheme = createTheme({
  breakpoints,
});

export const theme = overrideTheme(baseTheme);

export const createAppTheme = (externalTheme?: Nullable<ExternalTheme>) => {
  if (externalTheme) {
    return overrideTheme(baseTheme, externalTheme);
  }
  return theme;
};

console.debug('original theme', theme);
