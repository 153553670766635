import { FC, Fragment } from 'react';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
import { BookingDateSlot } from 'domain/model/order';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';

type BookingOrderItemSlotRangeProps = {
  readonly slots: BookingDateSlot[];
};

const BookingOrderItemSlotRange: FC<BookingOrderItemSlotRangeProps> = ({ slots }) => {
  return slots.map(slot => (
    <Fragment key={`${slot.startDate}-${slot.endDate}`}>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem title='Прибытие'>{moment(slot.startDate).format(EDateFormat.Human)}</BookingSlotItem>
        <BookingSlotItem title='Выезд'>{moment(slot.endDate).format(EDateFormat.Human)}</BookingSlotItem>
      </MPGrid>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem title='Гостей'>{slot.qty}</BookingSlotItem>
      </MPGrid>
    </Fragment>
  ));
};

export default BookingOrderItemSlotRange;
