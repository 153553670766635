import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { Wrapper } from './controls';
import { EOfferStatus } from '../../../../../../../domain/model/enums';

const ActivityEndDateAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();

  if (!aspOffer) {
    return null;
  }

  const isVisible = aspOffer.status === EOfferStatus.Active && !activation.isLastActivationPending;

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Активно до {moment(aspOffer.endDate).format(EDateFormat.Human)}</Typography>
    </Wrapper>
  );
};

export default ActivityEndDateAdapter;
