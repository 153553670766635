import { FCC} from 'react';
import { ControlButtons, FilterList, Wrapper } from './controls';
import { ProductFilterLayoutProps } from './types';

const ProductFilterLayout: FCC<ProductFilterLayoutProps> = props => {
  const { title, actions, children } = props;

  return (
    <Wrapper>
      {title}
      <FilterList>{children}</FilterList>
      {actions && <ControlButtons>{actions}</ControlButtons>}
    </Wrapper>
  );
};

export default ProductFilterLayout;
