import { getAspOfferClaimDialogSelector } from '../../store/selectors';
import RequiredActionUpdateUserPhoneContainer from '../../../../../requiredAction/updateUserPhone/container';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { useSelector } from 'react-redux';

const visibleSelector = getAspOfferClaimDialogSelector('updateUserPhone');

export const AspOfferClaimUpdateUserPhoneDialogAdapter = () => {
  const { onActivate, onCloseDialog } = useAspOfferClaimContext();

  const visible = useSelector(visibleSelector);

  const onClose = () => onCloseDialog('updateUserPhone');

  return (
    visible && (
      <RequiredActionUpdateUserPhoneContainer
        onSuccess={onActivate}
        onClose={onClose}
      />
    )
  );
};
