import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  position: relative;
`;

export const FilterList = styled.div`
  display: grid;
  grid-gap: ${p => p.theme.spacing(4)};
  padding-bottom: ${p => p.theme.spacing(16)};
`;

export const ControlButtons = styled.div`
  width: 100%;

  position: absolute;
  bottom: 0;

  display: grid;
  grid-gap: ${p => p.theme.spacing()};
`;
