import styled from '@emotion/styled/macro';
import { ButtonProps, css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

type StyledButtonProps = {
  readonly active: boolean;
};

export const StyledButton = styled(({ active, ...others }: ButtonProps & StyledButtonProps) => (
  <MPButton {...others} />
))(
  ({ theme, active }) => css`
    min-width: auto;

    border-radius: ${theme.shape.borderRadius as number}px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};

    &:hover {
      background: ${active && theme.palette.success.main};
    }
  `
);
