import useTradeOfferCategoriesData from './useTradeOfferCategoriesData';
import useTradeOfferDetailsData from './useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from './useTradeOfferPartnerDeskData';

type TradeDetailsLoadingStatusProps = {
  readonly id: UUID;
};

type UseTradeOfferDetailsFetchingStatus = boolean;

const useTradeOfferDetailsLoadingStatus = ({
  id,
}: TradeDetailsLoadingStatusProps): UseTradeOfferDetailsFetchingStatus => {
  const { isCategoriesLoading } = useTradeOfferCategoriesData();
  const { isTradeOfferLoading } = useTradeOfferDetailsData(id);
  const { isPartnerDeskLoading } = useTradeOfferPartnerDeskData(id);

  return isCategoriesLoading || isTradeOfferLoading || isPartnerDeskLoading;
};

export default useTradeOfferDetailsLoadingStatus;
