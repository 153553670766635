import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTradeOffersContextHandlers } from '../../../hooks/useTradeOffersContextHandlers';
import {
  partnerDeskDetailsGuidSelector,
  partnerDeskDetailsTradeOffersCommonArgsSelector,
} from '../../../store/selectors';
import PartnerDeskTradeOffersDataContainer from '../dataContainer';

type PartnerDeskTradeOffersCommonAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Common;

const PartnerDeskTradeOffersCommonAdapter = ({ guid }: PartnerDeskTradeOffersCommonAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsTradeOffersCommonArgsSelector);

  const handlers = useTradeOffersContextHandlers();

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskTradeOffersDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangeTradeOffersPage}
          onShowCard={handlers.onShowTradeOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeTradeOffersPage, handlers.onShowTradeOffersCard]
  );
};

export default PartnerDeskTradeOffersCommonAdapter;
