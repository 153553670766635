import styled from '@emotion/styled/macro';
import { Badge, badgeClasses } from '@mui/material';

export const StyledBadge = styled(Badge)`
  display: block;
  top: 0;
  right: 0;

  .${badgeClasses.badge} {
    z-index: 1;
    transform: translate(0px, 0px);
    padding: 0;
  }
`;
