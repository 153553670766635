import ContentLoader from 'presentation/components/common/loader';
import { StyledButton } from './controls';
import { ProductOfferAddToCartButtonProps } from './types';

const ProductOfferAddToCartButton = (props: ProductOfferAddToCartButtonProps) => {
  const { isEmpty, label, isFetching, ...others } = props;

  const LabelComponent = label;

  return (
    <StyledButton
      active={!isEmpty}
      color={isEmpty ? 'primary' : 'success'}
      fullWidth={false}
      {...others}
    >
      <LabelComponent isEmpty={isEmpty} />
      {isFetching && <ContentLoader size='1.5rem' />}
    </StyledButton>
  );
};

export default ProductOfferAddToCartButton;
