import { Typography } from '@mui/material';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { Wrapper } from './controls';

const TradeOfferClaimCodeAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();
  if (!tradeOffer) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Предложение: {tradeOffer.code}</Typography>
    </Wrapper>
  );
};

export default TradeOfferClaimCodeAdapter;
