import styled from '@emotion/styled/macro';
import { css, Fab } from '@mui/material';
import { fabClasses } from '@mui/material';

export const CloseFab = styled(Fab)(
  ({ theme }) => css`
    &.${fabClasses.root} {
      background: ${theme.palette.white.main};
      box-shadow: 0px 4px 20px -20px hsla(220, 13%, 50%, 0.2);
      transition: all ease-in 0.25s;

      &:hover {
        box-shadow: 0px 0px 0px 0px hsla(220, 13%, 50%, 0);
      }
    }
  `
);
