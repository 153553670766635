import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import UserDetailsToolbarLayout from 'presentation/layouts/userDetailsToolbar';
import { ContentWrapper, Wrapper } from './controls';
import { UserActivationsLayoutType } from './types';

const UserActivationsLayout: UserActivationsLayoutType = props => {
  const { header, toolbar, loader, children } = props;

  return (
    <Fade in>
      <Wrapper>
        <Typography variant='h1'>{header}</Typography>
        <Splitter size={3} />

        {toolbar && (
          <UserDetailsToolbarLayout>
            {toolbar}
            <Splitter size={3} />
          </UserDetailsToolbarLayout>
        )}

        <ContentWrapper>{children}</ContentWrapper>
        {loader}
      </Wrapper>
    </Fade>
  );
};

export default UserActivationsLayout;
