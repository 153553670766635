import styled from '@emotion/styled/macro';
import { css, Grid } from '@mui/material';

export const SelectWrapper = styled(Grid)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 1;
    }
  `
);
