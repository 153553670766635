import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBookingOffersContextHandlers } from '../../../hooks/useBookingOffersContextHandlers';
import { partnerDeskDetailsBookingOffersArgsSelector, partnerDeskDetailsGuidSelector } from '../../../store/selectors';
import PartnerDeskBookingOffersDataContainer from '../dataContainer';

type PartnerDeskBookingOffersCommonAdapterProps = {
  readonly guid: UUID;
};

export const PartnerDeskBookingOffersAdapter = ({ guid }: PartnerDeskBookingOffersCommonAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsBookingOffersArgsSelector);

  const handlers = useBookingOffersContextHandlers();

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskBookingOffersDataContainer
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangeBookingOffersPage}
          onShowCard={handlers.onShowBookingOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeBookingOffersPage, handlers.onShowBookingOffersCard]
  );
};
