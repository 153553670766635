import { FC } from 'react';
import { Typography } from '@mui/material';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';

type BookingOfferDetailsTitleAdapterProps = {
  readonly id: UUID;
};

const BookingOfferDetailsTitleAdapter: FC<BookingOfferDetailsTitleAdapterProps> = ({ id }) => {
  const { bookingOffer } = useBookingOfferDetailsData(id);

  if (!bookingOffer) {
    return null;
  }

  return <Typography variant='h1'>{bookingOffer.name}</Typography>;
};

export default BookingOfferDetailsTitleAdapter;
