import { useEmailEditQuery } from '../../../../hooks/useEmailEditQuery';
import ChangeEmailSuccessDialog from '../successDialog';

type UserSuccessEmailUpdateAdapterProps = {
  readonly onNext: () => void;
};

export const UserSuccessEmailUpdateAdapter = (props: UserSuccessEmailUpdateAdapterProps) => {
  const { onNext } = props;

  const [, { data: emailEditData }] = useEmailEditQuery();
  const email = emailEditData?.newEmail ?? '';

  return (
    <ChangeEmailSuccessDialog
      open
      email={email}
      onClose={onNext}
    />
  );
};
