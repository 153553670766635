import { RefObject } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';

type MPAvatarEditorProps = Pick<AvatarEditorProps, 'image' | 'width' | 'height'> & {
  readonly editorRef: RefObject<AvatarEditor>;
};

export const MPAvatarEditor = ({ editorRef, ...props }: MPAvatarEditorProps) => {
  return (
    <AvatarEditor
      ref={editorRef}
      {...props}
    />
  );
};
