import { createSelector } from '@reduxjs/toolkit';
import { partnerApi } from 'data/api/partner';
import { RootState } from 'data/store/store';
import { EProductOfferSortType } from 'domain/model/enums';

export const partnerDeskDetailsGuidSelector = (store: RootState) => store.partnerDesk.details.guid;

export const partnerDeskDetailsStateSelector = (store: RootState) => store.partnerDesk.details;

// trade offers

export const partnerDeskDetailsTradeOffersSortSelector = (store: RootState) =>
  store.partnerDesk.details.tradeOffers.common?.sort ?? null;

export const partnerDeskDetailsTradeOffersCommonArgsSelector = (store: RootState) =>
  store.partnerDesk.details.tradeOffers.common;

export const partnerDeskDetailsTradeOffersUpcomingArgsSelector = (store: RootState) =>
  store.partnerDesk.details.tradeOffers.upcoming;

export const partnerDeskDetailsTradeOffersCommonCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.common;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsTradeOffersUpcomingCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsTradeOffersCountSelector = createSelector(
  partnerDeskDetailsTradeOffersCommonCountSelector,
  partnerDeskDetailsTradeOffersUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const partnerDeskDetailsTradeOffersCommonIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.common;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsTradeOffersUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsTradeOffersIsErrorSelector = createSelector(
  partnerDeskDetailsTradeOffersCommonIsErrorSelector,
  partnerDeskDetailsTradeOffersUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const partnerDeskDetailsTradeOffersCommonIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.common;
  return args ? partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsTradeOffersUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsTradeOffersIsFetchingSelector = createSelector(
  partnerDeskDetailsTradeOffersCommonIsFetchingSelector,
  partnerDeskDetailsTradeOffersUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const partnerDeskDetailsTradeOffersIsNewFetchingSelector = createSelector(
  partnerDeskDetailsTradeOffersIsFetchingSelector,
  (store: RootState) => store.partnerDesk.details.tradeOffers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

// asp offers

export const partnerDeskDetailsAspOffersSortSelector = (store: RootState) =>
  store.partnerDesk.details.aspOffers.common?.sort ?? null;

export const partnerDeskDetailsAspOffersCommonArgsSelector = (store: RootState) =>
  store.partnerDesk.details.aspOffers.common;

export const partnerDeskDetailsAspOffersUpcomingArgsSelector = (store: RootState) =>
  store.partnerDesk.details.aspOffers.upcoming;

export const partnerDeskDetailsAspOffersCommonCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.common;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsAspOffersUpcomingCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsAspOffersCountSelector = createSelector(
  partnerDeskDetailsAspOffersCommonCountSelector,
  partnerDeskDetailsAspOffersUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const partnerDeskDetailsAspOffersCommonIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.common;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsAspOffersUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsAspOffersIsErrorSelector = createSelector(
  partnerDeskDetailsAspOffersCommonIsErrorSelector,
  partnerDeskDetailsAspOffersUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const partnerDeskDetailsAspOffersCommonIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.common;
  return args ? partnerApi.endpoints.getPartnerAspOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsAspOffersUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.aspOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerAspOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsAspOffersIsFetchingSelector = createSelector(
  partnerDeskDetailsAspOffersCommonIsFetchingSelector,
  partnerDeskDetailsAspOffersUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const partnerDeskDetailsAspOffersIsNewFetchingSelector = createSelector(
  partnerDeskDetailsAspOffersIsFetchingSelector,
  (store: RootState) => store.partnerDesk.details.aspOffers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

// corp offers

export const partnerDeskDetailsCorpOffersSortSelector = (store: RootState) =>
  store.partnerDesk.details.corpOffers.common?.sort ?? null;

export const partnerDeskDetailsCorpOffersCommonArgsSelector = (store: RootState) =>
  store.partnerDesk.details.corpOffers.common;

export const partnerDeskDetailsCorpOffersUpcomingArgsSelector = (store: RootState) =>
  store.partnerDesk.details.corpOffers.upcoming;

export const partnerDeskDetailsCorpOffersCommonCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.common;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsCorpOffersUpcomingCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsCorpOffersCountSelector = createSelector(
  partnerDeskDetailsCorpOffersCommonCountSelector,
  partnerDeskDetailsCorpOffersUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const partnerDeskDetailsCorpOffersCommonIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.common;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsCorpOffersUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsCorpOffersIsErrorSelector = createSelector(
  partnerDeskDetailsCorpOffersCommonIsErrorSelector,
  partnerDeskDetailsCorpOffersUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const partnerDeskDetailsCorpOffersCommonIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.common;
  return args ? partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsCorpOffersUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.corpOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsCorpOffersIsFetchingSelector = createSelector(
  partnerDeskDetailsCorpOffersCommonIsFetchingSelector,
  partnerDeskDetailsCorpOffersUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const partnerDeskDetailsCorpOffersIsNewFetchingSelector = createSelector(
  partnerDeskDetailsCorpOffersIsFetchingSelector,
  (store: RootState) => store.partnerDesk.details.corpOffers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

// product offers

export const partnerDeskDetailsProductOffersArgsSelector = (store: RootState) =>
  store.partnerDesk.details.productOffers.common;

export const partnerDeskDetailsProductOffersSortSelector = (store: RootState) =>
  (store.partnerDesk.details.productOffers.common.sort ?? null) as Nullable<EProductOfferSortType[]>;

export const partnerDeskDetailsProductOffersCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.productOffers.common;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerProductOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsProductOffersIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.productOffers.common;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerProductOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsProductOffersIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.productOffers.common;
  return args ? partnerApi.endpoints.getPartnerProductOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsProductOffersIsNewFetchingSelector = createSelector(
  partnerDeskDetailsProductOffersIsFetchingSelector,
  (store: RootState) => store.partnerDesk.details.productOffers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

// booking offers

export const partnerDeskDetailsBookingOffersSortSelector = (store: RootState) =>
  store.partnerDesk.details.bookingOffers.list?.sort ?? null;

export const partnerDeskDetailsBookingOffersArgsSelector = (store: RootState) =>
  store.partnerDesk.details.bookingOffers.list;

export const partnerDeskDetailsBookingOffersCountSelector = (store: RootState) => {
  const args = store.partnerDesk.details.bookingOffers.list;

  if (!args) {
    return 0;
  }

  const { data } = partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const partnerDeskDetailsBookingOffersIsErrorSelector = (store: RootState) => {
  const args = store.partnerDesk.details.bookingOffers.list;

  if (!args) {
    return 0;
  }

  const { isError } = partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store);
  return isError;
};

export const partnerDeskDetailsBookingOffersIsFetchingSelector = (store: RootState) => {
  const args = store.partnerDesk.details.bookingOffers.list;
  return args ? partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store).isLoading : false;
};

export const partnerDeskDetailsBookingOffersIsNewFetchingSelector = createSelector(
  partnerDeskDetailsBookingOffersIsFetchingSelector,
  (store: RootState) => store.partnerDesk.details.bookingOffers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);
