import { configureStore, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { loggerMiddleware } from 'system/middleware';
import { api } from '../api';
import { rootReducer } from './reducers';

const isProductionMode = process.env.NODE_ENV === 'production';
const isTestMode = process.env.NODE_ENV === 'test';
const projectMiddlewares: Middleware[] = !isProductionMode && !isTestMode ? [loggerMiddleware] : [];

// для получения отдельных сторов (полезно для тестов)
export const setupStore = (preloadedState: any) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: gDM => gDM().concat(api.middleware).concat(projectMiddlewares),
    devTools: !isProductionMode && !isTestMode,
  });
};

const store = setupStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppThunkAPIConfig<R = any> = {
  state: RootState;
  rejectValue: R;
};

export default store;

setupListeners(store.dispatch);
