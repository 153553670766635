import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { BookingPriceItem } from 'domain/model/bookingOffer';
import { toRubCurrency } from 'presentation/utils/currency';
import CountInput from 'presentation/components/common/countInput';
import { Ellipsis } from 'presentation/components/common/ellipsis';
import { Content, Price, Wrapper } from './controls';

type ServiceSelectProps = BookingPriceItem & {
  readonly count: number;
  readonly onChange: (isIncrement?: boolean, count?: number) => void;
};

export const ServiceSelect: FC<ServiceSelectProps> = ({ name, price, unit, count, onChange }) => {
  const handleIncrement = (changedCount: number) => {
    onChange(true, changedCount);
  };

  const handleDecrement = (changedCount: number) => {
    onChange(false, changedCount);
  };

  return (
    <Wrapper>
      {name && (
        <Typography
          component={'h3'}
          variant={'body1'}
        >
          {name}
        </Typography>
      )}
      <Content>
        {Number(price) > 0 && (
          <Price>
            <Typography
              variant={'subtitle1'}
              component={'span'}
            >
              {toRubCurrency(price)}
            </Typography>{' '}
            <Ellipsis as='span'>{unit?.name?.toLocaleLowerCase() ?? ''}</Ellipsis>
          </Price>
        )}
        <div>
          {count ? (
            <CountInput
              isInputDisabled
              preserveValueOnChanges
              value={count}
              size={'micro'}
              onDecrement={handleDecrement}
              onIncrement={handleIncrement}
            />
          ) : (
            <Button
              color={'brand'}
              size={'small'}
              fullWidth
              onClick={() => onChange()}
            >
              Добавить
            </Button>
          )}
        </div>
      </Content>
    </Wrapper>
  );
};
