import { Link, LinkProps } from '@mui/material';
import { ExtendButtonBaseTypeMap } from '@mui/material/ButtonBase/ButtonBase';
import { LinkBaseProps, LinkTypeMap } from '@mui/material/Link/Link';
import { OverridableComponent, OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent';
import { getLegalPersonalDataPolicyRoute } from '../../../legal/entry';

type PropsType<M extends OverridableTypeMap> = OverrideProps<LinkTypeMap<M>, 'a'>;

type LinkType<M extends OverridableTypeMap> = ((props: PropsType<M>) => JSX.Element) &
  OverridableComponent<ExtendButtonBaseTypeMap<M>>;

const AboutPrivacyPolicyLinkPublic: LinkType<LinkTypeMap> = ({ children, ...others }: LinkBaseProps & LinkProps) => {
  return (
    <Link
      color='inherit'
      underline='none'
      href={getLegalPersonalDataPolicyRoute()}
      target='_blank'
      {...others}
    >
      {children}
    </Link>
  );
};

export default AboutPrivacyPolicyLinkPublic;
