import { SnackbarContent, SvgIcon, Typography } from '@mui/material';
import { ENoticeStatus } from 'domain/model/enums';
import { CheckIcon, CloseIcon, InfoEmptyIcon, WarningTriangleIcon } from 'presentation/media/icons';
import { forwardRef } from 'react';
import { MPIconButton } from '../../../../theme/ui-kit/button';

const variantIcon: Record<ENoticeStatus, typeof SvgIcon> = {
  [ENoticeStatus.Success]: CheckIcon,
  [ENoticeStatus.Warning]: WarningTriangleIcon,
  [ENoticeStatus.Error]: WarningTriangleIcon,
  [ENoticeStatus.Info]: InfoEmptyIcon,
};

type NoticeItemProps = {
  readonly variant: ENoticeStatus;
  readonly message: string;
  readonly onClose?: () => void;
};

const NoticeItem = forwardRef<any, NoticeItemProps>(({ variant, message, onClose }, ref) => {
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      ref={ref}
      color={variant}
      message={
        <>
          <Icon fontSize='medium' />
          <Typography>{message}</Typography>
        </>
      }
      action={
        onClose && (
          <MPIconButton
            size='small'
            onClick={onClose}
          >
            <CloseIcon
              fontSize='medium'
              color='secondary'
            />
          </MPIconButton>
        )
      }
    />
  );
});

export default NoticeItem;
