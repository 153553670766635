import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    width: 100%;
    max-height: 460px;
    display: grid;
  `
);
