import { Fade } from '@mui/material';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';

type OfferDetailsTabDescriptionProps = {
  readonly description: Nullable<string>;
};

const OfferDetailsTabDescription = ({ description }: OfferDetailsTabDescriptionProps) => {
  return (
    <Fade in>
      <DangerouslyHtmlWrapper>
        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      </DangerouslyHtmlWrapper>
    </Fade>
  );
};

export default OfferDetailsTabDescription;
