import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep7 = () => {
  return (
    <>
      <Text>
        25. Ответственность за нарушение требований законодательства Российской Федерации и нормативных документов ОАО
        «РЖД» в области персональных данных определяется в соответствии с законодательством Российской Федерации.
      </Text>

      <Text>
        26. Политика является общедоступным документом и подлежит опубликованию на официальном сайте ОАО «РЖД» в
        информационно-телекоммуникационной сети «Интернет» по интернет-адресу: https://www.rzd.ru.
      </Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep7;
