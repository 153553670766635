import { useGetUserFavoritesBookingsOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { FC, useEffect } from 'react';
import { TabProps } from './types';
import OfferListItemContainer from '../../../../offer/listItem/container';

export const UserFavoritesBookingsOffersContainer: FC<TabProps> = ({ guid, queryGuid }) => {
  const { data, error } = useGetUserFavoritesBookingsOffersQuery({ guid: queryGuid });
  const offers = data?.data;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  if (!offers) {
    return null;
  }

  return (
    <CardList>
      {offers.map(offer => (
        <OfferListItemContainer
          key={offer.id}
          offer={offer}
          favorite={{
            guid,
            stored: true /*чтобы не скрывался таб после убирания всех сердечек*/,
          }}
        />
      ))}
    </CardList>
  );
};
