import { useState } from 'react';
import DangerouslyHtmlWrapper from '../../../../../components/common/wrappers/dangerouslyHtml';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import OfferConditions from '../../../components/conditions';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';

type CorpOfferConditionsAdapterProps = {
  readonly id: UUID;
};

const CorpOfferConditionsAdapter = ({ id }: CorpOfferConditionsAdapterProps) => {
  const { corpOffer } = useCorpOfferDetailsData(id);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!corpOffer?.conditions) {
    return null;
  }

  return (
    <>
      <OfferConditions
        title='Условия'
        text={corpOffer.conditions}
        onClickExtend={handleOpen}
      />
      <MPConfirmDialog
        title='Условия'
        open={open}
        maxWidth='sm'
        scroll='paper'
        fullScreenBreakpoint='sm'
        buttons={
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={handleClose}
          >
            Понятно
          </MPButton>
        }
        onClose={handleClose}
      >
        <DangerouslyHtmlWrapper>
          <div dangerouslySetInnerHTML={{ __html: corpOffer.conditions }} />
        </DangerouslyHtmlWrapper>
      </MPConfirmDialog>
    </>
  );
};

export default CorpOfferConditionsAdapter;
