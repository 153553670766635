import styled from '@emotion/styled/macro';
import { css, GridProps } from '@mui/material';

type StickyGridProps = GridProps & {
  readonly top: number | string;
};

export const StickyContainer = styled(({ top, color, ...others }: StickyGridProps) => <div {...others} />)(
  ({ theme, top }) => css`
    width: 100%;
    position: sticky;
    top: ${typeof top === 'string' ? top : `${top}px`};

    background-color: ${theme.palette.background.default};

    z-index: 1;
  `
);
