import { Text } from '../../components/controls';

const AboutPublicOfferServiceConditionsInfo = () => {
  return (
    <>
      <Text>
        3.1. Оператор, на основании договоров, заключаемых с Партнерами, размещает на Платформе Карточку предложения
        Партнера.
      </Text>
      <Text>
        3.2. Информация, содержащаяся в карточке, проверяется Оператором доступными средствами, исходя из сведений,
        полученных от Партнера.
      </Text>
      <Text>
        3.3. Информация, содержащаяся в Карточке предложения Партнера, считается достоверной и актуальной в течение
        срока доступности на Платформе.
      </Text>
      <Text>
        3.4. Последующее заключение договоров купли-продажи товаров и (или) договоров оказания услуг между Пользователем
        Платформы и Партнером осуществляется посредством информационно-коммуникационной сети «Интернет» с использованием
        ресурсов Партнера и на стороне Партнера. Права и обязанности по заключенным договорам возникают изменяются и
        прекращаются у Пользователя Платформы и Партнера в соответствии с законодательством Российской Федерации.
      </Text>
    </>
  );
};
export default AboutPublicOfferServiceConditionsInfo;
