import { Theme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AppSvgIcon from '../../../../components/common/icon';
import ContentLoader from '../../../../components/common/loader';
import { MPFab } from '../../../../theme/ui-kit/button';
import OptionBadge from '../badge';
import { HeaderOptionProps } from './types';

type OptionProps = HeaderOptionProps & {
  readonly active: boolean;
};

const Option = ({ to, active, icon, notificationsCount, isLoading }: OptionProps) => {
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <OptionBadge
      badgeContent={notificationsCount}
      showZero={false}
    >
      <MPFab
        color='secondaryLight'
        size='medium'
        variant='squared'
        component={RouterLink}
        to={to}
        disabled={isLoading}
      >
        <AppSvgIcon
          icon={icon}
          fontSize={isLgDown ? 'large' : 'medium'}
          color={active ? 'primary' : 'inherit'}
        />
        {isLoading && <ContentLoader position='absolute' />}
      </MPFab>
    </OptionBadge>
  );
};

export default Option;
