import CurrentUserBalanceContext, {
  CurrentUserBalanceContextType,
} from 'presentation/features/user/current/balance/context';
import { useContext } from 'react';

const useCurrentUserBalance = (): CurrentUserBalanceContextType => {
  return useContext<CurrentUserBalanceContextType>(CurrentUserBalanceContext);
};

export default useCurrentUserBalance;
