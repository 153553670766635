import { Typography } from '@mui/material';
import { ECorpOfferPromotionType } from 'domain/model/enums';
import ClaimMessage from '../../../../components/claim/message';
import { getOfferCodesEndsText } from '../../../../utils';

type CorpOfferClaimCodesEndsMessageProps = {
  readonly promotionType: ECorpOfferPromotionType;
};

export const CorpOfferClaimCodesEndsMessage = (props: CorpOfferClaimCodesEndsMessageProps) => {
  const { promotionType } = props;

  return (
    <ClaimMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        {getOfferCodesEndsText(promotionType)}
      </Typography>
    </ClaimMessage>
  );
};
