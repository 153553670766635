import { MPTopPushDialog } from 'presentation/theme/ui-kit/dialog';
import { ContentWrapper, Wrapper } from './controls';
import { CatalogLayoutType } from './types';

export const CatalogLayout: CatalogLayoutType = props => {
  const { tabs, children, ...dialogProps } = props;

  return (
    <MPTopPushDialog {...dialogProps}>
      <Wrapper>
        {tabs}
        <ContentWrapper>{children}</ContentWrapper>
      </Wrapper>
    </MPTopPushDialog>
  );
};
