import { Bold, Text } from '../../components/controls';

const AboutPersonalDataPolicyStep2 = () => {
  return (
    <>
      <Text>6. В настоящей Политике используются следующие понятия:</Text>
      <Text>
        1) <Bold>автоматизированная обработка персональных данных</Bold> – обработка персональных данных с помощью
        средств вычислительной техники;
      </Text>
      <Text>
        2) <Bold>безопасность персональных данных</Bold> – состояние защищенности персональных данных, характеризуемое
        способностью пользователей, технических средств и информационных технологий обеспечить конфиденциальность,
        целостность и доступность персональных данных при их обработке в информационных системах;
      </Text>
      <Text>
        3) <Bold>биометрические персональные данные</Bold> – сведения, характеризующие физиологические и биологические
        особенности субъекта персональных данных, на основании которых можно установить его личность и которые
        используются оператором для установления личности субъекта персональных данных;
      </Text>
      <Text>
        4) <Bold>информационная система</Bold> – совокупность содержащейся в базах данных информации и обеспечивающих ее
        обработку информационных технологий и технических средств;
      </Text>
      <Text>
        5) <Bold>контрагент</Bold> – российское или иностранное юридическое или физическое лицо, с которым ОАО «РЖД»
        состоит в договорных отношениях или планирует вступить в договорные отношения, за исключением трудовых
        отношений;
      </Text>
      <Text>
        6) <Bold>конфиденциальность персональных данных</Bold> – обязательное для соблюдения требование не раскрывать
        третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не
        предусмотрено законодательством Российской Федерации;
      </Text>
      <Text>
        7) <Bold>материальный носитель</Bold> – бумажный или машинный носитель информации, предназначенный для
        фиксирования, передачи и хранения персональных данных;
      </Text>
      <Text>
        8) <Bold>неавтоматизированная обработка персональных данных</Bold> – обработка персональных данных,
        осуществляемая при непосредственном участии работника без использования средств вычислительной техники;
      </Text>
      <Text>
        9) <Bold>обработка персональных данных</Bold> – любое действие (операция) или совокупность действий (операций),
        совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными,
        включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление (в том
        числе вымарывание), уничтожение персональных данных;
      </Text>
      <Text>
        10) <Bold>оператор</Bold> – государственный орган, муниципальный орган, юридическое или физическое лицо,
        самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных,
        а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке,
        действия (операции), совершаемые с персональными данными;
      </Text>
      <Text>
        11) <Bold>персональные данные</Bold> – любая информация, относящаяся к прямо или косвенно определенному или
        определяемому физическому лицу (субъекту персональных данных);
      </Text>
      <Text>
        12) <Bold>персональные данные, разрешенные субъектом персональных данных для распространения,</Bold> –
        персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем
        дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в
        порядке, предусмотренном Федеральным законом «О персональных данных»;
      </Text>
      <Text>
        13) <Bold>пользователь услуг ОАО «РЖД»</Bold> – пассажир, грузоотправитель, грузополучатель либо иное физическое
        или юридическое лицо, пользующиеся услугами, оказываемыми ОАО «РЖД»;
      </Text>
      <Text>
        14) <Bold>предоставление персональных данных</Bold> – действия, направленные на раскрытие персональных данных
        определенному лицу или определенному кругу лиц;
      </Text>
      <Text>
        15) <Bold>распространение персональных данных</Bold> – действия, направленные на раскрытие персональных данных
        неопределенному кругу лиц;
      </Text>
      <Text>
        16) <Bold>специальные категории персональных данных</Bold> – категории персональных данных, касающиеся расовой,
        национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья,
        интимной жизни и судимости;
      </Text>
      <Text>
        17) <Bold>субъекты персональных данных</Bold> – пользователи услуг, контрагенты, работники ОАО «РЖД», их близкие
        родственники, кандидаты для приема на работу (соискатели), пенсионеры, состоящие на учете в ОАО «РЖД», и их
        официальные представители, а также иные лица, чьи персональные данные стали известны ОАО «РЖД» при осуществлении
        своей деятельности, в том числе в силу предоставления им со стороны ОАО «РЖД» социальных льгот, гарантий и
        компенсаций;
      </Text>
      <Text>
        18) <Bold>трансграничная передача персональных данных</Bold> – передача персональных данных на территорию
        иностранного государства органу власти иностранного государства, иностранному физическому или иностранному
        юридическому лицу;
      </Text>
      <Text>
        19) <Bold>уничтожение персональных данных</Bold> – действия, в результате которых становится невозможным
        восстановить содержание персональных данных в информационной системе или на машинном носителе и (или) в
        результате которых уничтожаются материальные носители персональных данных.
      </Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep2;
