import { Address } from 'domain/model/address';
import CitySelectDialog from 'presentation/components/common/dialogs/citySelect';
import ContentLoader from 'presentation/components/common/loader';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import CityConfirmDialog from './cityConfirmDialog';
import { Wrapper } from './controls';
import { locationDetectorCitySelector } from './store/selectors';
import { locationDetectorCityFetch, locationDetectorClear } from './store/slice';

type LocationDetectorProps = {
  readonly children: ReactNode;
};

const LocationDetector = ({ children }: LocationDetectorProps) => {
  const dispatch = useDispatch();
  const { settings, setCity } = useUserLocalSettings();

  const [cityChangeDialogVisible, setCityChangeDialogVisible] = useState<boolean>(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);

  const { city: localCity } = settings;

  const { data: city, isFetching } = useSelector(locationDetectorCitySelector);

  const openCityChangeDialog = () => {
    setCityChangeDialogVisible(true);
  };

  const closeCityChangeDialog = () => {
    setCityChangeDialogVisible(false);
  };

  const closeCityConfirmDialog = () => {
    setConfirmDialogVisible(false);
  };

  const openCityConfirmDialog = () => {
    setConfirmDialogVisible(true);
  };

  const onChangeCity = useCallback(
    (newCity: Address) => {
      setCity(newCity.id, new AddressHelper(newCity).getLocalitySimpleName());
    },
    [setCity]
  );

  useEffect(() => {
    if (!localCity) {
      const promise = dispatch(locationDetectorCityFetch());
      return () => {
        dispatch(locationDetectorClear());
        promise?.abort();
      };
    }
  }, [dispatch, localCity]);

  useEffect(() => {
    if (city) {
      setCity(city.id, new AddressHelper(city).getLocalitySimpleName());
      openCityConfirmDialog();
    }
  }, [city, setCity]);

  return (
    <>
      {isFetching && (
        <Wrapper>
          <ContentLoader />
        </Wrapper>
      )}
      {localCity && (
        <CityConfirmDialog
          visible={confirmDialogVisible}
          city={localCity?.name}
          onAccept={closeCityConfirmDialog}
          onReject={openCityChangeDialog}
          onClose={closeCityConfirmDialog}
        />
      )}
      <CitySelectDialog
        visible={cityChangeDialogVisible}
        onChangeCity={onChangeCity}
        onClose={closeCityChangeDialog}
      />
      {settings.city && children}
    </>
  );
};

export default LocationDetector;
