import { Typography } from '@mui/material';

type OrderStubProps = {
  alignCenter?: boolean;
};

const OrderStub = (props: OrderStubProps) => {
  const { alignCenter = false } = props;

  return (
    <Typography
      width='100%'
      textAlign={alignCenter ? 'center' : 'left'}
      color='textSecondary'
    >
      Здесь будет храниться история ваших заказов
    </Typography>
  );
};

export default OrderStub;
