import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div``;

export const SearchWrapper = styled.div(
  ({ theme }) => css`
    width: 380px;

    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `
);

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    min-height: ${theme.spacing(3)};

    position: relative;
    display: flex;
    align-items: center;
  `
);
