import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { MPGrid } from 'presentation/theme/ui-kit/grid';

export const OrderLayoutWrapper = styled(MPGrid)(
  ({ theme: { breakpoints } }) => css`
    width: 736px;

    ${breakpoints.down('sm')} {
      width: 100%;
    }
  `
);

export const OrderDetailsWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.white.main};

    ${theme.breakpoints.up('md')} {
      border-radius: ${theme.spacing(2)};
    }
  `
);
