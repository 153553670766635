import styled from '@emotion/styled';
import { typographyClasses } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;

  .${typographyClasses.root} {
    margin-right: 0.25rem;
  }
`;
