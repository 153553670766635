import { UUID } from '../../../../../../domain/model/types';
import { useAuth } from '../../../../auth/provider/useAuth';
import { OfferFavoriteLinkContainer } from '../../../favorite';
import { EOfferType } from '../../../../../../domain/model/enums';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';

type BookingOfferDetailsFavoriteAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

const BookingOfferDetailsFavoriteAdapter = ({ guid, id }: BookingOfferDetailsFavoriteAdapterProps) => {
  const { isAuthenticated } = useAuth();
  const { bookingOffer } = useBookingOfferDetailsData(id);

  if (!bookingOffer || !isAuthenticated) {
    return null;
  }

  return (
    <OfferFavoriteLinkContainer
      guid={guid}
      offerId={id}
      offerType={EOfferType.Booking}
    />
  );
};

export default BookingOfferDetailsFavoriteAdapter;
