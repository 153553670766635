import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import { CalendarProps } from 'react-multi-date-picker';
import { localeRu } from './locale';

export const permanentOptions: Partial<CalendarProps> = {
  weekStartDayIndex: 1,
  headerOrder: ['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON'],
  locale: localeRu,
  monthYearSeparator: ' ',
  renderButton: (direction: 'left' | 'right', handleClick: () => void) => {
    return (
      <>
        {direction === 'left' && (
          <ArrowLeftIcon
            fontSize='large'
            color='primary'
            onClick={handleClick}
          />
        )}
        {direction === 'right' && (
          <ArrowRightIcon
            fontSize='large'
            color='primary'
            onClick={handleClick}
          />
        )}
      </>
    );
  },
};

export const regularOptions: Partial<CalendarProps> = {
  showOtherDays: true,
  shadow: false,
};
