import { Text } from './controls';

const AboutTermsOfUseTermsInfo = () => {
  return (
    <>
      <Text>
        4.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с
        использованием Платформы, в том числе, если такие действия приведут к нарушению прав и законных интересов
        третьих лиц, а также за соблюдение законодательства при использовании Платформы.
      </Text>
      <Text>4.2. При использовании Платформы Пользователь не вправе:</Text>
      <Text>
        4.2.1. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, который
        является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является
        пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или
        дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит
        оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии,
        детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе
        под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ
        или их аналогов, взрывчатых веществ или иного оружия;
      </Text>
      <Text>4.2.2. нарушать права третьих лиц и/или причинять им вред в любой форме;</Text>
      <Text>4.2.3. несанкционированно собирать и хранить персональные данные других лиц;</Text>
      <Text>4.2.4. нарушать нормальную работу Платформы;</Text>
      <Text>
        4.2.5. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;
      </Text>
      <Text>4.2.6. другим образом нарушать нормы законодательства, в том числе нормы международного права.</Text>
    </>
  );
};

export default AboutTermsOfUseTermsInfo;
