import { Text } from './controls';

const AboutTermsOfUseOthersInfo = () => {
  return (
    <>
      <Text>
        9.1. Настоящее Соглашение представляет собой договор между Пользователем и Правообладателем относительно порядка
        использования Платформы и заменяет собой все предыдущие соглашения между Пользователем и Правообладателем.
      </Text>
      <Text>
        9.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
        Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
        Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
        разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского
        права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство»
        понимается как законодательство Российской Федерации, так и законодательство места пребывания Пользователя.
      </Text>
      <Text>
        9.3. Ничто в Соглашении не может пониматься как установление между Пользователем и Правообладателем агентских
        отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то
        иных отношений, прямо не предусмотренных Соглашением.
      </Text>
      <Text>
        9.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
        недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
        применимость остальных положений Соглашения.
      </Text>
      <Text>
        9.5. Бездействие со стороны Правообладателя в случае нарушения Пользователем либо иными пользователями положений
        Соглашений не лишает Правообладателя права предпринять соответствующие действия в защиту своих интересов
        позднее, а также не означает отказа Правообладателя от своих прав в случае совершения в последующем подобных
        либо сходных нарушений.
      </Text>
    </>
  );
};

export default AboutTermsOfUseOthersInfo;
