import { userApi } from 'data/api/user';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppNotificationsReceiverCallbackProps, ENotificationMessageType } from '../../notification/types';
import useNotificationSocket from '../../notification/useNotificationSocket';

const ActivationsNotificationsReceiver = () => {
  const dispatch = useDispatch();

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>(
    msg => {
      if (msg.type === ENotificationMessageType.OfferActivationChangedMessage) {
        // получаем новую активацию, дальше api разберётся что с этим делать внутри себя
        dispatch(
          userApi.endpoints.getUserNewActivation.initiate({
            id: msg.offerActivationId,
          })
        ).unsubscribe();
      }
    },
    [dispatch]
  );

  useNotificationSocket({ onMessageReceive });

  return null;
};

export default ActivationsNotificationsReceiver;
