export enum ETradeOfferClaimSlotNameArea {
  title = 'title',
  promotionType = 'promotionType',
  leftPeriod = 'leftPeriod',
  activation = 'activation',
  promotion = 'promotion',
  newPromotion = 'newPromotion',
  warnMessages = 'warnMessages',
  infoMessages = 'infoMessages',
  bottomBar = 'bottomBar',
  subscriptions = 'subscriptions',
}

export enum ETradeOfferClaimDialogTag {
  Help = 'help',
  Unavailable = 'unavailable',
}
