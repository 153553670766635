import { createPortal } from 'react-dom';
import PreviewSnackbar from './component';

type PreviewSnackbarContainerProps = {
  readonly onClick: () => void;
};

const PreviewSnackbarContainer = (props: PreviewSnackbarContainerProps) => {
  return createPortal(<PreviewSnackbar {...props} />, document.body);
};

export default PreviewSnackbarContainer;
