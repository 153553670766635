import TradeOfferIframe from 'presentation/features/offer/trade/components/iframe';

type TradeOfferClaimWidgetAdapterProps = {
  readonly wrapperRef: any;
  readonly srcDoc: string;
};

export const TradeOfferClaimWidgetAdapter = (props: TradeOfferClaimWidgetAdapterProps) => {
  const { wrapperRef: widgetWrapperRef, srcDoc } = props;

  return (
    <TradeOfferIframe
      wrapperRef={widgetWrapperRef}
      srcDoc={srcDoc}
    />
  );
};
