/**
 * Удаляет из массива значение, возвращает новый массив
 */
export const getNewArrayExcludingValue = (array: any[], value: any) => {
  const index = array.indexOf(value);

  if (index < 0) {
    return array;
  }

  return array.toSpliced(index, 1);
};

/**
 * Typescript-safe .filter(Boolean) - вместо Boolean использовать isDefined
 */
export const isDefined = <T>(value: T | null | undefined): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};
