import { FCC } from 'react';
import { Caption } from './controls';

const CmsCategoryCaption: FCC = ({ children }) => {
  return (
    <Caption
      variant='subtitle1'
      align='center'
    >
      {children}
    </Caption>
  );
};

export default CmsCategoryCaption;
