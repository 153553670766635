import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { UserAgreement } from 'domain/model/agreement';
import Splitter from 'presentation/components/common/splitter';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';
import { DocLink } from './link';

export type SignAgreementsDialogProps = {
  readonly agreements: UserAgreement[];
  readonly acceptContent?: any;
  readonly rejectContent?: any;
  readonly open: boolean;
  readonly isAccepting?: boolean;
  readonly isRejecting?: boolean;
  readonly onAccept: () => void;
  readonly onReject: () => void;
};

const SignAgreementsDialog = (props: SignAgreementsDialogProps) => {
  const {
    agreements,
    acceptContent = 'Принять',
    rejectContent = 'Отклонить',
    open,
    isAccepting,
    isRejecting,
    onAccept,
    onReject,
  } = props;

  return (
    <MPConfirmDialog
      title='Необходимо ваше согласие с документами'
      open={open}
      closeButtonVisible={false}
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            color='brand'
            fullWidth
            size={'large'}
            onClick={onAccept}
            disabled={isAccepting || isRejecting}
          >
            {acceptContent}
            {isAccepting && <ContentLoader />}
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            size={'large'}
            fullWidth
            disabled={isAccepting || isRejecting}
            onClick={onReject}
          >
            {rejectContent}
            {isRejecting && <ContentLoader />}
          </MPButton>
        </ConfirmButtons>
      }
    >
      Пожалуйста, ознакомьтесь с документами и дайте своё согласие, нажав кнопку «Принять»
      {!!agreements.length && (
        <>
          <Splitter size={2} />
          <MPGrid
            container
            spacing={2}
            wrap='nowrap'
            direction='column'
          >
            {agreements.map(a => (
              <MPGrid
                item
                key={a.doc.id}
              >
                <DocLink
                  href={a.doc.url}
                  underline='hover'
                >
                  {a.doc.name}
                </DocLink>
              </MPGrid>
            ))}
          </MPGrid>
        </>
      )}
    </MPConfirmDialog>
  );
};

export default SignAgreementsDialog;
