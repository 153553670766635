import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { Category } from 'domain/model/nsi';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';

type CategoryProps = ApiCancellable & {
  readonly id: UUID;
};

type CategoriesProps = ApiCancellable;

type ProductOfferApi = {
  readonly category: (props: CategoryProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Category>>;
  readonly categories: (props: CategoriesProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Category[]>>;
};

/**
 * АПИ по работе с продуктами
 */
const productOffer: ProductOfferApi = {
  /** Получение категории товаров по id **/
  category: ({ id, signal }) => {
    return {
      url: `/product-categories/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  /** Получение списка категорий товаров **/
  categories: ({ signal }) => {
    return {
      url: '/product-categories',
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default productOffer;
