import { PartnerOfferListRequest, useGetPartnerAspOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { AspOfferShort } from 'domain/model/aspOffer';
import { EOfferListType, ETradeOfferSortType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import {
  partnerDeskDetailsAspOffersIsNewFetchingSelector
} from 'presentation/features/partnerDesk/details/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import OfferListItemContainer from '../../../../offer/listItem/container';

type PartnerDeskAspOffersDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<ETradeOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (aspOffer: AspOfferShort) => void;
};

const PartnerDeskAspOffersDataContainer = (props: PartnerDeskAspOffersDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { isAuthenticated } = useAuth();
  const isNewFetching = useSelector(partnerDeskDetailsAspOffersIsNewFetchingSelector);
  const { page, partnerId } = args;

  const { data, error, isSuccess, isFetching } = useGetPartnerAspOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const aspOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!aspOffers && aspOffers.length > 0;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {aspOffers.map(aspOffer => (
            <InView
              key={aspOffer.id}
              active={isSuccess && !isFetching}
              sessionKey={sessionKey}
              data={aspOffer}
              onShow={onShowCard}
            >
              <OfferListItemContainer
                key={aspOffer.id}
                offer={aspOffer}
                favorite={
                  isAuthenticated && {
                    guid,
                    partnerId,
                  }
                }
              />
            </InView>
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskAspOffersDataContainer;
