import CorpOfferBreadcrumbs from '../../components/breadcrumbs';
import useCorpOfferCategoriesData from '../hooks/useCorpOfferCategoriesData';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';

type CorpOfferBreadcrumbAdapterProps = {
  readonly id: UUID;
}

const CorpOfferBreadcrumbAdapter = (props: CorpOfferBreadcrumbAdapterProps) => {
  const { id } = props;
  const { categories } = useCorpOfferCategoriesData();
  const { corpOffer } = useCorpOfferDetailsData(id);

  return (
    <CorpOfferBreadcrumbs
      offerCategories={corpOffer?.categories ?? null}
      dictionary={categories ?? null}
    />
  );
};

export default CorpOfferBreadcrumbAdapter;
