import { AxiosResponse } from 'axios';
import { aspOfferApi } from 'data/api/aspOffer';
import { ActionConfirmWithCode, Locality, Pageable, UserFeedBackForm } from 'domain/model';
import { OfferActivation } from 'domain/model/activation';
import { Banner } from 'domain/model/banner';
import { CartItem } from 'domain/model/cart';
import { CorpOfferShort } from 'domain/model/corpOffer';
import {
  EBalanceType,
  EBannerPlace,
  EOfferActivationSortType,
  EOfferShortDiscriminator,
  EOfferType,
  EOrderSortType,
  EOrderStatus,
  OfferUnavailabilityReason,
} from 'domain/model/enums';
import { NotificationOption, NotificationSetting } from 'domain/model/notification';
import { OfferFavorite } from 'domain/model/offer';
import { LandingWindow } from 'domain/model/partner';
import { ProductOffer } from 'domain/model/productOffer';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { AccountBalance, User, UserData } from 'domain/model/user';
import { pageableCacheClearByTag } from 'presentation/features/general/pageable/cacheStorage/redux/store/slice';
import { BookingOfferShort } from '../../domain/model/bookingOffer';
import { OrderFull, OrderShort } from '../../domain/model/order';
import apiDefinition from '../openApi';
import { NotificationRequestProps, UpdateProps } from '../openApi/notification';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { RootState } from '../store/store';
import { corpOfferApi } from './corpOffer';
import {
  api,
  EAspOfferServicesTag,
  EBookingOfferServicesTag,
  ECacheServicesTag,
  ECartServicesTag,
  ECorpOfferServicesTag,
  EOrderServicesTag,
  EProductOfferServicesTag,
  ETradeOfferServicesTag,
  EUserServicesTag,
  OfferFavoriteActionRequest,
} from './index';
import {
  createOfferFavoritePatches,
  createUserNewActivationPatches,
  createUserNewActivationTagsToInvalidate,
} from './patches';
import { productApi } from './productOffer';
import { tradeOfferApi } from './tradeOffer';

export type UserBonusesBalanceRequest = {
  readonly userId: UUID;
};

/**
 * Модифицированный ответ запроса избранного
 * ! При добавлении новых типов отредактировать также favoritesCountMap
 */
export type UserFavoritesResponse = {
  [EOfferType.Trade]: UUID[];
  tradeOffersCount: number;
  [EOfferType.Asp]: UUID[];
  aspOffersCount: number;
  [EOfferType.Corp]: UUID[];
  corpOffersCount: number;
  [EOfferType.Product]: UUID[];
  productOffersCount: number;
  [EOfferType.Booking]: UUID[];
  bookingOffersCount: number;
  totalCount: number;
};

// маппинг для патчей при операциях с избранным с карточек
export const favoritesCountMap = {
  [EOfferType.Trade]: 'tradeOffersCount',
  [EOfferType.Asp]: 'aspOffersCount',
  [EOfferType.Corp]: 'corpOffersCount',
  [EOfferType.Product]: 'productOffersCount',
  [EOfferType.Booking]: 'bookingOffersCount',
} as Record<EOfferType, keyof UserFavoritesResponse>;

type UserFavoritesOffersRequest = {
  readonly guid: UUID;
};

export type UserBannersRequest = {
  readonly guid: UUID;
  readonly place: EBannerPlace;
  readonly cityId: Nullable<UUID>;
};

export type CancelOrderRequest = {
  readonly id: UUID;
  readonly cancellationType: { id: UUID };
  readonly comment: string;
};

export type UserOrdersRequest = {
  readonly guid: UUID;
  readonly page: number;
  readonly pageSize: number;
  readonly offerType?: EOfferType[];
  readonly statuses: EOrderStatus[];
};

export type UserActivationRequest = {
  readonly id: UUID;
};

export type UserActivationsRequest = {
  readonly guid: UUID;
  readonly page: number;
  readonly pageSize: number;
};

type GetUserOfferActivationsRequest = {
  readonly id: UUID;
};

export type ActivateUserOfferRequest = {
  readonly id: UUID;
};

export type UserOfferAvailabilityRequest = {
  readonly offerId: UUID;
  readonly reasons?: Nullable<OfferUnavailabilityReason[]>;
};

export type UserOfferSubscriptionRequest = {
  readonly offerId: UUID;
  readonly offerType: EOfferType;
  readonly subscribe: boolean;
};

export type UserSearchOffersRequest = {
  readonly query?: Nullable<string>;
  readonly offerType?: Nullable<EOfferType[]>;
  readonly page: number;
  readonly pageSize: number;
  readonly sort?: Nullable<string[]>;
};

export type ActivateOfferResponse = OfferActivation;

export type UpdateEmailResponse = ActionConfirmWithCode & {
  readonly currentEmail: string;
  readonly newEmail: string;
};

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<User, {}>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: () => apiDefinition.user.current({}),
      providesTags: [EUserServicesTag.Current],
    }),
    getUserBalance: builder.query<AccountBalance, void>({
      transformResponse: (response: AxiosResponse<AccountBalance>): AccountBalance => {
        return {
          ...response.data,
          type: EBalanceType.Bonuses /*todo asp пока нет АСП - захардкожено*/,
        };
      },
      query: () => apiDefinition.user.balance({}),
      providesTags: [EUserServicesTag.Current],
    }),
    getUserBanners: builder.query<Banner[], UserBannersRequest>({
      query: ({ place, cityId }) => {
        return apiDefinition.user.banners({
          localityId: cityId,
          place,
        });
      },
      transformResponse: (response: AxiosResponse) => response.data,
    }),
    getUserFavoritesTradeOffers: builder.query<Pageable<TradeOfferShort>, UserFavoritesOffersRequest>({
      transformResponse: (response: AxiosResponse<TradeOfferShort[]>): Pageable<TradeOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: () => apiDefinition.user.favorites({ offerType: [EOfferType.Trade] }),
      providesTags: [ETradeOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesOffers: builder.query<OfferFavorite[], UserFavoritesOffersRequest>({
      transformResponse: (response: AxiosResponse<OfferFavorite[]>): OfferFavorite[] => response.data,
      query: () => apiDefinition.user.favorites({}),
      providesTags: [EUserServicesTag.Favorites],
    }),
    getUserFavorites: builder.query<UserFavoritesResponse, void>({
      keepUnusedDataFor: 3600,
      transformResponse: (response: AxiosResponse<OfferFavorite[]>): UserFavoritesResponse => {
        const transformedData = {
          [EOfferType.Trade]: [] as UUID[],
          [EOfferType.Asp]: [] as UUID[],
          [EOfferType.Corp]: [] as UUID[],
          [EOfferType.Product]: [] as UUID[],
          [EOfferType.Booking]: [] as UUID[],
        };

        response.data.forEach(({ discriminator, id }) => {
          // Всё избранное без корректного type будет проигнорировано
          switch (discriminator) {
            case EOfferShortDiscriminator.Trade:
              transformedData[EOfferType.Trade].push(id);
              break;
            case EOfferShortDiscriminator.Corp:
              transformedData[EOfferType.Corp].push(id);
              break;
            case EOfferShortDiscriminator.Booking:
              transformedData[EOfferType.Booking].push(id);
              break;
          }
        });

        const totalCount = Object.values(transformedData).reduce((count, value) => count + value.length, 0);

        return {
          ...transformedData,
          tradeOffersCount: transformedData[EOfferType.Trade].length + transformedData[EOfferType.Asp].length,
          aspOffersCount: transformedData[EOfferType.Asp].length,
          corpOffersCount: transformedData[EOfferType.Corp].length,
          productOffersCount: transformedData[EOfferType.Product].length,
          bookingOffersCount: transformedData[EOfferType.Booking].length,
          totalCount,
        };
      },
      query: () => {
        return apiDefinition.user.favorites({});
      },
      providesTags: [EUserServicesTag.FavoritesCount],
    }),
    getUserFavoritesCorpOffers: builder.query<Pageable<CorpOfferShort>, UserFavoritesOffersRequest>({
      transformResponse: (response: AxiosResponse<CorpOfferShort[]>): Pageable<CorpOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: () => apiDefinition.user.favorites({ offerType: [EOfferType.Corp] }),
      providesTags: [ECorpOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesProductOffers: builder.query<Pageable<ProductOffer>, UserFavoritesOffersRequest>({
      transformResponse: (response: AxiosResponse<ProductOffer[]>): Pageable<ProductOffer> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: () => apiDefinition.user.favorites({ offerType: [EOfferType.Product] }),
      providesTags: [EProductOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesBookingsOffers: builder.query<Pageable<BookingOfferShort>, UserFavoritesOffersRequest>({
      transformResponse: (response: AxiosResponse<BookingOfferShort[]>): Pageable<BookingOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: () => apiDefinition.user.favorites({ offerType: [EOfferType.Booking] }),
      providesTags: [EBookingOfferServicesTag.FavoriteList],
    }),
    getUserOrder: builder.query<OrderFull, UUID>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: id => apiDefinition.user.order.one({ id }),
    }),
    getUserOrders: builder.query<Pageable<OrderShort>, UserOrdersRequest>({
      transformResponse: (response: AxiosResponse<OrderShort[]>): Pageable<OrderShort> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ page, pageSize, statuses, offerType }) => {
        return apiDefinition.user.order.all({
          statuses,
          page,
          pageSize,
          offerType,
          sort: [EOrderSortType.ByCreatedAt],
        });
      },
      providesTags: [EOrderServicesTag.Orders],
    }),
    getUserOrdersCount: builder.query<number, void>({
      keepUnusedDataFor: 3600,
      transformResponse: (response: AxiosResponse): number => {
        const pageable = getPageableFromResponseHeaders(response);
        return pageable.totalCount;
      },
      query: () => {
        return apiDefinition.user.order.all({
          page: 1,
          pageSize: 1,
        });
      },
      providesTags: [EUserServicesTag.OrdersCount],
    }),
    createUserOrders: builder.mutation<OrderFull[], CartItem[]>({
      transformResponse: (response: AxiosResponse<OrderFull[]>): OrderFull[] => response.data,
      query: apiDefinition.user.order.create,
      invalidatesTags: [ECartServicesTag.Cart, EOrderServicesTag.Orders, EUserServicesTag.OrdersCount],
    }),
    cancelOrder: builder.mutation<OrderFull, CancelOrderRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: apiDefinition.user.order.cancel,
      invalidatesTags: (result, error, args) => [
        EOrderServicesTag.Orders,
        EUserServicesTag.OrdersCount,
        {
          type: ECacheServicesTag.Common,
          id: args.id,
        },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        //очищаем кэш в redux хранилище для pageable
        dispatch(pageableCacheClearByTag({ tag: EOrderServicesTag.Orders }));
      },
    }),
    getUserNewActivation: builder.query<OfferActivation, UserActivationRequest>({
      transformResponse: (response: AxiosResponse<OfferActivation>): OfferActivation => response.data,
      query: apiDefinition.activation.one,
      async onQueryStarted(args, { getState, dispatch, queryFulfilled }) {
        const { data: newActivation } = await queryFulfilled;
        //инвалидируем нужные тэги
        const tagsToInvalidate = createUserNewActivationTagsToInvalidate({ newActivation });
        await dispatch(userApi.util.invalidateTags(tagsToInvalidate));

        //патчим данные в кэшах
        await createUserNewActivationPatches({
          newActivation,
          state: getState() as RootState,
          dispatch,
        });
      },
    }),
    getUserActivations: builder.query<Pageable<OfferActivation>, UserActivationsRequest>({
      transformResponse: (response: AxiosResponse<OfferActivation[]>): Pageable<OfferActivation> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },

      query: ({ page, pageSize }) =>
        apiDefinition.activation.all({
          page,
          pageSize,
          sort: [EOfferActivationSortType.ByCreatedAt],
        }),
      providesTags: [EUserServicesTag.Activations],
    }),
    getUserActivationsCount: builder.query<number, {}>({
      keepUnusedDataFor: 3600,
      transformResponse: (response: AxiosResponse<OfferActivation[]>): number => {
        const pageable = getPageableFromResponseHeaders(response);
        return pageable.totalCount;
      },
      query: () => apiDefinition.activation.count({}),
      providesTags: [EUserServicesTag.Activations, EUserServicesTag.ActivationsCount],
    }),
    userFeedback: builder.mutation<number, UserFeedBackForm>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: data => apiDefinition.user.feedback({ data }),
    }),
    addUserOfferToFavorites: builder.mutation<number, OfferFavoriteActionRequest>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id }) =>
        apiDefinition.user.addOfferToFavorites({
          id,
        }),
      async onQueryStarted({ id, offerType, ...payload }, { getState, dispatch, queryFulfilled }) {
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: true,
          state: getState() as RootState,
          dispatch,
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      },
    }),
    removeUserOfferFromFavorites: builder.mutation<number, OfferFavoriteActionRequest>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id }) =>
        apiDefinition.user.removeOfferFromFavorites({
          id,
        }),
      async onQueryStarted({ id, offerType, ...payload }, { getState, dispatch, queryFulfilled }) {
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: false,
          state: getState() as RootState,
          dispatch,
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      },
    }),
    updateCurrentUser: builder.mutation<number, { id: UUID; data: UserData }>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id, data }) => apiDefinition.user.update({ id, data }),
      invalidatesTags: [EUserServicesTag.Current],
    }),
    updateCurrentUserEmail: builder.mutation<UpdateEmailResponse, { email: string }>({
      transformResponse: (response: AxiosResponse<UpdateEmailResponse>) => response.data,
      query: ({ email }) => apiDefinition.user.updateEmail({ email }),
    }),
    confirmCode: builder.mutation<number, { otpId: UUID; code: string }>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ code, otpId }) => apiDefinition.user.sendCode({ code, otpId }),
    }),
    bindCurrentUserCorpRole: builder.mutation<number, void>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: () => apiDefinition.user.assignCorpRole(),
    }),
    changeUserLocation: builder.mutation<number, { userId: UUID; location: Locality }>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ userId, location }) => apiDefinition.user.changeLocation({ userId, location }),
      invalidatesTags: [
        EUserServicesTag.Current,
        EUserServicesTag.Location,
        ETradeOfferServicesTag.List,
        ETradeOfferServicesTag.ByPartnerList,
        ETradeOfferServicesTag.Details,
        EAspOfferServicesTag.List,
        EAspOfferServicesTag.ByPartnerList,
        EAspOfferServicesTag.Details,
        ECorpOfferServicesTag.List,
        ECorpOfferServicesTag.ByPartnerList,
        ECorpOfferServicesTag.Details,
        EProductOfferServicesTag.List,
        EProductOfferServicesTag.ByPartnerList,
        EProductOfferServicesTag.Details,
        EBookingOfferServicesTag.List,
        EBookingOfferServicesTag.Details,
      ],
    }),
    resetCurrentUserPassword: builder.mutation<number, { id: UUID }>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id }) => apiDefinition.user.resetPassword({ id }),
      invalidatesTags: [EUserServicesTag.Current],
    }),
    getUserOfferActivations: builder.query<OfferActivation[], GetUserOfferActivationsRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => {
        return apiDefinition.activation.byOfferId({
          page: 1,
          pageSize: 100000,
          sort: [EOfferActivationSortType.ByCreatedAt],
          offerId: id,
        });
      },
      providesTags: [EUserServicesTag.Activations],
    }),
    activateUserOffer: builder.mutation<ActivateOfferResponse, ActivateUserOfferRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => apiDefinition.activation.create({ offerId: id }),
      invalidatesTags: [EUserServicesTag.Activations],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          //очищаем кэш в redux хранилище для pageable
          dispatch(pageableCacheClearByTag({ tag: EUserServicesTag.Activations }));
        } catch (e: any) {
          console.error('Activate offer error', e);
        }
      },
    }),
    getUserOfferAvailability: builder.query<OfferUnavailabilityReason[], UserOfferAvailabilityRequest>({
      transformResponse: (response: AxiosResponse<OfferUnavailabilityReason[]>): OfferUnavailabilityReason[] =>
        response.data,
      query: ({ offerId, reasons }) => {
        return apiDefinition.user.offerAvailability({
          offerId,
          reasons,
        });
      },
      providesTags: [EUserServicesTag.Current, EUserServicesTag.Activations],
    }),
    subscribeToUserOffer: builder.mutation<number, UserOfferSubscriptionRequest>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ offerId, subscribe }) =>
        subscribe
          ? apiDefinition.user.subscribeToOffer({ offerId })
          : apiDefinition.user.unSubscribeFromOffer({ offerId }),
      async onQueryStarted({ offerId: id, offerType, subscribe }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        switch (offerType) {
          case EOfferType.Corp: {
            dispatch(
              corpOfferApi.util.updateQueryData('getCorpOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EOfferType.Trade: {
            dispatch(
              tradeOfferApi.util.updateQueryData('getTradeOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EOfferType.Asp: {
            dispatch(
              aspOfferApi.util.updateQueryData('getAspOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EOfferType.Product: {
            dispatch(
              productApi.util.updateQueryData('getProductOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EOfferType.Booking: {
            // TODO booking
            break;
          }
        }
      },
    }),
    getLandingPage: builder.query<LandingWindow, void>({
      query: () => apiDefinition.user.landing.window({}),
      transformResponse: (response: AxiosResponse) => response.data,
    }),
    getLandingPageTest: builder.query<LandingWindow, void>({
      query: () => apiDefinition.user.landing.windowTest({}),
      transformResponse: (response: AxiosResponse) => response.data,
    }),
    getAllNotifications: builder.query<NotificationOption[], NotificationRequestProps>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ userId, roles }) => apiDefinition.notification.all({ userId, roles }),
    }),
    updateNotification: builder.mutation<NotificationSetting, UpdateProps>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id, option, enabled }) => apiDefinition.notification.update({ id, option, enabled }),
      async onQueryStarted({ option, enabled }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getCurrentUser', {}, draft => {
            const notification = draft.notificationSettings.find(item => item.option.id === option.id);

            if (notification) Object.assign(notification, { enabled });
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUserBalanceQuery,
  useGetUserFavoritesOffersQuery,
  useGetUserFavoritesTradeOffersQuery,
  useGetUserFavoritesCorpOffersQuery,
  useGetUserFavoritesProductOffersQuery,
  useGetUserFavoritesBookingsOffersQuery,
  useGetUserFavoritesQuery,
  useGetUserOrdersQuery,
  useGetUserOrdersCountQuery,
  useGetUserOrderQuery,
  useCreateUserOrdersMutation,
  useGetUserNewActivationQuery,
  useGetUserActivationsQuery,
  useGetUserActivationsCountQuery,
  useAddUserOfferToFavoritesMutation,
  useRemoveUserOfferFromFavoritesMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentUserEmailMutation,
  useBindCurrentUserCorpRoleMutation,
  useGetUserBannersQuery,
  useActivateUserOfferMutation,
  useGetUserOfferActivationsQuery,
  useGetUserOfferAvailabilityQuery,
  useChangeUserLocationMutation,
  useResetCurrentUserPasswordMutation,
  useSubscribeToUserOfferMutation,
  useUserFeedbackMutation,
  useGetLandingPageQuery,
  useGetLandingPageTestQuery,
  useCancelOrderMutation,
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
  useConfirmCodeMutation,
} = userApi;
