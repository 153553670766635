import { ActionWrapper, ContentWrapper, IconWrapper, TextWrapper, Wrapper } from './controls';
import { UserAttributeLayoutType } from './types';

const UserAttributeLayout: UserAttributeLayoutType = props => {
  const { icon, label, action, children } = props;

  const actionNode = action && <ActionWrapper>{action}</ActionWrapper>;

  return (
    <Wrapper>
      <ContentWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper
          secondaryTypographyProps={{ color: 'text.primary', variant: 'body1' }}
          primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
          secondary={children}
        >
          {label}
        </TextWrapper>
        {actionNode}
      </ContentWrapper>
    </Wrapper>
  );
};

export default UserAttributeLayout;
