import { Tab, useMediaQuery } from '@mui/material';
import { PartnerDesk } from 'domain/model/partner';
import { TradeOffer } from 'domain/model/tradeOffer';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabAboutCompany } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { OfferTabs } from '../../../components/tabs/controls';
import { ETradeOfferDetailsTab } from '../utils';

type TradeOfferTabsProps = {
  readonly tab: ETradeOfferDetailsTab;
  readonly onChange: (event: React.SyntheticEvent, newTab: ETradeOfferDetailsTab) => void;
  readonly tradeOffer: TradeOffer;
  readonly partnerDesk: PartnerDesk;
};

const TradeOfferTabs = (props: TradeOfferTabsProps) => {
  const { onChange, tab, partnerDesk, tradeOffer } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      spacing={isMdUp ? 3 : 2}
    >
      <MPGrid
        item
        xs
      >
        <TabsWrapper>
          <OfferTabs
            value={tab}
            textColor='secondary'
            variant='scrollable'
            onChange={onChange}
            scrollButtons={false}
          >
            <Tab
              label='Описание'
              value={ETradeOfferDetailsTab.Description}
            />
            <Tab
              label='Контакты'
              value={ETradeOfferDetailsTab.Contacts}
            />
            <Tab
              label='О компании'
              value={ETradeOfferDetailsTab.AboutCompany}
            />
          </OfferTabs>
        </TabsWrapper>
      </MPGrid>
      <MPGrid
        item
        zero={12}
      >
        {tab === ETradeOfferDetailsTab.Description && (
          <OfferDetailsTabDescription description={tradeOffer.description} />
        )}
        {tab === ETradeOfferDetailsTab.Contacts && <OfferDetailsTabContacts contacts={partnerDesk.contacts} />}
        {tab === ETradeOfferDetailsTab.AboutCompany && (
          <PartnerDeskDetailsTabAboutCompany about={partnerDesk.aboutCompany} />
        )}
      </MPGrid>
    </MPGrid>
  );
};

export default TradeOfferTabs;
