import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type AspOfferDetailsState = {
  readonly guid: Nullable<UUID>;
};

type Reducer<T> = CaseReducer<AspOfferDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AspOfferDetailsState> & {
  aspOfferDetailsStartSession: Reducer<{ guid: UUID }>;
};

const slice = createSlice<AspOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
  },
  reducers: {
    aspOfferDetailsStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
  },
});

export const { aspOfferDetailsStartSession } = slice.actions;

export default slice.reducer;
