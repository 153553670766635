import { Category } from 'domain/model/nsi';
import * as H from 'history';
import { BreadcrumbObject } from './types';

export const breadcrumbTreeExpandTitle = (count: number) => `+ ${count}`;

const getParents = (
  child: Category,
  parents: BreadcrumbObject[],
  dictionary: Category[],
  linkFactory?: (category: Category) => string | H.Location
): BreadcrumbObject[] => {
  const parent = dictionary.find(item => item.id === child.parentId);
  return parent ? getParents(parent, [{ ...parent, link: linkFactory?.(parent) }, ...parents], dictionary) : parents;
};

const compare = (index: number, a: BreadcrumbObject[], b: BreadcrumbObject[]): number => {
  if (index > a.length - 1) return -1;
  const sort = a[index].name.localeCompare(b[index]?.name ?? '');
  return sort === 0 ? compare(++index, a, b) : sort;
};

export const getBreadcrumbChains = (
  categories: Category[],
  dictionary: Category[],
  linkFactory?: (category: Category) => string | H.Location
): BreadcrumbObject[][] => {
  return categories
    .map(category => getParents(category, [{ ...category, link: linkFactory?.(category) }], dictionary, linkFactory))
    .sort((a, b) => compare(0, a, b));
};
