import { useMemo } from 'react';
import ProductFilterContainer from '../../filter/container';
import { useContextHandlers } from '../hooks/useContextHandlers';

const ProductOfferListFilterAdapter = () => {
  const handlers = useContextHandlers();

  return useMemo(() => <ProductFilterContainer onChangeFilter={handlers.onChangeFilter} />, [handlers.onChangeFilter]);
};

export default ProductOfferListFilterAdapter;
