import { useTheme } from '@mui/material';
import { StickyContainer } from 'presentation/components/common/wrappers/sticky';
import { useHeaderParams } from 'presentation/hooks/useHeaderParams';
import { ReactNode } from 'react';
import { Wrapper } from './controls';

type ProductListSidebarProps = {
  readonly children: ReactNode;
};

const ProductListSidebar = ({ children }: ProductListSidebarProps) => {
  const { spacing } = useTheme();
  const { headerHeight } = useHeaderParams();
  return (
    <Wrapper>
      <StickyContainer top={headerHeight + parseInt(spacing(7), 10)}>{children}</StickyContainer>
    </Wrapper>
  );
};

export default ProductListSidebar;
