import React from 'react';
import { SupportInfoType } from 'system/appConfigurator';

export type BusinessConfigContextType = {
  readonly corpEarningsSystemUrl: string;
  readonly corpLinkProxyUrl: string;
  readonly supportInfo: Nullable<SupportInfoType>;
};

const BusinessConfigContext = React.createContext<BusinessConfigContextType>({} as BusinessConfigContextType);

export default BusinessConfigContext;
