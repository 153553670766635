import ProductFilterButton from 'presentation/features/offer/product/filter/button/component';
import ProductFilterDialog from 'presentation/features/offer/product/filter/dialog';
import { useState } from 'react';

const ProductFilterButtonContainer = () => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleClickOpenFilterDialog = () => {
    setDialogVisible(true);
  };

  const handleClickCloseFilterDialog = () => {
    setDialogVisible(false);
  };

  return (
    <>
      <ProductFilterButton onClick={handleClickOpenFilterDialog} />
      <ProductFilterDialog
        open={dialogVisible}
        onClose={handleClickCloseFilterDialog}
      />
    </>
  );
};

export default ProductFilterButtonContainer;
