import { CrownIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemActivationsProps = {
  readonly count?: number;
  readonly onClick?: () => void;
};

export const UserMenuItemActivations: FC<UserMenuItemActivationsProps> = ({ count, onClick }) => {
  return (
    <UserMenuItem
      label='Мои привилегии'
      count={count}
      icon={<CrownIcon color='black' />}
      onClick={onClick}
    />
  );
};
