import { useHistory } from 'react-router';
import { getCorpOfferDetailsRoute } from '../../routes';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from '../hooks/useCorpOfferPartnerDeskData';
import CorpOfferTabs from '../tabs';
import { ECorpOfferDetailsTab } from '../utils';

type CorpOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ECorpOfferDetailsTab;
};

const CorpOfferDetailsTabsAdapter = ({ guid, id, tab }: CorpOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const onChangeTab = (event: React.SyntheticEvent, newTab: ECorpOfferDetailsTab) => {
    history.replace(getCorpOfferDetailsRoute({ id, tab: newTab, guid }));
  };

  const { corpOffer } = useCorpOfferDetailsData(id);
  const { partnerDesk } = useCorpOfferPartnerDeskData(id);

  if (!corpOffer || !partnerDesk) {
    return null;
  }

  return (
    <CorpOfferTabs
      tab={tab}
      corpOffer={corpOffer}
      partnerDesk={partnerDesk}
      onChange={onChangeTab}
    />
  );
};

export default CorpOfferDetailsTabsAdapter;
