import { Fade } from '@mui/material';
import { Wrapper } from './controls';
import { PartnerDeskDetailsWindowLayoutType } from './types';

const PartnerDeskDetailsWindowLayout: PartnerDeskDetailsWindowLayoutType = props => {
  const { children } = props;

  return (
    <Fade in>
      <Wrapper>{children}</Wrapper>
    </Fade>
  );
};

export default PartnerDeskDetailsWindowLayout;
