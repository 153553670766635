import { Container } from '@mui/material';
import { ContentWrapper } from './controls';
import { BannersMainTopLayoutType } from './types';

const BannersMainTopLayout: BannersMainTopLayoutType = props => {
  const { loader, children } = props;

  return (
    <Container>
      <ContentWrapper>
        {children}
        {loader}
      </ContentWrapper>
    </Container>
  );
};

export default BannersMainTopLayout;
