import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getUserOrdersRoute } from '../../../entry';

export type UseOrderDetails = {
  goBack: () => void;
};

const useOrderDetails = (): UseOrderDetails => {
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const goBack = () => gotoPrevIndependentLocation(getUserOrdersRoute({}));

  return {
    goBack,
  };
};

export default useOrderDetails;
