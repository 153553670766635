import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { PriceRangeFilter } from 'domain/model/offer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { parseFiltersFromSearch } from '../utils';

type ProductFilterBehaviorAdapterProps = {
  readonly onChangeFilter: (filter: PriceRangeFilter) => void;
};

const ProductFilterBehaviorAdapter = (props: ProductFilterBehaviorAdapterProps) => {
  const { onChangeFilter } = props;

  const location = useLocation();
  const { onSetFilter } = useContextHandlers();

  useEffect(() => {
    const filter = parseFiltersFromSearch(location.search);
    onSetFilter(filter);
    onChangeFilter(filter);
  }, [location.search, onSetFilter, onChangeFilter]);

  return null;
};

export default ProductFilterBehaviorAdapter;
