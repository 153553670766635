import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const accordion: OverrideThemeFunc<Components> = baseTheme => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        marginBottom: baseTheme.spacing(1.5),
        ':before': {
          display: 'none',
        },
      },
      rounded: {
        background: baseTheme.palette.secondary.A400,
        borderRadius: `${baseTheme.shape.borderRadius}px`,
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        background: baseTheme.palette.secondary.A400,
        minHeight: '40px',
        borderRadius: `${baseTheme.shape.borderRadius}px`,
      },
      content: {
        margin: 0,
      },
    },
  },
});

export default accordion;
