import { useUpdateCurrentUserMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { User, UserData } from 'domain/model/user';
import { UserEditCommonAttributes } from 'presentation/features/user/utils';
import { userProfileValidation } from 'presentation/features/user/validation';
import useValidation from 'presentation/hooks/validation/useValidation';
import { ValidationResult } from 'presentation/utils/validation';
import { useEffect, useMemo, useState } from 'react';

type UseUserProfileAttributeEditProps = {
  readonly user: User;
};

type UseUserProfileAttributeEdit = {
  readonly values: UserEditCommonAttributes;
  readonly isUpdating: boolean;
  readonly validation: Nullable<ValidationResult<UserEditCommonAttributes>>;
  readonly update: () => Promise<boolean>;
  readonly reset: () => void;
  readonly onChange: <A extends keyof UserEditCommonAttributes>(
    attribute: A,
    value: UserEditCommonAttributes[A]
  ) => void;
};

const getValue = (user: User): UserEditCommonAttributes => ({
  firstName: user.firstName,
  lastName: user.lastName,
  middleName: user.middleName,
  birthDate: user.birthDate,
  photo: user.photo,
  gender: user.gender,
});

const validationRules = {
  firstName: userProfileValidation.firstName,
  lastName: userProfileValidation.lastName,
  middleName: userProfileValidation.middleName,
  birthDate: userProfileValidation.birthDate,
  photo: userProfileValidation.photo,
  gender: userProfileValidation.gender,
};

export const useUserProfileCommonAttributesEdit = (
  props: UseUserProfileAttributeEditProps
): UseUserProfileAttributeEdit => {
  const { user } = props;

  const [values, setValues] = useState<UserEditCommonAttributes>(() => getValue(user));

  const [
    updateCurrentUser,
    { error: updateCurrentUserError, isSuccess: isUpdated, isLoading: isUpdating, reset: updateCurrentUserReset },
  ] = useUpdateCurrentUserMutation();

  const data: UserData = useMemo(() => ({ ...user, ...values }), [user, values]);

  const {
    validate,
    validationResult: validation,
    resetValidationResult,
  } = useValidation({
    object: data,
    rules: validationRules,
  });

  const update = () => {
    const isValid = validate();
    if (isValid) {
      return updateCurrentUser({ id: user.id, data })
        .unwrap()
        .then(result => result === 200);
    }
    return Promise.resolve(false);
  };

  const reset = () => {
    resetValidationResult();
    setValues(getValue(user));
  };

  const onChange = <A extends keyof UserEditCommonAttributes>(attribute: A, value: UserEditCommonAttributes[A]) => {
    setValues(prev => ({ ...prev, [attribute]: value }));
  };

  useEffect(() => {
    setValues(getValue(user));
  }, [user]);

  useEffect(() => {
    if (isUpdated) {
      updateCurrentUserReset();
    }
  }, [isUpdated, updateCurrentUserReset]);

  useEffect(() => {
    if (updateCurrentUserError) {
      ErrorHandler.handleHttpError(updateCurrentUserError);
    }
  }, [updateCurrentUserError]);

  return {
    values,
    isUpdating,
    validation,
    update,
    reset,
    onChange,
  };
};
