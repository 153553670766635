import { BreadcrumbTree } from 'presentation/components/common/breadcrumb/expanded';
import { getBreadcrumbChains } from 'presentation/components/common/breadcrumb/expanded/utils';
import useProductOfferDetailsCategoriesData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsCategoriesData';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { EAppCategories } from 'presentation/types';

type ProductOfferDetailsBreadcrumbsAdapterProps = {
  readonly id: UUID;
};

const ProductOfferDetailsBreadcrumbsAdapter = ({ id }: ProductOfferDetailsBreadcrumbsAdapterProps) => {
  const { productOffer } = useProductOfferDetailsData(id);
  const { categories, isLoading } = useProductOfferDetailsCategoriesData(id);

  /*const onBreadcrumbClick: OnBreadcrumbClick = id => {
    typeof id === 'string' && history.push(getProductOffersSearchRoute({ categoryId: id }));
  };*/

  const breadcrumbsData = getBreadcrumbChains(productOffer?.categoryList ?? [], categories ?? []);

  if (isLoading) {
    return null;
  }

  return (
    <BreadcrumbTree
      items={breadcrumbsData}
      title={EAppCategories.ProductOffers}
    />
  );
};

export default ProductOfferDetailsBreadcrumbsAdapter;
