import StubCommon from 'presentation/components/common/stub/common';

type ProductOffersStubProps = {
  readonly isEmptySearch: boolean;
};

const ProductOffersStub = (props: ProductOffersStubProps) => {
  const { isEmptySearch } = props;

  return isEmptySearch ? (
    <StubCommon
      title='К&nbsp;сожалению, в&nbsp;вашем городе нет&nbsp;предложений по&nbsp;товарам.'
      text='Но мы постараемся исправить эту ситуацию'
    />
  ) : (
    <StubCommon
      title='По вашему запросу ничего не найдено'
      text='Попробуйте сократить запрос или задать его по-другому'
    />
  );
};

export default ProductOffersStub;
