import { Link as MUILink, Typography, useMediaQuery } from '@mui/material';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { ERenderSource } from 'presentation/types';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MPChip } from '../../../../../theme/ui-kit/chip';
import OfferListItem, { OfferListItemProps } from '../../../components/listItem';
import { getTradeOfferDetailsRoute } from '../../routes';
import TradeOfferListItemInfo from './info';

export type TradeOfferListItemProps = {
  readonly offer: Pick<TradeOfferShort, 'id' | 'name' | 'title' | 'images' | 'partner' | 'tags'>;
  readonly direction?: OfferListItemProps['direction'];
  readonly favorite?: ReactNode;
  readonly renderFrom?: ERenderSource;
};

const TradeOfferListItem = forwardRef<any, TradeOfferListItemProps>((props, ref) => {
  const { offer, direction, favorite, renderFrom } = props;
  const { id, images, name, tags } = offer;
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getTradeOfferDetailsRoute({ id, from: renderFrom })}
    >
      <OfferListItem
        images={images}
        description={name}
        header={
          <MPChip
            size='micro'
            label={<Typography variant={isSmUp ? 'body2' : 'caption'}>{offer.title}</Typography>}
            color='warning'
          />
        }
        favorite={favorite}
        info={<TradeOfferListItemInfo {...offer} />}
        direction={direction}
        tags={tags}
      />
    </MUILink>
  );
});

TradeOfferListItem.displayName = 'TradeOfferListItem';

export default TradeOfferListItem;
