import { Typography } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';

type ClaimTitleProps = {
  readonly text: string;
};

const ClaimTitle = ({ text }: ClaimTitleProps) => {
  return (
    <MPChip
      size='micro'
      label={
        <Typography
          fontWeight={500}
          variant='body2'
        >
          {text}
        </Typography>
      }
      color='warning'
    />
  );
};

export default ClaimTitle;
