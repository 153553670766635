import * as React from 'react';
import { StyledAvatar } from './controls';

export type MPAvatarSize = 'micro' | 'small' | 'default' | 'large' | 'giant' | 'largest' | 'inherit';
export type MPAvatarVariant = 'circular' | 'rounded' | 'square';

type MPAvatarProps = {
  readonly url?: Nullable<string>;
  readonly autosize?: boolean;
  readonly selected?: boolean;
  readonly description?: string;
  readonly size?: MPAvatarSize;
  readonly variant?: MPAvatarVariant;
  readonly children?: React.ReactNode | false;
};

export const MPAvatar = (props: MPAvatarProps) => {
  const { url, autosize = true, selected = false, description, size = 'default', variant = 'square', children } = props;

  return (
    <StyledAvatar
      size={size}
      autosize={autosize}
      selected={selected}
      alt={description}
      src={url ?? undefined}
      variant={variant}
    >
      {children}
    </StyledAvatar>
  );
};
