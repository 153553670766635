import { useSelector } from 'react-redux';
import {
  productOfferListCommonDataCountSelector,
  productOfferListCommonIsErrorSelector,
  productOfferListCommonIsFetchingSelector,
  productOfferListHasSomeFilters,
  productOfferListSearchIsEmptySelector,
} from '../store/selectors';
import ProductOffersStub from '../stub/common';

const ProductOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(productOfferListSearchIsEmptySelector);
  const count = useSelector(productOfferListCommonDataCountSelector);
  const isSomeFilters = useSelector(productOfferListHasSomeFilters);
  const isFetching = useSelector(productOfferListCommonIsFetchingSelector);
  const isError = useSelector(productOfferListCommonIsErrorSelector);

  if (count === 0 && !isFetching && !isSomeFilters && !isError) {
    return <ProductOffersStub isEmptySearch={isEmptySearch} />;
  }

  return null;
};

export default ProductOfferListStubAdapter;
