import styled from '@emotion/styled/macro';
import { buttonClasses, ButtonProps, css, svgIconClasses } from '@mui/material';
import DottedButton from '../../../../../../components/common/buttons/dotted';

type StyledButtonProps = ButtonProps & {
  readonly fetching: boolean;
};

export const StyledDottedButton = styled(({ disabled, fetching, ...others }: StyledButtonProps) => (
  <DottedButton
    disabled={disabled}
    {...others}
  />
))(
  ({ theme, disabled, fetching }) => css`
    &.${buttonClasses.root} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      background-color: ${disabled ? theme.palette.secondary.A600 : theme.palette.primary.A500};

      padding: 0.75rem 1.25rem;

      ${disabled && !fetching && 'border-color: transparent;'}
      ${disabled && `border-color: ${theme.palette.secondary.A900};'`}
      ${theme.breakpoints.down('sm')} {
        padding: 0.75rem 1.25rem;
      }
    }

    .${svgIconClasses.root} {
      margin-left: ${theme.spacing()};
    }
  `
);
