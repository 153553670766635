import { combineReducers } from '@reduxjs/toolkit';
import previewMode, { PreviewModeState } from 'presentation/screen/cms/store/slice';

type CmsState = {
  previewMode: PreviewModeState;
};

export default combineReducers<CmsState>({
  previewMode,
});
