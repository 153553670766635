import { Typography, TypographyProps } from '@mui/material';
import { FCC } from 'react';

const OffersTextHeader: FCC<Pick<TypographyProps, 'variant' | 'mb'>> = props => {
  return (
    <Typography
      variant='h1'
      {...props}
    />
  );
};

export default OffersTextHeader;
