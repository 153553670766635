import { Link } from '@mui/material';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import { MPButton } from 'presentation/theme/ui-kit/button';

type CorpOfferClaimExtCertificateActivationProps = {
  readonly url: Nullable<string>;
  readonly disabled: boolean;
  readonly onActivate: () => void;
};

export const CorpOfferClaimExtCertificateActivation = (props: CorpOfferClaimExtCertificateActivationProps) => {
  const { url, onActivate, disabled } = props;

  return (
    <CorpLinkProxy link={url ?? ''}>
      {linkByProxy => (
        <MPButton
          fullWidth
          color='brand'
          href={linkByProxy}
          component={Link}
          disabled={disabled}
          target='_blank'
          onClick={onActivate}
        >
          Обменять на Сервисном портале
        </MPButton>
      )}
    </CorpLinkProxy>
  );
};
