import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep3 = () => {
  return (
    <>
      <Text>
        7. Обработка персональных данных в ОАО «РЖД» осуществляется с соблюдением следующих принципов и правил:
      </Text>
      <Text>1) обработка осуществляется на законной и справедливой основе;</Text>

      <Text>2) обработка ограничивается достижением конкретных, заранее определенных и законных целей;</Text>

      <Text>
        3) обработке подлежат персональные данные, отвечающие целям обработки, при обязательном соответствии их объема и
        содержания заявленным целям обработки;
      </Text>

      <Text>
        4) не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в
        целях, несовместимых между собой;
      </Text>

      <Text>
        5) при обработке обеспечиваются точность и достаточность персональных данных и, при необходимости, актуальность
        по отношению к целям обработки с принятием мер по удалению или уточнению неполных или неточных данных либо
        обеспечением принятия таких мер;
      </Text>

      <Text>
        6) хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не
        дольше, чем того требуют цели обработки персональных данных, если срок хранения персональных данных не
        установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому
        является субъект персональных данных;
      </Text>

      <Text>
        7) обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в
        случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством Российской
        Федерации.
      </Text>

      <Text>8. При обработке персональных данных ОАО «РЖД» обеспечивает их конфиденциальность и безопасность.</Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep3;
