import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import UserCorpLinkAcceptDialog from 'presentation/features/user/components/corpLinkAcceptDialog/component';
import { EUserProfileContainerDialogTag } from 'presentation/features/user/details/profile/utils';
import useDialogInHistory from 'presentation/hooks/useDialogInHistory';
import useUserBindCorpRole from 'presentation/hooks/useUserBindCorpRole';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useEffect } from 'react';

export const UserProfileCorpLinkAdapter = () => {
  const { isCorpUser, bindRzhdProfile } = useAuthUser();
  const { refreshToken } = useAuthToken();

  const {
    isFetching: bindCorpRoleIsFetching,
    error: bindCorpRoleError,
    onBindCorpRole,
  } = useUserBindCorpRole({
    refreshToken,
    bindRzhdProfile,
  });

  const {
    open: isAcceptUserLinkDialogOpened,
    onOpen: onOpenAcceptUserLinkDialog,
    onClose: onCloseAcceptUserLinkDialog,
  } = useDialogInHistory({ tag: EUserProfileContainerDialogTag.AcceptUserLink });

  const onUserLinkAccept = () => {
    onCloseAcceptUserLinkDialog();
    onBindCorpRole();
  };

  const openUserLinkAcceptDialog = () => {
    onOpenAcceptUserLinkDialog();
  };

  useEffect(() => {
    if (bindCorpRoleError) {
      ErrorHandler.handleHttpError(bindCorpRoleError);
    }
  }, [bindCorpRoleError]);

  return (
    <>
      {!isCorpUser && (
        <MPButton
          fullWidth
          size='small'
          color='brand'
          disabled={bindCorpRoleIsFetching}
          onClick={openUserLinkAcceptDialog}
        >
          Подтвердить, что я являюсь работником ОАО «РЖД»
          {bindCorpRoleIsFetching && <ContentLoader size={15} />}
        </MPButton>
      )}
      <UserCorpLinkAcceptDialog
        open={isAcceptUserLinkDialogOpened}
        onAccept={onUserLinkAccept}
        onClose={onCloseAcceptUserLinkDialog}
      />
    </>
  );
};
