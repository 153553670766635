import { ContainerProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Wrapper } from './controls';

type DefaultContentWrapperProps = ContainerProps & {
  readonly children: ReactNode;
  readonly className?: string;
  readonly style?: any;
};

const DefaultContentWrapper = forwardRef<any, DefaultContentWrapperProps>(
  ({ children, className, style, ...others }, ref) => {
    return (
      <Wrapper
        ref={ref}
        className={className}
        style={style}
        {...others}
      >
        {children}
      </Wrapper>
    );
  }
);

export default DefaultContentWrapper;
