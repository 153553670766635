import { PhoneIcon } from 'presentation/media/icons';
import { phoneFormatView } from 'presentation/utils/phone';
import { FC, useState } from 'react';
import { UserProfileEditAttributePhone } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { useUserProfileAttributeEdit } from '../hooks/useAttributeEdit';
import { UserProfileDataAdapterProps } from './';

export const UserProfilePhoneAdapter: FC<UserProfileDataAdapterProps> = ({ user }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { value, validation, isUpdating, update, reset, onChange } = useUserProfileAttributeEdit({
    attribute: 'phone',
    user,
    initialValue: '',
  });

  const showEdit = () => setEditMode(true);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const updateInternal = () => {
    update().then(result => result && hideEdit());
  };

  return (
    <>
      <UserProfileViewAttribute
        label='Телефон'
        value={phoneFormatView(user.phone ?? '-')}
        icon={<PhoneIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributePhone
          label='Изменение телефона'
          value={value}
          validation={validation?.phone}
          isFetching={isUpdating}
          onChange={onChange}
          onClose={hideEdit}
          onSave={updateInternal}
        />
      )}
    </>
  );
};
