import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const CommonOptionsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 20px 30px -20px hsla(220, 13%, 50%, 0.35);
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(3)};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(1.5, 2, 3)};
      border-radius: 0 0 ${theme.spacing(2, 2)};
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4)};
      border-radius: 0 0 ${theme.spacing(2, 2)};
    }
  `
);

export const OptionsWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    justify-content: space-around;
    gap: ${spacing(2)};
  `
);
