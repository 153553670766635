import styled from '@emotion/styled/macro';
import { css, Grid, Typography } from '@mui/material';

export const LoadMoreWrapper = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spacing(1.5)};
    padding-bottom: ${theme.spacing(1.5)};
  `
);

export const Content = styled.div(
  ({ theme }) => css`
    margin: auto;

    ${theme.breakpoints.up('md')} {
      max-width: 944px;
    }
  `
);

export const OrderList = styled(Grid)(
  ({ theme }) => css`
    > div > div {
      -moz-outline-radius: ${(theme.shape.borderRadius as number) * 2}px;
      outline: 2px solid transparent;
      outline-offset: -2px;
      transition: outline-color ${theme.transitions.duration?.standard}ms;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        outline-color: ${theme.palette.text.primary};
      }
    }
  `
);

export const Bold = styled(Typography)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
  `
);
