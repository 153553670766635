import { Text } from './controls';

const AboutCookiesHowDoWeProcessInfo = () => {
  return (
    <>
      <Text>
        Веб-сайт обрабатывает сведения, собираемые посредством метрических программ, таких как Яндекс.Метрика Третьи
        лица не имеют доступа к Cookies веб-сайта. Другие сервисы (например, Microsoft, Google, Yandex и т.п.), которые
        собирают Cookie, включая ваш браузер, имеют собственные политики использования Cookie.
      </Text>
    </>
  );
};
export default AboutCookiesHowDoWeProcessInfo;
