import { FCC } from 'react';
import AppSvgIcon from '../../../../../components/common/icon';
import { ClockIcon, WarningTriangleIcon } from '../../../../../media/icons';
import { InfoWrapper, WithIconWrapper, WithIconWrapperContent } from './controls';

type ClaimMessageProps = {
  type: 'warn' | 'info' | 'waiting';
};

const WarnContainer: FCC = ({ children }) => {
  return (
    <WithIconWrapper>
      <AppSvgIcon
        fontSize='small'
        color='warning'
        icon={WarningTriangleIcon}
      />
      <WithIconWrapperContent>{children}</WithIconWrapperContent>
    </WithIconWrapper>
  );
};

const WaitingContainer: FCC = ({ children }) => {
  return (
    <WithIconWrapper>
      <AppSvgIcon
        fontSize='micro'
        color='success'
        icon={ClockIcon}
      />
      <WithIconWrapperContent>{children}</WithIconWrapperContent>
    </WithIconWrapper>
  );
};

const InfoContainer: FCC = ({ children }) => {
  return <InfoWrapper>{children}</InfoWrapper>;
};

const ClaimMessage: FCC<ClaimMessageProps> = ({ children, type }) => {
  let Container = InfoContainer;
  switch (type) {
    case 'warn':
      Container = WarnContainer;
      break;
    case 'info':
      Container = InfoContainer;
      break;
    case 'waiting':
      Container = WaitingContainer;
      break;
  }

  return <Container>{children}</Container>;
};

export default ClaimMessage;
