import { FCC } from 'react';
import OfferHeaderTitle from '../headerTitle';
import OffersTextHeader from '../textHeader';

type OffersCommonHeaderProps = {
  readonly icon?: React.ReactNode;
};

const OffersCommonHeader: FCC<OffersCommonHeaderProps> = props => {
  const { icon, children } = props;

  return (
    <OfferHeaderTitle
      icon={icon}
      title={<OffersTextHeader>{children}</OffersTextHeader>}
    />
  );
};

export default OffersCommonHeader;
