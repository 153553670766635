import { Hidden, Typography, useMediaQuery } from '@mui/material';
import Splitter from '../../../../components/common/splitter';
import useGridSpacing from '../../../../theme/hooks/useGridSpacing';
import { MPGrid } from '../../../../theme/ui-kit/grid';
import { BottomSection, Wrapper as StyledWrapper } from './controls';
import { CartSidebarLayoutType } from './types';

const CartSidebarLayout: CartSidebarLayoutType = props => {
  const { title, discount, cost, createOrdersButton, help, children } = props;

  const { x3 } = useGridSpacing();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const Wrapper = isMdUp ? StyledWrapper : BottomSection;

  return (
    <Wrapper>
      <MPGrid
        container
        spacing={x3}
      >
        <MPGrid
          item
          xs={5}
          md={12}
        >
          <Typography>{title}</Typography>

          <Hidden mdDown>
            <Splitter size={x3} />
          </Hidden>

          {isSmUp && (
            <>
              <Typography color='textSecondary'>{discount}</Typography>
              <Splitter size={1} />
            </>
          )}

          <Typography variant='h3'>{cost}</Typography>
        </MPGrid>
        <MPGrid
          item
          xs={7}
          md={12}
        >
          {createOrdersButton}
          {isSmUp && (
            <>
              <Splitter size={1} />
              {help}
            </>
          )}
        </MPGrid>
      </MPGrid>

      {children}
    </Wrapper>
  );
};

export default CartSidebarLayout;
