import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep4 = () => {
  return (
    <>
      <Text>
        9. Правовыми основаниями обработки персональных данных, на основании которых допускается обработка персональных
        данных в ОАО «РЖД», являются:
      </Text>

      <Text>
        1) согласие субъекта персональных данных на обработку персональных данных с учетом требований, предусмотренных
        законодательством Российской Федерации для соответствующей категории персональных данных;
      </Text>

      <Text>
        2) достижение целей, предусмотренных международным договором Российской Федерации или законом, осуществление и
        выполнение возложенных на ОАО «РЖД» законодательством Российской Федерации и Уставом ОАО «РЖД» функций,
        полномочий и обязанностей;
      </Text>

      <Text>
        3) судебные акты, акты другого органа или должностного лица, подлежащие исполнению ОАО «РЖД» в соответствии с
        положениями законодательства Российской Федерации об исполнительном производстве;
      </Text>

      <Text>
        4) договор, стороной которого либо выгодоприобретателем или поручителем по которому является субъект
        персональных данных, а также договор, заключенный по инициативе субъекта персональных данных или договор, по
        которому субъект персональных данных будет являться выгодоприобретателем или поручителем;
      </Text>

      <Text>
        5) обеспечение и (или) осуществление защиты жизни, здоровья или иных жизненно важных интересов субъекта
        персональных данных, если получение согласия субъекта персональных данных невозможно;
      </Text>

      <Text>
        6) осуществление прав и законных интересов ОАО «РЖД» или третьих лиц либо для достижения общественно значимых
        целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;
      </Text>

      <Text>
        7) осуществление профессиональной деятельности журналиста и (или) законной деятельности средства массовой
        информации либо научной, литературной или иной творческой деятельности при условии, что при этом не нарушаются
        права и законные интересы субъекта персональных данных;
      </Text>

      <Text>
        8) обработка персональных данных в статистических или иных исследовательских целях при условии их обязательного
        обезличивания;
      </Text>

      <Text>
        9) обработка персональных данных в целях опубликования или обязательного раскрытия информации в соответствии с
        законодательством Российской Федерации.
      </Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep4;
