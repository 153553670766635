import axios, { AxiosResponse } from 'axios';
import { AppFile } from 'domain/model';
import HttpClient from '../network/http';
import { getFilesEndpoint } from './utils';

type FilesApi = {
  readonly upload: (file: File, config?: object) => Promise<AxiosResponse<AppFile>>;
  readonly getImageUrl: (id: string, isPrivate?: boolean) => string;
};

const cacheControlOn = 'private, max-age=2592000, must-revalidate';

/**
 * АПИ по работе с файлами
 */
const files: FilesApi = {
  upload: (file, config = {}) => {
    const formData = new FormData();
    formData.append('file', file);
    const reqConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getFilesEndpoint()}/preload`, formData, reqConfig);
  },
  getImageUrl: (id, isPrivate) => {
    const token = HttpClient.getInstance().getAuthService()?.token;
    const params = new URLSearchParams();

    params.append('path', id);
    params.append('cache-control', cacheControlOn);
    if (isPrivate && token) params.append('access_token', token);

    return `${getFilesEndpoint()}/files?${params}`;
  },
};

export default files;
