import { Typography } from '@mui/material';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';

type AspOfferDetailsTitleAdapterProps = {
  readonly id: UUID;
};

export const AspOfferDetailsTitleAdapter = ({ id }: AspOfferDetailsTitleAdapterProps) => {
  const { aspOffer } = useAspOfferDetailsData(id);

  if (!aspOffer) {
    return null;
  }

  return <Typography variant='h1'>{aspOffer.name}</Typography>;
};

