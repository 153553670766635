import { Typography, TypographyProps } from '@mui/material';

type WarnMessageProps = {
  readonly show: boolean;
  readonly text: string;
};

export const WarnMessage = ({ show, text, ...rest }: WarnMessageProps & TypographyProps) => {
  return (
    <Typography
      color='warning.main'
      variant='body1'
      style={{ visibility: show ? 'visible' : 'hidden' }}
      {...rest}
    >
      {text}
    </Typography>
  );
};
