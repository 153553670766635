import { ComponentProps, PropsWithChildren, ReactNode } from 'react';

export type ContentLimiterAnyComponentType = (
  props: PropsWithChildren<ComponentProps<any> & { children?: ReactNode | undefined }>
) => ReactNode;

type ContentLimiterProps<TLayout extends ContentLimiterAnyComponentType> = PropsWithChildren<{
  readonly layout: TLayout;
  readonly layoutProps: ComponentProps<TLayout>;
}>;

export const ContentLimiter = <TLayout extends ContentLimiterAnyComponentType>(props: ContentLimiterProps<TLayout>) => {
  const { children, layout, layoutProps } = props;

  const Layout = layout as ContentLimiterAnyComponentType;

  return <Layout {...layoutProps}>{children}</Layout>;
};
