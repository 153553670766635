import { BadgeContentWrapper } from '../../../../features/offer/corp/details/adapters/carousel/controls';
import AppSvgIcon from '../../icon';
import { BannerIconBackground } from '../../../../media/icons';
import SvgIcon from '@mui/material/SvgIcon';
import { StyledBadge } from './controls';
import { FCC } from 'react';

type BadgeContentProps = {
  readonly backgroundColor?: string;
  readonly icon: typeof SvgIcon;
};

const BadgeContent = (props: BadgeContentProps) => {
  const { backgroundColor = 'white', icon } = props;

  return (
    <BadgeContentWrapper backgroundColor={backgroundColor}>
      <AppSvgIcon icon={BannerIconBackground} />
      <AppSvgIcon
        fontSize='medium'
        icon={icon}
      />
    </BadgeContentWrapper>
  );
};

type BannerBadgeProps = {
  readonly backgroundColor?: string;
  readonly icon: typeof SvgIcon;
};

const BannerBadge: FCC<BannerBadgeProps> = ({ backgroundColor, icon, children }) => {
  return (
    <StyledBadge
      badgeContent={
        <BadgeContent
          icon={icon}
          backgroundColor={backgroundColor}
        />
      }
    >
      {children}
    </StyledBadge>
  );
};

export default BannerBadge;
