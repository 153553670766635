import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useCallback, useEffect, useMemo, useState } from 'react';

type RenderManagerErrorProviderProps = {
  readonly children: JSX.Element;
};

const RenderManagerErrorProvider = ({ children }: RenderManagerErrorProviderProps) => {
  const [error, setError] = useState<Nullable<unknown>>(null);

  const setErrorProvider = useCallback((error: unknown) => {
    setError(error);
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);

  const value = useMemo(
    () => ({
      setError: setErrorProvider,
      clearError,
      error,
    }),
    [clearError, error, setErrorProvider]
  );

  return <RenderManagerErrorContext.Provider value={value}>{children}</RenderManagerErrorContext.Provider>;
};

export default RenderManagerErrorProvider;
