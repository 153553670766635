import axios, { AxiosResponse } from 'axios';
import { BookingServicesItem } from 'domain/model/bookingOffer';
import { PriceRangeFilter } from 'domain/model/offer';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getBaseEndpoint } from './utils';
import { addAdditionalQueryParams, BookingOffersBaseProps, BookingOffersRequestServices } from './user/bookingOffer';

export type BookingOffersListCategoryId = {
  categoryId: UUID;
};

export type BookingOffersListServicesProps = ApiCancellable &
  BookingOffersListCategoryId &
  Pick<BookingOffersBaseProps, 'query' | 'partnerId' | 'minPrice' | 'maxPrice'>;

export type BookingOffersListPriceRangeProps = ApiCancellable &
  BookingOffersListCategoryId &
  Pick<BookingOffersBaseProps, 'query'> &
  BookingOffersRequestServices;

type OrderOfferApi = {
  readonly serviceCategories: (props: BookingOffersListServicesProps) => Promise<AxiosResponse<BookingServicesItem[]>>;
  readonly priceRange: (props: BookingOffersListPriceRangeProps) => Promise<AxiosResponse<PriceRangeFilter>>;
};

/**
 * АПИ фильтров букинга
 */
const booking: OrderOfferApi = {
  serviceCategories: ({ signal, query, categoryId, partnerId, minPrice, maxPrice }) => {
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, { categories: [categoryId], query, partnerId });

    if (typeof minPrice === 'number') {
      params.append('minPrice', String(minPrice));
    }

    if (typeof maxPrice === 'number') {
      params.append('maxPrice', String(maxPrice));
    }

    return axios.get(`${getBaseEndpoint()}/customers/current/booking-services/categories`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  priceRange: ({ signal, query, categoryId, services }) => {
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, { categories: [categoryId], query });

    if (services) {
      services.forEach(item => params.append('serviceCategory', item));
    }

    return axios.get(`${getBaseEndpoint()}/customers/current/booking-price-range`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default booking;
