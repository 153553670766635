import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useProductOfferFilterInLocation } from '../hooks/useProductOfferApplyFilter';
import { productOfferFilterValuesSelector } from '../store/selectors';

const ProductFilterActionsAdapter = () => {
  const { onClearFilter } = useContextHandlers();
  const { onFilterApplyValues, onFilterClearValues } = useProductOfferFilterInLocation();

  const filterValues = useSelector(productOfferFilterValuesSelector);

  return useMemo(
    () => (
      <>
        <Button
          variant='contained'
          onClick={() => onFilterApplyValues(filterValues)}
        >
          Показать результаты
        </Button>
        <Button
          variant='outlined'
          onClick={() => {
            onClearFilter();
            onFilterClearValues();
          }}
        >
          Сбросить фильтры
        </Button>
      </>
    ),
    [onFilterApplyValues, filterValues, onClearFilter, onFilterClearValues]
  );
};

export default ProductFilterActionsAdapter;
