import { isArray } from 'lodash';
import { CalendarProps, DateObject } from 'react-multi-date-picker';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';
import { CalendarSelection } from '../types';
import { useLimitedDays } from '../hooks';
import { sortDates } from './utils';

export type MPMultipleCalendarPickerProps = Omit<CalendarProps<true>, 'onChange' | 'multiple' | 'range'> &
  CalendarSelection & {
    readonly onChange?: (date: Nullable<Date[]>) => false | void;
  };

export const MPMultipleCalendarPicker = ({
  limitPastSelection,
  limitFutureSelection,
  ...props
}: MPMultipleCalendarPickerProps) => {
  const onChangeInternal = (selectedDates: Nullable<DateObject | DateObject[]>): false | void => {
    if (selectedDates === null) {
      return props.onChange?.(null);
    }

    const result: Nullable<Date[]> = !isArray(selectedDates)
      ? [selectedDates.toDate()]
      : selectedDates.map(item => item.toDate());

    return props.onChange?.(result.sort(sortDates));
  };

  const mapDays = useLimitedDays({ limitPastSelection, limitFutureSelection });

  return (
    <StyledCalendar
      {...regularOptions}
      {...props}
      onChange={onChangeInternal}
      multiple
      mapDays={mapDays}
      range={false}
      {...permanentOptions}
    />
  );
};
