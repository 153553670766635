import { Hidden, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC, ReactNode } from 'react';

type PartnerDeskDetailsTabToolbarLayoutProps = {
  readonly title: ReactNode;
  readonly sort?: ReactNode;
};

export const PartnerDeskDetailsTabToolbarLayout: FC<PartnerDeskDetailsTabToolbarLayoutProps> = props => {
  const { title, sort } = props;

  return (
    <MPGrid
      container
      alignItems='center'
      justifyContent='space-between'
    >
      <Hidden mdDown>
        <MPGrid item>
          <Typography
            variant='h1'
            component='h2'
          >
            {title}
          </Typography>
        </MPGrid>
      </Hidden>
      {sort && <MPGrid item>{sort}</MPGrid>}
    </MPGrid>
  );
};
