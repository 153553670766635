import { Typography } from '@mui/material';
import { nbsp } from 'presentation/utils/constants';

const FooterCopyRight = () => {
  return (
    <Typography
      color='textSecondary'
      variant='caption'
    >
      © 2021 – {new Date().getFullYear()} ОАО{nbsp}«РЖД». Все права защищены.
    </Typography>
  );
};

export default FooterCopyRight;
