import { AspOffer } from 'domain/model/aspOffer';
import { FCC } from 'react';
import AspOfferClaimDialogsProvider from './dialogProvider/provider';
import AspOfferClaimProvider from './provider/provider';

type AspOfferClaimProvidersProps = {
  readonly guid: UUID;
  readonly aspOffer: AspOffer;
  readonly isActivationAllowed: boolean;
  readonly onPartnerButtonClick: () => void;
  readonly onShowAllOffers: () => void;
};

const AspOfferClaimProviders: FCC<AspOfferClaimProvidersProps> = props => {
  const { guid, children, aspOffer, isActivationAllowed, onShowAllOffers, onPartnerButtonClick } = props;

  return (
    <AspOfferClaimProvider
      id={aspOffer.id}
      guid={guid}
      isActivationAllowed={isActivationAllowed}
      promotionType={aspOffer.promotionType}
      onPartnerButtonClick={onPartnerButtonClick}
    >
      <AspOfferClaimDialogsProvider
        aspOffer={aspOffer}
        onShowAllOffers={onShowAllOffers}
      >
        {children}
      </AspOfferClaimDialogsProvider>
    </AspOfferClaimProvider>
  );
};

export default AspOfferClaimProviders;
