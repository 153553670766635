import { FC } from 'react';
import { Typography } from '@mui/material';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';

type UserPromptProps = {
  readonly isVisible: boolean;
  readonly title: string;
  readonly text: string;
  readonly onCancel: VoidFunction;
  readonly onProceed: VoidFunction;
};

const UserPrompt: FC<UserPromptProps> = ({ isVisible, onCancel, onProceed, title, text }) => {
  return (
    <MPConfirmDialog
      open={isVisible}
      text={
        <Typography
          variant='h2'
          component='span'
          color='textPrimary'
          mb={2}
        >
          {title}
        </Typography>
      }
      maxWidth='xs'
      onClose={onCancel}
      buttons={
        <MPGrid
          container
          spacing={2}
          flexDirection='column'
        >
          <MPGrid item>
            <MPButton
              fullWidth
              size='large'
              color='brand'
              onClick={onProceed}
            >
              Продолжить
            </MPButton>
          </MPGrid>
          <MPGrid item>
            <MPButton
              fullWidth
              size='large'
              variant='text'
              color='brand'
              onClick={onCancel}
            >
              Отмена
            </MPButton>
          </MPGrid>
        </MPGrid>
      }
    >
      {text}
    </MPConfirmDialog>
  );
};

export default UserPrompt;
