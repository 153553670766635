import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { TradeOfferClaimArchivedMessage } from './archived';
import { TradeOfferClaimPausedMessage } from './paused';
import { TradeOfferClaimUnavailableMessage } from './unavailable';
import { TradeOfferClaimUpcomingMessage } from './upcoming';

export const TradeOfferClaimInfoMessagesAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, isActivationAvailable } = useTradeOfferClaimContext();

  return useMemo(() => {
    if (!tradeOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return <TradeOfferClaimUnavailableMessage />;
      } else {
        return (
          <>
            {tradeOffer.status === EOfferStatus.Upcoming && (
              <TradeOfferClaimUpcomingMessage startDate={tradeOffer.startDate} />
            )}

            {tradeOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && <TradeOfferClaimPausedMessage />}

            {tradeOffer.status === EOfferStatus.Archived && <TradeOfferClaimArchivedMessage />}
          </>
        );
      }
    }
  }, [tradeOffer, isActivationAvailable, isActivationReceivedNow]);
};
