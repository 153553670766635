import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useHistory } from 'react-router';
import { Wrapper } from './controls';

type ForbiddenMessageProps = {
  readonly goToBack: () => void;
  readonly goToMain: () => void;
};

type ForbiddenComponentProps = {
  readonly children?: any;
};

const ForbiddenMessage = ({ goToBack, goToMain }: ForbiddenMessageProps) => {
  return (
    <MPGrid
      container
      spacing={2}
    >
      <MPGrid
        item
        zero={12}
      >
        <Typography
          variant='subtitle2'
          color='error'
        >
          Произошла ошибка
        </Typography>
      </MPGrid>
      <MPGrid
        item
        xs={12}
      >
        <Typography>Вы не имеете доступа к данной функции</Typography>
      </MPGrid>
      <MPGrid
        item
        zero={6}
        container
        justifyContent='flex-end'
      >
        <MPButton
          fullWidth={false}
          onClick={goToBack}
        >
          Вернуться назад
        </MPButton>
      </MPGrid>
      <MPGrid
        item
        zero={6}
        container
        justifyContent='flex-start'
      >
        <MPButton
          fullWidth={false}
          onClick={goToMain}
        >
          Перейти на главную
        </MPButton>
      </MPGrid>
    </MPGrid>
  );
};

const ForbiddenComponent = (props: ForbiddenComponentProps) => {
  const history = useHistory();

  const {
    children = (
      <ForbiddenMessage
        goToBack={() => history.goBack()}
        goToMain={() => history.replace('/')}
      />
    ),
  } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default ForbiddenComponent;
