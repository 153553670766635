import { UserDetailsToolbarLayoutType } from './types';
import { useHeaderParams } from 'presentation/hooks/useHeaderParams';
import { Wrapper } from './controls';

const UserDetailsToolbarLayout: UserDetailsToolbarLayoutType = props => {
  const { children } = props;

  const { headerHeight } = useHeaderParams();

  return <Wrapper top={headerHeight}>{children}</Wrapper>;
};

export default UserDetailsToolbarLayout;
