import { useTheme } from '@mui/material';

const Logo = () => {
  const { logo } = useTheme();

  return (
    <img
      src={logo}
      alt=''
    />
  );
};

export default Logo;
