import { FC, ReactNode, useState } from 'react';
import { Typography } from '@mui/material';
import { ArrowDownIcon, InfoHelpIcon } from '../../../media/icons';
import { MPAccordion, MPAccordionDetails, MPAccordionSummary } from '../../../theme/ui-kit/accordion';

const IconHelp = <InfoHelpIcon />;
const IconUp = <ArrowDownIcon />;

export type CustomAccordionProps = {
  /** Текст в свёрнутом состоянии */
  title: string | ReactNode;
  /** Текст в развёрнутом состоянии */
  description: string | ReactNode;
  /** Икона в свёрнутом состоянии */
  expandIcon?: ReactNode;
  /** Икона в развёрнутом состоянии */
  expandedIcon?: ReactNode;
};

/**
 * Стилизованный аккордеон
 */
const AccordionWithIcon: FC<CustomAccordionProps> = ({
  title,
  description,
  expandIcon = IconHelp,
  expandedIcon = IconUp,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <MPAccordion
      expanded={isExpanded}
      disableGutters
      elevation={0}
      onChange={() => setExpanded(isExpanded => !isExpanded)}
    >
      <MPAccordionSummary expandIcon={isExpanded ? expandedIcon : expandIcon}>
        <Typography variant='subtitle2'>{title}</Typography>
      </MPAccordionSummary>
      <MPAccordionDetails>
        <Typography variant='body2'>{description}</Typography>
      </MPAccordionDetails>
    </MPAccordion>
  );
};

export default AccordionWithIcon;
