import { useCallback, useEffect, useMemo, useState } from 'react';
import { SliderProps } from '@mui/material';
import debounce from 'lodash/debounce';
import { PriceRangeFilter } from 'domain/model/offer';
import { PriceRange } from 'presentation/components/common/price';
import { MPSlider } from '../../../../theme/ui-kit/slider';
import { Wrapper } from './controls';

export type OfferFilterPriceProps = Omit<SliderProps, 'value' | 'onChange'> & {
  readonly isDisabled?: boolean;
  readonly value: PriceRangeFilter;
  /** Выдача только последнего из вводимых значений, включен по-умолчанию */
  readonly hasDebounce?: boolean;
  /** Таймаут для отдачи наружу вводимых значений, 500мс по-умолчанию */
  readonly debounceTimeout?: number;
  /** Должен быть замеман */
  readonly onChange: (value: PriceRangeFilter) => void;
};

type HandleChangeProps = PriceRangeFilter & {
  from: number;
  to: number;
  // приоритет передаваемого значения над значением в стейте
  valuePriority: boolean;
};

const defaultRange = {
  min: 0,
  max: 9999999,
};

export const OfferFilterPrice: React.FCC<OfferFilterPriceProps> = ({
  isDisabled,
  min = defaultRange.min,
  max = defaultRange.max,
  hasDebounce = true,
  debounceTimeout = 500,
  value,
  value: { minPrice, maxPrice },
  onChange,
  ...other
}) => {
  const [currentRange, setCurrentRange] = useState<number[]>([minPrice ?? min, maxPrice ?? max]);
  const [currentMinPrice, currentMaxPrice] = currentRange;

  const isFixedRange = min + max + (minPrice ?? 0) + (maxPrice ?? 0) === min * 4;

  const handleChange = useCallback(
    ({ minPrice: updatedMin, maxPrice: updatedMax, from, to, valuePriority = false }: HandleChangeProps) => {
      const price = { minPrice: updatedMin, maxPrice: updatedMax };
      const isRangeEqual = from === to;
      const range = [
        ...(valuePriority && typeof updatedMin === 'number' && updatedMax ? [updatedMin, updatedMax] : currentRange),
      ];

      if (updatedMin! < from) {
        price.minPrice = from;
        range[0] = price.minPrice;
      } else if (updatedMin! > to - 1 && !isRangeEqual) {
        price.minPrice = to - 1;
        range[0] = price.minPrice;
      }

      if (updatedMax! > to) {
        price.maxPrice = to;
        range[1] = price.maxPrice;
      } else if (updatedMax! < from + 1 && !isRangeEqual) {
        price.maxPrice = from + 1;
        range[1] = price.maxPrice;
      }

      setCurrentRange(range);

      if (isFixedRange || (price.minPrice === minPrice && price.maxPrice === maxPrice)) {
        return;
      }

      onChange(price);
    },
    [currentRange, isFixedRange, maxPrice, minPrice, onChange]
  );

  const debouncedChange = useMemo(
    () => (hasDebounce ? debounce(handleChange, debounceTimeout) : handleChange),
    [hasDebounce, debounceTimeout, handleChange]
  );

  useEffect(() => {
    return () => {
      if (hasDebounce) {
        (debouncedChange as ReturnType<typeof debounce>).cancel();
      }
    };
  }, [hasDebounce, debouncedChange]);

  useEffect(() => {
    if (minPrice === currentMinPrice && maxPrice === currentMaxPrice) {
      return;
    }

    setCurrentRange([minPrice ?? min, maxPrice ?? max]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice, maxPrice]);

  const updateDebouncedValues = (value: number[], valuePriority = false) => {
    // отдаёт наружу нормированное значение
    debouncedChange({ minPrice: value[0], maxPrice: value[1], from: min, to: max, valuePriority });
  };

  const handleChangeSlider = (event: Event, value: number | number[]) => {
    if (!Array.isArray(value) || isFixedRange) {
      return;
    }

    setCurrentRange(value);
  };

  const handleChangeInputs = (from: number, to: number) => {
    if (isFixedRange) {
      return;
    }

    setCurrentRange([from, to]);
  };

  const handleBLur = () => {
    if (isFixedRange) {
      return;
    }

    updateDebouncedValues(currentRange);
  };

  return (
    <Wrapper>
      <PriceRange
        isDisabled={isDisabled}
        valueFrom={currentMinPrice}
        valueTo={currentMaxPrice}
        onChange={handleChangeInputs}
        onBlur={handleBLur}
      />
      <MPSlider
        disabled={isDisabled}
        min={isFixedRange ? min - 1 : min}
        max={max}
        value={isFixedRange ? [min - 1, max] : currentRange}
        color='brand'
        onChange={handleChangeSlider}
        onMouseUp={handleBLur}
        onChangeCommitted={handleBLur}
        {...other}
      />
    </Wrapper>
  );
};
