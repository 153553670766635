import { Typography, TypographyProps } from '@mui/material';
import { FCC } from 'react';

const PartnerDeskTabTextHeader: FCC<Pick<TypographyProps, 'variant'>> = props => {
  return (
    <Typography
      variant='h3'
      {...props}
    />
  );
};

export default PartnerDeskTabTextHeader;
