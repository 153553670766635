import AboutPublicOfferContainer from 'presentation/features/about/publicOffer/container';
import ScreenLayout from 'presentation/layouts/screen';

const AboutPublicOfferScreen = () => {
  return (
    <ScreenLayout footer>
      <AboutPublicOfferContainer />
    </ScreenLayout>
  );
};

export default AboutPublicOfferScreen;
