import { combineReducers } from '@reduxjs/toolkit';
import details, { PartnerDeskDetailsState } from './details/store/slice';

type PartnerDeskState = {
  readonly details: PartnerDeskDetailsState;
};

export default combineReducers<PartnerDeskState>({
  details,
});
