import PartnerLink from '../../../../../../components/common/partnerLink';

type TradeOfferClaimReferralLinkPromotionProps = {
  readonly link: string;
  readonly onClick: () => void;
};

export const TradeOfferClaimReferralLinkPromotion = (props: TradeOfferClaimReferralLinkPromotionProps) => {
  const { link, onClick } = props;

  return (
    <PartnerLink
      link={link}
      onClick={onClick}
    />
  );
};
