import styled from '@emotion/styled/macro';
import { svgIconClasses, typographyClasses } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .${typographyClasses.root} .${svgIconClasses.root} {
    margin-bottom: -3px;
    color: ${p => p.theme.palette.secondary.A900};
  }
`;

export const ImageWrapper = styled.div`
  width: 250px;
  height: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
