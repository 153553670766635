import { UserMenuItem } from 'presentation/features/user/components/menuItem';
import { LogoutIcon } from 'presentation/media/icons';
import { FC } from 'react';

type UserMenuItemLogoutProps = {
  readonly onClick?: () => void;
};

export const UserMenuItemLogout: FC<UserMenuItemLogoutProps> = ({ onClick }) => {
  return (
    <UserMenuItem
      label='Выйти'
      icon={<LogoutIcon color='black' />}
      onClick={onClick}
    />
  );
};
