import ProductOfferListAsideAdapter from 'presentation/features/offer/product/list/adapters/aside';
import ProductOfferListLayout from 'presentation/features/offer/product/list/layout';
import ProductOfferListBannersAdapter from './adapters/banners';
import ProductOfferListBreadcrumbsAdapter from './adapters/breadcrumbs';
import ProductOfferListCommonAdapter from './adapters/dataCommon';
import ProductOfferListLoaderAdapter from './adapters/loader';
import ProductOfferListStubAdapter from './adapters/stub';
import { ProductOfferListContext } from './context';
import { ProductOfferHeaderContainer } from './header/container';
import { useProductOfferList } from './hooks/useProductOfferList';

export type ProductOfferListContainerProps = {
  readonly guid: UUID;
  readonly name: Nullable<string>;
  readonly categoryId: Nullable<UUID>;
};

const Layout = ProductOfferListLayout;

const ProductOfferListContainer = (props: ProductOfferListContainerProps) => {
  const { name: nameParam, categoryId: categoryIdParam, guid } = props;

  const handlers = useProductOfferList({ guid, name: nameParam, categoryId: categoryIdParam });

  return (
    <ProductOfferListContext.Provider value={handlers}>
      <Layout
        banners={<ProductOfferListBannersAdapter guid={guid} />}
        breadcrumbs={<ProductOfferListBreadcrumbsAdapter />}
        header={<ProductOfferHeaderContainer />}
        aside={<ProductOfferListAsideAdapter />}
        stub={<ProductOfferListStubAdapter />}
        loader={<ProductOfferListLoaderAdapter />}
      >
        <ProductOfferListCommonAdapter
          guid={guid}
          isAddToCartFetching={id => !!id}
        />
      </Layout>
    </ProductOfferListContext.Provider>
  );
};

export default ProductOfferListContainer;
