import { useMemo } from 'react';
import UserLocation, { HeaderLocationProps } from '../../../components/common/location';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { useContextHandlers } from '../hooks/useContextHandlers';

type HeaderLocationAdapterProps = Pick<HeaderLocationProps, 'icon'>;

const HeaderLocationAdapter = ({ ...others }: HeaderLocationAdapterProps) => {
  const { settings } = useUserLocalSettings();
  const handlers = useContextHandlers();

  const userLocation = settings.city?.name ?? null;

  return useMemo(
    () =>
      userLocation ? (
        <UserLocation
          {...others}
          location={userLocation}
          onClick={handlers.showChangeLocationDialog}
        />
      ) : null,
    [handlers.showChangeLocationDialog, others, userLocation]
  );
};

export default HeaderLocationAdapter;
