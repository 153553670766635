import StubCommon from 'presentation/components/common/stub/common';
import { searchStub } from 'presentation/media/images';

const SerarchOffersStub = () => {
  return (
    <StubCommon
      title='По вашему запросу ничего не найдено'
      text='Попробуйте сократить запрос или задать его по-другому'
      image={searchStub}
    />
  );
};

export default SerarchOffersStub;
