import { Text } from './controls';
import { getSiteCaption } from '../../../../utils';

const AboutCookiesHowToOptOutInfo = () => {
  return (
    <>
      <Text>
        Вы можете перед заходом на сайт очистить свой файлы Cookies, подробнее об этом - в справке вашего браузера. В
        таком случае наш веб-сайт будет использовать только те Cookies, которые строго необходимы для функционирования
        веб-сайта и предлагаемых им услуг, сервисов. {getSiteCaption()} оставляет за собой право обновлять данную
        политику.
      </Text>
    </>
  );
};
export default AboutCookiesHowToOptOutInfo;
