import { useMediaQuery } from '@mui/material';
import { CmsBanner } from 'domain/model/cms';
import { ECmsBannerLinkObjectType, ECmsCollectionLinkObjectType, EOfferType } from 'domain/model/enums';
import AppImage from 'presentation/components/common/images/common';
import { useMemo } from 'react';
import { BannerIconBackground, CorpOfferIcon, TradeOfferIcon } from '../../../../media/icons';
import { ImageWrapper, LogoBackground, LogoIconWrapper, LogoWrapper, Wrapper } from './controls';

type CmsComponentBannerProps = {
  readonly banner: CmsBanner;
  readonly objectType?: Nullable<ECmsCollectionLinkObjectType | EOfferType>;
  readonly showIcon?: Nullable<boolean>;
  readonly onClick: () => void;
  readonly Caption?: React.ReactNode;
};

const CmsComponentBanner = ({ banner, objectType, showIcon, onClick, Caption }: CmsComponentBannerProps) => {
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  const Logo = useMemo(() => {
    /**
     * определяется первично по типу линка в баннере
     * если по нему не удалось определить, то используем objectType
     */

    let result = null;
    if (showIcon) {
      switch (banner.linkObjectType) {
        case ECmsBannerLinkObjectType.TradeOffer:
        case ECmsBannerLinkObjectType.TradeOfferCategory:
          result = TradeOfferIcon;
          break;
        case ECmsBannerLinkObjectType.CorpOffer:
        case ECmsBannerLinkObjectType.CorpOfferCategory:
          result = CorpOfferIcon;
          break;
        default:
          result = null;
      }
      if (!result) {
        switch (objectType) {
          case EOfferType.Trade:
          case ECmsCollectionLinkObjectType.TradeOffer:
            result = TradeOfferIcon;
            break;
          case EOfferType.Corp:
          case ECmsCollectionLinkObjectType.CorpOffer:
            result = CorpOfferIcon;
            break;
          default:
            result = null;
        }
      }
    }
    return result;
  }, [banner.linkObjectType, objectType, showIcon]);

  return (
    <Wrapper
      height={banner.height}
      onClick={onClick}
    >
      <ImageWrapper>
        {banner.desktopImage && <AppImage src={isSmDown ? banner.mobileImage : banner.desktopImage} />}
      </ImageWrapper>
      {Caption}
      {Logo && (
        <LogoWrapper>
          <LogoBackground>
            <BannerIconBackground />
          </LogoBackground>
          <LogoIconWrapper>
            <Logo fontSize={isMdDown ? 'micro' : 'medium'} />
          </LogoIconWrapper>
        </LogoWrapper>
      )}
    </Wrapper>
  );
};

export default CmsComponentBanner;
