import styled from '@emotion/styled';
import { css, dialogContentClasses, GridProps, iconButtonClasses } from '@mui/material';
import { MPDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { forwardRef } from 'react';

const defaultSize = `
  width: 100%;
  height: 100%;
`;

const fullSize = `
  width: 100%;
  height: 90vh;
`;

type StyledIframeProps = {
  readonly isOpened?: boolean;
};

export const StyledIframe = styled.iframe<StyledIframeProps>(
  ({ theme: { breakpoints }, isOpened }) => css`
    ${isOpened ? fullSize : defaultSize};
    border: none;
    position: relative;
    z-index: 1;

    ${breakpoints.down('xl')} {
      width: 100%;
      ${!isOpened && 'height: 100%;'};
    }

    ${breakpoints.down('md')} {
      ${isOpened && 'height: 100%;'};
    }
  `
);

type MapContainerProps = GridProps & {
  readonly isError?: boolean;
  readonly children: any;
};

export const MapContainer = styled(
  forwardRef(({ isError, ...props }: MapContainerProps, ref: any) => (
    <MPGrid
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme: { spacing, breakpoints, palette }, isError }) => css`
    ${defaultSize};
    position: relative;
    overflow: hidden;
    border-radius: ${spacing(3)};
    cursor: ${isError ? 'inherit' : 'pointer'};

    background: ${isError ? (palette.grey as any)[100] : 'inherit'};
    padding: ${isError ? spacing(2) : 0};

    ${breakpoints.down('lg')} {
      width: 100%;
      height: 144px;
    }
  `
);

export const DialogContainer = styled(MPDialog)(
  ({ theme: { breakpoints } }) => css`
    ${breakpoints.down('md')} {
      .${dialogContentClasses.root} {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding: 0;
      }

      .${iconButtonClasses.root} {
        position: fixed;
        right: 8px;
        top: 0;
      }
    }
  `
);

export const MapClicker = styled.div`
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  ${fullSize};
  z-index: 2;
`;
