import { FCC } from 'react';
import TradeOfferClaimProvider from './provider/provider';
import TradeOfferClaimDialogsProvider from './dialogProvider/provider';
import { TradeOffer } from 'domain/model/tradeOffer';

type TradeOfferClaimProvidersProps = {
  readonly guid: UUID;
  readonly tradeOffer: TradeOffer;
  readonly isActivationAllowed: boolean;
  readonly onPartnerButtonClick: () => void;
  readonly onShowAllOffers: () => void;
};

const TradeOfferClaimProviders: FCC<TradeOfferClaimProvidersProps> = props => {
  const { guid, children, tradeOffer, isActivationAllowed, onShowAllOffers, onPartnerButtonClick } = props;

  return (
    <TradeOfferClaimProvider
      id={tradeOffer.id}
      guid={guid}
      isActivationAllowed={isActivationAllowed}
      promotionType={tradeOffer.promotionType}
      onPartnerButtonClick={onPartnerButtonClick}
    >
      <TradeOfferClaimDialogsProvider
        tradeOffer={tradeOffer}
        onShowAllOffers={onShowAllOffers}
      >
        {children}
      </TradeOfferClaimDialogsProvider>
    </TradeOfferClaimProvider>
  );
};

export default TradeOfferClaimProviders;
