import { Category } from 'domain/model/nsi';
import { BreadcrumbObject } from 'presentation/components/common/breadcrumb/expanded';
import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getTradeOffersListRoute, getTradeOffersSearchRoute } from '../../routes';

type TradeOfferBreadcrumbsProps = {
  readonly offerCategories: Nullable<Category[]>;
  readonly dictionary: Nullable<Category[]>;
};

const TradeOfferBreadcrumbs = (props: TradeOfferBreadcrumbsProps) => {
  const { offerCategories, dictionary } = props;

  const getBreadcrumbLink = (category: Category) => getTradeOffersSearchRoute({ categoryId: category.id });

  const startCategory: BreadcrumbObject = {
    id: '',
    name: EAppCategories.TradeOffers,
    parentId: null,
    link: getTradeOffersListRoute(),
  };

  return (
    <OfferBreadcrumbs
      startCategory={startCategory}
      offerCategories={offerCategories ?? null}
      dictionary={dictionary}
      linkFactory={getBreadcrumbLink}
    />
  );
};

export default TradeOfferBreadcrumbs;
