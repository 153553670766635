import styled from '@emotion/styled/macro';
import { buttonClasses, css } from '@mui/material';
import { colorGradientCalculator } from 'presentation/theme/override/palette';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const Wrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;

    & > .${buttonClasses.root}:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > .${buttonClasses.root}:nth-last-child(1) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `
);

export const FileSelector = styled.input(
  () => css`
    width: 100%;
    height: 100%;

    opacity: 0;
    position: absolute;
    border: none;

    cursor: pointer;
  `
);

export const Button = styled(MPButton)(
  () => css`
    width: 56px;
    height: 56px;
    min-width: auto;
    min-height: auto;
  `
);

export const PhotoBorder = styled.div(
  ({ theme: { palette } }) => css`
    background: linear-gradient(90deg, ${colorGradientCalculator(palette.primary.main, palette.brand.main).join(', ')});
    border-radius: 40px;
    overflow: hidden;

    padding: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export const PhotoWrapper = styled.div(
  ({ theme }) => css`
    width: 160px;
    height: 160px;

    border-radius: 38px;
    background: ${theme.palette.secondary.light};

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center center;
    }
  `
);
