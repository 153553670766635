import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const appBar: OverrideThemeFunc<Components> = baseTheme => ({
  MuiAppBar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: baseTheme.palette.secondary.A400,
      },
    },
  },
});

export default appBar;
