import AboutPublicOfferScreen from 'presentation/screen/about/publicOffer';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import AboutAspScreen from '../../screen/about/asp';
import { AppRoute } from '../general/routing';
import { EAppFeature } from '../../types';

const root = rootRouting.about;

const routing = {
  root,
  customers: `${root}/customers`,
  company: `${root}/company`,
  publicOffer: `${root}/public-offer`,
  asp: `${root}/asp`,
};

export const getAboutRootRoute = () => routing.root;

const AboutEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.publicOffer}
        component={AboutPublicOfferScreen}
      />
      <AppRoute
        exact
        protect
        path={routing.asp}
        component={AboutAspScreen}
        feature={EAppFeature.Asp}
      />
      <Redirect
        exact
        path={routing.root}
        to={routing.publicOffer}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default AboutEntry;
