import { HeaderWrapper, LoaderWrapper } from './controls';
import { CmsBannersLayoutType } from './types';

const CmsBannersLayout: CmsBannersLayoutType = props => {
  const { header, footer, loader, children } = props;

  return (
    <>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}
      {children}
      {footer}
      {loader && <LoaderWrapper>{loader}</LoaderWrapper>}
    </>
  );
};

export default CmsBannersLayout;
