import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { BookingCartItem } from '../types';

export type OfferCart = Record<UUID, Nullable<BookingCartItem[]>>;

export type BookingOfferDetailsState = {
  readonly cart: Nullable<OfferCart>;
  readonly modal: Nullable<BookingCartItem>;
};

type Reducer<T = undefined> = CaseReducer<BookingOfferDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<BookingOfferDetailsState> & {
  bookingOfferDetailsAddToCart: Reducer<{ offerId: UUID; items: BookingCartItem[] }>;
  bookingOfferDetailsClearCart: Reducer<UUID>;
  bookingOfferDetailsClearCarts: Reducer;
  bookingOfferDetailsSetModal: Reducer<Nullable<BookingCartItem>>;
};

const slice = createSlice<BookingOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    cart: null,
    modal: null,
  },
  reducers: {
    bookingOfferDetailsAddToCart: (state, { payload: { offerId, items } }) => {
      if (!state.cart) {
        state.cart = {};
      }
      state.cart[offerId] = items;
    },
    bookingOfferDetailsClearCart: (state, { payload }) => {
      if (!state.cart || !state.cart[payload]) {
        return;
      }
      state.cart[payload] = null;
    },
    bookingOfferDetailsClearCarts: state => {
      state.cart = null;
    },
    bookingOfferDetailsSetModal: (state, { payload }) => {
      state.modal = payload;
    },
  },
});

export const {
  bookingOfferDetailsAddToCart,
  bookingOfferDetailsClearCart,
  bookingOfferDetailsClearCarts,
  bookingOfferDetailsSetModal,
} = slice.actions;

export default slice.reducer;
