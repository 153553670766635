import styled from '@emotion/styled/macro';
import { css, outlinedInputClasses } from '@mui/material';
import { MPIconButton } from 'presentation/theme/ui-kit/button';
import { MPFormInput, MPFormInputProps } from 'presentation/theme/ui-kit/input';

export const Button = styled(MPIconButton)(
  () => css`
    // Обнуление отступов внутри кнопки
    padding: 0;

    // скругляем кнопки для нормального эффекта fiddle
    border-radius: 50%;
  `
);

type InputProps = MPFormInputProps & {
  fullWidth?: boolean;
};

export const Input = styled(({ fullWidth, ...props }: InputProps) => <MPFormInput {...props} />)(
  ({ theme, size, fullWidth }) => css`
    .${outlinedInputClasses.root} {
      ${fullWidth && 'justify-content: space-between'};
      // Добавляем индивидуальную высоту для компонента под дизайн
      height: ${size === 'small' ? '42px' : size === 'medium' ? '48px' : '34px'};

      // Добавляем отступы по бокам как в дизайне
      padding: 0 ${theme.spacing(size === 'micro' ? 0.625 : size === 'small' ? 1.125 : 1.5)};
      background-color: ${theme.palette.background.default};

      &:hover {
        background-color: ${theme.palette.secondary.A500};
      }
    }

    .${outlinedInputClasses.input} {
      // При любых размерах ширина компонента фиксированная в дизайне
      max-width: ${size === 'micro' ? '52px' : size === 'small' ? '44px' : '38px'};

      // обнуляем паддинги для самого input что бы большее число влезло для отображения
      padding: 0 !important;
      text-align: center;

      // При любых размерах нужен одинаковый размер шрифта
      font-size: ${theme.typography.body1.fontSize} !important;
    }

    //fieldset находиться в не зависимости от label и создает лишний border
    .${outlinedInputClasses.notchedOutline} {
      display: none;
    }
  `
);

export const Wrapper = styled.div<{ isInputDisabled?: boolean }>(
  ({ theme: { palette }, isInputDisabled }) => css`
    position: relative;

    ${isInputDisabled &&
    css`
      .${outlinedInputClasses.input}.${outlinedInputClasses.disabled} {
        color: ${palette.text.primary};
        -webkit-text-fill-color: inherit;
      }
    `}
  `
);
