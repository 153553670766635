import { AxiosResponse } from 'axios';
import {
  ActivateOfferResponse,
  useActivateUserOfferMutation,
  useGetUserOfferActivationsQuery,
  useGetUserOfferAvailabilityQuery,
} from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import { OfferActivation } from 'domain/model/activation';
import { CorpOffer } from 'domain/model/corpOffer';
import {
  EBalanceType,
  ECorpOfferPromotionType,
  EOfferActivateError,
  EOfferActivationStatus,
  ETradeOfferPromotionType,
} from 'domain/model/enums';
import { TradeOffer } from 'domain/model/tradeOffer';
import { useCallback, useEffect, useState } from 'react';
import Notifier from '../../../../system/notifier';
import { OfferActivationError } from '../types';
import { getOfferLastActivation } from '../utils';
import useCurrentUserBalanceByType from '../../user/current/useBalanceByType';

type UseUserOfferActivationsProps = {
  readonly offerId: UUID;
  readonly balanceType?: EBalanceType;
  readonly offerPrice?: Nullable<number>;
  readonly isActivationAllowed?: boolean;
  readonly offerRefetch: () => void;
};

export type OfferActivationState = {
  readonly isLoadActivationsFetching: boolean;
  readonly isLoadActivationsFailed: boolean;
  readonly isReactivationSupported: boolean;
  readonly activationsReload: () => void;

  readonly isActivationAvailable: boolean;
  readonly isUserBalanceNotEnough: boolean;

  readonly activateOffer: (offer: CorpOffer | TradeOffer) => Promise<Nullable<ActivateOfferResponse>>;
  readonly activationError: Nullable<OfferActivationError>;
  readonly activationIsFetching: boolean;

  readonly activations: OfferActivation[];

  readonly isLastActivationPending: boolean;
  readonly lastActivation: Nullable<OfferActivation>;
  readonly lastReceivedActivation: Nullable<OfferActivation>;
};

const useOfferActivations = (props: UseUserOfferActivationsProps): OfferActivationState => {
  const { offerId, offerPrice, balanceType, offerRefetch, isActivationAllowed = true } = props;

  const [activationError, setActivationError] = useState<Nullable<OfferActivationError>>(null);

  const balance = useCurrentUserBalanceByType(balanceType ?? null);

  const {
    data: activations = [],
    error: activationsError,
    isFetching: activationsIsFetching,
    refetch: activationsRefetch,
  } = useGetUserOfferActivationsQuery(
    { id: offerId },
    {
      refetchOnMountOrArgChange: true,
      skip: !isActivationAllowed,
    }
  );

  const {
    data: unavailabilityReasons = [],
    error: availabilityError,
    isFetching: availabilityIsFetching,
    refetch: availabilityRefetch,
  } = useGetUserOfferAvailabilityQuery(
    { offerId },
    {
      refetchOnMountOrArgChange: true,
      skip: !isActivationAllowed,
    }
  );

  const [activateOfferInternal, activateOfferResult] = useActivateUserOfferMutation();

  const { isLoading: activationIsFetching, data: lastReceivedActivation = null } = activateOfferResult;
  const lastActivation = getOfferLastActivation(activations) ?? null;
  const isLastActivationPending = lastActivation?.status === EOfferActivationStatus.Pending;
  const parseActivationError = useCallback(
    (error: ServerErrorResponse) => {
      const type = error.code as EOfferActivateError;
      const message = error.message;
      switch (type) {
        case EOfferActivateError.PromotionFreshOut:
          ErrorHandler.handleBusinessError(error);
          offerRefetch();
          availabilityRefetch();
          break;
        default:
          setActivationError({
            type,
            message,
          });
      }
    },
    [availabilityRefetch, offerRefetch]
  );

  const parseActivationErrorResponse = useCallback(
    (response: AxiosResponse<ServerErrorResponse>) => {
      switch (response.status) {
        case businessErrorCode:
          if (!response.data.code) {
            setActivationError({
              type: EOfferActivateError.Unknown,
            });
            ErrorHandler.handleHttpError(response);
          } else {
            parseActivationError(response.data);
          }
          return;
        case 500:
          setActivationError({
            type: EOfferActivateError.Unknown,
          });
          return;
        default: {
          ErrorHandler.handleHttpError(response);
        }
      }
    },
    [parseActivationError]
  );

  const activateOffer = useCallback(
    async (offer: CorpOffer | TradeOffer) => {
      let callActivate: boolean = false;
      switch (offer.promotionType) {
        case ECorpOfferPromotionType.ExternalCertificate:
          callActivate = false;
          break;
        case ECorpOfferPromotionType.Certificate:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
        case ETradeOfferPromotionType.Voucher:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.PublicPromocode:
        case ETradeOfferPromotionType.Widget:
        case ETradeOfferPromotionType.ReferralLink:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.Asp:
        case ETradeOfferPromotionType.Digift:
          callActivate = true;
          break;
      }

      let result = null;
      if (callActivate) {
        setActivationError(null);
        try {
          result = await activateOfferInternal({ id: offer.id }).unwrap();
          offerRefetch();
          activationsRefetch();
        } catch (e) {
          parseActivationErrorResponse(e as AxiosResponse<ServerErrorResponse>);
        }
      }

      return result;
    },
    [activateOfferInternal, offerRefetch, activationsRefetch, parseActivationErrorResponse]
  );

  const activationsReload = useCallback(() => {
    activationsRefetch();
    availabilityRefetch();
  }, [activationsRefetch, availabilityRefetch]);

  const isLoadActivationsFetching = activationsIsFetching || availabilityIsFetching;
  const isLoadActivationsFailed = !!activationsError || !!availabilityError;

  useEffect(() => {
    if (isLoadActivationsFailed) {
      Notifier.getInstance().addError('Ошибка при получении. Попробуйте ещё раз позже');
    }
  }, [isLoadActivationsFailed]);

  const isActivationAvailable =
    !unavailabilityReasons.some(reason => reason === EOfferActivateError.InappropriateTargeting) &&
    activationError?.type !== EOfferActivateError.InappropriateTargeting;
  const isReactivationSupported = !unavailabilityReasons.some(
    reason => reason === EOfferActivateError.OfferActivationAlreadyExist
  );
  const isUserBalanceNotEnough = !balance?.balance || balance?.balance < (offerPrice ?? 0);

  return {
    isLoadActivationsFetching,
    isLoadActivationsFailed,
    isReactivationSupported,
    activationsReload,

    isActivationAvailable,
    isUserBalanceNotEnough,

    activateOffer,
    activationError,
    activationIsFetching,
    activations,

    isLastActivationPending,
    lastActivation,
    lastReceivedActivation,
  };
};

export default useOfferActivations;
