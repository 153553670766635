import { useCallback, useState } from 'react';
import CorpOfferActivateDialog from '../../../notification/dialogs/corpOfferActivate';
import { AppNotificationsReceiverCallbackProps, ENotificationMessageType } from '../../../notification/types';
import useNotificationSocket from '../../../notification/useNotificationSocket';

type CorpOfferActivateDialogState = {
  readonly title: string;
  readonly description: string;
};

const CorpOffersNotificationsReceiver = () => {
  const [corpOfferActivateDialogState, setCorpOfferActivateDialogState] =
    useState<Nullable<CorpOfferActivateDialogState>>(null);

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>(msg => {
    switch (msg.type) {
      case ENotificationMessageType.OfferActivationChangedMessage:
        setCorpOfferActivateDialogState({
          title: msg.title,
          description: msg.description,
        });
        return;
      default:
        return;
    }
  }, []);

  useNotificationSocket({ onMessageReceive });

  return (
    <>
      <CorpOfferActivateDialog
        open={corpOfferActivateDialogState !== null}
        title={corpOfferActivateDialogState?.title ?? ''}
        description={corpOfferActivateDialogState?.description ?? ''}
        onClose={() => setCorpOfferActivateDialogState(null)}
      />
    </>
  );
};

export default CorpOffersNotificationsReceiver;
