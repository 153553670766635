import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  partnerDeskDetailsAspOffersCountSelector,
  partnerDeskDetailsAspOffersIsErrorSelector,
  partnerDeskDetailsAspOffersIsFetchingSelector,
} from '../../store/selectors';

const PartnerDeskDetailsTabAspOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsAspOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsAspOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsAspOffersIsErrorSelector);

  return useMemo(
    () => (!count && !isFetching && !isError ? <div>Торговые предложения пока отсутствуют</div> : null),
    [count, isFetching, isError]
  );
};

export default PartnerDeskDetailsTabAspOffersNoData;
