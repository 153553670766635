import styled from '@emotion/styled/macro';
import { Container, Grid } from '@mui/material';
import { EParentGridAreas } from '../bookingDetail/types';

type ParentLayoutGridAreaProps = {
  readonly area: EParentGridAreas;
};

export const FitContainer = styled(Container)`
  [area=${EParentGridAreas.Inner}] {
    ${p => p.theme.breakpoints.up('md')} {
      padding-top: ${p => p.theme.spacing(2)};
      padding-bottom: ${p => p.theme.spacing(12.5)};
    }
  }
`;

export const ParentLayoutGridArea = styled(Grid)<ParentLayoutGridAreaProps>`
  grid-area: ${p => p.area};
`;
