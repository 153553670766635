import { useContext, useEffect } from 'react';
import { BookingOrder } from 'domain/model/order';
import ErrorHandler from 'data/network/errorHandler';
import { useGetUserOrderQuery } from 'data/api/user';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';

const useBookingOfferOrderData = (
  id: UUID
): {
  order?: BookingOrder;
  isFetching: boolean;
  isLoading: boolean;
} => {
  const { setError } = useContext(RenderManagerErrorContext);

  const {
    data: order,
    isFetching,
    isLoading,
    error: orderFetchError,
  } = useGetUserOrderQuery(id, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (orderFetchError) {
      ErrorHandler.handleHttpError(orderFetchError);
      setError(orderFetchError);
    }
  }, [setError, orderFetchError]);

  return {
    order: order as BookingOrder,
    isFetching,
    isLoading,
  };
};

export default useBookingOfferOrderData;
