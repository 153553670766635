import styled from '@emotion/styled/macro';
import { Grid, Typography } from '@mui/material';

export const ListCounter = styled(Typography)`
  position: absolute;
  left: 0;
`;

export const NumberedList = styled(Grid)`
  .MuiGrid-item {
    position: relative;
  }

  &.MuiGrid-spacing-xs-2 {
    > .MuiGrid-item {
      padding-left: ${p => p.theme.spacing(5)};
    }

    ${ListCounter} {
      margin-left: ${p => p.theme.spacing(2)};
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    &.MuiGrid-spacing-xs-1 {
      > .MuiGrid-item {
        padding-left: ${p => p.theme.spacing(3)};
      }

      ${ListCounter} {
        margin-left: ${p => p.theme.spacing(1)};
      }
    }
  }
`;
