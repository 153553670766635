import HeaderLayout from './layout';
import { HeaderLayoutProps } from './layout/types';

const Layout = HeaderLayout;

type HeaderProps = HeaderLayoutProps;

const Header = (props: HeaderProps) => {
  return <Layout {...props} />;
};

export default Header;
