import { Wrapper } from './controls';
import { CategorySelectDoubleLayoutType } from './types';

const CategorySelectDoubleLayout: CategorySelectDoubleLayoutType = props => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default CategorySelectDoubleLayout;
