import ScreenLayout from 'presentation/layouts/screen';
import AboutCookiesContainer from '../../features/about/cookies/container';

const LegalCookiesScreen = () => {
  return (
    <ScreenLayout>
      <AboutCookiesContainer />
    </ScreenLayout>
  );
};

export default LegalCookiesScreen;
