import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep5 = () => {
  return (
    <>
      <Text>
        10. В целях эффективной организации процессов обработки и обеспечения безопасности персональных данных в ОАО
        «РЖД» генеральный директор – председатель правления ОАО «РЖД» назначает из числа своих заместителей
        ответственного за организацию обработки персональных данных в ОАО «РЖД», который в соответствии с установленными
        полномочиями обеспечивает:
      </Text>

      <Text>
        1) осуществление внутреннего контроля за соблюдением в ОАО «РЖД» требований законодательства Российской
        Федерации и нормативных документов ОАО «РЖД» в области персональных данных, в том числе требований к защите
        персональных данных в ОАО «РЖД»;
      </Text>

      <Text>
        2) доведение до сведения работников ОАО «РЖД» положений законодательства Российской Федерации, нормативных
        документов ОАО «РЖД» по вопросам обработки персональных данных, а также требований по защите персональных
        данных;
      </Text>

      <Text>
        3) контроль за обработкой обращений и запросов субъектов персональных данных или их представителей по фактам
        нарушений законодательства в области персональных данных, допущенных работниками ОАО «РЖД».
      </Text>

      <Text>
        11. К обработке персональных данных допускаются руководители ОАО «РЖД», наделенные этим правом работодателем, а
        также работники ОАО «РЖД», уполномоченные на обработку персональных данных в установленном ОАО «РЖД» порядке.
      </Text>

      <Text>
        Указанные руководители и работники имеют право обрабатывать только те персональные данные, которые необходимы им
        для выполнения своих должностных обязанностей.
      </Text>

      <Text>
        12. Обработка персональных данных в ОАО «РЖД» осуществляется с использованием средств автоматизации и (или) без
        использования таких средств.
      </Text>

      <Text>
        13. Обработка специальных категорий персональных данных, а также биометрических персональных данных в ОАО «РЖД»
        осуществляется в соответствии с требованиями законодательства Российской Федерации.
      </Text>

      <Text>
        14. В целях реализации прав субъектов персональных данных ОАО «РЖД» при обработке их персональных данных:
      </Text>

      <Text>
        1) применяет правовые, организационные и технические меры для защиты персональных данных субъектов персональных
        данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
        предоставления, распространения, а также от иных неправомерных действий;
      </Text>

      <Text>
        2) разъясняет субъектам персональных данных юридические последствия отказа предоставить их персональные данные и
        (или) дать согласие на их обработку, если предоставление персональных данных является обязательным в
        соответствии с законодательством Российской Федерации;
      </Text>

      <Text>
        3) осуществляет блокирование, уточнение и уничтожение неправомерно обрабатываемых персональных данных, а также
        прекращение их неправомерной обработки;
      </Text>

      <Text>
        4) уведомляет субъекта персональных данных об устранении допущенных нарушений или уничтожении его персональных
        данных;
      </Text>

      <Text>
        5) предоставляет по запросу субъекта персональных данных или его представителя информацию, касающуюся обработки
        его персональных данных, в порядке, установленном законодательством Российской Федерации, а также нормативными
        документами ОАО «РЖД»;
      </Text>

      <Text>
        6) осуществляет внутренний контроль и (или) аудит соответствия обработки персональных данных законодательству
        Российской Федерации и нормативным документам ОАО «РЖД»;
      </Text>

      <Text>
        7) проводит оценку вреда, который может быть причинен субъектам персональных данных в случае нарушения
        законодательства Российской Федерации о персональных данных, соотношения указанного вреда и принимаемых ОАО
        «РЖД» мер, направленных на обеспечение выполнения обязанностей, предусмотренных законодательством Российской
        Федерации в области персональных данных.
      </Text>

      <Text>
        15. Обработка персональных данных, разрешенных субъектом персональных данных для распространения, осуществляется
        с соблюдением запретов и условий, установленных статьей 10.1 Федерального закона «О персональных данных».
      </Text>

      <Text>
        Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения,
        оформляется отдельно от иных согласий субъекта персональных данных.
      </Text>

      <Text>
        16. Передача персональных данных третьим лицам осуществляется с письменного согласия субъектов персональных
        данных, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и здоровью субъектов
        персональных данных, а также в иных случаях, установленных законодательством Российской Федерации.
      </Text>

      <Text>
        17. Передача персональных данных органам государственной власти допускается в отсутствие согласия субъекта
        персональных данных на обработку его персональных данных в порядке и в случаях, предусмотренных
        законодательством Российской Федерации.
      </Text>

      <Text>
        18. Трансграничная передача персональных данных осуществляется с учетом ограничений и запретов, предусмотренных
        законодательством Российской Федерации в области персональных данных.
      </Text>

      <Text>До начала осуществления трансграничной передачи персональных данных ОАО «РЖД»:</Text>

      <Text>
        проводит оценку мер, принимаемых органами власти иностранного государства, иностранными физическими и
        юридическими лицами, которым планируется трансграничная передача персональных данных, по обеспечению
        конфиденциальности и безопасности персональных данных;
      </Text>

      <Text>
        уведомляет уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять
        трансграничную передачу персональных данных.
      </Text>

      <Text>
        19. ОАО «РЖД» вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных
        данных, если иное не предусмотрено законодательством Российской Федерации, на основании заключаемого с этим
        лицом договора.
      </Text>

      <Text>
        20. При сборе персональных данных, в том числе с использованием информационно-телекоммуникационной сети
        «Интернет», ОАО «РЖД» обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся
        на территории Российской Федерации, за исключением случаев, предусмотренных законодательством Российской
        Федерации.
      </Text>

      <Text>
        21. Обеспечение безопасности персональных данных, в том числе при их обработке в информационных системах,
        осуществляется в соответствии с законодательством Российской Федерации и требованиями уполномоченного органа по
        защите прав субъектов персональных данных, федерального органа исполнительной власти, уполномоченного в области
        обеспечения безопасности, и федерального органа исполнительной власти, уполномоченного в области противодействия
        техническим разведкам и технической защиты информации.
      </Text>

      <Text>
        22. Сроки обработки и хранения персональных данных для каждой цели обработки персональных данных в ОАО «РЖД»,
        предусмотренной приложением № 1 к настоящей Политике, определяются с учетом соблюдения требований
        законодательства Российской Федерации и (или) с учетом положений договора, стороной, выгодоприобретателем или
        поручителем по которому выступает субъект персональных данных и (или) согласия субъекта персональных данных на
        обработку его персональных данных.
      </Text>

      <Text>
        23. Порядок и способы уничтожения персональных данных в ОАО «РЖД» определяются в соответствии с
        законодательством Российской Федерации и нормативными документами ОАО «РЖД».
      </Text>

      <Text>Персональные данные подлежат уничтожению в следующих случаях:</Text>

      <Text>
        1) по достижении целей обработки или в случае утраты необходимости в достижении целей обработки персональных
        данных;
      </Text>

      <Text>
        2) в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных, за исключением
        случаев, предусмотренных законодательством Российской Федерации;
      </Text>

      <Text>
        3) при предоставлении субъектом персональных данных или его представителем сведений, подтверждающих, что
        персональные данные:
      </Text>

      <Text>
        а) являются неполными, устаревшими, неточными (при условии, что уточнение персональных данных невозможно);
      </Text>

      <Text>б) персональные данные получены незаконно;</Text>

      <Text>в) персональные данные не являются необходимыми для заявленной цели обработки;</Text>

      <Text>
        4) при выявлении неправомерной обработки персональных данных (в случае невозможности обеспечить правомерность
        обработки персональных данных);
      </Text>

      <Text>
        5) в случае обращения субъекта персональных данных с требованием о прекращении обработки персональных данных, за
        исключением случаев, предусмотренных законодательством Российской Федерации.
      </Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep5;
