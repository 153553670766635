import { SearchOffersRequest } from 'data/api/search';
import store from 'data/store/store';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { EOfferType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { PaginationSize } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { searchOffersDefaultParams } from '../../utils';
import { searchOffersSelector } from '../store/selectors';
import {
  searchOffersSetArgs,
  searchOffersSetIsNewFetching,
  searchOffersSetPage,
  searchOffersSetPageSize,
  searchOffersStartSession,
} from '../store/slice';
import { BookingOfferShort } from '../../../../../domain/model/bookingOffer';

type UseSearchOffersHandlersProps = {
  readonly guid: UUID;
  readonly query: Nullable<string>;
  readonly offerType?: Nullable<EOfferType[]>;
};

export type UseSearchOffers = {
  readonly onLoadMore: () => void;
  readonly onShowCard: (offer: TradeOfferShort | CorpOfferShort | BookingOfferShort) => void;
  readonly onChangePageSize: (newPageSize: PaginationSize) => void;
};

const useSearchOffersHandlers = (props: UseSearchOffersHandlersProps): UseSearchOffers => {
  const { guid, query, offerType } = props;

  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();

  const getCurrentState = useCallback(() => {
    return searchOffersSelector(store.getState());
  }, []);

  const onLoadMore = useCallback<UseSearchOffers['onLoadMore']>(() => {
    const state = getCurrentState();
    dispatch(searchOffersSetIsNewFetching(false));
    dispatch(searchOffersSetPage((state.args?.page ?? 0) + 1));
  }, [dispatch, getCurrentState]);

  const onShowCard = useCallback<UseSearchOffers['onShowCard']>(
    offer => webAnalytics.offerShowInSearch(offer.id),
    [webAnalytics]
  );

  const onChangePageSize = useCallback<UseSearchOffers['onChangePageSize']>(
    pageSize => {
      dispatch(searchOffersSetIsNewFetching(false));
      dispatch(searchOffersSetPageSize(pageSize));
    },
    [dispatch]
  );

  useEffect(() => {
    const emptyParams = searchOffersDefaultParams;

    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentArgs = state.args ?? emptyParams;
    const currentQuery = currentArgs?.query;

    const isGuidChanged = currentGuid !== guid;
    const isQueryChanged = (currentQuery || null) !== (query || null);

    if (isGuidChanged) {
      const args: SearchOffersRequest = {
        ...emptyParams,
        query,
        offerType,
      };

      dispatch(searchOffersStartSession({ guid, args }));
    } else {
      const args: SearchOffersRequest = {
        ...currentArgs,
        query,
        offerType,
      };

      dispatch(searchOffersSetArgs(args));
    }

    if (isGuidChanged || isQueryChanged) {
      dispatch(searchOffersSetIsNewFetching(true));
    } else {
      dispatch(searchOffersSetIsNewFetching(false));
    }
  }, [dispatch, query, guid, getCurrentState, offerType]);

  return {
    onLoadMore,
    onShowCard,
    onChangePageSize,
  };
};

export default useSearchOffersHandlers;
