import { OfferActivation } from '../../../domain/model/activation';
import { AppDispatch, RootState } from '../../store/store';
import { userApi } from '../user';
import { api, ECacheServicesTag, ECorpOfferServicesTag, ETradeOfferServicesTag, EUserServicesTag } from '../index';
import { CreatePatchesResult, OfferActivationPatchedType, PatchTag } from './types';
import { isOfferActivation, isOfferActivationCollection, isOfferActivationPageable } from './utils';

type CreateUserNewActivationPatchesProps = {
  readonly newActivation: OfferActivation;
  readonly state: RootState;
  readonly dispatch: AppDispatch;
};

//выполнение апдейтов в списках с активациями
export const createUserNewActivationPatches = ({
  newActivation,
  state,
  dispatch,
}: CreateUserNewActivationPatchesProps): CreatePatchesResult => {
  const {
    id,
    offer: { id: offerId },
  } = newActivation;

  //формируем оптимальный набор тэгов
  const tags: PatchTag[] = [
    {
      type: EUserServicesTag.Activations,
      id,
    },
    {
      type: ECorpOfferServicesTag.Activations,
      id: offerId,
    },
    {
      type: ETradeOfferServicesTag.Activations,
      id: offerId,
    },
    {
      type: ECacheServicesTag.Common,
      id,
    },
  ];

  //создаём набор патчей
  return api.util.selectInvalidatedBy(state, tags).map(cache => {
    const { originalArgs, endpointName } = cache;
    return dispatch(
      userApi.util.updateQueryData(endpointName as any, originalArgs, draft => {
        const dataObject = draft as OfferActivationPatchedType;

        if (isOfferActivationCollection(dataObject)) {
          const changedOfferActivation = dataObject?.find(item => item.id === id);
          if (changedOfferActivation) {
            Object.assign(changedOfferActivation, newActivation);
          } else {
            dataObject.push(newActivation);
          }
        } else if (isOfferActivationPageable(dataObject)) {
          const changedOfferActivation = dataObject?.data?.find(item => item.id === id);
          if (changedOfferActivation) {
            Object.assign(changedOfferActivation, newActivation);
          } else {
            dataObject?.data?.push(newActivation);
          }
        } else if (isOfferActivation(dataObject) && dataObject?.id === id) {
          Object.assign(dataObject, newActivation);
        }
      })
    );
  });
};

type CreateUserNewActivationTagsToInvalidateProps = {
  readonly newActivation: OfferActivation;
};

//выполнение апдейтов в списках с активациями
export const createUserNewActivationTagsToInvalidate = ({
  newActivation,
}: CreateUserNewActivationTagsToInvalidateProps): PatchTag[] => {
  const {
    offer: { id: offerId },
  } = newActivation;
  return [
    EUserServicesTag.Activations,
    {
      type: ECorpOfferServicesTag.Details,
      offerId,
    },
    {
      type: ETradeOfferServicesTag.Details,
      offerId,
    },
  ];
};
