import { CategoryTreeItem } from 'domain/model/nsi';
import { useMemo, useState } from 'react';
import CategorySelectItemWrapper from '../selectItemWrapper';
import CategorySelectLeaf from '../selectLeaf';
import { FirstLeaf } from './controls';
import CategorySelectShowMore from './showMore';

type CategorySelectDoubleProps = {
  readonly category: CategoryTreeItem;
  readonly selected: Nullable<CategoryTreeItem>;
  readonly isInSelectedPath: (category: CategoryTreeItem) => boolean;
  readonly initialVisibleCount: number;
  readonly onClick: (category: CategoryTreeItem) => void;
  readonly scrollToCategory: (el: HTMLElement) => void;
};

//вывод категорий дуплексом - родитель и под ним все дети, причем с возможностью свернуть/развернуть детей
const CategorySelectDouble = (props: CategorySelectDoubleProps) => {
  const { category, initialVisibleCount, selected, isInSelectedPath, onClick, scrollToCategory } = props;

  const childrenCount = category.children?.length ?? 0;

  const isSelectedFirstLeaf = useMemo<boolean>(() => isInSelectedPath(category), [category, isInSelectedPath]);

  //необходимость развернуть список категорий в случае если выбранная категория скрыта
  const needExpand =
    isSelectedFirstLeaf &&
    selected &&
    category.children?.findIndex(item => item.id === selected.id) > initialVisibleCount;

  const [visibleCount, setVisibleCount] = useState<number>(needExpand ? childrenCount : initialVisibleCount);

  const onExpandOrCollapse = () => {
    if (visibleCount === childrenCount) {
      setVisibleCount(initialVisibleCount);
    } else {
      setVisibleCount(childrenCount);
    }
  };

  const visibleItems = category.children?.slice(0, visibleCount);
  const expanded = visibleCount > initialVisibleCount;

  return (
    <div>
      <FirstLeaf>
        <CategorySelectItemWrapper>
          <CategorySelectLeaf
            onClick={onClick}
            category={category}
          />
        </CategorySelectItemWrapper>
      </FirstLeaf>
      {visibleItems?.map(child => (
        <CategorySelectItemWrapper key={child.id}>
          <CategorySelectLeaf
            category={child}
            selected={isInSelectedPath(child)}
            scrollToCategory={scrollToCategory}
            onClick={onClick}
          />
        </CategorySelectItemWrapper>
      ))}
      {category.children?.length > initialVisibleCount && (
        <CategorySelectItemWrapper onClick={() => null}>
          <CategorySelectShowMore
            expanded={expanded}
            onExpandOrCollapse={onExpandOrCollapse}
          />
        </CategorySelectItemWrapper>
      )}
    </div>
  );
};

export default CategorySelectDouble;
