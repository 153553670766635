import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const slider: OverrideThemeFunc<Components> = baseTheme => ({
  MuiSlider: {
    defaultProps: {
      size: 'medium',
      color: 'primary',
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        height: ownerState.size === 'small' ? '8px' : ownerState.size === 'medium' ? '12px' : '16px',
        // Регулируем заезд элементов управления за родительский блок
        ...(Array.isArray(ownerState.value) && { width: 'calc(100% - 16px)', marginLeft: 8, boxSizing: 'border-box' }),
      }),
      rail: ({ ownerState }) => ({
        backgroundColor: baseTheme.palette.secondary.A600,
        ...(Array.isArray(ownerState.value) && { marginLeft: '-8px', width: 'calc(100% + 16px)' }),
      }),
      thumb: ({ ownerState }) => ({
        width: ownerState.size === 'small' ? '12px' : ownerState.size === 'medium' ? '16px' : '20px',
        height: ownerState.size === 'small' ? '12px' : ownerState.size === 'medium' ? '16px' : '20px',

        backgroundColor: baseTheme.palette.white.main,
        borderColor: 'currentColor',
        borderWidth: 2,
        borderStyle: 'solid',

        ':before': {
          boxShadow: 'none',
        },
      }),
    },
  },
});

export default slider;
