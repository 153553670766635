import { RootState } from 'data/store/store';

export const productOfferFilterValuesSelector = (store: RootState) =>
  store.productOffer.filter.filter ?? store.productOffer.filter.priceRangeTo;

export const productOfferFilterMinPriceSelector = ({ productOffer: { filter } }: RootState) =>
  filter.filter?.minPrice ?? filter.priceRangeFrom;

export const productOfferFilterMaxPriceSelector = ({ productOffer: { filter } }: RootState) =>
  filter.filter?.maxPrice ?? filter.priceRangeTo;

export const productOfferFilterPriceRangeSelector = ({
  productOffer: {
    filter: { priceRangeFrom, priceRangeTo },
  },
}: RootState) => ({
  minPrice: priceRangeFrom,
  maxPrice: priceRangeTo,
});
