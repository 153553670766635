import ContentLoader from 'presentation/components/common/loader';
import useOrderDetailsFetchingStatus from '../hooks/useOrderDetailsFetchingStatus';

type OrderDetailsLoaderAdapterProps = {
  readonly id: UUID;
};

const OrderDetailsLoaderAdapter = ({ id }: OrderDetailsLoaderAdapterProps) => {
  const isFetching = useOrderDetailsFetchingStatus({ id });

  return isFetching ? <ContentLoader position='fixed' /> : null;
};

export default OrderDetailsLoaderAdapter;
