import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';

type OrderDetailStubProps = {
  readonly text: string;
};

const OrderDetailStub = ({ text }: OrderDetailStubProps) => {
  return <OfferDetailsErrorLayout text={text} />;
};

export default OrderDetailStub;
