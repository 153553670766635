import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledLightButton = styled(MPButton)(
  ({ theme }) => css`
    &.MuiButtonBase-root {
      border: 0;

      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};

      ${theme.breakpoints.down('sm')} {
        padding-left: ${theme.spacing(1.5)};
        padding-right: ${theme.spacing(1.5)};
      }

      background-color: ${theme.palette.primary.A500};
    }

    * {
      font-weight: ${theme.typography.fontWeightBold};
      line-height: 1rem;
    }
  `
);
