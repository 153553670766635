import { Link, Typography } from '@mui/material';
import { getFileDownloadEndpoint } from 'data/openApi/utils';
import LightButton from 'presentation/components/common/buttons/light';

type FileAttributeProps = {
  readonly label: string;
  readonly href: string;
};

const FileAttribute = (props: FileAttributeProps) => {
  const { label, href } = props;

  const hrefOut = href.startsWith('http') ? href : getFileDownloadEndpoint({ id: href });

  return (
    <Link
      href={hrefOut}
      component={LightButton}
      underline='none'
      size='small'
    >
      <Typography variant='body2'>{label}</Typography>
    </Link>
  );
};

export default FileAttribute;
