import { CartIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemCartProps = {
  readonly count?: number;
  readonly onClick?: () => void;
};

export const UserMenuItemCart: FC<UserMenuItemCartProps> = ({ count, onClick }) => {
  return (
    <UserMenuItem
      label='Корзина'
      count={count}
      icon={<CartIcon color='black' />}
      onClick={onClick}
    />
  );
};
