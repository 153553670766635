import { useMemo } from 'react';
import PartnerDesksFilterContainer from '../../filter/container';
import usePartnerDeskList from '../provider/usePartnerDeskList';

const PartnerDeskListFilterAdapter = () => {
  const { currentFilter, onChangeFilter } = usePartnerDeskList();

  return useMemo(
    () => (
      <PartnerDesksFilterContainer
        value={currentFilter}
        isFetching={false}
        onChange={onChangeFilter}
      />
    ),
    [currentFilter, onChangeFilter]
  );
};

export default PartnerDeskListFilterAdapter;
