import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';
import { useGetUserOrderQuery } from '../../../../../../data/api/user';

const useOrderDetailsData = (id: UUID) => {
  const {
    data: order,
    isFetching,
    isLoading,
    error: orderFetchError,
  } = useGetUserOrderQuery(id, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (orderFetchError) {
      ErrorHandler.handleHttpError(orderFetchError);
    }
  }, [orderFetchError]);

  return {
    order,
    isFetching,
    isLoading,
  };
};

export default useOrderDetailsData;
