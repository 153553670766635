import { Typography } from '@mui/material';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';

type CorpOfferDetailsTitleAdapterProps = {
  readonly id: UUID;
};

const CorpOfferDetailsTitleAdapter = ({ id }: CorpOfferDetailsTitleAdapterProps) => {
  const { corpOffer } = useCorpOfferDetailsData(id);

  if (!corpOffer) {
    return null;
  }

  return <Typography variant='h1'>{corpOffer.name}</Typography>;
};

export default CorpOfferDetailsTitleAdapter;
