import { AccountBalance } from 'domain/model/user';
import { useSelector } from 'react-redux';
import { currentUserBalancesSelector } from './store/selectors';

const useCurrentUserBalances = (): Nullable<AccountBalance[]> => {
  /*const { hasFeature } = useTechConfig();*/
  const balances = useSelector(currentUserBalancesSelector);

  /*return (balances ?? []).filter(item => item.type !== EBalanceType.ASP || hasFeature(EAppFeature.Asp));*/
  return balances ? [balances] : null;
};

export default useCurrentUserBalances;
