import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { StyledDottedButton } from './controls';

type CorpOfferClaimCertificateActivationProps = {
  readonly disabled: boolean;
  readonly isFetching: boolean;
  readonly onBuy: () => void;
};

export const CorpOfferClaimCertificateActivation = (props: CorpOfferClaimCertificateActivationProps) => {
  const { disabled, isFetching, onBuy } = props;

  return (
    <StyledDottedButton
      disabled={disabled}
      fetching={isFetching}
      fullWidth
      onClick={onBuy}
    >
      <Typography
        variant='h2'
        color={isFetching ? 'transparent' : undefined}
      >
        Обменять
      </Typography>
      {isFetching && <ContentLoader />}
    </StyledDottedButton>
  );
};
