import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';

export const CurrentActivateErrrorPausedOfferWarnMessage = () => {
  return (
    <ClaimMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        Ошибка при получении. Попробуйте ещё раз позже
      </Typography>
    </ClaimMessage>
  );
};
