import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';

type AspOfferPartnerInfoProps = {
  readonly id: UUID;
};

export const AspOfferPartnerInfoAdapter = ({ id }: AspOfferPartnerInfoProps) => {
  const history = useHistory();
  const { aspOffer } = useAspOfferDetailsData(id);
  const { partnerDesk } = useAspOfferPartnerDeskData(id);
  const onPartnerClick = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id, tab: EPartnerDeskDetailsTab.AspOffers }));
    }
  };

  if (!aspOffer?.partner) {
    return null;
  }

  return (
    <OfferPartnerInfo
      partner={aspOffer.partner}
      onClick={onPartnerClick}
    />
  );
};

