import AboutPersonalDataPolicyContainer from 'presentation/features/about/personalDataPolicy/container';
import ScreenLayout from 'presentation/layouts/screen';

const LegalPersonalDataPolicyScreen = () => {
  return (
    <ScreenLayout>
      <AboutPersonalDataPolicyContainer />
    </ScreenLayout>
  );
};

export default LegalPersonalDataPolicyScreen;
