import styled from '@emotion/styled/macro';
import { Collapse, css } from '@mui/material';
import { MPBreadcrumbs } from 'presentation/theme/ui-kit/breadcrumbs';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const BreadcrumbExpandButton = styled(MPButton)`
  &.MuiButtonBase-root {
    border: 0;
    background-color: ${p => p.theme.palette.secondary.A600};
    padding-bottom: 0;
    padding-top: 0;
    padding-left: ${p => p.theme.spacing(0.1)};
    padding-right: ${p => p.theme.spacing(0.6)};
    min-width: 1rem;
    min-height: auto;
    border-radius: ${p => p.theme.shape.borderRadius}px;
  }

  svg {
    width: ${p => p.theme.spacing(2.2)};
    height: ${p => p.theme.spacing(2.2)};
  }

  .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightMedium};
  }
`;

export const StyledBreadcrumbs = styled(MPBreadcrumbs)`
  & .MuiBreadcrumbs-ol {
    align-items: baseline;
    flex-wrap: nowrap;
  }
`;

type BreadcrumbItemWrapper = {
  readonly hidden?: boolean;
};

export const BreadcrumbItemWrapper = styled.div<BreadcrumbItemWrapper>(
  ({ hidden }) => css`
    display: flex;
    ${hidden && `visibility: hidden;`}
  `
);

export const BreadcrumbCollapse = styled(Collapse)(
  ({ theme }) => css`
    > :first-of-type {
      padding-top: ${theme.spacing()};
      padding-left: ${theme.spacing()};
    }
  `
);
