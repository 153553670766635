import { FCC } from 'react';
import CorpOfferClaimProvider from './provider/provider';
import CorpOfferClaimDialogsProvider from './dialogProvider/provider';
import { CorpOffer } from '../../../../../domain/model/corpOffer';

type CorpOfferClaimProvidersProps = {
  readonly corpOffer: CorpOffer;
  readonly onShowAllOffers: () => void;
};

const CorpOfferClaimProviders: FCC<CorpOfferClaimProvidersProps> = props => {
  const { children, corpOffer, onShowAllOffers } = props;

  return (
    <CorpOfferClaimProvider
      id={corpOffer.id}
      promotionType={corpOffer.promotionType}
    >
      <CorpOfferClaimDialogsProvider
        corpOffer={corpOffer}
        onShowAllOffers={onShowAllOffers}
      >
        {children}
      </CorpOfferClaimDialogsProvider>
    </CorpOfferClaimProvider>
  );
};

export default CorpOfferClaimProviders;
