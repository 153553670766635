import { Typography } from '@mui/material';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { Wrapper } from './controls';

const OfferCodeAdapter = () => {
  const { aspOffer } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Предложение: {aspOffer.code}</Typography>
    </Wrapper>
  );
};

export default OfferCodeAdapter;
