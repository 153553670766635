import { FabProps } from '@mui/material';
import { User } from 'domain/model/user';
import { UserAvatar } from 'presentation/components/common/avatars';
import ContentLoader from 'presentation/components/common/loader';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import UserBadge from '../badge';

type HeaderUserShortProps = Pick<FabProps, 'size'> & {
  readonly user: User;
  readonly balance?: ReactNode;
  readonly isFetching?: boolean;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const HeaderUserShort = forwardRef((props: HeaderUserShortProps, ref: any) => {
  const { user, balance, isFetching, size = 'medium', onClick } = props;

  return (
    <UserBadge balance={balance}>
      <MPFab
        ref={ref}
        size={size}
        variant='squared'
        disabled={isFetching}
        onClick={onClick}
      >
        <UserAvatar
          user={user}
          size='inherit'
        />
        {isFetching && <ContentLoader />}
      </MPFab>
    </UserBadge>
  );
});
