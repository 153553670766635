import { TokenExpiredConfig } from '@mp-npm/mp-auth-client';
import { ENetworkErrorCode, ServerErrorResponse } from 'data/network/types';

export const authCheckSsoPage = 'check-sso.html';
export const authCheckIdpLinkPage = 'check-idp-link.html';

export enum EAuthAction {
  LoginSuccess = 'check-sso-success',
  RzhdPortalBindSuccess = 'auth-rzhdPortal-bind-success',
}

export enum EAuthError {
  NotAllowed = 'not_allowed',
  UnknownIdentityProvider = 'unknown_identity_provider',
  NotLoggedIn = 'not_logged_in',
}

export type AuthAction = {
  readonly message: EAuthAction;
  readonly error?: EAuthError;
};

export const getCheckSsoPath = () => `${window.location.origin}/${authCheckSsoPage}`;

//факт того, что ошибка вызвана бизнес-логикой, для таких ситуаций обработка отдельная в нужных местах
export const isSecurityBusinessError = (data: ServerErrorResponse): boolean => {
  return data?.code === ENetworkErrorCode.MissingUserAgreement;
};
export const isMissingUserAgreementError = (data: ServerErrorResponse): boolean => {
  return data?.code === ENetworkErrorCode.MissingUserAgreement;
};

export const tokenExpiredConfig: TokenExpiredConfig = {
  secondsOnTokenExpired: -1,
  secondsToExpireToken: 30,
  msToCheckInterval: 5000,
};
