import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import UserOrderContainer from '../../features/user/details/order/container';
import { useParams } from 'react-router';

const UserOrderScreen = () => {
  const { id } = useParams<{ id: UUID }>();

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout>
          <UserOrderContainer
            guid={guid}
            id={id}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserOrderScreen;
