import { ErrorBoundary } from '@sentry/react';
import { FCC } from 'react';
import ErrorBoundaryContent from './content';

type ErrorBoundarySentryProps = {
  readonly onLogout?: (() => void) | false;
};

const ErrorBoundarySentry: FCC<ErrorBoundarySentryProps> = ({ children, onLogout }) => {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorBoundaryContent
          message={error.toString()}
          stacktrace={componentStack}
          onLogout={onLogout}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundarySentry;
