import { CloseFab } from './controls';
import { CloseIcon } from '../../../../../../media/icons';

type CloseProps = {
  readonly onClick: () => void;
};

const Close = ({ onClick }: CloseProps) => {
  return (
    <CloseFab
      size='medium'
      variant='circular'
      onClick={onClick}
    >
      <CloseIcon />
    </CloseFab>
  );
};

export default Close;
