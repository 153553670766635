import { useSelector } from 'react-redux';
import { corpOfferListNoDataSelector, corpOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import CorpOffersStub from '../stub/common';

export const CorpOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(corpOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(corpOfferListNoDataSelector);

  if (noData) {
    return <CorpOffersStub isEmptySearch={isEmptySearch} />;
  }

  return null;
};
