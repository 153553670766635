import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';

type UserNeedFillProfileDialogProps = {
  readonly open: boolean;
  readonly message?: string;
  readonly onFill: () => void;
  readonly onClose: () => void;
};

/** todo по идее надо как-то централизованно с ним работать, а не из фич */
const UserNeedFillProfileDialog = (props: UserNeedFillProfileDialogProps) => {
  const { open, message, onFill, onClose } = props;

  return (
    <MPConfirmDialog
      title='Необходимо заполнить профиль'
      open={open}
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          onClick={onFill}
        >
          Заполнить профиль
        </MPButton>
      }
    >
      {message || 'Для получения предложения необходимо заполнить профиль'}
    </MPConfirmDialog>
  );
};

export default UserNeedFillProfileDialog;
