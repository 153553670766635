import BusinessConfigContext, {
  BusinessConfigContextType,
} from 'presentation/features/general/config/contexts/businessConfigContext';
import { useContext } from 'react';

type UseBusinessConfig = () => BusinessConfigContextType;

const useBusinessConfig: UseBusinessConfig = () => {
  return useContext<BusinessConfigContextType>(BusinessConfigContext);
};

export default useBusinessConfig;
