/** факт доступности элемента корзины для оформления заказа */
import { CartItem } from 'domain/model/cart';
import { EOfferStatus } from 'domain/model/enums';

export const isCartItemEnabled = (item: CartItem): boolean => {
  const count = item.offer.stock ?? 0;
  return item.offer.status === EOfferStatus.Active && count > 0;
};

export const isCartItemStockOutOfQuantity = (item: CartItem): boolean => {
  const count = item.offer.stock ?? 0;
  return item.quantity > count;
};
