import { Hidden, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EAppCategories } from 'presentation/types';

type ProductOffersCategoryHeaderProps = {
  readonly sortSelectComponent: JSX.Element;
};

export const ProductOffersCommonHeader = ({ sortSelectComponent }: ProductOffersCategoryHeaderProps) => {
  const { x5 } = useGridSpacing();
  return (
    <>
      <MPGrid
        container
        alignItems='center'
      >
        <MPGrid
          item
          zero={12}
          sm
        >
          <Typography
            variant='h2'
            component='h1'
          >
            {EAppCategories.ProductOffers}
          </Typography>
        </MPGrid>
        <MPGrid item>
          <Hidden smUp>
            <Splitter size={1.5} />
          </Hidden>
          {sortSelectComponent}
        </MPGrid>
      </MPGrid>
      <Splitter size={x5} />
    </>
  );
};
