import { CmsContainerSlotProps } from '../../types';
import { Wrapper } from './controls';

type CmsContainerSplitterProps = CmsContainerSlotProps;

const CmsContainerSplitter = ({ hasContent }: CmsContainerSplitterProps) => {
  if (!hasContent) return null;

  return <Wrapper />;
};

export default CmsContainerSplitter;
