import { Text } from './controls';

const AboutTermsOfUsePartyContentInfo = () => {
  return (
    <>
      <Text>
        6.1. Платформа может содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица
        и их контент не проверяются Правообладателем на соответствие тем или иным требованиям (достоверности, полноты,
        законности и т.п.). Правообладатель не несет ответственность за любую информацию, материалы, размещенные на
        сайтах третьих лиц, к которым Пользователь получает доступ с использованием Платформы, в том числе, за любые
        мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов
        или контента и последствия их использования Пользователем.
      </Text>
      <Text>
        6.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого
        характера, размещенная в Платформе, не является одобрением или рекомендацией данных продуктов (услуг,
        деятельности) со стороны Правообладателя, за исключением случаев, когда на это прямо указывается на ресурсах
        Правообладателя.
      </Text>
    </>
  );
};

export default AboutTermsOfUsePartyContentInfo;
