import { ChangeEmailContainer } from 'presentation/features/auth/changeEmail/container';
import { FC } from 'react';

type ChangeAdapterProps = {
  readonly onClose: () => void;
};

export const ChangeAdapter: FC<ChangeAdapterProps> = ({ onClose }) => {
  return <ChangeEmailContainer onClose={onClose} />;
};
