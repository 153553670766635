import { OfferListRequest } from 'data/api';
import { useGetProductOfferListQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { PriceRangeFilter } from 'domain/model/offer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import ProductOfferCard from 'presentation/features/offer/product/components/card';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PaginationSize } from '../../../../types';
import { useContextHandlers } from './hooks/useContextHandlers';
import { productOffersSetPage } from './store/slice';

export type ProductOfferListDataContainerProps = {
  readonly guid: UUID;
  readonly isMoreColumnsDislayed: boolean;
  readonly isFilterStubVisible: boolean;
  readonly args: OfferListRequest;
  readonly filterValues: Nullable<PriceRangeFilter>;
  readonly isAddToCartFetching: (id: UUID) => boolean;
};

const ProductOfferListDataContainer = (props: ProductOfferListDataContainerProps) => {
  const { args, filterValues, isMoreColumnsDislayed, isFilterStubVisible } = props;

  const { page, pageSize } = args;

  const dispatch = useDispatch();

  const { onChangePageSize } = useContextHandlers();

  /*const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: onAddOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
    },
  });*/

  const {
    data: productQueryData,
    isFetching: isProductOffersFetching,
    error: productOffersFetchError,
  } = useGetProductOfferListQuery({ ...args, ...filterValues }, { refetchOnMountOrArgChange: true });

  const productOffers = productQueryData?.data;
  const totalCount = productQueryData?.totalCount ?? 0;

  useEffect(() => {
    if (productOffersFetchError) {
      ErrorHandler.handleHttpError(productOffersFetchError);
    }
  }, [productOffersFetchError]);

  const onLoadMore = useCallback(() => {
    dispatch(productOffersSetPage(page + 1));
  }, [dispatch, page]);

  return (
    <>
      {productOffers && totalCount !== 0 && (
        <CardPageableList
          totalCount={totalCount}
          isFetching={isProductOffersFetching}
          smSpan={6}
          span={isMoreColumnsDislayed ? 3 : 4}
          pageSize={pageSize as PaginationSize}
          onChangePageSize={onChangePageSize}
          onLoadMore={onLoadMore}
        >
          {productOffers?.map(product => (
            <ProductOfferCard
              key={product.id}
              product={product}
              /*favorite={{
                showAlways: isLgDown,
                active: isFavorite(EOfferType.Product, product),
                inProgress: isAddToFavoritesFetching(product.id),
                onClick: () => onAddOrRemoveFavorite(EOfferType.Product, product),
              }}*/
            />
          ))}
        </CardPageableList>
      )}
      {productOffers && totalCount === 0 && isFilterStubVisible && <div>toto</div> /*todo <ProductFilterStub />*/}
    </>
  );
};

export default ProductOfferListDataContainer;
