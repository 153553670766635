import { Api } from '@reduxjs/toolkit/query';
import { useAppDispatch } from 'data/store/store';
import { useEffect, useState } from 'react';

type UseWatchCartApiMutationsProps<T> = {
  readonly deps: any[];
  readonly api: T;
  readonly endpoints: string[];
};

const useWatchApiMutations = <T extends Pick<Api<any, any, any, any>, 'util'>>({
  deps,
  api,
  endpoints,
}: UseWatchCartApiMutationsProps<T>) => {
  const dispatch = useAppDispatch();
  const [processes, setProcesses] = useState(0);

  const stringDeps = JSON.stringify(deps);

  useEffect(() => {
    setProcesses(prev => prev + 1);
    const promisesList = dispatch(api.util.getRunningMutationsThunk()).filter(mp =>
      endpoints.includes(mp.arg.endpointName)
    );

    const promises = Promise.all(promisesList);
    promises.then(() => {
      setProcesses(prev => prev - 1);
    });
  }, [dispatch, stringDeps, api, endpoints]);

  return processes !== 0;
};

export default useWatchApiMutations;
