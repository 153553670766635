import styled from '@emotion/styled/macro';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { chipClasses, css, typographyClasses } from '@mui/material';

export const Link = styled(MPChip)(
  ({ theme }) =>
    css`
      border-radius: ${theme.shape.borderRadius * 1.2}px;

      .${chipClasses.label} {
        padding-left: ${theme.spacing()};
        padding-right: ${theme.spacing()};
      }
    `
) as typeof MPChip;

export const LabelWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing()};

    .${typographyClasses.root} {
      padding-left: ${theme.spacing(0.5)};
    }
  `
);
