import { getSiteCaption } from '../../../../utils';
import { Text } from './controls';

const AboutCookiesCommonInfo = () => {
  return (
    <>
      <Text>
        Данная Политика использования файлов cookie относится к сайту {getSiteCaption()}, далее "сайт". Всплывающее окно
        предупреждает посетителей Сайта при первом посещении с помощью нового браузера или в режиме приватного просмотра
        об осуществлении сбора Сookies на сайте. Продолжая пользоваться Сайтом, вы даёте своё согласие на обработку
        ваших Сookies.
      </Text>
    </>
  );
};
export default AboutCookiesCommonInfo;
