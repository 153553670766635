import styled from '@emotion/styled';
import { css, svgIconClasses } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme, onClick }) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    cursor: ${onClick ? 'pointer' : 'default'};

    .${svgIconClasses.root} {
      margin-right: ${theme.spacing(0.5)};
    }
  `
);
