import { Category } from 'domain/model/nsi';
import HorizontalScroller, { HorizontalScrollerItem } from 'presentation/components/common/horizontalScroll';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { useCallback, useMemo } from 'react';

type PartnerDeskOffersCategoriesProps = {
  readonly categories: Category[];
  readonly categoryId: Nullable<UUID>;
  readonly onChange: (category: Nullable<Category>) => void;
};

const PartnerDeskOffersCategories = (props: PartnerDeskOffersCategoriesProps) => {
  const { categories, categoryId, onChange } = props;

  const isSelected = useCallback(
    (c: Nullable<Category>) => {
      return (c?.id ?? null) === categoryId || categories.length === 1;
    },
    [categoryId, categories]
  );

  const onChangeInternal = useCallback(
    (c: Nullable<Category>) => {
      if ((c?.id ?? null) !== categoryId) {
        onChange(c);
      }
    },
    [categoryId, onChange]
  );

  const selectedIndex = categoryId ? categories?.findIndex(c => c.id === categoryId) : 0;

  return useMemo(
    () =>
      categories.length >= 2 ? (
        <HorizontalScroller
          gap={8}
          initialSelected={selectedIndex}
        >
          {categories.length >= 2 && (
            <HorizontalScrollerItem key='all'>
              <MPChip
                key='all'
                label='Все'
                color='black'
                variant={isSelected(null) ? 'filled' : 'outlined'}
                onClick={() => onChangeInternal(null)}
              />
            </HorizontalScrollerItem>
          )}
          {categories?.map(c => (
            <HorizontalScrollerItem key={c.id}>
              <MPChip
                key={c.id}
                label={c.name}
                color='black'
                variant={isSelected(c) ? 'filled' : 'outlined'}
                onClick={() => onChangeInternal(c)}
              />
            </HorizontalScrollerItem>
          ))}
        </HorizontalScroller>
      ) : null,
    [categories, isSelected, selectedIndex, onChangeInternal]
  );
};

export default PartnerDeskOffersCategories;
