import { createSelector } from '@reduxjs/toolkit';
import { bookingOfferApi } from 'data/api/bookingOffer';
import { RootState } from 'data/store/store';
import { bookingOffersDefaultParams } from '../../utils';
import { BookingOfferListState } from './types';

export const bookingOfferListGuidSelector = (store: RootState) => store.bookingOffer.list.guid;

export const bookingOfferListSelector = (store: RootState) => store.bookingOffer.list;

export const bookingOfferListArgsSelector = (store: RootState) => store.bookingOffer.list.data;

export const bookingOfferListIsDefaultServiceSetSelector = (store: RootState) =>
  store.bookingOffer.list.isDefaultServiceSet;

export const bookingOfferListCategoryIdSelector = (store: RootState) => store.bookingOffer.list.categoryId ?? null;

export const bookingOfferListServicesFilterSelector = (store: RootState) =>
  (store.bookingOffer.list.filter.services ?? null) as Nullable<UUID[]>;

export const bookingOfferListPriceFilterSelector = (store: RootState) =>
  store.bookingOffer.list.filter.priceRange ?? bookingOffersDefaultParams.priceRange;

export const bookingOfferListFilterDataSelector = (store: RootState) => store.bookingOffer.list.filterData;

export const bookingOfferIsFilterFetchingSelector = createSelector(
  bookingOfferListFilterDataSelector,
  ({ isFetching }) => {
    return isFetching;
  }
);

export const bookingOfferIsFilterErrorSelector = createSelector(bookingOfferListFilterDataSelector, ({ isFailed }) => {
  return isFailed;
});

export const bookingOfferAllServicesSelector = (store: RootState): BookingOfferListState['services'] =>
  store.bookingOffer.list.services;

export const bookingOfferListCountSelector = (store: RootState) => {
  const {
    categoryId,
    data: args,
    filter: { priceRange, services },
  } = store.bookingOffer.list;

  if (!args) {
    return 0;
  }

  const { data } = bookingOfferApi.endpoints.getBookingOfferList.select({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services ?? null : [],
  })(store);

  return data?.data?.length ?? 0;
};

export const bookingOfferListIsErrorSelector = (store: RootState) => {
  const {
    categoryId,
    data: args,
    filter: { priceRange, services },
  } = store.bookingOffer.list;

  if (!args) {
    return 0;
  }

  const { isError } = bookingOfferApi.endpoints.getBookingOfferList.select({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services ?? null : [],
  })(store);

  return isError;
};

export const bookingOfferListIsFetchingSelector = (store: RootState) => {
  const {
    categoryId,
    data: args,
    filter: { priceRange, services },
  } = store.bookingOffer.list;

  return args
    ? bookingOfferApi.endpoints.getBookingOfferList.select({
        ...args,
        ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
        services: categoryId ? services ?? null : [],
      })(store).isLoading
    : false;
};

export const bookingOfferPriceRangeSelector = createSelector(bookingOfferListFilterDataSelector, ({ priceRange }) => {
  return priceRange ?? bookingOffersDefaultParams.priceRange;
});

export const bookingOfferListNoDataSelector = createSelector(
  bookingOfferListCountSelector,
  bookingOfferListIsFetchingSelector,
  bookingOfferIsFilterFetchingSelector,
  bookingOfferIsFilterErrorSelector,
  bookingOfferListIsErrorSelector,
  (count, isListFetching, isFilterFetching, isFilterError, isError) => {
    return !isError && !isFilterError && !isListFetching && !isFilterFetching && count === 0;
  }
);

export const bookingOfferListNoDataNoFilterSelector = createSelector(
  bookingOfferListCountSelector,
  bookingOfferListIsFetchingSelector,
  bookingOfferListIsErrorSelector,
  (count, isListFetching, isError) => {
    return !isError && !isListFetching && count === 0;
  }
);

export const bookingOfferListIsNewSearchFetchingSelector = createSelector(
  bookingOfferListIsFetchingSelector,
  (store: RootState) => store.bookingOffer.list.isNewSearchFetching,
  (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching
);

export const bookingOfferListNameSelector = (store: RootState) => store.bookingOffer.list.data?.search?.name ?? null;
