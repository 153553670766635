import { FC, useState } from 'react';
import { IsPhoneExistsDialog } from './exists';
import { IsPhoneNotExistsDialog } from './notExists';
import { useUpdateUserPhone } from './hooks/useUpdateUserPhone';

type RequiredActionUpdateUserPhoneContainerProps = {
  readonly onSuccess: (phone: string) => void;
  readonly onClose: () => void;
};

const RequiredActionUpdateUserPhoneContainer: FC<RequiredActionUpdateUserPhoneContainerProps> = ({
  onSuccess,
  onClose,
}) => {
  const { phone, isFetching, onUpdate } = useUpdateUserPhone({ onSuccess, onClose });

  const [editMode, setEditMode] = useState<boolean>(() => !phone);

  const onChange = () => setEditMode(true);

  if (editMode) {
    return (
      <IsPhoneNotExistsDialog
        phone={phone}
        isFetching={isFetching}
        onSubmit={onUpdate}
        onCancel={onClose}
      />
    );
  }

  return (
    <IsPhoneExistsDialog
      phone={phone}
      onSubmit={onUpdate}
      onChange={onChange}
      onCancel={onClose}
    />
  );
};

export default RequiredActionUpdateUserPhoneContainer;
