import Splitter from 'presentation/components/common/splitter';
import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { SidebarWrapper } from '../controls';
import ActivationListItemLabel from '../label/title';
import PartnerLink from 'presentation/components/common/partnerLink';

type ActivationListItemLinkSidebarProps = {
  readonly appointmentDate: Nullable<string>;
  readonly expireDate: Nullable<string>;
  readonly codeTypeName: string;
  readonly partnerLink: string;
  readonly onPartnerLinkClick: () => void;
};

const ActivationListItemLinkSidebar = (props: ActivationListItemLinkSidebarProps) => {
  const { appointmentDate, expireDate, codeTypeName, partnerLink, onPartnerLinkClick } = props;

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={1}
        direction='column'
      >
        <MPGrid item>
          <ActivationListItemLabel
            codeTypeName={codeTypeName}
            appointmentDate={appointmentDate}
          />
          {expireDate && <ActivationListItemExpireDate expireDate={expireDate} />}
        </MPGrid>
        <MPGrid item>
          <Splitter
            variant='horizontal'
            size={1}
          />
          <PartnerLink
            link={partnerLink}
            onClick={onPartnerLinkClick}
          />
        </MPGrid>
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemLinkSidebar;
