import LegalPersonalDataAgreementScreen from 'presentation/screen/public/personalDataAgreement';
import LegalPersonalDataPolicyScreen from 'presentation/screen/public/personalDataPolicy';
import { Redirect, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import { EAppFeature } from '../../types';
import { RouteByFeature } from '../general/routing/routeByFeature';
import LegalTermsOfUseScreen from 'presentation/screen/public/termsOfUse';
import LegalCookiesScreen from '../../screen/public/cookies';

const root = rootRouting.legal;

const routing = {
  root,
  personalDataPolicy: `${root}/personal-data-policy`,
  personalDataAgreement: `${root}/personal-data-agreement`,
  termsOfUse: `${root}/terms-of-use`,
  cookies: `${root}/cookies`,
};

export const getLegalPersonalDataPolicyRoute = () => routing.personalDataPolicy;

export const getLegalPersonalDataAgreementRoute = () => routing.personalDataAgreement;

export const getLegalTermsOfUseRoute = () => routing.termsOfUse;

export const getLegalCookiesRoute = () => routing.cookies;

const LegalEntry = () => {
  return (
    <Switch>
      <RouteByFeature
        feature={EAppFeature.PersonalDataPolicy}
        exact
        path={routing.personalDataPolicy}
        component={LegalPersonalDataPolicyScreen}
      />
      <RouteByFeature
        feature={EAppFeature.PersonalDataAgreement}
        exact
        path={routing.personalDataAgreement}
        component={LegalPersonalDataAgreementScreen}
      />
      <RouteByFeature
        feature={EAppFeature.TermsOfUse}
        exact
        path={routing.termsOfUse}
        component={LegalTermsOfUseScreen}
      />
      <RouteByFeature
        feature={EAppFeature.Cookies}
        exact
        path={routing.cookies}
        component={LegalCookiesScreen}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default LegalEntry;
