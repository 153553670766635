import { JSX } from 'react';
import { IconTooltip, IconWrapper, Text, Wrapper } from './controls';

type CmsContainerHeaderProps = {
  readonly text: Nullable<string>;
  readonly infoText: string;
  readonly Icon?: Nullable<JSX.Element>;
};

const CmsContainerHeader = ({ text, infoText, Icon }: CmsContainerHeaderProps) => {
  return (
    <Wrapper>
      {Icon && (
        <IconTooltip
          disabled={!text}
          title={infoText}
        >
          <IconWrapper>{Icon}</IconWrapper>
        </IconTooltip>
      )}
      <Text variant={text ? 'h1' : 'body2'}>{text ?? infoText}</Text>
    </Wrapper>
  );
};

export default CmsContainerHeader;
