import { useEffect, useState } from 'react';

type UseHeaderParams = (props?: UseFixedFooterParamsProps) => {
  readonly footerHeight: number;
};

type UseFixedFooterParamsProps = {
  readonly allFooters?: boolean;
};

export const useFixedFooterParams: UseHeaderParams = (
  { allFooters = true }: UseFixedFooterParamsProps = { allFooters: true }
) => {
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [resizeWatcher, setResizeWatcher] = useState<symbol>(Symbol());

  const footers = document.getElementsByTagName('footer');

  useEffect(() => {
    let fixedFooters: HTMLElement[] = [];

    if (!footers.length) {
      setFooterHeight(0);
      return;
    }

    if (footers?.length) {
      for (let i = 0; i < footers?.length; i++) {
        const footer = footers.item(i);
        if (footer && (footer.computedStyleMap().get('position') as CSSKeywordValue)?.value === 'fixed') {
          fixedFooters.push(footer);
        }
      }
    } else {
      fixedFooters = [];
    }

    if (!fixedFooters.length) {
      setFooterHeight(0);
      return;
    }

    const footer0OffsetHeight = fixedFooters?.[0]?.offsetHeight ?? 0;
    const footer1OffsetHeight = fixedFooters?.[1]?.offsetHeight ?? 0;
    const footer2OffsetHeight = fixedFooters?.[2]?.offsetHeight ?? 0;

    if (allFooters) {
      const height = footer0OffsetHeight + footer1OffsetHeight + footer2OffsetHeight;
      setFooterHeight(height);
    } else {
      setFooterHeight(footer0OffsetHeight);
    }
  }, [footers, allFooters, resizeWatcher]);

  useEffect(() => {
    const listener = () => setResizeWatcher(Symbol());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return {
    footerHeight,
  };
};
