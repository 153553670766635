import { FCC, ReactNode, useEffect } from 'react';
import { AboutTextSimpleLayout } from '../../../layouts/aboutSimple';

type AboutTextSimpleContainerProps = {
  readonly title?: ReactNode;
  readonly close?: ReactNode;
};

const AboutTextSimpleContainer: FCC<AboutTextSimpleContainerProps> = ({ title, close, children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const Layout = AboutTextSimpleLayout;

  return (
    <Layout
      title={title}
      close={close}
    >
      {children}
    </Layout>
  );
};

export default AboutTextSimpleContainer;
