import styled from '@emotion/styled/macro';

export const AnyContentWrapper = styled.div`
  flex: 1;

  > :not(:first-of-type) {
    margin-top: ${p => p.theme.spacing(5)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;

  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing(3)};
`;
