import { RootState } from 'data/store/store';
import { AspOfferClaimState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

export const aspOfferClaimDialogsSelector = (store: RootState) => store.aspOffer.claim.dialogs;

export const getAspOfferClaimDialogSelector = (name: keyof AspOfferClaimState['dialogs']) =>
  createSelector(
    () => name,
    aspOfferClaimDialogsSelector,
    (name, dialogs) => dialogs[name]
  );
