import rootRouting from 'routing';
import { ECatalogTab } from '../catalog/types';

export enum EGlobalUrlParam {
  Search = 'search',
}

export const offerSectionToTabMap = [
  {
    tab: ECatalogTab.TradeOffers,
    path: rootRouting.tradeOffer,
  },
  {
    tab: ECatalogTab.CorpOffers,
    path: rootRouting.corpOffer,
  },
  {
    tab: ECatalogTab.BookingOffers,
    path: rootRouting.bookingOffer,
  },
  {
    tab: ECatalogTab.ProductOffers,
    path: rootRouting.product,
  },
  {
    tab: ECatalogTab.AspOffers,
    path: rootRouting.aspOffer,
  },
];
