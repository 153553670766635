import { createAppTheme, MPThemeProvider, theme } from 'presentation/theme';
import { FCC } from 'react';
import useUserTheme from './useUserTheme';

export const UserThemeProvider: FCC = ({ children }) => {
  const externalTheme = useUserTheme();

  let appTheme = theme;
  if (externalTheme) {
    appTheme = createAppTheme(externalTheme);
  }

  return <MPThemeProvider theme={appTheme}>{children}</MPThemeProvider>;
};
