import { StompClientProvider } from '@mp-npm/mp-stomp-client';
import HttpClient from 'data/network/http';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { FCC, useCallback } from 'react';
import { ENoticeStatus } from '../../../domain/model/enums';
import { User } from '../../../domain/model/user';
import Notifier from '../../../system/notifier';
import useWsClient from './useWsClient';

type WsClientProviderProps = {
  readonly user: Nullable<User>;
};

export const WsClientProvider: FCC<WsClientProviderProps> = ({ children, user }) => {
  const { token } = useAuthToken();

  const { apiWs, debug, isWsEnabled, wsEndpoint } = useWsClient();

  const onStompConnectionFailure = useCallback((message: Nullable<string>) => {
    Notifier.getInstance().addNotice(
      ENoticeStatus.Error,
      `Ошибка подключения websocket: ${message ?? 'неизвестная ошибка'}`
    );
  }, []);

  return (
    <StompClientProvider
      stop={!isWsEnabled || !user || !token}
      endpoint={wsEndpoint}
      wsPath={apiWs?.path}
      sockjsPath={apiWs?.sockjsPath}
      debug={debug}
      getToken={() => HttpClient.getInstance().getAuthService()?.token || token || ''}
      onConnectionFailure={onStompConnectionFailure}
    >
      {children}
    </StompClientProvider>
  );
};
