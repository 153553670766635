import { useGetCorpOfferCategoriesUsedQuery, useGetCorpOffersCountQuery } from 'data/api/corpOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCategoriesId, getCategoryIntersectionById, getOffersCountQueryParams } from '../../../utils';
import {
  corpOfferListCategoryIdSelector,
  corpOfferListIsNewSearchFetchingSelector,
  corpOfferListNameSelector,
} from '../store/selectors';
import useCategoriesData from './useCategoriesData';

const useCorpOffersHeader = () => {
  const name = useSelector(corpOfferListNameSelector);
  const categoryId = useSelector(corpOfferListCategoryIdSelector);
  const isNewSearchFetching = useSelector(corpOfferListIsNewSearchFetchingSelector);

  //запрос всех категорий КП
  const { categories: categoriesData } = useCategoriesData();

  //запрос используемых категорий КП для поиска по наименованию
  const { data: categoriesUsedData, error: categoriesUsedFetchError } = useGetCorpOfferCategoriesUsedQuery(
    {
      name,
    },
    { skip: !name }
  );

  /*
   * запрос количества КП для поиска по наименованию
   * сделано отдельно, так как после поиска на экране появляется набор всех категорий, по которым найдены КП
   * далее на категорию можно кликнуть, тем самым уточнив поиск name+category
   * при этом итоговое количество на экране должно быть только по name
   */
  const { data: initialTotalCount = 0, error: countQueryError } = useGetCorpOffersCountQuery(
    getOffersCountQueryParams({
      name,
      categories: null,
    }),
    { skip: !name }
  );

  useEffect(() => {
    if (countQueryError) {
      ErrorHandler.handleHttpError(countQueryError);
    }
    if (categoriesUsedFetchError) {
      ErrorHandler.handleHttpError(categoriesUsedFetchError);
    }
  }, [categoriesUsedFetchError, countQueryError]);

  const categoriesOut = (
    categoriesUsedData?.length
      ? getCategoryIntersectionById(categoriesData, getCategoriesId(categoriesUsedData))
      : getCategoryIntersectionById(categoriesData, categoryId ? [categoryId] : [])
  )?.sort((c1, c2) => c1.name.localeCompare(c2.name));

  const selectedCategory = categoryId
    ? categoriesOut?.find(categoryOut => categoryOut.id === categoryId) ?? null
    : null;

  const isEmptySearch = !categoryId && !name;

  const isCommonHeaderVisible = isEmptySearch;
  const isSearchHeaderVisible = !isEmptySearch && initialTotalCount > 0;
  const isCategoryHeaderVisible = !name && selectedCategory;
  const isAnyHeaderVisible = isCommonHeaderVisible || isSearchHeaderVisible || isCategoryHeaderVisible;

  return {
    isAnyHeaderVisible,
    isNewSearchFetching,
    isCommonHeaderVisible,
    isSearchHeaderVisible,
    isCategoryHeaderVisible,
    categoriesOut,
    selectedCategory,
    initialTotalCount,
  };
};

export default useCorpOffersHeader;
