import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import useProductOfferPartnerDeskData from 'presentation/features/offer/product/details/hooks/useProductOfferPartnerDeskData';
import ProductOfferTabs from 'presentation/features/offer/product/details/tabs';
import { EProductOfferDetailsTab } from 'presentation/features/offer/product/details/utils';
import { getProductOfferDetailsRoute } from 'presentation/features/offer/product/entry';
import React from 'react';
import { useHistory } from 'react-router';

type ProductDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly tab: EProductOfferDetailsTab;
  readonly characteristicTabRef: React.RefObject<any>;
}

const ProductDetailsTabsAdapter = (props: ProductDetailsTabsAdapterProps) => {
  const { tab, characteristicTabRef, id } = props;

  const history = useHistory();

  const { productOffer } = useProductOfferDetailsData(id);

  const { partnerDesk } = useProductOfferPartnerDeskData(id);

  const changeTab = (newTab: EProductOfferDetailsTab) => {
    history.replace(getProductOfferDetailsRoute({ id, tab: newTab }));
  };

  const onChangeTab = (event: React.SyntheticEvent, newTab: EProductOfferDetailsTab) => {
    changeTab(newTab);
  };

  if (!productOffer || !partnerDesk) {
    return null;
  }

  return (
    <ProductOfferTabs
      productOffer={productOffer}
      tab={tab}
      characteristicTabRef={characteristicTabRef}
      onChange={onChangeTab}
      partnerDesk={partnerDesk}
    />
  );
};

export default ProductDetailsTabsAdapter;
