import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.main(
  () => css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    position: relative;
  `
);
