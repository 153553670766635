import useBookingOfferCategoriesData from './useBookingOfferCategoriesData';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';
import { BookingDetailsId } from '../types';

const useBookingOfferDetailsLoadingStatus = ({ id }: BookingDetailsId): boolean => {
  const { isCategoriesLoading } = useBookingOfferCategoriesData();
  const { isBookingOfferLoading } = useBookingOfferDetailsData(id);

  return isCategoriesLoading || isBookingOfferLoading;
};

export default useBookingOfferDetailsLoadingStatus;
