import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';

type OfferNdflHelpDialogProps = {
  readonly open: boolean;
  readonly description: Nullable<string>;
  readonly onClose: () => void;
};

const OfferNdflHelpDialog = ({ open, description, onClose }: OfferNdflHelpDialogProps) => {
  return (
    <MPConfirmDialog
      open={open}
      maxWidth='sm'
      title='Возможно, оплата НДФЛ'
      onClose={onClose}
      buttons={
        <MPButton
          color='brand'
          fullWidth
          size='large'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
    >
      <Typography variant='body2'>{description}</Typography>
    </MPConfirmDialog>
  );
};

export default OfferNdflHelpDialog;
