import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import AspOfferListContainer from 'presentation/features/offer/asp/list/container';
import { EAspOfferUrlParam } from 'presentation/features/offer/asp/utils';
import { TradeOffersListLocationState } from 'presentation/features/offer/trade/routes';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';

const AspOffersScreen = () => {
  const location = useLocation<Nullable<TradeOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(EAspOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(EAspOfferUrlParam.PartnerId) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <AspOfferListContainer
            name={name}
            categoryId={categoryId}
            partnerId={partnerId}
            guid={guid}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default AspOffersScreen;
