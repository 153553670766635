import { AxiosResponse } from 'axios';
import { CmsBanner, CmsCollection } from 'domain/model/cms';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { EOfferStatus } from 'domain/model/enums';
import { LandingWindow, PartnerDesk, PartnerWindow } from 'domain/model/partner';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import apiDefinition from '../openApi';
import { api, EUserServicesTag } from './index';

type CmsApiParams<T> = T & {
  readonly guid: UUID;
};

export type CmsApiOffersRequest = CmsApiParams<{
  readonly id: UUID;
  readonly city: Nullable<string>;
  readonly statuses?: Nullable<EOfferStatus[]>;
}>;

export const cmsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCmsPartnerPage: builder.query<PartnerWindow, CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      query: ({ id }) => apiDefinition.partner.page({ partnerId: id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsLandingPage: builder.query<LandingWindow, UUID>({
      keepUnusedDataFor: 3600,
      query: () => apiDefinition.user.landing.window({}),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsContainerBannersContent: builder.query<CmsBanner[], CmsApiParams<{ bannerIds: UUID[] }>>({
      keepUnusedDataFor: 3600,
      query: ({ bannerIds }) => apiDefinition.cms.banner.byIds({ ids: bannerIds }),
      transformResponse: (response: AxiosResponse<CmsBanner[]>, _, queryArg) => {
        const parsedArray: CmsBanner[] = [];

        queryArg.bannerIds.forEach(id => {
          const banner = response.data.find(banner => banner.id === id);

          if (banner) parsedArray.push(banner);
        });
        return parsedArray;
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsCollection: builder.query<CmsCollection, CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      query: ({ id }) => apiDefinition.cms.collection.one({ id }),
      transformResponse: (response: AxiosResponse<CmsCollection>) => response.data,
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsTradeOffersCollection: builder.query<TradeOfferShort[], CmsApiOffersRequest>({
      keepUnusedDataFor: 3600,
      transformResponse: (response: AxiosResponse<TradeOfferShort[]>, meta, { statuses }): TradeOfferShort[] => {
        return response.data.filter(item => !statuses?.length || statuses.includes(item.status));
      },
      query: ({ id }) => apiDefinition.user.trade.collection({ id }),
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsCorpOffersCollection: builder.query<CorpOfferShort[], CmsApiOffersRequest>({
      keepUnusedDataFor: 3600,
      query: ({ id }) => apiDefinition.user.corp.collection({ id }),
      transformResponse: (response: AxiosResponse<CorpOfferShort[]>, meta, { statuses }) => {
        return response.data.filter(item => !statuses?.length || statuses.includes(item.status));
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsPartnerDesksCollection: builder.query<PartnerDesk[], CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      query: ({ id }) => apiDefinition.partner.desk.collection({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: [EUserServicesTag.Location],
    }),
  }),
});

export const {
  useGetCmsPartnerPageQuery,
  useGetCmsLandingPageQuery,
  useGetCmsContainerBannersContentQuery,
  useGetCmsCollectionQuery,
  useGetCmsTradeOffersCollectionQuery,
  useGetCmsCorpOffersCollectionQuery,
  useGetCmsPartnerDesksCollectionQuery,
} = cmsApi;
