import { Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import { CloseIcon } from 'presentation/media/icons';
import { BlankChip, DeleteIconWrapper } from './controls';

const DeleteIcon = <CloseIcon />;

export const MPChip: typeof Chip = (props: ChipProps) => {
  const Component = props.label ? Chip : BlankChip;

  // NOTE(@Protopopov Ruslan): chip не поддерживает endIcon нативно, так что вставляем иконку в слот deleteIcon
  const applyEndIconProps = () => {
    if (props.onDelete) {
      const DeleteIconComponent = <DeleteIconWrapper>{DeleteIcon}</DeleteIconWrapper>;
      return { deleteIcon: DeleteIconComponent, onDelete: props.onDelete };
    }

    if (props.endIcon) {
      return { deleteIcon: props.endIcon, onDelete: () => null };
    }
  };

  return (
    <Component
      deleteIcon={DeleteIcon}
      {...props}
      {...applyEndIconProps()}
    />
  );
};
