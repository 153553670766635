import styled from '@emotion/styled/macro';
import { chipClasses, css } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPLink } from 'presentation/theme/ui-kit/link';

export const SubCategoryChip = styled(MPChip)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius * 1.2}px;

    .${chipClasses.label} {
      padding: ${theme.spacing(0.625, 1)};
    }
  `
);

export const SubCategoryLinkWrapper = styled(MPGrid)(
  ({ theme }) => css`
    > * {
      padding: ${theme.spacing(1.25, 1, 1.25, 0)};
      padding-left: 38px; //хардкод, потому что иначе никак, под влиянием другого элемента (выше заголовок раздела с иконкой)
    }
  `
);

export const SubCategoryLinkItem = styled(MPLink)(
  ({ theme }) => css`
    padding-right: ${theme.spacing(1.25)};

    border-radius: ${theme.shape.borderRadius * 1.2}px;

    :hover {
      background-color: ${theme.palette.secondary.A600};
    }
  `
);
