import Splitter from 'presentation/components/common/splitter';
import { ContentWrapper, SearchWrapper, Wrapper } from './controls';
import { PartnerDeskDetailsFilterLayoutType } from './types';

const PartnerDeskDetailsFilterLayout: PartnerDeskDetailsFilterLayoutType = props => {
  const { search, loader, children } = props;

  return (
    <Wrapper>
      <SearchWrapper>{search}</SearchWrapper>
      <Splitter size={3} />
      <ContentWrapper>
        {children}
        {loader}
      </ContentWrapper>
    </Wrapper>
  );
};

export default PartnerDeskDetailsFilterLayout;
