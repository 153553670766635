import { Hidden } from '@mui/material';
import { CountryTabs } from 'presentation/components/common/countrySelector';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback, useMemo } from 'react';
import { ELocaleShort } from '../../../../types';
import { CountryWrapper, LettersWrapper, NumericItemWrapper } from './controls';
import AlphabeticalSearchItem from './item';
import { charIsAllowed, specialCharactersAndDigitsRegEx } from './utils';

export type AlphabetData = string;

type PartnerDeskFilterAlphabeticalSearchProps = {
  readonly value: Nullable<string[]>;
  readonly specialSymbols: string[];
  readonly allowedAlphabet: AlphabetData[];
  readonly alphabet: AlphabetData[];
  readonly locale: ELocaleShort;
  readonly onChangeLocale: (locale: ELocaleShort) => void;
  readonly onChange: (char: Nullable<string[]>) => void;
};

const PartnerDeskFilterAlphabeticalSearch = ({
  value,
  onChange,
  allowedAlphabet,
  locale,
  onChangeLocale,
  alphabet,
  specialSymbols,
}: PartnerDeskFilterAlphabeticalSearchProps) => {
  const currentChar = useMemo<Nullable<string[]>>(
    () => (value && value?.length !== allowedAlphabet?.length ? value : null),
    [value, allowedAlphabet]
  );

  const onChangeInternal = useCallback(
    (char: string) => () => {
      if (char === currentChar?.[0]) {
        onChange(null);
      } else {
        onChange(char ? [char] : null);
      }
    },
    [currentChar, onChange]
  );

  const digitsAndSpecialSymbolsEnabled = !!specialSymbols.length;

  return (
    <MPGrid
      container
      alignItems='flex-start'
      spacing={3}
    >
      <MPGrid
        item
        zero
        sm='auto'
      >
        <CountryWrapper>
          <CountryTabs
            value={locale}
            variant='standard'
            onChange={onChangeLocale}
          />
        </CountryWrapper>
      </MPGrid>
      <Hidden smDown>
        <Splitter
          variant='vertical'
          size={2}
        />
      </Hidden>

      <MPGrid item>
        <AlphabeticalSearchItem
          selected={value?.length === allowedAlphabet?.length}
          onClick={() => onChange(allowedAlphabet)}
        >
          Все
        </AlphabeticalSearchItem>
      </MPGrid>

      <MPGrid
        item
        zero={12}
        sm
      >
        <LettersWrapper>
          {alphabet.map(char => {
            const enabled = charIsAllowed(char, allowedAlphabet);
            return (
              <AlphabeticalSearchItem
                key={char}
                disabled={!enabled}
                selected={char === currentChar?.[0]}
                onClick={enabled ? onChangeInternal(char) : undefined}
              >
                {char}
              </AlphabeticalSearchItem>
            );
          })}
          <NumericItemWrapper>
            <AlphabeticalSearchItem
              disabled={!digitsAndSpecialSymbolsEnabled}
              selected={currentChar?.[0] && specialCharactersAndDigitsRegEx.test(currentChar?.[0])}
              onClick={digitsAndSpecialSymbolsEnabled ? () => onChange(specialSymbols) : undefined}
            >
              0-9
            </AlphabeticalSearchItem>
          </NumericItemWrapper>
        </LettersWrapper>
      </MPGrid>
    </MPGrid>
  );
};

export default PartnerDeskFilterAlphabeticalSearch;
