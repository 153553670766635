import { useSelector } from 'react-redux';
import { pageContainerSelector } from './store/selectors';

type ILazyContentWrapperProps = {
  readonly guid: UUID;
  readonly children: (loadedItemsSize: number) => JSX.Element;
};

type LazyContentWrapperFC = (props: ILazyContentWrapperProps) => JSX.Element;

/**
 * Wrapper для передачи размера подгруженного контента, возвращает размер
 */
export const LazyContentWrapper: LazyContentWrapperFC = ({ children, guid }) => {
  const containers = useSelector(pageContainerSelector(guid));
  const size = containers.length;

  return children(size);
};
