import styled from '@emotion/styled/macro';
import { css, Typography } from '@mui/material';

export const Email = styled(Typography)(
  () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
) as typeof Typography;

export const Text = styled(Typography)(
  () => css`
    word-wrap: break-word;
    overflow-wrap: break-word;
  `
);

export const BalancesWrapper = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacing(6)};
  `
);
