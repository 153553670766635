import { EPartnerType } from 'domain/model/enums';
import { BookingOfferShort } from 'domain/model/bookingOffer';
import { LogoImageView } from 'presentation/components/common/images/cover';
import { innerPartnerLogoStub } from 'presentation/features/partnerDesk/utils';
import { LogoWrapper } from '../../../components/listItem';
import { InfoWrapper } from './controls';
import { AddressHelper } from '../../../../../../utils/address';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';

const BookingListItemInfo = (props: BookingOfferShort) => {
  const {
    address,
    partner: { logo, type },
  } = props;

  const logoPath = !logo && type === EPartnerType.Inner ? innerPartnerLogoStub : logo?.path;

  return (
    <InfoWrapper>
      {address && (
        <CardCaption
          variant='body2'
          color='textSecondary'
        >
          {new AddressHelper(address).getLocalitySimpleName()}
        </CardCaption>
      )}
      {logoPath && (
        <LogoWrapper>
          <LogoImageView id={logoPath} />
        </LogoWrapper>
      )}
    </InfoWrapper>
  );
};

export default BookingListItemInfo;
