export enum EValidationMessages {
  Empty = 'Обязательно для заполнения',
  EmailInvalid = 'Необходимо указать корректный адрес электронной почты',
  URLInvalid = 'Необходимо указать корректный URL',
  Summary = 'Заполните обязательные поля и исправьте ошибки',
}

export enum EValidationType {
  Error = 'error',
}

export type ValidationItemResult = {
  readonly type: EValidationType;
  readonly hasError: boolean;
  readonly message?: string;
};

export type ValidationResult<T> = {
  [K in keyof T]?: ValidationItemResult;
};

export type ValidationRule<T> = {
  readonly required?: boolean | ((object: T, value: any) => boolean);
  readonly requiredMessage?: string;
  readonly validator?: (object: T, value: any) => Nullable<ValidationItemResult>;
};

export type ValidationRules<T> = {
  readonly [K in keyof T]?: ValidationRule<T>;
};

export const validationRegexp = {
  email: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
};
