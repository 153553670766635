import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { AboutFeedbackLayoutType } from './types';

const AboutFeedbackLayout: AboutFeedbackLayoutType = props => {
  return (
    <MPConfirmDialog
      maxWidth='xs'
      fullScreenBreakpoint='sm'
      scroll='body'
      {...props}
    />
  );
};

export default AboutFeedbackLayout;
