import styled from '@emotion/styled/macro';
import { css, Grid } from '@mui/material';
import { ETradeOfferClaimSlotNameArea } from '../types';

type SlotsGridAreaProps = {
  readonly area: ETradeOfferClaimSlotNameArea;
};

export const SlotsGridArea = styled(Grid)<SlotsGridAreaProps>`
  width: 100%;
  grid-area: ${p => p.area};
`;

export const SlotsGrid = styled.div`
  width: 100%;
  z-index: 1;
  display: block;
  background-color: ${p => p.theme.palette.white.main};
  border-radius: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(3)};
  border: 1px solid ${p => p.theme.palette.secondary.A600};
  box-shadow: 0 20px 30px -20px hsla(220, 13%, 50%, 0.35);

  > [area=${ETradeOfferClaimSlotNameArea.title}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.leftPeriod}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.activation}]:not(:empty), > [area=${ETradeOfferClaimSlotNameArea.promotion}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.newPromotion}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.infoMessages}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(0)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.warnMessages}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.subscriptions}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};

    height: ${p => p.theme.spacing(9)};
  }

  > [area=${ETradeOfferClaimSlotNameArea.bottomBar}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(2)};
  }
`;

export const HelpWrapper = styled.div(
  ({ theme: { spacing, palette } }) => css`
    :not(:empty) {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};

      padding-bottom: ${spacing(2)};
      margin-bottom: ${spacing(2)};

      border-bottom: 1px solid ${palette.secondary.A900};
    }
  `
);

