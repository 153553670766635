import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const grids: OverrideThemeFunc<Components> = () => ({
  MuiGrid: {
    styleOverrides: {
      //пришлось xs задать больше 0, поэтому учим grid растягивать
      'grid-xs-12': {
        maxWidth: '100%',
        flexBasis: '100%',
        flexGrow: 0,
      },
    },
  },
});

export default grids;
