import AboutPersonalDataAgreementContainer from 'presentation/features/about/personalDataAgreement/container';
import ScreenLayout from 'presentation/layouts/screen';

const LegalPersonalDataAgreementScreen = () => {
  return (
    <ScreenLayout>
      <AboutPersonalDataAgreementContainer />
    </ScreenLayout>
  );
};

export default LegalPersonalDataAgreementScreen;
