import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';

type OfferPayButtonProps = {
  readonly isFetching?: boolean;
  readonly disabled?: boolean;
  readonly onPay: () => void;
};

export const OfferPayButton = (props: OfferPayButtonProps) => {
  const { isFetching, disabled, onPay } = props;

  return (
    <MPButton
      fullWidth
      size='large'
      color='brand'
      disabled={disabled || isFetching}
      onClick={onPay}
    >
      Купить
      {isFetching && <ContentLoader />}
    </MPButton>
  );
};
