import useAspOfferCategoriesData from './useAspOfferCategoriesData';
import useAspOfferDetailsData from './useAspOfferDetailsData';
import useAspOfferPartnerDeskData from './useAspOfferPartnerDeskData';

type TradeDetailsFetchingStatusProps = {
  readonly id: UUID;
};

type UseAspOfferDetailsFetchingStatus = boolean;

const useAspOfferDetailsFetchingStatus = ({
  id,
}: TradeDetailsFetchingStatusProps): UseAspOfferDetailsFetchingStatus => {
  const { isCategoriesFetching } = useAspOfferCategoriesData();
  const { isAspOfferFetching } = useAspOfferDetailsData(id);
  const { isPartnerDeskFetching } = useAspOfferPartnerDeskData(id);

  return isCategoriesFetching || isAspOfferFetching || isPartnerDeskFetching;
};

export default useAspOfferDetailsFetchingStatus;
