import { RootState } from 'data/store/store';
import { partnerApi, PartnerDesksListRequest } from 'data/api/partner';
import { PageableListState } from 'presentation/features/general/pageable/types';

export const getPartnersNameMapLoadingSelector = (store: RootState) => {
  const { isLoading, isUninitialized } = partnerApi.endpoints.getPartnersNameMap.select()(store);
  return isLoading || isUninitialized;
};

export const getPartnerDesksLoadingSelector =
  (args: PartnerDesksListRequest & PageableListState) => (store: RootState) => {
    const { isLoading, isUninitialized } = partnerApi.endpoints.getPartnerDesks.select(args)(store);

    return isLoading || isUninitialized;
  };
