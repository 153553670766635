import { CorpOfferShort } from 'domain/model/corpOffer';
import { EPartnerType } from 'domain/model/enums';
import { LogoImageView } from 'presentation/components/common/images/cover';
import { innerPartnerLogoStub } from 'presentation/features/partnerDesk/utils';
import { InfoWrapper } from './controls';
import { LogoWrapper } from '../../../components/listItem';
import { CardCaption } from '../../../components/listItem/controls';

type CorpOfferListItemInfoProps = Pick<CorpOfferShort, 'partner'>;

const CorpOfferListItemInfo = (props: CorpOfferListItemInfoProps) => {
  const {
    partner: { logo, type, name: partnerName },
  } = props;

  const logoPath = !logo && type === EPartnerType.Inner ? innerPartnerLogoStub : logo?.path;

  return (
    <InfoWrapper>
      <CardCaption
        variant='body2'
        color='textSecondary'
      >
        {partnerName}
      </CardCaption>
      {logoPath && (
        <LogoWrapper>
          <LogoImageView id={logoPath} />
        </LogoWrapper>
      )}
    </InfoWrapper>
  );
};

export default CorpOfferListItemInfo;
