import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  partnerDeskDetailsTradeOffersCountSelector,
  partnerDeskDetailsTradeOffersIsErrorSelector,
  partnerDeskDetailsTradeOffersIsFetchingSelector,
} from '../../store/selectors';

const PartnerDeskDetailsTabTradeOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsTradeOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsTradeOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsTradeOffersIsErrorSelector);

  return useMemo(
    () => (!count && !isFetching && !isError ? <div>Торговые предложения пока отсутствуют</div> : null),
    [count, isFetching, isError]
  );
};

export default PartnerDeskDetailsTabTradeOffersNoData;
