import { PriceRangeFilter } from 'domain/model/offer';

export const productOfferEmptyFilter: PriceRangeFilter = {
  minPrice: null,
  maxPrice: null,
};

export const priceRangeMinValue = 1;
export const priceRangeMaxValue = 100000;

/**
 * Функция для заполнения фильтров параметрами из search или "пустыми" значениями
 */
export const parseFiltersFromSearch = (searchParams: string): PriceRangeFilter => {
  const filters: PriceRangeFilter = { ...productOfferEmptyFilter };
  const search = new URLSearchParams(searchParams);

  Object.keys(productOfferEmptyFilter).forEach(filterId => {
    const filterValueFromSearch = search.get(filterId);

    if (filterValueFromSearch) {
      Object.assign(filters, { [filterId]: filterValueFromSearch });
    }
  });

  return filters;
};

/**
 * Функция для "отсечения" параметров фильтра из search
 * @param searchParams string
 */
export const getNonFilterSearch = (searchParams: string) => {
  const search = new URLSearchParams(searchParams);

  Object.keys(productOfferEmptyFilter).forEach(item => search.delete(item));

  return search.toString();
};
