import AboutTextSectionsContainer from '../textSections/container';
import AboutTermsOfUseAdvertInfo from './info/advert';
import AboutTermsOfUseCollectAndStorageInfo from './info/collectAndStorage';
import AboutUserAgreementCommonInfo from './info/common';
import AboutTermsOfUseContentRightsInfo from './info/contentRights';
import AboutTermsOfUseOthersInfo from './info/others';
import AboutTermsOfUsePartyContentInfo from './info/partyContent';
import AboutTermsOfUseRegistrationInfo from './info/register';
import AboutTermsOfUseTermsInfo from './info/terms';
import AboutTermsOfUseWarrantyAndLimitationInfo from './info/warrantyAndLimitation';

enum ESection {
  Common = 'common',
  SiteRegistration = 'siteRegistration',
  CollectAndStorage = 'collectAndStorage',
  Terms = 'terms',
  ContentRights = 'contentRights',
  PartyContent = 'cartyContent',
  Advert = 'advert',
  WarrantyAndLimitation = 'warrantyAndLimitation',
  Others = 'others',
}

const AboutTermsOfUseContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Пользовательское соглашение'
      sections={[
        {
          id: ESection.Common,
          name: '1. Общие положения',
          component: AboutUserAgreementCommonInfo,
        },
        {
          id: ESection.SiteRegistration,
          name: '2. Регистрация Пользователя. Учетная запись Пользователя',
          component: AboutTermsOfUseRegistrationInfo,
        },
        {
          id: ESection.CollectAndStorage,
          name: '3. Общие положения об использовании и хранении информации',
          component: AboutTermsOfUseCollectAndStorageInfo,
        },
        {
          id: ESection.Terms,
          name: '4. Условия использования Платформы',
          component: AboutTermsOfUseTermsInfo,
        },
        {
          id: ESection.ContentRights,
          name: '5. Исключительные права на содержание Платформы и контент',
          component: AboutTermsOfUseContentRightsInfo,
        },
        {
          id: ESection.PartyContent,
          name: '6. Сайты и контент третьих лиц',
          component: AboutTermsOfUsePartyContentInfo,
        },
        {
          id: ESection.Advert,
          name: '7. Реклама в Платформе',
          component: AboutTermsOfUseAdvertInfo,
        },
        {
          id: ESection.WarrantyAndLimitation,
          name: '8. Отсутствие гарантий, ограничение ответственности',
          component: AboutTermsOfUseWarrantyAndLimitationInfo,
        },
        {
          id: ESection.Others,
          name: '9. Иные положения',
          component: AboutTermsOfUseOthersInfo,
        },
      ]}
    />
  );
};

export default AboutTermsOfUseContainer;
