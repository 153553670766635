import { EOfferStatus } from 'domain/model/enums';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import { ERenderSource } from 'presentation/types';
import { FC } from 'react';
import RequiredActionUpdateUserPhoneContainer from '../../../requiredAction/updateUserPhone/container';
import InActive from '../components/inActive';
import BookingOfferBreadcrumbAdapter from './adapters/breadcrumb';
import BookingOfferCarouselAdapter from './adapters/carousel';
import BookingOfferCloseAdapter from './adapters/close';
import BookingOfferDetailsFavoriteAdapter from './adapters/favorite';
import BookingOfferDetailsLoaderAdapter from './adapters/loader';
import BookingOfferMapAdapter from './adapters/map';
import BookingOfferDetailsModalsAdapter from './adapters/modals';
import BookingOfferPartnerInfoAdapter from './adapters/partnerInfo';
import BookingOfferSidebarAdapter from './adapters/sidebar';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import BookingOfferDetailStub from './adapters/stub';
import BookingOfferDetailsTabsAdapter from './adapters/tabs';
import BookingOfferDetailsTitleAdapter from './adapters/title';
import { BookingOfferDetailContext } from './context';
import useBookingOfferDetails from './hooks/useBookingOfferDetails';
import useBookingOfferDetailsData from './hooks/useBookingOfferDetailsData';
import BookingOfferDetailsLayout from './layout';
import BookingOfferSimpleLayout from './layout/simple';
import { EBookingOfferDetailsTab } from './types';

type BookingOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: EBookingOfferDetailsTab;
  readonly from: ERenderSource;
};

const Close = <BookingOfferCloseAdapter />;
const Modals = <BookingOfferDetailsModalsAdapter />;

const BookingOfferDetailsContainer: FC<BookingOfferDetailsContainerProps> = ({ id, tab, guid, from }) => {
  const handlers = useBookingOfferDetails(id, from);
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const isActiveStatus = bookingOffer?.status === EOfferStatus.Active;

  return (
    <BookingOfferDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <CommonRenderManager
          loader={<BookingOfferDetailsLoaderAdapter id={id} />}
          stub={BookingOfferDetailStub}
        >
          {isActiveStatus ? (
            <>
              <BookingOfferDetailsLayout
                id={id}
                favorite={
                  <BookingOfferDetailsFavoriteAdapter
                    id={id}
                    guid={guid}
                  />
                }
                description={<BookingOfferMapAdapter id={id} />}
                sidebar={<BookingOfferSidebarAdapter id={id} />}
                smartphoneFixedBar={
                  <SmartPhoneFixedBarAdapter
                    guid={guid}
                    id={id}
                  />
                }
                tabs={
                  <BookingOfferDetailsTabsAdapter
                    guid={guid}
                    tab={tab}
                    id={id}
                  />
                }
                partnerInfo={<BookingOfferPartnerInfoAdapter id={id} />}
                title={<BookingOfferDetailsTitleAdapter id={id} />}
                breadcrumb={<BookingOfferBreadcrumbAdapter id={id} />}
                image={<BookingOfferCarouselAdapter id={id} />}
                close={Close}
              />
              {Modals}
              {handlers.confirmPhoneNumberDialogOpen && (
                <RequiredActionUpdateUserPhoneContainer
                  onSuccess={handlers.handleOrder}
                  onClose={handlers.onConfirmPhoneDialogClose}
                />
              )}
            </>
          ) : (
            <BookingOfferSimpleLayout
              title={bookingOffer?.name ?? ''}
              close={Close}
              id={id}
            >
              {InActive}
            </BookingOfferSimpleLayout>
          )}
        </CommonRenderManager>
      </RenderManagerErrorProvider>
    </BookingOfferDetailContext.Provider>
  );
};

export default BookingOfferDetailsContainer;
