import { Link } from '@mui/material';
import UserAttributeLayout from 'presentation/layouts/userAttribute';
import { ReactNode } from 'react';

type UserProfileViewAttributeProps = {
  readonly label?: ReactNode;
  readonly icon?: ReactNode;
  readonly value: ReactNode;
  readonly onTryChange?: () => void;
};

const Layout = UserAttributeLayout;

const UserProfileViewAttribute = (props: UserProfileViewAttributeProps) => {
  const { label, icon, value, onTryChange } = props;

  const action = onTryChange && (
    <Link
      component='div'
      color='primary'
      variant='caption'
      onClick={onTryChange}
    >
      Изменить
    </Link>
  );

  return (
    <Layout
      label={label}
      icon={icon}
      action={action}
    >
      {value}
    </Layout>
  );
};

export default UserProfileViewAttribute;
