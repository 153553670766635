import TradeOfferScreen from 'presentation/screen/tradeOffer';
import TradeOffersScreen from 'presentation/screen/tradeOffers';
import TradeOffersSearchResultsScreen from 'presentation/screen/tradeOffersSearch';
import { Route, Switch } from 'react-router-dom';
import { routing } from './routes';

const TradeOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={TradeOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={TradeOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={TradeOfferScreen}
      />
    </Switch>
  );
};

export default TradeOfferEntry;
