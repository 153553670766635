import { GroupedCategoryAttribute } from '../components/attributes/utils/prepareViewData';

export type FormattedAttributes = {
  readonly label: string;
  readonly value: string;
};

export const formatOneVariantAttributes = (attributes: GroupedCategoryAttribute[]): FormattedAttributes[] => {
  return attributes.map(a => {
    return {
      label: a.attribute?.group?.value ?? a.attribute.name,
      value: a.valuesGroup?.[0]?.values?.[0].value ?? '',
    };
  });
};
