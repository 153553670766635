import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import PartnerDeskListContainer from 'presentation/features/partnerDesk/list/container';
import ScreenLayout from '../../layouts/screen';

const PartnerDesksScreen = () => {
  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <PartnerDeskListContainer guid={guid} />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default PartnerDesksScreen;
