import CorpOfferScreen from 'presentation/screen/corpOffer';
import CorpOffersScreen from 'presentation/screen/corpOffers';
import CorpOffersSearchResultsScreen from 'presentation/screen/corpOffersSearch';
import { Route, Switch } from 'react-router-dom';
import { routing } from './routes';

const CorpOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={CorpOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={CorpOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={CorpOfferScreen}
      />
    </Switch>
  );
};

export default CorpOfferEntry;
