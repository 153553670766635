import ActivationsNotificationsReceiver from '../activation/notificationReceiver/container';
import CorpOffersNotificationsReceiver from '../offer/corp/notificationReceiver/container';
import UserNotificationsReceiver from '../user/notificationReceiver/container';

const AppNotificationsReceiver = () => {
  return (
    <>
      <UserNotificationsReceiver />
      <ActivationsNotificationsReceiver />
      <CorpOffersNotificationsReceiver />
    </>
  );
};

export default AppNotificationsReceiver;
