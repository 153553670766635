import { useMemo } from 'react';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';

const PartnerDeskDetailsTabContactsToolbar = () => {
  return useMemo(
    () => <PartnerDeskDetailsTabToolbarLayout title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.Contacts)} />,
    []
  );
};

export default PartnerDeskDetailsTabContactsToolbar;
