import moment from 'moment/moment';
import { DateObject } from 'react-multi-date-picker';
import { CalendarSelection } from './types';

const disabledObject = {
  disabled: true,
};

/**
 * Возвращает функцию mapDays для календаря в зависимости от текущих ограничений.
 * Не учитывает одновременно положительные limitPastSelection и limitFutureSelection.
 */
export const useLimitedDays = ({ limitPastSelection, limitFutureSelection }: CalendarSelection) => {
  const today = limitPastSelection ? moment().startOf('day') : moment().endOf('day');
  const hasLimitedSelection = limitPastSelection || limitFutureSelection;

  return hasLimitedSelection
    ? ({ date }: { date: DateObject }) => {
        const day = moment(date.toDate());
        const compareDay = limitPastSelection ? day.endOf('day') : day.startOf('day');
        const isDisabled = limitPastSelection ? compareDay.isBefore(today) : compareDay.isAfter(today);

        if (isDisabled) {
          return disabledObject;
        }

        return undefined;
      }
    : undefined;
};
