import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { ECorpOfferSortType, EOfferListType } from 'domain/model/enums';
import { corpOffersDefaultParams } from '../../utils';

export type CorpOfferListState = {
  readonly guid: Nullable<UUID>;
  readonly isNewFetching: boolean;
  readonly isNewSearchFetching: boolean;
  readonly [EOfferListType.Common]: OfferListRequest;
  readonly [EOfferListType.Upcoming]: OfferListRequest;
};

type Reducer<T> = CaseReducer<CorpOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CorpOfferListState> & {
  corpOffersStartSession: Reducer<{ guid: UUID }>;
  corpOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: number }>;
  corpOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  corpOffersSetSort: Reducer<{ type: EOfferListType; sort: ECorpOfferSortType[] }>;
  corpOffersSetArgs: Reducer<{ type: EOfferListType; args: OfferListRequest }>;
  corpOffersSetIsNewFetching: Reducer<boolean>;
  corpOffersSetIsNewSearchFetching: Reducer<boolean>;
};

const emptyParams = corpOffersDefaultParams;

const slice = createSlice<CorpOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    common: emptyParams,
    upcoming: emptyParams,
    isNewFetching: true,
    isNewSearchFetching: true,
  },
  reducers: {
    corpOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
    corpOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;

      state[type].pageSize = pageSize;
    },
    corpOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;

      state[type].sort = sort;
    },
    corpOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;

      state[type] = args;
    },
    corpOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;

      state[type].page = page;
    },
    corpOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
    corpOffersSetIsNewSearchFetching: (state, { payload }) => {
      state.isNewSearchFetching = payload;
    },
  },
});

export const {
  corpOffersSetPageSize,
  corpOffersSetPage,
  corpOffersStartSession,
  corpOffersSetSort,
  corpOffersSetArgs,
  corpOffersSetIsNewFetching,
  corpOffersSetIsNewSearchFetching,
} = slice.actions;

export default slice.reducer;
