import { RootState } from 'data/store/store';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type GeneralLoaderFixedAdapterProps = {
  readonly selector: (state: RootState) => boolean;
};

const GeneralLoaderFixedAdapter = ({ selector }: GeneralLoaderFixedAdapterProps) => {
  const isFetching = useSelector(selector);

  return useMemo(() => (isFetching ? <ContentLoader position='fixed' /> : null), [isFetching]);
};

export default GeneralLoaderFixedAdapter;
