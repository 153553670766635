import { LinkProps } from '@mui/material';
import { Link } from './controls';

type DocLinkProps = LinkProps;

export const DocLink = (props: DocLinkProps) => {
  const { children, target = '_blank', ...otherProps } = props;

  return (
    <Link
      {...otherProps}
      target={target}
    >
      {children}
    </Link>
  );
};
