import { Badge } from '@mui/material';
import { FilterIcon } from 'presentation/media/icons';
import { MPButton } from 'presentation/theme/ui-kit/button';
import AppSvgIcon from '../../icon';

type DataFilterButtonProps = {
  readonly count: number;
  readonly onClick: VoidFunction;
};

const DataFilterButton = ({ count, onClick }: DataFilterButtonProps) => {
  return (
    <MPButton
      size='small'
      color='white'
      endIcon={
        <Badge
          color='brand'
          badgeContent={count}
          invisible={count === 0}
        >
          <AppSvgIcon
            icon={FilterIcon}
            color='black'
          />
        </Badge>
      }
      onClick={onClick}
    >
      Фильтр
    </MPButton>
  );
};

export default DataFilterButton;
