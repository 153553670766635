import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import useScrollToElement from '../../../hooks/useScrollToElement';
import { AboutInfoSection } from '../types';

type RefType<T> = { readonly element: Nullable<HTMLElement>; readonly id: T };

export type ScrollToSections<T extends string> = {
  readonly current: Nullable<AboutInfoSection<T>>;
  readonly sectionsRefs: MutableRefObject<RefType<T>[]>;
  readonly expandedItem: Nullable<AboutInfoSection<T>>;
  readonly scrollTo: (el: HTMLElement) => void;
  readonly onSectionToggle: (item: AboutInfoSection<T>) => void;
  readonly setSectionRef: (element: Nullable<HTMLDivElement>, id: T) => void;
  readonly setCurrentSection: (section: AboutInfoSection<T>) => void;
};

type ScrollToSectionsProps = {
  readonly topOffset: Nullable<number>;
};

const useScrollToSections = <T extends string>({ topOffset }: ScrollToSectionsProps): ScrollToSections<T> => {
  const [expandedItem, setExpandedItem] = useState<Nullable<AboutInfoSection<T>>>(null);
  const { headerHeight } = useHeaderParams();
  const [current, setCurrent] = useState<Nullable<AboutInfoSection<T>>>(null);

  const sectionsRefs = useRef<RefType<T>[]>([]);

  const setCurrentSection = useCallback((section: AboutInfoSection<T>) => {
    setCurrent(section);
  }, []);

  const { scrollTo } = useScrollToElement({ adjusmentTop: headerHeight + (topOffset ?? 0) });

  const setSectionRef = (element: Nullable<HTMLDivElement>, id: T) => {
    if (sectionsRefs.current.find(s => s.element === element)) {
      return;
    }
    sectionsRefs.current = [...sectionsRefs.current, { element, id }];
  };

  const onSectionToggle = (item: AboutInfoSection<T>) => {
    if (item.id === expandedItem?.id) setExpandedItem(null);
    else setExpandedItem(item);
  };

  useEffect(() => {
    if (!expandedItem) {
      return;
    }
    const currentEl = sectionsRefs.current.find(s => expandedItem.id === s.id);
    setCurrentSection(expandedItem);
    scrollTo(currentEl?.element as HTMLDivElement);
  }, [expandedItem, scrollTo, setCurrentSection]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return {
    current,
    sectionsRefs,
    expandedItem,
    scrollTo,
    onSectionToggle,
    setSectionRef,
    setCurrentSection,
  };
};

export default useScrollToSections;
