import { FC } from 'react';
import ContentLoader from 'presentation/components/common/loader';
import OfferListLayout from 'presentation/layouts/offerList';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import BookingOfferOrderDetailsAdapter from '../../../user/details/order/booking/adapters/details';
import useBookingOfferOrderData from './hooks/useBookingOfferOrderData';
import BookingOfferOrderHeader from '../components/order/header';
import BookingOfferOrderButtons from '../components/order/buttons';
import { OrderDetailsWrapper, OrderLayoutWrapper } from '../components/order/controls';

type BookingOfferContainerProps = {
  id: UUID;
};

const BookingOfferOrderContainer: FC<BookingOfferContainerProps> = ({ id }) => {
  const { order, isFetching } = useBookingOfferOrderData(id);

  return (
    <MPGrid
      container
      justifyContent='center'
    >
      <OrderLayoutWrapper>
        <OfferListLayout
          breadcrumb={null}
          header={BookingOfferOrderHeader}
          aside={null}
          stub={null}
          loader={isFetching ? <ContentLoader position='fixed' /> : null}
        >
          {!!order && (
            <>
              <OrderDetailsWrapper>
                <BookingOfferOrderDetailsAdapter order={order} />
              </OrderDetailsWrapper>
              <BookingOfferOrderButtons />
            </>
          )}
        </OfferListLayout>
      </OrderLayoutWrapper>
    </MPGrid>
  );
};

export default BookingOfferOrderContainer;
