import { Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FCC } from 'react';
import Splitter from '../../components/common/splitter';
import AboutTextSimpleComponent from '../../features/about/textSimple/component';
import BackgroundContainer from '../backgroundContainer';
import { EParentGridAreas } from '../bookingDetail/types';
import { FitContainer, ParentLayoutGridArea } from './controls';
import { AboutTextSimpleLayoutProps } from './types';

export const AboutTextSimpleLayout: FCC<AboutTextSimpleLayoutProps> = ({ title, close, children }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <FitContainer disableGutters={isMobile}>
      <ParentLayoutGridArea area={EParentGridAreas.Inner}>
        <BackgroundContainer
          close={close}
          variant='main'
        >
          <MPGrid
            container
            item
            direction='column'
            sm={8}
          >
            {title && (
              <MPGrid item>
                <Typography variant='h1'>{title}</Typography>
                <Splitter size={isMobile ? 2 : 5} />
              </MPGrid>
            )}
            {children && (
              <MPGrid item>
                <AboutTextSimpleComponent>{children}</AboutTextSimpleComponent>
              </MPGrid>
            )}
          </MPGrid>
        </BackgroundContainer>
      </ParentLayoutGridArea>
    </FitContainer>
  );
};
