import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsAspOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskAspOffersCategoriesAdapter from './adapters/categories';
import PartnerDeskAspOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskAspOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabAspOffersNoData from './noData';

type PartnerDeskDetailsTabAspOffersProps = {
  readonly guid: UUID;
};

const Layout = PartnerDeskOffersTabLayout;

export const PartnerDeskDetailsTabAspOffers = (props: PartnerDeskDetailsTabAspOffersProps) => {
  const { guid } = props;

  return (
    <Layout
      toolbar={<PartnerDeskAspOffersCategoriesAdapter />}
      stub={<PartnerDeskDetailsTabAspOffersNoData />}
      loader={<GeneralLoaderFixedAdapter selector={partnerDeskDetailsAspOffersIsFetchingSelector} />}
    >
      <PartnerDeskAspOffersCommonAdapter guid={guid} />
      <PartnerDeskAspOffersUpcomingAdapter guid={guid} />
    </Layout>
  );
};
