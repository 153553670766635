import OfferListLayout from 'presentation/layouts/offerList';
import {
  AspOfferListAsideAdapter,
  AspOfferListDataCommonAdapter,
  AspOfferListDataUpcomingAdapter,
  AspOfferListLoaderAdapter,
  AspOfferListSortSelectAdapter,
  AspOfferListStubAdapter,
  AspOffersHeaderAdapter,
} from './adapters';
import { AspOfferListContext } from './context';
import useAspOfferListHandlers from './hooks/useAspOfferList';

const Layout = OfferListLayout;
const Header = <AspOffersHeaderAdapter />;
const Aside = <AspOfferListAsideAdapter />;
const Stub = <AspOfferListStubAdapter />;
const Loader = <AspOfferListLoaderAdapter />;
const SortSelect = <AspOfferListSortSelectAdapter />;

type AspOfferListContainerProps = {
  readonly guid: UUID;
  readonly name: Nullable<string>;
  readonly categoryId: Nullable<UUID>;
  readonly partnerId: Nullable<UUID>;
};

const AspOfferListContainer = (props: AspOfferListContainerProps) => {
  const { guid, name, categoryId, partnerId } = props;

  const handlers = useAspOfferListHandlers({ guid, name, categoryId, partnerId });

  return (
    <AspOfferListContext.Provider value={handlers}>
      <Layout
        header={Header}
        aside={Aside}
        controls={SortSelect}
        stub={Stub}
        loader={Loader}
      >
        <AspOfferListDataCommonAdapter guid={guid} />
        <AspOfferListDataUpcomingAdapter guid={guid} />
      </Layout>
    </AspOfferListContext.Provider>
  );
};

export default AspOfferListContainer;
