import styled from '@emotion/styled/macro';
import { forwardRef } from 'react';
import { css, IconButton, IconButtonProps, svgIconClasses } from '@mui/material';

export const CarouselNavigateButton = styled(
  forwardRef<any, IconButtonProps>((props, ref) => (
    <IconButton
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    width: ${theme.spacing(5.5)};
    height: ${theme.spacing(5.5)};
    color: ${theme.palette.secondary.main};
    border-radius: ${theme.spacing()};

    position: absolute;
    z-index: 1;

    top: 50%;
    transform: translateY(-50%);
    background-color: ${theme.palette.background.carouselNavigation};

    &:hover {
      background-color: ${theme.palette.secondary.A600};
    }

    & .${svgIconClasses.root} {
      color: ${theme.palette.black.main};
      transition: color ${theme.transitions.duration?.standard}ms;
    }
  `
);

export const CarouselNavigateButtonPrev = styled(
  forwardRef<any, any>((props, ref) => (
    <CarouselNavigateButton
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    left: ${theme.spacing(2)};
  `
);

export const CarouselNavigateButtonNext = styled(
  forwardRef<any, any>((props, ref) => (
    <CarouselNavigateButton
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    right: ${theme.spacing(2)};
  `
);
