import { Link, Typography } from '@mui/material';
import { EAddressOption } from 'domain/model/enums';
import { PartnerContactData } from 'domain/model/partner';
import { AddressFullView } from 'presentation/components/common/address';
import SectionTitle from 'presentation/components/common/sectionTitle';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { phoneFormatView } from 'presentation/utils/phone';
import useGridSpacing from '../../../../theme/hooks/useGridSpacing';

type PartnerDeskContactProps = {
  readonly contact: PartnerContactData;
};

const PartnerDeskContact = ({ contact }: PartnerDeskContactProps) => {
  const { name, phone, email, feedbackLink, address } = contact;

  const { x2 } = useGridSpacing();

  return (
    <MPGrid
      container
      spacing={x2}
      direction='column'
    >
      <MPGrid
        item
        zero
      >
        <Typography variant='h3'>{name}</Typography>
      </MPGrid>
      {address && (
        <MPGrid item>
          <SectionTitle gutterBottom={false}>Адрес</SectionTitle>
          <AddressFullView
            address={address}
            options={[EAddressOption.PostalCode]}
          />
        </MPGrid>
      )}
      {phone && (
        <MPGrid item>
          <SectionTitle gutterBottom={false}>Телефон</SectionTitle>
          <Typography
            component={Link}
            href={`tel:${phoneFormatView(phone)}`}
            color='textPrimary'
            underline='hover'
          >
            {phoneFormatView(phone)}
          </Typography>
        </MPGrid>
      )}
      {email && (
        <MPGrid item>
          <SectionTitle gutterBottom={false}>Электронная почта</SectionTitle>
          <Typography
            component={Link}
            href={`mailto:${email}`}
            color='textPrimary'
            underline='hover'
          >
            {email}
          </Typography>
        </MPGrid>
      )}
      {feedbackLink && (
        <MPGrid item>
          <SectionTitle gutterBottom={false}>Ссылка на форму обратной связи</SectionTitle>
          <Typography
            component={Link}
            href={feedbackLink}
            target='_blank'
            color='textPrimary'
            underline='hover'
          >
            {feedbackLink}
          </Typography>
        </MPGrid>
      )}
    </MPGrid>
  );
};

export default PartnerDeskContact;
