import { Link } from '@mui/material';
import Logo from '../../../../components/common/logo';
import { Wrapper } from './controls';

type HeaderTitleProps = {
  readonly onClickLogo: () => void;
};

const HeaderTitle = (props: HeaderTitleProps) => {
  const { onClickLogo } = props;

  return (
    <Wrapper>
      <Link onClick={onClickLogo}>
        <Logo />
      </Link>
    </Wrapper>
  );
};

export default HeaderTitle;
