import { FavoriteOutlineIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemFavoritesProps = {
  readonly count?: number;
  readonly onClick?: () => void;
};

export const UserMenuItemFavorites: FC<UserMenuItemFavoritesProps> = ({ count, onClick }) => {
  return (
    <UserMenuItem
      label='Избранное'
      count={count}
      icon={<FavoriteOutlineIcon color='black' />}
      onClick={onClick}
    />
  );
};
