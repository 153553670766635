import { Location } from 'history';
import CmsCollectionScreen from 'presentation/screen/cms/collection';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';

const root = rootRouting.data;

const routing = {
  root,
  cmsCollection: `${root}/collection/:id`,
};

export type DataLocationState = {
  readonly guid?: UUID;
};

type GetDataCmsCollectionRouteProps = {
  readonly id: UUID;
  readonly guid?: UUID;
};

export const getDataCmsCollectionRoute = (props: GetDataCmsCollectionRouteProps): Location<DataLocationState> => {
  const { id, guid } = props;

  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  return {
    pathname: routing.cmsCollection.replace(':id', id),
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

/**
 * точка входа для данных общего назначения, без явной привязки к предметной области
 * скрины рисующиеся отсюда максимально автономны
 */
const DataEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.cmsCollection}
        component={CmsCollectionScreen}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default DataEntry;
