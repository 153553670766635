import { Typography } from '@mui/material';
import { paginationSizeVariant } from 'domain/model/constants';
import { MPMenuItem } from 'presentation/theme/ui-kit/menu/item';
import { useMemo } from 'react';
import { PaginationSize } from '../../../../types';
import { Select } from './controls';

type PageSizeSelectProps = {
  readonly totalCount: number;
  readonly value: PaginationSize;
  readonly onChange: (value: PaginationSize) => void;
  readonly children?: (content: JSX.Element) => void;
};

const variants = paginationSizeVariant;
const minPageSize = variants[0];

const PageSizeSelect = (props: PageSizeSelectProps) => {
  const { totalCount, value, onChange, children } = props;

  const selectorContent = useMemo(
    () => (
      <Select<PaginationSize>
        fullWidth={false}
        variant='filled'
        value={value}
        onChange={onChange}
      >
        {variants.map(variant => (
          <MPMenuItem<number>
            key={variant}
            value={variant}
          >
            <Typography variant='body1'>Показывать по {variant}</Typography>
          </MPMenuItem>
        ))}
      </Select>
    ),
    [value, onChange]
  );

  if (totalCount <= minPageSize) return null;

  if (children) return <>{children(selectorContent)}</>;

  return selectorContent;
};

export default PageSizeSelect;
