import { ButtonProps } from '@mui/material';
import { StyledLightButton } from './controls';

const LightButton = (props: ButtonProps) => {
  return (
    <StyledLightButton
      {...props}
      variant='outlined'
    />
  );
};

export default LightButton;
