import { useEffect, useState } from 'react';
import { secondsToHms } from '../../../../utils/date';

type CountdownProps = {
  readonly seconds?: number;
  readonly active: boolean;
  readonly stop: () => void;
};

const Countdown = (props: CountdownProps) => {
  const { seconds = 0, active, stop } = props;
  const [left, setLeft] = useState<number>(seconds);

  useEffect(() => {
    let localLeft = left;
    const tick = () => {
      if (!active) return;

      if (localLeft <= 0) {
        stop();
      } else {
        localLeft--;
        setLeft(localLeft);
      }
    };

    const timerID = setInterval(tick, 1000);
    return () => clearInterval(timerID);
  }, [left, active, stop]);

  useEffect(() => {
    setLeft(seconds);
  }, [seconds]);

  return <>{secondsToHms(left)}</>;
};

export default Countdown;
