import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

type BannerImageWrapperProps = {
  readonly height: Nullable<string>;
};

const mobileImageMultiplier = 0.44;

export const Wrapper = styled.div<BannerImageWrapperProps>`
  height: 100%;
  ${p => (p.height ? `max-height: ${p.height};` : '')}

  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1.5)};

  > * {
    width: 100%;
    height: auto;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    gap: ${p => p.theme.spacing()};
    ${p => (p.height ? `max-height: ${Math.floor(+p.height * mobileImageMultiplier)};` : '')}
  }
`;

export const ImageWrapper = styled.div(
  ({ theme }) => css`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${theme.shape.borderRadius * 2}px;

    > * {
      // для первого контейнера, вдруг img обёрнут во что-то сверху
      width: 100%;
      height: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      border-radius: ${theme.shape.borderRadius}px;
    }
  `
);

export const LogoWrapper = styled.div`
  width: 51px !important;
  height: 52px !important;

  position: absolute;
  right: 0;
  top: 0;

  ${p => p.theme.breakpoints.down('md')} {
    width: 32px !important;
    height: 32.7px !important;
  }
`;

export const LogoBackground = styled.div`
  width: 100%;
  height: 100%;
  color: ${p => p.theme.palette.background.default};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LogoIconWrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  position: absolute;
  display: flex;
  align-items: start;
  justify-content: end;

  padding: ${p => p.theme.spacing()};

  ${p => p.theme.breakpoints.down('md')} {
    padding: ${p => p.theme.spacing(0.5)};
  }
`;
