import { IconProps, Typography, TypographyProps } from '@mui/material';
import { EBalanceType } from 'domain/model/enums';
import { toCurrency, toRubCurrency } from 'presentation/utils/currency';
import { FC } from 'react';
import { Wrapper } from './controls';
import { PaymentIcon } from 'presentation/components/common/paymentIcon';

type UserBalanceShortProps = Pick<TypographyProps, 'variant' | 'component' | 'onClick'> & {
  readonly type: EBalanceType;
  readonly value: Nullable<number>;
  readonly hideIcon?: boolean;
};

export const UserBalanceValue: FC<UserBalanceShortProps> = ({ value, type, hideIcon, onClick, ...others }) => {
  let func = toCurrency;
  switch (type) {
    case EBalanceType.Bonuses:
      func = toCurrency;
      break;
    case EBalanceType.ASP:
      func = toRubCurrency;
      break;
  }

  let iconSize: IconProps['fontSize'];
  switch (others.variant) {
    case 'h2':
      iconSize = 'medium';
      break;
    default:
      iconSize = 'small';
      break;
  }

  return (
    value !== null && (
      <Wrapper onClick={onClick}>
        {!hideIcon && (
          <PaymentIcon
            type={type}
            fontSize={iconSize}
          />
        )}
        <Typography
          variant='body2'
          component='div'
          {...others}
        >
          {func(value)}
        </Typography>
      </Wrapper>
    )
  );
};
