import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import AppSvgIcon from '../../../../components/common/icon';

export const Wrapper = styled(Grid)``;

export const Icon = styled(AppSvgIcon)`
  width: 3rem;
  height: 3rem;
`;
