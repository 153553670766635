import { useEffect } from 'react';
import { sectionLinksSetVisible } from '../store/slice';
import { useDispatch } from 'react-redux';

const useVisibleSectionLinks = (stop?: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (stop) {
      return;
    }

    dispatch(sectionLinksSetVisible(true));

    return () => {
      dispatch(sectionLinksSetVisible(false));
    };
  }, [dispatch, stop]);
};

export default useVisibleSectionLinks;
