import { Typography } from '@mui/material';
import ClaimMessage from 'presentation/features/offer/components/claim/message';
import { OfferActivationError } from '../../../../types';
import { getOfferActivationErrorText } from '../../../../utils';

type TradeOfferClaimErrorMessageProps = {
  readonly error: OfferActivationError;
};

export const TradeOfferClaimErrorMessage = ({ error }: TradeOfferClaimErrorMessageProps) => {
  return (
    <ClaimMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        {getOfferActivationErrorText(error)}
      </Typography>
    </ClaimMessage>
  );
};
