import { useMemo } from 'react';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';

const PartnerDeskDetailsTabAboutCompanyToolbar = () => {
  return useMemo(
    () => (
      <PartnerDeskDetailsTabToolbarLayout title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AboutCompany)} />
    ),
    []
  );
};

export default PartnerDeskDetailsTabAboutCompanyToolbar;
