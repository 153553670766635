import openApi from 'data/openApi';
import { userThemeSelector } from 'presentation/features/user/theme/store/selectors';
import { ExternalTheme } from 'presentation/theme';
import { useSelector } from 'react-redux';

const useUserTheme = (): Nullable<ExternalTheme> => {
  const theme = useSelector(userThemeSelector);

  return {
    ...theme,
    logo: theme?.logo ? openApi.files.getImageUrl(theme?.logo) : undefined,
    favicon: theme?.favicon ? openApi.files.getImageUrl(theme?.favicon) : undefined,
  };
};

export default useUserTheme;
