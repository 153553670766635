import { Tab, Tabs, TabProps } from '@mui/material';

export const MPTabs = Tabs;

export const MPTab = (props: TabProps & { hidden?: boolean }) => {
  return (
    <Tab
      {...props}
      style={{ display: props.hidden ? 'none' : 'static' }}
    />
  );
};
