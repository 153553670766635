import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;

    cursor: pointer;

    color: ${theme.palette.text.primary};

    .MuiSvgIcon-root {
      height: 0.9rem;
    }

    ${theme.breakpoints.down('sm')} {
      .MuiSvgIcon-root {
        height: unset;

        font-size: 1.25rem;
      }
    }
  `
);
