import { RequiredActionConfirmCodeContainer } from '../../../requiredAction/confirmCode/container';
import { useCodeConfirm } from '../hooks/useCodeConfirm';

type CodeConfirmationAdapterProps = {
  readonly onClose: () => void;
  readonly onNext: () => void;
};

export const CodeConfirmationAdapter = (props: CodeConfirmationAdapterProps) => {
  const { onClose, onNext } = props;

  const { email, source, onConfirmed, onGetNewCode } = useCodeConfirm();

  const onSuccess = () => {
    onConfirmed();
    onNext();
  };

  return (
    source && (
      <RequiredActionConfirmCodeContainer
        email={email}
        source={source}
        onSuccess={onSuccess}
        onGetNewCode={onGetNewCode}
        onClose={onClose}
      />
    )
  );
};
