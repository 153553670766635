import { UUID } from 'domain/model/types';
import AspOfferClaimContainer from 'presentation/features/offer/asp/claim/container';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';

type AspOfferDetailsSidebarAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

export const AspOfferDetailsSidebarAdapter = ({ guid, id }: AspOfferDetailsSidebarAdapterProps) => {
  const { aspOffer } = useAspOfferDetailsData(id);
  const { partnerDesk } = useAspOfferPartnerDeskData(id);

  if (!aspOffer) {
    return null;
  }

  return (
    <AspOfferClaimContainer
      guid={guid}
      aspOffer={aspOffer}
      partnerDesk={partnerDesk}
    />
  );
};
