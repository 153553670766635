import styled from '@emotion/styled/macro';
import { css, Link, LinkProps as MUILinkProps, Typography } from '@mui/material';

const StyledCategoryLink = styled(Link)(
  ({ theme }) => css`
    padding: ${theme.spacing(0.5, 1.5)};
    border-radius: ${theme.spacing(0.5)};

    &:hover {
      background-color: ${theme.palette.secondary.A500};
    }
  `
);

type CategoryLinkProps = {
  readonly onCategoryClick: (id: string) => void;
  readonly id: string;
};

export const CategoryLink = ({ onCategoryClick, id, children, ...other }: MUILinkProps & CategoryLinkProps) => {
  return (
    <StyledCategoryLink
      underline='none'
      onClick={() => onCategoryClick(id)}
      {...other}
    >
      <Typography
        color='textPrimary'
        variant='body1'
      >
        {children}
      </Typography>
    </StyledCategoryLink>
  );
};
