import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import useCorpOfferDetailsData from 'presentation/features/offer/corp/details/hooks/useCorpOfferDetailsData';
import CorpOfferClaimContainer from '../../claim/container';

type CorpOfferDetailSidebarAdapterProps = {
  readonly id: UUID;
};

const CorpOfferDetailSidebarAdapter = ({ id }: CorpOfferDetailSidebarAdapterProps) => {
  const { user } = useAuthUser();
  const { corpOffer } = useCorpOfferDetailsData(id);

  if (!corpOffer) {
    return null;
  }

  return user ? <CorpOfferClaimContainer corpOffer={corpOffer} /> : null;
};

export default CorpOfferDetailSidebarAdapter;
