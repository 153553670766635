import { BadgeProps } from '@mui/material';
import { Badge } from './controls';

type OptionBadgeProps = Pick<BadgeProps, 'badgeContent' | 'children' | 'showZero'>;

const OptionBadge = ({ badgeContent, showZero, children }: OptionBadgeProps) => {
  return (
    <Badge
      overlap='circular'
      color='brand'
      showZero={showZero}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      badgeContent={badgeContent}
    >
      {children}
    </Badge>
  );
};

export default OptionBadge;
