import { Address } from 'domain/model/address';
import CitySelectDialog from 'presentation/components/common/dialogs/citySelect';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../hooks/useUserLocalSettings';
import { useContextHandlers } from './hooks/useContextHandlers';
import { headerCityChangeDialogVisibleSelector } from './store/selectors';

const HeaderDialogs = () => {
  const { setCity } = useUserLocalSettings();

  const cityChangeDialogVisible = useSelector(headerCityChangeDialogVisibleSelector);
  const handlers = useContextHandlers();

  const onChangeCity = useCallback(
    (city: Address) => {
      setCity(city.id, new AddressHelper(city).getLocalityShortPath());
    },
    [setCity]
  );

  return (
    <CitySelectDialog
      visible={cityChangeDialogVisible}
      onChangeCity={onChangeCity}
      onClose={handlers.hideChangeLocationDialog}
    />
  );
};

export default HeaderDialogs;
