import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { aspOffersDefaultParams } from '../../utils';

export type AspOfferRelatedListState = {
  readonly args: OfferListRequest;
};

type Reducer<T> = CaseReducer<AspOfferRelatedListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AspOfferRelatedListState> & {
  aspRelatedOffersSetArgs: Reducer<OfferListRequest>;
};

const emptyParams = aspOffersDefaultParams;

const slice = createSlice<AspOfferRelatedListState, Reducers, 'relatedList'>({
  name: 'relatedList',
  initialState: {
    args: emptyParams,
  },
  reducers: {
    aspRelatedOffersSetArgs: (state, { payload }) => {
      state.args = payload;
    },
  },
});

export const { aspRelatedOffersSetArgs } = slice.actions;

export default slice.reducer;
