import { FCC, ReactNode } from 'react';
import { HelpWrapper } from './controls';

type ClaimInfoLayoutProps = {
  readonly help?: ReactNode;
};

const ClaimInfoLayout: FCC<ClaimInfoLayoutProps> = ({ children, help }) => {
  return (
    <>
      {help && <HelpWrapper>{help}</HelpWrapper>}
      {children}
    </>
  );
};

export default ClaimInfoLayout;
