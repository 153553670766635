import { partnersStub } from 'presentation/media/images';
import { useMemo } from 'react';
import StubCommon from '../../../../components/common/stub/common';
import usePartnerDeskList from '../provider/usePartnerDeskList';

const PartnerDeskListStubAdapter = () => {
  const { isEmpty } = usePartnerDeskList();

  return useMemo(
    () =>
      isEmpty ? (
        <StubCommon
          title='По вашему запросу ничего не найдено'
          text='Попробуйте сократить запрос или задать его по-другому'
          image={partnersStub}
        />
      ) : null,
    [isEmpty]
  );
};

export default PartnerDeskListStubAdapter;
