import { Typography } from '@mui/material';

const CartOrderCreateHelp = () => {
  return (
    <Typography variant='body2'>
      Данные заказов будут отправлены партнёрам. Менеджер партнёра свяжется с вам для уточнения деталей доставки и
      оплаты
    </Typography>
  );
};

export default CartOrderCreateHelp;
