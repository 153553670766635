import { LoggedUserAdapter } from 'presentation/features/auth/provider/loggedUserAdapter';
import { FC } from 'react';
import { User } from '../../../../../domain/model/user';
import {
  UserProfileActionsAdapter,
  UserProfileBehaviorAdapter,
  UserProfileCommonAdapter,
  UserProfileCorpLinkedAdapter,
  UserProfileEmailAdapter,
  UserProfileLoaderAdapter,
  UserProfileLocalityAdapter,
  UserProfilePasswordAdapter,
  UserProfilePhoneAdapter,
} from './adapters';
import UserProfileLayout from './layout';

const Layout = UserProfileLayout;

type UserProfileContainerInternalProps = {
  readonly user: User;
};

const UserProfileContainerInternal: FC<UserProfileContainerInternalProps> = ({ user }) => {
  return (
    <Layout
      title='Настройки'
      subTitle='Профиль'
      loader={<UserProfileLoaderAdapter />}
      corpLinked={<UserProfileCorpLinkedAdapter />}
      actions={<UserProfileActionsAdapter />}
      /*notifications={<UserProfileNotificationsAdapter />} временно отключено в рамках редизайна*/
    >
      <UserProfileCommonAdapter user={user} />
      <UserProfilePhoneAdapter user={user} />
      <UserProfileEmailAdapter user={user} />
      <UserProfileLocalityAdapter user={user} />
      <UserProfilePasswordAdapter user={user} />
    </Layout>
  );
};

const UserProfileContainer = () => {
  return (
    <>
      <UserProfileBehaviorAdapter />
      <LoggedUserAdapter component={UserProfileContainerInternal} />
    </>
  );
};

export default UserProfileContainer;
