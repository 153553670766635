import { Typography } from '@mui/material';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { CheckIcon } from 'presentation/media/icons';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { useMemo } from 'react';

export const UserProfileCorpLinkedAdapter = () => {
  const { isCorpUser } = useAuthUser();

  return useMemo(
    () =>
      isCorpUser ? (
        <MPChip
          icon={<CheckIcon fontSize='small' />}
          label={<Typography variant='caption'>Работник ОАО «РЖД»</Typography>}
          size='small'
          color='success'
        />
      ) : null,
    [isCorpUser]
  );
};
