import { ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import BackButton from '../../../../../features/offer/components/back';
import { DialogBackButtonWrapper } from './controls';

export type MPDialogBackButtonProps = Pick<ButtonProps, 'onClick'> & {
  readonly label?: ReactNode;
};

export const MPDialogBackButton = ({ label = 'Назад', onClick }: MPDialogBackButtonProps) => {
  return (
    <DialogBackButtonWrapper>
      <BackButton
        onClick={onClick}
        label={label}
      />
    </DialogBackButtonWrapper>
  );
};
