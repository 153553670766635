import { MPButton } from 'presentation/theme/ui-kit/button';
import React from 'react';
import { MPConfirmDialog } from '../../../../../../theme/ui-kit/dialog';

type ProductOfferPaymentInfoDialogProps = {
  readonly open: boolean;
  readonly info: React.ReactNode;
  readonly onClose: () => void;
};

const ProductOfferPaymentInfoDialog = ({ open, info, onClose }: ProductOfferPaymentInfoDialogProps) => {
  return (
    <MPConfirmDialog
      open={open}
      title='Оплата'
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <MPButton
          size='large'
          fullWidth
          color='brand'
          variant='contained'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
    >
      {info}
    </MPConfirmDialog>
  );
};

export default ProductOfferPaymentInfoDialog;
