import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPartnerDeskQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useContext, useEffect } from 'react';
import useAspOfferDetailsData from './useAspOfferDetailsData';

const useAspOfferPartnerDeskData = (id: UUID) => {
  const { setError } = useContext(RenderManagerErrorContext);
  const { aspOffer, isAspOfferFetched } = useAspOfferDetailsData(id);

  const partnerDeskId = isAspOfferFetched ? aspOffer?.partner?.id : undefined;

  const {
    data: partnerDesk,
    error: partnerDeskFetchError,
    isError: isPartnerDeskError,
    isLoading: isPartnerDeskLoading,
    isFetching: isPartnerDeskFetching,
  } = useGetPartnerDeskQuery(partnerDeskId ?? skipToken);

  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
      setError(partnerDeskFetchError);
    }
  }, [partnerDeskFetchError, setError]);

  return {
    partnerDesk,
    partnerDeskFetchError,
    isPartnerDeskError,
    isPartnerDeskFetching,
    isPartnerDeskLoading,
  };
};

export default useAspOfferPartnerDeskData;
