import AboutTermsOfUseContainer from 'presentation/features/about/termsOfUse/container';
import ScreenLayout from 'presentation/layouts/screen';

const LegalTermsOfUseScreen = () => {
  return (
    <ScreenLayout>
      <AboutTermsOfUseContainer />
    </ScreenLayout>
  );
};

export default LegalTermsOfUseScreen;
