import useIsVisibleOnce, { UseIsVisibleOnceProps } from 'presentation/hooks/useIsVisibleOnce';
import { cloneElement, JSX, useMemo, useRef } from 'react';

export type InViewProps<T> = UseIsVisibleOnceProps & {
  readonly children: JSX.Element;
  readonly active: boolean;
  readonly data: T;
  readonly onShow?: (data: T) => void;
};

const InView = <T,>(props: InViewProps<T>) => {
  const { children, sessionKey = '', threshold = 0.95, rootMargin, root, active, data, onShow } = props;

  const optionInternal = useMemo<UseIsVisibleOnceProps>(() => {
    return {
      sessionKey,
      threshold,
      root,
      rootMargin,
    };
  }, [sessionKey, root, rootMargin, threshold]);

  const ref = useRef<any>();
  useIsVisibleOnce(ref, optionInternal, active, data, onShow);

  return cloneElement(children, { ref });
};

export default InView;
