import { AspOffer } from 'domain/model/aspOffer';
import { EOfferActivateError } from 'domain/model/enums';
import UserNeedFillProfileDialog from 'presentation/features/user/components/needFillProfileDialog';
import { FCC, useEffect, useState } from 'react';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import AspOfferHelpDialog from '../../components/dialogs/help';
import useAspOfferClaimContext from '../provider/useAspOfferClaimContext';
import AspOfferClaimDialogsContext, { AspOfferClaimDialogsContextType } from './context';
import { EAspOfferClaimDialogTag } from '../types';
import OfferNdflHelpDialog from '../../../components/dialogs/ndfl';

type AspOfferClaimDialogsProviderProps = {
  readonly aspOffer: AspOffer;
  readonly onShowAllOffers: () => void;
};

const AspOfferClaimDialogsProvider: FCC<AspOfferClaimDialogsProviderProps> = props => {
  const { children, aspOffer, onShowAllOffers } = props;
  const { activation, onFillProfile } = useAspOfferClaimContext();

  const [profileDialogState, setProfileDialogState] = useState<Nullable<{ message?: string }>>();

  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Help });

  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Unavailable });

  const {
    open: isNdflDialogOpened,
    onOpen: onOpenNdflDialog,
    onClose: onCloseNdflDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Ndfl });

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.ProfileNotFilled) {
      setProfileDialogState({ message: activation.activationError.message });
    }
  }, [activation.activationError, setProfileDialogState]);

  const value: AspOfferClaimDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openHelpDialog: onOpenHelpDialog,
    openNdflDialog: onOpenNdflDialog,
    onShowAllOffers,
  };

  return (
    <AspOfferClaimDialogsContext.Provider value={value}>
      {children}
      <OfferNdflHelpDialog
        open={isNdflDialogOpened}
        description={'Текст про НДФЛ'}
        onClose={onCloseNdflDialog}
      />
      <OfferUnavailableDialog
        open={isUnavailableDialogOpened}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      <AspOfferHelpDialog
        promotionType={aspOffer.promotionType}
        open={isHelpDialogOpened}
        onClose={onCloseHelpDialog}
      />
      <UserNeedFillProfileDialog
        open={!!profileDialogState}
        message={profileDialogState?.message}
        onFill={onFillProfile}
        onClose={() => setProfileDialogState(null)}
      />
    </AspOfferClaimDialogsContext.Provider>
  );
};

export default AspOfferClaimDialogsProvider;
