import { useMemo } from 'react';
import HeaderOptions from '../../components/options';
import { EHeaderOptionType } from '../../components/options/types';
import useHeaderOptions from '../useHeaderOptions';
import HeaderUserInfoAdapter from '../userInfo';
import { MobileOptionsWrapper } from './controls';

const MobileHeaderOptionsAdapter = () => {
  const options = useHeaderOptions();

  options.push({
    type: EHeaderOptionType.User,
    component: <HeaderUserInfoAdapter />,
  });

  return useMemo(
    () => (
      <MobileOptionsWrapper>
        <HeaderOptions options={options} />
      </MobileOptionsWrapper>
    ),
    [options]
  );
};

export default MobileHeaderOptionsAdapter;
