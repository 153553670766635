import { AxiosResponse } from 'axios';
import { getPageableFromResponseHeaders } from 'data/openApi/utils';
import { Pageable } from 'domain/model';
import { EOfferType } from 'domain/model/enums';
import { OfferSearchSuggestion } from '../../domain/model/offer';
import apiDefinition from '../openApi';
import { api, ESearchServicesTag } from './index';

export type SearchOffersRequest = {
  readonly query?: Nullable<string>;
  readonly offerType?: Nullable<EOfferType[]>;
  readonly page: number;
  readonly pageSize: number;
  readonly sort?: Nullable<string[]>;
};

export const searchApi = api.injectEndpoints({
  endpoints: builder => ({
    searchOffers: builder.query<Pageable<OfferSearchSuggestion>, SearchOffersRequest>({
      transformResponse: (response: AxiosResponse): Pageable<OfferSearchSuggestion> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ page, pageSize, offerType, query, sort }) =>
        apiDefinition.user.search.offers({
          page: 1,
          pageSize: page * pageSize,
          offerTypes: offerType,
          query,
          sort,
        }),
      providesTags: [ESearchServicesTag.Offers],
    }),
  }),
});

export const { useSearchOffersQuery } = searchApi;
