import { useGetAspOfferCategoriesUsedTreeQuery } from 'data/api/aspOffer';
import { ECatalogTab } from 'presentation/features/catalog/types';
import { useSelector } from 'react-redux';
import { CatalogOffersContainer } from '../components/catalogContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { catalogTabSelector } from '../store/selectors';

type CatalogAspOffersTabAdapterProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogAspOffersTabAdapter = (props: CatalogAspOffersTabAdapterProps) => {
  const { selected } = props;

  const tab = useSelector(catalogTabSelector);
  const { onSelectAspOffersCategory } = useContextHandlers();

  const isActiveTab = tab === ECatalogTab.AspOffers;

  const response = useGetAspOfferCategoriesUsedTreeQuery({ guid: 'catalog-asp-offers' }, { skip: !isActiveTab });

  return isActiveTab ? (
    <CatalogOffersContainer
      {...response}
      onSelect={onSelectAspOffersCategory}
      selected={selected}
    />
  ) : null;
};
