import { Text } from '../../components/controls';

const AboutPublicOfferEntryInfo = () => {
  return (
    <>
      <Text>7.1. Оферта вступает в силу с даты ее публикации до момента ее отзыва РЖД.</Text>
      <Text>
        7.2. РЖД оставляет за собой право внести изменения в условия Оферты и (или) отозвать Оферту в любой момент по
        своему усмотрению. Изменения в Оферту вступают в силу с момента размещения измененного текста Оферты в сети
        Интернет по адресу https://mp.rzd.ru, если иной срок вступления изменений в силу не определен дополнительно при
        таком размещении.
      </Text>
    </>
  );
};

export default AboutPublicOfferEntryInfo;
