import { useGetProductOfferDetailsQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';

const useProductOfferDetailsData = (id: UUID) => {
  const {
    data: productOffer,
    error: productOfferFetchError,
    isError: isProductOfferError,
    isFetching: isProductOfferFetching,
    isSuccess: isProductOfferFetched,
  } = useGetProductOfferDetailsQuery({ id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (productOfferFetchError) {
      ErrorHandler.handleHttpError(productOfferFetchError);
    }
  }, [productOfferFetchError]);

  return {
    productOffer,
    productOfferFetchError,
    isProductOfferError,
    isProductOfferFetching,
    isProductOfferFetched,
  };
};

export default useProductOfferDetailsData;
