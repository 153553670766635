import { OfferListRequest } from '../../../../data/api';
import { paginationSizeVariant } from '../../../../domain/model/constants';
import { EBookingOfferSortType, EOfferType } from '../../../../domain/model/enums';
import { getOffersListQueryParams } from '../utils';
import { BookingOfferListFilter } from './list/store/types';
import { GetOffersCountQueryParamsProps } from '../types';

export const bookingOffersDefaultParams: OfferListRequest & BookingOfferListFilter = {
  ...getOffersListQueryParams(
    {
      name: null,
      categories: null,
      page: 1,
      pageSize: paginationSizeVariant[0],
      sort: [EBookingOfferSortType.Default],
    },
    EOfferType.Booking
  ),
  services: null,
  priceRange: {
    minPrice: null,
    maxPrice: null,
  },
};

export const getBookingOffersCountQueryParams = (props: GetOffersCountQueryParamsProps) => {
  const { name, categories } = props;

  return {
    search: {
      name,
      categories,
    },
    minPrice: null,
    maxPrice: null,
  };
};
