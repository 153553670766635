import AuthProtect from 'presentation/features/auth/protect';
import { FCC, useMemo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { EAppFeature } from '../../../types';
import { Feature } from '../feature';

type AppRouteProps = RouteProps & {
  readonly feature?: EAppFeature;
  readonly defaultRoute?: string;
  readonly protect?: boolean;
};

export const AppRoute: FCC<AppRouteProps> = ({ protect, feature, defaultRoute = '/', ...routeProps }) => {
  const route = useMemo(() => {
    const ToRender = routeProps.render || routeProps.component;
    return protect ? (
      <Route
        {...routeProps}
        component={undefined}
        render={props => <AuthProtect>{ToRender ? <ToRender {...props} /> : null}</AuthProtect>}
      />
    ) : (
      <Route {...routeProps} />
    );
  }, [protect, routeProps]);

  return feature ? (
    <Feature
      feature={feature}
      stubComponent={<Redirect to={defaultRoute} />}
    >
      {route}
    </Feature>
  ) : (
    route
  );
};
