import { LocationIcon } from '../../../media/icons';
import { MPLink } from '../../../theme/ui-kit/link';

import { Wrapper } from './controls';
import SvgIcon from '@mui/material/SvgIcon';

export type HeaderLocationProps = {
  readonly location: string;
  readonly icon?: typeof SvgIcon;
  readonly onClick?: () => void;
};

const UserLocation = (props: HeaderLocationProps) => {
  const { location, icon, onClick } = props;

  return (
    <Wrapper onClick={onClick}>
      <MPLink
        variant='body2'
        color='textSecondary'
        underline='none'
        startIcon={icon ?? LocationIcon}
      >
        {location}
      </MPLink>
    </Wrapper>
  );
};

export default UserLocation;
