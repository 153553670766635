import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { UserMenuItemLocality } from '../../components/menuItems';

type UserMenuLocalityAdapterProps = {
  readonly onLocationClick: () => void;
};

export const UserMenuLocalityAdapter = (props: UserMenuLocalityAdapterProps) => {
  const { onLocationClick } = props;

  const { settings } = useUserLocalSettings();

  const userLocation = settings.city?.name ?? null;

  return (
    <UserMenuItemLocality
      locality={userLocation}
      onClick={onLocationClick}
    />
  );
};
