import { CmsFeatureContext, ContentTarget } from 'domain/model/cms';
import { ECmsPreviewUrlParam } from 'presentation/features/cms/utils';
import { DataLocationState } from 'presentation/features/data/entry';
import { useLocation } from 'react-router';

export const useCmsTargetSearchParams = () => {
  const location = useLocation<DataLocationState>();
  const searchParams = new URLSearchParams(location.search);
  const targetParam = searchParams.get(ECmsPreviewUrlParam.Target) ?? '{}';
  const target: ContentTarget = JSON.parse(targetParam);

  return target;
};

export const useCmsContextSearchParams = () => {
  const location = useLocation<DataLocationState>();
  const searchParams = new URLSearchParams(location.search);
  const contextParam = searchParams.get(ECmsPreviewUrlParam.Context) ?? '{}';
  const context: CmsFeatureContext = JSON.parse(contextParam);

  return context;
};
