import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import axios, { AxiosRequestConfig } from 'axios';
import { EUserRole } from 'domain/model/enums';
import { NotificationOption, NotificationSetting } from 'domain/model/notification';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';

export type NotificationRequestProps = {
  readonly userId?: UUID;
  readonly roles?: EUserRole[];
};

type AllProps = ApiCancellable & NotificationRequestProps;

export type UpdateProps = {
  readonly id: UUID;
  readonly option: NotificationOption;
  readonly enabled: boolean;
};

type NotificationApi = {
  readonly all: (props: AllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, NotificationOption[]>>;
  readonly update: (props: UpdateProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, NotificationSetting>>;
};

/**
 * АПИ по работе с уведомлениями
 */
const notification: NotificationApi = {
  all: ({ userId, roles, signal }) => {
    const params = new URLSearchParams();

    if (userId) params.append('userId', userId);
    if (roles) roles.forEach(role => params.append('role', role));

    return {
      url: '/notifications/options',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  update: ({ id, option, enabled }) => {
    return {
      url: `/notifications/users/${id}/settings`,
      method: 'PATCH',
      data: {
        optionId: option.id,
        enabled,
      },
    };
  },
};

export default notification;
