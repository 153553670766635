import { AxiosResponse } from 'axios';
import { Cart, CartItem } from 'domain/model/cart';
import { ProductOfferShort } from 'domain/model/productOffer';
import apiDefinition from '../openApi';
import { api, ECartServicesTag } from './index';

type GetCartItemRequest = {
  readonly id: UUID;
};

type DeleteCartItemRequest = {
  readonly id: UUID;
};

export enum EUpdateBehaviour {
  Optimistic = 'optimistic',
  Pessimistic = 'pessimistic',
}

type UpdateCartItemRequest = {
  readonly cartItemId: UUID;
  readonly productId: UUID;
  readonly quantity: number;
  readonly behaviour?: EUpdateBehaviour;
};

type AddItemToCartRequest = {
  readonly product: ProductOfferShort;
  readonly quantity: number;
};

export enum cartApiEndpointNames {
  getCart = 'getCart',
  getCartItem = 'getCartItem',
  deleteCartItem = 'deleteCartItem',
  updateCartItem = 'updateCartItem',
  addItemToCart = 'addItemToCart',
}

export const cartApiEndpointsList = Object.values(cartApiEndpointNames);

export const cartApi = api.injectEndpoints({
  endpoints: builder => ({
    [cartApiEndpointNames.getCart]: builder.query<Cart, void>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: () => {
        return apiDefinition.user.cart.getCart({});
      },
      providesTags: [ECartServicesTag.Cart],
    }),
    [cartApiEndpointNames.getCartItem]: builder.query<CartItem, GetCartItemRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => {
        return apiDefinition.user.cart.getCartItem({ id });
      },
    }),
    [cartApiEndpointNames.deleteCartItem]: builder.mutation<number, DeleteCartItemRequest>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id }) => {
        return apiDefinition.user.cart.deleteCartItem({ id });
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const cartPatch = dispatch(
          cartApi.util.updateQueryData(cartApiEndpointNames.getCart, undefined, draft => {
            const withoutItemList = draft?.items?.filter(i => i.id !== id);
            if (withoutItemList) {
              Object.assign(draft, {
                ...draft,
                items: [...withoutItemList],
              });
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          cartPatch.undo();
        }
      },
    }),
    [cartApiEndpointNames.updateCartItem]: builder.mutation<CartItem, UpdateCartItemRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ productId, cartItemId, quantity }) => {
        return apiDefinition.user.cart.updateCartItem({ cartItemId, productId, quantity });
      },
      async onQueryStarted(
        { productId, quantity, cartItemId, behaviour = EUpdateBehaviour.Optimistic },
        { dispatch, queryFulfilled }
      ) {
        if (behaviour === EUpdateBehaviour.Optimistic) {
          const cartPatch = dispatch(
            cartApi.util.updateQueryData(cartApiEndpointNames.getCart, undefined, draft => {
              const updatingCartItem = draft?.items?.find(i => i.id === cartItemId);
              if (updatingCartItem) {
                Object.assign(updatingCartItem, {
                  ...updatingCartItem,
                  quantity,
                  offer: { ...updatingCartItem.offer, id: productId },
                });
              }
            })
          );

          try {
            await queryFulfilled;
          } catch {
            cartPatch.undo();
          }
        } else {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              cartApi.util.updateQueryData(cartApiEndpointNames.getCart, undefined, draft => {
                const updatingCartItem = draft?.items?.find(i => i.id === cartItemId);
                if (updatingCartItem) {
                  Object.assign(updatingCartItem, data);
                }
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
      },
    }),
    [cartApiEndpointNames.addItemToCart]: builder.mutation<CartItem, AddItemToCartRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ product, quantity }) => {
        return apiDefinition.user.cart.addToCart({ productId: product.id, quantity });
      },
      async onQueryStarted({ product, quantity }, { dispatch, queryFulfilled }) {
        const cartPatch = dispatch(
          cartApi.util.updateQueryData(cartApiEndpointNames.getCart, undefined, draft => {
            // данный айтем-заглушка перезапишется полученным с сервера
            const newCartItem: CartItem = {
              id: '',
              quantity,
              offer: product,
            };

            if (draft?.items) {
              Object.assign(draft, { ...draft, items: [...draft.items, newCartItem] });
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          cartPatch.undo();
        }
      },
    }),
  }),
});

export const {
  useGetCartQuery,
  useDeleteCartItemMutation,
  useGetCartItemQuery,
  useUpdateCartItemMutation,
  useAddItemToCartMutation,
} = cartApi;
