import useCorpOfferDetailsData from '../../hooks/useCorpOfferDetailsData';
import CorpOfferBadge from './badge';
import { useMemo, useState } from 'react';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { SwiperProps } from 'swiper/react';
import { Theme, useMediaQuery } from '@mui/material';
import { CarouselWrapper } from './controls';
import { offerImageAspectRatio } from '../../../../utils';

type CorpOfferCarouselAdapterProps = {
  readonly id: UUID;
};

const CorpOfferCarouselAdapter = (props: CorpOfferCarouselAdapterProps) => {
  const { id } = props;

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };

  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };

  const { corpOffer } = useCorpOfferDetailsData(id);

  const images = corpOffer?.images ?? [];

  const thumbChildren = images.map((image, i) => (
    <AppImage
      key={i}
      onClick={() => {}}
      src={image.path}
    />
  ));

  const thumbSwiperProps: SwiperProps = useMemo(
    () => ({
      slidesPerView: 4,
      spaceBetween: 10,
      loop: true,
    }),
    []
  );

  const mainSwiperProps: SwiperProps = useMemo(
    () => ({
      pagination: !isSmUp,
      navigation: false,
      spaceBetween: 10,
      onClick: onMediaClick,
      loop: true,
    }),
    [isSmUp]
  );

  if (!corpOffer?.images?.length) {
    return null;
  }

  return (
    <CarouselWrapper>
      <CorpOfferBadge>
        <CarouselGallery
          swipersGap={10}
          thumbSwiperContainerHeight={102}
          thumbChildren={thumbChildren}
          showThumbs={true}
          onActiveIndexChange={index => setActiveSlide(index)}
          thumbSwiperProps={thumbSwiperProps}
          mainSwiperProps={mainSwiperProps}
          aspectRatio={offerImageAspectRatio}
        >
          {images.map((image, i) => (
            <AppImage
              key={i}
              onClick={() => {}}
              src={image.path}
            />
          ))}
        </CarouselGallery>
      </CorpOfferBadge>
      <MediaPreviewDialog
        imageLinks={images}
        initSlide={activeSlide}
        open={previewDialogOpen}
        onClose={onCloseDialog}
      />
    </CarouselWrapper>
  );
};

export default CorpOfferCarouselAdapter;
