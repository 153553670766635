import { Fade } from '@mui/material';
import { AppOption } from 'domain/model';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OrderListItem from '../../../order/components/listItem';
import { LoadMoreWrapper, OrderList } from './controls';
import { OrderShort } from '../../../../../domain/model/order';

type UserOrdersProps = {
  readonly orders: OrderShort[];
  readonly generalOrderStatuses: AppOption[];
  readonly bookingOrderStatuses: AppOption[];
  readonly isFetching: boolean;
  readonly onLoadNextPage: Nullable<() => void>;
  readonly onOrderClick: (id: UUID) => void;
};

const UserOrders = (props: UserOrdersProps) => {
  const { orders, generalOrderStatuses, isFetching, onLoadNextPage, onOrderClick, bookingOrderStatuses } = props;

  return (
    <Fade in>
      <div>
        {orders && orders.length > 0 && (
          <OrderList
            container
            spacing={2}
            direction='column'
          >
            {orders.map(order => (
              <MPGrid
                key={order.id}
                item
              >
                <OrderListItem
                  order={order}
                  generalOrderStatuses={generalOrderStatuses}
                  bookingOrderStatuses={bookingOrderStatuses}
                  onClick={() => onOrderClick(order.id ?? '')}
                />
              </MPGrid>
            ))}
          </OrderList>
        )}

        {onLoadNextPage && (
          <LoadMoreWrapper>
            <MPButton
              color='white'
              size='small'
              fullWidth
              disabled={isFetching}
              onClick={onLoadNextPage}
            >
              Показать ещё
              {isFetching && <ContentLoader size={20} />}
            </MPButton>
          </LoadMoreWrapper>
        )}
      </div>
    </Fade>
  );
};

export default UserOrders;
