import { Typography } from '@mui/material';
import DottedButton from 'presentation/components/common/buttons/dotted';
import ContentLoader from 'presentation/components/common/loader';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ButtonWrapper } from './controls';

type OfferActivationButtonProps = {
  readonly isFetching: boolean;
  readonly onActivate: () => void;
};

export const OfferActivationButton = (props: OfferActivationButtonProps) => {
  const { isFetching, onActivate } = props;

  return (
    <MPGrid
      container
      alignItems='center'
      spacing={1}
    >
      <MPGrid
        item
        zero={12}
      >
        <ButtonWrapper>
          <DottedButton
            fullWidth
            disabled={isFetching}
            onClick={onActivate}
          >
            <Typography variant='h2'>Получить</Typography>
            {isFetching && <ContentLoader />}
          </DottedButton>
        </ButtonWrapper>
      </MPGrid>
    </MPGrid>
  );
};
