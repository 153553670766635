import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { SlotsGrid } from '../claim/controls';

export const SmartPhoneFixedBarBottomWrapper = styled.footer(
  ({ theme: { spacing, palette, zIndex } }) => css`
    padding: ${spacing(1, 2)};

    position: fixed;
    z-index: ${zIndex.drawer};
    left: 0;
    right: 0;
    bottom: 64px;
    display: flex;

    box-shadow: 0px -8px 20px 0px #0000000d;
    background: ${palette.background.paper};
  `
);

export const SmartPhoneFixedBarWrapper = styled.div(
  ({ theme: { spacing, palette, zIndex } }) => css`
    width: 100vw;
    height: 100vh;

    padding: ${spacing(1, 2)};

    position: fixed;
    z-index: ${zIndex.drawer};
    left: 0;
    top: 0;
    display: flex;
    overflow: scroll;

    background: ${palette.background.paper};
  `
);

export const SmartphoneSlotsGrid = styled(SlotsGrid)(
  ({ theme: { spacing } }) => css`
    padding: ${spacing(6, 1, 2)};
    border: 0;
    box-shadow: none;
  `
);

export const ButtonNoWrap = styled(MPButton)`
  white-space: nowrap;
`;
