import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { ReactNode } from 'react';

export type BackgroundWrapperProps = {
  readonly variant: 'inner' | 'main';
  readonly children: ReactNode;
};

export const Inner = styled.div(
  p => css`
    padding: 0 ${p.theme.spacing(1.5)} ${p.theme.spacing(1.5)} ${p.theme.spacing(1.5)};

    ${p.theme.breakpoints.up('sm')} {
      padding: 0 ${p.theme.spacing(2)} ${p.theme.spacing(2)} ${p.theme.spacing(2)};
    }

    ${p.theme.breakpoints.up('md')} {
      padding: ${p.theme.spacing(2)};
    }

    ${p.theme.breakpoints.up('lg')} {
      padding: ${p.theme.spacing(3)};
    }

    ${p.theme.breakpoints.up('xl')} {
      padding: ${p.theme.spacing(4)};
    }
  `
);

export const BackgroundWrapper = styled(({ variant, ...props }: BackgroundWrapperProps) => <div {...props} />)(
  p => css`
    position: relative;
    width: 100%;

    background-color: ${p.theme.palette.white.main};

    ${p.theme.breakpoints.up('md')} {
      border-radius: ${p.theme.spacing(3)};
    }

    ${Inner} {
      ${p.theme.breakpoints.down('md')} {
        padding-top: ${p.variant === 'main' ? p.theme.spacing(5) : 0};
        padding-bottom: ${p.variant === 'main' ? p.theme.spacing(5) : 0};
      }
    }
  `
);

export const Close = styled.div(
  p => css`
    display: none;
    position: absolute;

    top: ${p.theme.spacing(0.5)};
    right: ${p.theme.spacing(0.5)};

    ${p.theme.breakpoints.up('md')} {
      display: block;
    }
  `
);
