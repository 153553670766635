import { useMediaQuery } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import { CmsTradeOfferCollection } from '../tradeOffers';
import { CmsOfferCollectionAdapterProps } from '../types';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback } from 'react';
import { TradeOfferShort } from '../../../../../domain/model/tradeOffer';
import { CorpOfferShort } from '../../../../../domain/model/corpOffer';

/**
 * адаптер для коллекции ТП, открываемой в составе конструктора
 */
export const CmsTradeOfferCollectionSelfAdapter = (props: CmsOfferCollectionAdapterProps) => {
  const { webAnalytics } = useWebAnalytics();

  const isBetweenMdLg = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));

  const pageSize = isBetweenMdLg ? 3 : 4;
  const visibleCount = pageSize;
  const statuses: Nullable<EOfferStatus[]> = null;

  const onShowCard = useCallback(
    (offer: TradeOfferShort | CorpOfferShort) => webAnalytics.offerShow(offer.id),
    [webAnalytics]
  );

  return (
    <CmsTradeOfferCollection
      {...props}
      statuses={statuses}
      visibleCount={visibleCount}
      onLoadMore={null}
      onShowCard={onShowCard}
    />
  );
};
