import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPartnerDesksLoadingSelector, getPartnersNameMapLoadingSelector } from '../../filter/store/selectors';
import usePartnerDesksPageableArgsInRedux from '../hooks/usePartnerDesksPageableArgsInRedux';

type LoaderProps = {
  readonly guid: UUID;
};

const PartnerDeskListLoaderAdapter = ({ guid }: LoaderProps) => {
  const argsStorage = usePartnerDesksPageableArgsInRedux(guid);

  const isPartnerDesksLoading = useSelector(getPartnerDesksLoadingSelector(argsStorage.currentState));
  const isPartnersNameMapLoading = useSelector(getPartnersNameMapLoadingSelector);

  return useMemo(
    () => (isPartnerDesksLoading || isPartnersNameMapLoading ? <ContentLoader position='fixed' /> : null),
    [isPartnerDesksLoading, isPartnersNameMapLoading]
  );
};

export default PartnerDeskListLoaderAdapter;
