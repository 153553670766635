import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsBookingOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskDetailsTabBookingOffersNoData from './noData';
import { PartnerDeskBookingOffersAdapter, PartnerDeskBookingOffersCategoriesAdapter } from './adapters';

type PartnerDeskDetailsTabBookingOffersProps = {
  readonly guid: UUID;
};

export const PartnerDeskDetailsTabBookingOffers = (props: PartnerDeskDetailsTabBookingOffersProps) => {
  const { guid } = props;

  return (
    <PartnerDeskOffersTabLayout
      toolbar={<PartnerDeskBookingOffersCategoriesAdapter />}
      stub={<PartnerDeskDetailsTabBookingOffersNoData />}
      loader={<GeneralLoaderFixedAdapter selector={partnerDeskDetailsBookingOffersIsFetchingSelector} />}
    >
      <PartnerDeskBookingOffersAdapter guid={guid} />
    </PartnerDeskOffersTabLayout>
  );
};
