import { Fade, useMediaQuery, useTheme } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { EContentbarGridAreas, EInnerGridAreas } from 'presentation/layouts/bookingDetail/types';
import { FC, ReactNode } from 'react';
import BookingDetailLayout from '../../../../../layouts/bookingDetail';
import useBookingOfferDetailsLoadingStatus from '../hooks/useBookingOfferDetailsLoadingStatus';
import { FitContainer } from './controls';
import useVisibleSectionLinks from '../../../../sectionLinks/hooks/useVisibleSectionLinks';

const layoutFixedAreas = [
  EContentbarGridAreas.LargeDesktopTabs,
  EContentbarGridAreas.Description,
  EInnerGridAreas.Bottombar,
];

type BookingBookingDetailsLayoutProps = {
  readonly id: UUID;
  readonly tabs: ReactNode;
  readonly breadcrumb: ReactNode;
  readonly smartphoneFixedBar?: ReactNode;
  readonly description: ReactNode;
  readonly toolbar?: ReactNode;
  readonly close?: ReactNode;
  readonly title: ReactNode;
  readonly favorite: ReactNode;
  readonly image: ReactNode;
  readonly sidebar: ReactNode;
  readonly partnerInfo: ReactNode;
};

const BookingOfferDetailsLayout: FC<BookingBookingDetailsLayoutProps> = ({
  id,
  image,
  toolbar,
  breadcrumb,
  sidebar,
  tabs,
  partnerInfo,
  title,
  description,
  close,
  smartphoneFixedBar,
  favorite,
}) => {
  const isBookingOfferLoading = useBookingOfferDetailsLoadingStatus({ id });

  const theme = useTheme();

  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  useVisibleSectionLinks(downMd);

  return (
    <>
      <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>
      <Fade in>
        <DefaultContentWrapper>
          <FitContainer>
            {isBookingOfferLoading ? null : (
              <BookingDetailLayout
                breadcrumb={breadcrumb}
                description={description}
                title={title}
                sidebar={sidebar}
                partnerInfo={partnerInfo}
                images={image}
                favorite={favorite}
                smartphoneFixedBar={smartphoneFixedBar}
                content={tabs}
                close={close}
                fixedPositionsAreas={layoutFixedAreas}
              />
            )}
          </FitContainer>
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default BookingOfferDetailsLayout;
