import { FCC } from 'react';
import { SkirtWrapper } from './controls';

type ClaimInfoProps = {
  readonly isFirst?: boolean;
};

const ClaimInfo: FCC<ClaimInfoProps> = ({ children, ...other }) => {
  return <SkirtWrapper {...other}>{children}</SkirtWrapper>;
};

export default ClaimInfo;
