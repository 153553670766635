import { Theme, useMediaQuery } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { SwiperProps } from 'swiper/react';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { offerImageAspectRatio } from '../../../../utils';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { BookingDetailsId } from '../../types';
import BookingOfferBadge from './badge';
import { ImageWrapper } from './controls';

const BookingOfferCarouselAdapter: FC<BookingDetailsId> = ({ id }) => {
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewInitSlide, setPreviewInitSlide] = useState(0);
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };

  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };

  const thumbSwiperProps: SwiperProps = useMemo(
    () => ({
      slidesPerView: 4,
      spaceBetween: 10,
      loop: true,
    }),
    []
  );

  const mainSwiperProps: SwiperProps = useMemo(
    () => ({
      pagination: !isMdUp,
      navigation: false,
      spaceBetween: 10,
      onClick: onMediaClick,
      loop: true,
    }),
    [isMdUp]
  );

  if (!bookingOffer?.images) {
    return null;
  }

  const { images } = bookingOffer;

  const thumbChildren = images.map((image, i) => (
    <AppImage
      key={i}
      onClick={() => {}}
      src={image.path}
    />
  ));

  return (
    <ImageWrapper>
      <BookingOfferBadge>
        <CarouselGallery
          swipersGap={10}
          initSlide={0}
          thumbSwiperContainerHeight={102}
          thumbChildren={thumbChildren}
          showThumbs={isMdUp}
          onActiveIndexChange={index => {
            setPreviewInitSlide(index);
          }}
          thumbSwiperProps={thumbSwiperProps}
          mainSwiperProps={mainSwiperProps}
          aspectRatio={offerImageAspectRatio}
        >
          {images.map((image, i) => (
            <AppImage
              key={i}
              onClick={() => {}}
              src={image.path}
            />
          ))}
        </CarouselGallery>
        {previewDialogOpen && (
          <MediaPreviewDialog
            imageLinks={images}
            initSlide={previewInitSlide}
            open={previewDialogOpen}
            onClose={onCloseDialog}
          />
        )}
      </BookingOfferBadge>
    </ImageWrapper>
  );
};

export default BookingOfferCarouselAdapter;
