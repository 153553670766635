import { Fade } from '@mui/material';
import React from 'react';
import { Grid } from './controls';
import { CardGridProps } from './constants';

const CardList: React.FCC<CardGridProps> = ({ span = 3, lgSpan, mdSpan, smSpan = 6, children }) => (
  <Fade in>
    <Grid
      span={span}
      lgSpan={lgSpan}
      mdSpan={mdSpan}
      smSpan={smSpan}
    >
      {children}
    </Grid>
  </Fade>
);

export default CardList;
export type { CardGridProps } from './constants';
