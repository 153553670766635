import { FC } from 'react';
import {
  CatalogAspOffersTabAdapter,
  CatalogBehaviorAdapter,
  CatalogBookingOffersTabAdapter,
  CatalogCorpOffersTabAdapter,
  CatalogProductOffersTabAdapter,
  CatalogTabsAdapter,
  CatalogTradeOffersTabAdapter,
} from './adapters';
import { CatalogContext } from './context';
import useCatalogHandlers from './hooks/useCatalog';
import { CatalogLayout } from './layout';

type CatalogContainerProps = {
  readonly open: boolean;
  readonly selected: Nullable<UUID>;
  readonly matchedOfferSectionPath: Nullable<string>;
  readonly onClose: () => void;
};

const Layout = CatalogLayout;

export const CatalogContainer: FC<CatalogContainerProps> = props => {
  const { open, selected, onClose, matchedOfferSectionPath } = props;

  const handlers = useCatalogHandlers({
    onClose,
    matchedOfferSectionPath,
  });

  const catalogProps = { selected };

  return (
    <CatalogContext.Provider value={handlers}>
      <CatalogBehaviorAdapter />
      <Layout
        open={open}
        tabs={<CatalogTabsAdapter />}
        onClose={onClose}
      >
        <CatalogTradeOffersTabAdapter {...catalogProps} />
        <CatalogCorpOffersTabAdapter {...catalogProps} />
        <CatalogProductOffersTabAdapter {...catalogProps} />
        <CatalogBookingOffersTabAdapter {...catalogProps} />
        <CatalogAspOffersTabAdapter {...catalogProps} />
      </Layout>
    </CatalogContext.Provider>
  );
};
