import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCorpOffersContextHandlers } from '../../../hooks/useCorpOffersContextHandlers';
import {
  partnerDeskDetailsCorpOffersCommonArgsSelector,
  partnerDeskDetailsGuidSelector,
} from '../../../store/selectors';
import PartnerDeskCorpOffersDataContainer from '../dataContainer';

type PartnerDeskCorpOffersCommonAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Common;

const PartnerDeskCorpOffersCommonAdapter = ({ guid }: PartnerDeskCorpOffersCommonAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsCorpOffersCommonArgsSelector);

  const handlers = useCorpOffersContextHandlers();

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskCorpOffersDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangeCorpOffersPage}
          onShowCard={handlers.onShowCorpOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeCorpOffersPage, handlers.onShowCorpOffersCard]
  );
};

export default PartnerDeskCorpOffersCommonAdapter;
