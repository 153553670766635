import { useChangeUserLocationMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import {
  currentUserIdSelector,
  currentUserLocalityIdSelector,
} from 'presentation/features/user/current/store/selectors';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserLocalSettings, UserSettings } from 'system/userLocalSettings';
import useErrorTracking from '../../../hooks/useErrorTracking';

export type UserSettingsContextType = {
  readonly settings: UserSettings;
  readonly setCity: (fiasId: UUID, name: string) => void;
  readonly setUserCookieAccepted: (value: boolean) => void;
  readonly isCurrentUserAcceptedCookies: boolean;
};

type UserSettingsProviderType = (props: PropsWithChildren) => JSX.Element;

export const UserSettingsContext = React.createContext<UserSettingsContextType>({} as UserSettingsContextType);

const anonymousUserId = 'anonymous';

const UserSettingsProvider: UserSettingsProviderType = ({ children }) => {
  const currentUserId = useSelector(currentUserIdSelector);
  const currentUserLocalityId = useSelector(currentUserLocalityIdSelector);

  const userId = currentUserId || anonymousUserId;
  const isAnonymous = userId === anonymousUserId;

  const userLocalSettings = useMemo<UserLocalSettings>(() => UserLocalSettings.getInstance(userId), [userId]);
  const settings = userLocalSettings.getSettings();

  const [, setSymbol] = useState(Symbol());

  const rerender = () => {
    setSymbol(Symbol());
  };

  const setUserSettings: typeof userLocalSettings.setSettings = useCallback(
    settings => {
      userLocalSettings.setSettings(settings);
      rerender();
    },
    [userLocalSettings]
  );

  const { captureException } = useErrorTracking();

  const [changeUserLocation, { error: changeUserLocationError }] = useChangeUserLocationMutation();

  const isCurrentUserAcceptedCookies = isAnonymous || settings?.cookieAccepted === true;

  const setCity = useCallback(
    (fiasId: UUID, name: string) => {
      if (currentUserLocalityId !== fiasId && !isAnonymous) {
        changeUserLocation({ userId, location: { id: fiasId, name } })
          .unwrap()
          .then(() => {
            const newSettings = {
              ...settings,
              city: {
                fiasId,
                name,
              },
            };
            setUserSettings(newSettings);
          })
          .catch(e => {
            console.error(e);
            captureException(e);
          });
      } else {
        const newSettings = {
          ...settings,
          city: {
            fiasId,
            name,
          },
        };
        setUserSettings(newSettings);
      }
    },
    [isAnonymous, settings, setUserSettings, changeUserLocation, userId, currentUserLocalityId, captureException]
  );

  const setUserCookieAccepted = useCallback(
    (value: boolean) => {
      const newSettings: UserSettings = {
        ...settings,
        cookieAccepted: value,
      };
      setUserSettings(newSettings);
    },
    [setUserSettings, settings]
  );

  const value: UserSettingsContextType = useMemo(
    () => ({
      settings,
      setCity,
      setUserCookieAccepted,
      isCurrentUserAcceptedCookies,
    }),
    [settings, setCity, setUserCookieAccepted, isCurrentUserAcceptedCookies]
  );

  useEffect(() => {
    if (changeUserLocationError) {
      ErrorHandler.handleHttpError(changeUserLocationError);
    }
  }, [changeUserLocationError]);
  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};

export default UserSettingsProvider;
