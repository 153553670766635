import { PartnerOfferListRequest } from 'data/api/partner';
import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferStatus, OfferSortTypes } from 'domain/model/enums';
import { FCC } from 'react';
import { EAppCategories } from '../../../types';
import PartnerDeskDetailsTabAboutCompanyToolbar from './tabs/aboutCompany/toolbar';
import PartnerDeskDetailsTabAspOffersToolbar from './tabs/aspOffers/toolbar';
import PartnerDeskDetailsTabBookingOffersToolbar from './tabs/bookingOffers/toolbar';
import PartnerDeskDetailsTabContactsToolbar from './tabs/contacts/toolbar';
import PartnerDeskDetailsTabCorpOffersToolbar from './tabs/corpOffers/toolbar';
import PartnerDeskDetailsTabProductOffersToolbar from './tabs/productOffers/toolbar';
import PartnerDeskDetailsTabTradeOffersToolbar from './tabs/tradeOffers/toolbar';

export enum EPartnerDeskDetailsTab {
  PartnerWindow = 'partner-window',
  TradeOffers = 'trade-offers',
  AspOffers = 'asp-offers',
  ProductOffers = 'product-offers',
  CorpOffers = 'corp-offers',
  BookingOffers = 'booking-offers',
  AboutCompany = 'about-company',
  Contacts = 'contacts',
}

export type PartnerDeskDetailsTabInfo = {
  readonly label: string;
  readonly count?: number;
  readonly value: EPartnerDeskDetailsTab;
  readonly toolbar?: FCC;
};

type GetPartnerDeskDetailsTabListProps = {
  readonly isFetching: boolean;
  readonly tradeOffersCount: number;
  readonly aspOffersCount: number;
  readonly corpOffersCount: number;
  readonly productOffersCount: number;
  readonly bookingOffersCount: number;
  readonly partnerWindowContainersCount: number;
  readonly isCorpUser: boolean;
};

type GetPartnerOffersListQueryParamsProps<S extends OfferSortTypes> = {
  readonly partnerId: UUID;
  readonly categoryId?: Nullable<UUID>;
  readonly statuses?: EOfferStatus[];
  readonly pageSize: number;
  readonly page: number;
  readonly sort: Nullable<S[]>;
};

// todo добавить автотесты
export const getPartnerOffersListQueryParams = <S extends OfferSortTypes>(
  props: GetPartnerOffersListQueryParamsProps<S>
): PartnerOfferListRequest<S> => {
  const { partnerId, categoryId, pageSize, page, sort, statuses } = props;

  return {
    partnerId,
    categoryId,
    sort,
    statuses,
    page: 1,
    pageSize: page * pageSize,
  };
};

export const partnerDeskOffersListEmptyParams = getPartnerOffersListQueryParams<any>({
  partnerId: '',
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null,
});

export const getPartnerDeskDetailsFirstActiveTab = (
  tab: Nullable<EPartnerDeskDetailsTab>,
  tabList: PartnerDeskDetailsTabInfo[]
) => {
  return tabList.find(item => item.value === tab) ?? tabList?.[0] ?? null;
};

export const getPartnerDeskDetailsTabName = (tab: EPartnerDeskDetailsTab): string => {
  switch (tab) {
    case EPartnerDeskDetailsTab.PartnerWindow:
      return 'Главная';
    case EPartnerDeskDetailsTab.ProductOffers:
      return EAppCategories.ProductOffers;
    case EPartnerDeskDetailsTab.TradeOffers:
      return EAppCategories.TradeOffers;
    case EPartnerDeskDetailsTab.AspOffers:
      return EAppCategories.AspOffers;
    case EPartnerDeskDetailsTab.CorpOffers:
      return EAppCategories.CorpOffers;
    case EPartnerDeskDetailsTab.BookingOffers:
      return EAppCategories.BookingOffers;
    case EPartnerDeskDetailsTab.AboutCompany:
      return 'О компании';
    case EPartnerDeskDetailsTab.Contacts:
      return 'Контакты';
  }
};

export const getPartnerDeskDetailsTabList = (props: GetPartnerDeskDetailsTabListProps): PartnerDeskDetailsTabInfo[] => {
  const {
    isFetching,
    corpOffersCount,
    productOffersCount,
    tradeOffersCount,
    aspOffersCount,
    bookingOffersCount,
    partnerWindowContainersCount,
    isCorpUser,
  } = props;

  if (isFetching) {
    return [];
  }

  const tabs: PartnerDeskDetailsTabInfo[] = [];

  if (partnerWindowContainersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.PartnerWindow),
      value: EPartnerDeskDetailsTab.PartnerWindow,
    });
  }

  if (isCorpUser && corpOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.CorpOffers),
      count: corpOffersCount,
      value: EPartnerDeskDetailsTab.CorpOffers,
      toolbar: PartnerDeskDetailsTabCorpOffersToolbar,
    });
  }

  if (tradeOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.TradeOffers),
      count: tradeOffersCount,
      value: EPartnerDeskDetailsTab.TradeOffers,
      toolbar: PartnerDeskDetailsTabTradeOffersToolbar,
    });
  }

  if (isCorpUser && aspOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AspOffers),
      count: aspOffersCount,
      value: EPartnerDeskDetailsTab.AspOffers,
      toolbar: PartnerDeskDetailsTabAspOffersToolbar,
    });
  }

  if (productOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.ProductOffers),
      count: productOffersCount,
      value: EPartnerDeskDetailsTab.ProductOffers,
      toolbar: PartnerDeskDetailsTabProductOffersToolbar,
    });
  }

  if (bookingOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.BookingOffers),
      count: bookingOffersCount,
      value: EPartnerDeskDetailsTab.BookingOffers,
      toolbar: PartnerDeskDetailsTabBookingOffersToolbar,
    });
  }

  tabs.push(
    {
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AboutCompany),
      value: EPartnerDeskDetailsTab.AboutCompany,
      toolbar: PartnerDeskDetailsTabAboutCompanyToolbar,
    },
    {
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.Contacts),
      value: EPartnerDeskDetailsTab.Contacts,
      toolbar: PartnerDeskDetailsTabContactsToolbar,
    }
  );

  return tabs;
};
