import { CorpOffer } from '../../../../../domain/model/corpOffer';
import CorpOfferClaim from './component';
import CorpOfferClaimProviders from './providers';
import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { useHistory } from 'react-router';

type CorpOfferClaimContainerProps = {
  readonly corpOffer: CorpOffer;
};

const CorpOfferClaimContainer = (props: CorpOfferClaimContainerProps) => {
  const { corpOffer } = props;
  const history = useHistory();

  const onShowAllOffers = () => {
    if (corpOffer) {
      history.push(
        getPartnerDeskDetailsRoute({
          id: corpOffer.partner.id,
          tab: EPartnerDeskDetailsTab.CorpOffers,
        })
      );
    }
  };

  return (
    <CorpOfferClaimProviders
      corpOffer={corpOffer}
      onShowAllOffers={onShowAllOffers}
    >
      <CorpOfferClaim />
    </CorpOfferClaimProviders>
  );
};

export default CorpOfferClaimContainer;
