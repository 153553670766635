import { useContext } from 'react';
import SentryContext, { SentryContextType } from '../components/sentry/context';

type UseErrorTracking = () => SentryContextType;

const useErrorTracking: UseErrorTracking = () => {
  return useContext<SentryContextType>(SentryContext);
};

export default useErrorTracking;
