import { combineReducers } from '@reduxjs/toolkit';
import filter, { ProductFilterState } from './filter/store/slice';
import list, { ProductOfferListState } from './list/store/slice';

type ProductOfferState = {
  list: ProductOfferListState;
  filter: ProductFilterState;
};

export default combineReducers<ProductOfferState>({
  list,
  filter,
});
