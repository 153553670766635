import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPConfirmDialog } from '../../../../../../../theme/ui-kit/dialog';
import { ListCounter, NumberedList } from './controls';

type CertificateDialogProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const CertificateDialog = ({ open, onClose }: CertificateDialogProps) => {
  const { x2 } = useGridSpacing();

  return (
    <MPConfirmDialog
      open={open}
      maxWidth='sm'
      title='Как использовать сертификат'
      onClose={onClose}
      buttons={
        <MPButton
          size='large'
          fullWidth
          color='brand'
          variant='contained'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
    >
      <NumberedList
        item
        container
        direction='column'
        spacing={x2}
      >
        <MPGrid item>
          <ListCounter color='textSecondary'>1.</ListCounter>
          На&nbsp;сертификате указана вся необходимая информация по&nbsp;приобретённому предложению;
        </MPGrid>
        <MPGrid item>
          <ListCounter color='textSecondary'>2.</ListCounter>
          Скачайте и&nbsp;сохраните сертификат в&nbsp;электронном виде или распечатайте его;
        </MPGrid>
        <MPGrid item>
          <ListCounter color='textSecondary'>3.</ListCounter>
          В&nbsp;день, когда у&nbsp;вас запланировано событие, возьмите распечатанный сертификат или его электронную
          копию с&nbsp;собой и&nbsp;предъявите его на&nbsp;месте.
        </MPGrid>
      </NumberedList>
    </MPConfirmDialog>
  );
};

export default CertificateDialog;
