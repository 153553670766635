import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { recomputeBoundaries, registerFooter, unregisterFooter } from '../store/slice';

export const useFooterRegister = (name: string) => {
  const ref = useRef<HTMLElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ref.current) return;
    const offsetHeight = ref.current.offsetHeight;
    const resizeObserver = new ResizeObserver(entries => {
      if (ref.current) {
        const borderBoxSize = entries[0].borderBoxSize[0].blockSize;
        if (offsetHeight !== borderBoxSize) {
          dispatch(recomputeBoundaries({ name, footer: { height: borderBoxSize } }));
        }
      }
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect(); // clean up
    };
  }, [dispatch, name]);

  useLayoutEffect(() => {
    if (ref.current) {
      dispatch(registerFooter({ name, footer: { height: ref.current.offsetHeight } }));
    }

    return () => {
      dispatch(unregisterFooter(name));
    };
  }, [dispatch, name]);

  return {
    footerRef: ref,
  };
};
