import { Typography } from '@mui/material';
import LightButton from 'presentation/components/common/buttons/light';
import Splitter from 'presentation/components/common/splitter';
import { ShortCharacteristics, Wrapper } from './controls';

type ProductOfferShortCharacteristicsProps = {
  readonly text: string;
  readonly onShowFullCharacteristics: () => void;
};

const ProductOfferShortCharacteristics = ({
  text,
  onShowFullCharacteristics,
}: ProductOfferShortCharacteristicsProps) => {
  const isCharacteristicsLonger4Rows = text.trim().split('\n\n').length > 4;

  return (
    <Wrapper>
      <Typography
        variant='body1'
        fontWeight='bold'
      >
        Коротко о товаре:
      </Typography>
      <ShortCharacteristics>{text}</ShortCharacteristics>
      {isCharacteristicsLonger4Rows && (
        <>
          <Splitter size={1} />
          <LightButton
            size='small'
            onClick={onShowFullCharacteristics}
          >
            <Typography variant='body2'>Посмотреть все характеристики</Typography>
          </LightButton>
        </>
      )}
    </Wrapper>
  );
};

export default ProductOfferShortCharacteristics;
