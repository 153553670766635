import styled from '@emotion/styled/macro';
import { css, Typography } from '@mui/material';

export const Caption = styled(Typography)(
  ({ theme }) => css`
    // important because some wrappers can make item height for the all child
    max-height: 60px !important;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${theme.breakpoints.down('md')} {
      max-height: 70px !important;
    }
  `
);
