import Carousel3d from 'presentation/components/common/carousels/3d';
import { FCC } from 'react';
import { Wrapper } from './controls';

export const CmsCarouselBanner: FCC = ({ children }) => {
  return (
    <Wrapper>
      <Carousel3d autoplay={{ disableOnInteraction: false, pauseOnMouseEnter: true }}>{children}</Carousel3d>
    </Wrapper>
  );
};
