import { Address } from 'domain/model/address';
import { AddressHelper } from 'utils/address';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { AddressSelectorProps } from '../types';
import useAddressSelector from '../useAddressSelector';

export type AddressSingleSelectorProps = AddressSelectorProps<Address>;

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getFullPath();
const filterOptionsDefault = (options: Address[]) => options;

const AddressSingleSelector = (props: AddressSingleSelectorProps) => {
  const {
    query,
    error,
    disabled,
    helperText,
    count,
    onlyValidPostalCode,
    label,
    level,
    fromLevel,
    toLevel,
    value,
    noOptionsText,
    locations,
    filterOptions = filterOptionsDefault,
    filterBy,
    sortBy,
    groupBy,
    onChange,
    getOptionLabel = getOptionLabelDefault,
    ...others
  } = props;

  const { suggestions, isLoading, onSearch } = useAddressSelector(props);

  return (
    <MPAutocompleteSingleSelect<Address>
      {...others}
      inputValue={query}
      value={value}
      options={suggestions}
      label={label}
      noOptionsText={noOptionsText}
      isLoading={isLoading}
      error={error}
      disabled={disabled}
      helperText={helperText}
      filterOptions={filterOptions}
      groupBy={groupBy?.(suggestions)}
      getOptionLabel={getOptionLabel}
      onSearchValue={onSearch}
      onChangeValue={onChange}
    />
  );
};

export default AddressSingleSelector;
