import { Typography } from '@mui/material';
import AppImageLazy from 'presentation/components/common/images/lazy';
import { NoImageIcon } from 'presentation/media/icons';
import AppImage from '../common';
import { CoverImage, Image, ImageStubContent, LogoImage } from './controls';

type CoverImageViewProps = {
  readonly id: Nullable<string>;
  readonly border?: boolean;
  readonly topRounded?: boolean;
  readonly bottomRounded?: boolean;
  readonly thumb?: boolean;
  readonly lazy?: boolean;
  readonly fit?: 'cover' | 'contain';
  readonly children?: React.ReactNode;
};

export const CoverImageView = (props: CoverImageViewProps) => {
  const {
    id,
    border = true,
    topRounded = true,
    bottomRounded = true,
    thumb = false,
    lazy,
    fit = 'cover',
    children,
  } = props;

  const ImageComponent = lazy ? AppImageLazy : AppImage;

  return (
    <CoverImage
      border={border}
      topRounded={topRounded}
      bottomRounded={bottomRounded}
      thumb={thumb}
      fit={fit}
    >
      {id ? (
        <ImageComponent
          src={id}
          alt=''
        />
      ) : (
        <ImageStubContent>
          <NoImageIcon />
          <Typography>Фотография временно отсутствует</Typography>
        </ImageStubContent>
      )}
      {children}
    </CoverImage>
  );
};

type LogoImageViewProps = {
  readonly id: Nullable<string>;
  readonly stub?: Nullable<string>;
};

export const LogoImageView = (props: LogoImageViewProps) => {
  const { id, stub } = props;

  return (
    <LogoImage>
      {id && (
        <Image
          src={id}
          alt=''
        />
      )}
      {!id && stub && (
        <img
          src={stub}
          alt=''
        />
      )}
    </LogoImage>
  );
};
