import { Button } from '@mui/material';
import { cacheKeys } from 'data/api';
import { useCreateUserOrdersMutation } from 'data/api/user';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getProductOfferDetailsRoute, getProductOfferListRoute } from '../../offer/product/entry';
import { getUserOrdersRoute } from '../../user/entry';
import { CartOrderListItem } from '../components/cartOrderListItem';
import { getCartRootRoute } from '../entry';
import LastOrdersLayout from './layout';
import { EOrderDiscriminator } from '../../../../domain/model/enums';
import { ProductOrderItem } from '../../../../domain/model/order';

const Layout = LastOrdersLayout;

const LastOrdersContainer = () => {
  const history = useHistory();

  const [, { data, reset }] = useCreateUserOrdersMutation(cacheKeys.order);

  const onOpenProductDetails = (order: ProductOrderItem) => {
    // по ТЗ открывать нужно в новом табе
    window.open(getProductOfferDetailsRoute({ id: order.offer.id }));
  };

  const onContinueBuying = () => {
    history.push(getProductOfferListRoute());
  };

  const onRedirectToOrders = () => {
    history.push(getUserOrdersRoute({}));
  };

  useEffect(() => {
    if (!data) history.push(getCartRootRoute());
  }, [data, history]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const isManyOrders = data && data.length > 1;
  const headerText = isManyOrders ? 'Заказы оформлены' : 'Заказ оформлен';

  return (
    <Layout
      title={data && headerText}
      description={
        data &&
        `${headerText} успешно. Дождитесь звонка от менеджера партнёра для согласования деталей доставки и оплаты.`
      }
      buttons={
        data && (
          <>
            <Button
              variant='contained'
              onClick={onContinueBuying}
            >
              Продолжить покупки
            </Button>
            <Button
              variant='outlined'
              onClick={onRedirectToOrders}
            >
              Перейти к заказам
            </Button>
          </>
        )
      }
    >
      {data?.map(order => {
        if (order.discriminator !== EOrderDiscriminator.ProductOrder) {
          return null;
        }

        return (
          <CartOrderListItem
            key={order.id}
            order={order}
            onOpenProductDetails={onOpenProductDetails}
          />
        );
      })}
    </Layout>
  );
};

export default LastOrdersContainer;
