import { useHistory } from 'react-router';
import { getAspOfferDetailsRoute } from '../../routes';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';
import AspOfferTabs from '../tabs';
import { EAspOfferDetailsTab } from '../utils';

type AspOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: EAspOfferDetailsTab;
};

export const AspOfferDetailsTabsAdapter = ({ tab, id, guid }: AspOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const { aspOffer } = useAspOfferDetailsData(id);

  const { partnerDesk } = useAspOfferPartnerDeskData(id);
  const onChangeTab = (event: React.SyntheticEvent, newTab: EAspOfferDetailsTab) => {
    history.replace(getAspOfferDetailsRoute({ id, tab: newTab, guid }));
  };

  if (!aspOffer || !partnerDesk) {
    return null;
  }

  return (
    <AspOfferTabs
      tab={tab}
      aspOffer={aspOffer}
      partnerDesk={partnerDesk}
      onChange={onChangeTab}
    />
  );
};
