import { Link, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { CodeWrapper, StyledButton, Wrapper } from './controls';

type ErrorBoundaryContentProps = {
  readonly message?: any;
  readonly stacktrace?: any;
  readonly onLogout?: (() => void) | false;
};

const ErrorBoundaryContent = ({ message, stacktrace, onLogout }: ErrorBoundaryContentProps) => {
  return (
    <Wrapper maxWidth='md'>
      <MPGrid
        container
        spacing={2}
        direction='column'
      >
        <MPGrid item>
          <Typography
            variant='h5'
            gutterBottom
          >
            Произошла ошибка!
          </Typography>
        </MPGrid>
        <MPGrid
          item
          container
          spacing={2}
        >
          <MPGrid item>
            <StyledButton
              component={Link}
              href='/'
            >
              Перейти на главную
            </StyledButton>
          </MPGrid>
          {onLogout && (
            <MPGrid item>
              <StyledButton onClick={() => onLogout()}>Выйти</StyledButton>
            </MPGrid>
          )}
        </MPGrid>
        <MPGrid item>
          <Typography
            variant='overline'
            gutterBottom
          >
            {message}
          </Typography>
        </MPGrid>
        {stacktrace && (
          <MPGrid item>
            <CodeWrapper>
              {stacktrace.split('\n').map((item: any, index: number) => (
                <div key={index}>{item}</div>
              ))}
            </CodeWrapper>
          </MPGrid>
        )}
      </MPGrid>
    </Wrapper>
  );
};

export default ErrorBoundaryContent;
