import FilterBadge from 'presentation/components/common/badges/filter';
import { IconButton } from 'presentation/features/offer/product/filter/button/controls';

type ProductFilterButtonProps = {
  readonly active?: boolean;
  readonly onClick: () => void;
}

const ProductFilterButton = ({ active, onClick }: ProductFilterButtonProps) => {
  return (
    <IconButton
      size='medium'
      onClick={onClick}
    >
      <FilterBadge active={active} />
    </IconButton>
  );
};

export default ProductFilterButton;
