import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetPartnerBookingOfferCategoriesUsedQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import PartnerDeskOffersCategories from 'presentation/features/partnerDesk/details/tabs/categories';
import { partnerDeskDetailsBookingOffersArgsSelector } from '../../../store/selectors';
import { useBookingOffersContextHandlers } from '../../../hooks/useBookingOffersContextHandlers';

export const PartnerDeskBookingOffersCategoriesAdapter = () => {
  const { onChangeBookingOffersCategory } = useBookingOffersContextHandlers();

  const { partnerId, categoryId } = useSelector(partnerDeskDetailsBookingOffersArgsSelector);

  const { data, error } = useGetPartnerBookingOfferCategoriesUsedQuery({ partnerId });

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  return data ? (
    <PartnerDeskOffersCategories
      categoryId={categoryId ?? null}
      categories={data}
      onChange={onChangeBookingOffersCategory}
    />
  ) : null;
};
