import { useMemo } from 'react';
import {
  EPartnerDeskDetailsTab,
  getPartnerDeskDetailsFirstActiveTab,
  getPartnerDeskDetailsTabList,
  PartnerDeskDetailsTabInfo,
} from '../utils';
import {
  useGetPartnerDeskData,
  UseGetPartnerDeskDataParams,
  UseGetPartnerDeskDataPayload,
} from './useGetPartnerDeskData';

type UsePartnerDeskTabInfoParams = UseGetPartnerDeskDataParams & {
  readonly isCorpUser: boolean;
  readonly isTradeOfferFavoritesAllowed: boolean;
  readonly isCorpOfferFavoritesAllowed: boolean;
  readonly isProductOfferFavoritesAllowed: boolean;
  readonly isBookingOfferFavoritesAllowed: boolean;
  readonly bonusUnavailableReason?: Nullable<string>;
};

type UsePartnerDeskTabInfoPayload = UseGetPartnerDeskDataPayload & {
  readonly tabToDisplay: Nullable<PartnerDeskDetailsTabInfo>;
  readonly tabList: PartnerDeskDetailsTabInfo[];
  readonly favoritesAllowed: boolean;
};

type PartnerDeskTabInfoHook = (params: UsePartnerDeskTabInfoParams) => UsePartnerDeskTabInfoPayload;

export const usePartnerDeskTabInfo: PartnerDeskTabInfoHook = props => {
  const {
    guid,
    tab,
    id,
    categoryId,
    isCorpUser,
    bonusUnavailableReason,
    isCorpOfferFavoritesAllowed,
    isProductOfferFavoritesAllowed,
    isBookingOfferFavoritesAllowed,
    isTradeOfferFavoritesAllowed,
  } = props;

  const {
    isFetching,
    partnerWindowContainersCount,
    tradeOffersCount,
    aspOffersCount,
    corpOffersCount,
    productOffersCount,
    bookingOffersCount,
    partnerDesk,
    partnerWindow,
  } = useGetPartnerDeskData({ id, guid, categoryId, tab, isCorpUser, bonusUnavailableReason });

  const tabList = useMemo(
    () =>
      getPartnerDeskDetailsTabList({
        isFetching,
        partnerWindowContainersCount,
        tradeOffersCount,
        aspOffersCount,
        isCorpUser,
        corpOffersCount,
        productOffersCount,
        bookingOffersCount,
      }),
    [
      isFetching,
      partnerWindowContainersCount,
      tradeOffersCount,
      aspOffersCount,
      isCorpUser,
      corpOffersCount,
      productOffersCount,
      bookingOffersCount,
    ]
  );

  const tabToDisplay = useMemo<Nullable<PartnerDeskDetailsTabInfo>>(
    () => getPartnerDeskDetailsFirstActiveTab(tab, tabList),
    [tabList, tab]
  );

  const favoritesAllowed = useMemo<boolean>(() => {
    switch (tabToDisplay?.value ?? null) {
      case EPartnerDeskDetailsTab.TradeOffers:
        return isTradeOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.CorpOffers:
        return isCorpOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.ProductOffers:
        return isProductOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.BookingOffers:
        return isBookingOfferFavoritesAllowed;
      default:
        return false;
    }
  }, [
    isBookingOfferFavoritesAllowed,
    isCorpOfferFavoritesAllowed,
    isProductOfferFavoritesAllowed,
    isTradeOfferFavoritesAllowed,
    tabToDisplay?.value,
  ]);

  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    aspOffersCount,
    productOffersCount,
    bookingOffersCount,
    partnerWindowContainersCount,
    partnerWindow,
    partnerDesk,
    tabToDisplay,
    tabList,
    favoritesAllowed,
  };
};
