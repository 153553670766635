import useFavoritesCounter from 'presentation/features/favorite/counter/useCounter';
import { getUserFavoritesRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemFavorites } from '../../components/menuItems';

export const UserMenuFavoritesAdapter = () => {
  const history = useHistory();

  const { count } = useFavoritesCounter();

  const onClick = () => {
    history.push(getUserFavoritesRoute({}));
  };

  return (
    <UserMenuItemFavorites
      count={count}
      onClick={onClick}
    />
  );
};
