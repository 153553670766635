import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { MPDialog, MPDialogProps } from 'presentation/theme/ui-kit/dialog/common';
import { FCC, ReactNode } from 'react';

export type MPConfirmDialogProps = MPDialogProps & {
  readonly text?: ReactNode;
  readonly buttons?: ReactNode;
};

export const MPConfirmDialog: FCC<MPConfirmDialogProps> = props => {
  const { title, text, buttons, children, ...others } = props;

  return (
    <MPDialog
      fullWidth
      keepMounted
      title={title}
      {...others}
    >
      {(text || children) && (
        <DialogContent>
          {text && <DialogContentText>{text}</DialogContentText>}
          {children}
        </DialogContent>
      )}

      {buttons && <DialogActions>{buttons}</DialogActions>}
    </MPDialog>
  );
};
