import { RootState } from 'data/store/store';

export const authProviderIsLoadingSelector = (store: RootState): boolean => store.auth.provider.isLoading;

export const authProviderIsAuthenticatedSelector = (store: RootState): boolean => store.auth.provider.isAuthenticated;

export const authProviderTokenSelector = (store: RootState) => store.auth.provider.token;

export const authProviderTokenParsedSelector = (store: RootState) => store.auth.provider.tokenParsed;

export const authProviderTokenParsedAccessSelector = (store: RootState) =>
  store.auth.provider.tokenParsed?.realm_access?.roles;
