import TradeOfferBreadcrumbs from '../../components/breadcrumbs';
import useTradeOfferCategoriesData from '../hooks/useTradeOfferCategoriesData';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';

type TradeOfferBreadcrumbAdapterProps = {
  readonly id: UUID;
}

const TradeOfferBreadcrumbAdapter = (props: TradeOfferBreadcrumbAdapterProps) => {
  const { id } = props;
  const { categories } = useTradeOfferCategoriesData();
  const { tradeOffer } = useTradeOfferDetailsData(id);

  return (
    <TradeOfferBreadcrumbs
      offerCategories={tradeOffer?.categories ?? null}
      dictionary={categories ?? null}
    />
  );
};

export default TradeOfferBreadcrumbAdapter;
