import styled from '@emotion/styled/macro';
import { css, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';

type HeaderTooltipProps = TooltipProps & {
  readonly disabled: boolean;
};

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: fit-content;

    display: flex;
    align-items: center;
    border-radius: 100px;

    gap: ${theme.spacing()};
  `
);

export const Text = styled(Typography)(
  () => css`
    overflow: hidden;

    font-weight: bold;
    word-break: break-all;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    background-color: ${(theme.palette.grey as any)[100]};
    border-radius: 100%;
    padding: ${theme.spacing(0.5)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0.25)};
    }
  `
);

export const IconTooltip = styled(({ className, ...props }: HeaderTooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(
  ({ theme, disabled }) => css`
    .${tooltipClasses.tooltip} {
      background-color: white;
      color: ${theme.palette.common.black};

      ${disabled ? 'display: none' : ''}
    }
  `
);
