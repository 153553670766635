import { useMediaQuery } from '@mui/material';
import { FileLink } from 'domain/model';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import AppImage from 'presentation/components/common/images/common';
import { useMemo, useRef, useState } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  Leveler,
  MediaPreviewCarouselsSeparator,
  MediaPreviewCarouselWrapper,
  MediaPreviewDefaultCarouselWrapper,
  MediaPreviewThumbCarouselWrapper,
} from './controls';
import { Swiper as SwiperClass } from 'swiper/types';
import CarouselDefault from '../carouselDefault';
import { SwiperProps, SwiperSlide } from 'swiper/react';
import useGetDefaultCarouselNavigation from '../defaultNavigation/useGetDefaultCarouselNavigation';
import CarouselThumbs from '../thumbs';

export type MediaPreviewCarouselProps = {
  readonly images: FileLink[];
  readonly initSlide: number;
  readonly onMediaItemClick?: () => void;
};

const THRESHOLD = 2;

const MediaPreviewCarousel = ({ images, initSlide, onMediaItemClick }: MediaPreviewCarouselProps) => {
  const zoomInstances = useRef<ReactZoomPanPinchRef[]>([]);

  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<Nullable<SwiperClass>>(null);

  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const mainSwiperProps: SwiperProps = {
    effect: 'slide',
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    spaceBetween: 10,
    navigation: isMdUp,
    noSwiping: true,
    initialSlide: initSlide,
    pagination: false,
    threshold: THRESHOLD,
    noSwipingClass: 'swiper-slide',
    onSlideChange: () => {
      zoomInstances.current.forEach(i => {
        i.resetTransform();
      });
    },

    ...(!isMdUp && {
      direction: 'vertical',
      centeredSlides: false,
      loop: false,
    }),
  };

  const thumbSwiperProps: SwiperProps = {
    threshold: THRESHOLD,
    spaceBetween: 8,
    initialSlide: initSlide,
    watchSlidesProgress: true,
    slidesPerView: isLgUp ? 10 : 6,
  };

  const controlsElement = useGetDefaultCarouselNavigation({
    navigationEnabled: mainSwiperProps.navigation as boolean,
    childrenCount: images.length,
    swiper,
  });

  const defaultSlides = useMemo(
    () =>
      images.map((image, i) => {
        return isMdUp ? (
          <SwiperSlide key={i}>
            <TransformWrapper
              ref={instance => {
                if (instance && !zoomInstances.current.includes(instance)) {
                  zoomInstances.current.push(instance);
                }
              }}
            >
              <TransformComponent
                contentStyle={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}
                wrapperStyle={{ width: '100%', height: '100%' }}
              >
                <AppImage
                  onClick={onMediaItemClick && onMediaItemClick}
                  src={image.path}
                />
              </TransformComponent>
            </TransformWrapper>
          </SwiperSlide>
        ) : (
          <SwiperSlide key={i}>
            <AppImage
              onClick={onMediaItemClick && onMediaItemClick}
              src={image.path}
            />
          </SwiperSlide>
        );
      }),
    [images, isMdUp, onMediaItemClick]
  );

  return (
    <MediaPreviewCarouselWrapper>
      <Leveler>
        <MediaPreviewDefaultCarouselWrapper>
          <CarouselDefault
            controlsElement={controlsElement}
            swiperProps={mainSwiperProps}
            onSwiper={setSwiper}
            handlerChangeActiveIndex={() => {}}
            thumbsSwiper={thumbsSwiper}
          >
            {defaultSlides}
          </CarouselDefault>
        </MediaPreviewDefaultCarouselWrapper>
      </Leveler>
      {isMdUp && (
        <>
          <MediaPreviewCarouselsSeparator />
          <MediaPreviewThumbCarouselWrapper>
            <CarouselThumbs
              onSwiper={setThumbsSwiper}
              thumbScrollMode={true}
              swiperProps={thumbSwiperProps}
            >
              {images.map((image, i) => (
                <SwiperSlide key={i}>
                  <AppImage
                    onClick={onMediaItemClick && onMediaItemClick}
                    src={image.path}
                  />
                </SwiperSlide>
              ))}
            </CarouselThumbs>
          </MediaPreviewThumbCarouselWrapper>
        </>
      )}
    </MediaPreviewCarouselWrapper>
  );
};

export default MediaPreviewCarousel;
