import { useGetCmsCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import { ECmsCollectionLinkObjectType } from 'domain/model/enums';
import React, { useEffect } from 'react';
import { CmsCollectionLayout } from '../layouts';
import { CmsContainerTypedCommonProps, ECmsCollectionPlace } from '../types';
import { CmsCorpOfferCollectionCommonAdapter } from './adapters/corpOffersCommon';
import { CmsCorpOfferCollectionSelfAdapter } from './adapters/corpOffersSelf';
import { CmsTradeOfferCollectionCommonAdapter } from './adapters/tradeOffersCommon';
import { CmsTradeOfferCollectionSelfAdapter } from './adapters/tradeOffersSelf';
import { CmsPartnerCollection } from './partners';

type CmsCollectionContainerProps = Omit<CmsContainerTypedCommonProps<any>, 'layout'> & {
  readonly collectionId: UUID;
  readonly guid: UUID;
  readonly stub?: React.ReactNode;
  readonly place?: ECmsCollectionPlace;
  readonly pageSize?: Nullable<number>;
};

/**
 * фича отображения состава коллекции
 */
const CmsCollectionContainer = ({
  collectionId,
  header,
  footer,
  guid,
  stub,
  place,
  onDataLoaded,
}: CmsCollectionContainerProps) => {
  const { data: collection, error } = useGetCmsCollectionQuery({ guid, id: collectionId });

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  if (!collection) return null;

  const TradeOffersAdapter =
    place === ECmsCollectionPlace.Self ? CmsTradeOfferCollectionSelfAdapter : CmsTradeOfferCollectionCommonAdapter;
  const CorpOffersAdapter =
    place === ECmsCollectionPlace.Self ? CmsCorpOfferCollectionSelfAdapter : CmsCorpOfferCollectionCommonAdapter;

  return (
    <>
      {collection.linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && (
        <TradeOffersAdapter
          id={collection.id}
          guid={guid}
          layout={CmsCollectionLayout}
          header={header}
          footer={footer}
          stub={stub}
          onDataLoaded={onDataLoaded}
        />
      )}
      {collection.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && (
        <CorpOffersAdapter
          id={collection.id}
          guid={guid}
          layout={CmsCollectionLayout}
          header={header}
          footer={footer}
          stub={stub}
          onDataLoaded={onDataLoaded}
        />
      )}
      {collection.linkObjectType === ECmsCollectionLinkObjectType.Partner && (
        <CmsPartnerCollection
          id={collection.id}
          guid={guid}
          layout={CmsCollectionLayout}
          header={header}
          footer={footer}
          onDataLoaded={onDataLoaded}
        />
      )}
    </>
  );
};

export default CmsCollectionContainer;
