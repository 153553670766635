import { ScopeContext } from '@sentry/types/types/scope';
import React from 'react';

export type SentryContextType = {
  readonly captureException: (exception: string | Error, context?: Omit<Partial<ScopeContext>, 'user'>) => void;
};

const SentryContext = React.createContext<SentryContextType>({} as SentryContextType);

export default SentryContext;
