import { FC } from 'react';
import { Fade } from '@mui/material';
import { BookingPriceUnit, BookingServiceCategory } from 'domain/model/bookingOffer';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { BookingCartItem, UseBookingOfferDetails } from '../../details/types';
import { ServiceSelector } from '../serviceSelector';
import { ChipWrapper } from './controls';

type BookingDetailsServicesTabProps = {
  readonly selectedId: Nullable<UUID>;
  readonly serviceCategories: Pick<BookingServiceCategory, 'id' | 'name'>[];
  readonly services?: Nullable<BookingPriceUnit[]>;
  readonly cart: Nullable<BookingCartItem[]>;
  readonly onSelect: (id: UUID | null) => void;
  readonly onChangeServices: UseBookingOfferDetails['onChangeServicesSelection'];
};

const BookingOfferDetailsTabServices: FC<BookingDetailsServicesTabProps> = ({
  selectedId,
  serviceCategories,
  services,
  cart,
  onSelect,
  onChangeServices,
}) => {
  return (
    <Fade in>
      <div>
        <ChipWrapper>
          <MPChip
            label='Все'
            variant={selectedId ? 'outlined' : 'filled'}
            color={selectedId ? 'white' : 'black'}
            onClick={() => onSelect(null)}
          />
          {serviceCategories.map(({ id, name }) => (
            <MPChip
              key={id}
              label={name}
              variant={id === selectedId ? 'filled' : 'outlined'}
              color={id === selectedId ? 'black' : 'white'}
              onClick={() => onSelect(id)}
            />
          ))}
        </ChipWrapper>

        {services?.map(service => (
          <ServiceSelector
            key={service.id}
            {...service}
            cart={cart}
            onChange={onChangeServices}
          />
        ))}
      </div>
    </Fade>
  );
};

export default BookingOfferDetailsTabServices;
