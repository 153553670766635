import { AxiosRequestHeaders } from 'axios';
import HttpClient from 'data/network/http';
import { EHeaders } from 'data/network/types';

export let interceptorId: Nullable<number> = null;
const httpClient = HttpClient.getInstance();
export const applyInterceptor = (targetParamsEncoded: Nullable<string>) => {
  if (!interceptorId && targetParamsEncoded) {
    interceptorId = httpClient.applyInterceptor(
      config => {
        const headers: AxiosRequestHeaders = config.headers ?? {};

        try {
          if (targetParamsEncoded) {
            headers[EHeaders.TargetValue] = targetParamsEncoded;
          }
        } catch (e) {
          console.error('Guest target set interceptor error', e);
        }

        return config;
      },
      e => {
        return Promise.reject(e);
      }
    );
  }
};

export const clearInterceptor = () => {
  if (!interceptorId) {
    return;
  }
  httpClient.ejectInterceptor(interceptorId);
};
