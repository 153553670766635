import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { ERenderSource } from 'presentation/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCorpOffersListRoute } from '../../routes';
import { corpOfferDetailsStartSession } from '../store/slice';

export type UseCorpOfferDetails = {
  readonly onBack: () => void;
};

export type UseCorpOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
  readonly from: ERenderSource;
};

const useCorpOfferDetails = ({ id, guid, from }: UseCorpOfferDetailsProps): UseCorpOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);

  useEffect(() => {
    dispatch(corpOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const onBack = () => {
    gotoPrevIndependentLocation(getCorpOffersListRoute());
  };

  return {
    onBack,
  };
};

export default useCorpOfferDetails;
