import OfferClaimSubscriptionDisabled from './disabled';
import OfferClaimSubscriptionEnabled from './enabled';

type OfferClaimSubscriptionProps = {
  readonly enabled?: boolean;
  readonly isFetching?: boolean;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe?: () => void;
};

const OfferClaimSubscription = (props: OfferClaimSubscriptionProps) => {
  const { enabled, isFetching, onSubscribe, onUnSubscribe } = props;

  return enabled ? (
    <OfferClaimSubscriptionEnabled
      isFetching={isFetching}
      onUnSubscribe={onUnSubscribe}
    />
  ) : (
    <OfferClaimSubscriptionDisabled
      isFetching={isFetching}
      onSubscribe={onSubscribe}
    />
  );
};

export default OfferClaimSubscription;
