import CancelAdapter from 'presentation/features/header/adapters/cancel';
import CatalogAdapter from './adapters/catalog';
import CatalogButtonAdapter from './adapters/catalogButton';
import HeaderLinksAdapter from './adapters/links';
import HeaderLocationAdapter from './adapters/location';
import HeaderLogoAdapter from './adapters/logo';
import MobileHeaderOptionsAdapter from './adapters/mobileOptions/mobileOptions';
import HeaderOptionsAdapter from './adapters/options';
import SearchAdapter from './adapters/search';
import Header from './component';
import { HeaderContext } from './context';
import HeaderDialogs from './dialogs';
import useHeader from './hooks/useHeader';
import BalancesAdapter from './adapters/balances';

const HeaderContainer = () => {
  const handlers = useHeader();

  return (
    <HeaderContext.Provider value={handlers}>
      <Header
        logo={<HeaderLogoAdapter />}
        catalogButton={<CatalogButtonAdapter />}
        catalog={<CatalogAdapter />}
        search={<SearchAdapter />}
        cancel={<CancelAdapter />}
        links={<HeaderLinksAdapter />}
        dialogs={<HeaderDialogs />}
        balances={<BalancesAdapter />}
        location={<HeaderLocationAdapter />}
        options={<HeaderOptionsAdapter />}
        mobileOptions={<MobileHeaderOptionsAdapter />}
      />
    </HeaderContext.Provider>
  );
};

export default HeaderContainer;
