import AboutTextSectionsContainer from '../textSections/container';
import AboutPersonalDataPolicyStep5 from './info/step5';
import AboutPersonalDataPolicyStep3 from './info/step3';
import AboutPersonalDataPolicyStep6 from './info/step6';
import AboutPersonalDataPolicyStep1 from './info/step1';
import AboutPersonalDataPolicyStep2 from './info/step2';
import AboutPersonalDataPolicyStep7 from './info/step7';
import AboutPersonalDataPolicyStep4 from './info/step4';

export enum ESection {
  Step1 = '1',
  Step2 = '2',
  Step3 = '3',
  Step4 = '4',
  Step5 = '5',
  Step6 = '6',
  Step7 = '7',
}

const AboutPersonalDataPolicyContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Политика обработки персональных данных в ОАО «РЖД»'
      subText='Распоряжение ОАО «РЖД» от 10.11.2023 № 2819/р'
      sections={[
        {
          id: ESection.Step1,
          name: '1. Общие положения',
          component: AboutPersonalDataPolicyStep1,
        },
        {
          id: ESection.Step2,
          name: '2. Основные понятия',
          component: AboutPersonalDataPolicyStep2,
        },
        {
          id: ESection.Step3,
          name: '3. Принципы и правила обработки персональных данных',
          component: AboutPersonalDataPolicyStep3,
        },
        {
          id: ESection.Step4,
          name: '4. Правовые основания обработки персональных данных',
          component: AboutPersonalDataPolicyStep4,
        },
        {
          id: ESection.Step5,
          name: '5. Организация обработки персональных данных',
          component: AboutPersonalDataPolicyStep5,
        },
        {
          id: ESection.Step6,
          name: '6. Права субъекта персональных данных',
          component: AboutPersonalDataPolicyStep6,
        },
        {
          id: ESection.Step7,
          name: '7. Заключительные положения',
          component: AboutPersonalDataPolicyStep7,
        },
      ]}
    />
  );
};

export default AboutPersonalDataPolicyContainer;
