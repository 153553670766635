import styled from '@emotion/styled/macro';
import { buttonBaseClasses } from '@mui/material';

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  .${buttonBaseClasses.root} {
    padding: 0.75rem 1.25rem;

    ${p => p.theme.breakpoints.down('sm')} {
      padding: 0.75rem 1.25rem;
    }
  }
`;
