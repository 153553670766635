import { combineReducers } from '@reduxjs/toolkit';
import args, { PageableArgsState } from './argumentsStorage/redux/store/slice';
import cache, { PageableCacheState } from './cacheStorage/redux/store/slice';

type PageableDataState = {
  readonly args: PageableArgsState;
  readonly cache: PageableCacheState;
};

export default combineReducers<PageableDataState>({
  args,
  cache,
});
