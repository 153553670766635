import { Text } from './controls';

const AboutCookiesHowDoWeUseInfo = () => {
  return (
    <>
      <Text>
        Сookies используются в целях улучшения работы веб-сайта. Сведения о действиях пользователей веб-сайта
        обрабатываются для совершенствования сервиса, определения предпочтений пользователя, используются для улучшения
        персонализации и интерактивности в предоставлении целевой информации на Сайте
      </Text>
    </>
  );
};
export default AboutCookiesHowDoWeUseInfo;
