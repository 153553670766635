import { Theme, useMediaQuery } from '@mui/material';
import CatalogButton from 'presentation/features/catalog/components/button';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useSelector } from 'react-redux';
import { headerCatalogDialogVisibleSelector } from '../store/selectors';

const CatalogButtonAdapter = () => {
  const handlers = useContextHandlers();

  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);

  const isXlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));

  return (
    <CatalogButton
      open={catalogVisible}
      showIconOnly={isXlDown}
      onClick={handlers.toggleCatalog}
    />
  );
};

export default CatalogButtonAdapter;
