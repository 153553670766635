import { Text } from './controls';

const AboutTermsOfUseWarrantyAndLimitationInfo = () => {
  return (
    <>
      <Text>
        8.1. Пользователь использует Платформу на свой собственный риск. Платформа предоставляется «как есть».
        Правообладатель не принимает на себя никакой ответственности, в том числе за соответствие сервисов целям
        Пользователя;
      </Text>
      <Text>
        8.2 Правообладатель не контролирует и не несет ответственности за высказывания, действия, поведения людей и
        других лиц.
      </Text>
      <Text>
        8.3. Правообладатель не гарантирует, что: Платформа соответствует/будет соответствовать требованиям
        Пользователя; Платформа будет предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые
        могут быть получены с использованием Платформы, будут точными и надежными и могут использоваться для каких-либо
        целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество
        какого-либо продукта, услуги, информации и пр., полученных с использованием Платформы, будет соответствовать
        ожиданиям Пользователя;
      </Text>
      <Text>
        8.4. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и руководства к
        действию и т.д.), доступ к которым Пользователь получает с использованием Платформы, Пользователь может
        использовать на свой собственный страх и риск и самостоятельно несет ответственность за возможные последствия
        использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить
        компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред;
      </Text>
      <Text>
        8.5. Правообладатель не несет ответственности за любые виды убытков, произошедшие вследствие использования
        Пользователем Платформы или отдельных частей/функций Платформы. Это положение распространяется также на удаление
        Правообладателем контента Пользователя, его информации или учетной записи.
      </Text>
    </>
  );
};

export default AboutTermsOfUseWarrantyAndLimitationInfo;
