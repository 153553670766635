import ContentLoader from 'presentation/components/common/loader';
import PartnerDeskHeader from 'presentation/features/partnerDesk/components/header';
import { PartnerDeskDetailsLayout } from 'presentation/features/partnerDesk/details/layouts';
import { useMemo } from 'react';
import { getBonusConfig } from '../../../utils';
import { PartnerDeskDetailsContext } from './context';
import usePartnerDeskDetailsHandlers from './hooks/usePartnerDeskDetails';
import { usePartnerDeskTabInfo } from './hooks/usePartnerDeskTabInfo';
import {
  PartnerDeskDetailsTabAboutCompany,
  PartnerDeskDetailsTabAspOffers,
  PartnerDeskDetailsTabBookingOffers,
  PartnerDeskDetailsTabContacts,
  PartnerDeskDetailsTabCorpOffers,
  PartnerDeskDetailsTabPartnerWindow,
  PartnerDeskDetailsTabProductOffers,
  PartnerDeskDetailsTabs,
  PartnerDeskDetailsTabTradeOffers,
} from './tabs';
import { EPartnerDeskDetailsTab } from './utils';

type PartnerDeskDetailsContainerProps = {
  readonly guid: UUID;
  readonly id: UUID;
  readonly tab: Nullable<EPartnerDeskDetailsTab>;
  readonly categoryId?: Nullable<UUID>;
  readonly isCorpUser?: boolean;
  readonly isTradeOfferFavoritesAllowed: boolean;
  readonly isCorpOfferFavoritesAllowed: boolean;
  readonly isProductOfferFavoritesAllowed: boolean;
  readonly isBookingOfferFavoritesAllowed: boolean;
};

const Layout = PartnerDeskDetailsLayout;

const PartnerDeskDetailsContainer = (props: PartnerDeskDetailsContainerProps) => {
  const {
    guid,
    id,
    tab,
    categoryId,
    isCorpUser = false,
    isTradeOfferFavoritesAllowed = false,
    isCorpOfferFavoritesAllowed = false,
    isProductOfferFavoritesAllowed = false,
    isBookingOfferFavoritesAllowed = false,
  } = props;

  const handlers = usePartnerDeskDetailsHandlers({ guid, partnerId: id, categoryId });

  const bonusUnavailableReason = getBonusConfig()?.unavailableReason;

  const { partnerDesk, partnerWindow, tabToDisplay, tabList, isFetching } = usePartnerDeskTabInfo({
    guid,
    bonusUnavailableReason,
    tab,
    id,
    categoryId,
    isCorpUser,
    isTradeOfferFavoritesAllowed,
    isCorpOfferFavoritesAllowed,
    isProductOfferFavoritesAllowed,
    isBookingOfferFavoritesAllowed,
  });

  const tabsComponent = useMemo(
    () => (
      <PartnerDeskDetailsTabs
        tab={tabToDisplay}
        tabs={tabList}
        onChangeTab={handlers.onChangeTab}
      />
    ),
    [tabToDisplay, tabList, handlers.onChangeTab]
  );

  return (
    <PartnerDeskDetailsContext.Provider value={handlers}>
      <Layout
        toolbar={tabToDisplay?.toolbar}
        header={partnerDesk && <PartnerDeskHeader partnerDesk={partnerDesk} />}
        tabs={tabsComponent}
        loader={isFetching && <ContentLoader />}
      >
        {partnerDesk && tabToDisplay && (
          <>
            {tabToDisplay.value === EPartnerDeskDetailsTab.PartnerWindow && partnerWindow && (
              <PartnerDeskDetailsTabPartnerWindow
                partnerWindow={partnerWindow}
                guid={guid}
                partnerId={id}
              />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.TradeOffers && (
              <PartnerDeskDetailsTabTradeOffers guid={guid} />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.AspOffers && <PartnerDeskDetailsTabAspOffers guid={guid} />}
            {tabToDisplay.value === EPartnerDeskDetailsTab.CorpOffers && (
              <PartnerDeskDetailsTabCorpOffers guid={guid} />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.ProductOffers && (
              <PartnerDeskDetailsTabProductOffers guid={guid} />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.BookingOffers && (
              <PartnerDeskDetailsTabBookingOffers guid={guid} />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.AboutCompany && (
              <PartnerDeskDetailsTabAboutCompany about={partnerDesk.aboutCompany} />
            )}
            {tabToDisplay.value === EPartnerDeskDetailsTab.Contacts && (
              <PartnerDeskDetailsTabContacts contacts={partnerDesk.contacts} />
            )}
          </>
        )}
      </Layout>
    </PartnerDeskDetailsContext.Provider>
  );
};

export default PartnerDeskDetailsContainer;
