import { ECatalogTab } from 'presentation/features/catalog/types';
import { useSelector } from 'react-redux';
import { useGetTradeOfferCategoriesUsedTreeQuery } from 'data/api/tradeOffer';
import { catalogTabSelector } from '../store/selectors';
import { CatalogOffersContainer } from '../components/catalogContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';

type CatalogTradeOffersTabAdapterProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogTradeOffersTabAdapter = (props: CatalogTradeOffersTabAdapterProps) => {
  const { selected } = props;

  const tab = useSelector(catalogTabSelector);
  const { onSelectTradeOffersCategory } = useContextHandlers();

  const isActiveTab = tab === ECatalogTab.TradeOffers;

  const response = useGetTradeOfferCategoriesUsedTreeQuery({ guid: 'catalog-trade-offers' }, { skip: !isActiveTab });

  return isActiveTab ? (
    <CatalogOffersContainer
      {...response}
      onSelect={onSelectTradeOffersCategory}
      selected={selected}
    />
  ) : null;
};
