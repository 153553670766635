import styled from '@emotion/styled/macro';
import { css, Grid } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    margin: 0 auto;

    flex: 1;
    position: relative;
    padding-bottom: ${theme.spacing(25)};
    padding-top: ${theme.spacing(5.5)};

    ${theme.breakpoints.up('md')} {
      max-width: 736px;
    }
  `
);

export enum EOrderGridAreas {
  Options = 'options',
  Details = 'details',
}

export const OrderLayoutGrid = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing()};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas:
      '${`${EOrderGridAreas.Options} `.repeat(12)}'
      '${`${EOrderGridAreas.Details} `.repeat(12)}';
  `
);

type OrderLayoutGridAreaProps = {
  readonly area: EOrderGridAreas;
};

export const OrderLayoutGridArea = styled(Grid)<OrderLayoutGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const BackgroundContainer = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.white.main};

    ${theme.breakpoints.up('md')} {
      border-radius: ${theme.spacing(2)};
    }
  `
);

export const CloseWrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
  `
);
