import { OfferActivation } from '../../../../../../domain/model/activation';
import { OfferPromotionType } from '../../../../../../domain/model/enums';
import ActivationListItem from '../../../../activation/listItem';
import useTakeOfferActivations from '../../../../offer/hooks/useTakeOfferActivations';

export type ActivationListItemAdapterProps = {
  readonly activation: OfferActivation;
  readonly onOfferClick: () => void;
  readonly onOpenHelpDialog: (type: OfferPromotionType) => void;
  readonly getActivationCodeTypeName: (type: OfferPromotionType) => string;
  readonly onPartnerLinkClick: () => void;
};

const ActivationListItemAdapter = (props: ActivationListItemAdapterProps) => {
  const { activation, getActivationCodeTypeName, onOpenHelpDialog, onPartnerLinkClick, onOfferClick } = props;

  const { onTakeActivation, isTaking, onNeedCopy, needCopy, isTaked } = useTakeOfferActivations({
    lastActivation: activation,
  });

  return (
    <ActivationListItem
      activation={activation}
      onTakeActivation={() => onTakeActivation(activation)}
      getActivationCodeTypeName={getActivationCodeTypeName}
      onOfferClick={onOfferClick}
      onPartnerLinkClick={onPartnerLinkClick}
      onOpenHelpDialog={onOpenHelpDialog}
      onNeedCopy={onNeedCopy}
      needCopy={needCopy}
      copied={isTaked}
      fileDownloading={isTaking}
    />
  );
};

export default ActivationListItemAdapter;
