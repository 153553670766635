import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 95%;

    z-index: ${theme.zIndex.snackbar};

    position: fixed;
    margin: 0 50%;
    transform: translate(-50%, 0);
    bottom: ${theme.spacing(2)};

    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      width: auto;
    }

    & > :not(:first-of-type) {
      margin-top: ${theme.spacing()};
    }
  `
);
