import styled from '@emotion/styled/macro';
import {
  Container as MuiContainer,
  css,
  Drawer as MuiDrawer,
  drawerClasses,
  DrawerProps as MuiDrawerProps,
  paperClasses,
} from '@mui/material';

type DrawerProps = MuiDrawerProps & {
  readonly offsetTop?: number;
  readonly offsetRight?: number;
};

export const Drawer = styled(({ offsetTop, offsetRight, ...others }: DrawerProps) => (
  <MuiDrawer {...others} />
))<DrawerProps>(
  ({ theme, offsetTop, offsetRight }) => css`
    z-index: ${theme.zIndex.appBar - 2} !important;

    .${drawerClasses.paper} {
      background: ${theme.palette.background.default};
    }

    .${paperClasses.root} {
      max-height: 100%;
      padding-top: ${offsetTop ?? 0}px;
      padding-right: ${offsetRight ?? 0}px;

      ${theme.breakpoints.down('sm')} {
        height: 100%;
      }
    }
  `
);

export const Container = styled(MuiContainer)(
  () => css`
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
  `
);
