import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'data/store/store';

export const footerRegisterSelector = (state: RootState) => state.footerRegister;

export const footerRegisterAllHeightSelector = createSelector([footerRegisterSelector], result => {
  return Object.values(result).reduce((prev, current) => {
    return prev + (current?.height ?? 0);
  }, 0);
});
