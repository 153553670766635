import { useGetCmsLandingPageQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { FCC, useEffect } from 'react';
import { useHistory } from 'react-router';
import CmsContainerContainer from '../../cms/container/container';
import { LazyContentWrapper } from '../../general/lazyContentWrapper';
import { LazyContentItem } from '../../general/lazyContentWrapper/item';
import { Wrapper } from './controls';
import PartnerWindowLayout from './layout';

type LandingWindowContainerProps = {
  readonly guid: UUID;
};

const Layout = PartnerWindowLayout;

const LandingWindowContainer: FCC<LandingWindowContainerProps> = ({ guid }) => {
  const { data, isFetching: isFetching, error, isSuccess } = useGetCmsLandingPageQuery(guid);
  const history = useHistory();

  const { defaultRoute } = useAuthUser();

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  useEffect(() => {
    if ((isSuccess || error) && !data?.length) {
      history.replace(defaultRoute);
    }
  }, [history, defaultRoute, data, isSuccess, error]);

  return (
    /*key нужен для полноценного рефреша, так как рендер сложный асинхронный*/
    <Layout key={guid}>
      <LazyContentWrapper guid={guid}>
        {listSize => (
          <>
            <Wrapper>
              {data?.slice(0, listSize + 1).map((container, i) => (
                <div key={container.id}>
                  <LazyContentItem
                    elementSize={33}
                    guid={guid}
                    containerId={container.id}
                    isLoaded={i !== listSize}
                  >
                    {onContainerDataLoaded => (
                      <CmsContainerContainer
                        guid={guid}
                        container={container}
                        onContainerDataLoaded={onContainerDataLoaded}
                      />
                    )}
                  </LazyContentItem>
                </div>
              ))}
            </Wrapper>

            {isFetching && <ContentLoader />}
          </>
        )}
      </LazyContentWrapper>
    </Layout>
  );
};

export default LandingWindowContainer;
