import { AccountBalance, User } from 'domain/model/user';
import Splitter from 'presentation/components/common/splitter';
import { UserBalanceValue } from 'presentation/features/user/components/balanceValue';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getPersonFullName } from 'presentation/utils';
import { HeaderUserShort } from '../short';
import { BalancesWrapper, Email, Text } from './controls';

type HeaderUserFullProps = {
  readonly user: User;
  readonly isFetching?: boolean;
  readonly balances?: Nullable<AccountBalance[]>;
};

export const HeaderUserFull = (props: HeaderUserFullProps) => {
  const { user, isFetching, balances } = props;

  return (
    <MPGrid
      container
      spacing={2}
    >
      <MPGrid
        item
        xs={2.5}
      >
        <HeaderUserShort
          size='small'
          user={user}
          isFetching={isFetching}
        />
      </MPGrid>
      <MPGrid
        item
        xs={9.5}
        zero
      >
        <Text>{getPersonFullName(user)}</Text>
        <Splitter size={0.5} />
        <Email
          color='text.secondary'
          variant='body2'
          component='div'
          title={user.email}
        >
          {user.email}
        </Email>
      </MPGrid>
      <MPGrid
        item
        xs={12}
      >
        {balances && balances.length > 0 && (
          <BalancesWrapper>
            <MPGrid
              container
              spacing={0.5}
              direction='column'
            >
              {balances.map(balance => (
                <MPGrid
                  key={balance.type}
                  item
                >
                  <UserBalanceValue
                    type={balance.type}
                    value={balance.balance}
                  />
                </MPGrid>
              ))}
            </MPGrid>
          </BalancesWrapper>
        )}
      </MPGrid>
    </MPGrid>
  );
};
