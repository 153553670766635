import { TypographyProps, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../../../media/icons';
import AppSvgIcon from '../icon';
import { CollapseText, Content, IconWrapper, Toggle } from './controls';

export type ExpandableTextProps = TypographyProps & {
  readonly lines?: number;
  readonly more?: ReactNode;
  readonly less?: ReactNode;
  readonly timeout?: number;
  readonly ellipsis?: boolean;
  readonly expanded?: boolean;
  readonly as?: React.ElementType;
  readonly onExpand?: () => void;
};

export const ExpandableText = (props: ExpandableTextProps) => {
  const {
    children,
    expanded = false,
    ellipsis = true,
    lines = 2,
    more,
    less,
    timeout = 300,
    variant = 'body1',
    onExpand,
    ...other
  } = props;

  const refContent = useRef<Nullable<HTMLDivElement>>(null);
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const { typography } = useTheme();

  const lineHeight = +String(typography[variant as Variant].lineHeight || typography.body1.lineHeight).replace(
    /\D/g,
    ''
  );

  const minHeight = lines * lineHeight;

  const MoreComponent = more ? more : 'посмотреть полностью';
  const LessComponent = less ? less : 'скрыть';

  const handleToggleExpanded = () => {
    onExpand?.();
  };

  // Инициализируем высоту блока при смене
  useEffect(() => {
    if (refContent.current !== null) {
      refContent.current.style.display = 'block';
      setIsExpandable(refContent.current.getBoundingClientRect().height > minHeight);
    }
  }, [lines, minHeight, refContent]);

  // Переключаем ellipsis
  useEffect(() => {
    if (!expanded && ellipsis) {
      setTimeout(() => {
        if (refContent.current !== null) {
          refContent.current.style.display = '-webkit-box';
        }
      }, timeout);
    } else {
      if (refContent.current !== null) {
        refContent.current.style.display = 'block';
      }
    }
  }, [ellipsis, expanded, timeout]);

  return (
    <>
      <CollapseText
        in={expanded}
        collapsedSize={minHeight}
        timeout={timeout}
      >
        <Content
          ref={refContent}
          lines={lines}
          expanded={expanded}
          variant={variant}
          {...other}
        >
          {children}
        </Content>
      </CollapseText>
      {isExpandable && (
        <Toggle
          onClick={handleToggleExpanded}
          color={'secondary'}
        >
          <IconWrapper expanded={expanded}>
            <AppSvgIcon
              icon={ArrowDownIcon}
              color={'secondary'}
              fontSize={'small'}
            />
          </IconWrapper>
          {expanded ? LessComponent : MoreComponent}
        </Toggle>
      )}
    </>
  );
};
