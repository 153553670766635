import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type TradeOfferDetailsState = {
  readonly guid: Nullable<UUID>;
};

type Reducer<T> = CaseReducer<TradeOfferDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<TradeOfferDetailsState> & {
  tradeOfferDetailsStartSession: Reducer<{ guid: UUID }>;
};

const slice = createSlice<TradeOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
  },
  reducers: {
    tradeOfferDetailsStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
  },
});

export const { tradeOfferDetailsStartSession } = slice.actions;

export default slice.reducer;
