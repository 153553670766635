import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useState } from 'react';
import HeaderLink from './link';

const Feedback = () => {
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);

  return (
    <>
      <HeaderLink
        text='Форма обратной связи'
        component='div'
        onClick={() => setFeedbackVisible(true)}
      />
      <AboutFeedbackContainer
        open={feedbackVisible}
        onClose={() => setFeedbackVisible(false)}
      />
    </>
  );
};

export default Feedback;
