import { Route, Switch } from 'react-router-dom';
import LoginScreen from 'presentation/screen/login';

const AuthEntry = () => {
  return (
    <Switch>
      <Route component={LoginScreen} />
    </Switch>
  );
};

export default AuthEntry;
