import { combineReducers } from '@reduxjs/toolkit';
import list from './list/store/slice';
import { BookingOfferListState } from './list/store/types';
import details, { BookingOfferDetailsState } from './details/store/slice';

type BookingOfferState = {
  readonly list: BookingOfferListState;
  readonly details: BookingOfferDetailsState;
};

export default combineReducers<BookingOfferState>({
  list,
  details,
});
