import styled from '@emotion/styled/macro';

export const DataWrapper = styled.div`
  flex: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: ${p => p.theme.spacing(5)};
  }
`;
