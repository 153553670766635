import CheckIcon from '@mui/icons-material/Check';
import { Fade, Typography } from '@mui/material';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import { ContentWrapper, ControlButtons, Footer, Header, HeaderInfo, ListOrders } from './controls';
import { LastOrdersLayoutType } from './types';

const LastOrdersLayout: LastOrdersLayoutType = props => {
  const { title, description, buttons, children } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        <ContentWrapper>
          <Header>
            <Typography variant='h3'>{title}</Typography>
            <CheckIcon color='success' />
          </Header>
          <HeaderInfo variant='body1'>{description}</HeaderInfo>
          <ListOrders>{children}</ListOrders>
          <Footer>
            <ControlButtons>{buttons}</ControlButtons>
          </Footer>
        </ContentWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default LastOrdersLayout;
