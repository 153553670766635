import styled from '@emotion/styled';
import { css, tabsClasses } from '@mui/material';
import { MPTabs } from 'presentation/theme/ui-kit/tab';

export const StyledTabs = styled(MPTabs)(
  ({ theme }) => css`
    .${tabsClasses.flexContainer} {
      gap: ${theme.spacing()};
    }
  `
);
