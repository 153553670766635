import { Text } from '../../components/controls';

const AboutPublicOfferDisputeResolutionInfo = () => {
  return (
    <>
      <Text>
        6.1. К Оферте подлежит применению право Российской Федерации и любые претензии или иски, вытекающие из нее
        должны быть поданы и рассмотрены в суде по месту нахождения ОАО «РЖД».
      </Text>
    </>
  );
};

export default AboutPublicOfferDisputeResolutionInfo;
