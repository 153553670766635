import StubCommon from 'presentation/components/common/stub/common';
import { offersStub } from 'presentation/media/images';

type CorpOffersStubProps = {
  readonly isEmptySearch: boolean;
};

const CorpOffersStub = (props: CorpOffersStubProps) => {
  const { isEmptySearch } = props;

  return isEmptySearch ? (
    <StubCommon
      title='К сожалению, для вас нет подходящих предложений бонусного пакета'
      text='Но мы стараемся исправить эту ситуацию'
      image={offersStub}
    />
  ) : (
    <StubCommon
      title='По вашему запросу ничего не найдено'
      text='Попробуйте сократить запрос или задать его по-другому'
      image={offersStub}
    />
  );
};

export default CorpOffersStub;
