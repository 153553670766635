import { Hidden, useMediaQuery } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import React, { FCC, useEffect, useRef, useState } from 'react';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import AppSvgIcon from '../../icon';
import { ButtonNext, ButtonPrev, StyledSwiper, Wrapper } from './controls';

const Carousel3d: FCC<SwiperProps> = ({ children, ...swiperProps }) => {
  const ref = useRef<any>();

  const isXsUp = useMediaQuery(theme => theme.breakpoints.up('xs'));

  const buttonPrevRef = useRef<Nullable<HTMLButtonElement>>(null);
  const buttonNextRef = useRef<Nullable<HTMLButtonElement>>(null);

  const [swiperRef, setSwiperRef] = useState<Nullable<SwiperClass>>(null);

  const childrenCount = React.Children.count(children);

  const onPrevClick = () => {
    swiperRef?.slidePrev();
  };

  const onNextClick = () => {
    swiperRef?.slideNext();
  };

  useEffect(() => {
    if (ref.current && swiperRef) {
      if (swiperRef.params.coverflowEffect) {
        const depth = ref.current?.offsetWidth * 0.45;
        swiperRef.params.coverflowEffect.stretch = depth * 1.2;
        swiperRef.params.coverflowEffect.depth = depth;
        swiperRef.update();
      }
    }
  }, [ref, swiperRef]);

  return (
    <Wrapper ref={ref}>
      <StyledSwiper
        {...swiperProps}
        effect='coverflow'
        slidesPerView='auto'
        centeredSlides
        loop
        runCallbacksOnInit
        navigation={
          childrenCount
            ? {
                prevEl: isXsUp ? buttonPrevRef.current : null,
                nextEl: isXsUp ? buttonNextRef.current : null,
              }
            : false
        }
        modules={[...(swiperProps.modules ?? []), Autoplay, Navigation, EffectCoverflow]}
        coverflowEffect={{ rotate: 0, modifier: 1, slideShadows: false }}
        onSwiper={setSwiperRef}
      >
        {(children as [])?.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </StyledSwiper>
      {childrenCount > 1 && (
        <Hidden smDown>
          <ButtonPrev
            ref={buttonPrevRef}
            onClick={onPrevClick}
          >
            <AppSvgIcon icon={ArrowLeftIcon} />
          </ButtonPrev>
          <ButtonNext
            ref={buttonNextRef}
            onClick={onNextClick}
          >
            <AppSvgIcon icon={ArrowRightIcon} />
          </ButtonNext>
        </Hidden>
      )}
    </Wrapper>
  );
};

export default Carousel3d;
