import { Hidden } from '@mui/material';
import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import Splitter from '../../splitter';
import CarouselDefault from '../carouselDefault';
import useGetDefaultCarouselNavigation from '../defaultNavigation/useGetDefaultCarouselNavigation';
import CarouselThumbs from '../thumbs';
import { CarouselCommonWrapper, DefaultCarouselWrapper, ThumbCarouselWrapper } from './controls';

export type SwiperControlsProps = {
  readonly buttonPrevRef: MutableRefObject<Nullable<HTMLButtonElement>>;
  readonly onPrevClick: () => void;
  readonly buttonNextRef: MutableRefObject<Nullable<HTMLButtonElement>>;
  readonly onNextClick: () => void;
  readonly swiper: Nullable<SwiperClass>;
};

export type CarouselGalleryProps = {
  readonly children?: any[];
  readonly initSlide?: number;
  readonly mainSwiperContainerHeight?: number;
  readonly thumbSwiperContainerHeight?: number;
  readonly thumbScrollMode?: boolean;
  readonly mainSwiperProps?: SwiperProps;
  readonly thumbSwiperProps?: SwiperProps;
  readonly thumbSlidesPerView?: number;
  readonly showThumbs?: boolean;
  readonly swipersGap?: number;
  readonly thumbChildren?: JSX.Element[];
  readonly aspectRatio?: number;
  readonly onActiveIndexChange?: (activeIndex: number) => void;
};

const CarouselGallery = ({
  children,
  initSlide,
  showThumbs = true,
  // TODO(@Protopopov Ruslan): глючит при изменении, нужна реинициализация swiper
  thumbScrollMode = false,
  mainSwiperContainerHeight,
  thumbSwiperContainerHeight,
  swipersGap,
  thumbChildren,
  aspectRatio,
  onActiveIndexChange,
  mainSwiperProps = {
    spaceBetween: 10,
    navigation: false,
    pagination: false,
    loop: true,
  },
  thumbSwiperProps = {
    spaceBetween: 10,
    loop: true,
    slidesPerView: 4,
  },
}: CarouselGalleryProps) => {
  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<Nullable<SwiperClass>>(null);

  const childrenCount = React.Children.count(children);
  const detailSlides = children?.map((child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>) ?? [];
  const finalThumbChildren = thumbChildren ?? children;
  const thumbSlides = finalThumbChildren?.map((child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>);

  const finalShowThumbs = showThumbs && !!(thumbSlides?.length && thumbSlides.length > 1);

  const handlerChangeActiveIndex = (swiper: SwiperClass) => {
    onActiveIndexChange?.(swiper.realIndex);
  };

  const controlsElement = useGetDefaultCarouselNavigation({
    navigationEnabled: !!mainSwiperProps?.navigation,
    childrenCount,
    swiper,
  });

  useEffect(() => {
    if (initSlide) {
      swiper?.slideTo(initSlide, 0);
      thumbsSwiper?.slideTo(initSlide, 0);
    }
  }, [initSlide, swiper, thumbsSwiper]);

  const internalLoop = useMemo(() => {
    if (typeof thumbSwiperProps.slidesPerView === 'string') {
      return true;
    }

    if (!thumbSwiperProps?.slidesPerView || !thumbSlides?.length) {
      return false;
    }

    return thumbSlides.length >= thumbSwiperProps.slidesPerView;
  }, [thumbSlides?.length, thumbSwiperProps?.slidesPerView]);

  const internalThumbSwiperProps: SwiperProps = {
    ...thumbSwiperProps,
    loop: internalLoop,
  };

  return (
    <CarouselCommonWrapper>
      <DefaultCarouselWrapper height={mainSwiperContainerHeight}>
        <CarouselDefault
          controlsElement={controlsElement}
          swiperProps={mainSwiperProps}
          onSwiper={setSwiper}
          handlerChangeActiveIndex={handlerChangeActiveIndex}
          thumbsSwiper={thumbsSwiper}
          aspectRatio={aspectRatio}
        >
          {detailSlides}
        </CarouselDefault>
      </DefaultCarouselWrapper>

      {finalShowThumbs && (
        <Hidden
          smDown
          implementation='css'
        >
          <Splitter size={`${swipersGap}px`} />
          <ThumbCarouselWrapper height={thumbSwiperContainerHeight}>
            <CarouselThumbs
              onSwiper={setThumbsSwiper}
              thumbScrollMode={thumbScrollMode}
              swiperProps={internalThumbSwiperProps}
            >
              {thumbSlides}
            </CarouselThumbs>
          </ThumbCarouselWrapper>
        </Hidden>
      )}
    </CarouselCommonWrapper>
  );
};

export default CarouselGallery;
