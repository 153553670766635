import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';

type CartProductsNotFoundDialogProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const CartProductsNotFoundDialog = ({ open, onClose }: CartProductsNotFoundDialogProps) => {
  return (
    <MPConfirmDialog
      open={open}
      title='Извините, но некоторых товаров нет в наличии'
      maxWidth='xs'
      buttons={
        <MPButton
          size='large'
          fullWidth
          color='brand'
          variant='contained'
          onClick={onClose}
        >
          Проверить корзину
        </MPButton>
      }
      onClose={onClose}
    >
      <Typography>
        Состав корзины изменился, так как возможно товара больше нет в наличии или его недостаточного для вашего заказа
      </Typography>
    </MPConfirmDialog>
  );
};

export default CartProductsNotFoundDialog;
