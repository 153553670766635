import { useCallback } from 'react';
import useCurrentUserBalance from '../../../hooks/useCurrentUserBalance';
import { AppNotificationsReceiverCallbackProps, ENotificationMessageType } from '../../notification/types';
import useNotificationSocket from '../../notification/useNotificationSocket';

const UserNotificationsReceiver = () => {
  const { refreshBalance } = useCurrentUserBalance();

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>(
    msg => {
      switch (msg.type) {
        case ENotificationMessageType.UserBalanceChange:
          refreshBalance();
          return;
        default:
          return;
      }
    },
    [refreshBalance]
  );

  useNotificationSocket({ onMessageReceive });

  return null;
};

export default UserNotificationsReceiver;
