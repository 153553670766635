import React from 'react';

export type CorpOfferClaimDialogsContextType = {
  readonly openUnavailableDialog: () => void;
  readonly openNdflHelpDialog: () => void;
  readonly openCertificateDialog: () => void;
  readonly onShowAllOffers: () => void;
};

const CorpOfferClaimDialogsContext = React.createContext<CorpOfferClaimDialogsContextType>(
  null as unknown as CorpOfferClaimDialogsContextType
);

export default CorpOfferClaimDialogsContext;
