import styled from '@emotion/styled/macro';
import { css, tabClasses, tabsClasses, TabsProps } from '@mui/material';
import { MPTabs } from 'presentation/theme/ui-kit/tab';

type MPTabsProps = TabsProps & {
  readonly error: boolean;
};

export const GenderTabs = styled(({ error, ...props }: MPTabsProps) => <MPTabs {...props} />)(
  ({ theme, error }) => css`
    &.${tabsClasses.root}, .${tabsClasses.flexContainer} {
      height: 56px;
      border-radius: ${theme.spacing(1.5)};
      ${error && 'outline: 1px solid ' + theme.palette.error.main + ';'}
    }

    .${tabClasses.root} {
      min-width: 62px;

      ${theme.breakpoints.down('sm')} {
        min-width: auto;
      }
    }
  `
);
