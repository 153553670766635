import { EPartnerType } from 'domain/model/enums';
import { PartnerDesk } from 'domain/model/partner';
import AppImage from 'presentation/components/common/images/common';
import { innerPartnerLogoStub } from '../../utils';

type PartnerDeskLogoProps = {
  readonly partnerDesk: PartnerDesk;
};

const PartnerDeskLogo = ({ partnerDesk }: PartnerDeskLogoProps) => {
  const { image } = partnerDesk;

  if (image?.path) {
    return (
      <AppImage
        src={image}
        alt=''
      />
    );
  }

  if (!image?.path && partnerDesk.partner.type === EPartnerType.Inner) {
    return (
      <img
        src={innerPartnerLogoStub}
        alt=''
      />
    );
  }

  return null;
};
export default PartnerDeskLogo;
