import { Category } from 'domain/model/nsi';
import { SideMenuWrapper } from 'presentation/components/common/wrappers/menu';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { CategoryLink } from './controls';

type SubcategoriesProps = {
  readonly categories: Category[];
  readonly onCategoryClick: (id: string) => void;
};

const Subcategories = ({ categories, onCategoryClick }: SubcategoriesProps) => {
  return (
    <SideMenuWrapper elevation={1}>
      <MPGrid
        container
        direction='column'
      >
        {categories.map(c => (
          <CategoryLink
            key={c.id}
            onCategoryClick={onCategoryClick}
            id={c.id}
          >
            {c.name}
          </CategoryLink>
        ))}
      </MPGrid>
    </SideMenuWrapper>
  );
};

export default Subcategories;
