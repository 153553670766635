import AboutTextSectionsComponent from 'presentation/features/about/textSections/component';
import AboutSectionsLayout from 'presentation/layouts/aboutSections';
import { ReactNode } from 'react';
import AboutTabsComponent from '../components/tabs';
import { AboutInfoSection } from '../types';

type AboutTextSectionsContainerProps<T extends string> = {
  readonly title: string;
  readonly sections: AboutInfoSection<T>[];
  readonly subText?: string;
  readonly close?: ReactNode;
};

const Layout = AboutSectionsLayout;

const AboutTextSectionsContainer = <T extends string>({
  title,
  sections,
  subText,
  close,
}: AboutTextSectionsContainerProps<T>) => {
  return (
    <Layout
      title={title}
      description={subText}
      close={close}
      tabs={(current, onChangeTab) => (
        <AboutTabsComponent<AboutInfoSection<string>>
          value={current}
          tabs={sections}
          onChange={onChangeTab}
          title={title}
        />
      )}
    >
      {({ expandedItem, setSectionRef, onSectionToggle, isCollapsable }) => (
        <AboutTextSectionsComponent
          sections={sections}
          expanded={expandedItem}
          isCollapsable={isCollapsable}
          onToggle={onSectionToggle}
          setSectionRef={setSectionRef}
        />
      )}
    </Layout>
  );
};

export default AboutTextSectionsContainer;
