import { Typography, useTheme } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { forwardRef } from 'react';
import { Wrapper } from './controls';
import { OrderListItemLayoutProps } from './types';
import BannerBadge from '../../../../../components/common/badges/banner';

const OrderListItemLayout = forwardRef<any, OrderListItemLayoutProps>((props, ref) => {
  const { status, name, cost, requisites, onClick, icon } = props;

  const theme = useTheme();

  return (
    <BannerBadge
      icon={icon}
      backgroundColor={theme.palette.background.default}
    >
      <Wrapper
        ref={ref}
        onClick={onClick}
      >
        <MPGrid
          container
          spacing={1}
        >
          {status && (
            <MPGrid
              item
              zero={12}
            >
              <Typography component='div'>{status}</Typography>
            </MPGrid>
          )}
          <MPGrid
            item
            zero
          >
            <Typography
              variant='subtitle1'
              component='div'
            >
              {name}
            </Typography>
            {requisites && (
              <Typography
                color='textSecondary'
                component='div'
              >
                {requisites}
              </Typography>
            )}
          </MPGrid>
          {cost && (
            <MPGrid item>
              <Typography
                variant='subtitle1'
                component='div'
              >
                {cost}
              </Typography>
            </MPGrid>
          )}
        </MPGrid>
      </Wrapper>
    </BannerBadge>
  );
});

export default OrderListItemLayout;
