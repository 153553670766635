import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PriceRangeFilter } from 'domain/model/offer';
import { BookingOffersRequestServices } from 'data/openApi/user/bookingOffer';
import { BookingOffersListCategoryId, BookingOffersListServicesProps } from 'data/openApi/booking';
import { AppThunkAPIConfig } from 'data/store/store';
import apiDefinition from 'data/openApi';
import ErrorHandler from 'data/network/errorHandler';
import { bookingOffersDefaultParams } from '../../utils';
import { BookingOfferListFilterData, EBookingOfferListRequestType } from './types';
import { getRequestTypes } from './utils';

export type BookingOfferListFetchProps = Pick<BookingOffersListServicesProps, 'query'> &
  Pick<BookingOfferListFilterData, 'requestType' | 'priceRangeRequested'> &
  BookingOffersListCategoryId &
  Partial<PriceRangeFilter> &
  BookingOffersRequestServices;

// TODO убрать обратно бизнес-логику отсюда из из слайсов в хуки useBookingOfferList, оставив запросы createAsyncThunk
/** Входная точка для выполнения набора зависимых запросов фильтра */
export const bookingOfferListFilterFetch = createAsyncThunk<
  BookingOfferListFilterData,
  BookingOfferListFetchProps,
  AppThunkAPIConfig
>(
  'booking/fetch',
  async ({ requestType, query, minPrice, maxPrice, services: argsServices, categoryId }, { rejectWithValue }) => {
    try {
      const { isAll, isReset, isServices, isPrice } = getRequestTypes(requestType as EBookingOfferListRequestType);

      let selectedServices = argsServices;
      let leavingServiceId = null;
      let priceRange = bookingOffersDefaultParams.priceRange;
      let priceRangeRequested = null;
      let services = null;
      let allServices = null;

      // первичный вход в категорию - получение доступных категорий услуг в ней
      if (isAll) {
        let servicesData;

        const { data: categoriesFiltered } = await apiDefinition.booking.serviceCategories({
          categoryId,
          minPrice: minPrice ?? null,
          maxPrice: maxPrice ?? null,
          query,
        });

        // доступные категории с фильтром или без него
        services = categoriesFiltered;

        if (typeof minPrice !== 'number' && typeof maxPrice !== 'number') {
          allServices = categoriesFiltered;
          servicesData = categoriesFiltered;
          // все доступные категории при условии перехода по ссылке извне с установленным фильтром цен
        } else {
          const { data: categoriesFull } = await apiDefinition.booking.serviceCategories({
            categoryId,
            minPrice: null,
            maxPrice: null,
            query,
          });

          allServices = categoriesFull;
          servicesData = categoriesFull;
        }

        // Проверка на категорию Проживание и возврат её для установки в фильтр по-умолчанию
        const leavingServiceFound = servicesData?.find(({ displayPrice }) => displayPrice);
        if (leavingServiceFound && (argsServices === null || leavingServiceFound.id === argsServices?.[0])) {
          selectedServices = [leavingServiceFound.id];
          leavingServiceId = leavingServiceFound.id;
        }
      }

      // Запрос диапазона цен в случае первичного входа или при ручном изменении категорий услуг
      if (isAll || isReset || isServices) {
        const { data } = await apiDefinition.booking.priceRange({
          categoryId,
          // В случае уже установленных в аргументах фильтрах используем их, а не категорию Проживания
          services: argsServices || selectedServices,
          query,
        });

        priceRange = data;
      }

      // Запрос услуг при ручном изменении диапазона цен
      // Либо повторный запрос при наличии категории Проживание и после готовности запроса диапазона для актуализации фильтра
      if (isPrice || isServices || isReset || (isAll && leavingServiceId)) {
        const { data } = await apiDefinition.booking.serviceCategories({
          categoryId,
          minPrice: (isPrice || isServices ? minPrice : priceRange.minPrice) ?? null,
          maxPrice: (isPrice || isServices ? maxPrice : priceRange.maxPrice) ?? null,
          query,
        });

        services = data;
      }

      if (isAll && minPrice !== null && maxPrice !== null) {
        priceRangeRequested = { minPrice, maxPrice } as PriceRangeFilter;
      }

      return {
        services,
        allServices,
        leavingServiceId,
        priceRange,
        requestType,
        priceRangeRequested,
      };
    } catch (e) {
      const error = e as AxiosError;
      ErrorHandler.handleHttpErrorLegacy(error, error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);
