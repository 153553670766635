import { Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { getUserActivationsRoute } from '../../../../user/entry';

type OfferClaimSubscriptionProps = LinkProps;

const OfferClaimLinkToActivations = (props: OfferClaimSubscriptionProps) => {
  return (
    <Link
      component={RouterLink}
      color='primary'
      to={getUserActivationsRoute({})}
      {...props}
    >
      Перейти в «Мои привилегии»
    </Link>
  );
};

export default OfferClaimLinkToActivations;
