import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';
import { AccountBalance } from 'domain/model/user';

export const currentUserIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};

export const currentUserLocalityIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};

export const currentUserBalancesSelector = (store: RootState): Nullable<AccountBalance> => {
  const { data: value } = userApi.endpoints.getUserBalance.select()(store);

  return value ?? null;
};
