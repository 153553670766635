import styled from '@emotion/styled/macro';

export const PartnerWrapper = styled.div`
  padding: ${p => p.theme.spacing(2, 3)};
  border: 1px solid ${p => p.theme.palette.secondary.A600};
  border-radius: ${p => p.theme.spacing(2)};

  z-index: 1;
  cursor: pointer;

  max-width: 100%;

  &:hover {
    border-color: ${p => p.theme.palette.secondary.main};
  }
`;

export const LogoImageViewWrapper = styled.div`
  width: 2rem;
  height: 2rem;
`;
