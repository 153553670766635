import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AspOfferListDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { aspOfferListCommonArgsSelector, aspOfferListGuidSelector } from '../store/selectors';

type AspOfferListDataCommonAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Common;

export const AspOfferListDataCommonAdapter = ({ guid }: AspOfferListDataCommonAdapterProps) => {
  const currentGuid = useSelector(aspOfferListGuidSelector);
  const args = useSelector(aspOfferListCommonArgsSelector);

  const handlers = useContextHandlers();

  return useMemo(
    () =>
      //  проверка на разность guid при апдейте компонента
      //  если разные - не рендерим, чтобы не показывать старые данные
      guid !== currentGuid || !args ? null : (
        <AspOfferListDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangePage}
          onShowCard={handlers.onShowCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangePage, handlers.onShowCard]
  );
};
