import { FC } from 'react';
import { useSelector } from 'react-redux';
import BookingOfferModal from '../../components/modal';
import { bookingOfferDetailsModalSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';

const BookingOfferDetailsModalsAdapter: FC = () => {
  const modalItem = useSelector(bookingOfferDetailsModalSelector);

  const { onApplyModalChanges } = useContextHandlers();

  if (!modalItem) {
    return null;
  }

  return (
    <BookingOfferModal
      {...modalItem}
      isVisible={!!modalItem}
      onClose={onApplyModalChanges}
    />
  );
};

export default BookingOfferDetailsModalsAdapter;
