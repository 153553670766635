import { FCC, useEffect, useState } from 'react';
import { useAuthToken } from '../../auth/provider/useAuthToken';
import { ChangeAdapter, ConfirmAdapter } from './adapters';

enum EMode {
  Confirm = 'confirm',
  Change = 'change',
}

export const RequiredActionConfirmEmailContainer: FCC = ({ children }) => {
  const { tokenParsed } = useAuthToken();
  const isEmailNeedConfirmed = tokenParsed?.email_verified === false;

  const [mode, setMode] = useState<Nullable<EMode>>(null);

  const onClose = () => setMode(null);

  const onChangeEmail = () => setMode(EMode.Change);

  useEffect(() => {
    if (isEmailNeedConfirmed) {
      setMode(EMode.Confirm);
    }
  }, [isEmailNeedConfirmed]);

  return (
    <>
      {mode === EMode.Confirm && (
        <ConfirmAdapter
          onClose={onClose}
          onChangeEmail={onChangeEmail}
        />
      )}
      {mode === EMode.Change && <ChangeAdapter onClose={onClose} />}
      {children}
    </>
  );
};
