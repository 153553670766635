export enum EAspOfferClaimSlotNameArea {
  promotionType = 'promotionType',
  price = 'price',
  activation = 'activation',
  warnMessages = 'warnMessages',
  infoMessages = 'infoMessages',
  subscriptions = 'subscriptions',
  linkToActivations = 'linkToActivations',
}

export enum EAspOfferClaimDialogTag {
  Help = 'help',
  Unavailable = 'unavailable',
  Ndfl = 'ndfl',
}