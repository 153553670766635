import styled from '@emotion/styled/macro';
import { svgIconClasses } from '@mui/material';
import AppImage from '../common';

export const ImageStubContent = styled.div`
  text-align: center;
  color: ${p => p.theme.palette.secondary.main};

  & .${svgIconClasses.root} {
    font-size: 4.625rem;
    margin-bottom: ${p => p.theme.spacing(1)};
  }
`;

export const Image = styled(AppImage)`
  width: 100%;

  vertical-align: top;
`;

type CoverImageProps = {
  readonly border: boolean;
  readonly topRounded: boolean;
  readonly bottomRounded: boolean;
  readonly thumb: boolean;
  readonly fit: 'cover' | 'contain';
};

export const CoverImage = styled.div<CoverImageProps>`
  position: relative;
  overflow: hidden;
  border: 1px solid ${p => p.theme.palette.secondary.main};
  padding-bottom: 74.286%;

  ${p =>
    p.thumb &&
    `
    padding-bottom: 67.567%;
  `};

  ${p =>
    !p.border &&
    `
    border: 0;
  `};

  ${p =>
    p.topRounded &&
    `
    border-top-left-radius: ${p.theme.shape.borderRadius * 1.2}px;
    border-top-right-radius: ${p.theme.shape.borderRadius * 1.2}px;
  `};

  ${p =>
    p.bottomRounded &&
    `
    border-bottom-left-radius: ${p.theme.shape.borderRadius * 1.2}px;
    border-bottom-right-radius: ${p.theme.shape.borderRadius * 1.2}px;
  `};

  ${ImageStubContent} {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: ${p => p.theme.spacing(2)};
  }

  img {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: ${p => p.fit};
    object-position: center center;
  }
`;

export const LogoImage = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: contain;
    object-position: center center;
  }
`;
