import AboutTextSectionsContainer from '../textSections/container';
import AboutCookiesCommonInfo from './info/common';
import AboutCookiesWhatAreCookiesInfo from './info/whatAreCookies';
import AboutCookiesHowDoWeUseInfo from './info/howDoWeUse';
import AboutCookiesHowDoWeProcessInfo from './info/howWeProcess';
import AboutCookiesHowToOptOutInfo from './info/howToOptOut';

enum ESection {
  Common = 'common',
  WhatAreCookiesInfo = 'whatAreCookiesInfo',
  HowDoWeUse = 'howDoWeUse',
  HowDoWeProcess = 'howDoWeProcess',
  HowToOptOut = 'howToOptOut',
}

const AboutCookiesContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Политика использования файлов cookie'
      sections={[
        {
          id: ESection.Common,
          name: '',
          component: AboutCookiesCommonInfo,
        },
        {
          id: ESection.WhatAreCookiesInfo,
          name: 'Что такое Cookies?',
          component: AboutCookiesWhatAreCookiesInfo,
        },
        {
          id: ESection.HowDoWeUse,
          name: 'Как мы используем Cookies?',
          component: AboutCookiesHowDoWeUseInfo,
        },
        {
          id: ESection.HowDoWeProcess,
          name: 'Как мы обрабатываем ваши Cookies?',
          component: AboutCookiesHowDoWeProcessInfo,
        },
        {
          id: ESection.HowToOptOut,
          name: 'Как отказаться от обработки Сookies?',
          component: AboutCookiesHowToOptOutInfo,
        },
      ]}
    />
  );
};

export default AboutCookiesContainer;
