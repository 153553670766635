import { useMemo } from 'react';
import { AppConfigurator } from 'system/appConfigurator';
import BusinessConfigContext from '../contexts/businessConfigContext';

type BusinessConfigProviderProps = {
  readonly children: any;
};

const BusinessConfigProvider = ({ children }: BusinessConfigProviderProps) => {
  const state = useMemo(() => {
    const configurator = AppConfigurator.getInstance();
    return {
      corpEarningsSystemUrl: configurator.getCorpEarningsSystemUrl(),
      corpLinkProxyUrl: configurator.getCorpLinkProxyUrl(),
      supportInfo: configurator.getSupportInfo(),
    };
  }, []);

  return <BusinessConfigContext.Provider value={state}>{children}</BusinessConfigContext.Provider>;
};

export default BusinessConfigProvider;
