import { DrawerProps } from '@mui/material';
import { unstable_getScrollbarSize as getScrollbarSize } from '@mui/utils';
import { useHeaderParams } from '../../../../hooks/useHeaderParams';
import { Container, Drawer } from './controls';

export type MPTopPushDialogProps = DrawerProps & {
  readonly title?: any;
  readonly onHeader?: boolean;
};

export const MPTopPushDialog = (props: MPTopPushDialogProps) => {
  const { title, onHeader, children, ...others } = props;
  const { headerHeight } = useHeaderParams();

  const scrollbarSize = getScrollbarSize(window.document);

  return (
    <Drawer
      variant='temporary'
      elevation={0}
      {...others}
      disableAutoFocus
      disableEnforceFocus
      anchor='top'
      offsetTop={headerHeight}
      offsetRight={scrollbarSize}
    >
      <Container>{children}</Container>
    </Drawer>
  );
};
