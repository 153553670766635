import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SearchOffersRequest } from 'data/api/search';
import { ETradeOfferSortType } from 'domain/model/enums';

export type SearchOffersState = {
  readonly guid: Nullable<UUID>;
  readonly isNewFetching: boolean;
  readonly args: Nullable<SearchOffersRequest>;
};

type Reducer<T> = CaseReducer<SearchOffersState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<SearchOffersState> & {
  searchOffersStartSession: Reducer<{ guid: UUID; args: SearchOffersRequest }>;
  searchOffersSetPageSize: Reducer<number>;
  searchOffersSetPage: Reducer<number>;
  searchOffersSetSort: Reducer<ETradeOfferSortType[]>;
  searchOffersSetArgs: Reducer<SearchOffersRequest>;
  searchOffersSetIsNewFetching: Reducer<boolean>;
};

const slice = createSlice<SearchOffersState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    args: null,
  },
  reducers: {
    searchOffersStartSession: (state, { payload }) => {
      const { guid, args } = payload;
      state.guid = guid;
      state.isNewFetching = true;
      state.args = args;
    },
    searchOffersSetArgs: (state, { payload }) => {
      state.args = payload;
    },
    searchOffersSetPageSize: (state, { payload }) => {
      if (state.args) {
        state.args.pageSize = payload;
      }
    },
    searchOffersSetSort: (state, { payload }) => {
      if (state.args) {
        state.args.sort = payload;
      }
    },
    searchOffersSetPage: (state, { payload }) => {
      if (state.args) {
        state.args.page = payload;
      }
    },
    searchOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
  },
});

export const {
  searchOffersSetPageSize,
  searchOffersSetPage,
  searchOffersStartSession,
  searchOffersSetSort,
  searchOffersSetIsNewFetching,
  searchOffersSetArgs,
} = slice.actions;

export default slice.reducer;
