import SvgIcon from '@mui/material/SvgIcon';
import AppSvgIcon from '../../../../components/common/icon';
import { MPFab } from '../../../../theme/ui-kit/button';

export type HeaderActionProps = {
  readonly active: boolean;
  readonly icon: typeof SvgIcon;
  readonly onClick: () => void;
};

const HeaderAction = ({ icon, active, onClick }: HeaderActionProps) => {
  return (
    <MPFab
      color='secondaryLight'
      size='medium'
      variant='squared'
      onClick={onClick}
    >
      <AppSvgIcon
        icon={icon}
        fontSize='medium'
        color={active ? 'primary' : 'inherit'}
      />
    </MPFab>
  );
};

export default HeaderAction;
