import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep1 = () => {
  return (
    <>
      <Text>
        1. Настоящая Политика, разработанная в соответствии с Федеральным законом «О персональных данных», устанавливает
        цели, основные принципы, правила и правовые основания обработки персональных данных, а также определяет основные
        меры по обеспечению их безопасности.
      </Text>
      <Text>
        2. Настоящая Политика разработана для реализации в ОАО «РЖД» требований законодательства Российской Федерации в
        области персональных данных, а также обеспечения защиты прав физических лиц при обработке их персональных
        данных.
      </Text>
      <Text>
        3. Положения настоящей Политики являются основой для разработки и актуализации распорядительных и
        организационно-правовых документов ОАО «РЖД», регламентирующих процессы обработки персональных данных различных
        категорий субъектов персональных данных, а также порядок реализации мер для защиты обрабатываемых персональных
        данных.
      </Text>
      <Text>
        4. Положения настоящей Политики обязательны для исполнения работниками ОАО «РЖД», имеющими доступ к персональным
        данным.
      </Text>
      <Text>
        5. Перечень целей обработки персональных данных в ОАО «РЖД», состава персональных данных и их категорий, а также
        категорий субъектов персональных данных предусмотрен приложением.
      </Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep1;
