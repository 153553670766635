import { useEffect } from 'react';

export interface UseIsVisibleOnceProps extends IntersectionObserverInit {
  //ключ сессии, в рамках которой фиксируются уникальные показы
  readonly sessionKey: string;
}

//разово фиксирует показ элемента, разовость обеспечена атрибутом sessionKey
const useIsVisibleOnce = <T>(
  ref: any,
  options: UseIsVisibleOnceProps,
  active: boolean,
  data: T,
  onShow?: (data: T) => void
) => {
  useEffect(() => {
    if (!active || !onShow) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onShow(data);
        observer.unobserve(ref.current);
        observer.disconnect();
      }
    }, options);

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, options, data, active, onShow]);
};

export default useIsVisibleOnce;
