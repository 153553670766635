import styled from '@emotion/styled/macro';
import { Container, Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const Wrapper = styled(Container)`
  padding-top: 5rem;
`;

export const CodeWrapper = styled(Typography)`
  font-size: 0.7rem;
  font-family: Monaco, MonoSpace, serif;
`;

export const StyledButton = styled(MPButton)`
  :hover {
    text-decoration: none;
  }
` as typeof MPButton;
