import { UserSettingsContext, UserSettingsContextType } from 'presentation/features/user/current/userSettingsProvider';
import { useContext } from 'react';

type UseUserLocalSettingsType = () => UserSettingsContextType;

const useUserLocalSettings: UseUserLocalSettingsType = () => {
  return useContext<UserSettingsContextType>(UserSettingsContext);
};

export default useUserLocalSettings;
