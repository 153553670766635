import { Typography } from '@mui/material';
import * as H from 'history';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../media/icons';
import { BreadcrumbItemWrapper } from './controls';

type BreadcrumbItemProps = {
  readonly name: string;
  readonly hidden?: boolean;
  readonly link?: string | H.Location;
};

export const BreadcrumbItem = ({ name, link, hidden }: BreadcrumbItemProps) => {
  return (
    <BreadcrumbItemWrapper hidden={hidden}>
      <MPChip
        label={<Typography variant='body2'>{name}</Typography>}
        size={'micro'}
        clickable={!!link}
        component={Link}
        icon={<ArrowLeftIcon />}
        to={link ?? ''}
      />
    </BreadcrumbItemWrapper>
  );
};
