import CartDetailsContainer from 'presentation/features/cart/details/container';
import ScreenLayout from 'presentation/layouts/screen';
import { v4 as uuidv4 } from 'uuid';

//гуид корзины постоянный, чтобы она была типа стейтфулл по своему состоянию
const guid = uuidv4();

const CartDetailsScreen = () => {
  return (
    <ScreenLayout footer>
      <CartDetailsContainer guid={guid} />
    </ScreenLayout>
  );
};

export default CartDetailsScreen;
