import { lighten } from '@mui/material';
import { darken } from '@mui/material/styles';
import { CustomPaletteOptions, PaletteOptions } from '@mui/material/styles/createPalette';
import { brandbook } from '../brandBook';
import { OverrideThemeFunc } from './types';

const palette: OverrideThemeFunc<PaletteOptions> = (_, externalTheme): CustomPaletteOptions => {
  const whiteColor = brandbook.color.white;
  const blackColor = brandbook.color.black;
  const grayColor = brandbook.color.gray;
  const blueColor = brandbook.color.blue;
  const redColor = brandbook.color.red;
  const orangeColor = brandbook.color.orange;
  const greenColor = brandbook.color.green;

  const primaryColor = externalTheme?.color?.primary?.main || blueColor;
  const primaryContrastTextColor = externalTheme?.color?.primary?.contrastText || whiteColor;
  const brandColor = externalTheme?.color?.brand?.main || redColor;
  const brandContrastTextColor = externalTheme?.color?.brand?.contrastText || whiteColor;
  const secondaryColor = externalTheme?.color?.secondary?.main || grayColor;
  const secondaryContrastTextColor = externalTheme?.color?.secondary?.contrastText || whiteColor;
  const backgroundColor = externalTheme?.color?.background?.main || colorLighten(secondaryColor, 0.96);

  return {
    common: {
      white: whiteColor,
      black: blackColor,
    },
    background: {
      default: backgroundColor,
      paper: whiteColor,
      tabs: colorLighten(secondaryColor, 0.9),
      carouselNavigation: colorLighten(whiteColor, 0.6),
    },
    text: {
      primary: blackColor,
      secondary: secondaryColor,
    },
    white: {
      main: whiteColor,
      dark: darken(whiteColor, 0.05),
      light: lighten(whiteColor, 0.05),
      contrastText: blackColor,
    },
    black: {
      main: blackColor,
      dark: darken(blackColor, 0.05),
      light: lighten(blackColor, 0.15),
      contrastText: whiteColor,
    },
    primary: {
      main: primaryColor,
      dark: primaryColor,
      light: primaryColor,
      A400: colorLighten(primaryColor, 0.96),
      A500: colorLighten(primaryColor, 0.95),
      A600: colorLighten(primaryColor, 0.95),
      A900: colorLighten(primaryColor, 0.44),
      contrastText: primaryContrastTextColor,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryColor,
      light: secondaryColor,
      A400: colorLighten(secondaryColor, 0.96),
      A500: colorLighten(secondaryColor, 0.95),
      A600: colorLighten(secondaryColor, 0.9),
      A700: colorLighten(secondaryColor, 0.96),
      A900: colorLighten(secondaryColor, 0.77),
      contrastText: secondaryContrastTextColor,
    },
    brand: {
      main: brandColor,
      dark: brandColor,
      light: brandColor,
      A400: colorLighten(brandColor, 0.96),
      A500: colorLighten(brandColor, 0.95),
      A900: colorLighten(brandColor, 0.49),
      contrastText: brandContrastTextColor,
    },
    error: {
      main: redColor,
    },
    warning: {
      main: orangeColor,
      light: colorLighten(orangeColor, 0.96),
      contrastText: whiteColor,
    },
    success: {
      main: greenColor,
      contrastText: whiteColor,
    },
  };
};

/**
 * @param color цвет
 * @param coefficient диапазон 0-1
 */
const colorLighten = (color: string, coefficient: number): string => {
  if (isHslColors(color)) {
    const [h, s] = parseHslColor(color);
    return `hsl(${h},${s},${coefficient * 100}%)`;
  }
  return lighten(color, coefficient);
};

/**
 * @param color1 цвет 1
 * @param color2 цвет 2
 */
export const colorGradientCalculator = (color1: string, color2: string): string[] => {
  if (isHslColors(color1, color2)) {
    const [h1, s1, l1] = parseHslColor(color1);
    const [h4, s4, l4] = parseHslColor(color2);

    const [h2, s2, l2] = [h1, `${parseFloat(s1) * 1.1}%`, `${parseFloat(l1) * 1.1}%`];
    const [h3, s3, l3] = [h4, `${parseFloat(s4) * 1.1}%`, `${parseFloat(l4) * 1.1}%`];

    return [`hsl(${h1},${s1},${l1})`, `hsl(${h2},${s2},${l2})`, `hsl(${h3},${s3},${l3})`, `hsl(${h4},${s4},${l4})`];
  }
  return [color1, color2];
};

/**
 * @param color1 цвет 1
 * @param color2 цвет 2
 * @param coefficient1 диапазон 0-1
 * @param coefficient2 диапазон 0-1
 */
export const colorGradientLightenCalculator = (
  color1: string,
  color2: string,
  coefficient1: number,
  coefficient2?: number
): string[] => {
  if (isHslColors(color1, color2)) {
    return [colorLighten(color1, coefficient1), colorLighten(color2, coefficient2 ?? coefficient1)];
  }
  return [color1, color2];
};

export const isHslColors = (...colors: string[]): boolean => {
  return colors.every(color => color.indexOf('hsl') === 0);
};

export const parseHslColor = (color: string): string[] => {
  return color
    .replaceAll(/[(hsl\\()|(hsla\\()|(\\))]/g, '')
    .replaceAll(' ', '')
    .split(',');
};

export default palette;
