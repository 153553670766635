import { useGetUserFavoritesProductOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { FC, useEffect } from 'react';
import ProductOfferCard from '../../../../offer/product/components/card';
import { TabProps } from './types';

export const UserFavoritesProductOffersContainer: FC<TabProps> = ({ queryGuid }) => {
  const { data, error: productOffersError } = useGetUserFavoritesProductOffersQuery({
    guid: queryGuid,
  });
  const productOffers = data?.data;

  /*const {
    isFetching: isAddToFavoritesFetching,
    isRemoved: isFavoriteRemoved,
    canAdd,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
    },
    clearAfterUnmount: false,
  });*/

  useEffect(() => {
    if (productOffersError) {
      ErrorHandler.handleHttpError(productOffersError);
    }
  }, [productOffersError]);

  if (!productOffers) {
    return null;
  }

  return (
    <CardList span={4}>
      {productOffers.map(offer => (
        <ProductOfferCard
          key={offer.id}
          product={offer}
          /*toRemove={isFavoriteRemoved(EOfferType.Product, offer)}
          favorite={{
            position: EProductOfferFavoritePosition.Details,
            active: canAdd(EOfferType.Product, offer) && isFavorite(EOfferType.Product, offer),
            inProgress: isAddToFavoritesFetching(offer.id),
            onClick: () => addOrRemoveFavorite(EOfferType.Product, offer),
          }}*/
        />
      ))}
    </CardList>
  );
};
