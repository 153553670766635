import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';
import { getBonusConfig } from 'presentation/utils';
import techStub from 'presentation/media/images/techStub.png';
import { FCC } from 'react';

const CorpOfferBonusUnavailableAdapter: FCC = ({ children }) => {
  const unavailableReason = getBonusConfig()?.unavailableReason;

  if (unavailableReason) {
    return (
      <OfferDetailsErrorLayout
        text={unavailableReason}
        stubImage={techStub}
      />
    );
  }

  return children;
};

export default CorpOfferBonusUnavailableAdapter;
