import styled from '@emotion/styled';
import { CarouselCommonWrapper } from '../../../../../../components/common/carousels/gallery/controls';

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${CarouselCommonWrapper} {
    //max-height: 80vh;
  }
`;
