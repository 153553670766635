import { getFileDownloadEndpoint } from 'data/openApi/utils';
import { CameraIcon, TrashIcon } from 'presentation/media/icons';
import { ChangeEventHandler, FC } from 'react';
import Notifier from 'system/notifier';
import { convertBytesToUnitText } from 'utils/files';
import { Button, FileSelector, PhotoBorder, PhotoWrapper, Wrapper } from './controls';

type UserPhotoSelectorProps = {
  readonly src?: Nullable<string>;
  readonly onChange?: (file: Nullable<File>) => void;
  readonly onClear?: () => void;
};

export const fileTypes = ['image/png', 'image/jpeg'];
export const fileMaxSize = 1 * 1024 * 1024;

export const UserPhotoSelector: FC<UserPhotoSelectorProps> = ({ src, onChange, onClear }) => {
  const source = src ? getFileDownloadEndpoint({ id: src, private: true }) : null;

  const onChangeInternal: ChangeEventHandler<HTMLInputElement> = event => {
    const file: Nullable<File> = event.target.files?.[0] ?? null;

    if (!file) {
      onChange?.(null);
      return;
    }

    const fileType = file.type;

    if (fileTypes.length > 0 && !fileTypes.includes(fileType)) {
      Notifier.getInstance().addError(`Недопустимый тип файла. Разрешены к загрузке ${fileTypes.join(', ')}.`);
      return;
    }

    if (fileMaxSize && file.size > fileMaxSize) {
      Notifier.getInstance().addError(`Максимальный размер файла ${convertBytesToUnitText(fileMaxSize)}`);
      return;
    }

    onChange?.(file);

    event.target.setAttribute('type', 'text');
    event.target.setAttribute('type', 'file');
  };

  return (
    <>
      <Wrapper>
        {onChange && (
          <Button color='secondary'>
            <CameraIcon color='primary' />
            <FileSelector
              type='file'
              onChange={onChangeInternal}
            />
          </Button>
        )}
        <PhotoBorder>
          <PhotoWrapper>
            {source && (
              <img
                src={source}
                alt='profile_photo'
              />
            )}
          </PhotoWrapper>
        </PhotoBorder>
        {onClear && (
          <Button
            color='secondary'
            onClick={onClear}
          >
            <TrashIcon color='error' />
          </Button>
        )}
      </Wrapper>
    </>
  );
};
