import styled from '@emotion/styled';
import { GridProps } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { forwardRef } from 'react';

export const IFrameWrapper = styled(
  forwardRef<any, GridProps>((props, ref) => (
    <MPGrid
      {...props}
      ref={ref}
    />
  ))
)`
  width: 100%;
  flex: 1;
  text-align: center;

  ${p => p.theme.breakpoints.up('md')} {
    align-self: center;
  }
`;

export const IFrame = styled.iframe`
  border: none;
  outline: none;
`;
