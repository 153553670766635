import { combineReducers } from '@reduxjs/toolkit';
import provide, { FavoritesProvideState } from './provide/store/slice';

type FavoritesState = {
  readonly provide: FavoritesProvideState;
};

export default combineReducers<FavoritesState>({
  provide,
});
