import { TextFieldProps } from '@mui/material';
import { KeyboardEvent, useEffect, useState } from 'react';
import CountInput from '../../../../components/common/countInput';

type CartCounterProps = {
  readonly size: TextFieldProps['size'];
  readonly count: number;
  readonly loading?: boolean;
  readonly max: number;
  readonly disabled?: boolean;
  readonly isEmpty: boolean;
  readonly isWarn?: boolean;
  readonly onDecrement: () => void;
  readonly onIncrement: () => void;
  readonly onChange: (count: number) => void;
};

const CartCounter = (props: CartCounterProps) => {
  const { size, count, max, disabled = false, loading = false, isEmpty, onChange, onDecrement, onIncrement } = props;
  const [stayOpened, setStayOpened] = useState(false);

  const [localCount, setLocalCount] = useState(count);

  useEffect(() => {
    setLocalCount(count);
  }, [count]);

  const handleIncrement = () => {
    onIncrement();
  };

  const handleDecrement = () => {
    if (isEmpty) {
      return;
    }
    onDecrement();
  };

  const handleChangeCount = (value: number) => {
    setLocalCount(value);
  };

  const onBlur = () => {
    if (localCount !== count) {
      onChange(localCount);
    }
    setStayOpened(false);
  };

  const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && localCount !== count) {
      onChange(localCount);
    }
  };

  return (
    <CountInput
      min={0}
      max={max}
      size={size}
      disabled={disabled}
      loading={loading}
      value={count}
      isEmpty={isEmpty}
      stayOpened={stayOpened}
      onKeyPress={onPressEnter}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
      onBlur={onBlur}
      onChangeCount={handleChangeCount}
    />
  );
};

export default CartCounter;
