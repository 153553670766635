import PartnerDeskDetailsAboutLayout from './layout';

type PartnerDeskDetailsTabAboutCompanyProps = {
  readonly about: string;
};

const Layout = PartnerDeskDetailsAboutLayout;

export const PartnerDeskDetailsTabAboutCompany = ({ about }: PartnerDeskDetailsTabAboutCompanyProps) => {
  return <Layout>{about}</Layout>;
};
