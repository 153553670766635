import { useSelector } from 'react-redux';
import { searchOffersNoDataSelector } from '../store/selectors';
import SerarchOffersStub from '../stub/common';

const SearchOffersStubAdapter = () => {
  const noData = useSelector(searchOffersNoDataSelector);

  if (noData) {
    return <SerarchOffersStub />;
  }

  return null;
};

export default SearchOffersStubAdapter;
