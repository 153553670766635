import { skipToken } from '@reduxjs/toolkit/query';
import { useGetUserActivationsCountQuery } from 'data/api/user';
import { useAuth } from 'presentation/features/auth/provider/useAuth';

const useActivationsCounter = () => {
  const { isAuthenticated } = useAuth();
  const { data } = useGetUserActivationsCountQuery(isAuthenticated ? {} : skipToken);

  return data ?? 0;
};

export default useActivationsCounter;
