import { EBannerPartition } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BannersMainTopContainer from '../../../../banners/mainTop/container';
import { corpOfferListSearchIsEmptySelector } from '../store/selectors';

type CorpOfferListBannersAdapterProps = {
  readonly guid: UUID;
};

export const CorpOfferListBannersAdapter = ({ guid }: CorpOfferListBannersAdapterProps) => {
  const isEmptySearch = useSelector(corpOfferListSearchIsEmptySelector);

  return useMemo(
    () =>
      isEmptySearch ? (
        <BannersMainTopContainer
          guid={guid}
          partition={EBannerPartition.CorpOffers}
        />
      ) : null,
    [guid, isEmptySearch]
  );
};
