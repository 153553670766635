import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type AspOfferClaimState = {
  readonly dialogs: {
    updateUserPhone: boolean;
  };
};

type Reducer<T = undefined> = CaseReducer<AspOfferClaimState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AspOfferClaimState> & {
  aspOfferClaimClear: Reducer;
  aspOfferClaimChangeDialogState: Reducer<{
    name: keyof AspOfferClaimState['dialogs'];
    data: boolean;
  }>;
};

const dialogsInitialState: AspOfferClaimState['dialogs'] = {
  updateUserPhone: false,
};

const slice = createSlice<AspOfferClaimState, Reducers, 'claim'>({
  name: 'claim',
  initialState: {
    dialogs: dialogsInitialState,
  },
  reducers: {
    aspOfferClaimClear: state => {
      state.dialogs = dialogsInitialState;
    },
    aspOfferClaimChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
});

export const { aspOfferClaimClear, aspOfferClaimChangeDialogState } = slice.actions;

export default slice.reducer;
