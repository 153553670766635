import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Children, FCC, ReactNode } from 'react';
import { FooterWrapper, Wrapper } from './controls';

type FooterGroupProps = {
  readonly label: string;
  readonly footer?: ReactNode;
};

const FooterGroup: FCC<FooterGroupProps> = props => {
  const { label, footer, children } = props;

  return (
    <Wrapper>
      <MPGrid
        container
        spacing={1}
        direction='column'
      >
        <MPGrid item>
          <Typography
            component='div'
            variant='subtitle2'
          >
            {label}
          </Typography>
        </MPGrid>
        {Children.toArray(children).map((child, index) => (
          <MPGrid
            key={index}
            item
          >
            <Typography
              component='div'
              variant='body2'
            >
              {child}
            </Typography>
          </MPGrid>
        ))}
      </MPGrid>
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </Wrapper>
  );
};

export default FooterGroup;
