import { FreeMode, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';
import { Swiper, SwiperProps } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { DetailSwiperWrapper } from './controls';

type CarouselDefaultProps = {
  readonly swiperProps: SwiperProps;
  readonly onSwiper: (swiper: SwiperClass) => void;
  readonly thumbsSwiper: Nullable<SwiperClass>;
  readonly handlerChangeActiveIndex: (swiper: SwiperClass) => void;
  readonly children: JSX.Element[];
  readonly controlsElement: Nullable<JSX.Element>;
  readonly aspectRatio?: number;
};

const CarouselDefault = (props: CarouselDefaultProps) => {
  const { swiperProps, thumbsSwiper, handlerChangeActiveIndex, onSwiper, children, controlsElement, aspectRatio } =
    props;

  return (
    <DetailSwiperWrapper aspectRatio={aspectRatio}>
      <Swiper
        {...swiperProps}
        navigation={false}
        loop={children?.length > 1 ? swiperProps.loop : false}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        runCallbacksOnInit
        modules={[...(swiperProps?.modules ?? []), FreeMode, Navigation, Thumbs, Pagination, Mousewheel, Scrollbar]}
        onSwiper={onSwiper}
        onSlideChange={handlerChangeActiveIndex}
      >
        {children}
      </Swiper>
      {controlsElement}
    </DetailSwiperWrapper>
  );
};

export default CarouselDefault;
