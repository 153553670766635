import { Link } from '@mui/material';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import rootRouting from '../../../../../routing';
import { getPulseConfig, getSiteCaption, getSupportInfo } from '../../../../utils';
import { Text } from './controls';

const AboutTermsOfUseRegistrationInfo = () => {
  const siteCaption = useMemo(
    () => (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        component={RouterLink}
        to={rootRouting.root}
      >
        {getSiteCaption()}
      </Link>
    ),
    []
  );

  const pulseUrl = useMemo(() => {
    const { url } = getPulseConfig();
    return url ? (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={url}
      >
        {url}
      </Link>
    ) : (
      'ссылка не настроена'
    );
  }, []);

  const pulseSupportEmail = useMemo(() => {
    const email = getPulseConfig()?.supportEmail;
    return (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    );
  }, []);

  const privilegeSupportEmail = useMemo(() => {
    const email = getSupportInfo()?.email;
    return (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    );
  }, []);

  return (
    <>
      <Text>
        2.1. Для того чтобы воспользоваться Платформой Пользователю необходимо пройти процедуру регистрации, в
        результате которой для Пользователя будет создана уникальная учетная запись. Пользователь, достигший возраста 18
        лет, вправе самостоятельно производить регистрацию учетной записи и использовать Платформу в пределах
        правоспособности, установленной применимым законодательством. В случае если Пользователь не достиг указанного
        возраста, а также в случаях, когда этого требует законодательство Российской Федерации, использование Платформы
        допускается только с согласия родителей или иных законных представителей.
      </Text>
      <Text>
        2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам,
        предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии.
      </Text>
      <Text>
        При регистрации Пользователь может загрузить изображение для учетной записи (аватар). В случае использования в
        качестве изображения учетной записи изображения Пользователя, Пользователь осознает и соглашается с тем, что
        Правообладатель вправе проводить опубликование и дальнейшее использование изображения Пользователя для
        использования в Платформе.
      </Text>
      <Text>
        2.3. Персональные данные Пользователя, определенные Политикой обработки персональных данных и содержащиеся в
        учетной записи Пользователя, хранятся и обрабатываются Правообладателем в соответствии с условиями Политики
        обработки персональных данных ({pulseUrl}, {siteCaption}). Правообладатель обязуется собирать, хранить и
        использовать Персональные данные Пользователя в соответствии с Федеральным законом от 27 июля 2006 г. № 152 ФЗ
        «О персональных данных» и внутренними документами Правообладателя.
      </Text>
      <Text>
        2.3.1. Правообладатель получает, хранит и использует следующие персональные данные пользователя: контактная
        информация, платежную информацию, данные о месте работы (только для работников ОАО «РЖД»), идентификация
        личности и устройства (информация, собираемая платформой для выполнения функций), история покупок, данные об
        использовании Платформы (аналитика работы и рекламная аналитика).
      </Text>
      <Text>
        2.3.2. Правообладатель получает, хранит и использует персональные данные пользователя указанные в статье 2.3.1.
        настоящего Соглашения в целях ведения рекламной деятельности, аналитической работы, персонализации Платформы,
        развития функциональности Платформы.
      </Text>
      <Text>2.4. Средства для доступа к учетной записи Пользователя.</Text>
      <Text>
        2.4.1. При регистрации Пользователь самостоятельно выбирает себе логин (уникальное символьное имя учетной записи
        Пользователя) и пароль для доступа к учетной записи. Платформа вправе запретить использование определенных
        логинов, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.).
      </Text>
      <Text>
        2.4.1.1. При регистрации Пользователю доступна возможность регистрации и последующего доступа к учетной записи
        путем предоставления третьей стороне права передачи персональной информации Пользователя Правообладателю
        (регистрация и вход с использованием сторонних приложений).
      </Text>
      <Text>
        2.5. Пользователь самостоятельно несет ответственность за безопасность (в том числе устойчивость к угадыванию)
        выбранных им средств для доступа к учетной записи, а также самостоятельно обеспечивает их конфиденциальность.
        Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
        использованием Платформы под учетной записью Пользователя, включая случаи добровольной передачи Пользователем
        данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или
        соглашениям).
      </Text>
      <Text>
        2.5.1. В целях безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под
        своей учетной записью по окончании каждой сессии использования Платформы. Правообладатель не отвечает за
        возможную потерю, утечку или порчу данных, а также другие последствия любого характера, которые могут произойти
        из-за нарушения настоящего пункта.
      </Text>
      <Text>2.6. Использование Пользователем своей учетной записи.</Text>
      <Text>
        2.6.1. Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать, а также
        использовать для каких-либо коммерческих целей какие-либо части Платформы (включая контент, доступный
        Пользователю посредством Платформы), или доступ к ним, кроме тех случаев, когда Пользователь получил такое
        разрешение от Правообладателя.
      </Text>
      <Text>
        2.7. Прекращение регистрации. Правообладатель вправе заблокировать или удалить учетную запись Пользователя, а
        также запретить доступ с использованием какой-либо учетной записи к Платформе без объяснения причин, в том числе
        в случае нарушения Пользователем условий Соглашения.
      </Text>
      <Text>2.8. Удаление учетной записи Пользователя.</Text>
      <Text>2.8.1. Пользователь вправе в любой момент удалить свою учетную запись в Платформе.</Text>
      <Text>
        2.8.2. Для удаления собственной учетной записи из Платформы Пользователь должен отправить обращение по адресу{' '}
        {pulseSupportEmail} или {privilegeSupportEmail}. В обращении следует указать уникальный идентификатор учетной
        записи, а также предоставить подтверждение принадлежности учетной записи автору обращения, указав текущий e-mail
        адрес.
      </Text>
    </>
  );
};
export default AboutTermsOfUseRegistrationInfo;
