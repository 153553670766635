import { ButtonProps } from '@mui/material';
import { StyledButtonLink } from './controls';

export const ButtonLink = (props: ButtonProps) => {
  return (
    <StyledButtonLink
      {...props}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      variant='text'
    />
  );
};
