import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { BookingOfferShort, BookingOrder, BookingOrderRequest } from 'domain/model/bookingOffer';
import { EOfferStatus, EOrderRequestDiscriminator } from 'domain/model/enums';
import { Category, CategoryTree } from 'domain/model/nsi';
import { PriceRangeFilter } from 'domain/model/offer';
import { OfferListRequest } from '../../api';
import { ApiCancellable, ApiRequestPageable } from '../types';
import { createCancelToken, getUserQueryParams } from '../utils';

export type BookingOffersRequestServices = {
  readonly services?: Nullable<UUID[]>;
};

export type BookingOffersRequestProps = PriceRangeFilter & BookingOffersRequestServices & OfferListRequest;

export type BookingOffersBaseProps = ApiCancellable &
  PriceRangeFilter & {
    readonly query?: Nullable<string>;
    readonly categories?: Nullable<UUID[]>;
    readonly partnerId?: Nullable<UUID>;
  };

export type BookingOffersCountProps = Omit<BookingOffersBaseProps, 'page' | 'pageSize' | 'sort'>;

type CategoriesUsedProps = ApiCancellable &
  Pick<BookingOffersBaseProps, 'query' | 'partnerId'> & {
    readonly onlyLeafCategories?: Nullable<boolean>;
    readonly statuses?: Nullable<EOfferStatus[]>;
  };

type BookingOffersProps = ApiRequestPageable &
  BookingOffersBaseProps &
  BookingOffersRequestServices & {
    readonly favorite?: Nullable<boolean>;
  };

type BookingOffersOrderProps = ApiCancellable & BookingOrderRequest;

export type BookingOfferApi = {
  readonly categories: (props: ApiCancellable) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Category>>;
  readonly all: (props: BookingOffersProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, BookingOfferShort[]>>;
  readonly count: (props: BookingOffersBaseProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, number>>;
  readonly one: (id: UUID) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, BookingOfferShort>>;
  readonly categoriesUsed: (props: CategoriesUsedProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CategoryTree>>;
  readonly createOrder: (props: BookingOffersOrderProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, BookingOrder>>;
};

const getBookingQueryParams = ({
  minPrice,
  maxPrice,
  ...queryParams
}: Partial<BookingOffersProps>): URLSearchParams => {
  const params = getUserQueryParams(queryParams);

  if (minPrice) {
    params.append('minPrice', minPrice.toString());
  }

  if (maxPrice) {
    params.append('maxPrice', maxPrice.toString());
  }

  return params;
};

export const addAdditionalQueryParams = (
  params: URLSearchParams,
  query: Partial<Record<keyof BookingOffersBaseProps, Nullable<string | string[]>>>
): void => {
  if (query['query']) {
    params.append('q', query['query'] as string);
  }

  if (query['categories']) {
    (query['categories'] as string[]).forEach(item => params.append('bookingOfferCategory', item));
  }

  if (query['partnerId']) {
    params.append('partnerId', query['partnerId'] as string);
  }
};

const bookingOffer: BookingOfferApi = {
  categories: ({ signal }) => {
    return {
      url: '/customers/current/booking-offers/categories',
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  all: props => {
    const { signal, categories, partnerId, services, ...queryParams } = props;

    const params = getBookingQueryParams(queryParams);
    params.append('resultType', 'list');

    // TODO (@Protopopov Ruslan): это надо бы переписать, щас params мутируется из нижней функции, сложно, тревожно
    addAdditionalQueryParams(params, { categories, partnerId });

    if (services) {
      services.forEach(item => params.append('serviceCategory', item));
    }

    return {
      url: `/customers/current/booking-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  count: props => {
    const { signal, ...queryParams } = props;

    const params = getBookingQueryParams(queryParams);
    params.append('resultType', 'count');

    return {
      url: `/customers/current/booking-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  one: id => {
    return {
      url: `/customers/current/booking-offers/${id}`,
      method: 'GET',
    };
  },

  categoriesUsed: ({ query, partnerId, onlyLeafCategories, statuses, signal }) => {
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, { partnerId, query });

    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    return {
      url: '/customers/current/booking-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  createOrder: ({ signal, ...data }) => {
    return {
      url: `/customers/current/orders`,
      method: 'POST',
      data: {
        discriminator: EOrderRequestDiscriminator.BookingOrderRequest,
        ...data,
      },
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default bookingOffer;
