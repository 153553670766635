import { useConfirmCodeMutation } from 'data/api/user';
import { EValidationType, ValidationResult, ValidationRules } from 'presentation/utils/validation';
import { useState } from 'react';
import validateObject from '../../../../hooks/validation/utils';
import { ActionConfirmWithCode } from '../../../../../domain/model';

type CodeData = {
  code: string;
};

export const rules: ValidationRules<CodeData> = {
  code: {
    required: true,
    requiredMessage: 'Необходимо указать корректный код',
  },
};

type CodeValue = { code: string };

type UseCodeEdit = {
  readonly codeTtl: number;
  readonly codeRequestId: string;
  readonly codeNextAttemptDate: string;
  readonly isFetching?: boolean;
  readonly validation: Nullable<ValidationResult<CodeData>>;
  readonly onSendCode: (code: string) => Promise<boolean>;
};

type UseCodeEditProps = {
  readonly source: ActionConfirmWithCode;
  readonly onSuccess: () => void;
};

export const useCodeEdit = (props: UseCodeEditProps): UseCodeEdit => {
  const {
    source: { otpId, otpTtl, nextAttempt },
    onSuccess,
  } = props;

  const [validation, setValidation] = useState<Nullable<ValidationResult<CodeValue>>>(null);

  const [confirmCode, { isLoading: isFetching }] = useConfirmCodeMutation();

  const onSendCode = (code: string) => {
    const validation = validateObject<CodeValue>({ code }, rules);
    const isValid = validation.isValid;
    setValidation(validation.results);

    if (isValid && otpId) {
      return confirmCode({ code, otpId })
        .unwrap()
        .then(() => {
          onSuccess();
          return true;
        })
        .catch(error => {
          setValidation({
            code: {
              hasError: true,
              type: EValidationType.Error,
              message: error.data?.message || 'Произошла неизвестная ошибка',
            },
          });

          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  };

  return {
    codeTtl: otpTtl,
    codeRequestId: otpId,
    codeNextAttemptDate: nextAttempt,
    isFetching,
    validation,
    onSendCode,
  };
};
