import { Link } from '@mui/material';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import rootRouting from '../../../../../routing';
import { getPulseConfig, getSiteCaption } from '../../../../utils';
import { Text } from './controls';

const AboutTermsOfUseConditionsInfo = () => {
  const siteCaption = useMemo(
    () => (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        component={RouterLink}
        to={rootRouting.root}
      >
        {getSiteCaption()}
      </Link>
    ),
    []
  );

  const pulseUrl = useMemo(() => {
    const { url } = getPulseConfig();
    return url ? (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={url}
      >
        {url}
      </Link>
    ) : (
      'ссылка не настроена'
    );
  }, []);

  return (
    <>
      <Text>
        1.1. ОАО «Российские железные дороги» (далее — «Правообладатель») предлагает пользователю (далее –
        «Пользователь») - использовать мобильное приложение «{getPulseConfig()?.caption}» и веб – сайт «Витрина» (далее
        – Платформа) на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение»). Соглашение
        вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.4
        Соглашения.
      </Text>
      <Text>
        1.2. Правообладатель предлагает Пользователям доступ к Платформе. Все существующие на данный момент сервисы и
        приложения, принадлежащие Правообладателю, условия использования которых ссылаются на данное Соглашение, а также
        любое развитие их и/или добавление новых является предметом настоящего Соглашения.
      </Text>
      <Text>
        1.3. Использование Платформы регулируется настоящим Соглашением, а также Политикой конфиденциальности.
        Соглашение может быть изменено Правообладателем без какого-либо специального уведомления, новая редакция
        Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если
        иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения всегда находится на страницах
        по адресам: {pulseUrl}, {siteCaption}.
      </Text>
      <Text>
        1.4. Начиная использовать Платформу, либо пройдя процедуру регистрации, Пользователь считается принявшим условия
        Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из
        положений Соглашения, Пользователь не вправе использовать Платформу. В случае если Правообладателем были внесены
        какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с которыми Пользователь не
        согласен, он обязан прекратить использование Платформы.
      </Text>
    </>
  );
};
export default AboutTermsOfUseConditionsInfo;
