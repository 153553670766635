import { Container, Stack, Theme, Toolbar, useMediaQuery } from '@mui/material';
import FooterRegister from 'presentation/features/footerRegister/footerRegister';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import {
  bottomNavigationVisibleSelector,
  headerCatalogDialogVisibleSelector,
  headerVisibleSelector,
} from '../store/selectors';
import {
  BottomBar,
  BottomMenuWrapper,
  ContentWrapperArea,
  EHeaderGridAreas,
  TopBar,
  TopBarContainer,
  Wrapper,
} from './controls';
import { HeaderLayoutType } from './types';

const HeaderVisibleManager: FCC = ({ children }) => {
  const isVisible = useSelector(headerVisibleSelector);
  const isCatalogVisible = useSelector(headerCatalogDialogVisibleSelector);

  return isVisible || isCatalogVisible ? <>{children}</> : null;
};

const MobileHeaderVisibleManager: FCC = ({ children }) => {
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isVisible = useSelector(bottomNavigationVisibleSelector);
  const isCatalogVisible = useSelector(headerCatalogDialogVisibleSelector);

  if (!isVisible) {
    return null;
  }

  if (isCatalogVisible && isSmDown) {
    return null;
  }

  return <>{children}</>;
};

const HeaderLayout: HeaderLayoutType = props => {
  const { logo, links, search, cancel, location, catalogButton, catalog, options, mobileOptions, dialogs, balances } =
    props;

  const isLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Wrapper
      position='sticky'
      color='default'
      elevation={0}
    >
      <HeaderVisibleManager>
        {isLgUp && (
          <TopBar>
            <TopBarContainer>
              <ContentWrapperArea area={EHeaderGridAreas.Location}>{location}</ContentWrapperArea>
              <ContentWrapperArea area={EHeaderGridAreas.Links}>{links}</ContentWrapperArea>
              {balances && <ContentWrapperArea area={EHeaderGridAreas.Balances}>{balances}</ContentWrapperArea>}
            </TopBarContainer>
          </TopBar>
        )}
        <Toolbar disableGutters>
          <Container>
            <Stack>
              <BottomBar>
                {isLgUp && <ContentWrapperArea area={EHeaderGridAreas.Logo}>{logo}</ContentWrapperArea>}
                {isMdUp && <ContentWrapperArea area={EHeaderGridAreas.Catalog}>{catalogButton}</ContentWrapperArea>}
                <ContentWrapperArea area={EHeaderGridAreas.Search}>{search}</ContentWrapperArea>
                {cancel && isSmDown && <ContentWrapperArea area={EHeaderGridAreas.Cancel}>{cancel}</ContentWrapperArea>}
                {options && isLgUp && (
                  <ContentWrapperArea area={EHeaderGridAreas.Options}>{options}</ContentWrapperArea>
                )}
              </BottomBar>
            </Stack>
          </Container>
        </Toolbar>
      </HeaderVisibleManager>
      {isLgDown && (
        <MobileHeaderVisibleManager>
          <FooterRegister name='bottomHeaderMenu'>
            {footerRef => {
              return (
                <BottomMenuWrapper
                  ref={footerRef}
                  role='menubar'
                >
                  <Container fixed>{mobileOptions}</Container>
                </BottomMenuWrapper>
              );
            }}
          </FooterRegister>
        </MobileHeaderVisibleManager>
      )}
      {dialogs}
      {catalog}
    </Wrapper>
  );
};

export default HeaderLayout;
