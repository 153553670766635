import { Slide, Snackbar } from '@mui/material';
import { Notice } from 'domain/model';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from './controls';
import NoticeItem from './notice';
import { notifierGetNotices } from './store/selectors';
import { closeNotice } from './store/slice';

const NotifierContainer = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const notices = useSelector(notifierGetNotices);

  const onClose = (notice: Notice) => {
    dispatch(closeNotice({ ...notice }));
  };

  return (
    <Wrapper>
      {notices &&
        notices.map(notice => (
          <Snackbar
            ref={ref}
            key={`${notice.status}-${notice.text}-${notice.timestamp}`}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={!!notice}
            autoHideDuration={10000}
            TransitionComponent={Slide}
            ClickAwayListenerProps={{
              mouseEvent: false,
            }}
            onClose={() => onClose(notice)}
          >
            <NoticeItem
              variant={notice.status}
              message={notice.text}
              onClose={() => onClose(notice)}
            />
          </Snackbar>
        ))}
    </Wrapper>
  );
});

export default NotifierContainer;
