import styled from '@emotion/styled';
import { cardClasses } from '@mui/material';

export const Wrapper = styled.div`
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;

  .${cardClasses.root} {
    width: 160px;

    ${p => p.theme.breakpoints.up('sm')} {
      width: 260px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  :not(:empty) {
    margin-bottom: ${p => p.theme.spacing(2)};
  }
`;
