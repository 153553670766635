import { OffersCountRequest } from 'data/api';
import { useGetProductOffersCountQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOffersCountQueryParams } from '../../../utils';
import ProductFilterButtonContainer from '../../filter/button/container';
import { useProductOfferListCategoriesData } from '../hooks/useProductOfferListCategoriesData';
import {
  productOfferListCommonSortSelector,
  productOfferListNameSelector,
  productOfferListSearchIsEmptySelector,
} from '../store/selectors';
import { ProductOffersCommonHeader } from './commonHeader';
import ProductOffersSearchHeader from './searchHeader';
import { useContextHandlers } from '../hooks/useContextHandlers';
import OffersSortSelect from '../../../components/sortSelect';
import { EProductOfferSortType, productOfferSortType } from '../../../../../../domain/model/enums';

const sortItems = Object.values(productOfferSortType);

export const ProductOfferHeaderContainer = () => {
  const { selectedCategory, categoriesToFilter, isCurrentCategoryLeaf } = useProductOfferListCategoriesData();

  const { x5 } = useGridSpacing();

  const handlers = useContextHandlers();

  const isEmptySearch = useSelector(productOfferListSearchIsEmptySelector);
  const name = useSelector(productOfferListNameSelector);
  const sort = useSelector(productOfferListCommonSortSelector);

  const countQueryParams: OffersCountRequest = getOffersCountQueryParams({
    name,
    categories: null,
  });

  const {
    data: initialTotalCount = 0,
    error: countQueryError,
    isSuccess: isCountFetched,
  } = useGetProductOffersCountQuery(countQueryParams, { refetchOnMountOrArgChange: false });

  useEffect(() => {
    if (countQueryError) {
      ErrorHandler.handleHttpError(countQueryError);
    }
  }, [countQueryError]);

  const sortSelectComponent = useMemo(
    () => (
      <OffersSortSelect<EProductOfferSortType>
        value={sort}
        items={sortItems}
        defaultValue={EProductOfferSortType.ByNovelty}
        renderValue={value => sortItems.find(item => item.id === value)?.name}
        onChange={handlers.onChangeSort}
      />
    ),
    [sort, handlers.onChangeSort]
  );

  const filterComponent = useMemo(
    () => !isCurrentCategoryLeaf && <ProductFilterButtonContainer />,
    [isCurrentCategoryLeaf]
  );

  const isSearchHeaderVisible = !isEmptySearch && initialTotalCount > 0 && !!categoriesToFilter;
  const isDefaultHeaderVisible = isCountFetched && initialTotalCount > 0 && isEmptySearch;

  return (
    <>
      {isSearchHeaderVisible && categoriesToFilter && (
        <>
          <ProductOffersSearchHeader
            category={selectedCategory}
            count={initialTotalCount}
            name={name}
            categories={categoriesToFilter}
            sortSelectComponent={sortSelectComponent}
            filterComponent={filterComponent}
            onChangeCategory={handlers.onChangeSearchCategory}
          />
          <Splitter size={x5} />
        </>
      )}

      {isDefaultHeaderVisible && <ProductOffersCommonHeader sortSelectComponent={sortSelectComponent} />}
    </>
  );
};
