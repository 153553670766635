import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import LegalAgreementContainer from 'presentation/features/legal/agreement/container';
import CheckSignsContainer from 'presentation/features/legal/checkSigns/container';
import { FCC, ReactNode, useMemo } from 'react';
import { RequiredActionConfirmEmailContainer } from '../presentation/features/requiredAction/confirmEmail/container';
import useTechConfig from '../presentation/hooks/useTechConfig';
import { EAppFeature } from '../presentation/types';

const AppDecorators: FCC = ({ children }) => {
  const { hasFeature } = useTechConfig();
  const { isAuthenticated } = useAuth();

  const decorators = useMemo<ReactNode[]>(() => {
    return [
      isAuthenticated && <LegalAgreementContainer key='legalAgreement' />,
      isAuthenticated && <CheckSignsContainer key='checkSigns' />,
      isAuthenticated && hasFeature(EAppFeature.ConfirmEmail) && (
        <RequiredActionConfirmEmailContainer key='confirmEmail' />
      ),
    ].filter(item => !!item);
  }, [hasFeature, isAuthenticated]);

  return <RenderDecoratorContainer decorators={decorators}>{children}</RenderDecoratorContainer>;
};

export default AppDecorators;
