import { Text } from '../../components/controls';

const AboutPublicOfferTermsInfo = () => {
  return (
    <>
      <Text>
        «Витрина» – ЕК АСУТР. Модуль корпоративной витрины (ЕК АСУТР.ВКП), программа для ЭВМ, являющаяся составной
        частью платформы социально-кадровых сервисов РЖД, обеспечивающая предоставление информации о предложениях
        товаров и услуг Партнеров, размещенная на странице в сети Интернет по адресу https://mp.rzd.ru/ (Далее
        Платформа);
      </Text>
      <Text>
        «Карточка предложения Партнера» – визуальное изображение, размещаемое на Платформе, содержащее информацию о
        Партнере, Предложении Партнера и способе принятия Пользователем Платформы Предложения Партнера, в том числе
        информацию об обязанности Пользователя Платформы приобретать товары и (или) услуги исключительно для личного
        пользования, запрет на передачу средств аутентификации на стороне Партнера (пароли, коды доступа и пр.) третьим
        лицам, запрет приобретение товаров в целях их возмездной реализации третьим лицам.
      </Text>
      <Text>
        «Оператор» – ООО «Цифровые сервисы», действующее от имени и за счет ОАО «РЖД» в объеме прав и обязанностей,
        определяемых договором.
      </Text>
      <Text>«Оферент» – Открытое акционерное общество «Российские железные дороги».</Text>
      <Text>«Партнер» – юридическое лицо, информация о товарах и (или) услугах которого, размещена на Платформе;</Text>
      <Text>
        «Пользователь Платформы» – работники ОАО «РЖД», работники дочерних и зависимых обществ ОАО «РЖД», члены семей
        работников ОАО «РЖД», другие пользователи, имеющие доступ на Платформу в целях, не связанных с осуществлением
        предпринимательской деятельности.
      </Text>
    </>
  );
};

export default AboutPublicOfferTermsInfo;
