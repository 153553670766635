import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

type ThumbSwiperWrapperProps = {
  readonly withScroll: boolean;
};

export const ThumbSwiperWrapper = styled.div<ThumbSwiperWrapperProps>(
  ({ theme, withScroll }) => css`
    position: relative;
    height: 100%;
    width: 100%;

    .swiper {
      ${withScroll && 'padding-bottom: 24px;'}
      height: 100%;
    }

    .slider {
      height: 100%;
    }

    .swiper-slide {
      overflow: hidden;
      height: auto;
      box-sizing: border-box;
      border: 1px solid ${theme.palette.secondary.A600};
      border-radius: ${(theme.shape.borderRadius as number) * 2}px;
      cursor: pointer;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      &:hover {
        border: 1px solid ${theme.palette.secondary.main};
      }
    }

    .swiper-slide-thumb-active {
      border: 1px solid ${theme.palette.secondary.main};

      &:hover {
        border: 1px solid ${theme.palette.secondary.main};
      }
    }

    .swiper-scrollbar.swiper-scrollbar-horizontal {
      left: 0;
      bottom: 0;
      width: 100%;
      height: ${theme.spacing(1.5)};
      background-color: hsla(220, 10%, 90%, 1);
    }

    .swiper-scrollbar-drag {
      background-color: hsla(220, 10%, 77%, 1);
    }
  `
);
