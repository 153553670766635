import { DialogTitle } from '@mui/material';
import {
  DialogTitleWrapper,
  DialogWrapper,
  FilterWrapper,
  StyledDialogContent,
} from 'presentation/features/offer/product/filter/dialog/controls';
import { MPDialog } from '../../../../../theme/ui-kit/dialog';

type ProductFilterDialogProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const ProductFilterDialog = ({ open, onClose }: ProductFilterDialogProps) => {
  return (
    <MPDialog
      PaperComponent={DialogWrapper}
      open={open}
      onClose={onClose}
    >
      <DialogTitleWrapper>
        <DialogTitle>Фильтры</DialogTitle>
      </DialogTitleWrapper>
      <StyledDialogContent>
        <FilterWrapper>Filter</FilterWrapper>
      </StyledDialogContent>
    </MPDialog>
  );
};

export default ProductFilterDialog;
