import usePrevious from 'presentation/hooks/usePrevious';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBookingOffersSearchRoute } from '../../routes';
import { BookingOffersSearchListLocationState } from '../../types';
import { bookingOffersDefaultParams } from '../../utils';
import { bookingOfferListFilterFetch } from '../store/fetch';
import {
  bookingOfferListFilterDataSelector,
  bookingOfferListGuidSelector,
  bookingOfferListIsDefaultServiceSetSelector,
  bookingOfferPriceRangeSelector,
} from '../store/selectors';
import {
  bookingOffersSetFilterPriceRange,
  bookingOffersSetFilterServices,
  bookingOffersSetIsDefaultServiceSet,
  bookingOffersSetSort,
} from '../store/slice';
import { EBookingOfferListRequestType } from '../store/types';

/**
 * Двунаправленная установка и синхронизации данных фильтра (аргументы/стор). Зависит от операций в store/slice/extraReducers
 */
const useBookingFilter = ({
  categoryId,
  name,
  sort,
  minPrice,
  maxPrice,
  services,
  guid,
}: BookingOffersSearchListLocationState) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { leavingServiceId, isFetched: isFilterFetched, priceRange } = useSelector(bookingOfferListFilterDataSelector);
  const priceRangeSelection = useSelector(bookingOfferPriceRangeSelector);
  const isDefaultServiceSet = useSelector(bookingOfferListIsDefaultServiceSetSelector);
  const currentGuid = useSelector(bookingOfferListGuidSelector);

  const previousStatePriceRangeSelection = usePrevious(priceRangeSelection);

  const { minPrice: rangeMin, maxPrice: rangeMax } = priceRangeSelection;
  const { minPrice: previousRangeMin, maxPrice: previousRangeMax } = previousStatePriceRangeSelection || {};
  const { minPrice: availableMinPrice, maxPrice: availableMaxPrice } = priceRange || {};

  // Запуск цепочки запросов фильтров при входе/смене категории
  useEffect(() => {
    if (!categoryId || currentGuid === guid) {
      return;
    }

    if (services && services.length > 0) {
      dispatch(bookingOffersSetFilterServices(services));
      dispatch(bookingOffersSetIsDefaultServiceSet(true));
    } else {
      dispatch(bookingOffersSetIsDefaultServiceSet(false));
    }

    if (typeof minPrice === 'number' || typeof maxPrice === 'number') {
      dispatch(bookingOffersSetFilterPriceRange({ minPrice: minPrice ?? null, maxPrice: maxPrice ?? null }));
    }

    if (sort) {
      dispatch(bookingOffersSetSort(sort));
    }

    dispatch(
      bookingOfferListFilterFetch({
        requestType: EBookingOfferListRequestType.All,
        categoryId,
        query: name,
        services: services ?? null,
        minPrice: minPrice ?? null,
        maxPrice: maxPrice ?? null,
      })
    );
    // только на categoryId в момент входа/смены
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, guid, dispatch, name]);

  // Установка при первом входе в категорию в аргументы услугу "Проживание" при её наличии
  useEffect(() => {
    if (!categoryId || services || !isFilterFetched || !leavingServiceId || isDefaultServiceSet) {
      return;
    }

    history.push(getBookingOffersSearchRoute({ guid, categoryId, services: [leavingServiceId] }));
    dispatch(bookingOffersSetIsDefaultServiceSet(true));
  }, [categoryId, dispatch, guid, history, isFilterFetched, isDefaultServiceSet, leavingServiceId, services]);

  // Дублирование аргументов услуг в стор
  useEffect(() => {
    if (!categoryId || currentGuid !== guid) {
      return;
    }

    dispatch(bookingOffersSetFilterServices(services ?? bookingOffersDefaultParams.services));
  }, [categoryId, currentGuid, dispatch, guid, services]);

  // Дублирование аргументов цен в стор
  useEffect(() => {
    if (!categoryId || (!minPrice && !maxPrice)) {
      return;
    }

    dispatch(bookingOffersSetFilterPriceRange({ minPrice: minPrice ?? null, maxPrice: maxPrice ?? null }));
  }, [dispatch, minPrice, maxPrice, categoryId, currentGuid, guid]);

  // Синхронизация аргументов после обновления доступного диапазона цен в сторе
  // Обновление в сторе для цен является первичным ввиду выполнения rtk запроса на список карточек сразу по готовности фильтров
  useEffect(() => {
    if (
      !categoryId ||
      !isFilterFetched ||
      (typeof rangeMin !== 'number' && typeof rangeMax !== 'number') ||
      (typeof minPrice !== 'number' && typeof maxPrice !== 'number')
    ) {
      return;
    }

    const isMinChanged = rangeMin !== minPrice;
    const isMaxChanged = rangeMax !== maxPrice;

    if (previousRangeMin === rangeMin && previousRangeMax === rangeMax) {
      return;
    }

    if (isMinChanged || isMaxChanged) {
      history.push(
        getBookingOffersSearchRoute({
          guid,
          categoryId,
          services,
          sort,
          minPrice: isMinChanged ? minPrice : rangeMin,
          maxPrice: isMaxChanged ? maxPrice : rangeMax,
        })
      );
    }
  }, [
    categoryId,
    guid,
    history,
    isFilterFetched,
    minPrice,
    maxPrice,
    rangeMin,
    rangeMax,
    previousRangeMin,
    previousRangeMax,
    availableMinPrice,
    availableMaxPrice,
    services,
    sort,
  ]);
};

export default useBookingFilter;
