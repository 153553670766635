import { Fragment } from 'react';
import { HeaderOptionItem } from '../../adapters/useHeaderOptions';

export type HeaderOptionsProps = {
  readonly options: HeaderOptionItem[];
};

const HeaderOptions = ({ options }: HeaderOptionsProps) => {
  return (
    <>
      {options.map(option => (
        <Fragment key={option.type}>{option.component}</Fragment>
      ))}
    </>
  );
};

export default HeaderOptions;
