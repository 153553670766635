import { FCC } from 'react';
import { Wrapper, Text, AcceptCookieContainer } from './controls';
import useUserLocalSettings from '../../hooks/useUserLocalSettings';
import { MPButton } from '../../theme/ui-kit/button';
import { useSelector } from 'react-redux';
import { MPLink } from '../../theme/ui-kit/link';
import { footerRegisterAllHeightSelector } from '../footerRegister/store/selectors';
import { getLegalCookiesRoute } from '../legal/entry';

const CookiesAcceptContainer: FCC = ({ children }) => {
  const { setUserCookieAccepted, isCurrentUserAcceptedCookies } = useUserLocalSettings();

  const allHeight = useSelector(footerRegisterAllHeightSelector);

  return (
    <>
      {children}
      {!isCurrentUserAcceptedCookies && (
        <Wrapper bottomOffset={allHeight}>
          <AcceptCookieContainer>
            <Text>
              Мы используем{' '}
              <MPLink
                href={getLegalCookiesRoute()}
                underline='always'
                color='textPrimary'
                target='_blank'
              >
                cookies
              </MPLink>
              , чтобы сайт работал лучше
            </Text>
            <MPButton
              color='brand'
              onClick={() => setUserCookieAccepted(true)}
            >
              Понятно
            </MPButton>
          </AcceptCookieContainer>
        </Wrapper>
      )}
    </>
  );
};

export default CookiesAcceptContainer;
