import { ButtonProps } from '@mui/material';
import { StyledButton } from './controls';

export type ProductOfferOutOfStockCartButtonProps = ButtonProps & {
  readonly label: string;
};

const ProductOfferOutOfStockCartButton = (props: ProductOfferOutOfStockCartButtonProps) => {
  const { label, ...others } = props;

  return (
    <StyledButton
      color={'secondary'}
      fullWidth={false}
      {...others}
    >
      {label}
    </StyledButton>
  );
};

export default ProductOfferOutOfStockCartButton;
