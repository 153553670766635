import { FC } from 'react';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import OfferClaimLayout from '../../../../../layouts/offerClaimLayout';
import { SidebarProps } from '../../details/types';
import { calcTotalPrice } from './utils';
import useBookingSidebarSlots from './slots';
import BookingSidebarSmartPhone from './smartPhone';
import ClaimInfo from '../claim/info';
import ClaimContent from '../claim/content';

export type BookingSidebarProps = SidebarProps & {
  readonly offerId: string;
  readonly onSelectItem: (index: number[]) => void;
  readonly onDeleteItem: (index: number[]) => void;
};

const BookingSidebar: FC<BookingSidebarProps> = ({ offerId, cart, ...other }) => {
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const totalPrice = calcTotalPrice(cart);

  const slots = useBookingSidebarSlots({
    cart,
    totalPrice,
    ...other,
  });

  return (
    <>
      <OfferClaimLayout
        content={isMdDown ? null : <ClaimContent>{slots}</ClaimContent>}
        skirt={
          <ClaimInfo isFirst={!isMdDown}>
            <Typography
              key='id'
              variant='body2'
            >
              Объект: {offerId}
            </Typography>
          </ClaimInfo>
        }
      />
      {isMdDown && (
        <BookingSidebarSmartPhone
          slots={slots}
          cart={cart}
          totalPrice={totalPrice}
        />
      )}
    </>
  );
};

export default BookingSidebar;
