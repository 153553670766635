import { observablePattern, observables } from '@mp-npm/mp-auth-client';
import { useGlobalPreviewMode } from 'presentation/screen/cms/hooks/previewMode/useGlobalPreviewMode';
import { useEffect } from 'react';
import PreviewSnackbarContainer from './previewSnackbar/container';

export const GlobalPreviewModeContainer = () => {
  const { clear, isHeaderApplied } = useGlobalPreviewMode();

  useEffect(() => {
    const observer = new observablePattern.Observer(() => {
      clear(false);
    });

    observables.onCustomLogoutObservableSubject.subscribe(observer);

    return () => {
      observables.onCustomLogoutObservableSubject.unsubscribe(observer);
    };
  }, [clear]);

  const onClear = () => clear();

  return isHeaderApplied ? <PreviewSnackbarContainer onClick={onClear} /> : null;
};
