import StubCommon from 'presentation/components/common/stub/common';
import { activationsStub } from 'presentation/media/images';

type ActivationsStubProps = {
  readonly text?: string;
};

const ActivationsStub = ({
  text = 'Здесь будут храниться ваши сертификаты, ваучеры, промокоды и др.',
}: ActivationsStubProps) => {
  return (
    <StubCommon
      title={text}
      image={activationsStub}
    />
  );
};

export default ActivationsStub;
