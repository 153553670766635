import { Typography } from '@mui/material';
import ClaimMessage from 'presentation/features/offer/components/claim/message';

export const TradeOfferClaimPausedMessage = () => {
  return (
    <ClaimMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение временно недоступно
      </Typography>
    </ClaimMessage>
  );
};
