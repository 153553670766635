import { EBalanceType, EUserGender, EUserRole } from 'domain/model/enums';
import { EDateTimeFormat } from 'domain/model/formats';
import { UserData } from 'domain/model/user';
import { currencySymbols } from 'locale';
import { toCurrency } from 'presentation/utils/currency';
import rootRouting from '../../../routing';
import { EAppFeature } from '../../types';
import { utcToLocalTimeZone } from '../../utils/date';
import { getUserProfileRoute } from './entry';

export type UserEditCommonAttributes = Pick<
  UserData,
  'firstName' | 'lastName' | 'middleName' | 'birthDate' | 'photo' | 'gender'
>;

export const getUserGenderName = (gender: EUserGender) => {
  switch (gender) {
    case EUserGender.Male:
      return 'Мужской';
    case EUserGender.Female:
      return 'Женский';
  }
};

export const getAllowedRoles = (realmRoles: string[]) => {
  return (realmRoles as EUserRole[]).filter(existed =>
    Object.values(EUserRole).some(localRole => localRole === existed)
  );
};

export const getCustomerAllowedRoles = (userRoles: EUserRole[]) => {
  return userRoles.filter(userRole => [EUserRole.Customer, EUserRole.Corp].includes(userRole));
};

type GetUserDefaultRouteType = (
  hasRole: (...role: EUserRole[]) => boolean,
  hasFeature: (feature: EAppFeature) => boolean
) => string;

export const getUserDefaultRoute: GetUserDefaultRouteType = (hasRole, hasFeature) => {
  // Открываем страницу товаров для всех
  if (hasFeature(EAppFeature.Product)) {
    return rootRouting.product;
  }

  // Открываем страницу корпоративных предложений
  if (hasRole(EUserRole.Corp)) {
    return rootRouting.corpOffer;
  }

  // Открываем страницу торговых предложений
  if (hasFeature(EAppFeature.Trade)) {
    return rootRouting.tradeOffer;
  }

  // Если ничего нет то страницу профиля
  return getUserProfileRoute();
};

export const getUserBalanceExpireMessage = (value: number, type: EBalanceType, expireDate: string) => {
  let typedValue = '';

  switch (type) {
    case EBalanceType.Bonuses:
      typedValue = 'б.';
      break;
    case EBalanceType.ASP:
      typedValue = currencySymbols.RU;
      break;
  }

  const dateMoment = utcToLocalTimeZone(expireDate);
  const date =
    dateMoment.year() === new Date().getFullYear()
      ? dateMoment.format(EDateTimeFormat.HumanPointShort)
      : dateMoment.format(EDateTimeFormat.HumanPoint);

  return [toCurrency(value), typedValue, 'сгорают', date].filter(item => !!item).join(' ');
};
