import styled from '@emotion/styled/macro';
import { svgIconClasses, Typography } from '@mui/material';

export const SidebarPriceWrapper = styled.div`
  display: flex;
  align-items: center;

  .${svgIconClasses.root} {
    margin-right: 4px;
  }
`;

export const PriceText = styled(Typography)`
  margin-right: 6px;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
`;

export const OriginalPriceText = styled(Typography)`
  text-decoration: line-through;
`;
