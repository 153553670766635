import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const ellipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const Ellipsis = styled.div`
  ${ellipsisCss};
`;
