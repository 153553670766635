import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type SectionLinksState = {
  readonly visible: boolean;
};

type Reducer<T = undefined> = CaseReducer<SectionLinksState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<SectionLinksState> & {
  sectionLinksSetVisible: Reducer<boolean>;
};

const slice = createSlice<SectionLinksState, Reducers, 'sectionLinks'>({
  name: 'sectionLinks',
  initialState: {
    visible: false,
  },
  reducers: {
    sectionLinksSetVisible: (state, { payload }) => {
      state.visible = payload;
    },
  },
});

export const { sectionLinksSetVisible } = slice.actions;

export default slice.reducer;
