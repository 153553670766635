import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { bottomMenuHeight } from '../../header/layout/controls';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    justify-content: flex-end;
    z-index: 1;

    background-color: ${theme.palette.secondary.A600};

    padding-bottom: ${bottomMenuHeight};

    ${theme.breakpoints.up('lg')} {
      padding-bottom: 0;
    }
  `
);

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    row-gap: ${theme.spacing(4)};
    column-gap: ${theme.spacing(3)};
    justify-content: space-between;
    grid-template-columns: 1fr;

    ${theme.breakpoints.up('md')} {
      row-gap: ${theme.spacing(2)};
      grid-template-columns: repeat(3, minmax(230px, 400px));
    }
  `
);

export const Footer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(4, 0)};

    background-color: ${theme.palette.secondary.A600};
  `
);
