import { ReactElement } from 'react';
import useBusinessConfig from '../../../../hooks/useBusinessConfig';

type CorpLinkProxyProps = {
  readonly link: string;
  readonly children: (linkByProxy: string) => ReactElement;
};

export const buildLinkByProxy = (link: string, proxyUrl: string): string => {
  if (link && proxyUrl) {
    const params = new URLSearchParams({
      url: link,
      rule: location.hostname,
    });
    return `${proxyUrl}?${params}`;
  } else {
    return link;
  }
};

/**
 * компонент который рендерит children, преобразуя ссылку в новую ссылку через прокси
 * прокси в данном случае - сервис, который осуществляет редирект запроса на правильный корпоративный ресурс в случае если необходимо
 */
const CorpLinkProxy = ({ link, children }: CorpLinkProxyProps) => {
  const { corpLinkProxyUrl } = useBusinessConfig();

  return children(buildLinkByProxy(link, corpLinkProxyUrl));
};

export default CorpLinkProxy;
