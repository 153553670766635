import styled from '@emotion/styled';
import { css, formControlClasses, outlinedInputClasses } from '@mui/material';
import { MPNaturalNumberInput } from '../../../../theme/ui-kit/input';

export const RangeInputsWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing(1)};

    .${formControlClasses.root} {
      border-radius: ${theme.spacing()};
      overflow: hidden;
    }

    .${outlinedInputClasses.notchedOutline} {
      border-color: transparent;
    }
  `
);

export const InputNumber = styled(MPNaturalNumberInput)(
  ({ theme: { spacing, typography } }) => css`
    .${outlinedInputClasses.input} {
      padding-left: ${spacing(0.5)};
      padding-right: ${spacing(1.5)};

      font-size: ${typography.body2.fontSize};
    }

    .${outlinedInputClasses.notchedOutline} {
      border: none;
    }

    .${outlinedInputClasses.root} {
      height: 38px;
    }
  `
);
