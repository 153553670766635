import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';

type CorpOfferClaimPendingMessageProps = {
  readonly activationComment: Nullable<string>;
};

export const CorpOfferClaimPendingInfoMessage = (props: CorpOfferClaimPendingMessageProps) => {
  const { activationComment } = props;

  return (
    <ClaimMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        {activationComment}
      </Typography>
    </ClaimMessage>
  );
};
