import { FilterIcon } from 'presentation/media/icons';
import AppSvgIcon, { AppSvgProps } from '../../icon';
import { StyledBadge } from './controls';

type FilterIconProps = Omit<AppSvgProps, 'icon'> & {
  active?: boolean;
};

const FilterBadge = ({ active, ...others }: FilterIconProps) => {
  return (
    <StyledBadge
      badgeContent={active}
      color={'primary'}
    >
      <AppSvgIcon
        icon={FilterIcon}
        {...others}
      />
    </StyledBadge>
  );
};

export default FilterBadge;
