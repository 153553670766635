import { OfferListRequest } from 'data/api';
import { useGetTradeOfferListQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferListType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import useCategoriesData from 'presentation/features/offer/trade/list/hooks/useCategoriesData';
import { tradeOfferListIsNewFetchingSelector } from 'presentation/features/offer/trade/list/store/selectors';
import { ERenderSource } from 'presentation/types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OfferListItemContainer from '../../listItem/container';

type TradeOfferListDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: OfferListRequest;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (tradeOffer: TradeOfferShort) => void;
};

const TradeOfferListDataContainer = (props: TradeOfferListDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const isNewFetching = useSelector(tradeOfferListIsNewFetchingSelector);
  const { page } = args;

  const { isAuthenticated } = useAuth();
  const { subCategories } = useCategoriesData();

  const { data, error, isSuccess, isFetching } = useGetTradeOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const tradeOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasSubCategories = !!subCategories?.length;
  const hasOffers = !!tradeOffers && tradeOffers.length > 0;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <CardPageableList
        span={hasSubCategories ? 4 : 3}
        lgSpan={hasSubCategories ? 6 : 4}
        totalCount={totalCount}
        onLoadMore={onLoadMore}
      >
        {tradeOffers.map(tradeOffer => (
          <InView
            key={tradeOffer.id}
            active={isSuccess && !isFetching}
            sessionKey={sessionKey}
            data={tradeOffer}
            onShow={onShowCard}
          >
            <OfferListItemContainer
              key={tradeOffer.id}
              offer={tradeOffer}
              favorite={
                isAuthenticated && {
                  guid,
                }
              }
              renderFrom={ERenderSource.OfferList}
            />
          </InView>
        ))}
      </CardPageableList>
    </div>
  );
};

export default TradeOfferListDataContainer;
