import { EOfferListType } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersTextHeader from '../../../components/textHeader';
import AspOfferListDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { aspOfferListGuidSelector, aspOfferListUpcomingArgsSelector } from '../store/selectors';

type AspOfferListDataUpcomingAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Upcoming;

export const AspOfferListDataUpcomingAdapter = ({ guid }: AspOfferListDataUpcomingAdapterProps) => {
  const currentGuid = useSelector(aspOfferListGuidSelector);
  const args = useSelector(aspOfferListUpcomingArgsSelector);

  const handlers = useContextHandlers();

  const header = useMemo(
    () => (
      <OffersTextHeader variant='h2'>
        Скоро будут доступны
        <Splitter size={3} />
      </OffersTextHeader>
    ),
    []
  );

  return useMemo(
    () =>
      guid !== currentGuid || !args ? null : (
        <AspOfferListDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          header={header}
          onChangePage={handlers.onChangePage}
          onShowCard={handlers.onShowCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangePage, handlers.onShowCard, header]
  );
};
