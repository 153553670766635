import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { productOfferListCommonIsFetchingSelector } from '../store/selectors';

// TODO(@Protopopov Ruslan) pass selector as prop, pass to useSelector, (store: RootState) => boolead
const ProductOfferListLoaderAdapter = () => {
  const isFetching = useSelector(productOfferListCommonIsFetchingSelector);

  return useMemo(() => (isFetching ? <ContentLoader position='fixed' /> : null), [isFetching]);
};

export default ProductOfferListLoaderAdapter;
