import { toCurrency, toRubCurrency } from 'presentation/utils/currency';
import { OriginalPriceText, PriceText, SidebarPriceWrapper } from './controls';
import { EPaymentType } from 'domain/model/enums';
import { PaymentIcon } from 'presentation/components/common/paymentIcon';

type OfferClaimPriceInfoProps = {
  readonly paymentType: EPaymentType;
  readonly price: number;
  readonly originalPrice?: Nullable<number>;
};

const OfferClaimPriceInfo = ({ paymentType, price, originalPrice }: OfferClaimPriceInfoProps) => {
  let func = toCurrency;
  switch (paymentType) {
    case EPaymentType.AspRzdRub:
    case EPaymentType.Rub:
      func = toRubCurrency;
      break;
    case EPaymentType.Free:
    case EPaymentType.BenefitRzdPoint:
      func = toCurrency;
      break;
  }

  return (
    <SidebarPriceWrapper>
      <PaymentIcon type={paymentType} />
      <PriceText>{func(price)}</PriceText>
      {originalPrice !== undefined && originalPrice !== null && originalPrice > price && (
        <OriginalPriceText
          variant='body1'
          color='textSecondary'
        >
          {func(originalPrice)}
        </OriginalPriceText>
      )}
    </SidebarPriceWrapper>
  );
};

export default OfferClaimPriceInfo;
