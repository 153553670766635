import { Category } from 'domain/model/nsi';
import OfferListAside from '../../../components/listAside';
import OfferSubCategories from '../../../components/subCategories';
import { getTradeOffersSearchRoute } from '../../routes';
import useCategoriesData from '../hooks/useCategoriesData';

export const TradeOfferListAsideAdapter = () => {
  const { subCategories, isCategoriesFetching } = useCategoriesData();

  const linkFactory = (category: Category) => getTradeOffersSearchRoute({ categoryId: category.id });

  if (!subCategories?.length || isCategoriesFetching) {
    return null;
  }

  return (
    <OfferListAside>
      <OfferSubCategories
        categories={subCategories}
        linkFactory={linkFactory}
      />
    </OfferListAside>
  );
};
