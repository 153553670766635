import { ReactNode, RefObject } from 'react';
import { useFooterRegister } from './hooks/useFooterRegister';

type FooterRegisterProps = {
  readonly name: string;
  readonly children: (ref: RefObject<HTMLElement>) => ReactNode;
};

const FooterRegister = ({ name, children }: FooterRegisterProps) => {
  const { footerRef } = useFooterRegister(name);

  return children(footerRef);
};

export default FooterRegister;
