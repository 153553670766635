import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';

export type UserSuccessEmailUpdateDialogProps = {
  readonly open: boolean;
  readonly email: string;
  readonly onClose: () => void;
};

const ChangeEmailSuccessDialog = (props: UserSuccessEmailUpdateDialogProps) => {
  const { email, open, onClose } = props;

  return (
    <MPConfirmDialog
      title='Электронная почта изменена'
      open={open}
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          onClick={onClose}
        >
          Продолжить
        </MPButton>
      }
    >
      Электронная почта вашего аккаунта была успешно изменена на{' '}
      <Typography
        variant='subtitle1'
        component='span'
      >
        {email}
      </Typography>
    </MPConfirmDialog>
  );
};

export default ChangeEmailSuccessDialog;
