import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferClaimContainer from '../../claim/container';

type TradeOfferSidebarAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

const TradeOfferSidebarAdapter = ({ id, guid }: TradeOfferSidebarAdapterProps) => {
  const { tradeOffer } = useTradeOfferDetailsData(id);
  const { partnerDesk } = useTradeOfferPartnerDeskData(id);

  if (!tradeOffer) {
    return null;
  }

  return (
    <TradeOfferClaimContainer
      guid={guid}
      partnerDesk={partnerDesk}
      tradeOffer={tradeOffer}
    />
  );
};

export default TradeOfferSidebarAdapter;
