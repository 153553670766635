import { getFileDownloadEndpoint } from 'data/openApi/utils';
import { FileLink as FileLinkModel } from 'domain/model';
import React from 'react';
import { StyledImage } from './controls';

export type AppImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  /** id/path файла или структура FileDescription */
  readonly src: string | FileLinkModel;
  readonly className?: string;
};

const ImageInternal = React.forwardRef((props: AppImageProps, ref: any) => {
  const { src, className, alt, ...otherProps } = props;

  const id: string = typeof src === 'string' ? src : src.path;
  const href = getFileDownloadEndpoint({ id });

  return (
    <StyledImage
      ref={ref}
      className={className}
      src={href}
      alt={alt ?? ''}
      {...otherProps}
    />
  );
});

const AppImage = React.forwardRef<typeof ImageInternal, any>((props: AppImageProps, ref: any) => (
  <ImageInternal
    ref={ref}
    {...props}
  />
)) as typeof ImageInternal;

export default AppImage;
