import { ECatalogTab } from 'presentation/features/catalog/types';
import { useSelector } from 'react-redux';
import { useGetBookingOfferCategoriesQuery } from 'data/api/bookingOffer';
import { catalogTabSelector } from '../store/selectors';
import { CatalogOffersContainer } from '../components/catalogContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';

type CatalogBookingOffersTabAdapterProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogBookingOffersTabAdapter = ({ selected }: CatalogBookingOffersTabAdapterProps) => {
  const tab = useSelector(catalogTabSelector);
  const { onSelectBookingOffersCategory } = useContextHandlers();

  const isActiveTab = tab === ECatalogTab.BookingOffers;

  const response = useGetBookingOfferCategoriesQuery({ guid: 'catalog-booking-offers' }, { skip: !isActiveTab });

  return isActiveTab ? (
    <CatalogOffersContainer
      {...response}
      onSelect={onSelectBookingOffersCategory}
      selected={selected}
    />
  ) : null;
};
