import { useEffect, FCC } from 'react';
import { NavigationOptions } from 'swiper/types';
import AppSvgIcon from '../../icon';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../media/icons';
import { SwiperControlsProps } from '../gallery';
import { CarouselNavigateButtonNext, CarouselNavigateButtonPrev } from './controls';

const CarouselDefaultNavigation: FCC<SwiperControlsProps> = props => {
  const { buttonPrevRef, onPrevClick, buttonNextRef, onNextClick, swiper } = props;

  useEffect(() => {
    if (!swiper) {
      return;
    }

    (swiper.params.navigation as NavigationOptions).prevEl = buttonPrevRef.current;
    (swiper.params.navigation as NavigationOptions).nextEl = buttonNextRef.current;
    swiper.update();
  }, [buttonNextRef, buttonPrevRef, swiper]);

  return (
    <>
      <CarouselNavigateButtonPrev
        ref={buttonPrevRef}
        onClick={onPrevClick}
      >
        <AppSvgIcon
          fontSize='medium'
          icon={ArrowLeftIcon}
        />
      </CarouselNavigateButtonPrev>
      <CarouselNavigateButtonNext
        ref={buttonNextRef}
        onClick={onNextClick}
      >
        <AppSvgIcon
          fontSize='medium'
          icon={ArrowRightIcon}
        />
      </CarouselNavigateButtonNext>
    </>
  );
};

export default CarouselDefaultNavigation;
