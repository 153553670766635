import { useHistory } from 'react-router';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import BookingOfferTabs from '../tabs';
import { EBookingOfferDetailsTab } from '../types';
import { addSearchParamToLocation } from '../../../../../../routing';
import { EBookingUrlParam } from '../../types';

type BookingOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: EBookingOfferDetailsTab;
};

const BookingOfferDetailsTabsAdapter = ({ tab, id }: BookingOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const { bookingOffer } = useBookingOfferDetailsData(id);

  const onChangeTab = (event: React.SyntheticEvent, newTab: EBookingOfferDetailsTab) => {
    history.replace(
      addSearchParamToLocation({
        location: history.location,
        param: EBookingUrlParam.Tab,
        value: newTab,
      })
    );
  };

  if (!bookingOffer) {
    return null;
  }

  return (
    <BookingOfferTabs
      tab={tab}
      id={id}
      bookingOffer={bookingOffer}
      onChange={onChangeTab}
    />
  );
};

export default BookingOfferDetailsTabsAdapter;
