import { forwardRef } from 'react';
import {
  UserMenuActivationsAdapter,
  UserMenuCartAdapter,
  UserMenuFavoritesAdapter,
  UserMenuLocalityAdapter,
  UserMenuLogoutAdapter,
  UserMenuOrdersAdapter,
  UserMenuSettingsAdapter,
} from './adapters';
import UserMenuLayout from './layout';

const Layout = UserMenuLayout;

type UserMenuContainerProps = {
  readonly onLocationClick?: Nullable<() => void>;
};

const UserMenuContainer = forwardRef<any, UserMenuContainerProps>((props, ref) => {
  const { onLocationClick } = props;

  return (
    <Layout
      ref={ref}
      logout={<UserMenuLogoutAdapter />}
    >
      {/*<UserMenuFinancesAdapter />*/}
      <UserMenuActivationsAdapter />
      <UserMenuFavoritesAdapter />
      <UserMenuOrdersAdapter />
      <UserMenuCartAdapter />
      {onLocationClick && <UserMenuLocalityAdapter onLocationClick={onLocationClick} />}
      <UserMenuSettingsAdapter />
    </Layout>
  );
});

export default UserMenuContainer;
