import styled from '@emotion/styled/macro';
import { css, outlinedInputClasses, svgIconClasses } from '@mui/material';
import { MPFormInput } from '../input';

export const MPSelectStyled = styled(MPFormInput)(
  ({ theme }) => css`
    .${outlinedInputClasses.notchedOutline} {
      border: 0;
    }

    .${svgIconClasses.root} {
      color: ${theme.palette.black.main};
    }
  `
);
