import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type HeaderState = {
  readonly visible: boolean;
  readonly bottomNavigationVisible: boolean;
  readonly dialogs: {
    readonly changeLocation: boolean;
    readonly catalog: boolean;
  };
};

export type HeaderDialogsState = {
  readonly name: keyof HeaderState['dialogs'];
  readonly value: boolean;
};

type Reducer<T = undefined> = CaseReducer<HeaderState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<HeaderState> & {
  headerStartSession: Reducer;
  headerSetDialogState: Reducer<HeaderDialogsState>;
  headerToggleDialogState: Reducer<HeaderDialogsState['name']>;
  headerSetVisible: Reducer<boolean>;
  bottomNavigationSetVisible: Reducer<boolean>;
};

const slice = createSlice<HeaderState, Reducers, 'header'>({
  name: 'header',
  initialState: {
    visible: true,
    bottomNavigationVisible: true,
    dialogs: {
      changeLocation: false,
      catalog: false,
    },
  },
  reducers: {
    headerStartSession: state => {
      state.dialogs = {
        changeLocation: false,
        catalog: false,
      };
    },
    headerSetDialogState: (state, { payload }) => {
      const { name, value } = payload;
      state.dialogs[name] = value;
    },
    headerToggleDialogState: (state, { payload }) => {
      state.dialogs[payload] = !state.dialogs[payload];
    },
    headerSetVisible: (state, { payload }) => {
      state.visible = payload;
    },
    bottomNavigationSetVisible: (state, { payload }) => {
      state.bottomNavigationVisible = payload;
    },
  },
});

export const {
  headerStartSession,
  headerSetDialogState,
  headerToggleDialogState,
  headerSetVisible,
  bottomNavigationSetVisible,
} = slice.actions;

export default slice.reducer;
