import validator from 'validator';
import {
  EValidationMessages,
  EValidationType,
  ValidationItemResult,
  ValidationResult,
  ValidationRule,
  ValidationRules,
} from '../../utils/validation';

type InternalValidateResult<T> = {
  readonly results: Nullable<ValidationResult<T>>;
  readonly isValid: boolean;
};

function validateObject<T>(object: T, rules: ValidationRules<T>): InternalValidateResult<T> {
  let results: Nullable<ValidationResult<T>> = {};
  let hasErrors = false;

  (Object.keys(rules) as [keyof T]).forEach(key => {
    if (!rules[key]) {
      return;
    }

    const value: any = object[key];
    const { required, validator: customValidator, requiredMessage } = rules[key] as ValidationRule<T>;

    let resultItem: Nullable<ValidationItemResult> = null;

    let isRequired: boolean;
    if (typeof required === 'function') {
      isRequired = required(object, value);
    } else {
      isRequired = required ?? false;
    }

    if (
      isRequired &&
      (value === null || value === undefined || (typeof value === 'string' && validator.isEmpty(value)))
    ) {
      resultItem = {
        type: EValidationType.Error,
        hasError: true,
        message: requiredMessage ?? EValidationMessages.Empty,
      };
    } else {
      if (customValidator) {
        resultItem = customValidator(object, value);
      }
    }

    if (results && resultItem) {
      results[key] = resultItem;

      if (resultItem.hasError) hasErrors = true;
    }
  });

  if (Object.keys(results).length === 0) {
    results = null;
  }

  return {
    results,
    isValid: !hasErrors,
  };
}

export default validateObject;
