import useCorpOfferCategoriesData from './useCorpOfferCategoriesData';
import useCorpOfferDetailsData from './useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from './useCorpOfferPartnerDeskData';

type CorpDetailsFetchingStatusProps = {
  readonly id: UUID;
};

type UseCorpOfferDetailsFetchingStatus = boolean;

const useCorpOfferDetailsFetchingStatus = ({
  id,
}: CorpDetailsFetchingStatusProps): UseCorpOfferDetailsFetchingStatus => {
  const { categoriesIsFetching: isCategoriesFetching } = useCorpOfferCategoriesData();
  const { isCorpOfferFetching } = useCorpOfferDetailsData(id);
  const { isPartnerDeskFetching } = useCorpOfferPartnerDeskData(id);

  return isCategoriesFetching || isCorpOfferFetching || isPartnerDeskFetching;
};

export default useCorpOfferDetailsFetchingStatus;
