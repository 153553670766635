import { Typography } from '@mui/material';
import { EOrderServicesTag } from 'data/api';
import { useNsiBookingOrderStatusesQuery, useNsiGeneralOrderStatusesQuery } from 'data/api/nsi';
import { useGetUserOrdersCountQuery, useGetUserOrdersQuery } from 'data/api/user';
import { Location } from 'history';
import ContentLoader from 'presentation/components/common/loader';
import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import useRtkQueryCachedPageableData from 'presentation/features/general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { EPaginationBehaviour } from 'presentation/features/general/pageable/types';
import usePageableList from 'presentation/features/general/pageable/usePageableList';
import OrderStub from 'presentation/features/order/components/stub';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { OrderShort } from '../../../../../domain/model/order';
import NoAnyOrderStub from '../../../order/components/noAnyOrderStub';
import UserOrdersTabs from '../../components/ordersTabs';
import { getUserOrderRoute, getUserOrdersRoute } from '../../entry';
import UserOrders from './component';
import UserOrdersLayout from './layout';
import { EUserOrdersTab, getUserOrdersStatus } from './utils';

type UserOrdersContainerProps = {
  readonly guid: UUID;
  readonly tab: EUserOrdersTab;
};

export const pageSize: number = 10 as const;

const Layout = UserOrdersLayout;

const UserOrdersContainer = (props: UserOrdersContainerProps) => {
  const { guid, tab } = props;

  const history = useHistory();

  const { data: generalOrderStatuses, isFetching: isGeneralOrderStatusesFetching } = useNsiGeneralOrderStatusesQuery();
  const { data: bookingOrderStatuses, isFetching: isBookingOrderStatusesFetching } = useNsiBookingOrderStatusesQuery();
  const { data: ordersCount, isFetching: isOrdersCountFetching } = useGetUserOrdersCountQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const statuses = useMemo(() => getUserOrdersStatus(tab), [tab]);

  const argsStorage = usePageableArgsInRedux({
    guid,
    defaultState: {
      guid,
      statuses,
      page: 1,
      pageSize,
    },
  });

  const {
    data: orders,
    isFetching: isOrdersFetching,
    isPreparing,
    loadMore,
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    cacheProvider: useRtkQueryCachedPageableData<OrderShort>({
      guid,
      tag: EOrderServicesTag.Orders,
      payload: argsStorage.currentState,
    }),
    argsStorage,
    useQuery: useGetUserOrdersQuery,
  });

  const onOpenUserRoute = (route: string | Location) => {
    history.replace(route);
  };

  const onChangeTab = (newTab: EUserOrdersTab) => {
    onOpenUserRoute(getUserOrdersRoute({ guid: uuidv4(), tab: newTab }));
  };

  const onOrderClick = (id: UUID) => {
    history.push(getUserOrderRoute({ id }));
  };

  const noAnyOrders = !isOrdersCountFetching && ordersCount === 0;

  const isFetching =
    isGeneralOrderStatusesFetching || isBookingOrderStatusesFetching || isOrdersCountFetching || isOrdersFetching;

  const noData = !isFetching && orders && orders.length === 0;

  if (isOrdersCountFetching && ordersCount === undefined) {
    return <Layout loader={<ContentLoader />} />;
  }

  if (noAnyOrders) {
    return (
      <Layout
        header={
          <Typography
            textAlign='center'
            variant='h1'
          >
            Заказы
          </Typography>
        }
        stub={<NoAnyOrderStub />}
      />
    );
  }

  return (
    <Layout
      header='Заказы'
      toolbar={
        <UserOrdersTabs
          tab={tab}
          onChangeTab={onChangeTab}
        />
      }
      loader={isFetching && !orders && <ContentLoader />}
      stub={noData && <OrderStub />}
    >
      {orders && generalOrderStatuses && bookingOrderStatuses && (
        <UserOrders
          orders={orders}
          generalOrderStatuses={generalOrderStatuses}
          bookingOrderStatuses={bookingOrderStatuses}
          isFetching={isFetching || isPreparing}
          onLoadNextPage={loadMore}
          onOrderClick={onOrderClick}
        />
      )}
    </Layout>
  );
};

export default UserOrdersContainer;
