import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Pageable } from 'domain/model';

type KeyType = string;

export type PageableCacheData<T = any> = Nullable<Pageable<T>>;

export type PageableCacheItemState<T = any> = {
  readonly tag?: string;
  readonly data: PageableCacheData<T>;
};

export type PageableCacheState = Record<KeyType, PageableCacheItemState>;

type Reducer<T = undefined> = CaseReducer<PageableCacheState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<PageableCacheState> & {
  readonly pageableCachePut: Reducer<{ key: KeyType; tag?: string; data: PageableCacheData }>;
  readonly pageableCacheClearByTag: Reducer<{ tag: string }>;
};

const slice = createSlice<PageableCacheState, Reducers, 'pageable/cache'>({
  name: 'pageable/cache',
  initialState: {},
  reducers: {
    pageableCachePut: (state, { payload }) => {
      const { key, tag, data } = payload;
      state[key] = { tag, data };
    },
    pageableCacheClearByTag: (state, { payload }) => {
      const { tag } = payload;
      Object.keys(state).forEach(key => {
        if (state[key].tag === tag) {
          delete state[key];
        }
      });
    },
  },
});

export const { pageableCachePut, pageableCacheClearByTag } = slice.actions;

export default slice.reducer;
