import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const divider: OverrideThemeFunc<Components> = baseTheme => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: baseTheme.palette.secondary.A900,
      },
    },
  },
});

export default divider;
