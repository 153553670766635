import { Typography } from '@mui/material';
import { Wrapper } from './controls';
import Splitter from 'presentation/components/common/splitter';

type StatusTextProps = {
  readonly status: string;
  readonly requisites: string;
};

const StatusText = ({ status, requisites }: StatusTextProps) => {
  return (
    <Wrapper>
      <Typography>{status}</Typography>
      <Splitter
        variant='vertical'
        size={0.5}
      />
      <Typography
        textAlign='center'
        color='textSecondary'
      >
        {requisites}
      </Typography>
    </Wrapper>
  );
};

export default StatusText;
