import 'overlayscrollbars/css/OverlayScrollbars.css';
import { FCC } from 'react';
import { Wrapper } from './controls';

type CategorySelectItemWrapperProps = {
  readonly onClick?: () => void;
};

const CategorySelectItemWrapper: FCC<CategorySelectItemWrapperProps> = ({ children, onClick }) => {
  return (
    <Wrapper
      onClick={onClick}
      underline='none'
    >
      {children}
    </Wrapper>
  );
};

export default CategorySelectItemWrapper;
