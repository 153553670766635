import { Divider, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';

export const PeriodHelperText = `
Выберите периоды, в которые планируете свой отдых. Для начала выбора периода нажмите на дату, в которую планируете въезд. 
Для завершения выбора периода выберите дату выезда. При однодневном заселении необходимо выбрать даты последовательно. 
Отметьте количество гостей для заселения. 
`;

export const DaysHelperText = `
Выберите даты, в которые планируете посетить услуги. Даты можно выбирать не последовательно. 
Для получения услуги необходимо выбрать как минимум одну дату. 
Отметьте количество гостей для получения услуги в выбранную дату.
`;

export const countWidth = '104px';

export const TableHeader = (
  <MPGrid
    container
    justifyContent='space-between'
  >
    <Splitter size={2} />
    <MPGrid item>
      <Typography
        variant='body2'
        color='textSecondary'
      >
        Выбранные даты
      </Typography>
    </MPGrid>
    <MPGrid
      item
      width={countWidth}
    >
      <Typography
        variant='body2'
        color='textSecondary'
      >
        Гостей
      </Typography>
    </MPGrid>
    <Splitter size={1} />
    <MPGrid
      item
      xs={12}
    >
      <Divider />
    </MPGrid>
    <Splitter size={1.5} />
  </MPGrid>
);
