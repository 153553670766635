import { Typography } from '@mui/material';

type CodeTitleProps = {
  readonly code: string;
  readonly text?: string;
};

const CodeTitle = ({ code, text = 'Код предложения' }: CodeTitleProps) => {
  return (
    <Typography
      variant='body2'
      color='textSecondary'
      gutterBottom
    >
      {text}: {code}
    </Typography>
  );
};

export default CodeTitle;
