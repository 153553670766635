import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OfferFilterPrice } from '../../../components/filterPrice';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { ProductFilterItem } from '../item';
import {
  productOfferFilterMaxPriceSelector,
  productOfferFilterMinPriceSelector,
  productOfferFilterPriceRangeSelector,
} from '../store/selectors';

const ProductFilterPriceAdapter = () => {
  const handlers = useContextHandlers();

  const priceRange = useSelector(productOfferFilterPriceRangeSelector);
  const minPrice = useSelector(productOfferFilterMinPriceSelector);
  const maxPrice = useSelector(productOfferFilterMaxPriceSelector);

  return useMemo(
    () => (
      <ProductFilterItem
        label='Цена'
        isActive
      >
        <OfferFilterPrice
          min={minPrice}
          max={maxPrice}
          value={priceRange}
          onChange={handlers.onChangePrice}
        />
      </ProductFilterItem>
    ),
    [minPrice, maxPrice, priceRange, handlers.onChangePrice]
  );
};

export default ProductFilterPriceAdapter;
