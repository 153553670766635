import { FormControlLabel, Typography } from '@mui/material';
import { BookingServicesItem } from 'domain/model/bookingOffer';
import { PriceRangeFilter } from 'domain/model/offer';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';
import { OfferFilterPrice } from '../../../components/filterPrice';
import { UseBookingOfferList } from '../../types';
import { PriceWrapper, ServicesWrapper, StyledCheckbox, Wrapper } from './controls';

type BookingOfferListAsideProps = Pick<
  UseBookingOfferList,
  'onChangePriceRange' | 'onSelectService' | 'onResetFilters'
> & {
  readonly isFilterFetching: boolean;
  readonly allServices?: Nullable<BookingServicesItem[]>;
  readonly availableServices?: Nullable<BookingServicesItem[]>;
  readonly availableRange?: PriceRangeFilter;
  readonly priceFilter: PriceRangeFilter;
  readonly filterCount: number;
  readonly services: Nullable<string[]>;
};

const BookingOfferListFilter: FC<BookingOfferListAsideProps> = ({
  isFilterFetching,
  allServices,
  availableServices,
  priceFilter,
  filterCount,
  services,
  availableRange,
  onChangePriceRange,
  onSelectService,
  onResetFilters,
}) => {
  const { minPrice: minValue, maxPrice: maxValue } = availableRange ?? {};

  const hasPrice = typeof minValue === 'number' && typeof maxValue === 'number' && minValue >= 0 && maxValue > 0;
  const hasServices = allServices && availableServices;
  const servicesIds = availableServices?.map(({ id }) => id) || [];
  const actualValue = {
    minPrice: priceFilter.minPrice ?? minValue ?? null,
    maxPrice: priceFilter.maxPrice ?? maxValue ?? null,
  };

  return (
    <Wrapper id='filter-container'>
      {hasPrice && (
        <>
          <PriceWrapper>
            <Typography
              variant='subtitle1'
              mb={2}
            >
              Цена
            </Typography>
            <OfferFilterPrice
              isDisabled={isFilterFetching}
              min={minValue}
              max={maxValue}
              value={actualValue}
              onChange={onChangePriceRange}
            />
          </PriceWrapper>
        </>
      )}
      {hasServices && (
        <>
          <Typography
            variant='subtitle1'
            mb={2}
          >
            Услуги
          </Typography>
          <ServicesWrapper>
            {allServices?.map(service => {
              const isChecked = !services ? false : services.includes(service.id);
              return (
                <MPGrid
                  item
                  key={service.id}
                >
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        size='small'
                        color='brand'
                        checked={!services ? false : services.includes(service.id)}
                        // TODO заменить на data-item, когда (и если) станет много услуг
                        onChange={() => onSelectService(service.id)}
                        disabled={!servicesIds.includes(service.id) && !isChecked}
                      />
                    }
                    label={service.name}
                  />
                </MPGrid>
              );
            })}
          </ServicesWrapper>
        </>
      )}
      {(hasPrice || hasServices) && (
        <MPButton
          fullWidth
          disabled={isFilterFetching || !filterCount}
          variant='outlined'
          color='brand'
          size='small'
          onClick={onResetFilters}
        >
          Сбросить фильтры
        </MPButton>
      )}
    </Wrapper>
  );
};

export default BookingOfferListFilter;
