import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

type DetailSwiperWrapperProps = {
  readonly aspectRatio?: number;
};

export const DetailSwiperWrapper = styled.div<DetailSwiperWrapperProps>(
  ({ theme, aspectRatio = 'auto' }) => css`
    height: 100%;
    width: 100%;
    position: relative;

    .swiper {
      height: 100%;
      aspect-ratio: ${aspectRatio};
    }

    .slider {
      height: 100%;
    }

    .swiper-slide {
      height: auto;
      cursor: pointer;
      overflow: hidden;
      text-align: center;

      ${theme.breakpoints.up('md')} {
        border-radius: ${(theme.shape.borderRadius as number) * 2}px;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center center;
      }
    }

    && .swiper-pagination {
      bottom: 0;
      line-height: ${theme.spacing(3.5)};
    }

    && .swiper-pagination-bullet {
      width: ${theme.spacing(1.5)};
      height: ${theme.spacing(1.5)};
      border-radius: ${theme.spacing(0.5)};
      background: hsla(0, 0%, 100%, 0.4);
    }

    && .swiper-pagination-bullet-active {
      background: ${theme.palette.white.main};
    }
  `
);
