import useAspOfferCategoriesData from './useAspOfferCategoriesData';
import useAspOfferDetailsData from './useAspOfferDetailsData';
import useAspOfferPartnerDeskData from './useAspOfferPartnerDeskData';

type TradeDetailsLoadingStatusProps = {
  readonly id: UUID;
};

type UseAspOfferDetailsFetchingStatus = boolean;

const useAspOfferDetailsLoadingStatus = ({ id }: TradeDetailsLoadingStatusProps): UseAspOfferDetailsFetchingStatus => {
  const { isCategoriesLoading } = useAspOfferCategoriesData();
  const { isAspOfferLoading } = useAspOfferDetailsData(id);
  const { isPartnerDeskLoading } = useAspOfferPartnerDeskData(id);

  return isCategoriesLoading || isAspOfferLoading || isPartnerDeskLoading;
};

export default useAspOfferDetailsLoadingStatus;
