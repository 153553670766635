import { Typography } from '@mui/material';

const InActive = (
  <>
    <Typography variant='body1'>Извините, данный объект временно не обслуживается.</Typography>
    <Typography variant='body1'>
      Вы сможете снова воспользоваться услугами объекта после возобновления его работы.
    </Typography>
  </>
);

export default InActive;
