import { useState } from 'react';
import { MailIcon } from '../../../../../media/icons';
import { ChangeEmailContainer } from '../../../../auth/changeEmail/container';
import UserProfileViewAttribute from '../../../components/attributeView';
import { UserProfileDataAdapterProps } from './index';

const label = 'Электронная почта';

export const UserProfileEmailAdapter = ({ user }: UserProfileDataAdapterProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const hideEdit = () => setEditMode(false);
  const showEdit = () => setEditMode(true);

  return (
    <>
      <UserProfileViewAttribute
        label={label}
        value={user.email}
        icon={<MailIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && <ChangeEmailContainer onClose={hideEdit} />}
    </>
  );
};
