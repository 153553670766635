import Splitter from 'presentation/components/common/splitter';
import OrderStub from '../stub';
import { Divider, Stack } from '@mui/material';

const NoAnyOrderStub = () => {
  return (
    <Stack width='100%'>
      <Divider />
      <Splitter size={3} />
      <OrderStub alignCenter />
    </Stack>
  );
};

export default NoAnyOrderStub;
