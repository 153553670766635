import { FC } from 'react';
import { Fab, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { OptionItemWrapper } from './controls';

type OptionProps = {
  icon: React.ReactNode;
  text: string;
  onClick: VoidFunction;
};

const Option: FC<OptionProps> = ({ icon, text, onClick }) => {
  return (
    <>
      <OptionItemWrapper>
        <Fab
          color='secondaryLight'
          size='medium'
          onClick={onClick}
          variant='circular'
        >
          {icon}
        </Fab>
        <Splitter size={1} />
        <Typography
          textAlign='center'
          variant='caption'
        >
          {text}
        </Typography>
      </OptionItemWrapper>
    </>
  );
};

export default Option;
