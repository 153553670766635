import { Fade, Typography } from '@mui/material';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { ReactNode } from 'react';
import { StubImage } from './controls';
import { Wrapper } from './controls';

export type OfferDetailsErrorLayoutProps = {
  readonly toolbar?: ReactNode;
  readonly stubImage?: string;
  readonly text: string;
};

const OfferDetailsErrorLayout = ({ toolbar, text, stubImage }: OfferDetailsErrorLayoutProps) => {
  return (
    <>
      {toolbar && <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>}
      <Fade in>
        <Wrapper>
          <Typography
            variant='h2'
            component='h1'
            textAlign='center'
          >
            {text}
          </Typography>
          {stubImage && (
            <StubImage
              src={stubImage}
              alt=''
            />
          )}
        </Wrapper>
      </Fade>
    </>
  );
};

export default OfferDetailsErrorLayout;
