import AspOfferBreadcrumbs from '../../components/breadcrumbs';
import useAspOfferCategoriesData from '../hooks/useAspOfferCategoriesData';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';

type AspOfferBreadcrumbAdapterProps = {
  readonly id: UUID;
};

export const AspOfferBreadcrumbAdapter = (props: AspOfferBreadcrumbAdapterProps) => {
  const { id } = props;
  const { categories } = useAspOfferCategoriesData();
  const { aspOffer } = useAspOfferDetailsData(id);

  return (
    <AspOfferBreadcrumbs
      offerCategories={aspOffer?.categories ?? null}
      dictionary={categories ?? null}
    />
  );
};

