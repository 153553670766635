import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useMemo } from 'react';
import HeaderLinks from '../components/links';

const HeaderLinksAdapter = () => {
  const { isAuthenticated } = useAuth();

  return useMemo(
    () => (
      <HeaderLinks
        isAuthenticated={isAuthenticated}
        reverseDirection
      />
    ),
    [isAuthenticated]
  );
};

export default HeaderLinksAdapter;
