import { useSelector } from 'react-redux';
import { useGetCorpOfferCategoriesUsedTreeQuery } from 'data/api/corpOffer';
import { ECatalogTab } from 'presentation/features/catalog/types';
import { catalogTabSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { CatalogOffersContainer } from '../components/catalogContainer';

type CatalogCorpOffersTabAdapterProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogCorpOffersTabAdapter = (props: CatalogCorpOffersTabAdapterProps) => {
  const { selected } = props;
  const { onSelectCorpOffersCategory } = useContextHandlers();

  const tab = useSelector(catalogTabSelector);

  const isActiveTab = tab === ECatalogTab.CorpOffers;

  const response = useGetCorpOfferCategoriesUsedTreeQuery({ guid: 'catalog-corp-offers' }, { skip: !isActiveTab });

  return isActiveTab ? (
    <CatalogOffersContainer
      {...response}
      selected={selected}
      onSelect={onSelectCorpOffersCategory}
    />
  ) : null;
};
