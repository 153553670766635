import { useCallback, useEffect, useRef } from 'react';
import { IFrame, IFrameWrapper } from '../../claim/adapters/widget/controls';

type TradeOfferIframeProps = {
  readonly wrapperRef: any;
  readonly srcDoc: string;
};

const minIframeHeight = 150;

const TradeOfferIframe = (props: TradeOfferIframeProps) => {
  const { wrapperRef: widgetWrapperRef, srcDoc } = props;

  const iframeWrapperRef = useRef<any>();
  const iframeRef = useRef<any>();

  const calculateIframeSize = useCallback(
    (element: any) => {
      if (element) {
        const iframeContentHeight: number = Math.max(
          parseFloat(getComputedStyle(element)?.height ?? 0) ?? element.offsetHeight ?? 0,
          minIframeHeight
        );
        if (iframeContentHeight > 0) {
          const newHeight = `calc(${iframeContentHeight}px + 10rem)`;
          if (widgetWrapperRef.current.style.detailSwiperHeight === newHeight) {
            return false;
          }

          widgetWrapperRef.current.style.detailSwiperHeight = newHeight;
          return true;
        }
      } else {
        widgetWrapperRef.current.style.detailSwiperHeight = `auto`;
        return true;
      }
      return false;
    },
    [widgetWrapperRef]
  );

  useEffect(() => {
    if (iframeRef.current && widgetWrapperRef.current && iframeWrapperRef.current) {
      let notChangedIframeSizeCounter = 0;
      const interval = setInterval(() => {
        const documentElement = iframeRef.current?.contentDocument?.documentElement;
        const isChanged = calculateIframeSize(documentElement);
        if (!isChanged) {
          notChangedIframeSizeCounter++;
        }
        if (notChangedIframeSizeCounter >= 10) {
          clearInterval(interval);
        }
      }, 200);

      return () => {
        clearInterval(interval);
      };
    }

    if (!iframeRef.current) {
      calculateIframeSize(null);
    }
  }, [iframeRef, widgetWrapperRef, iframeWrapperRef, calculateIframeSize]);

  return (
    <IFrameWrapper
      ref={iframeWrapperRef}
      item
      role='iframe'
    >
      <IFrame
        ref={iframeRef}
        width='100%'
        height='100%'
        srcDoc={srcDoc ?? ''}
      />
    </IFrameWrapper>
  );
};

export default TradeOfferIframe;
