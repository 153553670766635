import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledButton = styled(MPButton)(
  ({ theme }) => css`
    min-width: auto;
    cursor: default;
    border-radius: ${theme.shape.borderRadius as number}px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};

    &:hover {
      background: ${theme.palette.secondary.main};
    }
  `
);
