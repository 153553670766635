import rootRouting from '../../../../../../routing';
import { HomeSimpleIcon } from '../../../../../media/icons';
import HeaderOption, { HeaderOptionProps } from '../../option';

const HomepageOption = (props: Partial<HeaderOptionProps>) => {
  return (
    <HeaderOption
      icon={HomeSimpleIcon}
      to={rootRouting.root}
      {...props}
    />
  );
};

export default HomepageOption;
