import { CmsCollectionLayoutType } from 'presentation/features/cms/layouts/collection/types';
import { HeaderTitleWrapper, HeaderWrapper, Wrapper } from './controls';

export const CmsCollectionLayout: CmsCollectionLayoutType = props => {
  const { footer, header, showAll, children } = props;

  return (
    <Wrapper>
      {(header || showAll) && (
        <HeaderWrapper>
          <HeaderTitleWrapper>{header}</HeaderTitleWrapper>
          {showAll}
        </HeaderWrapper>
      )}
      {children}
      {footer}
    </Wrapper>
  );
};
