import styled from '@emotion/styled/macro';
import { css, Grid } from '@mui/material';

export const Wrapper = styled(Grid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
  `
);

export const StubWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    bottom: 0;

    display: flex;
  `
);
