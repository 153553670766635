import styled from '@emotion/styled/macro';
import { css, svgIconClasses } from '@mui/material';
import { MPChip } from '../../../../../theme/ui-kit/chip';

export const ExpandChip = styled(MPChip)(
  ({ theme }) => css`
    .${svgIconClasses.root} {
      margin-left: ${theme.spacing(0.5)};
    }
  `
);

export const Label = styled.div`
  display: flex;
  align-items: center;
`;
