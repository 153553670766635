import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { MPSelect } from 'presentation/theme/ui-kit/select';

export const Select = styled(MPSelect)(
  ({ theme }) =>
    css`
      min-height: 0;
      border-radius: ${theme.shape.borderRadius}px;

      & .MuiFilledInput-root {
        background-color: transparent;
      }

      & .MuiSelect-select {
        padding: ${theme.spacing(0.5, 4, 0.5, 1.5)};

        border-radius: ${theme.shape.borderRadius}px;
        background-color: ${theme.palette.secondary.light};
      }
    `
) as any;
