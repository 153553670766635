import { Typography } from '@mui/material';
import { GreenOKIcon } from 'presentation/media/icons';

const BookingOfferOrderHeader = (
  <>
    <Typography
      variant='h1'
      mb={2}
    >
      Заказ создан &nbsp;
      <GreenOKIcon />
    </Typography>
    <Typography variant='body2'>Заказ оформлен успешно и отправлен партнёру.</Typography>
    <Typography
      variant='body2'
      mb={2}
    >
      Дождитесь звонка от менеджера партнёра для согласования деталей бронирования и окончательной стоимости.
    </Typography>
  </>
);

export default BookingOfferOrderHeader;
