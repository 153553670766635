import Attributes from 'presentation/features/offer/product/components/attributes';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';

type ProductAttributesAdapterProps = {
  readonly id: UUID;
}

const ProductAttributesAdapter = ({ id }: ProductAttributesAdapterProps) => {
  const { productOffer } = useProductOfferDetailsData(id);

  return productOffer?.productDesk?.id ? (
    <Attributes
      deskId={productOffer.productDesk.id}
      productId={productOffer.id}
      selectedVariant={productOffer}
    />
  ) : null;
};

export default ProductAttributesAdapter;
