import { Stack, Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';

export const AspOfferClaimPendingInfoMessage = () => {
  return (
    <ClaimMessage type='info'>
      <Stack spacing={1}>
        <Typography
          variant='body2'
          color='black'
        >
          Оповестим вас и отправим сертификат на почту как только все будет готово.
        </Typography>
        <Typography
          variant='body2'
          color='black'
        >
          Сертификат можно будет скачать в разделе «Мои привилегии».
        </Typography>
      </Stack>
    </ClaimMessage>
  );
};
