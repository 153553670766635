import { Fade } from '@mui/material';
import { Wrapper } from './controls';

type PreviewSnackbarProps = {
  readonly onClick: () => void;
};

const PreviewSnackbar = ({ onClick }: PreviewSnackbarProps) => {
  return (
    <Fade in>
      <Wrapper onClick={onClick}>Выйти из Превью-режима</Wrapper>
    </Fade>
  );
};

export default PreviewSnackbar;
