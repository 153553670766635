import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useAspOfferDetailsData from '../../hooks/useAspOfferDetailsData';
import { useAuth } from '../../../../../auth/provider/useAuth';
import { OfferFavoriteCommonContainer } from '../../../../favorite';
import { EOfferType } from '../../../../../../../domain/model/enums';

type SmartPhoneFixedBarAdapterProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

export const SmartPhoneFixedBarAdapter = ({ id, guid }: SmartPhoneFixedBarAdapterProps) => {
  const { isAuthenticated } = useAuth();
  const { onBack } = useContextHandlers();

  const { aspOffer } = useAspOfferDetailsData(id);

  if (!aspOffer) {
    return null;
  }

  return (
    <OfferSmartPhoneFixedBar
      text={aspOffer.name}
      favorite={
        isAuthenticated && (
          <OfferFavoriteCommonContainer
            guid={guid}
            offerId={id}
            offerType={EOfferType.Trade}
          />
        )
      }
      onBack={onBack}
    />
  );
};
