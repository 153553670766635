import styled from '@emotion/styled/macro';

export const InfoWrapper = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: minmax(0, auto) auto;
  grid-column-gap: ${p => p.theme.spacing()};
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  flex: 0 0 auto;
  
  width: 2rem;
  height: 2rem;

  ${p => p.theme.breakpoints.down('md')} {
    width: 1.75rem;
    height: 1.75rem;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
