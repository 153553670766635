import { Typography } from '@mui/material';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';

type TradeOfferDetailsTitleAdapterProps = {
  readonly id: UUID;
}

const TradeOfferDetailsTitleAdapter = ({ id }: TradeOfferDetailsTitleAdapterProps) => {
  const { tradeOffer } = useTradeOfferDetailsData(id);

  if (!tradeOffer) {
    return null;
  }

  return <Typography variant='h1'>{tradeOffer.name}</Typography>;
};

export default TradeOfferDetailsTitleAdapter;
