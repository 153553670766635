import { ChipProps, Typography } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { SubCategoryChip } from './controls';

const OfferSubCategoryChipItem = (props: Omit<LinkProps, 'variant'> & ChipProps) => {
  const { label, ...otherProps } = props;

  return (
    <SubCategoryChip
      component={Link}
      variant={'filled'}
      clickable
      label={<Typography variant='body1'>{label}</Typography>}
      {...otherProps}
    />
  );
};

export default OfferSubCategoryChipItem;
