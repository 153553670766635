import { useGetCorpOfferCategoriesQuery } from 'data/api/corpOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';

const useCorpOfferCategoriesData = () => {
  const {
    data: categories,
    isFetching: categoriesIsFetching,
    isLoading: isCategoriesLoading,
    error: categoriesFetchError,
  } = useGetCorpOfferCategoriesQuery({});

  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
    }
  }, [categoriesFetchError]);

  return {
    categories,
    categoriesIsFetching,
    categoriesFetchError,
    isCategoriesLoading,
  };
};

export default useCorpOfferCategoriesData;
