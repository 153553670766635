import ClearIcon from '@mui/icons-material/Clear';
import { ButtonProps, Typography } from '@mui/material';
import { ButtonLink } from 'presentation/components/common/buttons/link';

type CartRemoveButtonProps = ButtonProps & {
  readonly count?: number;
};

const CartRemoveButton = (props: CartRemoveButtonProps) => {
  return (
    <ButtonLink
      startIcon={<ClearIcon color='secondary' />}
      {...props}
    >
      <Typography color='textPrimary'>Удалить {props.count || ''}</Typography>
    </ButtonLink>
  );
};

export default CartRemoveButton;
