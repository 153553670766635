import { CategoryTree, CategoryTreeItem } from 'domain/model/nsi';
import { useCallback, useMemo, useState } from 'react';
import { getCategoryPathById } from '../utils';

type UseCategorySelectResult = {
  readonly expanded: Nullable<CategoryTreeItem>;
  readonly expandedPath: Nullable<CategoryTreeItem[]>;
  readonly selectedCategory: Nullable<CategoryTreeItem>;
  readonly expandedFirstLevel: Nullable<CategoryTreeItem>;
  readonly setExpanded: (category: Nullable<CategoryTreeItem>) => void;
  readonly isExpanded: (category: CategoryTreeItem) => boolean;
};

type UseCategorySelectProps = {
  readonly tree: Nullable<CategoryTree>;
  readonly selected: Nullable<UUID>;
  //функционал выделения текущей категории и скролла к ней отключен
  readonly showSelected?: boolean;
};

export const useCategorySelect = (props: UseCategorySelectProps): UseCategorySelectResult => {
  const { tree, selected, showSelected } = props;

  const [expanded, setExpanded] = useState<Nullable<CategoryTreeItem>>(() =>
    showSelected && selected && tree ? getCategoryPathById(tree, selected)?.[0] ?? null : null
  );

  const selectedPath = useMemo(
    () => (selected && tree && showSelected ? getCategoryPathById(tree, selected) : null),
    [selected, tree, showSelected]
  );

  const expandedPath = useMemo(
    () => (expanded && tree ? getCategoryPathById(tree, expanded.id) : null),
    [expanded, tree]
  );

  const isExpanded = useCallback(
    (category: CategoryTreeItem) => expandedPath?.some(item => item.id === category.id) ?? false,
    [expandedPath]
  );

  const selectedCategory = selectedPath?.[selectedPath?.length - 1] ?? null;
  const expandedFirstLevel = expandedPath?.[0] ?? null;

  return {
    expanded,
    selectedCategory,
    expandedFirstLevel,
    expandedPath,
    setExpanded,
    isExpanded,
  };
};
