import { Typography } from '@mui/material';
import { ArrowLeftIcon } from 'presentation/media/icons';
import { IconWrapper, Wrapper } from './controls';

type CategoryCatalogBackButtonProps = {
  readonly text: string;
  readonly onClick: () => void;
};

const CategoryCatalogBackButton = ({ text, onClick }: CategoryCatalogBackButtonProps) => {
  return (
    <Wrapper onClick={onClick}>
      <IconWrapper
        variant='squared'
        size='small'
      >
        <ArrowLeftIcon color='inherit' />
      </IconWrapper>
      <Typography noWrap>{text}</Typography>
    </Wrapper>
  );
};

export default CategoryCatalogBackButton;
