import { FCC, ReactNode } from 'react';
import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { AboutTextSimpleLayout } from 'presentation/layouts/aboutSimple';
import useBookingOfferDetailsLoadingStatus from '../hooks/useBookingOfferDetailsLoadingStatus';
import BookingOfferBreadcrumbAdapter from '../adapters/breadcrumb';

type BookingOfferSimpleLayoutProps = {
  readonly id: UUID;
  readonly close?: ReactNode;
  readonly title: ReactNode;
};

const BookingOfferSimpleLayout: FCC<BookingOfferSimpleLayoutProps> = ({ id, title, close, children }) => {
  const isBookingOfferLoading = useBookingOfferDetailsLoadingStatus({ id });

  return (
    <Fade in>
      <DefaultContentWrapper>
        <BookingOfferBreadcrumbAdapter id={id} />
        {isBookingOfferLoading ? null : (
          <AboutTextSimpleLayout
            title={title}
            close={close}
          >
            {children}
          </AboutTextSimpleLayout>
        )}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default BookingOfferSimpleLayout;
