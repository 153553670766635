import CarouselDefaultNavigation from './component';
import { useCallback, useMemo, useRef } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';

type UseGetDefaultNavigation = {
  readonly navigationEnabled: boolean;
  readonly swiper: Nullable<SwiperClass>;
  readonly childrenCount: number;
};

const useGetDefaultCarouselNavigation = (props: UseGetDefaultNavigation) => {
  const { childrenCount, swiper, navigationEnabled } = props;

  const buttonPrevRef = useRef<Nullable<HTMLButtonElement>>(null);
  const buttonNextRef = useRef<Nullable<HTMLButtonElement>>(null);
  const onPrevClick = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  const onNextClick = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  return useMemo(
    () =>
      navigationEnabled && childrenCount > 1 ? (
        <CarouselDefaultNavigation
          swiper={swiper}
          buttonPrevRef={buttonPrevRef}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          buttonNextRef={buttonNextRef}
        />
      ) : null,
    [childrenCount, onNextClick, onPrevClick, swiper, navigationEnabled]
  );
};

export default useGetDefaultCarouselNavigation;
