import { LinearProgress, LinearProgressProps } from '@mui/material';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import { Wrapper } from './controls';

const GlobalLoaderContainer = (props: LinearProgressProps) => {
  const { headerHeight } = useHeaderParams();
  return (
    <Wrapper top={headerHeight}>
      <LinearProgress
        variant='indeterminate'
        {...props}
      />
    </Wrapper>
  );
};

export default GlobalLoaderContainer;
