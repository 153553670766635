import { CloseIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../../../components/common/icon';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { MPIconButton } from '../../../../../../theme/ui-kit/button';

const CorpOfferCloseAdapter = () => {
  const { onBack } = useContextHandlers();

  return (
    <MPIconButton
      color='secondary'
      onClick={onBack}
    >
      <AppSvgIcon icon={CloseIcon} />
    </MPIconButton>
  );
};

export default CorpOfferCloseAdapter;
