import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferType } from 'domain/model/enums';
import { createFavoriteProvideHasChangesSelector } from 'presentation/features/favorite/provide/store/selectors';
import { favoritesProvideClear } from 'presentation/features/favorite/provide/store/slice';
import {
  EUserFavoritesTab,
  getUserFavoritesFirstActiveTab,
  getUserFavoritesTabList,
  UserFavoritesTabInfo,
} from '../utils';
import { UseUserFavoritesTabsData, useUserFavoritesTabsData } from './useTabsData';

type UseUserFavoritesTabsProps = {
  readonly guid: UUID;
  readonly tab: Nullable<EUserFavoritesTab>;
  readonly isCorpUser: boolean;
};

type UseUserFavoritesTabs = UseUserFavoritesTabsData & {
  readonly tabToDisplay: Nullable<UserFavoritesTabInfo>;
  readonly tabList: UserFavoritesTabInfo[];
};

export const useUserFavoritesTabs = (props: UseUserFavoritesTabsProps): UseUserFavoritesTabs => {
  const { guid, tab, isCorpUser } = props;

  const dispatch = useDispatch();

  const { isFetching, tradeOffersCount, corpOffersCount, productOffersCount, bookingOffersCount } =
    useUserFavoritesTabsData();

  const hasTradeOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Trade));
  const hasCorpOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Corp));
  const hasProductOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Product));
  const hasBookingOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Booking));

  const tabList = useMemo(
    () =>
      getUserFavoritesTabList({
        isFetching,
        isCorpUser,
        hasTradeOffersChanges,
        hasCorpOffersChanges,
        hasProductOffersChanges,
        hasBookingOffersChanges,
        tradeOffersCount,
        corpOffersCount,
        productOffersCount,
        bookingOffersCount,
      }),
    [
      isFetching,
      isCorpUser,
      hasTradeOffersChanges,
      hasCorpOffersChanges,
      hasProductOffersChanges,
      hasBookingOffersChanges,
      tradeOffersCount,
      corpOffersCount,
      productOffersCount,
      bookingOffersCount,
    ]
  );

  const tabToDisplay = useMemo<Nullable<UserFavoritesTabInfo>>(
    () => getUserFavoritesFirstActiveTab(tab, tabList),
    [tabList, tab]
  );

  useEffect(() => {
    return () => {
      dispatch(favoritesProvideClear({ guid }));
    };
  }, [dispatch, guid]);

  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    productOffersCount,
    bookingOffersCount,
    tabToDisplay,
    tabList,
  };
};
