import { FC, useState } from 'react';
import { ChangeEmailAdapter, CodeConfirmationAdapter, UserSuccessEmailUpdateAdapter } from './adapters';

enum EDialogType {
  Change = 'Change',
  Code = 'Code',
  Success = 'Success',
}

type ChangeEmailContainerProps = {
  readonly onClose: () => void;
};

export const ChangeEmailContainer: FC<ChangeEmailContainerProps> = ({ onClose }) => {
  const [activeDialog, setActiveDialog] = useState<EDialogType>(EDialogType.Change);

  const onNext = () => {
    switch (activeDialog) {
      case EDialogType.Change:
        setActiveDialog(EDialogType.Code);
        break;
      case EDialogType.Code:
        setActiveDialog(EDialogType.Success);
        break;
      case EDialogType.Success:
        onClose();
        break;
    }
  };

  return (
    <>
      {activeDialog === EDialogType.Change && (
        <ChangeEmailAdapter
          onNext={onNext}
          onClose={onClose}
        />
      )}
      {activeDialog === EDialogType.Code && (
        <CodeConfirmationAdapter
          onClose={onClose}
          onNext={onNext}
        />
      )}
      {activeDialog === EDialogType.Success && <UserSuccessEmailUpdateAdapter onNext={onNext} />}
    </>
  );
};
