import { FC } from 'react';
import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { BookingDetailsId } from '../../types';
import { useAuth } from '../../../../../auth/provider/useAuth';
import { OfferFavoriteCommonContainer } from '../../../../favorite';
import { EOfferType } from '../../../../../../../domain/model/enums';

type SmartPhoneFixedBarAdapterProps = BookingDetailsId & {
  readonly guid: UUID;
};

const SmartPhoneFixedBarAdapter: FC<SmartPhoneFixedBarAdapterProps> = ({ id, guid }) => {
  const { isAuthenticated } = useAuth();
  const { onBack } = useContextHandlers();

  const { bookingOffer } = useBookingOfferDetailsData(id);

  if (!bookingOffer) {
    return null;
  }

  return (
    <OfferSmartPhoneFixedBar
      onBack={onBack}
      favorite={
        isAuthenticated && (
          <OfferFavoriteCommonContainer
            guid={guid}
            offerId={id}
            offerType={EOfferType.Booking}
          />
        )
      }
      text={bookingOffer.name}
    />
  );
};

export default SmartPhoneFixedBarAdapter;
