import { Fade, Typography } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import Splitter from '../../components/common/splitter';
import UserDetailsToolbarLayout from '../userDetailsToolbar';
import { ContentWrapper, Wrapper } from './controls';
import { UserDetailsLayoutType } from './types';

const UserDetailsLayout: UserDetailsLayoutType = props => {
  const { header, toolbar, loader, children } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        <Wrapper>
          <Typography variant='h1'>{header}</Typography>
          <Splitter size={3} />

          {toolbar && (
            <UserDetailsToolbarLayout>
              {toolbar}
              <Splitter size={3} />
            </UserDetailsToolbarLayout>
          )}

          <ContentWrapper>{children}</ContentWrapper>
          {loader}
        </Wrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default UserDetailsLayout;
