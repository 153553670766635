import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import OfferDetailsLayout from 'presentation/layouts/offerDetail';
import { ERenderSource } from 'presentation/types';
import {
  AspOfferBreadcrumbAdapter,
  AspOfferCarouselAdapter,
  AspOfferCloseAdapter,
  AspOfferConditionsAdapter,
  AspOfferDetailsFavoriteAdapter,
  AspOfferDetailsLoaderAdapter,
  AspOfferDetailsSidebarAdapter,
  AspOfferDetailsTabsAdapter,
  AspOfferDetailsTitleAdapter,
  AspOfferDetailStub,
  AspOfferPartnerInfoAdapter,
  SmartPhoneFixedBarAdapter,
} from './adapters';
import { AspOfferDetailContext } from './context';
import useAspOfferDetails from './hooks/useAspOfferDetails';
import useAspOfferDetailsLoadingStatus from './hooks/useAspOfferDetailsLoadingStatus';
import { EAspOfferDetailsTab } from './utils';

type AspOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: EAspOfferDetailsTab;
  readonly from: ERenderSource;
};

const Layout = OfferDetailsLayout;

const AspOfferDetailsContainer = ({ id, guid, tab, from }: AspOfferDetailsContainerProps) => {
  const handlers = useAspOfferDetails({ id, guid, from });

  return (
    <AspOfferDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <CommonRenderManager
          loader={<AspOfferDetailsLoaderAdapter id={id} />}
          stub={AspOfferDetailStub}
        >
          <Layout
            id={id}
            favorite={
              <AspOfferDetailsFavoriteAdapter
                id={id}
                guid={guid}
              />
            }
            description={<AspOfferConditionsAdapter id={id} />}
            sidebar={
              <AspOfferDetailsSidebarAdapter
                guid={guid}
                id={id}
              />
            }
            smartphoneFixedBar={
              <SmartPhoneFixedBarAdapter
                guid={guid}
                id={id}
              />
            }
            tabs={
              <AspOfferDetailsTabsAdapter
                guid={guid}
                tab={tab}
                id={id}
              />
            }
            partnerInfo={<AspOfferPartnerInfoAdapter id={id} />}
            title={<AspOfferDetailsTitleAdapter id={id} />}
            breadcrumb={<AspOfferBreadcrumbAdapter id={id} />}
            image={<AspOfferCarouselAdapter id={id} />}
            close={<AspOfferCloseAdapter />}
            /*related={<AspOfferDetailsRelatedAdapter id={id} />} сделано на будущее, пока отключено*/
            useLoading={useAspOfferDetailsLoadingStatus}
          />
        </CommonRenderManager>
      </RenderManagerErrorProvider>
    </AspOfferDetailContext.Provider>
  );
};

export default AspOfferDetailsContainer;
