import { LinkProps, Link } from '@mui/material';
import AppSvgIcon from '../../../components/common/icon';
import Splitter from '../../../components/common/splitter';
import SvgIcon from '@mui/material/SvgIcon';

type MPLinkProps = LinkProps & {
  readonly startIcon?: typeof SvgIcon;
  readonly iconSize?: 'micro' | 'small' | 'medium' | 'large';
};

export const MPLink = ({ children, startIcon, iconSize = 'micro', ...props }: MPLinkProps) => {
  return (
    <Link {...props}>
      {startIcon && (
        <>
          <AppSvgIcon
            fontSize={iconSize}
            icon={startIcon}
          />
          <Splitter
            size={1}
            variant='vertical'
          />
        </>
      )}
      {children}
    </Link>
  );
};
