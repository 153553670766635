import ScreenLayout from 'presentation/layouts/screen';
import BookingOfferOrderContainer from '../../features/offer/booking/order/container';
import { useParams } from 'react-router';

const BookingOfferOrder = () => {
  const { id } = useParams<{ id: UUID }>();

  return (
    <ScreenLayout footer>
      <BookingOfferOrderContainer id={id} />
    </ScreenLayout>
  );
};

export default BookingOfferOrder;
