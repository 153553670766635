import React from 'react';

export type CurrentUserBalanceContextType = {
  readonly value: Nullable<number>;
  readonly isFetching: boolean;
  readonly refreshBalance: () => void;
};

const CurrentUserBalanceContext = React.createContext<CurrentUserBalanceContextType>({
  value: null,
  isFetching: false,
  refreshBalance: () => null,
});

export default CurrentUserBalanceContext;
