import { useMediaQuery, useTheme } from '@mui/material';
import { RootState } from 'data/store/store';
import { Location } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useHistoryExtensions from './useHistoryExtensions';

const dialogParamName = 'dialog';
const defaultTag = 'default';

type UseDialogInHistoryProps = {
  readonly tag?: string;
  readonly isActiveOnDesktop?: boolean;
  readonly visibleSelector?: (state: RootState) => boolean;
};

type UseDialogInHistoryResult = {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
};

type UseDialogInHistory = (props: UseDialogInHistoryProps) => UseDialogInHistoryResult;

const emptyVisibleSelector = () => null;

const useDialogInHistory: UseDialogInHistory = ({
  tag = defaultTag,
  isActiveOnDesktop = false,
  visibleSelector,
}: UseDialogInHistoryProps) => {
  const location = useLocation();
  const history = useHistory();

  const initialVisibleParamFromStore: Nullable<boolean> = useSelector(visibleSelector ?? emptyVisibleSelector);

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isActive: boolean = isSmDown || isActiveOnDesktop;

  const dialogTag: Nullable<string> = new URLSearchParams(location.search).get(dialogParamName);

  const [initialVisible, setInitialVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { locationCollector } = useHistoryExtensions();

  const getNewLocation = useCallback(
    (currentLocation: Location, addDialogTag: boolean): Location => {
      const searchParams = new URLSearchParams(currentLocation.search);
      searchParams.delete(dialogParamName);
      if (addDialogTag) {
        searchParams.append(dialogParamName, tag);
      }
      return {
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
        hash: location.hash,
        state: location.state,
        key: location.key,
      };
    },
    [location.hash, location.key, location.pathname, location.state, tag]
  );

  const onOpen = useCallback(() => {
    if (isActive) {
      const existedParam = new URLSearchParams(location.search).get(dialogParamName) === tag;
      if (existedParam) {
        return;
      }

      const newLocation = getNewLocation(location, true);
      if (initialVisible) {
        setInitialVisible(false);
        history.replace(newLocation);
      } else {
        history.push(newLocation);
      }
    } else {
      setOpen(true);
    }
  }, [getNewLocation, history, initialVisible, isActive, location, tag]);

  const onClose = useCallback(() => {
    if (isActive) {
      const existedParam = new URLSearchParams(location.search).get(dialogParamName) === tag;
      if (!existedParam) {
        return;
      }

      setInitialVisible(false);

      if (locationCollector.length > 0) history.goBack();
      else {
        const search = new URLSearchParams(location.search);
        search.delete(dialogParamName);

        const newLocation = {
          ...location,
          search: `?${search.toString()}`,
        };

        history.replace(newLocation);
      }
    } else {
      setOpen(false);
    }
  }, [isActive, history, location, locationCollector, tag]);

  useEffect(() => {
    if (tag === dialogTag) {
      setInitialVisible(true);
    }
  }, [dialogTag, tag]);

  useEffect(() => {
    if (initialVisible) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVisible]);

  useEffect(() => {
    switch (initialVisibleParamFromStore) {
      case true:
        onOpen();
        break;
      case false:
        onClose();
        break;
      case null:
        break;
    }
  }, [initialVisibleParamFromStore, onClose, onOpen]);

  useEffect(() => {
    setOpen(dialogTag === tag);
  }, [dialogTag, tag]);

  return {
    open,
    onOpen,
    onClose,
  };
};

export default useDialogInHistory;
