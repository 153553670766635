import base64url from 'base64url';
import { useEffect } from 'react';
import { ContentTarget } from '../../../../../domain/model/cms';
import { EAudienceType, EMultiSelectorValueType, ETargetType } from '../../../../../domain/model/enums';
import useUserLocalSettings from '../../../../hooks/useUserLocalSettings';
import { useAuth } from '../../../auth/provider/useAuth';
import { applyInterceptor, clearInterceptor } from './useClearGuestTargetInterceptor';

// для гостевого режима
// на новый заголовок "x-target-value", который автоматически добавляется во все request
export const useGuestTarget = () => {
  const { isAuthenticated } = useAuth();
  const { settings } = useUserLocalSettings();

  const city = settings?.city;

  // обновляем ls
  useEffect(() => {
    if (!isAuthenticated && city?.fiasId) {
      const target: ContentTarget = {
        targetType: ETargetType.Geo,
        targetLocalities: { in: [{ id: city.fiasId }], select: EMultiSelectorValueType.In },
        targetAudience: { in: [EAudienceType.External], select: EMultiSelectorValueType.In },
      };
      const stringifiedTarget = JSON.stringify(target);
      const encodedTargetHeader = base64url.encode(stringifiedTarget);
      applyInterceptor(encodedTargetHeader);
    }

    return () => {
      clearInterceptor();
    };
  }, [isAuthenticated, city?.fiasId]);
};
