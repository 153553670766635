import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import useTradeOfferPartnerDeskData from 'presentation/features/offer/trade/details/hooks/useTradeOfferPartnerDeskData';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';

type TradeOfferPartnerInfoProps = {
  readonly id: UUID;
}

const TradeOfferPartnerInfoAdapter = ({ id }: TradeOfferPartnerInfoProps) => {
  const history = useHistory();
  const { tradeOffer } = useTradeOfferDetailsData(id);
  const { partnerDesk } = useTradeOfferPartnerDeskData(id);
  const onPartnerClick = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id, tab: EPartnerDeskDetailsTab.TradeOffers }));
    }
  };

  if (!tradeOffer?.partner) {
    return null;
  }

  return (
    <OfferPartnerInfo
      partner={tradeOffer.partner}
      onClick={onPartnerClick}
    />
  );
};

export default TradeOfferPartnerInfoAdapter;
