import { Hidden, Typography } from '@mui/material';
import { ArrowRightIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LabelWrapper, Link } from './controls';

export type CmsContainerShowAllLinkProps = Pick<RouterLinkProps, 'to'>;

const Label = () => {
  return (
    <LabelWrapper>
      <Hidden mdDown>
        <Typography variant='body2'>Посмотреть всё</Typography>
      </Hidden>
      <ArrowRightIcon fontSize='small' />
    </LabelWrapper>
  );
};

export const CmsContainerShowAllLink: FC<CmsContainerShowAllLinkProps> = ({ to }) => {
  return (
    <Link
      component={RouterLink}
      label={<Label />}
      color='white'
      clickable
      to={to}
    />
  );
};
