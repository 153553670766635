import styled from '@emotion/styled/macro';

const ROW_HEIGHT = 2.5;
const ROW_COUNT = 4;
const UNIT_KIND = 'rem';

export const Wrapper = styled.div``;

export const ShortCharacteristics = styled.div`
  white-space: break-spaces;
  line-height: ${ROW_HEIGHT}${UNIT_KIND};
  max-height: ${ROW_COUNT * ROW_HEIGHT}${UNIT_KIND};
  overflow: hidden;
`;
