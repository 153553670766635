import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getHomepageRoute } from 'routing';
import { headerSetDialogState, headerStartSession, headerToggleDialogState } from '../store/slice';

export type UseHeader = {
  readonly onLogoClick: () => void;
  readonly showChangeLocationDialog: () => void;
  readonly hideChangeLocationDialog: () => void;
  readonly toggleCatalog: () => void;
  readonly showCatalog: () => void;
  readonly hideCatalog: () => void;
};

const useHeader = (): UseHeader => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogoClick = useCallback(() => {
    history.push(getHomepageRoute());
  }, [history]);

  const showChangeLocationDialog = useCallback(() => {
    dispatch(
      headerSetDialogState({
        name: 'changeLocation',
        value: true,
      })
    );
  }, [dispatch]);

  const hideChangeLocationDialog = useCallback(
    () =>
      dispatch(
        headerSetDialogState({
          name: 'changeLocation',
          value: false,
        })
      ),
    [dispatch]
  );

  const toggleCatalog = useCallback(() => dispatch(headerToggleDialogState('catalog')), [dispatch]);

  const showCatalog = useCallback(
    () =>
      dispatch(
        headerSetDialogState({
          name: 'catalog',
          value: true,
        })
      ),
    [dispatch]
  );

  const hideCatalog = useCallback(
    () =>
      dispatch(
        headerSetDialogState({
          name: 'catalog',
          value: false,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    dispatch(headerStartSession());
  }, [dispatch]);

  return {
    onLogoClick,
    showChangeLocationDialog,
    hideChangeLocationDialog,
    toggleCatalog,
    showCatalog,
    hideCatalog,
  };
};

export default useHeader;
