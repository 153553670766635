import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { CmsPreviewLayoutType } from './types';

const CmsPreviewLayout: CmsPreviewLayoutType = props => {
  const { children } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>{children}</DefaultContentWrapper>
    </Fade>
  );
};

export default CmsPreviewLayout;
