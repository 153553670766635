import { LinkProps as MuiLinkProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { SubCategoryLinkItem } from './controls';

const OfferSubCategoryLinkItem = (props: LinkProps & MuiLinkProps) => {
  return (
    <SubCategoryLinkItem
      variant='body1'
      color='text.primary'
      underline={'none'}
      component={Link}
      {...props}
    />
  );
};

export default OfferSubCategoryLinkItem;
