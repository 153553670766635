import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from '../../../theme/ui-kit/dialog';
import { IconWrapper, SuccessFeedBackIcon } from './controls';

type SuccessFeedBackProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const SuccessFeedBack = (props: SuccessFeedBackProps) => {
  const { open, onClose } = props;

  return (
    <MPConfirmDialog
      open={open}
      maxWidth='xs'
      title={
        <Typography
          variant='inherit'
          align='center'
        >
          Спасибо!{'\u000a'}Сообщение отправлено
        </Typography>
      }
      buttons={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          onClick={onClose}
        >
          Вернуться на витрину
        </MPButton>
      }
      onClose={onClose}
    >
      <IconWrapper>
        <SuccessFeedBackIcon fontSize='huge' />
      </IconWrapper>
    </MPConfirmDialog>
  );
};

export default SuccessFeedBack;
