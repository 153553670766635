import { AspOfferShort } from 'domain/model/aspOffer';
import { toRubCurrency } from '../../../../../utils/currency';
import { Price } from './controls';

type AspOfferListItemPriceProps = Pick<AspOfferShort, 'price'>;

const AspOfferListItemPrice = (props: AspOfferListItemPriceProps) => {
  const { price } = props;

  return (
    <Price
      variant='h3'
      component='div'
    >
      {toRubCurrency(price)}
    </Price>
  );
};

export default AspOfferListItemPrice;
