import { Address, AddressObject } from 'domain/model/address';
import { EAddressLevel, EAddressOption } from 'domain/model/enums';
import { uniqBy } from 'lodash';

export class AddressHelper {
  private address: Address;
  private separator: string | undefined;

  constructor(source: Address, separator?: string) {
    this.address = JSON.parse(JSON.stringify(source));
    this.separator = separator;

    this.optimizeLocality();
  }

  // оптимизация населенного пункта, если нет нас пункта и города то добавляется синтетический город на основе региона
  private optimizeLocality() {
    const hasLocalityObjects =
      this.address.hierarchy?.some(
        item => item.level.id >= EAddressLevel.City && item.level.id <= EAddressLevel.Settlement
      ) ?? false;
    const regionObject = this.address.hierarchy?.find(item => item.level.id === EAddressLevel.Region);
    if (!hasLocalityObjects && regionObject != null) {
      this.address.hierarchy?.splice(1, 0, {
        ...regionObject,
        level: { ...regionObject.level, id: EAddressLevel.City },
      });
    }
  }

  // получение полного адреса по иерархии
  getFullPath(props?: { options?: EAddressOption[] }) {
    return AddressStringBuilder.full(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .withOptions(...(props?.options ?? []))
      .toString();
  }

  // получение полного адреса по иерархии с почтовым индексом
  getFullPathWithPostalCode() {
    return this.getFullPath({ options: [EAddressOption.PostalCode] });
  }

  // получение наименования населенного пункта начиная с региона
  getLocalityFullPath(): string {
    return AddressStringBuilder.fromRegionToSettlement(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .toString();
  }

  // получение наименования населенного пункта без региона и образований
  getLocalityShortPath(): string {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).toString();
  }

  // получение наименования населенного пункта без региона и образований и без города/района (если это посёлок)
  // отличается от getLocalityFullPath тем, что в locality входит всё из ГОРОД+НАС_ПУНКТ, а адреса очень сложные, и вдруг будет и то и то, в данном случае учитывается последний элемент
  getLastLocalityShortPath(): string {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toString();
  }

  // получение наименования населенного пункта без региона и образований и без имени типа
  getLocalitySimpleName(): string {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toSimpleString();
  }

  // получение наименования улицы начиная с региона
  getStreetFullPath() {
    return AddressStringBuilder.fromRegionToStreet(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .toString();
  }
}

export class AddressStringBuilder {
  private readonly defaultSeparator = ', ';
  private readonly source: AddressObject[];
  private readonly sourceOptions: Partial<Record<EAddressOption, string>>;
  private readonly defaultString: string;
  private separator: string = this.defaultSeparator;
  private options: string[] = [];
  private objects: AddressObject[];

  // получение иерархии по уровню [С, ПО]
  private static getHierarchyObjects(
    address: Address,
    fromLevel: EAddressLevel,
    toLevel: EAddressLevel
  ): AddressObject[] {
    //получаем объекты иерархии адреса
    let hierarchyObjects =
      address.hierarchy
        ?.filter(item => item.level.id >= fromLevel && item.level.id <= toLevel)
        ?.filter(item => !!item) ?? [];
    //сортируем по уровню для правильного порядка
    hierarchyObjects = hierarchyObjects.sort((o1, o2) => o1.level.id - o2.level.id);
    //убираем дублирующие id, это может быть к примеру для городов федерального значения
    return uniqBy(hierarchyObjects, 'id');
  }

  // полный адрес
  static full(address: Address): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Parking);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до посёлка
  static fromRegionToSettlement(address: Address): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Settlement);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до улицы
  static fromRegionToStreet(address: Address): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Street);
    return new AddressStringBuilder(address, objects);
  }

  // населенный пункт (от города до посёлка)
  static locality(address: Address): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.City, EAddressLevel.Settlement);
    return new AddressStringBuilder(address, objects);
  }

  // получение адресных "опций" - отдельные специфические элементы из всей структуры адреса
  private static parseOptions(address: Address): Partial<Record<EAddressOption, string>> {
    const options: Partial<Record<EAddressOption, string>> = {};
    if (address.postalCode) {
      options[EAddressOption.PostalCode] = address.postalCode;
    }
    return options;
  }

  constructor(address: Address, objects: AddressObject[]) {
    this.source = [...objects];
    this.sourceOptions = AddressStringBuilder.parseOptions(address);
    this.objects = objects;
    this.defaultString = address.shortName || address.name || '';
  }

  // добавление опции - почтовый индекс
  withPostalCode(): AddressStringBuilder {
    return this.withOptions(EAddressOption.PostalCode);
  }

  // изменение доп опций
  withOptions(...options: EAddressOption[]): AddressStringBuilder {
    options.map(option => {
      const value = this.sourceOptions[option];
      if (value) {
        this.options.push(value);
      }
    });
    return this;
  }

  // изменение сепаратора для вывода в строку
  withSeparator(separator?: string): AddressStringBuilder {
    this.separator = separator || this.defaultSeparator;
    return this;
  }

  // восстановление дефолтных настроек
  restore(): AddressStringBuilder {
    this.objects = [...this.source];
    this.options = [];
    this.separator = this.defaultSeparator;
    return this;
  }

  // конвертация в строку
  toString(props: { shortName?: boolean } = { shortName: true }): string {
    return props.shortName ? this.toShortString() : this.toFullString();
  }

  // конвертация в строку с короткими наименованиями
  toShortString(): string {
    const strings = this.objects.map(item => item.shortName ?? item.name ?? null);
    const commonString = strings.join(this.separator) || this.defaultString;
    return [...this.options, commonString].join(this.separator);
  }

  // конвертация в строку с длинными наименованиями
  toFullString(): string {
    const strings = this.objects.map(item => item.name ?? item.shortName ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // конвертация в строку с короткими наименованиями (без имен типов)
  toSimpleString(): string {
    const strings = this.objects.map(item => item.values?.[0]?.value ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // исключение уровней
  withoutLevels(...levels: EAddressLevel[]): AddressStringBuilder {
    this.objects = this.objects.filter(item => item?.level && !levels.includes(item.level.id)) ?? [];
    return this;
  }

  // исключение муниципалитетов
  withoutMunicipality(): AddressStringBuilder {
    return this.withoutLevels(EAddressLevel.Municipality);
  }

  // оставляем односложное наименовании в текущей иерархии (ищем начиная с последней до первой, останавливаемся на том где есть shortName)
  lastLevel(): AddressStringBuilder {
    const lastLevel = this.objects.reverse().find(item => !!item.shortName || !!item.name);
    this.objects = lastLevel ? [lastLevel] : [];
    return this;
  }
}
