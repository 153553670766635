import { backdropClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const drawers: OverrideThemeFunc<Components> = () => ({
  MuiDrawer: {
    styleOverrides: {
      root: {
        [`.${backdropClasses.root}`]: {
          background: 'rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(2.5px)'
        }
      }
    }
  }
});

export default drawers;
