import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const MPNaturalNumberFormat = React.forwardRef<any, NumberFormatProps>((props, ref) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      allowNegative={false}
      isNumericString
      decimalScale={0}
    />
  );
});
