import { BoxIsoIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemOrdersProps = {
  readonly count?: number;
  readonly onClick?: () => void;
};

export const UserMenuItemOrders: FC<UserMenuItemOrdersProps> = ({ count, onClick }) => {
  return (
    <UserMenuItem
      label='Заказы'
      count={count}
      icon={<BoxIsoIcon color='black' />}
      onClick={onClick}
    />
  );
};
