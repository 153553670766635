import { BreadcrumbTree } from 'presentation/components/common/breadcrumb/expanded';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { useProductOfferListCategoriesData } from '../hooks/useProductOfferListCategoriesData';
import { EAppCategories } from 'presentation/types';

export const ProductOfferListBreadcrumbsAdapter = () => {
  const { categoriesToBreadcrumbs } = useProductOfferListCategoriesData();

  const { x1 } = useGridSpacing();

  const isBreadcrumbsVisible = categoriesToBreadcrumbs.length > 0;

  return (
    <>
      {isBreadcrumbsVisible && (
        <>
          <BreadcrumbTree
            items={categoriesToBreadcrumbs}
            title={EAppCategories.ProductOffers}
          />
          <Splitter size={x1} />
        </>
      )}
    </>
  );
};

export default ProductOfferListBreadcrumbsAdapter;
