import Splitter from 'presentation/components/common/splitter';
import { Children, forwardRef, Fragment } from 'react';
import { Wrapper } from './controls';
import { UserMenuLayoutProps } from './types';

const UserMenuLayout = forwardRef<any, UserMenuLayoutProps>((props, ref) => {
  const { logout, children } = props;

  return (
    <Wrapper ref={ref}>
      {Children.toArray(children).map((child, index) => (
        <Fragment key={index}>{child}</Fragment>
      ))}

      {logout && (
        <>
          <Splitter size={1.5} />
          {logout}
        </>
      )}
    </Wrapper>
  );
});

export default UserMenuLayout;
