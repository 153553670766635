import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  partnerDeskDetailsCorpOffersCountSelector,
  partnerDeskDetailsCorpOffersIsErrorSelector,
  partnerDeskDetailsCorpOffersIsFetchingSelector,
} from '../../store/selectors';

const PartnerDeskDetailsTabCorpOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsCorpOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsCorpOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsCorpOffersIsErrorSelector);

  return useMemo(
    () => (!count && !isFetching && !isError ? <div>Корпоративные предложения пока отсутствуют</div> : null),
    [count, isFetching, isError]
  );
};

export default PartnerDeskDetailsTabCorpOffersNoData;
