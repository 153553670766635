import { AxiosResponse } from 'axios';
import { Pageable } from 'domain/model';
import { OfferActivation } from 'domain/model/activation';
import { EOfferStatus, ETradeOfferSortType } from 'domain/model/enums';
import { Category, CategoryTree } from 'domain/model/nsi';
import { TradeOffer, TradeOfferShort } from 'domain/model/tradeOffer';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ETradeOfferServicesTag, OfferListRequest, OffersCountRequest, OffersCountResponse } from './index';

export type TradeOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type TradeOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type TradeOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetTradeOfferDetailsRequest = {
  readonly id: UUID;
};

export type GetTradeOfferActivationsRequest = {
  readonly id: UUID;
};

export type ActivateTradeOfferRequest = {
  readonly id: UUID;
};

export type ActivateTradeOfferResponse = Nullable<OfferActivation>;

const defaultTradeOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];
const defaultTradeOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const tradeOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getTradeOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ ids }) => {
        return apiDefinition.tradeOffer.categories({ ids });
      },
    }),
    getTradeOfferCategoriesUsed: builder.query<Category[], TradeOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ name, onlyLeafCategories }) => {
        return apiDefinition.user.trade.categoriesUsed({
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          onlyLeafCategories,
        });
      },
    }),
    getTradeOfferCategoriesUsedTree: builder.query<CategoryTree, TradeOfferCategoriesUsedTreeRequest>({
      transformResponse: (response: AxiosResponse) => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.trade.categoriesUsed({
          statuses: defaultTradeOfferValidStatuses,
          onlyLeafCategories: false,
        });
      },
    }),
    getTradeOfferList: builder.query<Pageable<TradeOfferShort>, OfferListRequest>({
      transformResponse: (response: AxiosResponse): Pageable<TradeOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, sort = [] }) => {
        const { name, categories, statuses = defaultTradeOfferActiveStatuses, partnerId } = search;
        return apiDefinition.user.trade.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId,
        });
      },
      providesTags: [ETradeOfferServicesTag.List],
    }),
    getTradeOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        const { name, categories } = search;
        return apiDefinition.user.trade.count({
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          categories,
        });
      },
    }),
    getTradeOfferSearch: builder.query<Pageable<TradeOffer>, TradeOfferSearchRequest>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse): Pageable<TradeOffer> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, pageSize }) => {
        const { name } = search;

        return apiDefinition.user.trade.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          sort: [ETradeOfferSortType.Default],
        });
      },
    }),
    getTradeOfferDetails: builder.query<TradeOffer, GetTradeOfferDetailsRequest>({
      query: ({ id }) => apiDefinition.user.trade.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, args) => [
        { type: ETradeOfferServicesTag.Details, id: args.id },
        ETradeOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetTradeOfferListQuery,
  useGetTradeOffersCountQuery,
  useGetTradeOfferSearchQuery,
  useGetTradeOfferCategoriesQuery,
  useGetTradeOfferCategoriesUsedQuery,
  useGetTradeOfferCategoriesUsedTreeQuery,
  useGetTradeOfferDetailsQuery,
} = tradeOfferApi;
