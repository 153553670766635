import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

const gridWidth = '18rem';
const gridItemHeight = '1.5rem';

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)};

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: 0;
    grid-template-columns: repeat(1, ${gridWidth});
    grid-auto-rows: minmax(${gridItemHeight}, auto);
    grid-auto-flow: row;

    ${theme.breakpoints.down('sm')} {
      width: 100%;

      margin: 0;
      padding: ${theme.spacing(0, 0, 0, 2.5)};

      display: flex;
      flex-direction: column;
    }
  `
);
