import { CrownIcon } from '../../../../../media/icons';
import { getUserActivationsRoute } from '../../../../user/entry';
import HeaderOption, { HeaderOptionProps } from '../../option';

const PrivilegeOption = (props: Partial<HeaderOptionProps>) => {
  return (
    <HeaderOption
      icon={CrownIcon}
      to={getUserActivationsRoute({})}
      {...props}
    />
  );
};

export default PrivilegeOption;
