import ContentLoader from 'presentation/components/common/loader';
import PartnerDeskDetailsFilterLayout from 'presentation/features/partnerDesk/filter/layout';
import { useCallback } from 'react';
import { alphabets } from '../../../utils/constants';
import { PartnerDesksFilter } from '../types';
import PartnerDeskFilterAlphabeticalSearch from './alphabetical';
import { useAlphabet } from './alphabetical/hooks';
import PartnerDeskFilterPatternSearch from './pattern';

type PartnerDesksFilterContainerProps = {
  readonly value: PartnerDesksFilter;
  readonly onChange: (value: PartnerDesksFilter) => void;
  readonly isFetching?: boolean;
};

const Layout = PartnerDeskDetailsFilterLayout;

const PartnerDesksFilterContainer = ({ value, onChange, isFetching }: PartnerDesksFilterContainerProps) => {
  const { digitsAndSpecialSymbols, isAlphabetLoading, localeAlphabet, currentLocale, onChangeLocale } = useAlphabet();

  const onChangePatternSearch = useCallback(
    (patternSearch: Nullable<string>) => {
      if (patternSearch) {
        onChange({ ...value, firstCharacterSearch: null, patternSearch });
      } else {
        onChange({ ...value, firstCharacterSearch: localeAlphabet, patternSearch: null });
      }
    },
    [value, localeAlphabet, onChange]
  );

  const onChangeFirstCharacterSearch = useCallback(
    (firstCharacterSearch: Nullable<string[]>) => {
      onChange({ ...value, patternSearch: null, firstCharacterSearch });
    },
    [value, onChange]
  );

  return (
    <Layout
      search={
        <PartnerDeskFilterPatternSearch
          value={value.patternSearch ?? null}
          onChange={onChangePatternSearch}
        />
      }
      loader={
        isAlphabetLoading &&
        isFetching && (
          <ContentLoader
            position='fixed'
            size={16}
          />
        )
      }
    >
      {localeAlphabet.length > 0 && (
        <PartnerDeskFilterAlphabeticalSearch
          allowedAlphabet={localeAlphabet}
          alphabet={alphabets[currentLocale]}
          locale={currentLocale}
          specialSymbols={digitsAndSpecialSymbols}
          value={value.firstCharacterSearch ?? null}
          onChangeLocale={onChangeLocale}
          onChange={onChangeFirstCharacterSearch}
        />
      )}
    </Layout>
  );
};

export default PartnerDesksFilterContainer;
