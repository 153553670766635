import { OfferActivation } from '../../../domain/model/activation';
import { Pageable } from '../../../domain/model';
import { OfferActivationPatchedType } from './types';

export function isOfferActivation(obj: OfferActivationPatchedType): obj is OfferActivation {
  return (obj as OfferActivation).id !== undefined;
}

export function isOfferActivationCollection(obj: OfferActivationPatchedType): obj is OfferActivation[] {
  return Array.isArray(obj) && (obj as OfferActivation[]).length !== undefined;
}

export function isOfferActivationPageable(obj: OfferActivationPatchedType): obj is Pageable<OfferActivation> {
  return (obj as Pageable<OfferActivation>).totalCount !== undefined;
}
