import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  partnerDeskDetailsBookingOffersCountSelector,
  partnerDeskDetailsBookingOffersIsErrorSelector,
  partnerDeskDetailsBookingOffersIsFetchingSelector,
} from '../../store/selectors';

const PartnerDeskDetailsTabBookingOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsBookingOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsBookingOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsBookingOffersIsErrorSelector);

  return useMemo(
    () => (!count && !isFetching && !isError ? <div>Торговые предложения пока отсутствуют</div> : null),
    [count, isFetching, isError]
  );
};

export default PartnerDeskDetailsTabBookingOffersNoData;
