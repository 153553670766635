import { Container } from '@mui/material';
import { FooterLayoutType } from './types';
import { ContentWrapper, Footer, Wrapper } from './controls';

const FooterLayout: FooterLayoutType = props => {
  const { children } = props;

  return (
    <Wrapper>
      <Footer>
        <Container>
          <ContentWrapper>{children}</ContentWrapper>
        </Container>
      </Footer>
    </Wrapper>
  );
};

export default FooterLayout;
