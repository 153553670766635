import styled from '@emotion/styled/macro';
import { css, IconButton, IconButtonProps, Link } from '@mui/material';
import AppImage from 'presentation/components/common/images/common';
import { forwardRef } from 'react';
import { Swiper } from 'swiper/react';

export const Carousel = styled(Swiper)(
  ({ theme }) => css`
    max-height: ${theme.spacing(37.5)};

    & > .swiper-pagination {
      bottom: ${theme.spacing(1.5)};

      line-height: 1;
      font-size: 0;

      pointer-events: none;

      & .swiper-pagination-bullet {
        margin-left: ${theme.spacing(0.25)};
        margin-right: ${theme.spacing(0.25)};

        background-color: ${theme.palette.secondary.dark};
        border: 1px solid ${theme.palette.background.paper};
        opacity: 1;

        transition: background-color ${theme.transitions.duration?.standard}ms;

        &.swiper-pagination-bullet-active {
          background-color: ${theme.palette.primary.main};
        }
      }
    }

    ${theme.breakpoints.up('sm')} {
      border-radius: 0 0 ${(theme.shape.borderRadius as number) * 2}px ${(theme.shape.borderRadius as number) * 2}px;
    }

    ${theme.breakpoints.down('lg')} {
      max-height: ${theme.spacing(26.5)};
    }

    ${theme.breakpoints.down('md')} {
      max-height: ${theme.spacing(20.625)};
    }

    ${theme.breakpoints.down('sm')} {
      max-height: ${theme.spacing(15)};
    }
  `
);

export const ImageWrapper = styled(Link)(() => css``);

export const Image = styled(AppImage)(
  () => css`
    width: 100%;
    height: auto;

    object-fit: contain;
    object-position: 50% 50%;

    vertical-align: top;
  `
);

export const Button = styled(IconButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.default};
    opacity: 0.3;

    position: absolute;
    z-index: 1;

    top: 50%;
    transform: translateY(-50%);

    & .MuiSvgIcon-root {
      transition: color ${theme.transitions.duration?.standard}ms;
    }

    &:hover {
      background-color: ${theme.palette.background.default};
      opacity: 0.5;

      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    }
  `
);

export const ButtonPrev = styled(
  forwardRef<any, IconButtonProps>((props, ref) => (
    <Button
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    left: ${theme.spacing(2)};
  `
);

export const ButtonNext = styled(
  forwardRef<any, IconButtonProps>((props, ref) => (
    <Button
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    right: ${theme.spacing(2)};
  `
);
