import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferListIsFetchingSelector } from '../store/selectors';

export const CorpOfferListLoaderAdapter = () => {
  const isFetching = useSelector(corpOfferListIsFetchingSelector);

  return useMemo(() => (isFetching ? <ContentLoader position='fixed' /> : null), [isFetching]);
};
