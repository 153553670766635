import { AttributeText } from './controls';

type SidebarAttributeProps = {
  readonly name: string;
  readonly value: string;
};

const SidebarAttribute = ({ name, value }: SidebarAttributeProps) => {
  return (
    <AttributeText>
      {name}: {value}
    </AttributeText>
  );
};

export default SidebarAttribute;
