import styled from '@emotion/styled/macro';
import { Tab, Tabs, tabsClasses, tabClasses, typographyClasses, css } from '@mui/material';

export const StyledTabs = styled(Tabs)(
  p => css`
    overflow: hidden;
    border-left: 2px solid ${p.theme.palette.secondary.A900};
    background: transparent;
    border-radius: 0;

    .${tabsClasses.root} {
      position: sticky;
      top: 60px;
    }

    .${tabsClasses.scroller} {
      position: unset;
      overflow: visible !important;
    }

    .${tabsClasses.flexContainerVertical} {
      gap: ${p.theme.spacing(1.5)};
    }

    .${tabsClasses.indicator} {
      width: 2px;
      left: 0;
      background-color: ${p.theme.palette.text.primary};
    }
  `
);

export const StyledTab = styled(Tab)(
  p => css`
    min-height: ${p.theme.spacing(4)};

    align-items: flex-start;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${p.theme.spacing(3)};

    text-align: left;
    border-radius: 0;
    color: ${p.theme.spacing(3)};

    span.${typographyClasses.root} {
      color: ${p.theme.palette.common.black};
      font-weight: ${p.theme.typography.fontWeightMedium};
    }

    h6.${typographyClasses.root} {
      color: ${p.theme.palette.text.secondary};
      font-weight: ${p.theme.typography.fontWeightRegular};
    }

    &.${tabClasses.selected} {
      .${typographyClasses.root} {
        color: ${p.theme.palette.common.black};
        font-weight: ${p.theme.typography.fontWeightMedium};
      }
    }
  `
);
