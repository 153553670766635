import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { CartItem } from 'domain/model/cart';
import { EOfferType, EOrderStatus } from 'domain/model/enums';
import { ApiCancellable, ApiRequestPageable } from '../types';
import { createCancelToken, getUserQueryParams } from '../utils';
import { OrderFull, OrderShort } from '../../../domain/model/order';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiRequestPageable &
  ApiCancellable & {
    readonly query?: Nullable<string>;
    readonly statuses?: Nullable<EOrderStatus[]>;
    readonly offerType?: EOfferType[];
  };

type CreateProps = CartItem[];

type CancelProps = OneProps & {
  readonly cancellationType: { id: UUID };
  readonly comment: string;
};

export type UserOrder = {
  readonly one: (props: OneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderFull>>;
  readonly all: (props: AllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderShort[]>>;
  readonly create: (props: CreateProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderFull>>;
  readonly cancel: (props: CancelProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OrderFull>>;
};

const order: UserOrder = {
  one: ({ id, signal }) => ({
    url: `/customers/current/orders/${id}`,
    method: 'GET',
    cancelToken: signal && createCancelToken(axios, signal),
  }),
  all: props => {
    const { statuses, signal, offerType, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    if (offerType) {
      offerType.forEach(item => params.append('offerType', item));
    }

    return {
      url: `/customers/current/orders`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  create: data => {
    return {
      url: `/customers/current/orders`,
      method: 'POST',
      data,
    };
  },
  cancel: ({ id, signal, cancellationType, comment }) => {
    return {
      url: `/customers/current/orders/${id}`,
      method: 'POST',
      data: {
        cancellationType,
        comment,
        discriminator: 'CustomerCancelOrderCommand',
      },
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default order;
