import useCorpOfferCategoriesData from './useCorpOfferCategoriesData';
import useCorpOfferDetailsData from './useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from './useCorpOfferPartnerDeskData';

type CorpDetailsLoadingStatusProps = {
  readonly id: UUID;
};

type UseCorpOfferDetailsFetchingStatus = boolean;

const useCorpOfferDetailsLoadingStatus = ({ id }: CorpDetailsLoadingStatusProps): UseCorpOfferDetailsFetchingStatus => {
  const { isCategoriesLoading } = useCorpOfferCategoriesData();
  const { isCorpOfferLoading } = useCorpOfferDetailsData(id);
  const { isPartnerDeskLoading } = useCorpOfferPartnerDeskData(id);

  return isCategoriesLoading || isCorpOfferLoading || isPartnerDeskLoading;
};

export default useCorpOfferDetailsLoadingStatus;
