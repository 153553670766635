import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';
import { selected } from './constants';

const list: OverrideThemeFunc<Components> = baseTheme => ({
  MuiListItemButton: {
    styleOverrides: {
      root: {
        [`&${selected}`]: {
          backgroundColor: baseTheme.palette.secondary.A600,
        },
        [`:hover, &${selected}:hover`]: {
          backgroundColor: baseTheme.palette.secondary.A600,
        },
      },
    },
  },
});

export default list;
