import SmartPhoneFixedBar from 'presentation/components/common/smartPhoneFixedBar';
import { ReactNode } from 'react';

type OfferSmartPhoneFixedBarProps = {
  readonly text: string;
  readonly favorite?: ReactNode;
  readonly onBack: () => void;
};

const OfferSmartPhoneFixedBar = (props: OfferSmartPhoneFixedBarProps) => {
  const { text, favorite, onBack } = props;

  return (
    <SmartPhoneFixedBar
      onBack={onBack}
      text={text}
      favorite={favorite}
    />
  );
};

export default OfferSmartPhoneFixedBar;
