import { Typography } from '@mui/material';
import { ArrowRightIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../components/common/icon';
import { ShowMoreWrapper } from './controls';

type CategorySelectShowMoreProps = {
  readonly expanded: boolean;
  readonly onExpandOrCollapse: () => void;
};

const CategorySelectShowMore = ({ expanded, onExpandOrCollapse }: CategorySelectShowMoreProps) => {
  return (
    <ShowMoreWrapper
      expanded={expanded}
      onClick={onExpandOrCollapse}
    >
      <Typography color='secondary'>{expanded ? 'Свернуть' : 'Показать всё'}</Typography>
      <AppSvgIcon
        icon={ArrowRightIcon}
        color='secondary'
      />
    </ShowMoreWrapper>
  );
};

export default CategorySelectShowMore;
