import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';

export const OriginalPrice = styled(Typography)`
  text-decoration: line-through;
`;

export const DiscountValue = styled(Typography)`
  margin-left: ${p => p.theme.spacing(1)};
`;
