import { CmsContainerShowAllLink } from '../../components/showAllLink';
import { getPartnerDeskListRoute } from '../../../partnerDesk/entry';

/**
 * адаптер который добавляет в хедер кнопку "Посмотреть всё"
 */
export const CmsPartnersCollectionShowAllAdapter = () => {
  const link = getPartnerDeskListRoute({});

  return <CmsContainerShowAllLink to={link} />;
};
