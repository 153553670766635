import useAspOfferDetailsData from '../../hooks/useAspOfferDetailsData';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import { useCallback, useMemo, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { SwiperProps } from 'swiper/react';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { CarouselWrapper } from './controls';
import { aspOfferTag, offerImageAspectRatio } from '../../../../utils';
import OfferTags from '../../../../components/tags';

type AspOfferCarouselAdapterProps = {
  readonly id: UUID;
};

export const AspOfferCarouselAdapter = (props: AspOfferCarouselAdapterProps) => {
  const { id } = props;

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };

  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };

  const { aspOffer } = useAspOfferDetailsData(id);

  const images = aspOffer?.images ?? [];

  const thumbChildren = images.map((image, i) => (
    <AppImage
      key={i}
      onClick={() => {}}
      src={image.path}
    />
  ));

  const handleChangeActiveIndex = useCallback(
    (index: number) => {
      if (activeSlide !== index) {
        setActiveSlide(index);
      }
    },
    [activeSlide]
  );

  const thumbSwiperProps: SwiperProps = useMemo(
    () => ({
      slidesPerView: 4,
      spaceBetween: 10,
      onClick: () => {},
      loop: true,
    }),
    []
  );

  const mainSwiperProps: SwiperProps = useMemo(
    () => ({
      pagination: !isSmUp,
      navigation: false,
      spaceBetween: 10,
      onClick: onMediaClick,
      loop: true,
    }),
    [isSmUp]
  );

  const tags = useMemo(() => [...(aspOffer?.tags ?? []), aspOfferTag], [aspOffer?.tags]);

  if (!aspOffer?.images?.length) {
    return null;
  }

  return (
    <CarouselWrapper>
      <CarouselGallery
        swipersGap={10}
        thumbSwiperContainerHeight={102}
        thumbChildren={thumbChildren}
        showThumbs={true}
        onActiveIndexChange={handleChangeActiveIndex}
        thumbSwiperProps={thumbSwiperProps}
        mainSwiperProps={mainSwiperProps}
        aspectRatio={offerImageAspectRatio}
      >
        {images.map((image, i) => (
          <AppImage
            key={i}
            onClick={() => {}}
            src={image.path}
          />
        ))}
      </CarouselGallery>
      <MediaPreviewDialog
        imageLinks={images}
        initSlide={activeSlide}
        open={previewDialogOpen}
        onClose={onCloseDialog}
      />
      {tags && <OfferTags tags={tags} />}
    </CarouselWrapper>
  );
};

