import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type CorpOfferDetailsState = {
  readonly guid: Nullable<UUID>;
};

type Reducer<T> = CaseReducer<CorpOfferDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CorpOfferDetailsState> & {
  corpOfferDetailsStartSession: Reducer<{ guid: UUID }>;
};

const slice = createSlice<CorpOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
  },
  reducers: {
    corpOfferDetailsStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
  },
});

export const { corpOfferDetailsStartSession } = slice.actions;

export default slice.reducer;
