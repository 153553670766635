import ContentLoader from 'presentation/components/common/loader';
import useBookingOfferDetailsFetchingStatus from '../hooks/useBookingOfferDetailsFetchingStatus';
import { memo } from 'react';

type BookingDetailsLoaderAdapterProps = {
  readonly id: UUID;
};

const BookingOfferDetailsLoaderAdapter = memo(({ id }: BookingDetailsLoaderAdapterProps) => {
  const isFetching = useBookingOfferDetailsFetchingStatus({ id });

  return isFetching ? <ContentLoader position='fixed' /> : null;
});

export default BookingOfferDetailsLoaderAdapter;
