import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';

export const LogoWrapper = styled(Grid)`
  max-width: 80px;

  img {
    max-width: 100%;
    width: auto;
    max-height: 80px;
  }
`;
