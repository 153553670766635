import { AxiosResponse } from 'axios';
import { Pageable } from 'domain/model';
import { AspOfferShort } from 'domain/model/aspOffer';
import { CorpOfferShort } from 'domain/model/corpOffer';
import {
  EBookingOfferSortType,
  ECorpOfferSortType,
  EOfferStatus,
  EProductOfferSortType,
  ETradeOfferSortType,
} from 'domain/model/enums';
import { Category } from 'domain/model/nsi';
import { PartnerDesk, PartnerNameMap, PartnerWindow } from 'domain/model/partner';
import { ProductOffer } from 'domain/model/productOffer';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { BookingOfferShort } from '../../domain/model/bookingOffer';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import {
  api,
  EAspOfferServicesTag,
  EBookingOfferServicesTag,
  ECorpOfferServicesTag,
  EProductOfferServicesTag,
  ETradeOfferServicesTag,
} from './index';

export type PartnerOfferListRequest<
  S extends ETradeOfferSortType | ECorpOfferSortType | EProductOfferSortType | EBookingOfferSortType
> = {
  readonly partnerId: UUID;
  readonly categoryId?: Nullable<UUID>;
  readonly sort: Nullable<S[]>;
  readonly statuses?: EOfferStatus[];
  readonly pageSize: number;
  readonly page: number;
};

export type PartnerOfferCategoriesUsedRequest = {
  readonly partnerId: UUID;
  readonly statuses?: EOfferStatus[];
};

export type PartnerDesksListRequest = {
  readonly page: number;
  readonly pageSize: number;
  readonly query?: Nullable<string>;
  readonly name?: Nullable<string[]>;
};

export type PartnerTestPageRequest = {
  readonly partnerId: UUID;
};

const defaultPartnerOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.OutOfStock];

export const partnerApi = api.injectEndpoints({
  endpoints: builder => ({
    getPartnerTradeOfferList: builder.query<Pageable<TradeOfferShort>, PartnerOfferListRequest<ETradeOfferSortType>>({
      query: ({ partnerId, categoryId, sort, page, pageSize, statuses = defaultPartnerOfferValidStatuses }) => {
        return apiDefinition.user.trade.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null,
        });
      },
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      providesTags: (result, error, args) => [{ type: ETradeOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerTradeOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ partnerId, statuses }) => {
        return apiDefinition.user.trade.categoriesUsed({
          partnerId,
          statuses,
        });
      },
      providesTags: (result, error, args) => [{ type: ETradeOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerAspOfferList: builder.query<Pageable<AspOfferShort>, PartnerOfferListRequest<ETradeOfferSortType>>({
      query: ({ partnerId, categoryId, sort, page, pageSize, statuses = defaultPartnerOfferValidStatuses }) => {
        return apiDefinition.user.asp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null,
        });
      },
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      providesTags: (result, error, args) => [{ type: EAspOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerAspOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ partnerId, statuses }) => {
        return apiDefinition.user.asp.categoriesUsed({
          partnerId,
          statuses,
        });
      },
      providesTags: (result, error, args) => [{ type: EAspOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerProductOfferList: builder.query<Pageable<ProductOffer>, PartnerOfferListRequest<EProductOfferSortType>>({
      query: ({ partnerId, sort, page, pageSize, statuses = defaultPartnerOfferValidStatuses }) => {
        return apiDefinition.user.product.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
        });
      },
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      providesTags: (result, error, args) => [{ type: EProductOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerCorpOfferList: builder.query<Pageable<CorpOfferShort>, PartnerOfferListRequest<ECorpOfferSortType>>({
      query: ({ partnerId, categoryId, sort, page, pageSize, statuses = defaultPartnerOfferValidStatuses }) => {
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null,
        });
      },
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      providesTags: (result, error, args) => [{ type: ECorpOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerCorpOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ partnerId, statuses }) => {
        return apiDefinition.user.corp.categoriesUsed({
          partnerId,
          statuses,
        });
      },
      providesTags: (result, error, args) => [{ type: ECorpOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerBookingOfferList: builder.query<
      Pageable<BookingOfferShort>,
      PartnerOfferListRequest<EBookingOfferSortType>
    >({
      query: ({ categoryId, page, pageSize, ...bookingProps }) => {
        return apiDefinition.user.booking.all({
          page: 1,
          pageSize: page * pageSize,
          categories: categoryId ? [categoryId] : null,
          minPrice: null,
          maxPrice: null,
          ...bookingProps,
        });
      },
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      providesTags: (result, error, args) => [{ type: EBookingOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerBookingOfferCategoriesUsed: builder.query<
      Category[],
      Pick<PartnerOfferCategoriesUsedRequest, 'partnerId'>
    >({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ partnerId }) => {
        return apiDefinition.user.booking.categoriesUsed({
          partnerId,
        });
      },
      providesTags: (result, error, args) => [{ type: EBookingOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerDesksCollection: builder.query<PartnerDesk[], UUID>({
      query: id => apiDefinition.partner.desk.collection({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
    }),

    getPartnerDesk: builder.query<PartnerDesk, UUID>({
      query: id => apiDefinition.partner.desk.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
    }),

    getPartnerDesks: builder.query<Pageable<PartnerDesk>, PartnerDesksListRequest>({
      query: ({ page, pageSize, query, name }) => apiDefinition.partner.desk.all({ page, pageSize, query, name }),
      transformResponse: (response: AxiosResponse) => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
    }),

    getPartnerPage: builder.query<PartnerWindow, UUID>({
      query: partnerId => apiDefinition.partner.page({ partnerId }),
      transformResponse: (response: AxiosResponse) => response.data,
    }),

    getPartnerTestPage: builder.query<PartnerWindow, PartnerTestPageRequest>({
      query: ({ partnerId }) => apiDefinition.partner.pageTest({ partnerId }),
      transformResponse: (response: AxiosResponse<PartnerWindow>) => response.data,
    }),

    getPartnersNameMap: builder.query<PartnerNameMap, void>({
      query: () => apiDefinition.partner.nameMap(),
      transformResponse: (response: AxiosResponse<PartnerNameMap>) => response.data,
    }),
  }),
});

export const {
  useGetPartnerTradeOfferListQuery,
  useGetPartnerTradeOfferCategoriesUsedQuery,
  useGetPartnerAspOfferListQuery,
  useGetPartnerAspOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferListQuery,
  useGetPartnerProductOfferListQuery,
  useGetPartnerBookingOfferListQuery,
  useGetPartnerBookingOfferCategoriesUsedQuery,
  useGetPartnerDeskQuery,
  useGetPartnerDesksQuery,
  useGetPartnerPageQuery,
  useGetPartnerDesksCollectionQuery,
  useGetPartnerTestPageQuery,
  useGetPartnersNameMapQuery,
} = partnerApi;
