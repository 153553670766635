import { FavoriteOutlineIcon } from '../../../../../media/icons';
import { getUserFavoritesRoute } from '../../../../user/entry';
import HeaderOption, { HeaderOptionProps } from '../../option';

const FavoritesOption = (props: Partial<HeaderOptionProps>) => {
  return (
    <HeaderOption
      icon={FavoriteOutlineIcon}
      to={getUserFavoritesRoute({})}
      {...props}
    />
  );
};

export default FavoritesOption;
