import styled from '@emotion/styled/macro';
import { buttonBaseClasses, css, inputBaseClasses, outlinedInputClasses } from '@mui/material';
import { colorGradientCalculator, colorGradientLightenCalculator } from 'presentation/theme/override/palette';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPFormInput, MPFormInputProps } from 'presentation/theme/ui-kit/input';
import { disabled, focused } from '../../../../theme/override/constants';

export const AdornmentButton = styled(MPButton)(
  ({ theme }) => css`
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    &.${buttonBaseClasses.root} {
      padding: ${theme.spacing(1.5, 3)};
    }
  `
);

type StyledSearchInputProps = MPFormInputProps & {
  readonly showIcon?: boolean;
};

export const StyledSearchInput = styled(({ showIcon, ...others }: StyledSearchInputProps) => (
  <MPFormInput {...others} />
))<StyledSearchInputProps>(
  ({ theme, showIcon }) => css`
    .${inputBaseClasses.root} {
      padding: 0;

      &:hover:not(${disabled}):not(${focused}) .${outlinedInputClasses.notchedOutline} {
        border-color: transparent;
      }

      .${outlinedInputClasses.notchedOutline} {
        border-color: transparent;
      }
    }

    .${inputBaseClasses.root}.${inputBaseClasses.focused}, .${inputBaseClasses.root}.${inputBaseClasses.disabled} {
      .${outlinedInputClasses.notchedOutline} {
        border-color: transparent;
      }
    }

    .${inputBaseClasses.inputTypeSearch} {
      background: linear-gradient(
            to right,
            ${colorGradientLightenCalculator(theme.palette.primary.main, theme.palette.brand.main, 0.94, 0.96).join(
              ', '
            )}
          )
          padding-box,
        linear-gradient(
            to right,
            ${colorGradientCalculator(theme.palette.primary.main, theme.palette.brand.main).join(', ')}
          )
          border-box;
      border-top: ${theme.shape.borderWidthMd}px solid transparent;
      border-right: ${showIcon ? 0 : theme.shape.borderWidthMd}px solid transparent;
      border-bottom: ${theme.shape.borderWidthMd}px solid transparent;
      border-left: ${theme.shape.borderWidthMd}px solid transparent;
      border-bottom-left-radius: ${theme.shape.borderRadius}px;
      border-top-left-radius: ${theme.shape.borderRadius}px;
      height: auto;
      border-top-right-radius: ${showIcon ? 0 : theme.shape.borderRadius}px;
      border-bottom-right-radius: ${showIcon ? 0 : theme.shape.borderRadius}px;
      padding: 10px 16px;

      &[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        height: ${theme.spacing(3)};
        width: ${theme.spacing(3)};
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNzU3MjkgMTcuMjQyNkwxMS45OTk5IDEyTTE3LjI0MjYgNi43NTczNkwxMS45OTk5IDEyTTExLjk5OTkgMTJMNi43NTcyOSA2Ljc1NzM2TTExLjk5OTkgMTJMMTcuMjQyNiAxNy4yNDI2IiBzdHJva2U9IiM4OTkwOUYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        background-size: ${theme.spacing(3, 3)};
      }
    }
  `
);
