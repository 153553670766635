import AboutTextSectionsContainer from '../textSections/container';
import AboutPublicOfferCommonInfo from './info/common';
import AboutPublicOfferContractSubjectInfo from './info/contractSubject';
import AboutPublicOfferDisputeResolutionInfo from './info/disputeResolution';
import AboutPublicOfferEntryInfo from './info/offerEntry';
import AboutPublicOfferPrivilegeUsageInfo from './info/privilegeUsage';
import AboutPublicOfferResponsibilityInfo from './info/responsibility';
import AboutPublicOfferServiceConditionsInfo from './info/serviceConditions';
import AboutPublicOfferTermsInfo from './info/terms';

enum ESection {
  Common = 'common',
  Terms = 'terms',
  ContractSubject = 'contractSubject',
  ServiceConditions = 'serviceConditions',
  PrivilegeUsage = 'privilegeUsage',
  Responsibility = 'responsibility',
  DisputeResolution = 'disputeResolution',
  OfferEntry = 'offerEntry',
}

const AboutPublicOfferContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Публичная оферта ОАО «РЖД» о заключении договора на оказание услуг по предоставлению информации о товарах
              и услугах'
      sections={[
        {
          id: ESection.Common,
          name: 'Публичная оферта',
          component: AboutPublicOfferCommonInfo,
        },
        {
          id: ESection.Terms,
          name: '1. Термины и определения, используемые в оферте',
          component: AboutPublicOfferTermsInfo,
        },
        {
          id: ESection.ContractSubject,
          name: '2. Предмет договора',
          component: AboutPublicOfferContractSubjectInfo,
        },
        {
          id: ESection.ServiceConditions,
          name: '3. Порядок и условия оказания услуг',
          component: AboutPublicOfferServiceConditionsInfo,
        },
        {
          id: ESection.PrivilegeUsage,
          name: '4. Использование платформы',
          component: AboutPublicOfferPrivilegeUsageInfo,
        },
        {
          id: ESection.Responsibility,
          name: '5. Ответственность оферента и оператора',
          component: AboutPublicOfferResponsibilityInfo,
        },
        {
          id: ESection.DisputeResolution,
          name: '6. Разрешение споров',
          component: AboutPublicOfferDisputeResolutionInfo,
        },
        {
          id: ESection.OfferEntry,
          name: '7. Вступление оферты в силу и ее изменение',
          component: AboutPublicOfferEntryInfo,
        },
      ]}
    />
  );
};

export default AboutPublicOfferContainer;
