import { Hidden, Typography } from '@mui/material';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import CodeTitle from '../../../../../components/common/codeTitle/title';
import { Bold } from '../../../../user/details/activations/controls';
import { CommentTitle, CommentWrapper, SidebarWrapper } from '../controls';

type ActivationListItemPendingSidebarProps = {
  readonly offerName: string;
  readonly offerCode: string;
  readonly comment: Nullable<string>;
  readonly onOfferClick: () => void;
};

const ActivationListItemPendingSidebar = (props: ActivationListItemPendingSidebarProps) => {
  const { offerName, offerCode, comment, onOfferClick } = props;

  const { x2 } = useGridSpacing();

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={x2}
        direction='column'
      >
        <Hidden smUp>
          <MPGrid item>
            <Bold onClick={onOfferClick}>{offerName}</Bold>
          </MPGrid>
        </Hidden>
        <MPGrid item>
          <CodeTitle code={offerCode} />
        </MPGrid>
        <MPGrid item>
          <Typography gutterBottom>Предложение активировано</Typography>
        </MPGrid>
        <MPGrid item>
          <CommentWrapper>
            <MPGrid
              container
              spacing={x2}
              alignItems='center'
            >
              <MPGrid item>
                <CommentTitle>Заявка обрабатывается</CommentTitle>
              </MPGrid>
              {comment && (
                <MPGrid item>
                  <Typography variant='body2'>{comment}</Typography>
                </MPGrid>
              )}
            </MPGrid>
          </CommentWrapper>
        </MPGrid>
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemPendingSidebar;
