import { Zoom } from '@mui/material';
import { IconOff, IconOn } from 'presentation/components/common/buttons/cardFavorite/controls';
import MPSwitchIconButton from 'presentation/theme/ui-kit/button/switchIcon/button';
import { IconProps } from './types';

export type CardFavoriteButtonProps = {
  readonly active: boolean;
  readonly inProgress?: boolean;
  readonly onClick: () => void;
};

const IconOnInternal = ({ active }: IconProps) => {
  return (
    <Zoom in={active}>
      <IconOn color='brand' />
    </Zoom>
  );
};

const IconOffInternal = ({ active }: IconProps) => {
  return (
    <Zoom in={active}>
      <IconOff />
    </Zoom>
  );
};

const CardFavoriteButton = (props: CardFavoriteButtonProps) => {
  const { active, inProgress, onClick } = props;

  return (
    <MPSwitchIconButton
      active={active}
      inProgress={inProgress}
      icons={{
        on: <IconOnInternal active={active} />,
        off: <IconOffInternal active={!active} />,
      }}
      onClick={onClick}
    />
  );
};

export default CardFavoriteButton;
