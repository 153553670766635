import BackButton from '../../../features/offer/components/back';
import { SmartPhoneFixedBarWrapper } from './controls';
import { ReactNode } from 'react';

type SmartPhoneFixedBarProps = {
  readonly onBack: () => void;
  readonly text: string;
  readonly favorite?: ReactNode;
};

const SmartPhoneFixedBar = (props: SmartPhoneFixedBarProps) => {
  const { text, favorite, onBack } = props;

  return (
    <SmartPhoneFixedBarWrapper>
      <BackButton
        label={text}
        onClick={onBack}
      />

      {favorite}
    </SmartPhoneFixedBarWrapper>
  );
};

export default SmartPhoneFixedBar;
