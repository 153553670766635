import React, { FCC } from 'react';
import { Wrapper } from './controls';

type CmsContainerWrapperGridProps = {
  readonly mobileColumnsCount?: number;
};

const CmsContainerWrapperGrid: FCC<CmsContainerWrapperGridProps> = props => {
  const { mobileColumnsCount, children } = props;

  const childrenCount = React.Children.count(children);

  return <Wrapper mobileColumnsCount={mobileColumnsCount ?? childrenCount}>{children}</Wrapper>;
};

export default CmsContainerWrapperGrid;
