import { LocationIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemLocalityProps = {
  readonly locality: Nullable<string>;
  readonly onClick?: () => void;
};

export const UserMenuItemLocality: FC<UserMenuItemLocalityProps> = ({ locality, onClick }) => {
  return (
    <UserMenuItem
      label={locality}
      icon={<LocationIcon color='black' />}
      onClick={onClick}
    />
  );
};
