import { EOfferType } from 'domain/model/enums';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { SearchResultsLocationState } from 'presentation/features/search/entry';
import SearchResultsContainer from 'presentation/features/search/offers/container';
import ScreenLayout from 'presentation/layouts/screen';
import { ESearchUrlParam } from 'presentation/types';
import { useLocation } from 'react-router';

const AspOffersSearchResultsScreen = () => {
  const location = useLocation<Nullable<SearchResultsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get(ESearchUrlParam.Query);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <SearchResultsContainer
            guid={guid}
            query={query}
            offerType={[EOfferType.Asp]}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default AspOffersSearchResultsScreen;
