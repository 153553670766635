import { useSelector } from 'react-redux';
import ContentLoader from 'presentation/components/common/loader';
import { getUserFavoritesOffersIsFetchingSelector } from '../store/selectors';

type UserFavoritesLoaderAdapterProps = {
  readonly guid: UUID;
};

const UserFavoritesLoaderAdapter = ({ guid }: UserFavoritesLoaderAdapterProps) => {
  const isFetching = useSelector(getUserFavoritesOffersIsFetchingSelector(guid));

  return isFetching ? <ContentLoader position='fixed' /> : null;
};

export default UserFavoritesLoaderAdapter;
