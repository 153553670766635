import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';

export const Text = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing()};
`;

export const Bold = styled.b`
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

export const List = styled.ul`
  padding-left: ${p => p.theme.spacing(2)};
  margin: 0 0 ${p => p.theme.spacing()} 0;
`;

export const ListItem = styled.li``;

export const Subsection = styled.section`
  margin-bottom: ${p => p.theme.spacing(4)};

  ${Text}:first-of-type {
    font-weight: bold;
  }
`;
