import { Typography } from '@mui/material';
import PromotionTypeText from 'presentation/features/offer/components/claim/obtainingMethod';
import { EDateTimeFormat } from '../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../utils/date';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { Wrapper } from './controls';

const TradeOfferClaimPromotionDateActivateNow = () => {
  return (
    <Typography
      variant='body2'
      color='textSuccess'
    >
      получен только что
    </Typography>
  );
};

type TradeOfferClaimPromotionDateProps = {
  readonly appointmentDate: string;
};

const ClaimPromotionDate = (props: TradeOfferClaimPromotionDateProps) => {
  const { appointmentDate } = props;
  return (
    <Typography variant='body2'>
      от {utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}
    </Typography>
  );
};

const TradeOfferPromotionTypeAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, activation, isReceiptDateSupported } = useTradeOfferClaimContext();

  if (!tradeOffer) {
    return null;
  }

  return (
    <Wrapper>
      <PromotionTypeText promotionType={tradeOffer.promotionType} />
      {isReceiptDateSupported && isActivationReceivedNow && <TradeOfferClaimPromotionDateActivateNow />}
      {isReceiptDateSupported && !isActivationReceivedNow && activation.lastActivation?.appointmentDate && (
        <ClaimPromotionDate appointmentDate={activation.lastActivation.appointmentDate} />
      )}
    </Wrapper>
  );
};

export default TradeOfferPromotionTypeAdapter;
