import BookingOfferScreen from 'presentation/screen/bookingOffer';
import BookingOfferOrder from 'presentation/screen/bookingOfferOrder';
import BookingOffersScreen from 'presentation/screen/bookingOffers';
import BookingOffersSearchResultsScreen from 'presentation/screen/bookingOffersSearch';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routing } from './routes';

const BookingOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={BookingOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={BookingOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={BookingOfferScreen}
      />
      <Route
        exact
        path={routing.order}
        component={BookingOfferOrder}
      />
      <Redirect to={routing.list} />
    </Switch>
  );
};

export default BookingOfferEntry;
