import { Text } from './controls';

const AboutTermsOfUseContentRightsInfo = () => {
  return (
    <>
      <Text>
        5.1. Все объекты, доступные при помощи Платформы, в том числе элементы дизайна, текст, графические изображения,
        иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее – содержание
        Платформы), а также любой контент, размещенный в Платформе, являются объектами исключительных прав
        Правообладателя.
      </Text>
      <Text>
        5.2. Использование контента, а также каких-либо иных элементов Платформы возможно только в рамках функционала,
        предлагаемого Платформой. Никакие элементы содержания Платформы, а также любой контент, размещенный в Платформе,
        не могут быть использованы иным образом без предварительного разрешения Правообладателя. Под использованием
        подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе,
        отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством Российской
        Федерации.
      </Text>
      <Text>
        Использование Пользователем элементов содержания Платформы, а также любого контента для личного некоммерческого
        использования, допускается при условии сохранения всех знаков охраны авторского права, смежных прав, товарных
        знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования правообладателя в
        неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо
        предусмотренные законодательством Российской Федерации.
      </Text>
    </>
  );
};

export default AboutTermsOfUseContentRightsInfo;
