import useFavoritesCounter from 'presentation/features/favorite/counter/useCounter';
import FavoritesOption from '../../components/options/favorites/favorites';

const FavoritesOptionAdapter = () => {
  const { count, isLoading } = useFavoritesCounter();

  return (
    <FavoritesOption
      notificationsCount={count}
      isLoading={isLoading}
    />
  );
};

export default FavoritesOptionAdapter;
