import CmsPreviewScreen from 'presentation/screen/cms/preview';
import { Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';

const root = rootRouting.cms;

const routing = {
  root,
  test: `${root}/test`,
};

const CmsEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.test}
        component={CmsPreviewScreen}
      />
    </Switch>
  );
};

export default CmsEntry;
