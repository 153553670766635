import { useMemo } from 'react';
import HeaderOptions from '../components/options';
import { EHeaderOptionType } from '../components/options/types';
import useHeaderOptions from './useHeaderOptions';
import HeaderUserInfoAdapter from './userInfo';

const HeaderOptionsAdapter = () => {
  const options = useHeaderOptions();

  const desktopHeaderOptions = options.filter(o =>
    [EHeaderOptionType.Privilege, EHeaderOptionType.Favorites].includes(o.type)
  );

  desktopHeaderOptions.push({
    type: EHeaderOptionType.User,
    component: <HeaderUserInfoAdapter />,
  });

  return useMemo(() => <HeaderOptions options={desktopHeaderOptions} />, [desktopHeaderOptions]);
};

export default HeaderOptionsAdapter;
