import { ChipProps, useMediaQuery } from '@mui/material';
import { ReactElement } from 'react';
import { EOfferClientTagType, EOfferServerTagType, OfferTagsType } from '../../../../../domain/model/enums';
import { OfferTag as OfferTagType } from '../../../../../domain/model/offer';
import { AspCurrencyIcon } from '../../../../media/icons';
import { MPChip } from '../../../../theme/ui-kit/chip';

type OfferTagProps = {
  readonly offerTag: OfferTagType;
};

const getOfferTagIcon = (offerTagType: OfferTagsType): ReactElement => {
  switch (offerTagType) {
    case EOfferServerTagType.HIT:
    case EOfferServerTagType.SALE:
    case EOfferServerTagType.NEW:
      return <></>;
    case EOfferClientTagType.ASP:
      return <AspCurrencyIcon />;
  }
};

const getOfferTagColor = (offerTagType: OfferTagsType): ChipProps['color'] => {
  switch (offerTagType) {
    case EOfferServerTagType.HIT:
      return 'warning';
    case EOfferServerTagType.SALE:
      return 'error';
    case EOfferServerTagType.NEW:
      return 'success';
    case EOfferClientTagType.ASP:
      return 'white';
  }
};

const OfferTag = (props: OfferTagProps) => {
  const { offerTag } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPChip
      size={isMdUp ? 'small' : 'micro'}
      icon={getOfferTagIcon(offerTag.type)}
      color={getOfferTagColor(offerTag.type)}
      variant='filled'
      label={offerTag.name}
    />
  );
};

export default OfferTag;
