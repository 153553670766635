import { useCallback, useEffect, useState } from 'react';
import { getElementPageTopOffset } from '../utils/dom';

type UseScrollToElementProps = {
  readonly adjusmentTop?: number;
};

type UseScrollToElementReturnType = {
  readonly scrollTo: (el: HTMLElement) => void;
};

type UseScrollToElement = (p: UseScrollToElementProps) => UseScrollToElementReturnType;

const useScrollToElement: UseScrollToElement = ({ adjusmentTop = 0 }) => {
  const [callScrollWatcher, setCallScrollWatcher] = useState<symbol>(Symbol());
  const [currentEl, setCurrenEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!currentEl) {
      return;
    }

    const elementTopOffset = getElementPageTopOffset(currentEl);
    window.scrollTo({ top: elementTopOffset - adjusmentTop, behavior: 'smooth' });
  }, [adjusmentTop, callScrollWatcher, currentEl]);

  const scrollTo = useCallback((el: HTMLElement) => {
    setCallScrollWatcher(Symbol());
    setCurrenEl(el);
  }, []);

  return { scrollTo };
};

export default useScrollToElement;
