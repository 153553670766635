import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { headerCatalogDialogVisibleSelector } from '../../store/selectors';

const CancelAdapter = () => {
  const handlers = useContextHandlers();

  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);

  return (
    catalogVisible && (
      <Typography
        color='primary'
        onClick={handlers.hideCatalog}
      >
        Отмена
      </Typography>
    )
  );
};

export default CancelAdapter;
