import { StyledBreadcrumbs } from './controls';
import { BreadcrumbItem } from './item';
import { BreadcrumbObject } from './types';

type BreadcrumbChainProps = {
  readonly items: BreadcrumbObject[];
  readonly hideRootCrumb?: boolean;
};

export const BreadcrumbChain = ({ items, hideRootCrumb }: BreadcrumbChainProps) => (
  <StyledBreadcrumbs
    aria-label='breadcrumb'
    separator={null}
    gap='dense'
  >
    {items.map((breadcrumb, i) => (
      <BreadcrumbItem
        hidden={i === 0 && hideRootCrumb}
        key={breadcrumb.id}
        {...breadcrumb}
      />
    ))}
  </StyledBreadcrumbs>
);
