import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useHistory } from 'react-router';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { getUserActivationsRoute } from '../../../user/entry';

type CorpOfferActivateDialogProps = {
  readonly open: boolean;
  readonly title: string;
  readonly description: string;
  readonly onClose: () => void;
};

const CorpOfferActivateDialog = ({ open, title, description, onClose }: CorpOfferActivateDialogProps) => {
  const history = useHistory();

  const openPromotions = () => {
    onClose();
    history.push(getUserActivationsRoute({}));
  };

  return (
    <MPConfirmDialog
      open={open}
      maxWidth='xs'
      title={title}
      onClose={onClose}
      buttons={
        <MPButton
          color='brand'
          size='large'
          fullWidth
          onClick={openPromotions}
        >
          Посмотреть в Моих привилегиях
        </MPButton>
      }
    >
      <Typography
        variant='body2'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </MPConfirmDialog>
  );
};

export default CorpOfferActivateDialog;
