import { useMediaQuery } from '@mui/material';
import ProductOfferListFilterAdapter from 'presentation/features/offer/product/list/adapters/filter';
import { FilterWrapper } from 'presentation/features/offer/product/list/controls';
import { useProductOfferListCategoriesData } from 'presentation/features/offer/product/list/hooks/useProductOfferListCategoriesData';
import ProductListSidebar from 'presentation/features/offer/product/list/sidebar';
import { productOfferListCategoryIdSelector } from 'presentation/features/offer/product/list/store/selectors';
import Subcategories from 'presentation/features/offer/product/list/subcategories';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';

const ProductOfferListAsideAdapter = () => {
  const categoryId = useSelector(productOfferListCategoryIdSelector);
  const handlers = useContextHandlers();

  const { isCurrentCategoryLeaf, selectedSubcategories } = useProductOfferListCategoriesData();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const isSubcategoriesVisible = !!categoryId && selectedSubcategories.length > 0;
  const isFilterVisible = !!categoryId && !isCurrentCategoryLeaf && isMdUp;

  /*const onFilterApply = useCallback(
    (filter: PriceRangeFilter) => {
      dispatch(productOffersSetFilter(filter));
    },
    [dispatch]
  );*/

  if (!categoryId) {
    return null;
  }

  return (
    <>
      {isSubcategoriesVisible && (
        <ProductListSidebar>
          <Subcategories
            onCategoryClick={handlers.onChangeCategory} // TODO(@Protopopov Ruslan): move to link.to
            categories={selectedSubcategories}
          />
        </ProductListSidebar>
      )}
      {isFilterVisible && (
        <ProductListSidebar>
          <FilterWrapper>
            <ProductOfferListFilterAdapter />
          </FilterWrapper>
        </ProductListSidebar>
      )}
    </>
  );
};

export default ProductOfferListAsideAdapter;
