import { SelectProps, TextFieldProps } from '@mui/material';
import React from 'react';
import { ArrowDownIcon } from '../../../media/icons';
import { MPMenuItem } from '../menu/item';
import { MPSelectStyled } from './controls';

export type MPSelectProps<T> = Omit<SelectProps, 'onChange'> &
  Pick<TextFieldProps, 'helperText' | 'error' | 'size' | 'label'> & {
    readonly className?: string;
    readonly onChange: (value: T, child: React.ReactNode) => void;
  };

export const MPSelect = <T extends any>(props: MPSelectProps<T>) => {
  const { fullWidth, size, label, helperText, onChange, error, className, children, ...selectProps } = props;

  return (
    <MPSelectStyled
      label={label}
      size={size}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      value={selectProps.value}
      select
      className={className}
      SelectProps={{
        IconComponent: ArrowDownIcon,
        ...selectProps,
        onChange: (event, node) => onChange(event.target.value as T, node),
      }}
    >
      {children}
    </MPSelectStyled>
  );
};

MPSelect.Item = MPMenuItem;
