import { Fade } from '@mui/material';
import { Characteristics } from './controls';

type OfferDetailsTabCharacteristicsProps = {
  readonly text: string;
};

const OfferDetailsTabCharacteristics = ({ text }: OfferDetailsTabCharacteristicsProps) => {
  return (
    <Fade in>
      <Characteristics>{text}</Characteristics>
    </Fade>
  );
};

export default OfferDetailsTabCharacteristics;
