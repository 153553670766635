import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const SmartPhoneFixedBarWrapper = styled.div(
  ({ theme }) => css`
    z-index: ${theme.zIndex.appBar - 1};
    position: fixed;
    background: ${theme.palette.secondary.A400};
    display: flex;
    left: 0;
    right: 0;
    top: 0;
  `
);
