import useActivationsCounter from 'presentation/features/activation/counter/useCounter';
import { getUserActivationsRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemActivations } from '../../components/menuItems';

export const UserMenuActivationsAdapter = () => {
  const history = useHistory();

  const count = useActivationsCounter();

  const onClick = () => {
    history.push(getUserActivationsRoute({}));
  };

  return (
    <UserMenuItemActivations
      count={count}
      onClick={onClick}
    />
  );
};
