import styled from '@emotion/styled/macro';
import { Autocomplete as MuiAutocomplete, autocompleteClasses, chipClasses, css, svgIconClasses } from '@mui/material';

export const Autocomplete = styled(MuiAutocomplete)(
  () =>
    css`
      .${autocompleteClasses.endAdornment} {
        top: calc(50% - 1.625rem / 2);
      }

      .${svgIconClasses.fontSizeSmall} {
        font-size: 1.2rem;
      }

      .${chipClasses.root} {
        height: 1.875rem;
        font-size: 0.8125rem;
      }
    `
) as typeof MuiAutocomplete;

export const LoaderWrapper = styled.div(
  ({ theme }) => css`
    min-width: 1.8rem;

    position: absolute;
    right: ${theme.spacing()};
  `
);
