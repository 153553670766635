import { ETradeOfferPromotionType } from 'domain/model/enums';
import CommonHelpDialog from './common';
import { dialogContent } from './constants';

type TradeOfferHelpDialogProps = {
  readonly promotionType: ETradeOfferPromotionType;
  readonly open: boolean;
  readonly onClose: () => void;
};

const TradeOfferHelpDialog = ({ promotionType, open, onClose }: TradeOfferHelpDialogProps) => {
  return (
    <CommonHelpDialog
      open={open}
      title={dialogContent[promotionType].title}
      text={dialogContent[promotionType].text}
      onClose={onClose}
    />
  );
};

export default TradeOfferHelpDialog;
