import React from 'react';

export type TradeOfferClaimDialogsContextType = {
  readonly openUnavailableDialog: () => void;
  readonly openReactivationDialog: () => void;
  readonly openHelpDialog: () => void;
  readonly onActivate: () => void;
  readonly onShowAllOffers: () => void;
};

const TradeOfferClaimDialogsContext = React.createContext<TradeOfferClaimDialogsContextType>(
  null as unknown as TradeOfferClaimDialogsContextType
);

export default TradeOfferClaimDialogsContext;
