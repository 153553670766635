import { Text } from '../../components/controls';

const AboutPublicOfferPrivilegeUsageInfo = () => {
  return (
    <>
      <Text>
        4.1. РЖД предоставляет Пользователям Платформы безвозмездно, на условиях простой (неисключительной) лицензии
        непередаваемое право использования Платформы путем ее применения по прямому функциональному назначению с правом
        воспроизведения.
      </Text>
    </>
  );
};
export default AboutPublicOfferPrivilegeUsageInfo;
