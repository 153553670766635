import { LocationIcon } from 'presentation/media/icons';
import { FC, useState } from 'react';
import { AddressHelper } from 'utils/address';
import { UserProfileEditAttributeLocality } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { UserProfileDataAdapterProps } from './';
import { useUserProfileLocalityEdit } from '../hooks/useLocalityEdit';

const label = 'Город';

export const UserProfileLocalityAdapter: FC<UserProfileDataAdapterProps> = ({ user }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { value, validation, update, reset, onChange } = useUserProfileLocalityEdit({
    user,
  });

  const showEdit = () => setEditMode(true);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const updateInternal = () => {
    update();
    hideEdit();
  };

  return (
    <>
      <UserProfileViewAttribute
        label={label}
        value={user.locality ? new AddressHelper(user.locality).getLocalitySimpleName() : '-'}
        icon={<LocationIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributeLocality
          label={label}
          value={value}
          validation={validation?.locality}
          onChange={onChange}
          onClose={hideEdit}
          onSave={updateInternal}
        />
      )}
    </>
  );
};
