import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import BookingOfferDetailsContainer from 'presentation/features/offer/booking/details/container';
import { EBookingOfferDetailsTab } from 'presentation/features/offer/booking/details/types';
import { BookingOfferDetailsLocationState } from 'presentation/features/offer/booking/routes';
import ScreenLayout from 'presentation/layouts/screen';
import { ERenderSource } from 'presentation/types';
import { useLocation, useParams } from 'react-router';
import { EBookingUrlParam } from '../../features/offer/booking/types';

const BookingOfferScreen = () => {
  const location = useLocation<BookingOfferDetailsLocationState>();

  const from = location.state?.from ?? ERenderSource.Any;

  const { id } = useParams<{ id: UUID }>();
  const tab = (new URLSearchParams(location.search).get(EBookingUrlParam.Tab) ??
    EBookingOfferDetailsTab.Services) as EBookingOfferDetailsTab;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <BookingOfferDetailsContainer
            guid={guid}
            id={id}
            tab={tab}
            from={from}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default BookingOfferScreen;
