import { FCC } from 'react';
import { Wrapper } from './controls';

type OfferListAsideProps = {
  isFetching?: boolean;
};

const OfferListAside: FCC<OfferListAsideProps> = ({ isFetching, children }) => {
  return <Wrapper>{isFetching === true ? '' : children}</Wrapper>;
};

export default OfferListAside;
