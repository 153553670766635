import { EAppFeature } from 'presentation/types';
import React from 'react';

export type TechConfigContextType = {
  readonly isDebug: boolean;
  readonly hasFeature: (feature: EAppFeature) => boolean;
  readonly isGuestModeDisabled: boolean;
};

const TechConfigContext = React.createContext<TechConfigContextType>({} as TechConfigContextType);

export default TechConfigContext;
