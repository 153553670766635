import styled from '@emotion/styled/macro';
import { Chip, chipClasses, css } from '@mui/material';

export const BlankChip = styled(Chip)(
  ({ theme }) => css`
    min-height: 30px;
    min-width: 30px;

    .${chipClasses.label} {
      display: none;
    }

    .${chipClasses.deleteIcon} {
      margin: 0;
      color: ${theme.palette.white.light};
    }
  `
);

export const DeleteIconWrapper = styled.div`
  &:hover > * {
    color: red;
    cursor: pointer;
  }
`;
