import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from '../../../../theme/ui-kit/dialog/buttons';

type CityConfirmDialogProps = {
  readonly visible: boolean;
  readonly city?: string;
  readonly onAccept: () => void;
  readonly onReject: () => void;
  readonly onClose: () => void;
};

const CityConfirmDialog = (props: CityConfirmDialogProps) => {
  const { visible, city, onAccept, onReject, onClose } = props;

  const onLocalAccept = () => {
    onAccept();
    onClose();
  };

  const onLocalReject = () => {
    onReject();
    onClose();
  };

  return (
    <MPConfirmDialog
      title={`Ваше местоположение ${city}?`}
      open={visible}
      disableEscapeKeyDown
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onLocalAccept}
          >
            Да
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onLocalReject}
          >
            Нет
          </MPButton>
        </ConfirmButtons>
      }
      onClose={onClose}
    />
  );
};

export default CityConfirmDialog;
