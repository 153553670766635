import styled from '@emotion/styled/macro';
import { css, Menu as MuiMenu, paperClasses, SwipeableDrawer as MuiSwipeableDrawer } from '@mui/material';

const menuWidth = '265px';

export const Menu = styled(MuiMenu)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1.2)};

    .${paperClasses.root} {
      width: ${menuWidth};
      padding: ${theme.spacing(2)};
    }
  `
);

export const SwipeableDrawer = styled(MuiSwipeableDrawer)(
  ({ theme }) => css`
    .${paperClasses.root} {
      width: ${menuWidth};
      padding: ${theme.spacing(2)};
    }
  `
);

export const UserInfoWrapper = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacing(0.5)};
  `
);
