import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const ColumnWrapper = styled.div(
  ({ theme }) => css`
    > * {
      margin-bottom: ${theme.spacing(4)};
    }
  `
);
