import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import CorpOfferBonusUnavailableAdapter from 'presentation/features/offer/corp/components/bonusUnavailableStub';
import CorpOfferBreadcrumbAdapter from 'presentation/features/offer/corp/details/adapters/breadcrumb';
import CorpOfferDetailsPartnerInfoAdapter from 'presentation/features/offer/corp/details/adapters/partnerInfo';
import CorpOfferDetailSidebarAdapter from 'presentation/features/offer/corp/details/adapters/sidebar';
import CorpOfferDetailsTabsAdapter from 'presentation/features/offer/corp/details/adapters/tabs';
import CorpOfferDetailsTitleAdapter from 'presentation/features/offer/corp/details/adapters/title';
import { ERenderSource } from 'presentation/types';
import OfferDetailsLayout from '../../../../layouts/offerDetail';
import CorpOfferCarouselAdapter from './adapters/carousel';
import CorpOfferCloseAdapter from './adapters/close';
import CorpOfferConditionsAdapter from './adapters/condition';
import CorpOfferDetailsFavoriteAdapter from './adapters/favorite';
import CorpOfferDetailsLoaderAdapter from './adapters/loader';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import CorpOfferDetailStub from './adapters/stub';
import { CorpOfferDetailContext } from './context';
import useCorpOfferDetails from './hooks/useCorpOfferDetails';
import useCorpOfferDetailsLoadingStatus from './hooks/useCorpOfferDetailsLoadingStatus';
import { ECorpOfferDetailsTab } from './utils';

type CorpOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ECorpOfferDetailsTab;
  readonly from: ERenderSource;
};

const DetailsLayout = OfferDetailsLayout;

const CorpOfferDetailsContainer = ({ guid, id, tab, from }: CorpOfferDetailsContainerProps) => {
  const handlers = useCorpOfferDetails({ guid, id, from });

  return (
    <CorpOfferDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <RenderDecoratorContainer
          decorators={[
            <CommonRenderManager
              key='loader'
              loader={<CorpOfferDetailsLoaderAdapter id={id} />}
              stub={CorpOfferDetailStub}
            />,
            <CorpOfferBonusUnavailableAdapter key='bonusUnavailable' />,
          ]}
        >
          <DetailsLayout
            id={id}
            image={<CorpOfferCarouselAdapter id={id} />}
            favorite={
              <CorpOfferDetailsFavoriteAdapter
                guid={guid}
                id={id}
              />
            }
            description={<CorpOfferConditionsAdapter id={id} />}
            breadcrumb={<CorpOfferBreadcrumbAdapter id={id} />}
            smartphoneFixedBar={
              <SmartPhoneFixedBarAdapter
                guid={guid}
                id={id}
              />
            }
            partnerInfo={<CorpOfferDetailsPartnerInfoAdapter id={id} />}
            sidebar={<CorpOfferDetailSidebarAdapter id={id} />}
            tabs={
              <CorpOfferDetailsTabsAdapter
                id={id}
                guid={guid}
                tab={tab}
              />
            }
            title={<CorpOfferDetailsTitleAdapter id={id} />}
            close={<CorpOfferCloseAdapter />}
            useLoading={useCorpOfferDetailsLoadingStatus}
          />
        </RenderDecoratorContainer>
      </RenderManagerErrorProvider>
    </CorpOfferDetailContext.Provider>
  );
};

export default CorpOfferDetailsContainer;
