import { PartnerOfferListRequest, useGetPartnerCorpOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { ECorpOfferSortType, EOfferListType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import OfferListItemContainer from '../../../../offer/listItem/container';
import { partnerDeskDetailsCorpOffersIsNewFetchingSelector } from '../../store/selectors';

type PartnerDeskCorpOffersDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<ECorpOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (corpOffer: CorpOfferShort) => void;
};

const PartnerDeskCorpOffersDataContainer = (props: PartnerDeskCorpOffersDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { isAuthenticated } = useAuth();
  const isNewFetching = useSelector(partnerDeskDetailsCorpOffersIsNewFetchingSelector);
  const { page, partnerId } = args;

  const { data, error, isSuccess, isFetching } = useGetPartnerCorpOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const corpOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!corpOffers && corpOffers.length > 0;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {corpOffers.map(corpOffer => (
            <InView
              key={corpOffer.id}
              active={isSuccess && !isFetching}
              sessionKey={sessionKey}
              data={corpOffer}
              onShow={onShowCard}
            >
              <OfferListItemContainer
                key={corpOffer.id}
                offer={corpOffer}
                favorite={
                  isAuthenticated && {
                    guid,
                    partnerId,
                  }
                }
              />
            </InView>
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskCorpOffersDataContainer;
