import { EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import { OfferPromotionDownload } from '../../../../components/promotionDownload';
import { getOfferPromotionDownloadUrl } from '../../../../utils';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { TradeOfferClaimCodePromotion } from './code';
import { TradeOfferClaimReferralLinkPromotion } from './referralLink';
import TradeOfferClaimWidgetPromotion from './widget';

type TradeOfferClaimPromotionAdapterProps = {
  readonly wrapperRef: any;
};

export const TradeOfferClaimPromotionAdapter = ({ wrapperRef }: TradeOfferClaimPromotionAdapterProps) => {
  const {
    tradeOffer,
    activation,
    isActivationReceivedNow,
    onNeedCopy,
    isTaked,
    needCopy,
    onPartnerButtonClick,
    onTakeActivation,
    isTaking,
  } = useTradeOfferClaimContext();

  return useMemo(() => {
    const { lastActivation } = activation;

    if (!lastActivation) {
      return null;
    }

    const promotionDownloadUrl = getOfferPromotionDownloadUrl(lastActivation);

    const isRenderAllowed = tradeOffer && (tradeOffer.status === EOfferStatus.Active || isActivationReceivedNow);

    const renderPromotions = () => {
      switch (lastActivation.promotion.type) {
        case ETradeOfferPromotionType.Voucher:
          return promotionDownloadUrl ? (
            <OfferPromotionDownload
              label={lastActivation.promotion.code}
              onClick={() => onTakeActivation(lastActivation)}
              isFetching={isTaking}
            />
          ) : null;

        case ETradeOfferPromotionType.PublicPromocode:
          return (
            <TradeOfferClaimCodePromotion
              code={lastActivation.promotion.publicPromotionValue}
              onCopied={() => onTakeActivation(lastActivation)}
              onNeedCopy={onNeedCopy}
              copied={isTaked}
              needCopy={needCopy}
            />
          );
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
          return (
            <TradeOfferClaimCodePromotion
              code={lastActivation.promotion.code}
              onCopied={() => onTakeActivation(lastActivation)}
              onNeedCopy={onNeedCopy}
              copied={isTaked}
              needCopy={needCopy}
            />
          );
        case ETradeOfferPromotionType.Widget:
          return (
            <TradeOfferClaimWidgetPromotion
              wrapperRef={wrapperRef}
              title={lastActivation.offer.title}
              srcDoc={lastActivation.promotion.publicPromotionValue}
            />
          );
        case ETradeOfferPromotionType.ReferralLink:
          return (
            <TradeOfferClaimReferralLinkPromotion
              link={lastActivation.promotion.publicPromotionValue}
              onClick={onPartnerButtonClick}
            />
          );
        default:
          break;
      }
    };

    return isRenderAllowed ? (
      <MPGrid
        container
        alignItems='center'
        spacing={1}
      >
        <MPGrid
          item
          zero={12}
        >
          {renderPromotions()}
        </MPGrid>
      </MPGrid>
    ) : null;
  }, [
    activation,
    tradeOffer,
    isActivationReceivedNow,
    isTaking,
    onNeedCopy,
    isTaked,
    needCopy,
    wrapperRef,
    onPartnerButtonClick,
    onTakeActivation,
  ]);
};
