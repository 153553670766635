import ProductOfferShortCharacteristics from 'presentation/features/offer/product/components/short';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';

type ProductOfferShortCharacteristicsAdapterProps = {
  readonly id: UUID;
  readonly onShowAllCharacteristics: () => void;
}

const ProductOfferShortCharacteristicsAdapter = (props: ProductOfferShortCharacteristicsAdapterProps) => {
  const { onShowAllCharacteristics, id } = props;
  const { productOffer } = useProductOfferDetailsData(id);

  if (!productOffer?.otherAttributes) {
    return null;
  }

  return (
    <ProductOfferShortCharacteristics
      onShowFullCharacteristics={onShowAllCharacteristics}
      text={productOffer.otherAttributes}
    />
  );
};

export default ProductOfferShortCharacteristicsAdapter;
