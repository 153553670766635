import styled from '@emotion/styled';
import { MPFab } from 'presentation/theme/ui-kit/button';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  && > * + * {
    margin-left: ${p => p.theme.spacing(2)};
  }
`;

export const StyledFab = styled(MPFab)`
  min-width: 48px;
`;
