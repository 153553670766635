import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { PartnerDesk } from '../../../../../domain/model/partner';
import PartnerDeskCard from '../../components/card';
import { PartnerGrid } from '../../components/grid';
import { getPartnerDeskDetailsRoute } from '../../entry';
import usePartnerDeskList from '../provider/usePartnerDeskList';

const PartnerDeskListDataAdapter = () => {
  const history = useHistory();
  const { data } = usePartnerDeskList();

  const onOpenPartnerDeskDetails = useCallback(
    (partnerDesk: PartnerDesk) => {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id }));
    },
    [history]
  );

  return useMemo(
    () =>
      data && data.length > 0 ? (
        <PartnerGrid>
          {data.map(item => (
            <PartnerDeskCard
              key={item.partnerId}
              title={item.partner.name}
              image={item.partner.logo}
              onClick={() => onOpenPartnerDeskDetails(item)}
            />
          ))}
        </PartnerGrid>
      ) : null,
    [data, onOpenPartnerDeskDetails]
  );
};

export default PartnerDeskListDataAdapter;
