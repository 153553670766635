import styled from '@emotion/styled/macro';
import {
  Container,
  css,
  FormControl,
  IconButton,
  RadioGroup,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from '@mui/material';

type DrawerProps = SwipeableDrawerProps & {
  readonly offsetTop?: number;
};

export const ContentWrapper = styled(FormControl)(
  ({ theme }) => css`
    padding: 3.857rem;

    ${theme.breakpoints.down('sm')} {
      padding: 1.5rem;
    }
  `
);

export const RadioHeader = styled(Typography)(
  () => css`
    margin-top: 0.5rem;
    margin-bottom: 1.4rem;
  `
);

export const RadioList = styled(RadioGroup)(() => css``);

export const BackButtonWrapper = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(3)};

    position: sticky;
    top: 0;
    z-index: 1;

    background-color: ${theme.palette.background.default};
  `
);

export const Drawer = styled(({ offsetTop, ...others }: DrawerProps) => <SwipeableDrawer {...others} />)(
  ({ theme, offsetTop }) => css`
    z-index: ${theme.zIndex.appBar - 2} !important;

    .MuiPaper-root {
      width: 100%;
      max-height: 100%;
      height: 100%;
      padding-top: ${offsetTop ?? 0}px;
    }
  `
);

export const DrawerContainer = styled(Container)(
  ({ theme }) => css`
    height: 100%;
    max-height: 100%;

    padding-bottom: ${theme.spacing(7)};

    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `
);

export const CloseButton = styled(IconButton)(
  () => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
  `
);
