import { getCartRootRoute } from 'presentation/features/cart/entry';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { useHistory } from 'react-router';
import { UserMenuItemCart } from '../../components/menuItems';

export const UserMenuCartAdapter = () => {
  const history = useHistory();
  const { hasFeature } = useTechConfig();

  const isCartAllowed = hasFeature(EAppFeature.Product);

  const onClick = () => {
    history.push(getCartRootRoute());
  };

  return isCartAllowed ? <UserMenuItemCart onClick={onClick} /> : null;
};
