import { useGetLandingPageTestQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import { LazyContentWrapper } from '../../general/lazyContentWrapper';
import { LazyContentItem } from '../../general/lazyContentWrapper/item';
import CmsContainerContainer from '../container/container';
import { Wrapper } from './controls';
import CmsPreviewLayout from './layout';

type CmsPreviewLandingContainerProps = {
  readonly guid: UUID;
};

const Layout = CmsPreviewLayout;

const CmsPreviewLandingContainer = ({ guid }: CmsPreviewLandingContainerProps) => {
  const { data = null, isFetching, error } = useGetLandingPageTestQuery();
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  if (isFetching) {
    return <ContentLoader />;
  }

  return (
    <Layout>
      <LazyContentWrapper guid={guid}>
        {listSize => (
          <Wrapper>
            {data?.slice(0, listSize + 1).map((container, i) => (
              <div key={container.id}>
                <LazyContentItem
                  elementSize={33}
                  guid={guid}
                  containerId={container.id}
                  isLoaded={i !== listSize}
                >
                  {onContainerDataLoaded => (
                    <CmsContainerContainer
                      guid={guid}
                      container={container}
                      onContainerDataLoaded={onContainerDataLoaded}
                    />
                  )}
                </LazyContentItem>
              </div>
            ))}
          </Wrapper>
        )}
      </LazyContentWrapper>
      {isFetching && <ContentLoader />}
    </Layout>
  );
};

export default CmsPreviewLandingContainer;
