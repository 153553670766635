import { BadgeProps } from '@mui/material';
import { ReactNode } from 'react';
import { Badge } from './controls';

type UserBadgeProps = Pick<BadgeProps, 'children'> & {
  readonly balance?: ReactNode;
};

const UserBadge = ({ balance, children }: UserBadgeProps) => {
  return (
    <Badge
      overlap='circular'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      max={Infinity}
      badgeContent={balance}
    >
      {children}
    </Badge>
  );
};

export default UserBadge;
