import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo, useState } from 'react';
import HorizontalScroller, { HorizontalScrollerItem } from '../../horizontalScroll';
import AppSvgIcon from '../../icon';
import { BreadcrumbChain } from './chain';
import { BreadcrumbCollapse } from './controls';
import { BreadcrumbExpandChipContent } from './expandChip/content';
import { BreadcrumbItem } from './item';
import { BreadcrumbObject } from './types';
import { breadcrumbTreeExpandTitle } from './utils';

type BreadcrumbTreeProps = {
  readonly title?: string;
  readonly items: BreadcrumbObject[][];
};

export const BreadcrumbTree = (props: BreadcrumbTreeProps) => {
  const { items, title } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [breadcrumbExpand, setBreadcrumbExpand] = useState<boolean>(false);
  const onExpandSet = () => setBreadcrumbExpand(!breadcrumbExpand);

  const expandCount = items.length - 1;
  const expandTitle = breadcrumbTreeExpandTitle(expandCount);
  const isExpandable = items.length > 1;

  const iconComponent = useMemo(() => {
    return breadcrumbExpand ? (
      <AppSvgIcon
        fontSize='micro'
        icon={ExpandLess}
      />
    ) : (
      <AppSvgIcon
        fontSize='micro'
        icon={ExpandMore}
      />
    );
  }, [breadcrumbExpand]);

  if (items.length === 0) {
    console.error('Breadcrumb items is empty');
    return null;
  }

  return (
    <HorizontalScroller gap={8}>
      {title && (
        <HorizontalScrollerItem key='title'>
          <BreadcrumbItem name={title} />
        </HorizontalScrollerItem>
      )}
      <HorizontalScrollerItem key='items'>
        <MPGrid
          container
          item
          spacing={1}
          wrap='nowrap'
          direction='column'
        >
          <MPGrid
            item
            container
            spacing={0.5}
            alignItems='center'
          >
            <MPGrid item>{items[0] && <BreadcrumbChain items={items[0]} />}</MPGrid>
            {isExpandable && (
              <MPGrid item>
                <BreadcrumbExpandChipContent
                  count={expandCount}
                  isExpand={isMdUp}
                  onClick={onExpandSet}
                  title={expandTitle}
                  icon={iconComponent}
                />
              </MPGrid>
            )}
          </MPGrid>
          <BreadcrumbCollapse
            in={breadcrumbExpand}
            timeout='auto'
          >
            <MPGrid
              container
              spacing={1}
              direction='column'
              alignItems='baseline'
            >
              {items.slice(1).map((item, index) => (
                <MPGrid
                  item
                  key={`${index}_${item[1].id}`}
                >
                  <BreadcrumbChain
                    hideRootCrumb
                    key={`${index}_${item[1].id}`}
                    items={item}
                  />
                </MPGrid>
              ))}
            </MPGrid>
          </BreadcrumbCollapse>
        </MPGrid>
      </HorizontalScrollerItem>
    </HorizontalScroller>
  );
};
