import { useMemo } from 'react';
import HeaderTitle from '../components/title';
import { useContextHandlers } from '../hooks/useContextHandlers';

const HeaderLogoAdapter = () => {
  const handlers = useContextHandlers();

  return useMemo(() => <HeaderTitle onClickLogo={handlers.onLogoClick} />, [handlers.onLogoClick]);
};

export default HeaderLogoAdapter;
