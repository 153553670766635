import { Category, CategoryTreeItem } from 'domain/model/nsi';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import CategorySelectCommonLayout from 'presentation/features/category/select/common/layout';
import CategorySelectLeaf from '../../components/selectLeaf';

type CategorySelectCommonContainerProps = {
  readonly categories: CategoryTreeItem[];
  readonly selected: Nullable<UUID>;
  readonly onSelect: (category: Category) => void;
};

const Layout = CategorySelectCommonLayout;

//вывод категорий простым списком в один уровень
const CategorySelectCommonContainer = (props: CategorySelectCommonContainerProps) => {
  const { categories, selected, onSelect } = props;
  return (
    <Layout>
      {categories.map(item => (
        <CategorySelectLeaf
          key={item.id}
          category={item}
          selected={item.id === selected}
          onClick={onSelect}
        />
      ))}
    </Layout>
  );
};

export default CategorySelectCommonContainer;
