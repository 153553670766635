import AspOfferScreen from 'presentation/screen/aspOffer';
import AspOffersScreen from 'presentation/screen/aspOffers';
import AspOffersSearchResultsScreen from 'presentation/screen/aspOffersSearch';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';

const root = rootRouting.aspOffer;
export const routing = {
  list: root,
  details: `${root}/:id`,
  search: `${root}/search`,
};

const AspOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={AspOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={AspOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={AspOfferScreen}
      />
    </Switch>
  );
};

export default AspOfferEntry;
