import styled from '@emotion/styled/macro';
import { buttonBaseClasses, buttonClasses, ButtonProps, css, svgIconClasses, Typography } from '@mui/material';
import DottedButton from '../../../../components/common/buttons/dotted';
import { CopyIcon } from '../../../../media/icons';

type StyledButtonProps = ButtonProps & {
  readonly copied: boolean;
};

export const StyledDottedButton = styled(({ copied, disabled, ...others }: StyledButtonProps) => (
  <DottedButton
    disabled={disabled}
    {...others}
  />
))(
  ({ theme, copied, disabled }) => css`
    &.${buttonClasses.root} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      background-color: ${theme.palette.primary.A500};

      ${(disabled || copied) && 'border-color: transparent;'};

      padding: 0.75rem 1.25rem;

      ${theme.breakpoints.down('sm')} {
        padding: 0.75rem 1.25rem;
      }
    }

    &.${buttonClasses.root}.${buttonBaseClasses.root} {
      ${copied && `background-color: ${theme.palette.secondary.A500};`}
    }

    .${svgIconClasses.root} {
      margin-left: ${theme.spacing()};
    }
  `
);

export const ButtonWrapper = styled.div(
  () => css`
    width: 100%;
    display: inline-block;
    vertical-align: top;
  `
);

export const Overlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${theme.palette.white.main};
    background-color: ${theme.palette.success.main};
  `
);

export const CopyLabel = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const StyledCopyIcon = styled(CopyIcon)(
  () => css`
    &.${svgIconClasses.root} {
      margin-right: 0.25rem;
      margin-left: 0 !important;
    }
  `
);

export const TextLabel = styled(Typography)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
  `
);
