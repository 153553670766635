import HistoryExtensionContainer from 'presentation/features/general/historyExtension/container';
import Notifier from 'presentation/features/general/notifier/container';
import { WebAnalyticsInitializer } from 'presentation/features/webAnalytics';
import { GlobalPreviewModeContainer } from 'presentation/screen/cms/globalPreviewMode/container';
import { GuestTargetContainer } from 'presentation/features/user/guestTarget/container';

const AppInfrastructure = () => {
  return (
    <>
      <WebAnalyticsInitializer />
      <GlobalPreviewModeContainer />
      <GuestTargetContainer />
      <Notifier />
      <HistoryExtensionContainer />
    </>
  );
};

export default AppInfrastructure;
