import { Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPLink } from 'presentation/theme/ui-kit/link';
import { useEffect, useRef, useState } from 'react';
import DangerouslyHtmlWrapper from '../../../../components/common/wrappers/dangerouslyHtml';
import { WebWindowIcon } from '../../../../media/icons';
import { ContainerLimiter, DangerouslyHtmlWrapperInner, Wrapper } from './controls';

type OfferConditionsProps = {
  readonly title: string;
  readonly text: string;
  readonly onClickExtend?: () => void;
};

const OfferConditions = ({ onClickExtend, title, text }: OfferConditionsProps) => {
  const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const upXl = useMediaQuery(theme => theme.breakpoints.up('xl'));

  const contentElementRef = useRef(null);

  const rowCount = upXl ? 10 : upLg ? 6 : 8;

  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  useEffect(() => {
    const el = contentElementRef.current;
    if (el) {
      const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = el;
      setIsOverflowed(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
    }
  }, []);

  return (
    <Wrapper>
      <Typography
        variant='body1'
        fontWeight='bold'
      >
        {title}
      </Typography>
      <Splitter size={1} />
      <ContainerLimiter
        transparentSmooth
        rowCount={rowCount}
        isOverflowed={isOverflowed}
      >
        <DangerouslyHtmlWrapper ref={contentElementRef}>
          {text && (
            <DangerouslyHtmlWrapperInner
              rowCount={rowCount}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </DangerouslyHtmlWrapper>
      </ContainerLimiter>
      {isOverflowed && (
        <>
          <Splitter size={1} />
          <MPLink
            startIcon={WebWindowIcon}
            onClick={onClickExtend}
          >
            <Typography variant='body2'>посмотреть полностью</Typography>
          </MPLink>
        </>
      )}
    </Wrapper>
  );
};

export default OfferConditions;
