import { PartnerDesksListRequest } from 'data/api/partner';
import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import { useAlphabet } from 'presentation/features/partnerDesk/filter/alphabetical/hooks';

const usePartnerDesksPageableArgsInRedux = (guid: UUID) => {
  const { localeAlphabet } = useAlphabet();

  return usePageableArgsInRedux<PartnerDesksListRequest>({
    guid,
    defaultState: { query: null, name: localeAlphabet ?? null, page: 1, pageSize: 100 },
  });
};

export default usePartnerDesksPageableArgsInRedux;
