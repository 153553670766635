import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  partnerDeskDetailsProductOffersCountSelector,
  partnerDeskDetailsProductOffersIsErrorSelector,
  partnerDeskDetailsProductOffersIsFetchingSelector,
} from '../../store/selectors';

const PartnerDeskDetailsTabProductOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsProductOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsProductOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsProductOffersIsErrorSelector);

  return useMemo(
    () => (!count && !isFetching && !isError ? <div>Товарные предложения пока отсутствуют</div> : null),
    [count, isFetching, isError]
  );
};

export default PartnerDeskDetailsTabProductOffersNoData;
