import BusinessConfigProvider from './providers/businessConfigProdiver';
import TechConfigProvider from './providers/techConfigProdiver';

type AppConfigurationProps = {
  readonly children: any;
};

const AppConfiguration = ({ children }: AppConfigurationProps) => (
  <TechConfigProvider>
    <BusinessConfigProvider>{children}</BusinessConfigProvider>
  </TechConfigProvider>
);

export default AppConfiguration;
