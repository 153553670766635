import styled from '@emotion/styled';
import { css } from '@mui/material';

export const ChipWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    gap: ${spacing(0.5)};
    flex-wrap: wrap;
    margin-bottom: ${spacing(3)};
  `
);
