import { Text } from '../../components/controls';

const AboutPublicOfferContractSubjectInfo = () => {
  return (
    <>
      <Text>
        2.1. Предметом Договора является безвозмездное оказание Пользователям Платформы со стороны РЖД информационных
        услуг о предоставлении информации о предложениях Партнеров о товарах и услугах в объеме, определяемом такими
        Партнерами, с целью последующего заключения между Пользователями Платформы и Партнерами договоров купли-продажи
        товаров и (или) договоров оказания услуг на условиях Оферты.
      </Text>
    </>
  );
};
export default AboutPublicOfferContractSubjectInfo;
