import useActivationsCounter from 'presentation/features/activation/counter/useCounter';
import PrivilegeOption from '../../components/options/privilege/privilege';

const PrivilegeOptionAdapter = () => {
  const count = useActivationsCounter();

  return <PrivilegeOption notificationsCount={count} />;
};

export default PrivilegeOptionAdapter;
