import { useState } from 'react';
import { useCopyToClipboard } from './useCopyToClipboard';

const useCopied = () => {
  const [, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState<boolean>(false);
  const [needCopy, setNeedCopy] = useState<boolean>(false);

  const onNeedCopy = (value: boolean) => setNeedCopy(value);

  const onCopy = () => {
    setCopied(true);
    setNeedCopy(false);
  };

  return {
    copied,
    needCopy,
    onNeedCopy,
    onCopy,
    copy,
  };
};

export default useCopied;
