import { TabProps, Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { MPTab } from '../../../../theme/ui-kit/tab';

const LandingLink = ({ label, ...props }: Pick<LinkProps, 'to'> & TabProps) => {
  const isDownXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <MPTab
      component={RouterLink}
      {...props}
      size={'small'}
      iconPosition='start'
      label={<Typography variant={isDownXs ? 'body2' : 'body1'}>{label}</Typography>}
    />
  );
};

export default LandingLink;
