import { ETradeOfferSortType, tradeOfferSortType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../components/sortSelect';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeOfferListIsNewSearchFetchingSelector,
  tradeOfferListNoDataSelector,
  tradeOfferListSortSelector,
} from '../store/selectors';

const items = Object.values(tradeOfferSortType);

export const TradeOfferListSortSelectAdapter = () => {
  const handlers = useContextHandlers();

  const sort = useSelector(tradeOfferListSortSelector);
  const noData = useSelector(tradeOfferListNoDataSelector);
  const isNewSearchFetching = useSelector(tradeOfferListIsNewSearchFetchingSelector);

  return useMemo(
    () =>
      !noData &&
      !isNewSearchFetching && (
        <OffersSortSelect<ETradeOfferSortType>
          value={sort}
          items={items}
          defaultValue={ETradeOfferSortType.Default}
          onChange={handlers.onChangeSort}
        />
      ),
    [sort, noData, isNewSearchFetching, handlers.onChangeSort]
  );
};
