import { FileLink } from '../../../../../../domain/model';
import { MPDialog } from '../../../../../theme/ui-kit/dialog';
import MediaPreviewCarousel from '../../../../../components/common/carousels/mediaPreview';
import { DialogWrapper, MediaPreviewDialogCloseButton, StyledDialogContent } from './controls';
import { CloseIcon } from '../../../../../media/icons';

type MediaPreviewDialogProps = {
  readonly open: boolean;
  readonly initSlide?: number;
  readonly onClose: () => void;
  readonly imageLinks: FileLink[] | null;
};

const MediaPreviewDialog = ({ open, onClose, initSlide = 0, imageLinks }: MediaPreviewDialogProps) => {
  return (
    <MPDialog
      PaperComponent={DialogWrapper}
      open={open}
      closeButton={
        <MediaPreviewDialogCloseButton
          size='medium'
          variant='circular'
          disableElevation={false}
          onClick={onClose}
        >
          <CloseIcon />
        </MediaPreviewDialogCloseButton>
      }
      fullWidth
      onClose={onClose}
    >
      <StyledDialogContent>
        <MediaPreviewCarousel
          images={imageLinks ?? []}
          initSlide={initSlide}
        />
      </StyledDialogContent>
    </MPDialog>
  );
};

export default MediaPreviewDialog;
