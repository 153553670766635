import styled from '@emotion/styled/macro';
import { css, Typography, TypographyProps } from '@mui/material';

export const Text = styled(Typography)<TypographyProps & { rowCount: number }>(
  ({ rowCount }) => css`
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: ${rowCount};
    -webkit-box-orient: vertical;
  `
);
