import { useMediaQuery } from '@mui/material';
import { CategoryTreeItem } from 'domain/model/nsi';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import ContentLoader from 'presentation/components/common/loader';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import CategoryCatalogBackButton from '../components/backButton';
import CategorySelectBranch from '../components/selectBranch';
import CategorySelectLayout from './layout';
import { CategorySelectContainerProps } from './types';
import { useCategorySelect } from './useCategorySelect';

export type CategorySelectRootResult = {
  readonly childTree: Nullable<CategoryTreeItem[]>;
  readonly expanded: Nullable<CategoryTreeItem>;
  readonly selectedCategory: Nullable<CategoryTreeItem>;
  readonly setExpanded: (category: Nullable<CategoryTreeItem>) => void;
  readonly isExpanded: (category: CategoryTreeItem) => boolean;
};

type CategorySelectRootContainerProps = CategorySelectContainerProps & {
  readonly showSelected?: boolean;
  readonly children: (result: CategorySelectRootResult) => ReactNode;
};

const Layout = CategorySelectLayout;

const hasTouchscreen = 'ontouchstart' in window;

const CategorySelectRootContainer = (props: CategorySelectRootContainerProps) => {
  const { tree, selected, isFetching, onSelect, showSelected, children } = props;

  const overlayScrollbarRef = useRef<Nullable<OverlayScrollbarsComponent>>();

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const hasChildren = useMemo(() => tree?.some(({ children }) => children.length > 0), [tree]);

  const { expanded, expandedPath, expandedFirstLevel, setExpanded, selectedCategory, isExpanded } = useCategorySelect({
    tree,
    selected,
    showSelected,
  });

  const showChildren = (category: CategoryTreeItem) => {
    if (category.children?.length) {
      const expandedPathIndex = expandedPath?.findIndex(item => item.id === category.id) ?? -1;
      if (expandedPathIndex !== 0) {
        setExpanded(category);
      }
    } else {
      setExpanded(null);
    }
  };

  const toggleChildren = (category: CategoryTreeItem) => {
    if (category.children?.length === 0) {
      onSelect(category);
    } else {
      showChildren(category);
    }
  };

  const backTo = (category: CategoryTreeItem) => {
    let newExpanded: Nullable<CategoryTreeItem>;
    const expandedPathIndex = expandedPath?.findIndex(item => item.id === category.id) ?? -1;
    switch (expandedPathIndex) {
      //нажали на возврат на первом уровне
      case 0:
        newExpanded = null;
        break;
      //нажали на возврат на уровне более первого
      default:
        newExpanded = expandedPath?.[expandedPathIndex - 1] ?? null;
        break;
    }
    setExpanded(newExpanded);
  };

  const scrollToCategory = useCallback(
    (el: Nullable<HTMLElement>) => {
      const overlayInstance = overlayScrollbarRef.current?.osInstance();
      if (el) {
        overlayInstance?.scroll({ y: el.offsetTop - 50 }, 100);
      } else {
        overlayInstance?.scroll({ y: 0 }, 100);
      }
    },
    [overlayScrollbarRef]
  );

  //сброс прокрутки при переходе в другую категорию
  useEffect(() => {
    scrollToCategory(null);
  }, [expanded, scrollToCategory]);

  const firstLevelChildren = expandedFirstLevel?.children ?? null;

  //в мобилке на экран выводим состав выбранной категории, иначе основное дерево
  const rootTree = isSmDown && expanded ? expanded.children : tree;

  return (
    <Layout
      hasContainerDivider={hasChildren}
      scrollbarRef={overlayScrollbarRef as any}
      backButton={
        expanded && (
          <CategoryCatalogBackButton
            text={expanded.name}
            onClick={() => backTo(expanded)}
          />
        )
      }
      firstLevel={
        firstLevelChildren &&
        children({
          childTree: firstLevelChildren,
          selectedCategory,
          expanded,
          setExpanded,
          isExpanded,
        })
      }
      loader={isFetching && <ContentLoader position='fixed' />}
    >
      {rootTree?.map(category => (
        <CategorySelectBranch
          key={category.id}
          category={category}
          selected={isExpanded(category)}
          onClick={!hasTouchscreen ? () => onSelect(category) : () => toggleChildren(category)}
          onMouseEnter={!hasTouchscreen && (() => showChildren(category))}
        />
      ))}
    </Layout>
  );
};

export default CategorySelectRootContainer;
