import { Wrapper } from './controls';
import { LoginLayoutType } from './types';

const LoginLayout: LoginLayoutType = props => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default LoginLayout;
