import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type FooterParams = {
  readonly height: number;
};
export type RegisteredFooterParams = {
  readonly [footerName: string]: FooterParams | undefined;
};

export type FooterRegisterState = RegisteredFooterParams;

type Reducer<T = undefined> = CaseReducer<FooterRegisterState, PayloadAction<T>>;

type FooterPayload = {
  readonly name: string;
  readonly footer: FooterParams;
};

type Reducers = SliceCaseReducers<FooterRegisterState> & {
  registerFooter: Reducer<FooterPayload>;
  unregisterFooter: Reducer<string>;
  recomputeBoundaries: Reducer<FooterPayload>;
};

const slice = createSlice<FooterRegisterState, Reducers, 'footerRegister'>({
  name: 'footerRegister',
  initialState: {},
  reducers: {
    registerFooter: (state, { payload }) => {
      const isAlreadyRegistered = payload.name in state;

      if (isAlreadyRegistered) {
        return;
      }

      state[payload.name] = payload.footer;
    },
    unregisterFooter: (state, { payload }) => {
      delete state[payload];
    },
    recomputeBoundaries: (state, { payload }) => {
      delete state[payload.name];
      state[payload.name] = payload.footer;
    },
  },
});

export const { registerFooter, unregisterFooter, recomputeBoundaries } = slice.actions;

export default slice.reducer;
