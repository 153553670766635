import { Typography } from '@mui/material';
import moment from 'moment';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import ConfirmButtons from '../../../../../../theme/ui-kit/dialog/buttons';
import { pluralize } from '../../../../../../utils';

type CorpOfferGetCertProps = {
  readonly open: boolean;
  readonly price: number;
  readonly offerName: string;
  readonly offerEndDate: string;
  readonly activationExpiryDays: number;
  readonly onGetCertificate: () => void;
  readonly onClose: () => void;
};

const CorpOfferGetCertDialog = ({
  open,
  price,
  offerName,
  offerEndDate,
  activationExpiryDays,
  onGetCertificate,
  onClose,
}: CorpOfferGetCertProps) => {
  const activationExpiryDate = moment(offerEndDate).endOf('day').add(activationExpiryDays, 'day');

  return (
    <MPConfirmDialog
      open={open}
      title='Обменять баллы на сертификат?'
      closeButtonVisible
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <ConfirmButtons>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            onClick={onGetCertificate}
          >
            Да, обменять
          </MPButton>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            variant='text'
            onClick={onClose}
          >
            Нет
          </MPButton>
        </ConfirmButtons>
      }
    >
      <Typography variant='body2'>
        Обменять {price} {pluralize(price, ['балл', 'балла', 'баллов'])} на сертификат предложения «{offerName}
        »?
      </Typography>
      <Typography
        variant='body2'
        fontWeight='bold'
      >
        Внимание!
      </Typography>
      <Typography variant='body2'>
        Сертификат действителен до {moment(activationExpiryDate).format(EDateFormat.Human)} года.
      </Typography>
    </MPConfirmDialog>
  );
};

export default CorpOfferGetCertDialog;
