import { Fade, Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { StubWrapper, Wrapper } from './controls';
import { CartDetailsLayoutType } from './types';

const CartDetailsLayout: CartDetailsLayoutType = props => {
  const { title, help, sidebar, stub, loader, children } = props;

  const { x5 } = useGridSpacing();
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Fade in>
      <DefaultContentWrapper>
        <Wrapper
          container
          spacing={x5}
        >
          <MPGrid
            item
            zero={12}
          >
            <Typography variant='h3'>{title}</Typography>
          </MPGrid>
          <MPGrid
            item
            zero={12}
            md
          >
            {children}
          </MPGrid>
          {!isSmUp && (
            <MPGrid
              item
              zero={12}
            >
              {help}
            </MPGrid>
          )}
          <MPGrid
            item
            md={4}
          >
            {sidebar}
          </MPGrid>
        </Wrapper>

        {stub && <StubWrapper>{stub}</StubWrapper>}
        {loader}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default CartDetailsLayout;
