import { Text } from '../../components/controls';

const AboutPersonalDataPolicyStep6 = () => {
  return (
    <>
      <Text>24. Субъекты персональных данных имеют право на:</Text>

      <Text>1) получение по запросу полной информации о своих персональных данных, обрабатываемых в ОАО «РЖД»;</Text>

      <Text>2) ознакомление со своими персональными данными при обращении в ОАО «РЖД»;</Text>

      <Text>
        3) уточнение своих персональных данных, их блокирование или уничтожение в случае, если персональные данные
        являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной
        цели обработки;
      </Text>

      <Text>4) прекращение обработки своих персональных данных;</Text>

      <Text>
        5) предоставление своих персональных данных и согласия на их обработку свободно, своей волей и в своем интересе;
      </Text>

      <Text>6) отзыв согласия на обработку своих персональных данных;</Text>

      <Text>
        7) обжалование в соответствии с законодательством Российской Федерации действий (бездействия) ОАО «РЖД» при
        обработке персональных данных;
      </Text>

      <Text>8) осуществление иных прав, предусмотренных законодательством Российской Федерации.</Text>
    </>
  );
};

export default AboutPersonalDataPolicyStep6;
