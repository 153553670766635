import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SecurityToken } from 'domain/model/user';

export type AuthProviderState = {
  readonly isAuthenticated: boolean;
  readonly isLoading: boolean;
  readonly token: Nullable<string>;
  readonly tokenParsed: Nullable<SecurityToken>;
};

type Reducer<T> = CaseReducer<AuthProviderState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AuthProviderState> & {
  authProviderSetState: Reducer<{ isAuthenticated: boolean; isLoading: boolean }>;
  authProviderSetAuthenticated: Reducer<boolean>;
  authProviderSetToken: Reducer<{ token: Nullable<string>; parsed: Nullable<SecurityToken> }>;
};

const slice = createSlice<AuthProviderState, Reducers, 'provider'>({
  name: 'provider',
  initialState: {
    isAuthenticated: false,
    isLoading: true,
    token: null,
    tokenParsed: null,
  },
  reducers: {
    authProviderSetState: (state, { payload }) => {
      state.isAuthenticated = payload.isAuthenticated;
      state.isLoading = payload.isLoading;
    },
    authProviderSetAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    authProviderSetToken: (state, { payload }) => {
      state.token = payload.token;
      state.tokenParsed = payload.parsed;
    },
  },
});

export const { authProviderSetState, authProviderSetAuthenticated, authProviderSetToken } = slice.actions;

export default slice.reducer;
