import { combineReducers } from '@reduxjs/toolkit';
import details, { CorpOfferDetailsState } from './details/store/slice';
import list, { CorpOfferListState } from './list/store/slice';

type CorpOfferState = {
  readonly list: CorpOfferListState;
  readonly details: CorpOfferDetailsState;
};

export default combineReducers<CorpOfferState>({
  list,
  details,
});
