import { Text } from './controls';

const AboutTermsOfUseAdvertInfo = () => {
  return (
    <>
      <Text>
        7.1. Правообладатель несет ответственность за рекламу, размещенную им в Платформе, в пределах, установленных
        законодательством Российской Федерации.
      </Text>
    </>
  );
};

export default AboutTermsOfUseAdvertInfo;
