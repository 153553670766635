import { combineReducers } from '@reduxjs/toolkit';
import details, { CartDetailsState } from './details/store/slice';

type CartState = {
  readonly details: CartDetailsState;
};

export default combineReducers<CartState>({
  details,
});
