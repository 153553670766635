import { getUserProfileRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemSettings } from '../../components/menuItems';

export const UserMenuSettingsAdapter = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(getUserProfileRoute());
  };

  return <UserMenuItemSettings onClick={onClick} />;
};
