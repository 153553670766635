import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsTradeOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskTradeOffersCategoriesAdapter from './adapters/categories';
import PartnerDeskTradeOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskTradeOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabTradeOffersNoData from './noData';

type PartnerDeskDetailsTabTradeOffersProps = {
  readonly guid: UUID;
};

const Layout = PartnerDeskOffersTabLayout;

export const PartnerDeskDetailsTabTradeOffers = (props: PartnerDeskDetailsTabTradeOffersProps) => {
  const { guid } = props;

  return (
    <Layout
      toolbar={<PartnerDeskTradeOffersCategoriesAdapter />}
      stub={<PartnerDeskDetailsTabTradeOffersNoData />}
      loader={<GeneralLoaderFixedAdapter selector={partnerDeskDetailsTradeOffersIsFetchingSelector} />}
    >
      <PartnerDeskTradeOffersCommonAdapter guid={guid} />
      <PartnerDeskTradeOffersUpcomingAdapter guid={guid} />
    </Layout>
  );
};
