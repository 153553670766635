import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';

type TradeOfferDetailStub = {
  readonly text: string;
}

const TradeOfferDetailStub = ({ text }: TradeOfferDetailStub) => {
  return <OfferDetailsErrorLayout text={text} />;
};

export default TradeOfferDetailStub;
