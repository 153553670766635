import { ReactNode } from 'react';
import { AttributeButton } from '../controls';

type AttributeProps = {
  readonly isOutOfStock: boolean;
  readonly selected: boolean;
  readonly children: ReactNode;
  readonly onClickAttribute: () => void;
  readonly productIds: any;
};

const Attribute = (props: AttributeProps) => {
  const { selected, isOutOfStock, children, onClickAttribute } = props;

  return (
    <AttributeButton
      onClick={onClickAttribute}
      variant='outlined'
      isOutOfStock={isOutOfStock}
      selected={selected}
      color='secondary'
      size='small'
    >
      {children}
    </AttributeButton>
  );
};

export default Attribute;
