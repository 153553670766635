import { StyledSplitter } from './controls';

type SplitterProps = {
  /**
   * number - на базе spacing
   * string - произвольный
   */
  readonly size?: number | string;
  readonly variant?: 'vertical' | 'horizontal';
  readonly relative?: boolean;
};

/** Сплиттер контента */
const Splitter: (props: SplitterProps) => JSX.Element = ({ size = 2, variant = 'horizontal', relative }) => (
  <StyledSplitter
    size={size}
    variant={variant}
    relative={relative}
  />
);

export default Splitter;
