import UserMenuContainer from 'presentation/features/user/menu/container';
import { useMemo } from 'react';

type HeaderUserMenuAdapterProps = {
  readonly onLocationClick?: Nullable<() => void>;
}

const HeaderUserMenuAdapter = (props: HeaderUserMenuAdapterProps) => {
  const { onLocationClick } = props;

  return useMemo(() => <UserMenuContainer onLocationClick={onLocationClick} />, [onLocationClick]);
};

export default HeaderUserMenuAdapter;
