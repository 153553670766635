import { SvgIconComponent } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { MouseEventHandler, ReactNode } from 'react';

type MPSwitchIconButtonProps = IconButtonProps & {
  readonly icons: {
    readonly on: SvgIconComponent | ReactNode;
    readonly off: SvgIconComponent | ReactNode;
  };
  readonly active: boolean;
  readonly inProgress?: boolean;
};

const MPSwitchIconButton = (props: MPSwitchIconButtonProps) => {
  const { icons, active, inProgress, onClick, color = 'secondary', ...others } = props;

  const onInternalClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(event);
  };

  const IconOn = icons.on;
  const IconOff = icons.off;

  let icon: ReactNode;
  if (active) {
    icon = typeof IconOn === 'function' ? <IconOn /> : IconOn;
  } else {
    icon = typeof IconOff === 'function' ? <IconOff /> : IconOff;
  }

  return (
    <IconButton
      color={color}
      disabled={inProgress}
      {...others}
      onClick={onInternalClick}
    >
      {icon}
      {inProgress && <ContentLoader size={20} />}
    </IconButton>
  );
};

export default MPSwitchIconButton;
