import { EAddressLevel } from 'domain/model/enums';
import AddressSingleSelector, { AddressSingleSelectorProps } from './index';
import { Address } from 'domain/model/address';
import { AddressHelper } from 'utils/address';

export type AddressCitySingleSelectorProps = Omit<
  AddressSingleSelectorProps,
  'onlyValidPostalCode' | 'level' | 'fromLevel' | 'toLevel'
>;

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getLocalityFullPath();

const AddressCitySingleSelector = (props: AddressCitySingleSelectorProps) => {
  return (
    <AddressSingleSelector
      {...props}
      getOptionLabel={props.getOptionLabel ?? getOptionLabelDefault}
      level={EAddressLevel.City}
    />
  );
};

export default AddressCitySingleSelector;
