import axios, { AxiosResponse, CancelToken } from 'axios';
import { getAddressEndpoint } from 'data/openApi/utils';
import { Address, AddressLink } from 'domain/model/address';
import { EAddressLevel } from 'domain/model/enums';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';

const countDefault = 20 as const;

type CityQueryProps = ApiCancellable & {
  readonly name: string;
  readonly locations?: AddressLink[];
};

type AddressQueryProps = ApiCancellable & {
  readonly query: string;
  readonly count?: number;
  readonly level?: EAddressLevel;
  readonly fromLevel?: EAddressLevel;
  readonly toLevel?: EAddressLevel;
  readonly locations?: AddressLink[];
  readonly cancelToken?: CancelToken;
};

type AddressByIpQueryProps = ApiCancellable & {
  readonly cancelToken?: CancelToken;
};

type AddressApiType = {
  readonly cityQuery: (props: CityQueryProps) => Promise<AxiosResponse<Address[]>>;
  readonly addressQuery: (props: AddressQueryProps) => Promise<AxiosResponse<Address[]>>;
  readonly addressByIpQuery: (props: AddressByIpQueryProps) => Promise<AxiosResponse<Address>>;
};

const addressApi: AddressApiType = {
  cityQuery: ({ name, locations, signal }) => {
    const params = new URLSearchParams();
    if (name) {
      params.append('q', name);
    }
    params.append('maxCount', countDefault.toString(10));
    locations?.forEach(l => params.append('locations', JSON.stringify(l)));
    params.append('fromLevel', EAddressLevel.City.toString(10));
    params.append('toLevel', EAddressLevel.Settlement.toString(10));

    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  addressQuery: ({ query, count = countDefault, level, fromLevel, toLevel, locations, signal, cancelToken }) => {
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    params.append('maxCount', count.toString(10));
    locations?.forEach(l => params.append('locations', JSON.stringify(l)));

    if (level) {
      if (level === EAddressLevel.Area) {
        params.append('fromLevel', EAddressLevel.City.toString(10));
        params.append('toLevel', EAddressLevel.Area.toString(10));
      } else {
        params.append('fromLevel', level.toString(10));
        params.append('toLevel', level.toString(10));
      }
    } else if (fromLevel || toLevel) {
      if (fromLevel) {
        params.append('fromLevel', fromLevel.toString(10));
      }
      if (toLevel) {
        params.append('toLevel', toLevel.toString(10));
      }
    }

    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal)),
    });
  },
  addressByIpQuery: ({ signal, cancelToken }) => {
    return axios.get(`${getAddressEndpoint()}/iplocate`, {
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal)),
    });
  },
};

export default addressApi;
