import { createSelector } from '@reduxjs/toolkit';
import { productApi } from 'data/api/productOffer';
import { RootState } from 'data/store/store';
import { EOfferListType, EProductOfferSortType } from 'domain/model/enums';

export const selectRootStore = (store: RootState) => store;
export const productOfferListSelector = (store: RootState) => store.productOffer.list;
export const productOfferListGuidSelector = (store: RootState) => store.productOffer.list.guid;

export const productOfferListNameSelector = (store: RootState) => store.productOffer.list.common?.search?.name ?? null;

export const productOfferListFilterSelector = (store: RootState) => store.productOffer.list.filter;
export const productOfferListCategoryIdSelector = (store: RootState) =>
  store.productOffer.list.common.search?.categories?.[0];

export const productOfferListSearchIsEmptySelector = (store: RootState) =>
  !store.productOffer.list.common?.search?.name && !store.productOffer.list.common?.search?.categories?.length;

export const productOfferListCommonArgsSelector = createSelector(
  productOfferListSelector,
  lists => lists[EOfferListType.Common]
);

export const productOfferListCommonIsFetchingSelector = createSelector(
  selectRootStore,
  productOfferListCommonArgsSelector,
  productOfferListFilterSelector,
  (store, args, filter) => {
    if (!args) {
      return false;
    }

    const { isLoading } = productApi.endpoints.getProductOfferList.select({ ...args, ...filter })(store);
    return isLoading;
  }
);

export const productOfferListHasSomeFilters = createSelector(productOfferListFilterSelector, filter => {
  return filter ? Object.values(filter).some(value => value !== null) : false;
});

export const productOfferListCommonDataCountSelector = createSelector(
  selectRootStore,
  productOfferListCommonArgsSelector,
  productOfferListFilterSelector,
  (store, args, filter) => {
    if (!args) {
      return 0;
    }

    const { data } = productApi.endpoints.getProductOfferList.select({ ...args, ...filter })(store);

    return data?.data?.length ?? 0;
  }
);

export const productOfferListCommonIsErrorSelector = createSelector(
  selectRootStore,
  productOfferListCommonArgsSelector,
  productOfferListFilterSelector,
  (store, args, filter) => {
    if (!args) {
      return 0;
    }

    const { isError } = productApi.endpoints.getProductOfferList.select({ ...args, ...filter })(store);

    return isError;
  }
);

export const productOfferListCommonSortSelector = createSelector(selectRootStore, store => {
  return (store.productOffer.list.common?.sort ?? null) as Nullable<EProductOfferSortType[]>;
});

export const productOfferListIsNewFetchingSelector = createSelector(
  productOfferListCommonIsFetchingSelector,
  (store: RootState) => store.productOffer.list.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

export const productOfferListIsNewSearchFetchingSelector = createSelector(
  productOfferListCommonIsFetchingSelector,
  (store: RootState) => store.productOffer.list.isNewSearchFetching,
  (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching
);
