import { getTradeOfferCodeAssignDurationInSeconds } from 'presentation/features/offer/trade/utils';
import Countdown from '../common';

type TillDateCountdownProps = {
  readonly date: string;
  readonly active: boolean;
  readonly offset?: number;
  readonly stop: () => void;
};

const TillDateCountdown = (props: TillDateCountdownProps) => {
  const { date, active, stop } = props;

  const duration = getTradeOfferCodeAssignDurationInSeconds(date);

  return (
    <Countdown
      seconds={duration}
      active={active}
      stop={stop}
    />
  );
};

export default TillDateCountdown;
