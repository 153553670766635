import { EOrderDiscriminator } from '../../../../../domain/model/enums';
import BookingOfferOrder from './booking';
import useOrderDetailsData from './hooks/useOrderDetailsData';
import { OrderDetailContext } from './context';
import useOrderDetails from './hooks/useOrderDetails';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import OrderDetailsLoaderAdapter from './adapters/loader';
import OrderDetailStub from './adapters/stub';

type UserOrderContainerProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const UserOrderContainer = (props: UserOrderContainerProps) => {
  const { id } = props;

  const handlers = useOrderDetails();
  const { order } = useOrderDetailsData(id);

  return (
    <OrderDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <CommonRenderManager
          loader={<OrderDetailsLoaderAdapter id={id} />}
          stub={OrderDetailStub}
        >
          {order?.discriminator === EOrderDiscriminator.BookingOrder && <BookingOfferOrder order={order} />}
        </CommonRenderManager>
      </RenderManagerErrorProvider>
    </OrderDetailContext.Provider>
  );
};

export default UserOrderContainer;
