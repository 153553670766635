import { Link, LinkProps, Typography } from '@mui/material';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useEmailEditQuery } from 'presentation/hooks/useEmailEditQuery';
import { FC, useCallback, useEffect, useState } from 'react';
import { RequiredActionConfirmCodeContainer } from '../../confirmCode/container';
import { ActionConfirmWithCode } from '../../../../../domain/model';

type ConfirmAdapterProps = {
  readonly onClose: () => void;
  readonly onChangeEmail: () => void;
};

const ChangeEmail: FC<LinkProps> = props => (
  <Link
    color='primary'
    component={Typography}
    underline='hover'
    {...props}
  >
    Изменить почту
  </Link>
);

export const ConfirmAdapter: FC<ConfirmAdapterProps> = ({ onClose, onChangeEmail }) => {
  const { tokenParsed, refreshToken } = useAuthToken();

  const [source, setSource] = useState<Nullable<ActionConfirmWithCode>>(null);

  const [updateCurrentUserEmail, { isLoading: isFetching, error }] = useEmailEditQuery();

  const email = tokenParsed?.email ?? '';

  const onGetCode = useCallback(
    () => updateCurrentUserEmail({ email }).unwrap().then(setSource).catch(console.error),
    [email, updateCurrentUserEmail]
  );

  const onSuccess = () => {
    refreshToken();
    onClose();
  }

  useEffect(() => {
    onGetCode();
  }, [onGetCode]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
      setSource(null);
      onClose();
    }
  }, [error, onClose]);

  return (
    <>
      {source && (
        <RequiredActionConfirmCodeContainer
          email={email}
          source={source}
          onSuccess={onSuccess}
          onGetNewCode={onGetCode}
          back={<ChangeEmail onClick={onChangeEmail} />}
          onClose={onClose}
        />
      )}
      {isFetching && <ContentLoader />}
    </>
  );
};
