import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PriceRangeFilter } from 'domain/model/offer';
import { priceRangeMaxValue, priceRangeMinValue, productOfferEmptyFilter } from '../utils';

export type ProductFilterState = {
  readonly guid: Nullable<UUID>;
  readonly filter: PriceRangeFilter;
  readonly priceRangeFrom: number;
  readonly priceRangeTo: number;
};

type Reducer<T = undefined> = CaseReducer<ProductFilterState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<ProductFilterState> & {
  productOffersFilterSetPrice: Reducer<Pick<PriceRangeFilter, 'minPrice' | 'maxPrice'>>;
  productOffersFilterSet: Reducer<PriceRangeFilter>;
  productOffersFilterClear: Reducer;
};

const initialState: ProductFilterState = {
  guid: null,
  filter: productOfferEmptyFilter,
  priceRangeFrom: priceRangeMinValue,
  priceRangeTo: priceRangeMaxValue,
};

const slice = createSlice<ProductFilterState, Reducers, 'filter'>({
  name: 'filter',
  initialState,
  reducers: {
    productOffersFilterSetPrice: (state, { payload }) => {
      const { minPrice, maxPrice } = payload;
      state.filter.minPrice = minPrice;
      state.filter.maxPrice = maxPrice;
    },
    productOffersFilterSet: (state, { payload }) => {
      state.filter = payload;
    },
    productOffersFilterClear: state => {
      state.filter = initialState.filter;
    },
  },
});

export const { productOffersFilterSetPrice, productOffersFilterClear, productOffersFilterSet } = slice.actions;

export default slice.reducer;
