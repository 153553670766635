import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsProductOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskProductOffersAdapter from './adapters/data';
import PartnerDeskDetailsTabProductOffersNoData from './noData';

type PartnerDeskDetailsTabProductOffersProps = {
  readonly guid: UUID;
};

const Layout = PartnerDeskOffersTabLayout;

export const PartnerDeskDetailsTabProductOffers = (props: PartnerDeskDetailsTabProductOffersProps) => {
  const { guid } = props;

  return (
    <Layout
      stub={<PartnerDeskDetailsTabProductOffersNoData />}
      loader={<GeneralLoaderFixedAdapter selector={partnerDeskDetailsProductOffersIsFetchingSelector} />}
    >
      <PartnerDeskProductOffersAdapter guid={guid} />
    </Layout>
  );
};
