import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'data/store/store';
import { WrapperState } from './slice';

const pagesSelector = (store: RootState): WrapperState[] => store.lazyWrapper;
const currentPageSelector = (store: RootState, guid: UUID) => guid;

export const createPageContainerSelector = createSelector(
  pagesSelector,
  currentPageSelector,
  (pages, guid) => pages.find(page => page.guid === guid)?.containers ?? []
);

export const pageContainerSelector = (guid: UUID) => (store: RootState) => createPageContainerSelector(store, guid);
