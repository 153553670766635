import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PartnerOfferListRequest } from 'data/api/partner';
import {
  EBookingOfferSortType,
  ECorpOfferSortType,
  EOfferListType,
  EProductOfferSortType,
  ETradeOfferSortType,
} from 'domain/model/enums';
import { PaginationSize } from '../../../../types';
import { partnerDeskOffersListEmptyParams } from '../utils';

type TradeOffersState = PartnerOfferListRequest<ETradeOfferSortType>;
type AspOffersState = PartnerOfferListRequest<ETradeOfferSortType>;
type CorpOffersState = PartnerOfferListRequest<ECorpOfferSortType>;
type ProductOffersState = PartnerOfferListRequest<EProductOfferSortType>;
type BookingOffersState = PartnerOfferListRequest<EBookingOfferSortType>;

export type PartnerDeskDetailsState = {
  readonly guid: Nullable<UUID>;
  readonly tradeOffers: {
    readonly isNewFetching: boolean;
    readonly [EOfferListType.Common]: TradeOffersState;
    readonly [EOfferListType.Upcoming]: TradeOffersState;
  };
  readonly aspOffers: {
    readonly isNewFetching: boolean;
    readonly [EOfferListType.Common]: AspOffersState;
    readonly [EOfferListType.Upcoming]: AspOffersState;
  };
  readonly corpOffers: {
    readonly isNewFetching: boolean;
    readonly [EOfferListType.Common]: CorpOffersState;
    readonly [EOfferListType.Upcoming]: CorpOffersState;
  };
  readonly productOffers: {
    readonly isNewFetching: boolean;
    readonly [EOfferListType.Common]: ProductOffersState;
  };
  readonly bookingOffers: {
    readonly isNewFetching: boolean;
    readonly list: BookingOffersState;
  };
};

type Reducer<T = undefined> = CaseReducer<PartnerDeskDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<PartnerDeskDetailsState> & {
  partnerDeskStartSession: Reducer<UUID>;

  partnerDeskTradeOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: PaginationSize }>;
  partnerDeskTradeOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  partnerDeskTradeOffersSetSort: Reducer<{ type: EOfferListType; sort: Nullable<ETradeOfferSortType[]> }>;
  partnerDeskTradeOffersSetArgs: Reducer<{ type: EOfferListType; args: TradeOffersState }>;

  partnerDeskAspOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: PaginationSize }>;
  partnerDeskAspOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  partnerDeskAspOffersSetSort: Reducer<{ type: EOfferListType; sort: Nullable<ETradeOfferSortType[]> }>;
  partnerDeskAspOffersSetArgs: Reducer<{ type: EOfferListType; args: AspOffersState }>;

  partnerDeskCorpOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: PaginationSize }>;
  partnerDeskCorpOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  partnerDeskCorpOffersSetSort: Reducer<{ type: EOfferListType; sort: Nullable<ECorpOfferSortType[]> }>;
  partnerDeskCorpOffersSetArgs: Reducer<{ type: EOfferListType; args: CorpOffersState }>;

  partnerDeskProductOffersSetPageSize: Reducer<PaginationSize>;
  partnerDeskProductOffersSetPage: Reducer<number>;
  partnerDeskProductOffersSetSort: Reducer<Nullable<EProductOfferSortType[]>>;
  partnerDeskProductOffersSetArgs: Reducer<ProductOffersState>;

  partnerDeskBookingOffersSetPageSize: Reducer<PaginationSize>;
  partnerDeskBookingOffersSetPage: Reducer<number>;
  partnerDeskBookingOffersSetSort: Reducer<Nullable<EBookingOfferSortType[]>>;
  partnerDeskBookingOffersSetArgs: Reducer<BookingOffersState>;
};

const emptyOffersParams = partnerDeskOffersListEmptyParams;

const slice = createSlice<PartnerDeskDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    tradeOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams,
    },
    aspOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams,
    },
    corpOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams,
    },
    productOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
    },
    bookingOffers: {
      isNewFetching: true,
      list: emptyOffersParams,
    },
  },
  reducers: {
    partnerDeskStartSession: (state, { payload }) => {
      state.guid = payload;
    },
    partnerDeskTradeOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;
      state.tradeOffers[type].pageSize = pageSize;
    },
    partnerDeskTradeOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;
      state.tradeOffers[type].page = page;
    },
    partnerDeskTradeOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;
      state.tradeOffers[type].sort = sort;
    },
    partnerDeskTradeOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;
      state.tradeOffers[type] = args;
    },
    partnerDeskTradeOffersSetIsNewFetching: (state, { payload }) => {
      state.tradeOffers.isNewFetching = payload;
    },
    partnerDeskAspOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;
      state.aspOffers[type].pageSize = pageSize;
    },
    partnerDeskAspOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;
      state.aspOffers[type].page = page;
    },
    partnerDeskAspOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;
      state.aspOffers[type].sort = sort;
    },
    partnerDeskAspOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;
      state.aspOffers[type] = args;
    },
    partnerDeskAspOffersSetIsNewFetching: (state, { payload }) => {
      state.aspOffers.isNewFetching = payload;
    },
    partnerDeskCorpOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;
      state.corpOffers[type].pageSize = pageSize;
    },
    partnerDeskCorpOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;
      state.corpOffers[type].page = page;
    },
    partnerDeskCorpOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;
      state.corpOffers[type].sort = sort;
    },
    partnerDeskCorpOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;
      state.corpOffers[type] = args;
    },
    partnerDeskCorpOffersSetIsNewFetching: (state, { payload }) => {
      state.corpOffers.isNewFetching = payload;
    },
    partnerDeskProductOffersSetPageSize: (state, { payload }) => {
      state.productOffers.common.pageSize = payload;
    },
    partnerDeskProductOffersSetPage: (state, { payload }) => {
      state.productOffers.common.page = payload;
    },
    partnerDeskProductOffersSetSort: (state, { payload }) => {
      state.productOffers.common.sort = payload;
    },
    partnerDeskProductOffersSetArgs: (state, { payload }) => {
      state.productOffers.common = payload;
    },
    partnerDeskProductOffersSetIsNewFetching: (state, { payload }) => {
      state.productOffers.isNewFetching = payload;
    },
    partnerDeskBookingOffersSetPageSize: (state, { payload }) => {
      state.bookingOffers.list.pageSize = payload;
    },
    partnerDeskBookingOffersSetPage: (state, { payload }) => {
      state.bookingOffers.list.page = payload;
    },
    partnerDeskBookingOffersSetSort: (state, { payload }) => {
      state.bookingOffers.list.sort = payload;
    },
    partnerDeskBookingOffersSetArgs: (state, { payload }) => {
      state.bookingOffers.list = payload;
    },
    partnerDeskBookingOffersSetIsNewFetching: (state, { payload }) => {
      state.bookingOffers.isNewFetching = payload;
    },
  },
});

export const {
  partnerDeskStartSession,
  partnerDeskTradeOffersSetPage,
  partnerDeskTradeOffersSetPageSize,
  partnerDeskTradeOffersSetSort,
  partnerDeskTradeOffersSetArgs,
  partnerDeskTradeOffersSetIsNewFetching,
  partnerDeskAspOffersSetPage,
  partnerDeskAspOffersSetPageSize,
  partnerDeskAspOffersSetSort,
  partnerDeskAspOffersSetArgs,
  partnerDeskAspOffersSetIsNewFetching,
  partnerDeskCorpOffersSetPage,
  partnerDeskCorpOffersSetPageSize,
  partnerDeskCorpOffersSetSort,
  partnerDeskCorpOffersSetArgs,
  partnerDeskCorpOffersSetIsNewFetching,
  partnerDeskProductOffersSetPage,
  partnerDeskProductOffersSetPageSize,
  partnerDeskProductOffersSetSort,
  partnerDeskProductOffersSetArgs,
  partnerDeskProductOffersSetIsNewFetching,
  partnerDeskBookingOffersSetPage,
  partnerDeskBookingOffersSetPageSize,
  partnerDeskBookingOffersSetSort,
  partnerDeskBookingOffersSetArgs,
  partnerDeskBookingOffersSetIsNewFetching,
} = slice.actions;

export default slice.reducer;
