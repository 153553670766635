import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { ReactNode } from 'react';

type CommonHelpDialogProps = {
  readonly open: boolean;
  readonly title: ReactNode;
  readonly text: ReactNode;
  readonly onClose: () => void;
};

const CommonHelpDialog = (props: CommonHelpDialogProps) => {
  const { open, title, text, onClose } = props;

  return (
    <MPConfirmDialog
      open={open}
      title={title}
      maxWidth='xs'
      fullScreenBreakpoint='sm'
      buttons={
        <MPButton
          fullWidth
          size='large'
          color='brand'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
    >
      {text}
    </MPConfirmDialog>
  );
};

export default CommonHelpDialog;
