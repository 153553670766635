import { Text } from './controls';

const AboutCookiesWhatAreCookiesInfo = () => {
  return (
    <>
      <Text>
        Сookies - небольшие фрагменты данных, передаваемые с сайта на компьютер пользователя, мобильный телефон или иное
        устройство, которые записывают и хранят информацию о действиях пользователя на сайте. Cookies отражают ваши
        предпочтения или действия на Сайте, а также сведения об оборудовании пользователя, дату и время сессии. Они
        используются для вычисления параметров аудитории, отслеживания прогресса и количества входов - важные параметры
        для корректной работы Сайта. Сookies хранятся локально на вашем компьютере или мобильном устройстве. При желании
        вы можете удалить сохранённые Сookies в настройках браузера. Большинство вебсайтов используют cookie, файлы
        cookie полностью безопасны для компьютеров и иных устройств.
      </Text>
    </>
  );
};
export default AboutCookiesWhatAreCookiesInfo;
