import { SettingsIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { UserMenuItem } from '../../../components/menuItem';

type UserMenuItemSettingsProps = {
  readonly onClick?: () => void;
};

export const UserMenuItemSettings: FC<UserMenuItemSettingsProps> = ({ onClick }) => {
  return (
    <UserMenuItem
      label='Настройки'
      icon={<SettingsIcon color='black' />}
      onClick={onClick}
    />
  );
};
