import styled from '@emotion/styled/macro';
import { css, tabsClasses } from '@mui/material';
import { MPTabs } from 'presentation/theme/ui-kit/tab';

export const LinksWrapper = styled(MPTabs)(
  ({ theme }) => css`
    .${tabsClasses.flexContainer} {
      gap: ${theme.spacing()};
    }
  `
);

export const InnerWrapper = styled.div(
  ({ theme }) => css`
    margin-left: -${theme.spacing()};
  `
);
