import { FCC, ReactNode, useEffect, useRef, useState } from 'react';
import { CloseButton, Content, ContentWrapper, Link, Wrapper } from './controls';
import { Dialog, Typography, TypographyProps } from '@mui/material';
import AppSvgIcon from '../../../icon';
import { CloseIcon } from '../../../../../media/icons';
import Splitter from '../../../splitter';
import { MPButton } from '../../../../../theme/ui-kit/button';

type ContentLimiterDialogLayoutProps = {
  readonly rows?: number;
  readonly title?: ReactNode;
  readonly moreText?: ReactNode;
  readonly contentProps?: TypographyProps;
};

export const ContentLimiterDialogLayout: FCC<ContentLimiterDialogLayoutProps> = props => {
  const { children, title, rows = 5, moreText, contentProps } = props;

  const refContent = useRef<Nullable<HTMLDivElement>>(null);
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const handleMoreClick = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.clientHeight < refContent.current.scrollHeight) {
        setIsExpandable(true);
        refContent.current.style.display = '-webkit-box';
      }
    }
  }, [refContent]);

  const MoreComponent = (
    <Link
      variant={'body1'}
      onClick={handleMoreClick}
    >
      {moreText ? moreText : 'Посмотреть полностью'}
    </Link>
  );

  return (
    <Wrapper>
      <Content
        rows={rows}
        ref={refContent}
        variant={'body2'}
        {...contentProps}
      >
        {children}
      </Content>
      {isExpandable && moreText !== null && MoreComponent}
      {isOpenDialog && (
        <Dialog
          open={true}
          onClose={handleCloseDialog}
        >
          <ContentWrapper>
            <Typography variant='h2'>{title}</Typography>
            <CloseButton onClick={handleCloseDialog}>
              <AppSvgIcon icon={CloseIcon} />
            </CloseButton>
            <Splitter size={3} />
            {children}
            <Splitter size={3} />
            <MPButton
              onClick={handleCloseDialog}
              fullWidth={true}
              color={'brand'}
            >
              Закрыть
            </MPButton>
          </ContentWrapper>
        </Dialog>
      )}
    </Wrapper>
  );
};
