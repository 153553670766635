import HttpClient from 'data/network/http';
import ContentLoader from 'presentation/components/common/loader';
import { LoginLayoutType } from 'presentation/features/auth/login/layout/types';
import { useEffect } from 'react';
import LoginLayout from './layout';

const Layout: LoginLayoutType = LoginLayout;

const LoginContainer = () => {
  const authService = HttpClient.getInstance().getAuthService();

  useEffect(() => {
    if (!authService?.authenticated) {
      authService?.login();
    }
  }, [authService]);

  return (
    <Layout>
      <ContentLoader />
    </Layout>
  );
};
export default LoginContainer;
