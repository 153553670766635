import useTradeOfferCategoriesData from './useTradeOfferCategoriesData';
import useTradeOfferDetailsData from './useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from './useTradeOfferPartnerDeskData';

type TradeDetailsFetchingStatusProps = {
  readonly id: UUID;
};

type UseTradeOfferDetailsFetchingStatus = boolean;

const useTradeOfferDetailsFetchingStatus = ({
  id,
}: TradeDetailsFetchingStatusProps): UseTradeOfferDetailsFetchingStatus => {
  const { isCategoriesFetching } = useTradeOfferCategoriesData();
  const { isTradeOfferFetching } = useTradeOfferDetailsData(id);
  const { isPartnerDeskFetching } = useTradeOfferPartnerDeskData(id);

  return isCategoriesFetching || isTradeOfferFetching || isPartnerDeskFetching;
};

export default useTradeOfferDetailsFetchingStatus;
