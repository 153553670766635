import { Location } from 'history';
import PartnerDeskScreen from 'presentation/screen/partnerDesk';
import PartnerDesksScreen from 'presentation/screen/partnerDesks';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { EPartnerDeskDetailsTab } from './details/utils';
import { EPartnerDeskUrlParam } from './utils';

const root = rootRouting.partner;

const routing = {
  list: root,
  details: `${root}/:id`,
};

export type PartnerDeskDetailsLocationState = {
  readonly guid: UUID;
};

export type PartnerDesksListLocationState = {
  readonly guid: UUID;
};

type GetPartnerDeskDetailsRouteProps = {
  readonly guid?: UUID;
  readonly id: UUID;
  readonly categoryId?: UUID;
  readonly tab?: EPartnerDeskDetailsTab;
};

type GetPartnerDeskListRouteProps = {
  readonly guid?: UUID;
};

export const getPartnerDeskDetailsRoute = (
  props: GetPartnerDeskDetailsRouteProps
): Location<PartnerDeskDetailsLocationState> => {
  const { guid, id, tab, categoryId } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerDeskUrlParam.Tab, tab);
  }
  if (categoryId) {
    searchParams.append(EPartnerDeskUrlParam.Category, categoryId);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getPartnerDeskListRoute = (
  props: GetPartnerDeskListRouteProps
): Location<PartnerDesksListLocationState> => {
  const { guid } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  return {
    pathname: routing.list,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const PartnerDeskEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={PartnerDesksScreen}
      />
      <Route
        exact
        path={routing.details}
        component={PartnerDeskScreen}
      />
      <Redirect to={routing.list} />
    </Switch>
  );
};

export default PartnerDeskEntry;
