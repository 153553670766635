import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const cards: OverrideThemeFunc<Components> = baseTheme => ({
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: baseTheme.shape.borderRadius * 1.2,
      },
    },
  },
});

export default cards;
