import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPartnerDeskQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import useCorpOfferDetailsData from 'presentation/features/offer/corp/details/hooks/useCorpOfferDetailsData';
import { useEffect } from 'react';

const useCorpOfferPartnerDeskData = (id: UUID) => {
  const { corpOffer, isCorpOfferFetched } = useCorpOfferDetailsData(id);

  const partnerDeskId = isCorpOfferFetched ? corpOffer?.partner?.id : undefined;

  const {
    data: partnerDesk,
    error: partnerDeskFetchError,
    isError: isPartnerDeskError,
    isFetching: isPartnerDeskFetching,
    isLoading: isPartnerDeskLoading,
  } = useGetPartnerDeskQuery(partnerDeskId ?? skipToken);

  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
    }
  }, [partnerDeskFetchError]);

  return {
    partnerDesk,
    partnerDeskFetchError,
    isPartnerDeskError,
    isPartnerDeskFetching,
    isPartnerDeskLoading,
  };
};

export default useCorpOfferPartnerDeskData;
