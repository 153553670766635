import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskCorpOffersCategoriesAdapter from 'presentation/features/partnerDesk/details/tabs/corpOffers/adapters/categories';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsCorpOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskCorpOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskCorpOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabCorpOffersNoData from './noData';

type PartnerDeskDetailsTabCorpOffersProps = {
  readonly guid: UUID;
};

const Layout = PartnerDeskOffersTabLayout;

export const PartnerDeskDetailsTabCorpOffers = (props: PartnerDeskDetailsTabCorpOffersProps) => {
  const { guid } = props;

  return (
    <Layout
      toolbar={<PartnerDeskCorpOffersCategoriesAdapter />}
      stub={<PartnerDeskDetailsTabCorpOffersNoData />}
      loader={<GeneralLoaderFixedAdapter selector={partnerDeskDetailsCorpOffersIsFetchingSelector} />}
    >
      <PartnerDeskCorpOffersCommonAdapter guid={guid} />
      <PartnerDeskCorpOffersUpcomingAdapter guid={guid} />
    </Layout>
  );
};
