import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ContentLoader from 'presentation/components/common/loader';
import { bookingOfferIsFilterFetchingSelector, bookingOfferListIsFetchingSelector } from '../store/selectors';
import { BookingOffersSearchListLocationState } from '../../types';

const Loader = <ContentLoader position='fixed' />;

type BookingOfferListLoaderAdapterProps = Pick<BookingOffersSearchListLocationState, 'categoryId'>;

export const BookingOfferListLoaderAdapter = ({ categoryId }: BookingOfferListLoaderAdapterProps) => {
  const isFetching = useSelector(bookingOfferListIsFetchingSelector);
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);

  return useMemo(
    () => (isFetching || (categoryId && isFilterFetching) ? Loader : null),
    [isFetching, isFilterFetching, categoryId]
  );
};
