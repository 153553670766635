import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEffectAfterMount from '../../../../../hooks/useEffectAfterMount';
import { PageableArgsStorageProps, PageableArgsStorageResult, PageableListState } from '../../types';
import { pageableArgsSelector } from './store/selectors';
import { pageableArgsPut, pageableArgsPutPartial } from './store/slice';

/**
 * хук для работы с состоянием пагинированного списка в рамках redux slice
 * добавляет в slice параметры запроса вместе с пагинацией
 * времени жизни сохраняемых данных нет
 */
const usePageableArgsInRedux = <P extends PageableListState<PS>, PS extends number = number>(
  props: PageableArgsStorageProps<P>
): PageableArgsStorageResult<P, PS> => {
  const { guid: key, defaultState } = props;

  const data = useSelector(pageableArgsSelector(key));
  const { page: savedPage, pageSize: savedPageSize, ...savedStatePayload } = data ?? {};

  const { pageSize: defaultPageSize } = defaultState;
  const defaultStateString = JSON.stringify(defaultState);
  const savedStatePayloadString = Object.keys(savedStatePayload).length > 0 ? JSON.stringify(savedStatePayload) : null;

  const dispatch = useDispatch();

  const currentState = useMemo(() => {
    return {
      ...JSON.parse(defaultStateString),
      ...data,
    };
  }, [data, defaultStateString]);

  const save = useCallback(
    (state: P & PageableListState<PS>) => {
      const { page, pageSize, ...newStatePayload } = state;
      const newStatePayloadString = JSON.stringify(newStatePayload);
      //изменилась нагрузка - сбрасываем page и pageSize
      if (savedStatePayloadString && newStatePayloadString !== savedStatePayloadString) {
        dispatch(pageableArgsPut({ key, data: { ...state, page: 1, pageSize: defaultPageSize } }));
      } else {
        dispatch(pageableArgsPut({ key, data: state }));
      }
    },
    [dispatch, key, savedStatePayloadString, defaultPageSize]
  );

  const savePartial = useCallback(
    (state: Partial<P>) =>
      dispatch(
        pageableArgsPutPartial({
          key,
          data: { ...state, page: 1, pageSize: defaultPageSize },
        })
      ),
    [defaultPageSize, dispatch, key]
  );

  const reset = useCallback(() => {
    dispatch(pageableArgsPut({ key, data: JSON.parse(defaultStateString) }));
  }, [dispatch, key, defaultStateString]);

  //изменение нагрузки
  useEffectAfterMount(() => {
    dispatch(pageableArgsPut({ key, data: JSON.parse(defaultStateString) }));
  }, [defaultStateString]);

  return {
    currentState,
    defaultState,
    save,
    savePartial,
    reset,
  };
};

export default usePageableArgsInRedux;
