import { Stack, Typography } from '@mui/material';

type OrderFieldProps = {
  readonly label: string;
  readonly text: string;
};

const OrderField = ({ text, label }: OrderFieldProps) => {
  return (
    <Stack>
      <Typography
        variant='body2'
        color='textSecondary'
      >
        {label}
      </Typography>
      <Typography variant='body1'>{text}</Typography>
    </Stack>
  );
};

export default OrderField;
