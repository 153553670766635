import TechConfigContext, {
  TechConfigContextType,
} from 'presentation/features/general/config/contexts/techConfigContext';
import { useContext } from 'react';

type UseTechConfig = () => TechConfigContextType;

const useTechConfig: UseTechConfig = () => {
  return useContext<TechConfigContextType>(TechConfigContext);
};

export default useTechConfig;
