import { useRouteMatch } from 'react-router';
import Option from './component';
import { HeaderOptionProps } from './types';

const HeaderOption = (props: HeaderOptionProps) => {
  const { to } = props;
  const match = useRouteMatch(typeof to === 'string' ? to : to?.pathname);

  return (
    <Option
      active={!!match?.isExact}
      {...props}
    />
  );
};

export default HeaderOption;
export type { HeaderOptionProps } from './types';
