import { EOfferListType } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAspOffersContextHandlers } from '../../../hooks/useAspOffersContextHandlers';
import {
  partnerDeskDetailsAspOffersUpcomingArgsSelector,
  partnerDeskDetailsGuidSelector,
} from '../../../store/selectors';
import PartnerDeskTabTextHeader from '../../header';
import PartnerDeskAspOffersDataContainer from '../dataContainer';

type PartnerDeskAspOffersUpcomingAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Upcoming;

const PartnerDeskAspOffersUpcomingAdapter = ({ guid }: PartnerDeskAspOffersUpcomingAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsAspOffersUpcomingArgsSelector);

  const handlers = useAspOffersContextHandlers();

  const header = useMemo(
    () => (
      <PartnerDeskTabTextHeader>
        Скоро будут доступны
        <Splitter size={3} />
      </PartnerDeskTabTextHeader>
    ),
    []
  );

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskAspOffersDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          header={header}
          onChangePage={handlers.onChangeAspOffersPage}
          onShowCard={handlers.onShowAspOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeAspOffersPage, handlers.onShowAspOffersCard, header]
  );
};

export default PartnerDeskAspOffersUpcomingAdapter;
