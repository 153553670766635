import styled from '@emotion/styled/macro';
import {
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  CardProps as MuiCardProps,
  css,
  Typography,
} from '@mui/material';

type CardProps = MuiCardProps & {
  readonly dense?: boolean;
  readonly toRemove?: boolean;
};

export const CardContent = styled(MuiCardContent)(
  ({ theme }) => css`
    flex-grow: 1;

    padding: ${theme.spacing(1.5, 0, 0.5, 0)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1, 0, 0, 0)};
    }
  `
);

export const Card: any = styled(({ dense, toRemove, ...others }: CardProps) => <MuiCard {...others} />)(
  ({ toRemove, theme, dense }) => css`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;

    user-select: none;
    cursor: pointer;
    box-shadow: none;

    &.MuiCard-root {
      opacity: ${toRemove ? 0.5 : 1};
      border-radius: 0;

      ${theme.breakpoints.up('sm')} {
        overflow: hidden;
      }
    }

    & .MuiTypography-root {
      ${dense
        ? `
      font-size: ${theme.typography.caption.fontSize};
      line-height: ${theme.typography.caption.lineHeight};
      margin-bottom: 0;
    `
        : ''}
    }

    & .MuiCardContent-root:last-child {
      ${dense ? `padding-top: ${theme.spacing()};` : ''}
    }
  `
);

export const CardDescription = styled(Typography)(
  () => css`
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  `
);

export const CardActions = styled(MuiCardActions)(
  () => css`
    display: flex;
    padding: 0;
    flex: 1;
    margin-top: 0;
    align-items: stretch;
    flex-direction: column;

    & > :not(:first-of-type) {
      margin-left: 0;
    }
  `
);

export const CartActions = styled(MuiCardActions)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(0.75)};
    padding: 0;

    & > div {
      grid-template-columns: min-content minmax(82px, 102px);
      width: auto;
    }

    ${theme.breakpoints.up('sm')} {
      margin-top: ${theme.spacing(1.5)};
    }
  `
);

export const CoverImageViewWrapper = styled.div(
  () => css`
    width: 100%;
    position: relative;
  `
);

export const CardDetails = styled.div(
  () => css`
    display: flex;
    margin-left: 0;
    flex-direction: column;
    flex-grow: 1;
  `
);

export const CardFavoriteButtonWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;

    ${theme.breakpoints.down('sm')} {
      && .MuiIconButton-root {
        padding: ${theme.spacing(0.5)};
      }
    }
  `
);

export const SwitchIconButtonWrapper = styled.div(
  ({ theme }) => css`
    && .MuiIconButton-root {
      padding: ${theme.spacing(0.5)};
    }
  `
);
