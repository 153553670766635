import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;

    justify-content: space-between;
  `
);

export const FooterWrapper = styled.div(
  p => css`
    margin-top: ${p.theme.spacing(2)};
  `
);
