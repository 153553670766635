import CartDetailsScreen from 'presentation/screen/cart';
import { LastOrdersScreen } from 'presentation/screen/cart/lastOrders';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';

const root = rootRouting.cart;

const routing = {
  root,
  lastOrders: `${root}/last-orders`,
};

export const getCartRootRoute = () => routing.root;

export const getLastOrderRoute = () => routing.lastOrders;

const CartEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.root}
        component={CartDetailsScreen}
      />
      <Route
        exact
        path={routing.lastOrders}
        component={LastOrdersScreen}
      />
      <Redirect to={routing.root} />
    </Switch>
  );
};

export default CartEntry;
