import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferTabs from '../tabs';

type TradeOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ETradeOfferDetailsTab;
};

const TradeOfferDetailsTabsAdapter = ({ tab, id }: TradeOfferDetailsTabsAdapterProps) => {
  const { onChangeTab } = useContextHandlers();
  const { tradeOffer } = useTradeOfferDetailsData(id);

  const { partnerDesk } = useTradeOfferPartnerDeskData(id);

  if (!tradeOffer || !partnerDesk) {
    return null;
  }

  return (
    <TradeOfferTabs
      tab={tab}
      tradeOffer={tradeOffer}
      partnerDesk={partnerDesk}
      onChange={(event, newTab) => onChangeTab(newTab)}
    />
  );
};

export default TradeOfferDetailsTabsAdapter;
