import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    z-index: 3;
    bottom: 2rem;
    left: 2rem;

    color: white;
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.warning.light};
    border: 1px solid ${theme.palette.warning.main};
    border-radius: ${theme.spacing(1)};
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.warning.main};
    }
  `
);
