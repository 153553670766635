import { combineReducers } from '@reduxjs/toolkit';
import details, { TradeOfferDetailsState } from './details/store/slice';
import list, { TradeOfferListState } from './list/store/slice';

type TradeOfferState = {
  readonly list: TradeOfferListState;
  readonly details: TradeOfferDetailsState;
};

export default combineReducers<TradeOfferState>({
  list,
  details,
});
