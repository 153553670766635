import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';

export const Wrapper = styled(DefaultContentWrapper)(
  ({ theme }) => css`
    max-width: 1184px !important;

    margin: auto;
    position: relative;

    ${theme.breakpoints.down('lg')} {
      max-width: 580px !important;
    }
  `
);

export const ContentWrapper = styled.div(
  () => css`
    position: relative;
  `
);
