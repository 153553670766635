import { switchClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const switchField: OverrideThemeFunc<Components> = baseTheme => ({
  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      input: {
        width: 45,
      },
      switchBase: {
        padding: 0,
        transform: 'translate(1.5px, 1.5px)',

        [`.${switchClasses.input}`]: {
          left: '0',
        },

        [`.${switchClasses.input}`]: {
          backgroundColor: baseTheme.palette.secondary.A900,
        },

        [`&.${switchClasses.checked}`]: {
          transform: 'translate(21.5px, 1.5px)',

          [`.${switchClasses.input}`]: {
            left: '-100%',
          },

          [`+.${switchClasses.track}`]: {
            opacity: 1,
          },

          [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
            opacity: 0.4,
          },
        },
      },
      thumb: {
        backgroundColor: baseTheme.palette.white.main,
        boxShadow: 'unset',
      },
      sizeSmall: {
        [`&.${switchClasses.root}`]: {
          width: 45,
          height: 22,
          borderRadius: 100,
        },

        [`.${switchClasses.track}`]: {
          width: 45,
          height: 22,
          borderRadius: 100,
        },

        [`.${switchClasses.switchBase}`]: {
          top: '50%',
          transform: 'translate(-1.5px, calc(-50%))',

          [`&.${switchClasses.checked}`]: {
            transform: 'translate(21.5px, calc(-50%))',
          },
        },

        [`.${switchClasses.thumb}`]: {
          width: 18,
          height: 18,
        },
      },
      sizeMedium: {
        [`&.${switchClasses.root}`]: {
          width: 45,
          height: 24,
          borderRadius: 100,
        },

        [`.${switchClasses.track}`]: {
          width: 45,
          height: 24,
          borderRadius: 100,
        },
        [`.${switchClasses.thumb}`]: {
          width: 21,
          height: 21,
        },
      },
    },
  },
});

export default switchField;
