import { memo } from 'react';
import { useSelector } from 'react-redux';
import { BookingOffersSearchListLocationState } from '../../types';
import BookingOfferListData from '../dataContainer';
import {
  bookingOfferListArgsSelector,
  bookingOfferListGuidSelector,
  bookingOfferListPriceFilterSelector,
  bookingOfferListServicesFilterSelector,
} from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';

type BookingOfferListDataAdapterProps = Pick<BookingOffersSearchListLocationState, 'guid' | 'categoryId'>;

export const BookingOfferListDataAdapter = memo(({ guid, categoryId }: BookingOfferListDataAdapterProps) => {
  const currentGuid = useSelector(bookingOfferListGuidSelector);
  const args = useSelector(bookingOfferListArgsSelector);
  const priceRange = useSelector(bookingOfferListPriceFilterSelector);
  const services = useSelector(bookingOfferListServicesFilterSelector);

  const handlers = useContextHandlers();

  return guid !== currentGuid || !args ? null : (
    <BookingOfferListData
      guid={currentGuid}
      args={args}
      priceRange={priceRange}
      services={services}
      categoryId={categoryId}
      onShowCard={handlers.onShowCard}
    />
  );
});
