import { GridSpacing } from '@mui/material/Grid/Grid';
import { FCC, useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { DetailSwiperWrapper, Wrapper } from './controls';

type HorizontalScrollerProps = {
  readonly children?: any[];
  readonly initialSelected?: number;
  readonly gap?: number;
  readonly gapSpacing?: GridSpacing;
};

const HorizontalScroller: FCC<HorizontalScrollerProps> = props => {
  const { initialSelected, gap, gapSpacing, children } = props;

  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);

  useEffect(() => {
    if (initialSelected) {
      swiper?.slideTo(initialSelected, 0);
    }
  }, [initialSelected, swiper]);

  return (
    <Wrapper>
      <DetailSwiperWrapper
        gap={gap}
        gapSpacing={gapSpacing}
      >
        <Swiper
          freeMode={true}
          modules={[FreeMode]}
          slidesPerView='auto'
          runCallbacksOnInit
          onSwiper={setSwiper}
        >
          {children}
        </Swiper>
      </DetailSwiperWrapper>
    </Wrapper>
  );
};

export const HorizontalScrollerItem = SwiperSlide;

export default HorizontalScroller;
