import AboutTextSimpleContainer from '../textSimple/container';
import { List, Text, ListItem } from 'presentation/features/about/components/controls';

const AboutPersonalDataAgreementContainer = () => {
  return (
    <AboutTextSimpleContainer title='Согласие на обработку персональных данных'>
      <Text>
        «Настоящим я даю согласие открытому акционерному обществу «Российские железные дороги» (107174, г. Москва, ул.
        Новая Басманная, д. 2/1, стр. 1) на обработку следующих моих персональных данных: табельный номер, фамилия, имя,
        отчество, адрес электронной почты, номер мобильного (сотового) телефона, дата рождения, семейное положение, пол,
        с целью регистрации и использования личного кабинета на веб-сайте «Витрина» и в мобильном приложении «Пульс».
      </Text>
      <Text>
        С указанной целью я даю согласие на осуществление следующих действий с моими персональными данными: сбор,
        запись, систематизация, накопление, хранение, уточнение (обновление, изменение), передача (предоставление,
        доступ), извлечение, использование, блокирование, обезличивание, удаление, уничтожение персональных данных с
        использованием средств автоматизации, в том числе в информационно- телекоммуникационных сетях, или без
        использования таких средств.
      </Text>
      <Text>
        Настоящим я подтверждаю, что предоставленные мной в ОАО «РЖД» персональные данные являются достоверными и могут
        обрабатываться ОАО «РЖД» с заявленной целью.
      </Text>
      <Text>
        Настоящим я даю свое согласие ОАО «РЖД» направлять мне электронные письма/информационные сообщения на указанный
        мной адрес электронной почты и/или номер мобильного телефона.
      </Text>
      <Text>
        Также я даю согласие ОАО «РЖД» на передачу в рамках поручения моих персональных данных ООО «Цифровые сервисы»
        (354340, Краснодарский край, пгт. Сириус, Международная ул, д. 2/1, этаж 4 ком. 405) в следующем составе:
        табельный номер, фамилия, имя, отчество, адрес электронной почты, номер мобильного (сотового) телефона, дата
        рождения, семейное положение, пол, с целью обмена бонусных баллов работника на корпоративные предложения;
        приобретения пользователями веб-сайте «Витрина» (Модуль корпоративной витрины) предложений партнеров,
        опубликованных на веб-сайте «Витрина». А также продвижения товаров, работ, услуг, опубликованных на веб-сайте
        «Витрина», в том числе проведения анализа поведения пользователя, а также выявления предпочтений пользователя к
        определенному виду контента, идентификации пользователя, предоставления персонализированных рекламных и
        маркетинговых материалов, направления персонализированных рекламных и маркетинговых материалов на указанный
        электронный адрес и/или мобильный телефон пользователя, отслеживания заказов/покупок, совершенных пользователем
        веб-сайта «Витрина»; выполнения социальных обязательств в части организации, сопровождения и проведения
        спортивных мероприятий, в том числе определения места нахождения пользователя, поддержания связи с пользователем
        (коммуникация), в мобильном приложении «Пульс» (Модуль предоставления сервисов внешних поставщиков услуг).
      </Text>
      <Text>
        При регистрации в мобильном приложении «Пульс» я даю согласие ОАО «РЖД» на передачу моих персональных данных
        следующим третьим лицам:
      </Text>
      <List as='ol'>
        <ListItem>
          ОО РФСО «Локомотив» (107078, г. Москва, ул. Новая Басманная, д. 14, стр. 2) в следующем составе: табельный
          номер, фамилия, имя, отчество, адрес электронной почты, номер мобильного (сотового) телефона, дата рождения,
          семейное положение, пол, с целью моего участия в организуемых, сопровождаемых и проводимых спортивных
          мероприятиях дорожного и корпоративного уровня;
        </ListItem>
        <ListItem>
          Аппарат ЦК РОСПРОФЖЕЛ (107996, г. Москва, ул. Краснопрудная, д. 22-24, стр. 1), Дорпрофжел на
          Восточно-Сибирской железной дороге (664003, г. Иркутск, ул. Карла Маркса, д. 7), Дорпрофжел на Горьковской
          железной дороге (603011, г. Нижний Новгород, ул. Октябрьской революции, д. 78), Дорпрофжел на Дальневосточной
          железной дороге (680000, г. Хабаровск, ул. Муравьева-Амурского, д. 20), Дорпрофжел на Забайкальской железной
          дороге (672000, г. Чита, ул. Ленинградская, д. 34), Дорпрофжел на Западно-Сибирской железной дороге (630004,
          г. Новосибирск, ул. Урицкого, д. 39), Дорпрофжел на Калининградской железной дороге (236039, г. Калининград,
          ул. Киевская, д. 1), Дорпрофжел на Красноярской железной дороге (660017, г. Красноярск, Центральный район, ул.
          Горького, зд. 6), Дорпрофжел на Куйбышевской железной дороге (443030, г. Самара, площадь Комсомольская, д.
          2/3,офис 211), Дорпрофжел на Московской железной дороге (107140, г. Москва, Красносельский район, ул.
          Краснопрудная, д. 20), Дорпрофжел на Октябрьской железной дороге (191023, г. Санкт-Петербург, пл. Островского
          д. 2, офис 312), Дорпрофжел на Приволжской железной дороге (410031, г. Саратов, ул. Московская, д. 8),
          Дорпрофжел на Свердловской железной дороге (620027, г. Екатеринбург, Железнодорожный район, ул. Челюскинцев,
          стр. 11), Дорпрофжел на Северной железной дороге (150003, г. Ярославль, ул. Волжская набережная 59),
          Дорпрофжел на Северо-Кавказской железной дороге (344019, г. Ростов- на-Дону, ул. Театральная, д. 4),
          Дорпрофжел на Юго-Восточной железной дороге (394036, г. Воронеж, проспект Революции, 18), Дорпрофжел на
          Южно-Уральской железной дороге (454091, г. Челябинск, пр Революции, д. 3), МОО ППО работников аппарата
          управления ОАО «РЖД» (107078, г. Москва, Новая Басманная ул., д. 2), Терпрофжел МОСЖЕЛТРАНС (107053, г.
          Москва, Садовая-Спасская ул., 21/1) в следующем составе: табельный номер, фамилия, имя, отчество, адрес
          электронной почты, номер мобильного (сотового) телефона, дата рождения, семейное положение, пол, с целью моего
          участия в организуемых, сопровождаемых и проводимых спортивных мероприятиях узлового уровня;
        </ListItem>
      </List>
      <Text>
        Я согласен(на) с тем, что текст данного мной согласия хранится в электронном виде и подтверждает факт дачи ОАО
        «РЖД» согласия на обработку и передачу персональных данных в соответствии с вышеизложенными положениями.
      </Text>
      <Text>
        Настоящим я подтверждаю, что, размещая информацию на персональной странице, в том числе свои персональные
        данные, я осознаю и соглашаюсь с тем, что указанная информация может быть доступна пользователям мобильного
        приложения Пульс с целью привлечения для участия в спортивных мероприятиях.
      </Text>
      <Text>
        Согласие дается на срок использования личного кабинета и может быть в любой момент мной отозвано путем
        направления обращения по адресу pulsesupport@myservices.digital или privilegesupport@myservices.digital».
      </Text>
    </AboutTextSimpleContainer>
  );
};

export default AboutPersonalDataAgreementContainer;
