import { FC } from 'react';
import { CloseIcon } from 'presentation/media/icons';
import Option from './option';

const Icon = <CloseIcon />;

type CancelOptionProps = {
  onClick: VoidFunction;
};

const CancelOption: FC<CancelOptionProps> = ({ onClick }) => {
  return (
    <>
      <Option
        icon={Icon}
        text='Отменить заказ'
        onClick={onClick}
      />
    </>
  );
};

export default CancelOption;
