import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `
);
