import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper } from '../controls';
import { pushLazyLoadedItem } from '../store/slice';

type LazyContentItemProps = {
  readonly guid: UUID;
  readonly containerId: UUID;
  readonly isLoaded?: boolean;
  readonly elementSize?: 20 | 25 | 33 | 50 | 100;
  readonly children: (onDataLoaded: () => void) => void;
};

const options = {
  root: null,
  rootMargin: '20%',
  threshold: 0,
};

export const LazyContentItem: React.FC<LazyContentItemProps> = props => {
  const { elementSize = 100, isLoaded, guid, containerId, children } = props;
  const [isVisible, setIsVisible] = useState(!!isLoaded);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const onIntersect: IntersectionObserverCallback = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !isVisible) {
        setIsVisible(true);
      }
    },
    [isVisible]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, options);
    const element = containerRef.current;

    if (element) {
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [onIntersect]);

  const onDataLoaded = () => {
    if (!isDataLoaded) dispatch(pushLazyLoadedItem({ guid, containerId }));
    setIsDataLoaded(true);
  };

  return (
    <Wrapper
      ref={containerRef}
      elementSize={elementSize}
      isContentLoaded={isDataLoaded}
    >
      <>{isVisible && children(onDataLoaded)}</>
    </Wrapper>
  );
};
