import { MPDialog } from 'presentation/theme/ui-kit/dialog';
import { ReactNode } from 'react';
import { TradeOfferClaimWidgetAdapter } from '../widget';
import { WidgetContent } from './controls';

type TradeOfferClaimWidgetDialogAdapterProps = {
  readonly open: boolean;
  readonly title: ReactNode;
  readonly wrapperRef: any;
  readonly onClose: () => void;
  readonly srcDoc: string;
};

const TradeOfferClaimWidgetDialogAdapter = (props: TradeOfferClaimWidgetDialogAdapterProps) => {
  const { open, title, onClose, wrapperRef, srcDoc } = props;

  return (
    <MPDialog
      open={open}
      title={title}
      closeButtonVisible
      onClose={onClose}
      maxWidth='xs'
      fullScreen={false}
      fullScreenBreakpoint='sm'
    >
      <WidgetContent>
        <TradeOfferClaimWidgetAdapter
          srcDoc={srcDoc}
          key='widget'
          wrapperRef={wrapperRef}
        />
      </WidgetContent>
    </MPDialog>
  );
};

export default TradeOfferClaimWidgetDialogAdapter;
