import React from 'react';
import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import Splitter from 'presentation/components/common/splitter';
import { ESlotNameArea, SlotsGridArea } from '../claim/controls';
import { SidebarProps } from '../../details/types';
import BookingSidebarSlot from './slot';

export type BookingSidebarSlotsProps = SidebarProps & {
  readonly totalPrice: Nullable<string>;
  readonly onSelectItem: (index: number[]) => void;
  readonly onDeleteItem: (index: number[]) => void;
};

const BlankButton = (
  <MPButton
    key='disabled'
    fullWidth
    disabled
  >
    Выберите минимум одну услугу
  </MPButton>
);

const useBookingSidebarSlots = ({
  isLoading,
  cart,
  totalPrice,
  customerComment,
  onSelectItem,
  onDeleteItem,
  onOrder,
  onChangeCustomerComment,
}: BookingSidebarSlotsProps): Nullable<React.JSX.Element>[] => {
  let slots: Nullable<React.JSX.Element>[] =
    cart?.map((item, cartIndex) => (
      <BookingSidebarSlot
        key={item.orderItem.priceItem.id}
        divider={cartIndex !== cart.length - 1}
        slotIndex={cartIndex}
        onChange={onSelectItem}
        onDelete={onDeleteItem}
        {...item}
      />
    )) ?? [];

  if (!cart || cart.length === 0) {
    slots = [BlankButton];
  }

  if (cart?.length) {
    slots = slots.concat(
      <SlotsGridArea
        area={ESlotNameArea.bottomBar}
        key={ESlotNameArea.bottomBar}
      >
        <Splitter size={1} />
        <MPFormInput
          multiline
          fullWidth
          rows={3}
          inputProps={{ maxLength: 1000 }}
          label='Напишите ваши пожелания'
          size='small'
          value={customerComment}
          onChange={event => onChangeCustomerComment(event.target.value)}
        />
        <Splitter size={3} />
        <MPGrid
          container
          spacing={1}
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
        >
          <MPGrid item>
            <Typography variant='subtitle2'>Предварительная сумма:</Typography>
          </MPGrid>
          <MPGrid item>
            <Typography
              variant='h2'
              noWrap
            >
              {totalPrice}
            </Typography>
          </MPGrid>
        </MPGrid>
        <Splitter size={3} />
        <MPButton
          fullWidth
          disabled={isLoading}
          color='brand'
          size='large'
          onClick={onOrder}
        >
          Оформить заказ
        </MPButton>
        <Splitter size={1} />
        <Typography variant='body2'>
          Указанная стоимость заказа является предварительной. После оформления заказа вам&nbsp;перезвонит менеджер для
          уточнения деталей заказа и&nbsp;стоимости
        </Typography>
      </SlotsGridArea>
    );
  }

  return slots;
};

export default useBookingSidebarSlots;
