import UserProfileContainer from 'presentation/features/user/details/profile/container';
import ScreenLayout from 'presentation/layouts/screen';

const UserProfileScreen = () => {
  return (
    <ScreenLayout footer>
      <UserProfileContainer />
    </ScreenLayout>
  );
};

export default UserProfileScreen;
