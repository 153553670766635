import { useMemo } from 'react';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import usePartnerDeskList from '../provider/usePartnerDeskList';

const PartnerDeskListShowMoreAdapter = () => {
  const { isFetching, isShowMoreDisplayed, onLoadMore } = usePartnerDeskList();

  return useMemo(
    () =>
      isShowMoreDisplayed ? (
        <MPButton
          fullWidth
          color='white'
          disabled={isFetching}
          onClick={onLoadMore}
        >
          Показать ещё
          {isFetching && <ContentLoader size={20} />}
        </MPButton>
      ) : null,
    [isFetching, isShowMoreDisplayed, onLoadMore]
  );
};

export default PartnerDeskListShowMoreAdapter;
