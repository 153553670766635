import { useCallback, useEffect, useState } from 'react';
import { ValidationItemResult, ValidationResult, ValidationRules } from '../../utils/validation';
import validateObject from './utils';

type UseValidationProps<T> = {
  readonly object: Nullable<T>;
  readonly rules: ValidationRules<T>;
  readonly validateOnChange?: boolean;
};

type UseValidation<T> = {
  readonly isValid: boolean;
  readonly validationResult: Nullable<ValidationResult<T>>;
  readonly validate: () => boolean;
  readonly resetValidationResult: (attribute?: keyof T) => void;
  readonly applyExternalValidationResult: (attribute: keyof T, externalResult: ValidationItemResult) => void;
};

function useValidation<T>(props: UseValidationProps<T>): UseValidation<T> {
  const { object, rules } = props;

  const [result, setResult] = useState<Nullable<ValidationResult<T>>>(null);

  const resetValidationResult = (attribute?: keyof T) => {
    if (!attribute) {
      setResult(null);
      return;
    }

    if (result === null || !result[attribute]) {
      return;
    }

    const temp = { ...result };
    delete temp[attribute];

    setResult(temp);
  };

  const validate = useCallback((): boolean => {
    if (object === null) {
      setResult(null);
      return true;
    }

    const validateObjectResult = validateObject<T>(object, rules);

    setResult(validateObjectResult.results);
    return validateObjectResult.isValid;
  }, [object, rules]);

  const applyExternalValidationResult = (attribute: keyof T, externalResult: ValidationItemResult) => {
    setResult({
      ...result,
      [attribute]: externalResult,
    });
  };

  const isValid =
    result === null ||
    Object.entries(result).filter(([, value]) => (value as ValidationItemResult).hasError).length === 0;

  useEffect(() => {
    if (!isValid) validate();
  }, [object, validate, isValid]);

  return {
    validationResult: result,
    isValid,
    validate,
    resetValidationResult,
    applyExternalValidationResult,
  };
}

export default useValidation;
