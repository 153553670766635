import { useGetAspOfferDetailsQuery } from 'data/api/aspOffer';
import ErrorHandler from 'data/network/errorHandler';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useContext, useEffect } from 'react';

const useAspOfferDetailsData = (id: UUID) => {
  const { setError } = useContext(RenderManagerErrorContext);

  const {
    data: aspOffer,
    error: aspOfferFetchError,
    isError: isAspOfferError,
    isLoading: isAspOfferLoading,
    isFetching: isAspOfferFetching,
    isSuccess: isAspOfferFetched,
  } = useGetAspOfferDetailsQuery({ id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (aspOfferFetchError) {
      ErrorHandler.handleHttpError(aspOfferFetchError);
      setError(aspOfferFetchError);
    }
  }, [setError, aspOfferFetchError]);

  return {
    aspOffer,
    aspOfferFetchError,
    isAspOfferError,
    isAspOfferFetching,
    isAspOfferLoading,
    isAspOfferFetched,
  };
};

export default useAspOfferDetailsData;
