import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserFavoritesOffersIsFetchingSelector } from '../store/selectors';
import UserFavoritesStub from '../stub';

type UserFavoritesLoaderAdapterProps = {
  readonly countGuid: UUID;
  readonly tabsCount: number;
};

const UserFavoritesStubAdapter = ({ countGuid, tabsCount }: UserFavoritesLoaderAdapterProps) => {
  const isFetching = useSelector(getUserFavoritesOffersIsFetchingSelector(countGuid));

  return useMemo(() => (!isFetching && !tabsCount ? <UserFavoritesStub /> : null), [isFetching, tabsCount]);
};

export default UserFavoritesStubAdapter;
