import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';

type BookingOfferDetailStubProps = {
  readonly text: string;
};

const BookingOfferDetailStub = ({ text }: BookingOfferDetailStubProps) => {
  return <OfferDetailsErrorLayout text={text} />;
};

export default BookingOfferDetailStub;
