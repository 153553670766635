import ContentLoader from 'presentation/components/common/loader';
import useAspOfferDetailsFetchingStatus from '../hooks/useAspOfferDetailsFetchingStatus';

type TradeDetailsLoaderAdapterProps = {
  readonly id: UUID;
};

export const AspOfferDetailsLoaderAdapter = ({ id }: TradeDetailsLoaderAdapterProps) => {
  const isFetching = useAspOfferDetailsFetchingStatus({ id });

  return isFetching ? <ContentLoader position='fixed' /> : null;
};
