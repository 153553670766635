import useBookingOfferCategoriesData from './useBookingOfferCategoriesData';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';
import { BookingDetailsId } from '../types';

const useBookingOfferDetailsFetchingStatus = ({ id }: BookingDetailsId): boolean => {
  const { isCategoriesFetching } = useBookingOfferCategoriesData();
  const { isBookingOfferFetching } = useBookingOfferDetailsData(id);

  return isCategoriesFetching || isBookingOfferFetching;
};

export default useBookingOfferDetailsFetchingStatus;
