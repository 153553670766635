import { EOrderStatus } from 'domain/model/enums';

export enum EUserOrdersTab {
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export const getUserOrdersStatus = (tab: EUserOrdersTab): EOrderStatus[] => {
  switch (tab) {
    case EUserOrdersTab.Active:
      return [EOrderStatus.New, EOrderStatus.Active, EOrderStatus.Confirmed, EOrderStatus.Paid, EOrderStatus.Sent];
    case EUserOrdersTab.Completed:
      return [EOrderStatus.Given, EOrderStatus.PartiallyReturned, EOrderStatus.Returned];
    case EUserOrdersTab.Cancelled:
      return [EOrderStatus.Cancelled];
  }
};
