import { ReactNode } from 'react';
import { AspOfferPromotionType, ETradeOfferPromotionType } from 'domain/model/enums';

type DialogContent = {
  readonly title: ReactNode;
  readonly text: ReactNode;
};

export const dialogContent: { [k in AspOfferPromotionType]: DialogContent } = {
  [ETradeOfferPromotionType.Asp]: {
    title: 'Как использовать сертификат',
    text: '',
  },
  [ETradeOfferPromotionType.Digift]: {
    title: 'Как использовать сертификат',
    text: '',
  },
};
