import { defaultNetworkErrorStubText } from 'presentation/utils/constants';
import { FCC, ReactNode } from 'react';
import useRenderManagerError from './hooks/useRenderManagerError';

type StubProps = {
  readonly text: string;
};

export type CommonRenderManagerProps = {
  readonly stub: FCC<StubProps>;
  readonly loader: ReactNode;
};

const CommonRenderManager: FCC<CommonRenderManagerProps> = props => {
  const { error } = useRenderManagerError();
  const { loader, stub: StubComponent, children } = props;

  if (error) {
    return <StubComponent text={defaultNetworkErrorStubText} />;
  }

  return (
    <>
      {children}
      {loader}
    </>
  );
};

export default CommonRenderManager;
