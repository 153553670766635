import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Primitive } from '@sentry/types';
import { ScopeContext } from '@sentry/types/types/scope';
import { CapturedUserAttributes } from 'domain/model/user';
import { SentryType } from 'system/appConfigurator';

export default class SentryClient {
  private static instance: SentryClient;

  public static getInstance(): SentryClient {
    if (!SentryClient.instance) {
      SentryClient.instance = new SentryClient();
    }

    return SentryClient.instance;
  }

  private constructor() {}

  options: Nullable<SentryType> = null;

  public init(options: Nullable<SentryType>) {
    this.options = options;

    if (!options) {
      console.info(`Sentry client not started, options is empty`);
      return;
    }

    if (options?.enabled && options?.project) {
      const release = options?.project + (options?.release ? `@${options?.release}` : '');

      Sentry.init({
        ...options,
        release,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
      });

      if (options?.tags) {
        Sentry.setTags(
          options.tags as {
            [key: string]: Primitive;
          }
        );
      }

      console.info(`Sentry client init with release '${release}'`);
    } else {
      console.info(`Sentry client is not active. Check the env variables: sentryEnabled, sentryProject`);
    }
  }

  public isActive(): boolean {
    return this.options?.enabled ?? false;
  }

  public getCaptureUserAttributes(): Nullable<CapturedUserAttributes> {
    return this.options?.captureUserAttributes ?? null;
  }

  public captureException(exception: string | Error, context?: Partial<ScopeContext>) {
    if (this.options?.enabled) {
      if (typeof exception === 'string') {
        Sentry.captureException(new Error(exception), context);
      } else {
        Sentry.captureException(exception, context);
      }
    } else {
      console.warn('Sentry client is not active. Check env params');
    }
  }
}
