import { CmsOfferCategoriesLayoutType } from './types';
import { HeaderWrapper } from './controls';

const CmsOfferCategoriesLayout: CmsOfferCategoriesLayoutType = props => {
  const { footer, header, children, loader } = props;

  return (
    <>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}
      {children}
      {footer}
      {loader}
    </>
  );
};

export default CmsOfferCategoriesLayout;
