import { useEffect, useState } from 'react';

type UseElementRect = (props: UseElementRectProps) => {
  readonly elementRect: Nullable<DOMRect>;
};

type UseElementRectProps = {
  readonly active?: boolean;
  readonly element: Nullable<HTMLElement | string>;
};

export const useElementRect: UseElementRect = ({ active = true, element }) => {
  const [elementRect, setElementRect] = useState<Nullable<DOMRect>>(null);
  const [resizeWatcher, setResizeWatcher] = useState<symbol>(Symbol());

  const elementNode = typeof element === 'string' ? document.querySelector(element) : element;

  useEffect(() => {
    if (!elementNode || !active) {
      return;
    }

    const rect = elementNode.getBoundingClientRect();

    setElementRect(rect);
  }, [elementNode, active, resizeWatcher]);

  useEffect(() => {
    const listener = () => setResizeWatcher(Symbol());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return {
    elementRect,
  };
};
