export type Fetchable = {
  readonly isFetching: boolean;
  readonly isFetched: boolean;
  readonly isFailed: boolean;
  readonly isCancelled?: boolean;
};

export const fetchableDefault: Fetchable = {
  isFetching: false,
  isFetched: false,
  isFailed: false,
  isCancelled: false,
};

export const fetchableFetching: Fetchable = {
  ...fetchableDefault,
  isFetching: true,
};

export const fetchableFetched: Fetchable = {
  ...fetchableDefault,
  isFetched: true,
};

export const fetchableFailed: Fetchable = {
  ...fetchableDefault,
  isFailed: true,
};
