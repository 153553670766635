import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { ESearchUrlParam } from 'presentation/types';
import { useLocation } from 'react-router';
import { SearchResultsLocationState } from '../../features/search/entry';
import SearchResultsContainer from '../../features/search/offers/container';
import { EOfferType } from '../../../domain/model/enums';

const BookingOffersSearchResultsScreen = () => {
  const location = useLocation<Nullable<SearchResultsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get(ESearchUrlParam.Query);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <SearchResultsContainer
            guid={guid}
            query={query}
            offerType={[EOfferType.Booking]}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default BookingOffersSearchResultsScreen;
