import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 280px;

    margin-right: ${theme.spacing(3)};

    ${theme.breakpoints.down('lg')} {
      width: 200px;
    }

    ${theme.breakpoints.down('md')} {
      width: 100%;

      margin-right: 0;
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
