import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { UserFavoriteProcess } from 'domain/model';

export type FavoritesProvideStateByGuid = {
  readonly processes: UserFavoriteProcess[];
};

export type FavoritesProvideState = Record<UUID, FavoritesProvideStateByGuid>;

type Reducer<T> = CaseReducer<FavoritesProvideState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<FavoritesProvideState> & {
  favoritesProvideClear: Reducer<{ guid: UUID }>;
  favoritesProvideChangeProcess: Reducer<{ guid: UUID; process: UserFavoriteProcess }>;
};

const slice = createSlice<FavoritesProvideState, Reducers, 'provide'>({
  name: 'provide',
  initialState: {},
  reducers: {
    favoritesProvideClear: (state, { payload }) => {
      const { guid } = payload;
      delete state[guid];
    },
    favoritesProvideChangeProcess: (state, { payload }) => {
      const { guid, process } = payload;

      if (!state[guid]) {
        state[guid] = { processes: [] };
      }

      const sessionState = state[guid];

      if (sessionState) {
        const currentProcess = sessionState.processes.find(p => p.id === process.id);
        if (currentProcess) {
          currentProcess.action = process.action;
          currentProcess.id = process.id;
          currentProcess.isFetching = process.isFetching;
          currentProcess.isFetched = process.isFetched;
          currentProcess.isFailed = process.isFailed;
          currentProcess.isCancelled = process.isCancelled;
          currentProcess.offerType = process.offerType;
        } else {
          sessionState.processes.push(process);
        }
      }
    },
  },
});

export const { favoritesProvideClear, favoritesProvideChangeProcess } = slice.actions;

export default slice.reducer;
