import { Location } from 'history';
import { FileLink } from '../../../../../../../domain/model';
import { StyledAppImage, StyledLink } from './controls';

type OrderImageProps = {
  readonly fileLink: FileLink;
  readonly to: string | Location;
};

const OrderImage = ({ fileLink, to }: OrderImageProps) => {
  return (
    <StyledLink to={to}>
      <StyledAppImage src={fileLink.path} />
    </StyledLink>
  );
};

export default OrderImage;
