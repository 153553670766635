import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PageableListPayload, PageableListState } from '../../../types';

type KeyType = UUID;

export type PageableArgsItemState = PageableListPayload & PageableListState;

export type PageableArgsState = Record<KeyType, PageableArgsItemState>;

type Reducer<T = undefined> = CaseReducer<PageableArgsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<PageableArgsState> & {
  readonly pageableArgsPut: Reducer<{ key: KeyType; data: PageableArgsItemState }>;
  readonly pageableArgsPutPartial: Reducer<{ key: KeyType; data: Partial<PageableArgsItemState> }>;
};

const slice = createSlice<PageableArgsState, Reducers, 'pageable/args'>({
  name: 'pageable/args',
  initialState: {},
  reducers: {
    pageableArgsPut: (state, { payload }) => {
      const { key, data } = payload;
      const currentData = state[key];
      if (!deepEqual(currentData, data)) {
        state[key] = data;
      }
    },
    pageableArgsPutPartial: (state, { payload }) => {
      const { key, data } = payload;
      state[key] = {
        ...state[key],
        ...data,
      };
    },
  },
});

export const { pageableArgsPut, pageableArgsPutPartial } = slice.actions;

export default slice.reducer;

const deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};
