import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  CheckboxProps,
  InputAdornment,
  InputBaseComponentProps,
  Radio,
  RadioProps,
  Switch,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  CheckboxCheckMarkIcon,
  CheckboxIcon,
  CheckboxIndeterminateMarkIcon,
  RadioCheckMarkIcon,
  RadioMarkIcon,
} from '../../../media/icons';
import { MPNaturalNumberFormat } from '../format';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Counter, TextFieldLimited } from './controls';
import { useIMask } from 'react-imask';
import { phoneMask } from '../../../utils/phone';
import { MaskedPattern } from 'imask';

export type MPFormInputProps = TextFieldProps;

export const MPFormInput = TextField;
export const MPSwitch = Switch;
export const MPRadio = (props: RadioProps) => {
  return (
    <Radio
      icon={<RadioMarkIcon />}
      checkedIcon={<RadioCheckMarkIcon />}
      {...props}
    />
  );
};

type MPCheckboxProps = CheckboxProps;

export const MPCheckbox = (props: MPCheckboxProps) => {
  return (
    <Checkbox
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckMarkIcon />}
      indeterminateIcon={<CheckboxIndeterminateMarkIcon />}
      {...props}
    />
  );
};

export const MPSearchInput = (props: TextFieldProps) => {
  const { placeholder, InputProps, ...others } = props;

  return (
    <MPFormInput
      type='search'
      placeholder={placeholder || 'Поиск'}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='secondary' />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...others}
    />
  );
};

export const MPNaturalNumberInput = (props: MPFormInputProps) => {
  const { InputProps, ...others } = props;

  return (
    <MPFormInput
      InputProps={{
        inputComponent: MPNaturalNumberFormat,
        ...InputProps,
      }}
      {...others}
    />
  );
};

export const MPMaskedInput = React.forwardRef<any, InputBaseComponentProps & MaskedInputProps>(
  (props, forwardedRef: any) => {
    return (
      <MaskedInput
        showMask
        {...props}
        ref={(ref: any) => {
          forwardedRef?.(ref ? ref.inputElement : null);
        }}
      />
    );
  }
);

export const MPFormTextArea = (props: MPFormInputProps) => {
  const { inputProps, value, onChange, ...other } = props;
  const maxLength = inputProps?.maxLength;

  const [symbolsCount, setSymbolsCount] = useState<number>(((value as string) ?? '').length);

  const onChangeInternal = (event: any) => {
    setSymbolsCount(event.target.value.length ?? 0);
    onChange?.(event);
  };

  if (maxLength) {
    return (
      <TextFieldLimited>
        <MPFormInput
          {...other}
          value={value}
          onChange={onChangeInternal}
          inputProps={inputProps}
        />
        <Counter
          variant={'body2'}
          color={'secondary'}
        >
          {symbolsCount} из {maxLength}
        </Counter>
      </TextFieldLimited>
    );
  }

  return (
    <MPFormInput
      {...other}
      value={value}
      onChange={onChangeInternal}
      inputProps={inputProps}
    />
  );
};

export type MPMaskInputProps<Value extends string = string> = Omit<MPFormInputProps, 'onChange' | 'value'> & {
  readonly onChange?: (value: Value) => void;
  readonly value?: Nullable<Value>;
  readonly maskOptions: MaskedPattern<Value>;
};

const MPBaseMaskInput = (props: MPMaskInputProps) => {
  const { maskOptions, InputLabelProps, value: controlledValue = '', onFocus, onBlur, onChange, ...other } = props;
  const [isFocused, setIsFocused] = useState(false);

  const { ref, value, setValue } = useIMask(maskOptions, {
    onAccept: (value, masked) => {
      onChange?.(masked.unmaskedValue);
    },
  });

  const shrink = isFocused || !!value;

  // Синхронизация значений
  useEffect(() => {
    setValue(controlledValue || '');
  }, [setValue, controlledValue]);

  return (
    <MPFormInput
      {...other}
      inputRef={ref}
      value={value}
      onFocus={event => {
        onFocus?.(event);
        setIsFocused(true);
      }}
      onBlur={event => {
        onBlur?.(event);
        setIsFocused(false);
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink,
      }}
    />
  );
};

export const MPPhoneInput = (props: Omit<MPMaskInputProps, 'maskOptions'>) => {
  return (
    <MPBaseMaskInput
      maskOptions={phoneMask}
      {...props}
    />
  );
};
