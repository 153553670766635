import { EOfferStatus } from '../../../../../../../domain/model/enums';
import useCorpOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import OfferClaimHelp from '../../../../components/claim/help';

const NdflAdapter = () => {
  const { corpOffer } = useCorpOfferClaimContext();
  const { openNdflHelpDialog } = useCorpOfferClaimDialogs();

  const isVisible = corpOffer?.status === EOfferStatus.Active;

  if (!corpOffer?.hasNdfl) {
    return null;
  }

  return isVisible ? (
    <OfferClaimHelp
      text={'Возможно, оплата НДФЛ'}
      onClick={openNdflHelpDialog}
    />
  ) : null;
};

export default NdflAdapter;
