export enum EParentGridAreas {
  Bar = 'offer-bar',
  Inner = 'offer-inner',
}

export enum EInnerGridAreas {
  SmartphoneFixedBar = 'offer-smartphone-fixed-bar',
  Breadcrumbs = 'offer-breadcrumbs',
  Title = 'offer-title',
  Image = 'offer-image',
  Description = 'offer-description',
  Favorite = 'offer-favorite',
  Sidebar = 'offer-side-bar',
  Tabs = 'offer-tabs',
}
