import { Fade } from '@mui/material';
import { Wrapper } from './controls';
import { PartnerDeskOffersTabLayoutType } from './types';

const PartnerDeskOffersTabLayout: PartnerDeskOffersTabLayoutType = props => {
  const { toolbar, stub, loader, children } = props;

  return (
    <Fade in>
      <Wrapper>
        {toolbar}
        {children}
        {loader}
        {stub}
      </Wrapper>
    </Fade>
  );
};

export default PartnerDeskOffersTabLayout;
