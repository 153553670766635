import { useEffect } from 'react';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { useAuthUser } from '../../../auth/provider/useAuthUser';
import { useUpdateCurrentUserMutation } from '../../../../../data/api/user';

type UseUserProfileEmailEdit = {
  readonly phone: string;
  readonly isFetching: boolean;
  readonly onUpdate: (phone: string) => void;
};

type UseUserProfileEmailEditProps = {
  readonly onSuccess: (phone: string) => void;
  readonly onClose: () => void;
};

export const useUpdateUserPhone = (props: UseUserProfileEmailEditProps): UseUserProfileEmailEdit => {
  const { onSuccess, onClose } = props;

  const { user } = useAuthUser();

  const [updateCurrentUser, { isLoading: isFetching, reset }] = useUpdateCurrentUserMutation();

  const onUpdate = async (phone: string) => {
    if (user && phone) {
      try {
        if (phone !== user.phone) {
          await updateCurrentUser({ id: user.id, data: { ...user, phone } }).unwrap();
        }
        onSuccess(phone);
        onClose();
      } catch (error) {
        ErrorHandler.handleHttpError(error);
      }
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  return {
    phone: user?.phone ?? '',
    isFetching,
    onUpdate,
  };
};
