import { MPButton } from 'presentation/theme/ui-kit/button';
import React from 'react';
import { MPConfirmDialog } from '../../../../../../theme/ui-kit/dialog';

type ProductOfferDeliveryInfoDialogProps = {
  readonly open: boolean;
  readonly info: React.ReactNode;
  readonly onClose: () => void;
};

const ProductOfferDeliveryInfoDialog = ({ open, info, onClose }: ProductOfferDeliveryInfoDialogProps) => {
  return (
    <MPConfirmDialog
      open={open}
      maxWidth='xs'
      title='Получение'
      onClose={onClose}
      buttons={
        <MPButton
          size='large'
          fullWidth
          color='brand'
          variant='contained'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
    >
      {info}
    </MPConfirmDialog>
  );
};

export default ProductOfferDeliveryInfoDialog;
