import styled from '@emotion/styled/macro';
import { css, IconButton, IconButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Swiper } from 'swiper/react';

const navigationButtonWidth = '3rem';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: calc(100% - ${navigationButtonWidth} * 3);
    height: 100%;
    max-height: inherit;

    margin: auto;

    position: relative;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
);

export const StyledSwiper = styled(Swiper)(
  ({ theme }) => css`
    height: 100%;

    .swiper-slide {
      height: auto;

      text-align: center;

      img {
        width: 87%;
        height: 100%;

        object-fit: contain;
        object-position: center center;
      }
    }

    .swiper-slide-prev img,
    .swiper-slide-next img,
    .swiper-slide-duplicate-prev img,
    .swiper-slide-duplicate-next img {
      background-color: ${theme.palette.background.default};
    }
  `
);

export const Button = styled(
  forwardRef<any, IconButtonProps>((props, ref) => (
    <IconButton
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    width: ${navigationButtonWidth};
    height: ${navigationButtonWidth};
    color: ${theme.palette.secondary.main};
    border: 1px solid ${theme.palette.secondary.A600};

    position: absolute;
    z-index: 1;

    top: 50%;
    transform: translateY(-50%);

    & .MuiSvgIcon-root {
      font-size: 2rem;
      transition: color ${theme.transitions.duration?.standard}ms;
    }
  `
);

export const ButtonPrev = styled(
  forwardRef<any, any>((props, ref) => (
    <Button
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    left: calc(-${navigationButtonWidth} - ${theme.spacing()});
  `
);

export const ButtonNext = styled(
  forwardRef<any, any>((props, ref) => (
    <Button
      ref={ref}
      {...props}
    />
  ))
)(
  ({ theme }) => css`
    right: calc(-${navigationButtonWidth} - ${theme.spacing()});
  `
);
