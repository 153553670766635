import { ETradeOfferSortType, tradeOfferSortType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../../offer/components/sortSelect';
import { useTradeOffersContextHandlers } from '../../hooks/useTradeOffersContextHandlers';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';
import { partnerDeskDetailsTradeOffersSortSelector } from '../../store/selectors';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';

const items = Object.values(tradeOfferSortType);

const PartnerDeskDetailsTabTradeOffersToolbar = () => {
  const sort = useSelector(partnerDeskDetailsTradeOffersSortSelector);

  const handlers = useTradeOffersContextHandlers();

  return useMemo(
    () => (
      <PartnerDeskDetailsTabToolbarLayout
        title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.TradeOffers)}
        sort={
          <OffersSortSelect<ETradeOfferSortType>
            value={sort}
            items={items}
            defaultValue={ETradeOfferSortType.Default}
            onChange={handlers.onChangeTradeOffersSort}
          />
        }
      />
    ),
    [sort, handlers.onChangeTradeOffersSort]
  );
};

export default PartnerDeskDetailsTabTradeOffersToolbar;
