import { useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import TradeOfferClaimWidgetDialogAdapter from '../../../../offer/trade/claim/adapters/widgetModal';
import { OfferActivation } from '../../../../../../domain/model/activation';

type ActivationWidgetButtonProps = {
  readonly wrapperRef: any;
  readonly activation: OfferActivation;
  readonly srcDoc: string;
};

const ActivationWidgetButton = ({ wrapperRef, activation, srcDoc }: ActivationWidgetButtonProps) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  return (
    <>
      <MPButton
        color='brand'
        fullWidth
        size='medium'
        onClick={onOpen}
      >
        Открыть
      </MPButton>
      <TradeOfferClaimWidgetDialogAdapter
        srcDoc={srcDoc}
        title={activation.offer.title}
        wrapperRef={wrapperRef}
        onClose={onClose}
        open={open}
      />
    </>
  );
};

export default ActivationWidgetButton;
