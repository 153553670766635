import styled from '@emotion/styled/macro';
import { css, List as MuiList, svgIconClasses } from '@mui/material';

export const FirstLevelWrapper = styled.div(
  () => css`
    width: 100%;
    flex: 1;
    overflow: hidden;
  `
);

export const RootWrapper = styled.div<{ hasBorder: boolean }>(
  ({ theme, hasBorder }) => css`
    width: 306px;

    flex: none !important;

    ${hasBorder && `border-right: 1px solid ${theme.palette.secondary.A900}`};

    ${theme.breakpoints.down('sm')} {
      width: 100%;

      display: flex;

      border: none;

      > :first-of-type {
        width: 100%;
      }
    }
  `
);

export const List = styled(MuiList)(
  ({ theme }) => css`
    min-height: 60vh;
    max-height: 70vh;

    padding: ${theme.spacing(0, 1.5, 0, 0)};

    .${svgIconClasses.root} {
      color: ${theme.palette.text.primary};
    }

    ${theme.breakpoints.down('sm')} {
      padding-left: 0;
      padding-right: 0;
    }
  `
);
