import styled from '@emotion/styled/macro';
import React from 'react';
import { ContentLoaderWrapperProps } from './index';

export const Wrapper = styled(
  ({
    alpha,
    position,
    size,
    justifyContent,
    ...others
  }: ContentLoaderWrapperProps & { children?: React.ReactNode }) => <div {...others} />
)`
  ${p => (!p.alpha ? '' : 'background-color: #dddddd88;')};

  position: ${p => p.position};

  z-index: 2;

  ${p =>
    p.position === 'fixed' && !p.alpha
      ? `
    width: ${typeof p.size === 'number' ? `${p.size}px` : p.size} !important;
    height: ${typeof p.size === 'number' ? `${p.size}px` : p.size} !important;
    
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  `
      : `
    width: 100%;
    height: 100%;
    
    top: 0;
    left: 0;
    
    display: flex;
    align-items: center;
    justify-content: ${p.justifyContent};
  `}
`;

export const SimpleWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  & > * {
    font-size: 0.5rem;
    color: ${p => p.theme.palette.primary.A900};
  }
`;
