import { Text } from '../../components/controls';

const AboutPublicOfferCommonInfo = () => {
  return (
    <>
      <Text>Дата опубликования: «23» сентября 2022</Text>
      <Text>
        Настоящий документ представляет собой предложение Открытого акционерного общества «Российские железные дороги»,
        именуемого в дальнейшем РЖД, заключить с Пользователями Платформы на безвозмездной основе договор об оказании
        информационных услуг о предоставлении информации о предложениях Партнеров о товарах и услугах в объеме,
        определяемом такими Партнерами, с целью последующего заключения между Пользователями Платформы и Партнерами
        договоров купли-продажи товаров и (или) договоров оказания услуг на условиях, изложенных в настоящей публичной
        оферте (далее «Оферта»).
      </Text>
    </>
  );
};

export default AboutPublicOfferCommonInfo;
