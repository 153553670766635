import { useAddUserOfferToFavoritesMutation, useRemoveUserOfferFromFavoritesMutation } from 'data/api/user';
import { EFavoriteProcessAction, EOfferType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { favoriteProvideByProcessIdSelector } from '../../favorite/provide/store/selectors';
import { UseFavoriteCommonProps } from '../../favorite/provide/types';
import useFavoritesMutation from '../../favorite/provide/useFavoritesMutation';
import { userIsOfferFavoriteSelector } from '../../user/details/favorites/store/selectors';

type PayloadType = {
  readonly id: UUID;
  readonly offerType: EOfferType;
  readonly partnerId?: UUID;
};

type UseOfferFavoriteProps = UseFavoriteCommonProps<PayloadType>;

type UseOfferFavoriteResult = {
  readonly isFetching: boolean;
  readonly isFavorite: boolean;
  readonly onToggle: () => void;
};

/**
 * хук для работы с избранным одного оффера
 */
export const useOfferFavoriteSimple = (props: UseOfferFavoriteProps): UseOfferFavoriteResult => {
  const { payload } = props;
  const { id, offerType } = payload;

  const { webAnalytics } = useWebAnalytics();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const isFavorite = useSelector(userIsOfferFavoriteSelector(id, offerType));

  const [addMutation] = useAddUserOfferToFavoritesMutation();
  const [removeMutation] = useRemoveUserOfferFromFavoritesMutation();

  const onToggle = useCallback(() => {
    if (!isFavorite) {
      webAnalytics.offerAddToFavorite(id);
    }

    setIsFetching(true);
    const mutation = isFavorite ? removeMutation : addMutation;
    mutation({ id, offerType })
      .unwrap()
      .finally(() => setIsFetching(false));
  }, [addMutation, id, isFavorite, offerType, removeMutation, webAnalytics]);

  return {
    isFetching,
    isFavorite,
    onToggle,
  };
};

export const useOfferFavoriteStored = (props: UseOfferFavoriteProps): UseOfferFavoriteResult => {
  const { guid, payload } = props;
  const { id, offerType } = payload;

  const { webAnalytics } = useWebAnalytics();

  const process = useSelector(favoriteProvideByProcessIdSelector(guid, id));
  const isFavorite = useSelector(userIsOfferFavoriteSelector(id, offerType));

  const addMutation = useAddUserOfferToFavoritesMutation();
  const removeMutation = useRemoveUserOfferFromFavoritesMutation();

  const { add: addInternal, remove: removeInternal } = useFavoritesMutation({
    guid,
    payload,
    clearAfterUnmount: false,
    addMutation,
    removeMutation,
  });

  const onToggle = useCallback(() => {
    if (isFavorite) {
      removeInternal(id);
    } else {
      webAnalytics.offerAddToFavorite(id);
      addInternal(id);
    }
  }, [addInternal, id, isFavorite, removeInternal, webAnalytics]);

  const isRemoving = process?.action === EFavoriteProcessAction.Remove && process?.isFetching;

  const isAdding = process?.action === EFavoriteProcessAction.Add && process?.isFetching;

  return {
    isFetching: isAdding || isRemoving,
    isFavorite,
    onToggle,
  };
};
