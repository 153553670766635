import { CmsComponent } from 'domain/model/cms';
import { ECmsContainerType, EOfferType } from 'domain/model/enums';
import React, { FCC, ReactElement } from 'react';

export enum ECmsCollectionPlace {
  Self = 'self',
}

export type CmsContainerTypedCommonProps<L> = {
  readonly guid: UUID;
  readonly header?: Nullable<React.ReactNode>;
  //пока применяется для отступов между контейнерами, чтобы при отсутствии данных не рисовать его к примеру и не занимать место
  //по идее штука универсальная, можно хоть что сюда пихать
  readonly footer?: Nullable<FCC<CmsContainerSlotProps>>;
  readonly layout: L;
  readonly offerType?: Nullable<EOfferType>;
  readonly showIcon?: Nullable<boolean>;
  readonly pageSize?: Nullable<number>;
  readonly onDataLoaded?: () => void;
};

export type CmsContainerTypedProps<L, ExtraProps> = ExtraProps &
  CmsContainerTypedCommonProps<L> & {
    readonly containerType: ECmsContainerType;
    readonly components: Nullable<CmsComponent[]>;
  };

export type CmsContainerSlotProps = {
  readonly hasContent?: boolean;
};

export type CmsContainerTyped<L, ExtraProps = {}> = (
  props: CmsContainerTypedProps<L, ExtraProps>
) => ReactElement | null;
