import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PriceRangeFilter } from 'domain/model/offer';
import { productOffersFilterClear, productOffersFilterSet, productOffersFilterSetPrice } from '../store/slice';

export type UseProductOfferFilter = {
  readonly onChangePrice: (value: PriceRangeFilter) => void;
  readonly onSetFilter: (filter: PriceRangeFilter) => void;
  readonly onClearFilter: () => void;
};

export const useProductOfferFilter = (): UseProductOfferFilter => {
  const dispatch = useDispatch();

  const onChangePrice = useCallback<UseProductOfferFilter['onChangePrice']>(
    value => {
      dispatch(productOffersFilterSetPrice(value));
    },
    [dispatch]
  );

  const onSetFilter = useCallback(
    (filter: PriceRangeFilter) => {
      dispatch(productOffersFilterSet(filter));
    },
    [dispatch]
  );

  const onClearFilter = useCallback(() => {
    dispatch(productOffersFilterClear());
  }, [dispatch]);

  return {
    onChangePrice,
    onSetFilter,
    onClearFilter,
  };
};
