import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';

type CorpOfferDetailStub = {
  readonly text: string;
};

const CorpOfferDetailStub = ({ text }: CorpOfferDetailStub) => {
  return <OfferDetailsErrorLayout text={text} />;
};

export default CorpOfferDetailStub;
