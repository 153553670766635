import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import React from 'react';
import { PaginationSize } from '../../../types';
import CardList, { CardGridProps } from '../cardList';
import ContentLoader from '../loader';
import PageSizeSelect from '../selects/pageSize';
import Splitter from '../splitter';
import { LoaderWrapper, PageSizeSelectWrapper } from './controls';

type CardPageableListWithPageSizeProps = {
  readonly pageSize: PaginationSize;
  readonly onChangePageSize: (newPageSize: PaginationSize) => void;
};

type CardPageableListWithoutPageSizeProps = {
  readonly pageSize?: undefined;
  readonly onChangePageSize?: undefined;
};

export type CardPageableListProps = CardGridProps & {
  readonly totalCount: number;
  readonly isFetching?: boolean;
  readonly disableLoadMore?: boolean;
  readonly onLoadMore?: Nullable<() => void>;
} & (CardPageableListWithPageSizeProps | CardPageableListWithoutPageSizeProps);

export const CardPageableList: React.FCC<CardPageableListProps> = props => {
  const { children, totalCount, isFetching, disableLoadMore, onLoadMore, pageSize, onChangePageSize, ...listProps } =
    props;
  const { x3 } = useGridSpacing();

  const currentLength = React.Children.count(children);

  return (
    <div>
      <CardList {...listProps}>{children}</CardList>

      {totalCount > currentLength && onLoadMore && (
        <>
          <Splitter
            variant='horizontal'
            size={x3}
          />
          <MPButton
            fullWidth
            color='white'
            disabled={disableLoadMore || isFetching}
            onClick={onLoadMore}
          >
            Показать ещё
            {isFetching && <ContentLoader size={20} />}
          </MPButton>
        </>
      )}

      {props.onChangePageSize && (
        <PageSizeSelect
          totalCount={totalCount}
          value={props.pageSize}
          onChange={props.onChangePageSize}
        >
          {content => <PageSizeSelectWrapper>{content}</PageSizeSelectWrapper>}
        </PageSizeSelect>
      )}

      {!currentLength && isFetching && (
        <LoaderWrapper>
          <ContentLoader />
        </LoaderWrapper>
      )}
    </div>
  );
};
