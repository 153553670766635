import { Link } from '@mui/material';
import { useMemo } from 'react';
import { getPulseConfig, getSupportInfo } from '../../../../utils';
import { Text } from './controls';

const AboutTermsOfUseCollectAndStorageInfo = () => {
  const pulseSupportEmail = useMemo(() => {
    const email = getPulseConfig()?.supportEmail;
    return (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    );
  }, []);

  const privilegeSupportEmail = useMemo(() => {
    const email = getSupportInfo()?.email;
    return (
      <Link
        color='primary'
        underline='none'
        target='_blank'
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    );
  }, []);

  return (
    <>
      <Text>
        3.1. Правообладатель вправе устанавливать ограничения в использовании Платформы, для отдельных категорий
        Пользователей (в зависимости от места пребывания Пользователя, языка, на котором предоставляется сервис и т.д.),
        в том числе: наличие/отсутствие отдельных функций Платформы.
      </Text>
      <Text>
        3.2. Правообладатель вправе посылать своим пользователям информационные сообщения. Используя Платформу,
        Пользователь также дает свое согласие на получение сообщений рекламного характера. Пользователь вправе
        отказаться от получения сообщений рекламного характера путем использования соответствующего функционала
        Платформы, в рамках которого или в связи, с которым пользователем были получены сообщения рекламного характера.
        Пользователь выражает согласие Правообладателю на оповещение других Пользователей Платформы о публичных
        действиях Пользователя, в том числе о размещении им новых публикаций, о произведенных им действиях в отношении
        публичных действий других Пользователей, а также иной активности, совершенной им в рамках Платформы.
      </Text>
      <Text>
        3.3. В целях повышения качества Платформы Правообладатель и/или привлекаемые им для проведения опроса лица
        вправе осуществлять сбор мнений и отзывов Пользователей по различным вопросам путем направления информационного
        сообщения в том числе по контактным данным, указанным Пользователем в учетной записи (посредством телефонных
        звонков или электронных писем). Собранные мнения и отзывы могут быть использованы для формирования
        статистических данных, которые могут быть использованы в Платформе. Отзывы, предоставленные Пользователем при
        проведении опроса, также могут быть опубликованы Правообладателем в Платформе либо в сервисах аффилированных с
        Правообладателем лиц, как с указанием имени Пользователя, так и без указания. При оставлении отзывов
        Пользователь обязуется руководствоваться требованиями настоящего Соглашения, в том числе требованиями,
        установленными п. 4 настоящего Соглашения.
      </Text>
      <Text>
        3.4. При выявлении Пользователем фактов незаконного использования Платформы или размещения информации
        противоречащей положением настоящего Соглашения или законам Российской Федерации, Пользователь вправе обратиться
        к правообладателю с соответствующей жалобой при помощи специальной функции Платформы или путем направления
        жалобы по адресу электронной почты {pulseSupportEmail} для {getPulseConfig()?.caption} и {privilegeSupportEmail}{' '}
        для Витрины.
      </Text>
      <Text>
        3.4.1. В течение 30 рабочих дней Правообладатель рассматривает полученную жалобу на предмет полноты указанных в
        ней сведений и в случае достаточности предоставленных сведений принимает меры в соответствии с настоящим
        Положением и законами Российской Федерации. В случае недостаточности представленных Пользователем сведений
        Правообладатель запрашивает дополнительные сведения и документы, которые Пользователь должен предоставить в
        течение 30 рабочих дней с даты получения запроса на адрес электронной почты Правообладателя. В случае
        непредоставления Пользователем запрошенных дополнительных сведений или документов в пятидневный срок жалоба
        Пользователя считается отозванной.
      </Text>
    </>
  );
};

export default AboutTermsOfUseCollectAndStorageInfo;
