import { ReactNode } from 'react';
import { BackgroundWrapper, Close, Inner } from './controls';

type BackgroundContainerProps = {
  readonly close?: ReactNode;
  readonly children: ReactNode;
  readonly variant?: 'inner' | 'main';
};

const BackgroundContainer = ({ children, close, variant = 'inner' }: BackgroundContainerProps) => {
  return (
    <BackgroundWrapper variant={variant}>
      <Inner>{children}</Inner>
      {close && <Close>{close}</Close>}
    </BackgroundWrapper>
  );
};

export default BackgroundContainer;
