import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmButtons from 'presentation/theme/ui-kit/dialog/buttons';
import { phoneFormatView } from 'presentation/utils/phone';
import { FC } from 'react';

type IsPhoneExistsDialogProps = {
  readonly phone: string;
  readonly onSubmit: (phone: string) => void;
  readonly onChange: () => void;
  readonly onCancel: () => void;
};

export const IsPhoneExistsDialog: FC<IsPhoneExistsDialogProps> = ({ phone, onSubmit, onChange, onCancel }) => {
  return (
    <MPConfirmDialog
      title='Телефон'
      text={
        <Typography
          color='textPrimary'
          variant='body1'
        >
          Номер{' '}
          <Typography
            variant='subtitle1'
            component='span'
          >
            {phoneFormatView(phone)}
          </Typography>{' '}
          ваш? Менеджер перезвонит на него для уточнения деталей по заказу.
        </Typography>
      }
      open={true}
      disableEscapeKeyDown
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => onSubmit(phone)}
          >
            Да, это мой номер
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onChange}
          >
            Указать номер телефона
          </MPButton>
        </ConfirmButtons>
      }
      onClose={onCancel}
    />
  );
};
