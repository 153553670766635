import ContentLoader from 'presentation/components/common/loader';
import useCorpOfferDetailsFetchingStatus from '../hooks/useCorpOfferDetailsFetchingStatus';

type CorpDetailsLoaderAdapterProps = {
  readonly id: UUID;
};

const CorpOfferDetailsLoaderAdapter = ({ id }: CorpDetailsLoaderAdapterProps) => {
  const isFetching = useCorpOfferDetailsFetchingStatus({ id });

  return isFetching ? <ContentLoader position='fixed' /> : null;
};

export default CorpOfferDetailsLoaderAdapter;
