import { Typography } from '@mui/material';
import { JSX } from 'react';
import { ExpandChip, Label } from './controls';

type BreadcrumbExpandButtonContentProps = {
  readonly title: string;
  readonly count: number;
  readonly isExpand: boolean;
  readonly icon: JSX.Element;
  readonly onClick: () => void;
};

export const BreadcrumbExpandChipContent = ({
  title,
  isExpand,
  count,
  icon,
  onClick,
}: BreadcrumbExpandButtonContentProps) => {
  const label = (
    <Label>
      <Typography variant='body2'>{isExpand ? title : `+${count}`}</Typography>
      {icon}
    </Label>
  );

  return (
    <ExpandChip
      size='micro'
      label={label}
      onClick={onClick}
    />
  );
};
