import { RefetchConfigOptions, SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { UseQuery, UseQueryStateOptions } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig } from 'axios';
import { Pageable } from 'domain/model';

export type PageableListState<PS = number> = {
  readonly page: number;
  readonly pageSize: PS;
};

export type PageableProps = 'page' | 'pageSize';

export type ExcludePageable<T> = Omit<T, PageableProps>;

// TODO: запретить передачу page, pageSize (текущий тип позволяет их передать)
export type PageableListPayload = ExcludePageable<PageableListState>;

export type PageableArgsStorageProps<P extends PageableListPayload> = {
  //идентификатор состояния сессии
  readonly guid: UUID;
  //умолчальное состояние
  readonly defaultState: P;
};

export type PageableArgsStorageResult<P extends PageableListPayload, PS = number> = {
  //последнее сохраненное состояния
  readonly currentState: P & PageableListState<PS>;
  //умолчальное состояние
  readonly defaultState: P & PageableListState<PS>;
  //сохранение состояния, если оно не предусмотрено то должно быть undefined
  readonly save: (state: P & PageableListState<PS>) => void;
  //сохранение части состояния (перезапись избранных ключей), если оно не предусмотрено то должно быть undefined
  readonly savePartial: (state: Partial<P>) => void;
  //сброс состояния
  readonly reset: () => void;
};

export type PageableArgsStorage<P extends PageableListPayload, PS = number> = (
  props: PageableArgsStorageProps<P>
) => PageableArgsStorageResult<P, PS>;

export type PageableListQueryDefinitionType<P extends PageableListPayload, R> = QueryDefinition<
  P,
  BaseQueryFn<AxiosRequestConfig<R>>,
  any,
  R
>;

export type PageableListQueryType<P extends PageableListPayload, R> = UseQuery<
  PageableListQueryDefinitionType<P & PageableListState, Pageable<R>>
>;

export type PageableListQueryOptionsType = SubscriptionOptions &
  RefetchConfigOptions &
  Pick<UseQueryStateOptions<any, any>, 'skip'>;

export enum EPaginationBehaviour {
  IncrementPage = 'increment-page',
  IncrementPageSize = 'increment-page-size',
}

export type PageableListCacheProviderResult<R> = {
  readonly cache: Nullable<Pageable<R>>;
  readonly setCache: (data: Pageable<R>) => void;
  readonly clearCache?: () => void;
};

type ConditionalUsePageableListCommonProps<R> =
  | {
      readonly paginationBehaviour: EPaginationBehaviour.IncrementPageSize;
      readonly cacheProvider?: undefined;
    }
  | {
      readonly paginationBehaviour: EPaginationBehaviour.IncrementPage;
      readonly cacheProvider: PageableListCacheProviderResult<R>;
    };

export type UsePageableListCommonProps<P extends PageableListPayload, R, PS = number> = {
  //идентификатор состояния сессии использования хука, в случае его изменения происходит обнуление состояния
  readonly guid: UUID;
  //провайдер хранения состояния текущих параметров пагинации и доп нагрузки, разграничивается с помощью guid
  readonly argsStorage: PageableArgsStorageResult<P, PS>;
  //скипнуть внутреннюю обработку ощибок, пропустить ошибки наружу
  readonly passErrors?: boolean;
} & ConditionalUsePageableListCommonProps<R>;

export type UsePageableListQueryProps<P extends PageableListPayload, R> = {
  //ссылка на хук query
  readonly useQuery: PageableListQueryType<P, R>;
  //опции хука query
  readonly queryOptions?: Partial<PageableListQueryOptionsType>;
};
