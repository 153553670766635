import { Typography } from '@mui/material';
import { IconFavoriteFilled } from 'presentation/media/icons';
import { favoritesStub } from 'presentation/media/images';
import Splitter from '../../../../../components/common/splitter';
import { ImageWrapper, Wrapper } from './controls';

const UserFavoritesStub = () => {
  return (
    <Wrapper>
      <Typography
        variant='h2'
        gutterBottom
      >
        В избранном пусто
      </Typography>
      <Typography>
        Жмите <IconFavoriteFilled fontSize='small' /> чтобы сохранить
      </Typography>
      <Typography>понравившиеся предложения</Typography>
      <Splitter size={5} />
      <ImageWrapper>
        <img
          src={favoritesStub}
          alt='favorites-stub-image'
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default UserFavoritesStub;
