import { EOfferStatus } from '../../../../../../../domain/model/enums';
import { dialogContent } from '../../../components/dialogs/help/constants';
import useTradeOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import OfferClaimHelp from '../../../../components/claim/help';

const TradeOfferClaimHelpAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();
  const { openHelpDialog } = useTradeOfferClaimDialogs();

  if (!tradeOffer) {
    return null;
  }

  const text = dialogContent[tradeOffer.promotionType].title;

  const isVisible = tradeOffer.status === EOfferStatus.Active;

  return isVisible ? (
    <OfferClaimHelp
      text={text}
      onClick={openHelpDialog}
    />
  ) : null;
};

export default TradeOfferClaimHelpAdapter;
