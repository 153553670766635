import MainFooterContainer from 'presentation/features/footer/container';
import { ScreenLayoutType } from './types';

const ScreenLayout: ScreenLayoutType = props => {
  const { footer, children } = props;

  return (
    <>
      {children}
      {typeof footer === 'boolean' ? <MainFooterContainer /> : footer}
    </>
  );
};

export default ScreenLayout;
