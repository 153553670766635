import { PartnerOfferListRequest, useGetPartnerTradeOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferListType, ETradeOfferSortType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import {
  partnerDeskDetailsTradeOffersIsNewFetchingSelector
} from 'presentation/features/partnerDesk/details/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import OfferListItemContainer from '../../../../offer/listItem/container';

type PartnerDeskTradeOffersDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<ETradeOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (tradeOffer: TradeOfferShort) => void;
};

const PartnerDeskTradeOffersDataContainer = (props: PartnerDeskTradeOffersDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { isAuthenticated } = useAuth();
  const isNewFetching = useSelector(partnerDeskDetailsTradeOffersIsNewFetchingSelector);
  const { page, partnerId } = args;

  const { data, error, isSuccess, isFetching } = useGetPartnerTradeOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const tradeOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!tradeOffers && tradeOffers.length > 0;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {tradeOffers.map(tradeOffer => (
            <InView
              key={tradeOffer.id}
              active={isSuccess && !isFetching}
              sessionKey={sessionKey}
              data={tradeOffer}
              onShow={onShowCard}
            >
              <OfferListItemContainer
                key={tradeOffer.id}
                offer={tradeOffer}
                favorite={
                  isAuthenticated && {
                    guid,
                    partnerId,
                  }
                }
              />
            </InView>
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskTradeOffersDataContainer;
