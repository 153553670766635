import OfferDetailsOnlyTitle from 'presentation/features/offer/components/detailsOnlyTitle';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';

type ProductOfferDetailsOnlyTitleAdapterProps = {
  readonly id: UUID;
}

const ProductOfferDetailsOnlyTitleAdapter = (props: ProductOfferDetailsOnlyTitleAdapterProps) => {
  const { id } = props;
  const { productOffer } = useProductOfferDetailsData(id);

  if (!productOffer) {
    return null;
  }

  return <OfferDetailsOnlyTitle title={productOffer.name} />;
};

export default ProductOfferDetailsOnlyTitleAdapter;
