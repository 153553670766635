import AddressCitySingleSelector from 'presentation/components/common/address/singleSelector/city';
import { UserProfileEditAttribute } from './attribute';
import { UserProfileEditAttributeProps } from './types';

type UserProfileEditAttributeLocalityProps = UserProfileEditAttributeProps<'locality'>;

export const UserProfileEditAttributeLocality = (props: UserProfileEditAttributeLocalityProps) => {
  const { label, value, validation, isFetching, onChange } = props;

  return (
    <UserProfileEditAttribute {...props}>
      <AddressCitySingleSelector
        fullWidth
        label={label}
        value={value}
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={onChange}
      />
    </UserProfileEditAttribute>
  );
};
