import useOrderDetailsData from './useOrderDetailsData';

type OrderDetailsFetchingStatusProps = {
  readonly id: UUID;
};

type UseOrderDetailsFetchingStatus = boolean;

const useOrderDetailsFetchingStatus = ({ id }: OrderDetailsFetchingStatusProps): UseOrderDetailsFetchingStatus => {
  const { isFetching: isOrdersFetching } = useOrderDetailsData(id);

  return isOrdersFetching;
};

export default useOrderDetailsFetchingStatus;
