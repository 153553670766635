import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserActivationsContainer from 'presentation/features/user/details/activations/container';
import ScreenLayout from 'presentation/layouts/screen';

const UserActivationsScreen = () => {
  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <UserActivationsContainer guid={guid} />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserActivationsScreen;
