import React from 'react';

export type RenderManagerErrorContextType = {
  readonly setError: (error: unknown) => void;
  readonly clearError: () => void;
  readonly error: unknown;
};

const RenderManagerErrorContext = React.createContext<RenderManagerErrorContextType>(
  {} as RenderManagerErrorContextType
);

export default RenderManagerErrorContext;
