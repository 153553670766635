import { useGetCorpOfferDetailsQuery } from 'data/api/corpOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useContext, useEffect } from 'react';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';

const useCorpOfferDetailsData = (id: UUID) => {
  const { setError } = useContext(RenderManagerErrorContext);

  const {
    data: corpOffer,
    error: corpOfferFetchError,
    isError: isCorpOfferError,
    isFetching: isCorpOfferFetching,
    isLoading: isCorpOfferLoading,
    isSuccess: isCorpOfferFetched,
  } = useGetCorpOfferDetailsQuery({ id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (corpOfferFetchError) {
      ErrorHandler.handleHttpError(corpOfferFetchError);
      setError(corpOfferFetchError);
    }
  }, [setError, corpOfferFetchError]);

  return {
    corpOffer,
    corpOfferFetchError,
    isCorpOfferError,
    isCorpOfferFetching,
    isCorpOfferFetched,
    isCorpOfferLoading,
  };
};

export default useCorpOfferDetailsData;
