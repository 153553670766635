import { PriceRangeFilter } from 'domain/model/offer';
import { EBookingOfferListRequestType } from './types';

export const getRequestTypes = (requestType: EBookingOfferListRequestType) => {
  const isAll = requestType === EBookingOfferListRequestType.All;
  const isReset = requestType === EBookingOfferListRequestType.Reset;
  const isServices = requestType === EBookingOfferListRequestType.Services;
  const isPrice = requestType === EBookingOfferListRequestType.Price;

  return { isAll, isReset, isServices, isPrice };
};

export const getAdjustedPrice = (
  type: 'minPrice' | 'maxPrice',
  priceRange: PriceRangeFilter,
  requestedPriceRange: Nullable<PriceRangeFilter>
) => {
  if (!requestedPriceRange || requestedPriceRange[type] === null || priceRange[type] === null) {
    return priceRange[type];
  }

  const equality =
    type === 'minPrice'
      ? (requestedPriceRange?.[type] ?? 0) >= (priceRange[type] ?? Infinity)
      : (requestedPriceRange?.[type] ?? Infinity) <= (priceRange[type] ?? 0);

  return equality ? requestedPriceRange[type] : priceRange[type];
};
