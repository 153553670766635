import ContentLoader from 'presentation/components/common/loader';
import useTradeOfferDetailsFetchingStatus from '../hooks/useTradeOfferDetailsFetchingStatus';

type TradeDetailsLoaderAdapterProps = {
  readonly id: UUID;
}

const TradeOfferDetailsLoaderAdapter = ({ id }: TradeDetailsLoaderAdapterProps) => {
  const isFetching = useTradeOfferDetailsFetchingStatus({ id });

  return isFetching ? <ContentLoader position='fixed' /> : null;
};

export default TradeOfferDetailsLoaderAdapter;
