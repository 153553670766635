import { TypographyOptions, TypographyStyle } from '@mui/material/styles/createTypography';
import { brandbook } from '../brandBook';
import { OverrideThemeFunc } from './types';

const defaultFontStyle: TypographyStyle = brandbook.typography.body1;

const typography: OverrideThemeFunc<TypographyOptions> = (baseTheme): TypographyOptions => ({
  htmlFontSize: 16,

  fontFamily: `'${brandbook.typography.fontFamily}', sans-serif`,
  fontWeightRegular: brandbook.typography.fontWeightRegular,
  fontWeightMedium: brandbook.typography.fontWeightMedium,
  fontSize: parseInt(defaultFontStyle.fontSize as string),

  h1: {
    ...brandbook.typography.h1,
    [baseTheme.breakpoints.down('md')]: {
      ...brandbook.typography.h2,
    },
  },
  h2: {
    ...brandbook.typography.h2,
    [baseTheme.breakpoints.down('md')]: {
      ...brandbook.typography.h3,
    },
  },
  h3: brandbook.typography.h3,

  subtitle1: brandbook.typography.subtitle1,
  subtitle2: brandbook.typography.p2Medium,

  body1: brandbook.typography.body1,
  body2: brandbook.typography.body2,

  button: {
    ...brandbook.typography.body1,
    textTransform: 'none',
  },
  caption: {
    ...brandbook.typography.p3,
    display: 'block',
  },
  overline: brandbook.typography.p4,
});

export default typography;
