import { MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

type MPMenuItemProps<T extends string | number | readonly string[] | undefined> = MenuItemProps & {
  readonly value: T;
};

function MPMenuItemInternal<T extends string | number | readonly string[] | undefined>(
  props: MPMenuItemProps<T> & { forwardedRef?: any }
) {
  const { forwardedRef, ...others } = props;

  return (
    <MenuItem
      ref={forwardedRef}
      {...others}
    />
  );
}

export const MPMenuItem = React.forwardRef<typeof MPMenuItemInternal, any>(
  <T extends string | number | readonly string[] | undefined>(props: MPMenuItemProps<T>, ref: any) => (
    <MPMenuItemInternal<T>
      forwardedRef={ref}
      {...props}
    />
  )
) as typeof MPMenuItemInternal;
