import { TabsProps } from '@mui/material';
import { MPTab } from 'presentation/theme/ui-kit/tab';
import { ELocaleShort } from 'presentation/types';
import { CountrySelectorProps } from '../utils';
import { Tabs } from './controls';

type CountryTabsProps = CountrySelectorProps & Omit<TabsProps, 'value' | 'onChange' | 'children'>;

export const CountryTabs = ({ value, onChange, ...other }: CountryTabsProps) => {
  return (
    <Tabs
      value={value}
      onChange={(event, value) => onChange(value)}
      {...other}
    >
      {Object.values(ELocaleShort).map(item => (
        <MPTab
          key={item}
          label={item.toUpperCase()}
          value={item}
        />
      ))}
    </Tabs>
  );
};
