import { Tab, Tabs } from '@mui/material';
import { EUserOrdersTab } from '../../details/orders/utils';

type UserOrdersTabsProps = {
  readonly tab: EUserOrdersTab;
  readonly onChangeTab: (newTab: EUserOrdersTab) => void;
};

const UserOrdersTabs = ({ tab, onChangeTab }: UserOrdersTabsProps) => {
  return (
    <Tabs
      value={tab}
      textColor='secondary'
      variant='scrollable'
      onChange={(event, newTab) => onChangeTab(newTab)}
    >
      <Tab
        label='В процессе'
        value={EUserOrdersTab.Active}
      />
      <Tab
        label='Выполненные'
        value={EUserOrdersTab.Completed}
      />
      <Tab
        label='Отмененные'
        value={EUserOrdersTab.Cancelled}
      />
    </Tabs>
  );
};

export default UserOrdersTabs;
