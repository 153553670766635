import AboutTextSectionsContainer from '../textSections/container';
import AboutAspCommonInfo from './info/common';

enum ESection {
  Common = 'common',
}

const AboutAspContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='AboutAspContainer'
      sections={[
        {
          id: ESection.Common,
          name: '1. Что такое авансируемый социальный пакет',
          component: AboutAspCommonInfo,
        },
      ]}
    />
  );
};

export default AboutAspContainer;
