import { useMediaQuery } from '@mui/material';
import { CartIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../../../components/common/icon';
import { productOfferAddToCartLabel, productOfferInCartLabel } from '../constants';
import { ProductOfferAddToCartLabelProps } from '../types';

const ProductOfferCartButtonLabelForCard = ({ isEmpty }: ProductOfferAddToCartLabelProps) => {
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (isEmpty) return <>{productOfferAddToCartLabel}</>;

  return <>{isSmDown ? <AppSvgIcon icon={CartIcon} /> : productOfferInCartLabel}</>;
};

export default ProductOfferCartButtonLabelForCard;
