import { Wrapper } from './controls';
import { CategorySelectCommonLayoutType } from './types';

const CategorySelectCommonLayout: CategorySelectCommonLayoutType = props => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default CategorySelectCommonLayout;
