import * as H from 'history';
import { Location } from 'history';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { SearchResultsLocationState } from 'presentation/features/search/entry';
import { ERenderSource, ESearchUrlParam } from 'presentation/types';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
import { GetSearchResultsRouteProps } from '../types';
import { EBookingOfferDetailsTab } from './details/types';
import { BookingOffersListLocationState, BookingOffersSearchListLocationState, EBookingUrlParam } from './types';

type GetBookingOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: EBookingOfferDetailsTab;
  readonly from?: ERenderSource;
};

type GetBookingOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export type BookingOfferDetailsLocationState = {
  readonly guid: UUID;
  readonly from?: ERenderSource;
};

const root = rootRouting.bookingOffer;
export const routing = {
  list: root,
  search: `${root}/search`,
  details: `${root}/:id`,
  order: `${root}/order/:id`,
};

export const getBookingOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersSearchRoute = (
  props: BookingOffersSearchListLocationState
): H.Location<Pick<BookingOffersSearchListLocationState, 'guid'>> => {
  const { name, categoryId, guid, minPrice, maxPrice, sort, services } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ECatalogUrlParam.Category, categoryId);
  }

  if (typeof minPrice === 'number') {
    params.append(EBookingUrlParam.MinPrice, String(minPrice));
  }

  if (typeof maxPrice === 'number') {
    params.append(EBookingUrlParam.MaxPrice, String(maxPrice));
  }

  if (sort) {
    params.append(EBookingUrlParam.Sort, sort.join('+'));
  }

  if (typeof services === 'object') {
    params.append(EBookingUrlParam.Services, services.join(','));
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersListRoute = (
  props?: GetBookingOffersListRouteProps
): Location<BookingOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersDetailsOrderRoute = ({
  id,
  guid,
}: GetBookingOfferDetailsRouteProps): H.Location<Pick<BookingOffersSearchListLocationState, 'guid'>> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  return {
    pathname: `${routing.order.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersDetailsRoute = ({
  id,
  tab,
  guid,
  from,
}: GetBookingOfferDetailsRouteProps): H.Location<BookingOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  if (tab) {
    params.append(EBookingUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
      from,
    },
    hash: '',
  };
};
