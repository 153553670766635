import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { getAspOffersSearchRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersSearchRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOffersSearchRoute } from 'presentation/features/offer/corp/routes';
import { getProductOffersSearchRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersSearchRoute } from 'presentation/features/offer/trade/routes';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppCategories, EAppFeature } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { offerSectionToTabMap } from '../../header/utils';
import { CatalogContextValue } from '../context';
import { catalogSetTab, catalogSetTabs } from '../store/slice';
import { CatalogTabInfo, ECatalogTab } from '../types';

type UseCatalogHandlersProps = {
  readonly onClose: () => void;
  readonly matchedOfferSectionPath: Nullable<string>;
};

const useCatalogHandlers = ({ onClose, matchedOfferSectionPath }: UseCatalogHandlersProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasFeature } = useTechConfig();
  const { isCorpUser } = useAuthUser();

  const onChangeTab = useCallback<CatalogContextValue['onChangeTab']>(
    tab => {
      dispatch(catalogSetTab(tab));
    },
    [dispatch]
  );

  const onSelectTradeOffersCategory = useCallback<CatalogContextValue['onSelectTradeOffersCategory']>(
    category => {
      history.push(getTradeOffersSearchRoute({ categoryId: category.id }));
    },
    [history]
  );

  const onSelectAspOffersCategory = useCallback<CatalogContextValue['onSelectAspOffersCategory']>(
    category => {
      history.push(getAspOffersSearchRoute({ categoryId: category.id }));
    },
    [history]
  );

  const onSelectCorpOffersCategory = useCallback<CatalogContextValue['onSelectCorpOffersCategory']>(
    category => {
      history.push(getCorpOffersSearchRoute({ categoryId: category.id }));
    },
    [history]
  );

  const onSelectProductOffersCategory = useCallback<CatalogContextValue['onSelectProductOffersCategory']>(
    category => {
      history.push(getProductOffersSearchRoute({ categoryId: category.id }));
    },
    [history]
  );

  const onSelectBookingOffersCategory = useCallback<CatalogContextValue['onSelectBookingOffersCategory']>(
    category => {
      history.push(getBookingOffersSearchRoute({ categoryId: category.id }));
    },
    [history]
  );

  useEffect(() => {
    const tabs: CatalogTabInfo[] = [];

    if (isCorpUser) {
      tabs.push({ label: EAppCategories.CorpOffers as const, value: ECatalogTab.CorpOffers });
    }

    if (hasFeature(EAppFeature.Trade)) {
      tabs.push({ label: EAppCategories.TradeOffers as const, value: ECatalogTab.TradeOffers });
    }

    if (hasFeature(EAppFeature.Product)) {
      tabs.push({ label: EAppCategories.ProductOffers as const, value: ECatalogTab.ProductOffers });
    }

    if (hasFeature(EAppFeature.Booking)) {
      tabs.push({ label: EAppCategories.BookingOffers, value: ECatalogTab.BookingOffers });
    }

    if (isCorpUser && hasFeature(EAppFeature.Asp)) {
      tabs.push({ label: EAppCategories.AspOffers, value: ECatalogTab.AspOffers });
    }

    dispatch(catalogSetTabs(tabs));

    if (!tabs.length) {
      return;
    }
    const matchedWithPathTab = offerSectionToTabMap.find(s => s.path === matchedOfferSectionPath);
    matchedWithPathTab ? dispatch(catalogSetTab(matchedWithPathTab.tab)) : dispatch(catalogSetTab(tabs[0].value));
  }, [dispatch, isCorpUser, hasFeature, matchedOfferSectionPath]);

  return {
    onClose,
    onChangeTab,
    onSelectTradeOffersCategory,
    onSelectAspOffersCategory,
    onSelectCorpOffersCategory,
    onSelectProductOffersCategory,
    onSelectBookingOffersCategory,
  };
};

export default useCatalogHandlers;
