import { Typography } from '@mui/material';
import { AppOptionTyped } from '../../../../../../../domain/model';
import { EReasonInitiator } from '../../../../../../../domain/model/enums';
import { BookingOrder } from '../../../../../../../domain/model/order';
import { ContentLimiter } from '../../../../../../components/common/contentLimit';
import { ContentLimiterDialogLayout } from '../../../../../../components/common/contentLimit/layouts';
import { WebWindowIcon } from '../../../../../../media/icons';
import { LinkMore, Wrapper } from './controls';

type StatusCommentProps = {
  readonly order: BookingOrder;
  readonly reasons: AppOptionTyped<UUID>[];
};

const getLabelInitiator = (initiator: EReasonInitiator) => {
  switch (initiator) {
    case EReasonInitiator.Customer:
      return 'Заказ отменён вами';
    case EReasonInitiator.Partner:
      return 'Заказ отменен менеджером объекта';
  }
};

const getReasonComment = (initiator: EReasonInitiator, order: BookingOrder, reasons: AppOptionTyped<string>[]) => {
  let reason;
  switch (initiator) {
    case EReasonInitiator.Customer:
      reason = reasons.find(c => c.id === order.customerCancelReason?.id);
      if (reason?.name?.toLowerCase() === 'другое') {
        return order.lastStatusComment;
      } else {
        return reason?.name;
      }
    case EReasonInitiator.Partner:
      reason = reasons.find(c => c.id === order.cancelReason?.id);
      if (reason?.name?.toLowerCase() === 'другое') {
        return order.lastStatusComment;
      } else {
        return reason?.name;
      }
  }
};

const StatusComment = ({ order, reasons }: StatusCommentProps) => {
  const initiator: EReasonInitiator = order.customerCancelReason ? EReasonInitiator.Customer : EReasonInitiator.Partner;
  const comment = getReasonComment(initiator, order, reasons);
  const initiatorLabel = getLabelInitiator(initiator);

  return (
    <Wrapper>
      <ContentLimiter
        layout={ContentLimiterDialogLayout}
        layoutProps={{
          rows: 3,
          title: 'Причина отмены',
          contentProps: { textAlign: 'center' },
          moreText: (
            <LinkMore>
              <WebWindowIcon /> посмотреть полностью
            </LinkMore>
          ),
        }}
      >
        <Typography>
          {initiatorLabel}
          {': '}
          {comment}
        </Typography>
      </ContentLimiter>
    </Wrapper>
  );
};

export default StatusComment;
