import { useSelector } from 'react-redux';
import { aspOfferListNoDataSelector, aspOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import AspOffersStub from '../stub/common';

export const AspOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(aspOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(aspOfferListNoDataSelector);

  if (noData) {
    return <AspOffersStub isEmptySearch={isEmptySearch} />;
  }

  return null;
};
