import { pluralize } from './pluralize';

export enum EFileSizeMeasure {
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  Byte = 'Byte',
}

const oneKb = 1024;
const oneMb = oneKb * oneKb;
const oneGb = oneKb * oneMb;

type NumberUnit = {
  readonly value: number;
  readonly unit: EFileSizeMeasure;
};

export const convertBytesToAnyUnits = (bytes: number, unit?: EFileSizeMeasure): NumberUnit => {
  if (!unit) {
    if (bytes < oneKb) {
      unit = EFileSizeMeasure.Byte;
    } else if (bytes < oneMb) {
      unit = EFileSizeMeasure.KB;
    } else if (bytes >= oneGb) {
      unit = EFileSizeMeasure.GB;
    } else {
      unit = EFileSizeMeasure.MB;
    }
  }

  switch (unit) {
    case EFileSizeMeasure.Byte:
      return {
        value: bytes,
        unit: EFileSizeMeasure.Byte,
      };
    case EFileSizeMeasure.KB:
      return {
        value: Math.round(bytes / oneKb),
        unit,
      };
    case EFileSizeMeasure.MB:
      return {
        value: Math.round((bytes / oneMb) * 100) / 100,
        unit,
      };
    case EFileSizeMeasure.GB:
      return {
        value: Math.round(bytes / oneGb),
        unit,
      };
    default:
      return {
        value: bytes,
        unit: EFileSizeMeasure.Byte,
      };
  }
};

export const getNumberUnitText = (numberUnit: NumberUnit): string => {
  switch (numberUnit.unit) {
    case EFileSizeMeasure.Byte:
      return `${numberUnit.value} ${pluralize(numberUnit.value, ['байт', 'байта', 'байт'])}`;
    case EFileSizeMeasure.KB:
      return `${numberUnit.value} КБ`;
    case EFileSizeMeasure.MB:
      return `${numberUnit.value} МБ`;
    case EFileSizeMeasure.GB:
      return `${numberUnit.value} ГБ`;
    default:
      return `${numberUnit.value} ${numberUnit.unit}`;
  }
};

export const convertBytesToUnitText = (bytes: number, unit?: EFileSizeMeasure): string => {
  const numberUnit: NumberUnit = convertBytesToAnyUnits(bytes, unit);
  return getNumberUnitText(numberUnit);
};
