import { createSelector } from '@reduxjs/toolkit';
import { corpOfferApi } from 'data/api/corpOffer';
import { RootState } from 'data/store/store';
import { ECorpOfferSortType } from 'domain/model/enums';

export const corpOfferListGuidSelector = (store: RootState) => store.corpOffer.list.guid;

export const corpOfferListSelector = (store: RootState) => store.corpOffer.list;

export const corpOfferListCommonArgsSelector = (store: RootState) => store.corpOffer.list.common;

export const corpOfferListUpcomingArgsSelector = (store: RootState) => store.corpOffer.list.upcoming;

export const corpOfferListNameSelector = (store: RootState) => store.corpOffer.list.common?.search?.name ?? null;
export const corpOfferListSortSelector = (store: RootState) =>
  (store.corpOffer.list.common?.sort ?? null) as Nullable<ECorpOfferSortType[]>;
export const corpOfferListCategoryIdSelector = (store: RootState) =>
  store.corpOffer.list.common?.search?.categories?.[0] ?? null;

export const corpOfferListSearchIsEmptySelector = (store: RootState) =>
  !store.corpOffer.list.common?.search?.name && !store.corpOffer.list.common?.search?.categories?.length;

export const corpOfferListSearchByNameIsEmptySelector = (store: RootState) =>
  !store.corpOffer.list.common?.search?.name;

export const corpOfferListCommonCountSelector = (store: RootState) => {
  const args = store.corpOffer.list.common;

  if (!args) {
    return 0;
  }

  const { data } = corpOfferApi.endpoints.getCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const corpOfferListUpcomingCountSelector = (store: RootState) => {
  const args = store.corpOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = corpOfferApi.endpoints.getCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const corpOfferListCountSelector = createSelector(
  corpOfferListCommonCountSelector,
  corpOfferListUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const corpOfferListCommonIsErrorSelector = (store: RootState) => {
  const args = store.corpOffer.list.common;

  if (!args) {
    return 0;
  }

  const { isError } = corpOfferApi.endpoints.getCorpOfferList.select(args)(store);
  return isError;
};

export const corpOfferListUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.corpOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = corpOfferApi.endpoints.getCorpOfferList.select(args)(store);
  return isError;
};

export const corpOfferListIsErrorSelector = createSelector(
  corpOfferListCommonIsErrorSelector,
  corpOfferListUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const corpOfferListCommonIsFetchingSelector = (store: RootState) => {
  const args = store.corpOffer.list.common;
  return args ? corpOfferApi.endpoints.getCorpOfferList.select(args)(store).isLoading : false;
};

export const corpOfferListUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.corpOffer.list.upcoming;
  return args ? corpOfferApi.endpoints.getCorpOfferList.select(args)(store).isLoading : false;
};

export const corpOfferListIsFetchingSelector = createSelector(
  corpOfferListCommonIsFetchingSelector,
  corpOfferListUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const corpOfferListIsNewFetchingSelector = createSelector(
  corpOfferListIsFetchingSelector,
  (store: RootState) => store.corpOffer.list.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

export const corpOfferListIsNewSearchFetchingSelector = createSelector(
  corpOfferListIsFetchingSelector,
  (store: RootState) => store.corpOffer.list.isNewSearchFetching,
  (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching
);

export const corpOfferListNoDataSelector = createSelector(
  corpOfferListCountSelector,
  corpOfferListIsFetchingSelector,
  corpOfferListIsErrorSelector,
  (count, isFetching, isError) => count === 0 && !isFetching && !isError
);
