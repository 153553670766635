import { ClaimWrapper, InnerWrapper, SkirtWrapper } from './controls';
import React, { PropsWithChildren } from 'react';

type OfferClaimLayoutProps = PropsWithChildren & {
  readonly content: Nullable<React.JSX.Element>;
  readonly skirt?: Nullable<React.JSX.Element>;
};

const OfferClaimLayout = React.forwardRef(({ content, skirt, children }: OfferClaimLayoutProps, ref: any) => {
  return (
    <ClaimWrapper ref={ref}>
      <InnerWrapper>
        {content}
        {skirt && <SkirtWrapper>{skirt}</SkirtWrapper>}
      </InnerWrapper>
      {children}
    </ClaimWrapper>
  );
});

export default OfferClaimLayout;
