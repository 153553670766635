import styled from '@emotion/styled/macro';
import { tabsClasses } from '@mui/material';
import { forwardRef } from 'react';

type TabsWrapperProps = {
  readonly offsetX?: Nullable<number> | false;
  readonly children: any;
}

export const TabsWrapper = styled(
  forwardRef(({ offsetX, ...props }: TabsWrapperProps, ref: any) => (
    <div
      ref={ref}
      {...props}
    />
  ))
)`
  ${p => p.theme.breakpoints.down('sm')} {
    .${tabsClasses.root} {
      margin-left: ${p => -(p.offsetX || 0)}px;
      margin-right: ${p => -(p.offsetX || 0)}px;
    }
  }
`;
