import { Typography } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import moment from 'moment-timezone';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';

const ActivityEndDateAdapter = () => {
  const { corpOffer } = useCorpOfferClaimContext();
  if (!corpOffer) {
    return null;
  }

  if (corpOffer.status !== EOfferStatus.Active && corpOffer.status !== EOfferStatus.Upcoming) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Активно до {moment(corpOffer.endDate).format(EDateFormat.Human)}</Typography>
    </Wrapper>
  );
};

export default ActivityEndDateAdapter;
