import { Fade } from '@mui/material';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { PartnerDeskDetailsAboutLayoutType } from './types';

const PartnerDeskDetailsAboutLayout: PartnerDeskDetailsAboutLayoutType = props => {
  const { children } = props;

  return (
    <Fade in>
      <DangerouslyHtmlWrapper>
        {children && <div dangerouslySetInnerHTML={{ __html: children }} />}
      </DangerouslyHtmlWrapper>
    </Fade>
  );
};

export default PartnerDeskDetailsAboutLayout;
