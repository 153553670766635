import { ListItemIcon, MenuItem, MenuItemProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { CounterWrapper, TextWrapper } from './controls';

type UserMenuItemProps = Omit<MenuItemProps, 'children'> & {
  readonly icon?: ReactNode;
  readonly label: ReactNode;
  readonly count?: number;
  readonly onClick?: () => void;
};

export const UserMenuItem: FC<UserMenuItemProps> = ({ label, icon, count, ...others }) => {
  return (
    <MenuItem {...others}>
      <ListItemIcon>{icon}</ListItemIcon>
      <TextWrapper primary={label} />
      {!!count && (
        <CounterWrapper>
          <Typography color='text.secondary'>{count}</Typography>
        </CounterWrapper>
      )}
    </MenuItem>
  );
};
