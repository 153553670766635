import styled from '@emotion/styled/macro';
import { css, Typography } from '@mui/material';

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    height: 100%;
    max-width: 60%;

    ${theme.breakpoints.down('sm')} {
      position: relative;

      max-width: none;
    }
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)};
  `
);

export const HeaderInfo = styled(Typography)(
  () => css`
    margin-top: 0.85rem;
  `
);

export const ListOrders = styled.div(() => css``);

export const ControlButtons = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 1.4rem;

    margin-right: 0;

    ${theme.breakpoints.down('sm')} {
      margin: auto;
      margin-top: 2.5rem;
    }
  `
);

export const Footer = styled.div(
  ({ theme }) => css`
    position: fixed;
    bottom: 0;

    height: 5rem;
    width: 100%;

    background-color: ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      position: relative;

      margin: auto;
      margin-top: 2.5rem;
    }
  `
);
