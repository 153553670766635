import styled from '@emotion/styled/macro';
import { css, ListItemSecondaryAction, ListItemText } from '@mui/material';

export const CounterWrapper = styled(ListItemSecondaryAction)(
  ({ theme }) => css`
    position: relative;
    right: unset;
    top: unset;
    transform: none;
    margin-left: ${theme.spacing()};
  `
);

export const TextWrapper = styled(ListItemText)(
  () => css`
    .MuiTypography-root {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `
);
